import { format, date } from "../../../utils"
import { stringifyFilter } from 'colbi_web_ui/lib/utils'

const mapDetails = (json) => {
	const {
		fiscalYear,
		auditFileVersion,
		startDate,
		endDate,
		createdDate,
		application,
		taxRegistrationNumber,
		company = {},
		salesInvoices = {},
		glTransactions = {},
		purchasesInvoices = {},
		payments = {},
		stockMovements = {},
		file = {}
	} = json

	return [
		[
			{ label: 'Version', value: auditFileVersion, type: 'string' },
			{ label: 'Created Date', value: date(createdDate), type: 'date' },
			{ label: 'Start Date', value: date(startDate), type: 'date' },
			{ label: 'End Date', value: date(endDate), type: 'date' },
			{ label: 'Fiscal Year', value: fiscalYear, type: 'string' },
		],
		[
			{ label: 'Software Company Name', value: application.name, type: 'string' },
			{ label: 'Software Version', value: application.version, type: 'string' },
			{ label: 'Tax Accouting Basis', value: application.type, type: 'string' },
		],
		{
			title: 'Company',
			rows: [
				[
					{ label: 'Company Name', value: company.name || 'N/A', type: 'string' },
					{ label: 'Registration Number', value: taxRegistrationNumber || 'N/A', type: 'string' },
					{ label: 'Entity', value: company.taxEntity || 'N/A', type: 'string' },
					{ label: 'Business Name', value: company.businessName || 'N/A', type: 'string' },
				]
			]
		},
		{
			title: 'Sales Invoices',
			rows: [
				[
					{ label: 'Entries No', value: format(salesInvoices.entriesNumber, null, null), type: 'number' },
					{ label: 'Debit Total', value: format(salesInvoices.debitTotal, 'fullValue'), type: 'currency' },
					{ label: 'Credit Total', value: format(salesInvoices.creditTotal, 'fullValue'), type: 'currency' }
				]
			]
		},
		{
			title: 'Purchase Invoices',
			rows: [
				[
					{ label: 'Entries No', value: format(purchasesInvoices.entriesNumber, null, null), type: 'number' },
					{ label: 'Debit Total', value: format(purchasesInvoices.debitTotal, 'fullValue'), type: 'currency' },
					{ label: 'Credit Total', value: format(purchasesInvoices.creditTotal, 'fullValue'), type: 'currency' }
				]
			]
		},
		{
			title: 'GL Transactions',
			rows: [
				[
					{ label: 'Entries No', value: format(glTransactions.entriesNumber, null, null), type: 'number' },
					{ label: 'Debit Total', value: format(glTransactions.debitTotal, 'fullValue'), type: 'currency' },
					{ label: 'Credit Total', value: format(glTransactions.creditTotal, 'fullValue'), type: 'currency' }
				]
			]
		},
		{
			title: 'Payments',
			rows: [
				[
					{ label: 'Entries No', value: format(payments.entriesNumber, null, null), type: 'number' },
					{ label: 'Debit Total', value: format(payments.debitTotal, 'fullValue'), type: 'currency' },
					{ label: 'Credit Total', value: format(payments.creditTotal, 'fullValue'), type: 'currency' }
				]
			]
		},
		{
			title: 'Stock Movements',
			rows: [
				[
					{ label: 'Entries No', value: format(stockMovements.entriesNumber, null, null), type: 'number' },
					{ label: 'Quantity Issued', value: format(stockMovements.quantityIssued, null, null), type: 'number' }
				]
			]
		},
		{
			title: 'File',
			rows: [
				[
					{ label: 'ID', value: file.id || 'N/A', type: 'string' },
					{ label: 'Type', value: file.type || 'N/A', type: 'string' },
					{ label: 'Version', value: file.version || 'N/A', type: 'string' },
					{
						label: 'Uploaded At',
						value: date(file.uploadedAt) || 'N/A',
						type: 'date'
					},
					{ label: 'Name', value: file.name || 'N/A', type: 'string' }
				]
			]
		}
	]
}

const mapCard = (json) => {
	const {
		fiscalYear,
		auditFileVersion,
		taxRegistrationNumber,
		businessName,
		startDate,
		endDate,
		glTransactionsCount,
		invoicesCount,
		stockMovementsCount,
		assetTransactionCount,
		paymentsCount,
		purchaseCount,
		fileName,
		fileId,
		fileType,
		application,
		company
	} = json

	const fileTypeToUse = (fileType|| "").toString().startsWith("LtErp")? "LtErpFile": (fileType || "").toString()
	const resType = fileTypeToUse.replace("File", "")

	const linkToUse = `/:locale(pt|en|lt|es)?/:projectId/repository/folders/${fiscalYear}/${resType}`;	

	return [
		[
			{ labels: ['file_name'], 
			value: { 
				id: fileId , name: fileName, __typename: 'FileDetail',
				link: {
					to: `${linkToUse}/${fileId}?filter=${
						stringifyFilter(
							[{ column: 'id', filters: [{ value: `${fileId}` }] }]
						)
						}`
				},
				}, type: 'object' }
		],
		[
			{ labels: ['nif'], value: `${ taxRegistrationNumber }`, type: 'string', basis: "20%"  }
		],
		[
			{ labels: ['business_name'], value: `${ businessName ? businessName : 'N/A' }`, type: 'string' }
		],
		[
			{ labels: ['companyTaxEntity'], value: company.taxEntity, type: 'string' }
		],
		[
			{ labels: ['version'], value: `${ auditFileVersion }`, type: 'number' }
		],
		[
			{ labels: ['fiscal_year'], value: `${ fiscalYear }`, type: 'number' }
		],
		[
			{ labels: ['start_date'], value: date(startDate), type: 'date' }
		],
		[
			{ labels: ['end_date'], value: date(endDate), type: 'date' }
		],
		[
			{ labels: ['application_name'], value: `${ application.name }`, type: 'string', basis: "30%" }
		],
		[
			{ labels: ['gl_transactions'], value: format(glTransactionsCount, null, null), type: 'number', align: 'right' }
		],
		[
			{ labels: ['invoices'], value: format(invoicesCount, null, null), type: 'number', align: 'right' }
		],
		[
			{ labels: ['Purchase Invoices'], value: format(purchaseCount, null, null), type: 'number', align: 'right' }
		],
		[
			{ labels: ['stock_movement'], value: format(stockMovementsCount, null, null), type: 'number', align: 'right' }
		],
		[
			{ labels: ['asset_transaction'], value: format(assetTransactionCount, null, null), type: 'number', align: 'right' }
		],
		[
			{ labels: ['payments'], value: format(paymentsCount, null, null), type: 'number', align: 'right' }
		]
	]
}

const mapper = (json, card, details) => {
	return {
		...json,
		details: details ? mapDetails(json) : [],
		card: card ? mapCard(json) : []
	}
}

const header = [
	{ label: ['FileName'], align: 'left'},
	{ label: ['Registration Number'], align: 'left', basis: "20%"  },
	{ label: ['Business'], align: 'left', columnName: "company_name", sortable: true },
	{ label: ['Tax Entity'], align: 'left'},
	{ label: ['Version'], align: 'center' },
	{ label: ['Fiscal Year'], align: 'center' },
	{ label: ['Start'], align: 'center', columnName: "selection_criteria_start_date", sortable: true },
	{ label: ['End'], align: 'center', columnName: "selection_criteria_end_date", sortable: true },
	{ label: ['Software Name'], align: 'left', basis: "30%"},
	{ label: ['GL Transactions'], align: 'right' },
	{ label: ['Invoices'], align: 'right' },
	{ label: ['Purchase Invoices'], align: 'right' },
	{ label: ['Stock Mov.'], align: 'right' },
	{ label: ['Asset Transactions'], align: 'right' },
	{ label: ['Payments'], align: 'right' },
]

const headerMapper = {
	map: mapper,
	header
}

export default headerMapper;