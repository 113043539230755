import { checkNumberValues } from "../../../utils/convertUtils";
const mapDetails = (json) => {
    const {
        nif,
        period,
        board404MaisValiasNOTributadasExArt32N2EExArt32AN1Do4,
        board404MenosValiasFiscaisNODedutVeisExArt32N2EExArt32AN16,
        board404DataDoLicenciamento28,
        board404NDePostosDeTrabalhoCriadosNos1S6MesesEMantidosNoPer36,
        board404InvestimentoEfetuadoNaAquisiODeAtivosFixosTangEIntang41,
        board404ProjetosDeInvInternacionalizaOSaldoNODeduzido55,
        board404ProjetosDeInvInternacionalizaODotaODoPerOdo67,
        board404ProjetosDeInvInternacionalizaODeduODoPerOdo79,
        board404ProjetosDeInvInternacionalizaOSaldoQueTransita91,
        board404DeduEsMatColetSaldoNODeduzido115,
        board404DeduEsMatColectDeduODoPerOdo139,
        board404DeduEsMatColectSaldoQueTransitaParaPerOdosSeg151,
        board404DeduEsMatColectDotaODoPerOdo127,
        board405EntidadeLicenciadasNaZonaFrancaDaMadeira36AN6DoEbf16,
        board405IncentivosFiscaisAosLucrosReinvestidosNaRam4,
        board405SocDeCapitalDeRiscoScrEInvestidoresDeCapitalDeRiscoIcr28,
        board405TotalDeDeduEs52,
        board40540,
        board407N2IncentivosDeAnosAnterioresAno4,
        board407N2IncentivosDeAnosAnterioresValor8,
        board407N1IncentivosDeAnosAnterioresAno20,
        board407IncentivoDeNaturezaNOFiscal36,
        board407N1IncentivosDeAnosAnterioresValor24,
        board407TotalDeIncentivosDoTriNio90190290390460,
        board407IrcARegularizar73,
        board407TotalDosIncentivosDoAnoDeNatFiscal904A904B904C904D48,
        board407InvestimentosElegVeisIntangVel97,
        board407InvestimentosElegVeisTangVel85,
        board407InvestimentosElegVeisTotal109,
        board407ReduODeEncargosComASegSocX1TxDoIrc121,
        board407MajoraODasDepreciaEsMajoraO133,
        board407MajoraODasDepreciaEsTaxaDoIrc145,
        board407MajoraODasDepreciaEsValorDoAuxLio149,
        board408MajoraOComOsEncargosComASegSocialMajoraO4,
        board408MajoraOComOsEncargosComASegSocialTaxaDoIrc16,
        board408MajoraODoCrDitoFiscalAoInvestimento32,
        board408TotalDosAuxLios1004100710101011101256,
        board408TaxaMXimaLegalAplicVel73,
        board408ExcessoARegularizar77,
        board408IncentivosFiscAosLucrosReinvestidosNaRaaArt6DeDl89,
        board408DeduOPorLucrosRetidosEReinvestidosPelasPmeEArtS27A34Do101,
        board408TaxaDeAuxLio69,
        board408MajoraOComOsEncargosComASegSocialValorDoAuxLio20,
        board408Outros44,
        board409CrDitoFiscalExtraordinRioAoInvestimentoSaldoNODeduzido16,
        board409CrDitoFiscalExtraordinRioAoInvestimentoSaldoNODeduzido28,
        board409CrDitoFiscalExtraordinRioAoInvestimentoDeduODoPerOdo40,
        board409RemuneraOConvencionalDoCapitalSocial64,
        board409ReduODeTaxaDoIrcAplicVelSPmeAos1S15000DeMat76,
        board409ReduODeTaxaBenefCiosInterioridade88,
        board409DespesasComProjetoDeInvestimentoProdutivo100,
        board409DerramaMunicipal112,
        board409CrDitoFiscalExtraordinRioAoInvestimentoSaldoQueTransita52,
        board419NDePostosDeTrabCriadosMantNoInCioDoPerOdoDeTributaO64,
        board419NDePostosDeTrabCriadosMantNoFinalDoPerOdoDeTributaO69,
        board420DeduODe50ColetaPelasEntidadesArt36AN6DoEbf40,
        board420IndiqueSeSeQualificaComoMicroentidadeNosTermos52,
        board427BenefCiosFiscaisContratuaisAoInvestimentoTotalSaldoNO4,
        board427BenefCiosFiscaisContratuaisAoInvestimentoTotalDotaODo18,
        board427BenefCiosFiscaisContratuaisAoInvestimentoTotalDeduODo32,
        board427BenefCiosFiscaisContratuaisAoInvestimentoTotalSaldoQue46,
        board427SifideTotalSaldoNODeduzidoNoPerOdoAnterior60,
        board427SifideTotalDotaODoPerOdo74,
        board427SifideTotalDeduODoPerOdo88,
        board427SifideTotalSaldoQueTransitaParaPerOdoSSeguinteS102,
        board427RegimeFiscalDeApoioAoInvestimentoTotalSaldoNODeduzidoNo116,
        board427RegimeFiscalDeApoioAoInvestimentoTotalDotaODoPerOdo130,
        board427RegimeFiscalDeApoioAoInvestimentoTotalDeduODoPerOdo144,
        board427RegimeFiscalDeApoioAoInvestimentoTotalSaldoQueTransitaPara158,
        board429IfpcTotalSaldoNODeduzidoNoPerOdoAnterior4,
        board429IfpcTotalValorDoIncentivoNoPerOdo18,
        board429IfpcTotalDeduODoPerOdo32,
        board429IfpcTotalSaldoQueTransitaParaPerOdoSSeguinteS46,
        board429IfpcTotalValorAReembolsar60,
        board429IfpcTotalValorARepRATransportarParaOC372DoQ10Da74,
        board434BeneficiosFiscaisContratAoInvTotalSaldoCaducado4,
        board434RetgsInfAdicionalTotalSaldoCaducadoRealDeclGrupo18,
        board434RetgsInfAdicionalTotalSaldoNODeduzidoColGrupo32,
        board434RetgsInfAdicionalTotalDotaODoPerOdoDeclGrupo46,
        board434RetgsInfAdicionalTotalDeduODeclDeGrupo60,
        board434RetgsInfAdicionalTotalSaldoQueTransitaDeclGrupo74,
        board436RetgsInfAdicionalTotalSaldoCaducadoRealDeclGrupo18,
        board436RetgsInfAdicionalTotalSaldoNODeduzidoColGrupo32,
        board436RetgsInfAdicionalTotalDotaODoPerOdoDeclGrupo46,
        board436RetgsInfAdicionalTotalDeduODeclGrupo60,
        board436RetgsInfAdicionalTotalSaldoQueTransitaDeclGrupo74,
        board438RegimeFiscalDeApoioAoInvestimentoTotalSaldoCaducado4,
        board438RetgsInfAdicionalTotalSaldoCaducadoRealDeclGrupo18,
        board438RetgsInfAdicionalTotalSaldoNODeduzidoColGrupo32,
        board438RetgsInfAdicionalTotalDotaODoPerOdoDeclGrupo46,
        board438RetgsInfAdicionalTotalDeduODeclDeGrupo60,
        board438RetgsInfAdicionalTotalSaldoQueTransitaDeclGrupo74,
        board438TransmBenefFiscSocFundidaTotalSaldoBenefTransmitidos88,
        board438TransmBenefFiscSocFundidaTotalDotaODoPerOdo102
    } = json
    return [
        [
            { label: 'NIF', value: nif, type: 'string' },
            { label: 'Período', value: period, type: 'string' },
        ],
        {
            title: "Q11-111 - Coletividades Desportivas (Art.o 54.o, N.o 2 Do Ebf)",
            rows: [
                [
                    {
                        label: "1111 | Saldo Não Deduzido No Período Anterior",
                        value: checkNumberValues(board404DeduEsMatColetSaldoNODeduzido115),
                        type: "number"
                    },
                    {
                        label: "1112 | Dotação Do Período",
                        value: checkNumberValues(board404DeduEsMatColectDotaODoPerOdo127),
                        type: "number"
                    },
                    {
                        label: "1113 | Dedução Do Período",
                        value: checkNumberValues(board404DeduEsMatColectDeduODoPerOdo139),
                        type: "number"
                    },
                    {
                        label: "1114 | Saldo Que Transita Para Período(S) Seguinte(S)",
                        value: checkNumberValues(board404DeduEsMatColectSaldoQueTransitaParaPerOdosSeg151),
                        type: "number"
                    },
                ],
            ],
        },
        {
            title: "Q05 - Soc. Gestoras De Participações Sociais (Sgps), Soc. De Capital De Risco (Scr) E Investidores De Capital De Risco (Icr)",
            rows: [
                [
                    {
                        label: "501 | Mais-valias Não Tributadas (Ex-art.o 32.o, N.o 2 E Ex-art.o 32.o-a, N.o 1 Do Ebf)",
                        value: checkNumberValues(board404MaisValiasNOTributadasExArt32N2EExArt32AN1Do4),
                        type: "number"
                    },
                    {
                        label: "502 | Menos-valias Fiscais Não Dedutíveis (Ex-artº 32.ºa, N.º1 Do Ebf)",
                        value: checkNumberValues(board404MenosValiasFiscaisNODedutVeisExArt32N2EExArt32AN16),
                        type: "number"
                    },
                ],
            ]
        },
        {
            title: "Q06 - Entidades Licenciadas Na Zona Franca Da Madeira",
            rows: [
                [
                    {
                        label: "Q06-601 - Data Do Licenciamento",
                        value: board404DataDoLicenciamento28 || "N/A",
                        type: "String"
                    },
                    {
                        label: "Q06-602 - Nº de postos de trabalho criados nos primeiros 6 meses de atividade",
                        value: board404NDePostosDeTrabalhoCriadosNos1S6MesesEMantidosNoPer36 || "N/A",
                        type: "number"
                    },
                    {
                        label: "Q06-603 - Investimento efetuado na aquisição de AF tangíveis e intangíveis, nos 2 primeiros anos de atividade",
                        value: checkNumberValues(board404InvestimentoEfetuadoNaAquisiODeAtivosFixosTangEIntang41),
                        type: "number"
                    },
                    {
                        label: "Q06-606 - Nº de postos de trab.criados/mant. (início de PT)",
                        value: board419NDePostosDeTrabCriadosMantNoInCioDoPerOdoDeTributaO64 || "N/A",
                        type: "number"
                    },
                    {
                        label: "Q06-607 - Nº de postos de trab.criados/mant. (final de PT)",
                        value: board419NDePostosDeTrabCriadosMantNoFinalDoPerOdoDeTributaO69 || "N/A",
                        type: "number"
                    },
                ],
            ]
        },
        {
            title: "Q07 - Deduções à Coleta (A deduzir no campo 355 do quadro 10 da declaração)",
            rows: [
                [
                    {
                        label: "Q071-03 - Benefícios Fiscais contratuais ao Inv. - Total: Saldo Caducado",
                        value: checkNumberValues(board434BeneficiosFiscaisContratAoInvTotalSaldoCaducado4),
                        type: "number"
                    },
                    {
                        label: "Q071-701 - Benefícios Fiscais contratuais ao Inv. - Total: Saldo não Deduzido no período anterior",
                        value: checkNumberValues(board427BenefCiosFiscaisContratuaisAoInvestimentoTotalSaldoNO4),
                        type: "number"
                    },
                    {
                        label: "Q071-702 - Benefícios Fiscais contratuais ao Inv. - Total: Dotação do período",
                        value: checkNumberValues(board427BenefCiosFiscaisContratuaisAoInvestimentoTotalDotaODo18),
                        type: "number"
                    },
                    {
                        label: "Q071-703 - Benefícios Fiscais contratuais ao Inv. - Total: Dedução do Período",
                        value: checkNumberValues(board427BenefCiosFiscaisContratuaisAoInvestimentoTotalDeduODo32),
                        type: "number"
                    },
                    {
                        label: "Q071-704 - Benefícios Fiscais contratuais ao Inv. - Total: Saldo que transita para período seguinte",
                        value: checkNumberValues(board427BenefCiosFiscaisContratuaisAoInvestimentoTotalSaldoQue46),
                        type: "number"
                    },
                ],
                [
                    {
                        label: "Q071-A-03 - RETGS - Inf. Adicional - Total: Saldo Caducado Real Decl. Grupo",
                        value: checkNumberValues(board434RetgsInfAdicionalTotalSaldoCaducadoRealDeclGrupo18),
                        type: "number"
                    },
                    {
                        label: "Q071-A-04 - RETGS - Inf. Adicional - Total: Saldo não Deduzido Col. Grupo",
                        value: checkNumberValues(board434RetgsInfAdicionalTotalSaldoNODeduzidoColGrupo32),
                        type: "number"
                    },
                    {
                        label: "Q071-A-05 - RETGS - Inf. Adicional - Total: Dotação do período Decl. Grupo",
                        value: checkNumberValues(board434RetgsInfAdicionalTotalDotaODoPerOdoDeclGrupo46),
                        type: "number"
                    },
                    {
                        label: "Q071-A-06 - RETGS - Inf. Adicional - Total: Dedução Decl. Grupo",
                        value: checkNumberValues(board434RetgsInfAdicionalTotalDeduODeclDeGrupo60),
                        type: "number"
                    },
                    {
                        label: "Q071-A-07 - RETGS - Inf. Adicional - Total: Saldo que transita Decl. Grupo",
                        value: checkNumberValues(board434RetgsInfAdicionalTotalSaldoQueTransitaDeclGrupo74),
                        type: "number"
                    },
                ],
                [
                    {
                        label: "Q072-705 - Projetos de inv. à Inter. - Projetos De Inv. à Inter. - Saldo não deduzido",
                        value: checkNumberValues(board404ProjetosDeInvInternacionalizaOSaldoNODeduzido55),
                        type: "number"
                    },
                    {
                        label: "Q072-706 - Projetos de inv à Inter. - Projetos De Inv. à Inter. - Dotação do período",
                        value: checkNumberValues(board404ProjetosDeInvInternacionalizaODotaODoPerOdo67),
                        type: "number"
                    },
                    {
                        label: "Q072-707 - Projetos de inv à Inter. - Projetos De Inv. à Inter. - Dedução do período",
                        value: checkNumberValues(board404ProjetosDeInvInternacionalizaODeduODoPerOdo79),
                        type: "number"
                    },
                    {
                        label: "Q072-708 - Projetos de inv à Inter. - Projetos De Inv. à Inter. - Saldo que transita",
                        value: checkNumberValues(board404ProjetosDeInvInternacionalizaOSaldoQueTransita91),
                        type: "number"
                    },
                ],
                [
                    {
                        label: "Q73-709 - SIFIDE Total: Saldo não deduzido no período anterior",
                        value: checkNumberValues(board427SifideTotalSaldoNODeduzidoNoPerOdoAnterior60),
                        type: "number"
                    },
                    {
                        label: "Q73-710 - SIFIDE Total: Dotação do período",
                        value: checkNumberValues(board427SifideTotalDotaODoPerOdo74),
                        type: "number"
                    },
                    {
                        label: "Q73-711 - SIFIDE Total: Dedução do período",
                        value: checkNumberValues(board427SifideTotalDeduODoPerOdo88),
                        type: "number"
                    },
                    {
                        label: "Q73-712 - SIFIDE Total: Saldo que transita para período seguinte",
                        value: checkNumberValues(board427SifideTotalSaldoQueTransitaParaPerOdoSSeguinteS102),
                        type: "number"
                    },
                ],
                [
                    {
                        label: "Q073-A-03 - RETGS - Inf. Adicional - Total: Saldo caducado real decl. Grupo",
                        value: checkNumberValues(board436RetgsInfAdicionalTotalSaldoCaducadoRealDeclGrupo18),
                        type: "number"
                    },
                    {
                        label: "Q073-A-04 - RETGS - Inf. Adicional - Total: Saldo não deduzido col. Grupo",
                        value: checkNumberValues(board436RetgsInfAdicionalTotalSaldoNODeduzidoColGrupo32),
                        type: "number"
                    },
                    {
                        label: "Q073-A-05 - RETGS - Inf. Adicional - Total: Dotação do período decl. Grupo",
                        value: checkNumberValues(board436RetgsInfAdicionalTotalDotaODoPerOdoDeclGrupo46),
                        type: "number"
                    },
                    {
                        label: "Q073-A-06 - RETGS - Inf. Adicional - Total: Dedução decl. Grupo",
                        value: checkNumberValues(board436RetgsInfAdicionalTotalDeduODeclGrupo60),
                        type: "number"
                    },
                    {
                        label: "Q073-A-07 - RETGS - Inf. Adicional - Total: Saldo que transita decl. Grupo",
                        value: checkNumberValues(board436RetgsInfAdicionalTotalSaldoQueTransitaDeclGrupo74),
                        type: "number"
                    },
                ],
                [
                    {
                        label: "Q074-03 - Reg. fiscal apoio inv. - Total: Saldo caducado",
                        value: checkNumberValues(board438RegimeFiscalDeApoioAoInvestimentoTotalSaldoCaducado4),
                        type: "number"
                    },
                    {
                        label: "Q074-713 - Reg. fiscal apoio inv. - Total: Saldo não deduzido no período anterior",
                        value: checkNumberValues(board427RegimeFiscalDeApoioAoInvestimentoTotalSaldoNODeduzidoNo116),
                        type: "number"
                    },
                    {
                        label: "Q074-714 - Reg. fiscal apoio inv. - Total: Dotação do período",
                        value: checkNumberValues(board427RegimeFiscalDeApoioAoInvestimentoTotalDotaODoPerOdo130),
                        type: "number"
                    },
                    {
                        label: "Q074-715 - Reg. fiscal apoio inv. - Total: Dedução do período",
                        value: checkNumberValues(board427RegimeFiscalDeApoioAoInvestimentoTotalDeduODoPerOdo144),
                        type: "number"
                    },
                    {
                        label: "Q074-716 - Reg. fiscal apoio inv. - Total: Saldo transita para período seguinte",
                        value: checkNumberValues(board427RegimeFiscalDeApoioAoInvestimentoTotalSaldoQueTransitaPara158),
                        type: "number"
                    },
                ],
                [
                    {
                        label: "Q074-A - Retgs - Inf. Adicional - Total: Saldo Caducado Real Decl. Grupo",
                        value: checkNumberValues(board438RetgsInfAdicionalTotalSaldoCaducadoRealDeclGrupo18),
                        type: "number"
                    },
                    {
                        label: "Q074-A - Retgs - Inf. Adicional - Total: Saldo Não Deduzido Col. Grupo",
                        value: checkNumberValues(board438RetgsInfAdicionalTotalSaldoNODeduzidoColGrupo32),
                        type: "number"
                    },
                    {
                        label: "Q074-A - Retgs - Inf. Adicional - Total: Dotação Do Período Decl. Grupo",
                        value: checkNumberValues(board438RetgsInfAdicionalTotalDotaODoPerOdoDeclGrupo46),
                        type: "number"
                    },
                    {
                        label: "Q074-A - Retgs - Inf. Adicional - Total: Dedução Decl. De Grupo",
                        value: checkNumberValues(board438RetgsInfAdicionalTotalDeduODeclDeGrupo60),
                        type: "number"
                    },
                    {
                        label: "Q074-A - Retgs - Inf. Adicional - Total: Saldo Que Transita Decl. Grupo",
                        value: checkNumberValues(board438RetgsInfAdicionalTotalSaldoQueTransitaDeclGrupo74),
                        type: "number"
                    },
                ],
                [
                    {
                        label: "Q076-722 - Crédito fiscal ext. ao inv. - Saldo não deduzido no período anterior",
                        value: checkNumberValues(board409CrDitoFiscalExtraordinRioAoInvestimentoSaldoNODeduzido16),
                        type: "number"
                    },
                    {
                        label: "Q076-723 - Crédito fiscal ext. ao inv. - Dotação Do Período",
                        value: checkNumberValues(board409CrDitoFiscalExtraordinRioAoInvestimentoSaldoNODeduzido28),
                        type: "number"
                    },
                    {
                        label: "Q076-724 - Crédito Fiscal ext. ao inv. - Dedução do período",
                        value: checkNumberValues(board409CrDitoFiscalExtraordinRioAoInvestimentoDeduODoPerOdo40),
                        type: "number"
                    },
                    {
                        label: "Q076-725 - Crédito Fiscal ext. ao inv. - Saldo que transita para período seguinte",
                        value: checkNumberValues(board409CrDitoFiscalExtraordinRioAoInvestimentoSaldoQueTransita52),
                        type: "number"
                    },
                ],
                [
                    {
                        label: "Q079-793 - IFPC - Saldo não deduzido no período anterior",
                        value: checkNumberValues(board429IfpcTotalSaldoNODeduzidoNoPerOdoAnterior4),
                        type: "number"
                    },
                    {
                        label: "Q079-794 - IFPC - Valor do incentivo no período",
                        value: checkNumberValues(board429IfpcTotalValorDoIncentivoNoPerOdo18),
                        type: "number"
                    },
                    {
                        label: "Q079-795 - IFPC - Dedução do período",
                        value: checkNumberValues(board429IfpcTotalDeduODoPerOdo32),
                        type: "number"
                    },
                    {
                        label: "Q079-796 - IFPC - Saldo que transita para período seguinte",
                        value: checkNumberValues(board429IfpcTotalSaldoQueTransitaParaPerOdoSSeguinteS46),
                        type: "number"
                    },
                    {
                        label: "Q079-797 - IFPC - Valor a reembolsar",
                        value: checkNumberValues(board429IfpcTotalValorAReembolsar60),
                        type: "number"
                    },
                    {
                        label: "Q079-798 - IFPC - Valor a repôr",
                        value: checkNumberValues(board429IfpcTotalValorARepRATransportarParaOC372DoQ10Da74),
                        type: "number"
                    },
                ],
                [
                    {
                        label: "Q075-717 - Outras deduções à coleta: Incentivos fiscais aos lucros reinvestidos na RAM",
                        value: checkNumberValues(board405IncentivosFiscaisAosLucrosReinvestidosNaRam4),
                        type: "number"
                    },
                    {
                        label: "Q075-726 - Outras deduções à coleta: Incentivos fiscais aos lucros reinvestidos na RAA",
                        value: checkNumberValues(board408IncentivosFiscAosLucrosReinvestidosNaRaaArt6DeDl89),
                        type: "number"
                    },
                    {
                        label: "Q075-718 - Outras deduções à coleta: Entidades licenciadas na ZFM",
                        value: checkNumberValues(board405EntidadeLicenciadasNaZonaFrancaDaMadeira36AN6DoEbf16),
                        type: "number"
                    },
                    {
                        label: "Q075-719 - Outras deduções à coleta: Sociedades de capital de risco e investidores de capital de risco",
                        value: checkNumberValues(board405SocDeCapitalDeRiscoScrEInvestidoresDeCapitalDeRiscoIcr28),
                        type: "number"
                    },
                    {
                        label: "Q075-727 - Outras deduções à coleta: Dedução por lucros retidos e reinvestidos pelas PME",
                        value: checkNumberValues(board408DeduOPorLucrosRetidosEReinvestidosPelasPmeEArtS27A34Do101),
                        type: "number"
                    },
                    {
                        label: "Q075-728 - Outras deduções à coleta: Dedução de 50% à coleta pelas entidades licenciadas na ZFM",
                        value: checkNumberValues(board420DeduODe50ColetaPelasEntidadesArt36AN6DoEbf40),
                        type: "number"
                    },
                    {
                        label: "Q075-720 - Outras deduções à coleta: Outros",
                        value: checkNumberValues(board40540),
                        type: "number"
                    },
                    {
                        label: "Q075-721 - Outras deduções à coleta: Total das Deduções (703+707+711+715+724+795+717+726+718+719+727+728+720)",
                        value: checkNumberValues(board405TotalDeDeduEs52),
                        type: "number"
                    },
                ],
                [
                    {
                        label: "Q077-730 - Trans. de benefícios fiscais da soc.fundida ou da soc. contribuidora - Total: Saldo Benef.transmitidos",
                        value: checkNumberValues(board438TransmBenefFiscSocFundidaTotalSaldoBenefTransmitidos88),
                        type: "number"
                    },
                    {
                        label: "Q077-731 - Trans. de benefícios fiscais da soc.fundida ou da soc. contribuidora - Total: Dotação Do Período.",
                        value: checkNumberValues(board438TransmBenefFiscSocFundidaTotalDotaODoPerOdo102),
                        type: "number"
                    },
                ],
                [
                    {
                        label: "Q078 - Inc. sujeitos às taxas máximas de auxílios regionais - Microentidade ?",
                        value: board420IndiqueSeSeQualificaComoMicroentidadeNosTermos52 || "N/A",
                        type: "string"
                    },
                ]
            ]
        },
        {
            title: "Q09 - Incentivos fiscais sujeitos à regra de Minimis",
            rows: [
                [
                    {
                        label: "Q09 - N-2, Incentivos de anos anteriores - ano",
                        value: checkNumberValues(board407N2IncentivosDeAnosAnterioresAno4),
                        type: "number"
                    },
                    {
                        label: "Q09-901 - N-2, Incentivos de anos anteriores - valor",
                        value: checkNumberValues(board407N2IncentivosDeAnosAnterioresValor8),
                        type: "number"
                    },
                    {
                        label: "Q09 - N-1, Incentivos de anos anteriores - ano",
                        value: checkNumberValues(board407N1IncentivosDeAnosAnterioresAno20),
                        type: "number"
                    },
                    {
                        label: "Q09-902 - N-1, Incentivos de anos anteriores - valor",
                        value: checkNumberValues(board407N1IncentivosDeAnosAnterioresValor24),
                        type: "number"
                    },
                ],
            ],
        },
        {
            title: "Q09 - Incentivos Do Ano",
            rows: [
                [
                    {
                        label: "Q09-903 - Incentivos de natureza não fiscal",
                        value: checkNumberValues(board407IncentivoDeNaturezaNOFiscal36),
                        type: "number"
                    },
                    {
                        label: "Q09-904A - Remuneração convencional do capital social (Lei n.o 55-A/2010, de 31/12 e art.o 41.o-A do EBF) x taxa do IRC",
                        value: checkNumberValues(board409RemuneraOConvencionalDoCapitalSocial64),
                        type: "number"
                    },
                    {
                        label: "Q09-904B - Redução da taxa do IRC aplicável às PME, aos primeiros € 15.000,00 de matéria coletável (art.o 87.o, n.o 2 do CIRC)",
                        value: checkNumberValues(board409ReduODeTaxaDoIrcAplicVelSPmeAos1S15000DeMat76),
                        type: "number"
                    },
                    {
                        label: "Q09-904C - Redução de taxa - benefícios à interioridade (ex-art.o 43.o e art.o 41.o-B do EBF)",
                        value: checkNumberValues(board409ReduODeTaxaBenefCiosInterioridade88),
                        type: "number"
                    },
                ],
                [
                    {
                        label: "Q09-904D - Despesas com projeto de investimento produtivo (art.o 18.o, n.o 1 , al. b) e n.o 5 do CFI, revogado pelo Dec.-Lei n.o 162/2014, de 31/10) x taxa do IRC",
                        value: checkNumberValues(board409DespesasComProjetoDeInvestimentoProdutivo100),
                        type: "number"
                    },
                    {
                        label: "Q09-904E - Derrama municipal (art.o 18.o, n.o 25 da Lei n.o73/2013, de 3 de setembro)",
                        value: checkNumberValues(board409DerramaMunicipal112),
                        type: "number"
                    },
                    {
                        label: "Q09-904 - Total Dos Incentivos Do Ano De Natureza Fiscal (904-a + 904-b + 904-c + 904-d + 904-e)",
                        value: checkNumberValues(board407TotalDosIncentivosDoAnoDeNatFiscal904A904B904C904D48),
                        type: "number"
                    },
                    {
                        label: "Q09-905 - Total Dos Incentivos Do Triénio (901+ 902 + 903 + 904)",
                        value: checkNumberValues(board407TotalDeIncentivosDoTriNio90190290390460),
                        type: "number"
                    },
                    {
                        label: "Q09-906 - Irc A Regularizar (A Indicar No Campo 372 Do Quadro 10 Da Declaração)",
                        value: checkNumberValues(board407IrcARegularizar73),
                        type: "number"
                    },
                ],
            ],
        },
        {
            title: "Q10 - Incentivos Fiscais à interioridade ligados ao investimento sujeitos às taxas máximas de auxílios regionais (Ex-art.o 43.o Do Ebf)",
            rows: [
                [
                    {
                        label: "Q10-1001 - Investimentos elegíveis - Tangível",
                        value: checkNumberValues(board407InvestimentosElegVeisTangVel85),
                        type: "number"
                    },
                    {
                        label: "Q10-1002 - Investimentos elegíveis - Intangível",
                        value: checkNumberValues(board407InvestimentosElegVeisIntangVel97),
                        type: "number"
                    },
                    {
                        label: "Q10-1003 - Investimentos elegíveis - Total",
                        value: checkNumberValues(board407InvestimentosElegVeisTotal109),
                        type: "number"
                    },
                    {
                        label: "Q10-1004 - Redução dos encargos com a segurança social X (1 - taxa do IRC)",
                        value: checkNumberValues(board407ReduODeEncargosComASegSocX1TxDoIrc121),
                        type: "number"
                    },
                ],
                [
                    {
                        label: "Q10-1005 - Majoração das depreciações (Majoração)",
                        value: checkNumberValues(board407MajoraODasDepreciaEsMajoraO133),
                        type: "number"
                    },
                    {
                        label: "Q10-1006 - Majoração das depreciações (Taxa do IRC)",
                        value: board407MajoraODasDepreciaEsTaxaDoIrc145 || "N/A",
                        type: "percentage"
                    },
                    {
                        label: "Q10-1007 - Majoração das depreciações (Valor do auxílio)",
                        value: checkNumberValues(board407MajoraODasDepreciaEsValorDoAuxLio149),
                        type: "number"
                    },
                ],
                [
                    {
                        label: "Q10-1008 - Majorações dos encargos com a segurança social (Majoração)",
                        value: checkNumberValues(board408MajoraOComOsEncargosComASegSocialMajoraO4),
                        type: "number"
                    },
                    {
                        label: "Q10-1009 - Majorações dos encargos com a segurança social (Taxa do IRC)",
                        value: board408MajoraOComOsEncargosComASegSocialTaxaDoIrc16 || "N/A",
                        type: "percentage"
                    },
                    {
                        label: "Q10-1010 - Majorações dos encargos com a segurança social (Valor do auxílio)",
                        value: checkNumberValues(board408MajoraOComOsEncargosComASegSocialValorDoAuxLio20),
                        type: "number"
                    },
                ],
                [
                    {
                        label: "Q10-1011 - Majorações do crédito fiscal ao investimento",
                        value: checkNumberValues(board408MajoraODoCrDitoFiscalAoInvestimento32),
                        type: "number"
                    },
                    {
                        label: "Q10-1012 - Outros",
                        value: checkNumberValues(board408Outros44),
                        type: "number"
                    },
                    {
                        label: "Q10-1013 - Total dos Auxílios (1004+1007+1010+1011+1012)",
                        value: checkNumberValues(board408TotalDosAuxLios1004100710101011101256),
                        type: "number"
                    },
                ],
                [
                    {
                        label: "Q10-1014 - Taxa de auxílio",
                        value: board408TaxaDeAuxLio69 || "N/A",
                        type: "percentage"
                    },
                    {
                        label: "Q10-1015 - Taxa máxima legal aplicável",
                        value: board408TaxaMXimaLegalAplicVel73 || "N/A",
                        type: "percentage"
                    },
                    {
                        label: "Q10-1016 - Excesso a Regularizar (a transportar para o campo 372 do quadro 10 da declaração)",
                        value: checkNumberValues(board408ExcessoARegularizar77),
                        type: "number"
                    },
                ],
            ],
        },
    ]
}

const mapper = (json, card, details) => {
    return {
        ...json,
        name: json.declarationtype,
        shortName: `${json.period}`,
        details: details ? mapDetails(json) : []
    };
};

const mod22dannexMapper = {
    map: mapper
};

export default mod22dannexMapper;