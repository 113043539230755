import { format, date } from '../../../utils'

const mapDetails = (json) => {
    const {
        lineNumber,
        invoiceType,
        quantity,
        invoiceUOM,
        debitCreditIndicator,
        debitAmount,
        creditAmount,
        invoiceLineAmount,
        invoiceLineCurrencyAmount,
        shippingCostsAmount,
        shippingCostsCurrencyAmount,
        invoiceDate,
        taxPointDate,
        description,
        generalLedgerAccountRowKey,
        accountId,
        productRowKey,
        productCode,
        purchaseInvoiceId
    } = json
    return [
        [
            { label: 'Invoice No', value: purchaseInvoiceId, type: 'string' },
            { label: 'Line Number', value: lineNumber, type: 'string' },
            { label: 'Type', value: invoiceType, type: 'string' },
            { label: 'Quantity', value: quantity, type: 'number' },
            { label: 'UOM', value: invoiceUOM, type: 'string' },
            { label: 'Debit Credit Indicator', value: debitCreditIndicator, type: 'string' }
        ],
        [
            { label: 'Debit Amount', value: format(debitAmount, 'fullValue'), type: 'currency' },
            { label: 'Credit Amount', value: format(creditAmount, 'fullValue'), type: 'currency' },
            { label: 'Amount', value: format(invoiceLineAmount, 'fullValue'), type: 'currency' },
            { label: 'Currency Amount', value: format(invoiceLineCurrencyAmount, 'fullValue'), type: 'currency' },
            { label: 'Shipping Costs Amount', value: format(shippingCostsAmount, 'fullValue'), type: 'currency' },
            { label: 'Shipping Costs Currency Amount', value: format(shippingCostsCurrencyAmount, 'fullValue'), type: 'currency' }
        ],
        [
            { label: 'Date', value: date(invoiceDate), type: 'date' },
            { label: 'Tax Point Date', value: date(taxPointDate), type: 'date' },
            generalLedgerAccountRowKey ?
                { label: 'Account', value: { id: generalLedgerAccountRowKey, name: accountId, __typename: 'GeneralLedger' }, type: 'object' } :
                { label: 'Account', value: accountId || 'N/A', type: 'string' },
            productRowKey ?
                { label: 'Product', value: { id: productRowKey, name: productCode, __typename: 'Product' }, type: 'object' } :
                { label: 'Product', value: productCode || 'N/A', type: 'string' },
            { label: 'Description', value: description, type: 'string' }
        ]
    ]
}

const header = [
    { label: ["Line Number"], align: 'left', basis: '15%' },
    { label: ["CODE"], align: 'center', basis: '30%' },
    { label: ["Account Id"], align: 'left', columnName: "accountId", basis: '30%' },
    { label: ["Description"], align: 'left', columnName: "description", basis: '60%' },
    { label: ["Quantity"], align: 'center', columnName: "quantity", sortable: true, basis: '30%' },
    { label: ["Unit Price"], align: 'center', columnName: "unit_price", sortable: true, basis: '40%' },
    { label: ["Debit"], align: 'right', columnName: "debit_amount", sortable: true, basis: '35%' },
    { label: ["Credit"], align: 'right', columnName: "credit_amount", sortable: true, basis: '35%' },
    { label: ["Debit Credit Indicator"], align: 'center', columnName: "debit_credit_indicator", sortable: true, basis: '30%' },
]

const mapCard = (json) => {
    const {
        lineNumber,
        description,
        productCode,
        debitAmount,
        quantity,
        creditAmount,
        accountId,
        unitPrice,
        debitCreditIndicator
    } = json
    return [
        [
            { labels: ['number'], value: lineNumber, type: 'string', basis: '15%' }
        ],
        [
            { labels: ['code'], value: productCode || 'N/A', type: 'number', basis: '30%' }
        ],
        [
            { labels: ['accountId'], value: accountId, type: 'string', basis: '30%' }
        ],
        [
            { labels: ['description'], value: description, type: 'string', basis: '60%' }
        ],
        [
            { labels: ['quantity'], value: format(quantity, null, null), type: 'number', basis: '30%' }
        ],
        [
            { labels: ['unitPrice'], value: format(unitPrice), type: 'number', basis: '40%' }
        ],
        [
            { labels: ['debit_amount'], value: format(debitAmount), type: 'currency', basis: '35%', align: 'right' }
        ],
        [
            { labels: ['credit_amount'], value: format(creditAmount), type: 'currency', basis: '35%' }
        ],
        [
            { labels: ['debit_credit_indicator'], value: debitCreditIndicator, type: 'string', basis: '30%', align: 'center' }
        ],
    ]
}

const mapper = (json, card, details) => {
    const name = json.description

    return {
        ...json,
        __typename: json.__typename,
        id: json.id,
        name,
        shortName: name,
        details: details ? mapDetails(json) : [],
        card: card ? mapCard(json) : []
    }
}

const purchaseinvoicelineMapper = {
    map: mapper,
    header
}

export default purchaseinvoicelineMapper;