import React from "react";
import { useGlobalValue } from "colbi_web_ui/lib/state/GlobalProvider";
import styles from "./SubmitSalesInfo.module.sass";
import { ActionButton } from "colbi_web_ui/lib/components/inputs";
import I18n from "../../I18n";

const SubmitSalesInfo = () => {
	const { pushDialog, params, sendSalesInformationEmail } = useGlobalValue();

	const { projectId, fiscalYear, importId } = params;

	return (
		<div>
			<div className={`${styles["export-button"] || ""}`}>
				<ActionButton
					primary
					onClick={() => {
						pushDialog &&
							pushDialog("ConfirmDialog", I18n.translate`Sales submit`, {
								title: I18n.translate`Do you want to submit the sales information?`,

								text: I18n.translate`Please confirm that you want to proceed!`,
							}).then(() => {
								sendSalesInformationEmail(projectId, fiscalYear, importId);
							});
					}}
				>
					{I18n.translate`Submit Sales Info`}
				</ActionButton>
			</div>
		</div>
	);
};

export default SubmitSalesInfo;
