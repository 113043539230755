import { getFirstModuleOfArray } from '../routes/manageRouting'

export function mapProjectsByOrganization(organization, projectId) {
	const tempProjects = (organization.projects || []).sort(({ name: a }, { name: b }) => (a.localeCompare(b))).map(project => {
        let projectObj = undefined;
        //check if navigate by fiscalYear
        if (project && project.fiscalYears && Object.keys(project.fiscalYears).length > 0) {
            const mostRecentFiscalYear = Math.max(...Object.keys(project.fiscalYears))
            const projectModules = project && project.modules
            const findFirstModule = getFirstModuleOfArray((projectModules || []).filter(r => r.name !== "repository").map(elem => elem.name))
            const moduleFound = (projectModules || []).find(elem => elem.name === findFirstModule)
            //found an matched modules and not default (repository)
            let typeOfNavigation = "full"
            let moduleOrRouteToUse = ""
            if (moduleFound){
                moduleOrRouteToUse = moduleFound.name
                if(moduleFound.navigation_by_import_id) {
                    const filterFullImportId = project.fiscalYears[mostRecentFiscalYear].filter(({id} )=> id !== "full").map(({ id }) => id)
                    if(filterFullImportId && filterFullImportId.length){
                        typeOfNavigation = Math.max(...filterFullImportId)
                    }
                }
                if(moduleFound.default_route && moduleFound.default_route.length){
                    moduleOrRouteToUse = moduleFound.default_route
                }

                projectObj = organization.organizationExpired === 0 && organization.organizationActive === 1 && project.projectExpired === 0 && project.active === 1 ? {
                    ...project,
                    to: `/${project.id}/${mostRecentFiscalYear}/${typeOfNavigation}/${moduleOrRouteToUse}`,
                    isSelected: project.id === projectId
                } : {
                    ...project
                }
            }
        } 
        
        if(!projectObj) {
            projectObj = organization.organizationExpired === 0 && organization.organizationActive === 1 && project.projectExpired === 0 && project.active === 1 ? {
                ...project,
                to: `/${project.id}/repository/activity/status`,
                isSelected: project.id === projectId
            } : {
                ...project
            }
        }
        return {...projectObj, type: 'project'}
    })
	return tempProjects
}