import I18n from '../../../I18n'
import { format, date } from '../../../utils'

const mapDetails = (json) => {
	const {
		refNumber,
		type,
		period,
		invoiceDate,
		grossTotal,
		netTotal,
		supplierId,
		supplierRowKey,
		systemId
	} = json
	return [
		[
			{label: 'Invoice No', value: refNumber, type: 'string'},
			{ label: 'Type', value: type, type: 'string' },
			{ label: 'System ID', value: systemId, type: 'string' },
			{ label: 'Period', value: period, type: 'number' },
			{ label: 'Invoice Date', value: date(invoiceDate), type: 'date' },
			supplierRowKey ? { label: 'Supplier', value: { id: supplierRowKey, name: supplierId, __typename: 'ErpSupplier' }, type: 'object' } : null,
		],
		[
			{ label: 'Gross Total', value: format(grossTotal, 'fullValue'), type: 'currency' },
			{ label: 'Net Total', value: format(netTotal, 'fullValue'), type: 'currency' }
		]
	]
}

const mapCard = (json) => {
	const {
		refNumber,
		type,
		invoiceDate,
		grossTotal,
		netTotal,
		supplierId,
		supplierRowKey,
		systemId,
		period
	} = json

	return [
		[ { labels: ['id'], value: refNumber, type: 'string' } ],
		[ { labels: ['type'], value: I18n.translate`${ type }`, type: 'number' } ],
		[ { labels: ['Period'], value: period, type: 'number' } ],
		[ { labels: ['systemId'], value: systemId, type: 'string' } ],
		supplierRowKey ? [
			{
				labels: ['Supplier'],
				value: {
					id: supplierRowKey,
					name: supplierId,
					__typename: 'ErpSupplier'
				},
				type: 'object',
				mobileHidden: true
			}
		] : [
			{ labels: ['Supplier'], value: 'N/A', type: 'string',  mobileHidden: true }
		],
		[ { labels: ['date'], value: date(invoiceDate), type: 'date' } ],
		[ { labels: ['net'], value: format(netTotal), type: 'currency' } ],
		[ { labels: ['gross'], value: format(grossTotal), type: 'currency' } ]
	]
}

const mapper = (json, card, details) => {
	return {
		...json,
		name: json.refNumber,
		shortName: json.refNumber,
		details: details ? mapDetails(json) : [],
		card: card ? mapCard(json) : []
	}
}

const header = [
	{ label: ["Invoice No"], align: 'left', columnName: "invoice_no", sortable: true },
	{ label: ["Type"], align: 'center', columnName: "invoice_type", sortable: true },
	{ label: ["Period"], align: 'center', columnName: "period", sortable: true },
	{ label: ["System Id"], align: 'left', columnName: "system_id" },
	{ label: ["Supplier"], align: 'left',columnName: "supplier_id" },
	{ label: ["Date"], align: 'center', columnName: "invoice_date", sortable: true },
	{ label: ["Net Total"], align: 'right', columnName: "net_total", sortable: true  },
	{ label: ["Gross Total"], align: 'right', columnName: "gross_total", sortable: true }
]

const purchaseinvoiceMapper = {
	map: mapper,
	header
}

export default purchaseinvoiceMapper;