import { format } from "../../../utils";

const header = [
	{ label: ["Account ID"], align: "left", basis: '15%' },
	{ label: ["Account Table ID"], align: "left" },
	{ label: ["Description"], align: "left", basis: '90%' },
	{ label: ["Opening Debit Balance"], align: "right", columnName: "opening_debit_balance", sortable: true, totalizerName: "total_opening_debit_balance" },
	{ label: ["Opening Credit Balance"], align: "right", columnName: "opening_credit_balance", sortable: true, totalizerName: "total_opening_credit_balance" },
	{ label: ["Closing Debit Balance"], align: "right", columnName: "closing_debit_balance", sortable: true, totalizerName: "total_closing_debit_balance" },
	{ label: ["Closing Credit Balance"], align: "right", columnName: "closing_credit_balance", sortable: true,  totalizerName: "total_closing_credit_balance"}
];

const mapDetails = (json) => {
	const {
		accountId,
		accountTableId,
		accountType,
		description,
		openingDebitBalance,
		openingCreditBalance,
		closingDebitBalance,
		closingCreditBalance
	} = json
	return [
		[
			{ label: 'Account ID', value: accountId, type: 'string' },
			{ label: 'Account Table ID', value: accountTableId, type: 'string' },
			{ label: 'Account Type', value: accountType, type: 'string' },
			{ label: 'Description', value: description, type: 'string' },
			{ label: 'Account Table Description', value: accountTableId, type: 'string' },
			{ label: 'Opening Debit Balance', value: format(openingDebitBalance, 'fullValue'), type: 'currency' },
			{ label: 'Opening Credit Balance', value: format(openingCreditBalance, 'fullValue'), type: 'currency' },
			{ label: 'Closing Debit Balance', value: format(closingDebitBalance, 'fullValue'), type: 'currency' },
			{ label: 'Closing Credit Balance', value: format(closingCreditBalance, 'fullValue'), type: 'currency' }
		]
	]
}

const mapCard = json => {
	const {
		accountId,
		accountTableId,
		description,
		openingDebitBalance,
		openingCreditBalance,
		closingDebitBalance,
		closingCreditBalance
	} = json;

	return [
		[{ labels: ["accountId"], value: accountId || "N/A", type: "string" , basis: '15%'}],
		[
			{
				labels: ["accountTableId"],
				value: accountTableId || "N/A",
				type: "string"
			}
		],
		[{ labels: ["description"], value: description, type: "string", basis: '90%' }],
		[
			{
				labels: ["openingDebitBalance"],
				value: format(openingDebitBalance),
				type: "currency"
			}
		],
		[
			{
				labels: ["openingCreditBalance"],
				value: format(openingCreditBalance),
				type: "currency"
			}
		],
		[
			{
				labels: ["closingDebitBalance"],
				value: format(closingDebitBalance),
				type: "currency"
			}
		],
		[
			{
				labels: ["closingCreditBalance"],
				value: format(closingCreditBalance),
				type: "currency"
			}
		]
	];
};

const mapper = (json, card,details) => {
	const name = json.account_id;

	return {
		...json,
		__typename: json.__typename,
		id: json.id,
		name,
		shortName: name,
		details: details ? mapDetails(json) : [],
		card: card ? mapCard(json) : []
	};
};
const generalledgeraccountMapper = {
	map: mapper,
	header
};

export default generalledgeraccountMapper;
