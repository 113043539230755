import I18n from '../../../I18n'
import { format, date } from '../../../utils'

const mapDetails = (json) => {
	const {
		type,
		period,
		invoiceDate,
		taxPayable,
		grossTotal,
		netTotal,
		supplierId,
		supplierRowKey,
		accountId,
		glPostingDate,
		refNumber,
		taxEntity,
		transaction
	} = json
	return [
		[
			{ label: 'Invoice No', value: refNumber, type: 'string' },
			{ label: 'Account ID', value: accountId, type: 'string' },
			{ label: 'Entity', value: taxEntity, type: 'string' },
			{ label: 'Type', value: type, type: 'string' },
			{ label: 'Period', value: period, type: 'number' },
			{ label: 'Invoice Date', value: date(invoiceDate), type: 'date' },
			{ label: 'GL Posting Date', value: date(glPostingDate), type: 'date' },
		],
		[
			{ label: 'Gross Total', value: format(grossTotal, 'fullValue'), type: 'currency' },
			{ label: 'Net Total', value: format(netTotal, 'fullValue'), type: 'currency' },
			{ label: 'Tax Payable', value: format(taxPayable, 'fullValue'), type: 'currency' }
		],
		[
			supplierRowKey ? { label: 'Supplier', value: { id: supplierRowKey, name: supplierId, __typename: 'Supplier' }, type: 'object' } : null,
			transaction ? { label: 'Transaction', value: { id: transaction.id, name: transaction.name, __typename: 'Transaction' }, type: 'object' } : null,
		],
	]
}

const mapCard = (json) => {
	const {
		refNumber,
		numberOfLines,
		type,
		invoiceDate,
		taxPayable,
		grossTotal,
		netTotal,
		country,
		period,
		supplierId,
		supplierRowKey,
		transaction
	} = json

	return [
		[{ labels: ['id'], align: 'left', value: refNumber, type: 'string', basis: "15%" }],
		[{ labels: ['type'], align: 'center', value: I18n.translate`${type}`, type: 'number' }],
		[{ labels: ['date'], value: date(invoiceDate), type: 'date', align: 'center' }],
		supplierRowKey ? [
			{
				labels: ['Supplier'],
				value: {
					id: supplierRowKey,
					name: supplierId,
					__typename: 'Supplier'
				},
				type: 'object',
				mobileHidden: true,
				basis: "40%"
			}
		] : [
			{ labels: ['Supplier'], value: 'N/A', type: 'string',  mobileHidden: true, basis: "40%" }
		],
		[{ labels: ['country'], align: 'center', value: I18n.translate`${country}`, type: 'string' }],
		[{ labels: ['period'], value: I18n.translate`${period}`, type: 'number' }],
		transaction && transaction.id && transaction.name ? 
			[{ labels: ['transaction'], align: 'left', value: { id: transaction.id, name: transaction.name, __typename: 'Transaction' }, type: 'object', mobileHidden: true, basis: "20%" }] 
			: [{ labels: ['transaction'], align: 'left', value: `${transaction && transaction.name ? transaction.name : "N/A"}`, type: 'string', basis: "20%" }],
		[{ labels: ['lines'], value: numberOfLines, type: 'number', align: 'center', basis: '10%' }],
		[{ labels: ['net'], value: format(netTotal), type: 'currency',basis: '15%' }],
		[{ labels: ['tax'], value: format(taxPayable), type: 'currency', mobileHidden: true,basis: '15%' }],
		[{ labels: ['gross'], value: format(grossTotal), type: 'currency',basis: '15%' }]
	]
}

const mapper = (json, card, details) => {
	return {
		...json,
		name: json.refNumber,
		shortName: json.refNumber,
		details: details ? mapDetails(json) : [],
		card: card ? mapCard(json) : []
	}
}

const header = [
	{ label: ["Invoice No"], align: 'left', columnName: "invoice_no", sortable: true, basis: "15%" },
	{ label: ["Type"], align: 'center', columnName: "invoice_type", sortable: true },
	{ label: ["Date"], align: 'center', columnName: "invoice_date", sortable: true },
	{ label: ["Supplier Name"], align: 'left', columnName: "supplierId", basis: "40%" },
	{ label: ["Country"], align: 'center' },
	{ label: ["Period"], align: 'center', columnName: "period", sortable: true, basis: '13%'},
	{ label: ["Transaction"], align: 'center', mobileHidden: true, basis: "20%" },
	{ label: ["lines"], align: 'center', columnName: "number_of_lines", sortable: true,basis: '10%' },	
	{ label: ["Net Total"], align: 'right', columnName: "document_totals_net_total",sortable: true,basis: '15%' },
	{ label: ["Tax Payable"], align: 'right', mobileHidden: true, columnName: "document_totals_tax_payable",sortable: true,basis: '15%' },
	{ label: ["Gross Total"], align: 'right', columnName: "document_totals_gross_total",sortable: true,basis: '15%' }
]

const purchaseinvoiceMapper = {
	map: mapper,
	header
}

export default purchaseinvoiceMapper;