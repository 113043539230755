const mapCard = (json) => {
    const {
        unitOfMeasure,
        description
    } = json

    return [
        [
            { labels: ['unit_of_measure'], value: unitOfMeasure, type: 'string' }
        ],
        [
            { labels: ['description'], value: description, type: 'string' }
        ]
    ]
}

const mapper = (json, card) => {
    const name = json.unit_of_measure

    return {
        ...json,
        __typename: json.__typename,
        id: '',
        name,
        shortName: name,
        card: card ? mapCard(json) : []
    }
}

const header = [
    { label: ["Unit of Measure"], align: 'left' },
    { label: ["Description"], align: 'left' }
]

const uomtableentryMapper = {
    map: mapper,
    header
}
export default uomtableentryMapper;