import fetch from './fetchReducer'
import search from './searchReducer'
import modals from './modalsReducer'
import dialog from './dialogReducer'
import modalsData from './modalsDataReducer'
import uploads from './uploadsReducer'
import downloads from './downloadsReducer'
import menu from './menuReducer'
import locale from './localeReducer'
import auditNote from './auditNoteSubmit'
import { user, loginError, resetPasswordError, authToken, forgetPasswordMessage } from './userReducer'

const index = {
	locale,
	search,
	fetch,
	modals,
	dialog,
	modalsData,
	menu,
	uploads,
	downloading: downloads,
	user,
	loginError,
	resetPasswordError,
	authToken,
	auditNote,
	forgetPasswordMessage
}
export default index