const mapDetails = json => {
    const { supplierId,
        taxRegistrationNumber,
        taxRegistrationNumber1,
        supplierName,
        streetName,
        city,
        postalCode,
        region,
        telephone,
        fax,
        accountid,
        toRemove,
        tstcd2,
        country } = json;

    return [
        [
            { label: 'Supplier Id', value: supplierId, type: 'string' },
            { label: 'Entity Name', value: supplierName, type: 'string' },
            { label: 'tax Registration Number', value: taxRegistrationNumber, type: 'string' },
            { label: 'tax Registration Number 1', value: taxRegistrationNumber1, type: 'string' },
            { label: 'account ID', value: accountid, type: 'string' },
            { label: 'tstcd2', value: tstcd2, type: 'string' }
        ], [
            { label: 'telephone', value: telephone, type: 'string' },
            { label: 'fax', value: fax, type: 'string' }
        ], [
            { label: 'street', value: streetName, type: 'string' },
            { label: 'city', value: city, type: 'string' },
            { label: 'postal Code', value: postalCode, type: 'string' },
            { label: 'region', value: region, type: 'string' },
            { label: 'country', value: country, type: 'string' }
        ], [
            { label: 'toRemove', value: toRemove, type: 'string' },
        ]
    ];
};

const mapCard = json => {
    const {
        supplierId,
        supplierName,
        taxRegistrationNumber,
        country
    } = json;

    return [
        [{ labels: ["Supplier Id"], value: supplierId, type: "string", basis: "12%" }],
        [{ labels: ["Supplier Name"], value: supplierName, type: "string", basis: "50%" }],
        [{ labels: ["nif"], value: `${taxRegistrationNumber}`, type: "string" }],
        [{ labels: ["country"], value: `${country}`, type: "string" }],
    ];
};

const mapper = (json, card, details) => {

    return {
        ...json,
        name: json.supplierId,
        shortName: json.supplierId,
        details: details ? mapDetails(json) : [],
        card: card ? mapCard(json) : []
    };
};

const header = [
    { label: ['Supplier id'], align: 'left', columnName: "supplier_id", sortable: true, basis: "12%" },
    { label: ['Entity Name'], align: 'left', columnName: "supplier_name", sortable: true, basis: "50%" },
    { label: ['Registration Number'], align: 'left', columnName: "taxRegistrationNumber" },
    { label: ['country'], align: 'left', columnName: "country" },
]

const supplierMapper = {
    map: mapper,
    header
}

export default supplierMapper;

