const mapDetails = (json) => {
	const { documentNumber, recordId, accountId, customerId, supplierId, description, debitCreditIndicator, amount, taxCode, taxBase, currencyAmount, lineItemType, postingKey, taxRegistrationNumber, country } = json

	return [
		[
			{ label: 'Invoice No.', value: documentNumber, type: 'string' },
			{ label: 'Record ID', value: recordId, type: 'string' },
			{ label: 'Account ID', value: accountId, type: 'string' },
			(customerId || supplierId) ? { label: customerId ? 'Customer ID' : 'Supplier ID', value: customerId ? customerId : supplierId, type: 'string' } : null,
			{ label: 'Description', value: description, type: 'string' },
			{ label: 'Tax Code', value: taxCode, type: 'string' },
			{ label: 'Line Item Type', value: lineItemType, type: 'string' },
			{ label: 'Posting Key', value: postingKey, type: 'string' },
			{ label: 'Tax Registration Number', value: taxRegistrationNumber, type: 'string' },
			{ label: 'country', value: country, type: 'string' },

		],
		[
			{ label: 'Debit Credit Indicator', value: debitCreditIndicator, type: 'string' },
			{ label: 'Amount', value: amount, type: 'currency' },
			{ label: 'Tax Base', value: `${taxBase}`, type: 'string' },
			{ label: 'Currency Amount', value: currencyAmount, type: 'currency' },
		]
	]

}

const mapCard = (json) => {
	const {
		recordId, accountId, description, debitCreditIndicator, amount, taxCode
	} = json
	return [
		[
			{ labels: ['record Id'], value: recordId, type: 'string' }
		], [
			{ labels: ['Description'], value: description, type: 'string' }
		], [
			{ labels: ['Debit Credit Indicator'], value: debitCreditIndicator, type: 'string' }
		], [
			{ labels: ['Tax Code'], value: taxCode, type: 'string' }
		], [
			{ labels: ['Account ID'], value: accountId, type: 'string' }
		], [
			{ labels: ['Amount'], value: amount, type: 'currency' }
		]
	]
}

const mapper = (json, card, details) => {
	return {
		...json,
		name: json.documentNumber,
		shortName: json.documentNumber,
		details: details ? mapDetails(json) : [],
		card: card ? mapCard(json) : []
	}
}

const header = [
	{ label: ["record Id"], align: 'left', columnName: "record_id", sortable: true },
	{ label: ["Description"], align: 'left', columnName: "description", sortable: true },
	{ label: ["Debit Credit Indicator"], align: 'center', columnName: "debit_credit_indicator", sortable: true },
	{ label: ["Tax Code"], align: 'center', columnName: "tax_code", sortable: true },
	{ label: ["Account ID"], align: 'center', columnName: "account_id", sortable: true },
	{ label: ["Amount"], align: 'right', columnName: "amount", sortable: true }
]

const purchaseinvoicelineMapper =  {
	map: mapper,
	header
}
export default purchaseinvoicelineMapper;