const mapCard = (json) => {
	const {
		SapDocNo,
		SapDocType,
		HsnCode,
		Quantity,
		UnitOfMeasure,
		UnitPrice,
		TaxableAmount,
		CgstRate,
		CgstAmount,
		IgstRate,
		IgstAmount,
		SgstRate,
		SgstAmount,
	} = json;

	return [
		[{ labels: ["SapDocNo"], value: SapDocNo, type: "string", basis: "25%" }],
		[{ labels: ["SapDocType"], value: SapDocType, type: "string" }],
		[{ labels: ["HsnCode"], value: HsnCode, type: "string" }],
		[{ labels: ["Quantity"], value: Quantity, type: "string" }],
		[{ labels: ["UnitOfMeasure"], value: UnitOfMeasure, type: "string" }],
		[{ labels: ["UnitPrice"], value: UnitPrice, type: "string" }],
		[{ labels: ["TaxableAmount"], value: TaxableAmount, type: "string" }],
		[
			{
				labels: ["CgstRate"],
				value: CgstRate,
				type: "percentage",
				align: "left",
			},
		],
		[{ labels: ["CgstAmount"], value: CgstAmount, type: "string" }],
		[
			{
				labels: ["SgstRate"],
				value: SgstRate,
				type: "percentage",
				align: "left",
			},
		],
		[{ labels: ["SgstAmount"], value: SgstAmount, type: "string" }],
		[
			{
				labels: ["IgstRate"],
				value: IgstRate,
				type: "percentage",
				align: "left",
			},
		],
		[{ labels: ["IgstAmount"], value: IgstAmount, type: "string" }],
	];
};

const mapper = (json, card) => {
	return {
		...json,
		name: json.name,
		shortName: json.name,
		card: card ? mapCard(json) : [],
	};
};

const header = [
	{
		label: ["SAP Doc. No."],
		align: "left",
		columnName: "SapDocNo",
		sortable: false,
		basis: "25%",
	},
	{
		label: ["SAP Doc. Type"],
		align: "left",
		columnName: "SapDocType",
		sortable: false,
	},
	{
		label: ["Hsn Code"],
		align: "left",
		columnName: "HsnCode",
		sortable: false,
	},
	{
		label: ["Quantity"],
		align: "left",
		columnName: "Quantity",
		sortable: false,
	},
	{
		label: ["Unit Of Measure"],
		align: "left",
		columnName: "UnitOfMeasure",
		sortable: false,
	},
	{
		label: ["Unit Price"],
		align: "left",
		columnName: "UnitPrice",
		sortable: false,
	},
	{
		label: ["Taxable Value (₹)"],
		align: "left",
		columnName: "TaxableAmount",
		sortable: false,
	},
	{
		label: ["CGST Rate (%)"],
		align: "left",
		columnName: "CgstRate",
		sortable: false,
	},
	{
		label: ["CGST Amount (₹)"],
		align: "left",
		columnName: "CgstAmount",
		sortable: false,
	},
	{
		label: ["SGST Rate (%)"],
		align: "left",
		columnName: "SgstRate",
		sortable: false,
	},
	{
		label: ["SGST Amount (₹)"],
		align: "left",
		columnName: "SgstAmount",
		sortable: false,
	},
	{
		label: ["IGST Rate (%)"],
		align: "left",
		columnName: "IgstRate",
		sortable: false,
	},
	{
		label: ["IGST Amount (₹)"],
		align: "left",
		columnName: "IgstAmount",
		sortable: false,
	},
];

const einvoicelineMapper = {
	map: mapper,
	header,
};

export default einvoicelineMapper;
