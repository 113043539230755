import { date } from "../../../utils"

const mapDetails = (json) => {
	const {
		period,
		movementDate,
		type,
		registryDate,
		transaction,
		taxEntity,
		movementReference
	} = json

	return [
		[	
			{ label: 'Reference No', value: movementReference, type: 'string' },
			{ label: 'Entity', value: taxEntity, type: 'string' },
			{ label: 'Type', value: type, type: 'string' },
			{ label: 'Period', value: period, type: 'number' },
			{ label: 'Movement Date', value: date(movementDate), type: 'date' },
			{ label: 'Movement Posting Date', value: date(registryDate), type: 'date' },
			transaction ? { label: 'GlTransaction Id', value: { id: transaction.id, name: transaction.name, __typename: 'Transaction' }, type: 'object' } : null,
		]
	]
}

const mapCard = (json) => {
	const {
		numberOfLines,
		period,
		movementDate,
		type,
		documentReferenceDocumentType,
		movementReference,
		transaction
	} = json

	return [
		[
			{ labels: ['ref'], value: `${ movementReference }`, type: 'string' }
		],
		[
			{ labels: ['number_of_lines'], value: `${ numberOfLines }`, type: 'number' }
		],
		[
			{
				labels: ['type'],
				value: type,
				type: 'number',
				mobileHidden: true
			}
		],
		[
			{ labels: ['Document Type'], value: `${ documentReferenceDocumentType }`, type: 'number' }
		],
		[
			transaction ? { label: 'gltransaction Id', value: { id: transaction.id, name: transaction.name, __typename: 'Transaction' }, type: 'object' } : null,
		],
		[
			{ labels: ['period'], value: period, type: 'number' }
		],
		[
			{ labels: ['date'], value: date(movementDate), type: 'date' }
		],
	]
}

const mapper = (json, card, details) => {
	return {
		...json,
		name: json.number,
		shortName: json.number,
		details: details ? mapDetails(json) : [],
		card: card ? mapCard(json) : []
	}
}

const header = [
	{ label: ["Ref"], align: 'left', columnName: "movement_reference", sortable: true },
	{ label: ["Lines"], align: 'center',columnName: "number_of_lines", sortable: true },
	{
		label: ["Type"],
		align: 'center',
		mobileHidden: true,
		columnName: "movement_type", sortable: true
	},
	{ label: ["Document Reference Type"], align: 'left', columnName: "document_reference_document_type", sortable: true },
	{ label: ["gltransaction Id"], align: 'left', columnName: "gltransaction_id", sortable: true },
	{ label: ["Period"], align: 'center', columnName: "period", sortable: true },
	{ label: ["Date"], align: 'center',columnName: "movement_date", sortable: true  },
]

const stockmovementMapper = {
	map: mapper,
	header
}

export default stockmovementMapper;