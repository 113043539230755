import { checkNumberValues } from "../../../utils/convertUtils";
const mapDetails = (json) => {
	const {
		nif,
		period,
		board302MatColectC311C366ExetoC300DoQ09DaDeclOuC42DoAnxE4,
		board304MatRiaColetVelRegimeEspecial4,
		board302ColetaSePmeAt1500000X1728,
		board303ColetaSePme15000RostoQ11BC61500021OuGeRostoQ1164,
		board304ColetaDoRegimeEspecial16,
		board302ColetaDaRamSePmeAt15000RostoQ11BC4XC7A67,
		board303ColetaDaRamSePme15000RostoQ11BC4C7BOu76,
		board304ColetaEspecialDaRam28,
		board302ColetaDaRaaSePmeAt15000RostoQ11BC5MontRostoQ1155,
		board303ColetaDaRaaSePme15000RostoQ11BC5xRostoQ11BC688,
		board304ColetaEspecialDaRaa40,
		board302ColetaDoContSePmeAt15000RostoQ11BC22XC7A79,
		board303ColetaDoContSePme15000RostoQ11BC22xc7BOuGe100,
		board304ColetaEspecialDoContinente52,
		board302MatRiaColetVelC311C399OuC322OuC409DoQ09DaDecl16,
		board302TaxaColetaC11xTaxa40,
		board302ColetaC11xTaxa43,
		board302ColetaDaRamRostoQ11BC4XC12ATranspPC37091,
		board302ColetaDaRaaRostoQ11BC5XC12X08ATranspPC350103,
		board302ColetaDoContRostoQ11BC22XC12ATranspPC349115,
		board303MatRiaColetVelC311DoQ09DaDecl4,
		board303ColetaSePmeAt15000x1716,
		board303ColetaSePme15000C1615000X21OuGeC16x21112,
		board303ColectaDaRamSePmeAt15000RostoQ11BC4xc17A28,
		board303ColetaDaRamSePme15000RostoQ11BC4C17BOu124,
		board303ColetaDaRaaSePmeAt15000RostoQ11BC5xc17A40,
		board303ColetaDaRaaSePme15000RostoQ11BC5xc17BOu136,
		board303ColetaDoContSePmeAt15000RostoQ11BC22xc17A52,
		board303ColetaDoContSePmeAt15000RostoQ11BC22xc17BOu148,
	} = json
	return [
		[
			{ label: 'NIF', value: nif, type: 'string' },
			{ label: 'Período', value: period, type: 'string' },
		],
		{
			title: "Q04 - Regime Geral, Regime Simplificado com Aplicação Das Taxas Regionais e Regime Especial Das Atividades De Transporte Marítimo",
			rows: [
				[
					{
						label: "Q04-6 - Matéria Coletável",
						value: checkNumberValues(board302MatColectC311C366ExetoC300DoQ09DaDeclOuC42DoAnxE4),
						type: "number"
					},
					{
						label: "Q04-6A - Matéria Coletável Regime Especial",
						value: checkNumberValues(board304MatRiaColetVelRegimeEspecial4),
						type: "number"
					},
					{
						label: "Q04-7A - Coleta: Pme <= 15.000€",
						value: checkNumberValues(board302ColetaSePmeAt1500000X1728),
						type: "number"
					},
					{
						label: "Q04-7B - Coleta: Pme  > 15.000€",
						value: checkNumberValues(board303ColetaSePme15000RostoQ11BC61500021OuGeRostoQ1164),
						type: "number"
					},
					{
						label: "Q04-7C - Coleta do Regime Especial",
						value: checkNumberValues(board304ColetaDoRegimeEspecial16),
						type: "number"
					},
				],
				[
					{
						label: "Q04-8A - Coleta da RAM - Se Pme <= 15.000€",
						value: checkNumberValues(board302ColetaDaRamSePmeAt15000RostoQ11BC4XC7A67),
						type: "number"
					},
					{
						label: "Q04-8B - Coleta da RAM: Se Pme > 15.000€",
						value: checkNumberValues(board303ColetaDaRamSePme15000RostoQ11BC4C7BOu76),
						type: "number"
					},
					{
						label: "Q04-8C - Coleta do Regime Especial",
						value: checkNumberValues(board304ColetaEspecialDaRam28),
						type: "number"
					},
				],
				[
					{
						label: "Q04-9A - Coleta da RAA: Se Pme <= 15.000€",
						value: checkNumberValues(board302ColetaDaRaaSePmeAt15000RostoQ11BC5MontRostoQ1155),
						type: "number"
					},
					{
						label: "Q04-9B - Coleta da RAA: Se Pme > 15.000€",
						value: checkNumberValues(board303ColetaDaRaaSePme15000RostoQ11BC5xRostoQ11BC688),
						type: "number"
					},
					{
						label: "Q04-9C - Coleta do Regime Especial",
						value: checkNumberValues(board304ColetaEspecialDaRaa40),
						type: "number"
					},
				],
				[
					{
						label: "Q04-10A - Coleta do Continente: Pme <= 15.000€",
						value: checkNumberValues(board302ColetaDoContSePmeAt15000RostoQ11BC22XC7A79),
						type: "number"
					},
					{
						label: "Q04-10B - Coleta do Continente: Pme > 15.000€",
						value: checkNumberValues(board303ColetaDoContSePme15000RostoQ11BC22xc7BOuGe100),
						type: "number"
					},
					{
						label: "Q04-10C - Coleta do Regime Especial",
						value: checkNumberValues(board304ColetaEspecialDoContinente52),
						type: "number"
					},
				],
			],
		},
		{
			title: "Q05 -  Antigo Regime Simplificado (Ex-artigo 58.o Do Circ)",
			rows: [
				[
					{
						label: "Q05-11 - Matéria Coletável ",
						value: checkNumberValues(board302MatRiaColetVelC311C399OuC322OuC409DoQ09DaDecl16),
						type: "number"
					},
					{
						label: "Q05-21 - Taxa: Coleta: (Campo 11 X Taxa)",
						value: board302TaxaColetaC11xTaxa40,
						type: "percentage"
					},
					{
						label: "Q05-12 - Coleta: (Campo 11 X Taxa)",
						value: checkNumberValues(board302ColetaC11xTaxa43),
						type: "number"
					},
					{
						label: "Q05-13 - Coleta da RAM:",
						value: checkNumberValues(board302ColetaDaRamRostoQ11BC4XC12ATranspPC37091),
						type: "number"
					},
					{
						label: "Q05-14 - Coleta da RAA",
						value: checkNumberValues(board302ColetaDaRaaRostoQ11BC5XC12X08ATranspPC350103),
						type: "number"
					},
					{
						label: "Q05-15 - Coleta Do Continente",
						value: checkNumberValues(board302ColetaDoContRostoQ11BC22XC12ATranspPC349115),
						type: "number"
					},
				],
			],
		},
		{
			title: "Q06 - Regime Geral sem aplicação das taxas regionais",
			rows: [
				[
					{
						label: "Q06-16 - Matéria Coletável ",
						value: checkNumberValues(board303MatRiaColetVelC311DoQ09DaDecl4),
						type: "number"
					},
					{
						label: "Q06-17A - Coleta: Pme <= 15.000€",
						value: checkNumberValues(board303ColetaSePmeAt15000x1716),
						type: "number"
					},
					{
						label: "Q06-17B - Coleta: Pme > 15.000,00",
						value: checkNumberValues(board303ColetaSePme15000C1615000X21OuGeC16x21112),
						type: "number"
					},
				],
				[
					{
						label: "Q06-18A - Coleta da RAM: Pme <= 15.000,00",
						value: checkNumberValues(board303ColectaDaRamSePmeAt15000RostoQ11BC4xc17A28),
						type: "number"
					},
					{
						label: "Q06-18B - Coleta da RAM: Pme > 15.000€",
						value: checkNumberValues(board303ColetaDaRamSePme15000RostoQ11BC4C17BOu124),
						type: "number"
					},
				],
				[
					{
						label: "Q06-19A - Coleta da RAA: Pme <= 15.000€",
						value: checkNumberValues(board303ColetaDaRaaSePmeAt15000RostoQ11BC5xc17A40),
						type: "number"
					},
					{
						label: "Q06-19B - Coleta da RAA - Pme > 15.000€",
						value: checkNumberValues(board303ColetaDaRaaSePme15000RostoQ11BC5xc17BOu136),
						type: "number"
					},
				],
				[
					{
						label: "Q06-20A - Coleta do Continente: Pme <= 15.000€",
						value: checkNumberValues(board303ColetaDoContSePmeAt15000RostoQ11BC22xc17A52),
						type: "number"
					},
					{
						label: "Q06-20B - Coleta do Continente: Pme > 15.000€",
						value: checkNumberValues(board303ColetaDoContSePmeAt15000RostoQ11BC22xc17BOu148),
						type: "number"
					},
				],
			],
		}
	]
}

const mapper = (json, card, details) => {
	return {
		...json,
		name: json.declarationtype,
		shortName: `${json.period}`,
		details: details ? mapDetails(json) : []
	};
};

const mod22cannexMapper = {
	map: mapper
};
export default mod22cannexMapper;
