const search = (state = { results: {}, status: {}, activity: false }, action) => {
	switch (action.type) {
		case 'SEARCH_TERM_START': {
			return {
				...state,
				activity: true
			}
		}
		case 'SEARCH_TERM_SUCCESS': {
			const { data = [], q = '' } = action.payload || {}

			return {
				...state,
				result: {
					q,
					data
				},
				activity: false
			}
		}
		default: break
	}
	return state
}

export default search
