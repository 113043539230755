import { applyMiddleware, compose, createStore } from 'redux'
import thunkMiddleware from 'redux-thunk'

import monitorReducersEnhancer from './enhancers/monitorReducer'
import rootReducer from './reducers'

import autoMergeLevel1 from 'redux-persist/lib/stateReconciler/autoMergeLevel1';
import { persistStore, persistCombineReducers } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web

import * as views from './views'

export default function configureStore(preloadedState) {
	const dev = process.env.NODE_ENV !== 'production' && module.hot
	const middlewares = [thunkMiddleware]
	const middlewareEnhancer = applyMiddleware(...middlewares)
	
	const enhancers = [middlewareEnhancer, monitorReducersEnhancer]
	if (dev) {
		if (window.__REDUX_DEVTOOLS_EXTENSION__) {
			enhancers.push(window.__REDUX_DEVTOOLS_EXTENSION__())
		}
	}
	const composedEnhancers = compose(...enhancers)
	
	const persistConfig = {
        key: 'session',
        storage: storage,
        stateReconciler: autoMergeLevel1,
		whitelist: ['user']
    }
    const persistedReducer = persistCombineReducers(persistConfig, rootReducer)

	const store = createStore(
		persistedReducer,
		preloadedState,
		composedEnhancers
	)
	const persistor = persistStore(store)
	if (dev) {
		module.hot.accept('./reducers', () => store.replaceReducer(rootReducer))
	}

	return {persistor, store}
}

export {
	views
}
