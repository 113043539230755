const mapCard = (json) => {
	const {
		taxonomyCode,
		taxonomyCodeDescription,
		suggestedTaxonomyCode,
		suggestedSourceAccount,
		status,
		taxonomyReference,
		sourceAccount,
		sourceAccountDescription
	} = json

	const statusType = "taxonomy_anomaly_status_" + status

	return [
		[
			{ labels: ['taxonomyReference'], value: taxonomyReference, type: 'string', basis: '8%' }

		],
		[
			{ labels: ['sourceAccount'], value: sourceAccount, type: 'string', basis: '10%' }

		],
		[
			{ labels: ['sourceAccountDescription'], value: sourceAccountDescription, type: 'string' }

		],
		[
			{ labels: ['taxonomyCode'], value: taxonomyCode, type: 'string', basis: '8%' }

		],
		[
			{ labels: ['taxonomyCodeDescription'], value: taxonomyCodeDescription, type: 'string' }

		],
		[
			{ labels: ['suggestedSourceAccount'], value: suggestedSourceAccount, type: 'string', basis: '10%' }

		],
		[
			{ labels: ['suggestedTaxonomyCode'], value: suggestedTaxonomyCode, type: 'string', basis: '30%' }

		],
		[
			{ labels: ['status'], value: statusType, type: 'taxonomy-status', basis: '15%' }
		]
	]
}

const header = [
	{ label: ['taxonomyReference'], align: 'left', basis: '8%' },
	{ label: ['sourceAccount'], align: 'left', basis: '10%' },
	{ label: ['sourceAccountDescription'], align: 'left' },
	{ label: ['taxonomyCode'], align: 'left', basis: '8%' },
	{ label: ['taxonomyCodeDescription'], align: 'left' },
	{ label: ['suggestedSourceAccount'], align: 'left', basis: '10%' },
	{ label: ['suggestedTaxonomyCode'], align: 'left', basis: '30%' },
	{ label: ['status'], align: 'center', basis: '15%' },
]

const mapper = (json, card) => {
	return {
		...json,
		name: json.name,
		shortName: json.name,
		card: card ? mapCard(json) : [],
	}
}

const taxonomyanomalyMapper = {
	map: mapper,
	header
};

export default taxonomyanomalyMapper