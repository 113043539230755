import { format } from "../../../utils"
import I18n from '../../../I18n'

const mapCard = (json) => {
	const {
		d01,
		l01,
		f01,
		f02,
		f05,
		f06,
		f07,
		f08,
		a03,
	} = json

	return [
		[
			{ labels: ['Account Id'], value: `${d01} - ${I18n.translate`${l01}`}`, type: 'string' }

		],
		[
			{ labels: ['Taxonomy'], value: a03, type: 'string' }

		],
		[
			{ labels: ['debit_period'], value: format(f02, 'normal', 'AOA', 2, 'pt-AO'), type: 'currency' }

		],
		[
			{ labels: ['credit_period'], value: format(f01, 'normal', 'AOA', 2, 'pt-AO'), type: 'currency' }

		],
		[
			{ labels: ['accumulated_debit'], value: format(f06, 'normal', 'AOA', 2, 'pt-AO'), type: 'currency' }

		],
		[
			{ labels: ['accumulated_credit'], value: format(f05, 'normal', 'AOA', 2, 'pt-AO'), type: 'currency' }

		],
		[
			{ labels: ['debit_balance'], value: format(f08, 'normal', 'AOA', 2, 'pt-AO'), type: 'currency' }

		],
		[
			{ labels: ['credit_balance'], value: format(f07, 'normal', 'AOA', 2, 'pt-AO'), type: 'currency' }
		]
	]
}

const header = [
	{ label: ['Account Id'], align: 'left' },
	{ label: ['Taxonomy'], align: 'left' },
	{ label: ['Period Debit'], align: 'right' },
	{ label: ['Period Credit'], align: 'right' },
	{ label: ['Accumulated Debit'], align: 'right' },
	{ label: ['Accumulated Credit'], align: 'right' },
	{ label: ['Debit Balance'], align: 'right' },
	{ label: ['Credit Balance'], align: 'right' }
]

const mapper = (json, card) => {
	return {
		...json,
		name: json.name,
		shortName: json.name,
		card: card ? mapCard(json) : [],
		link: {
			to: '/:locale(pt|en|lt|es)?/:projectId/:fiscalYear([0-9]{4}|current)/:importId/saft_ao/reports/statement_accounts_accumulated',
			filter: [
				{ "filters": [{ "value": json.d01 }], "column": "account_id" },
				{ "filters": [{ "value": `0-${json.a01}` }], "column": "transaction__period" }
			]
		}
	}
}

const trialbalanceMapper = {
	map: mapper,
	header
};

export default trialbalanceMapper;
