import { format, date } from '../../../utils'

const header = [
    { label: ["Depreciation Method"], align: 'left' },
    { label: ["Debit Credit Indicator"], align: 'left' },
    { label: ["Account Id"], align: 'center' },
    { label: ["Appreciation Date"], align: 'center' },
    { label: ["Depreciation Percentage"], align: 'right' },
    { label: ["Appreciation"], align: 'right' },
    { label: ["Depreciation for Period"], align: 'right' },
]

const mapCard = (json) => {
	const {
        depreciationMethod,
        debitCreditIndicator,
        generalLedgerAccountRowKey,
        accountId,
        appreciationDate,
        depreciationPercentage,
        appreciation,
        depreciationForPeriod
	} = json

	return [
        [
            { labels: ['depreciationMethod'], value: depreciationMethod, type: 'string' }
        ],
        [
            { labels: ['debitCreditIndicator'], value: debitCreditIndicator, type: 'string' }
        ],
        [
            generalLedgerAccountRowKey ?
                { label: 'Account', value: { id: generalLedgerAccountRowKey, name: accountId, __typename: 'GeneralLedger' }, type: 'object' } : 
                { labels: ['Account'], value: accountId || 'N/A', type: 'string' }
        ],
        [
            { labels: ['appreciationDate'], value: date(appreciationDate), type: 'date' }
        ],
        [
            { labels: ['depreciationPercentage'], value: format(depreciationPercentage, 'normal', null, 2), type: 'percentage' }
        ],
        [
            { labels: ['appreciation'], value: format(appreciation, "fullValue"), type: 'currency' }
        ],
        [
            { labels: ['depreciationForPeriod'], value: format(depreciationForPeriod, "fullValue"), type: 'currency' }
        ]
	]
}

const mapper = (json, card) => {
    const name = json.account_id
    
    return {
        ...json,
        __typename: json.__typename,
        id: json.id,
		name,
        shortName: name,
		card: card ? mapCard(json) : []
	}
}

const assetvaluationappreciationMapper = {
    map: mapper,
    header
}
export default assetvaluationappreciationMapper;