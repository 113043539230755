const mapCard = (json) => {
	const {
        taxType,
        taxCode
	} = json

	return [
        [
            { labels: ['taxType'], value: taxType, type: 'string' }
        ],
        [
            { labels: ['taxCode'], value: taxCode, type: 'number' }
        ]
	]
}

const mapper = (json, card) => {
    const name = json.product__id

	return {
        ...json,
        __typename: json.__typename,
        id: json.id,
		name,
		shortName: name,
		card: card ? mapCard(json) : []
	}
}

const header = [
    { label: ["Tax Type"], align: 'left' },
    { label: ["Tax Code"], align: 'center' }
]

const taxesMapper = {
	map: mapper,
	header
}

export default taxesMapper;