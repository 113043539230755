import { pushDialog } from "."
import I18n from "../../I18n"

const loginSuccess = (data) => (
	{
		type: 'LOGIN_SUCCESS',
		payload: {
			data
		}
	}
)
const loginError = (data) => (
	{
		type: 'LOGIN_ERROR',
		payload: {
			data
		}
	}
)
export const logoutSuccess = () => (
	{
		type: 'LOGOUT_SUCCESS'
	}
)

const authToken = (data) => (
	{
		type: 'LOGIN_SUCCESS_TOKEN',
		payload: {
			data
		}
	}
)

export const login = (username, password) => (
	(dispatch, getState, history) => {
		const queriesBody = {
			query: `mutation($u:String!,$p:String!){session(username:$u,password:$p){session,authToken}}`,
			variables: {
				u: username,
				p: password
			}
		}
		fetch('/graphql', {
			method: 'POST',
			credentials: 'same-origin',
			headers: {
				'Content-Type': 'application/json',
				'Accept': 'application/json',
			},
			body: JSON.stringify(queriesBody)
		})
			.then((res) => res
				.json()
				.then((res) => {
					const { data, errors } = res

					if (errors) {
						return dispatch(loginError(errors[0].message))
					}

					if (data.session) {
						dispatch(loginSuccess(data.session))
						dispatch(authToken(data.session.authToken))
					} else {
						dispatch(loginError('Invalid credentials. Make sure you are using your username and password.'))
					}
				})
				.catch(() => dispatch(loginError('Api error or Unknown error. Please try again later')))
			)
	}
)

export const loginWithToken = (token, locale) => (
	(dispatch, getState, history) => {
		window.localStorage.removeItem('persist:session')
		const queriesBody = {
			query: `mutation($t:String){external_application_login(tokenAccess:$t){session,authToken}}`,
			variables: {
				t: token
			}
		}
		fetch('/graphql', {
			method: 'POST',
			credentials: 'same-origin',
			headers: {
				'Content-Type': 'application/json',
				'Accept': 'application/json',
			},
			body: JSON.stringify(queriesBody)
		})
			.then((res) => res
				.json()
				.then((res) => {
					const { data, errors } = res
					if (errors) {
						dispatch(loginError(errors[0].message))
						try {
							window.localStorage.removeItem('persist:session')
						} catch (e) {
							console.error("100 - Local storage")
						}
						dispatch(logoutSuccess())
						return window.location.href = `/login`;
					}
					if (data.external_application_login) {
						try {
							window.localStorage.removeItem('persist:session')
						} catch (e) {
							console.error("100 - Local storage")
						}
						dispatch(loginSuccess(data.external_application_login))
						dispatch(authToken(data.external_application_login.authToken))
						return window.location.href = `/en`;
					} else {
						dispatch(loginError('Invalid credentials. Make sure you are using your username and password.'))
					}
				})
				.catch(() => dispatch(loginError('Api error or Unknown error. Please try again later')))
			)
	}
)

export const logout = () => (
	(dispatch, getState) => {
		const queriesBody = {
			query: `mutation($authToken:String!){deleteSession(authToken:$authToken){ok}}`,
			variables: {
				authToken: getState().user.authToken || "",
			}
		}
		if (queriesBody) {
			fetch('/graphql', {
				method: 'POST',
				credentials: 'same-origin',
				headers: {
					'Content-Type': 'application/json',
					'Accept': 'application/json',
				},
				body: JSON.stringify(queriesBody)
			})
				.then((res) => res.json().catch(() => ({})))
				.then((res) => {
					const data = res.data || {}
					if (data.deleteSession && data.deleteSession.ok) {
						try {
							window.localStorage.clear()
						} catch (e) {
							console.error("100 - Local storage")
						}
						dispatch(logoutSuccess())
					} else if (res.errors) {
						dispatch(logoutSuccess())
					}
				})
				.catch(() => {
					dispatch(logoutSuccess())
				})
		}
	}
)

export const getFindingDetails = (projectId, fiscalYear, modulePrefix, ruleId, importId ) => (
	(dispatch) => {
		const queriesBody = {
			query: `mutation($projectId:String,$fiscalYear:String,$modulePrefix:String,$ruleId:String,$importId:String){requestFindingDetailByRuleId(projectId:$projectId,fiscalYear:$fiscalYear,modulePrefix:$modulePrefix,ruleId:$ruleId,importId:$importId)}`,
			variables: {
				projectId: projectId,
				fiscalYear: fiscalYear,
				modulePrefix: modulePrefix,
				ruleId: ruleId,
				importId: importId
			}
		}

		return fetch('/graphql', {
			method: 'POST',
			credentials: 'same-origin',
			headers: {
				'Content-Type': 'application/json',
				'Accept': 'application/json'
			},
			body: JSON.stringify(queriesBody)
		})
			.then((res) => {
				window.location.reload()
			})
			.catch((e) => {
				throw new Error(e.message)
			})

	}
)



//RESET PASSWORD
const resetPasswordSuccess = (data) => (
	{
		type: 'RESET_SUCCESS',
		payload: {
			data
		}
	}
)

const resetPasswordError = (msg) => (
	{
		type: 'RESET_ERROR',
		payload: {
			msg: msg
		}
	}
)

const forgetPasswordMessage = (msg) => (
	{
		type: 'FORGET_PASSWORD_MESSAGE',
		payload: {
			msg
		}
	}
)

export const forgetPassword = (email) => 
 	(dispatch) => {
		
 		const queryBody = {
			query: `mutation($p:String!){forgetPassword(email:$p){success, message}}`,
			variables: {
				p: email
			}
		}

		if(queryBody){
			fetch('/graphql', {
				method: 'POST',
				credentials: 'same-origin',
				headers: {
					'Content-Type': 'application/json',
					'Accept': 'application/json'
				},
				body: JSON.stringify(queryBody)
			})
				.then((res) => res.json())
				.then((res) => {
					const {data, errors} = res;
					if(errors){
						return dispatch(forgetPasswordMessage({success: false, message: errors[0].message}));
					}else{
						if((data || {}).forgetPassword && (data || {}).forgetPassword.success){
							return dispatch(forgetPasswordMessage({success: true , message: data.forgetPassword.message}));
						}
					}
				})
				.catch(() => dispatch(forgetPasswordMessage({success: false, message: 'Api error or Unknown error. Please try again later'})))
		}
 }

export const resetPassword = (oldPassword, newPassword, confirmPassword, userId, organizationId, role) => (
	(dispatch) => {

		if (newPassword !== confirmPassword) {
			return dispatch(resetPasswordError('Your "new password" and "confirm password" does not match.'))
		}

		const queriesBody = {
			query: `mutation($p:String!,$n:String!){reset_password(oldPassword:$p,newPassword:$n){success}}`,
			variables: {
				p: oldPassword,
				n: newPassword,
			}
		}

		if (queriesBody) {
			fetch('/graphql', {
				method: 'POST',
				credentials: 'same-origin',
				headers: {
					'Content-Type': 'application/json',
					'Accept': 'application/json',
				},
				body: JSON.stringify(queriesBody)
			})
				.then((res) => res
					.json()
					.then((res) => {
						const { data, errors } = res

						if (errors) {
							return dispatch(resetPasswordError(errors[0].message))
						}

						if ((data || {}).reset_password && (data || {}).reset_password.success) {
							try {
								window.localStorage.clear()
								dispatch(resetPasswordSuccess(data.session))
							} catch (e) {
								console.error("100 - Local storage")
							}
							dispatch(logoutSuccess())
						} else {
							dispatch(resetPasswordError('Invalid password. Make sure your password is correct.'))
						}
					})
					.catch(() => dispatch(resetPasswordError('Api error or Unknown error. Please try again later')))
				)
		}
	}
)

export const handleDownloadFilesAction = ({ files }, pushDialog, i18n) => (
	(dispatch) => {
		for(const file of files){
			const {fileLocation} = file
			fetch('/api/download_file', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					'Accept': 'application/json',
				},
				body: JSON.stringify({ filePath: fileLocation })
			}).then((t) => {
				return t.blob().then((b) => {
					if (b && b.size > 20) {
						const slipttedRowValue = fileLocation.split('/');
						const fileName = slipttedRowValue[slipttedRowValue.length - 1];
						const a = document.createElement("a")
						a.href = URL.createObjectURL(b)
						a.setAttribute("download", fileName)
						document.body.appendChild(a)
						a.click()
						document.body.removeChild(a)
					} else {
						pushDialog('AlertDialog', i18n`Document Dowload Error`, {
							title: i18n`Something went wrong when generating the requested file`
						}).catch(()=>null)
					}
				});
			}).catch((e) => {
				throw new Error(e.message)
			})
		}
		return
	}
)

export const sendSalesInformationEmail = (projectId, fiscalYear, importId) => (
	(dispatch) => {
		const queriesBody = {
			query: `mutation($p:String!,$f:String!,$i:String!){send_sales_information_email(projectId:$p,fiscalYear:$f,importId:$i)}`,
			variables: {
				p: projectId,
				f: fiscalYear,
				i: importId
			}
		}
		return fetch('/graphql', {
			method: 'POST',
			credentials: 'same-origin',
			headers: {
				'Content-Type': 'application/json',
				'Accept': 'application/json'
			},
			body: JSON.stringify(queriesBody)
		})
			.then((res) => res.json().catch(() => ({})))
			.then((res) => {
				const data = res.data || {}
				if (data && data.send_sales_information_email) {
					window.location.reload()
				} else {
					dispatch(pushDialog('ConfirmDialog', I18n.translate`Submit Error`, {
						title: I18n.translate`Somethings went wrong in sales information Submittion`,
					}))
				}
			})
			.catch((e) => {
				dispatch(pushDialog('ConfirmDialog', I18n.translate`Submit Error`, {
					title: I18n.translate`Somethings went wrong in sales information Submittion`,
				}))
			})
	})