import { format, date } from "../../../utils"

const mapDetails = (json) => {
	const {
		lineNumber,
		series,
		serialNumber,
		type,
		movementDate,
		quantity,
		debitAmount,
		creditAmount,
		settlementAmount,
		iecAmount,
		documentType,
		documentNumber,
		shipTo,
		transaction,
		supplier,
		customer,
		product
	} = json
	return [
		[
			{ label: 'Line Number', value: lineNumber, type: 'string' },
			{
				label: 'Product',
				value: {
					id: product.id,
					name: product.name,
					__typename: 'Product'
				},
				type: 'object'
			},
			customer ? {
				label: 'Customer',
				value: {
					id: customer.id,
					name: customer.name,
					__typename: 'Customer'
				},
				type: 'object'
			} : null,
			supplier ? {
				label: 'Supplier',
				value: {
					id: supplier.id,
					name: supplier.name,
					__typename: 'Supplier'
				},
				type: 'object'
			} : null,
			transaction ? {
				label: 'Transaction',
				value: {
					id: transaction.id,
					name: transaction.name,
					__typename: 'Transaction'
				},
				type: 'object'
			} : null,
			{
				label: 'Movement Type',
				value: type,
				type: 'string'
			},
			{
				label: 'Movement Date',
				value: date(movementDate),
				type: 'date'
			},
		],
		[
			{ label: 'Credit Amount', value: format(creditAmount), type: 'currency' },
			{ label: 'Debit Amount', value: format(debitAmount), type: 'currency' },
			{ label: 'Settlement Amount', value: format(settlementAmount), type: 'currency' }
		],
		[
			{
				label: 'Quantity',
				value: quantity,
				type: 'number'
			},
			{ label: 'IEC. Amount', value: format(iecAmount), type: 'currency' },
		],

		{
			title: 'Document',
			rows: [
				[
					{
						label: 'Document Type',
						value: documentType,
						type: 'string'
					},
					{
						label: 'Document Number',
						value: documentNumber,
						type: 'string'
					},
					{
						label: 'Document Series',
						value: series,
						type: 'string'
					},
					{
						label: 'Document Serial Number',
						value: serialNumber,
						type: 'string'
					}
				]
			]
		},

		shipTo ? {
			title: 'Ship To',
			rows: [
				[{
					label: 'Postal Code',
					value: shipTo.postalCode || 'N/A',
					type: 'string'
				},
				{
					label: 'City',
					value: shipTo.city || 'N/A',
					type: 'string'
				},
				{
					label: 'Region',
					value: shipTo.region || 'N/A',
					type: 'string'
				},
				{
					label: 'Country',
					value: shipTo.country || 'N/A',
					type: 'string'
				}
				]
			]
		} : null,
	]
}

const mapCard = (json) => {
	const {
		lineNumber,
		productCode,
		description,

		taxCountry,
		taxType,
		taxCode,
		taxPercentage,

		quantity,
		unitPrice,
		creditAmount,
		debitAmount,
		unitOfMeasure
	} = json

	return [
		[
			{ labels: ['description'], value: `${description}`, type: 'string' }
		],
		[
			{ labels: ['number'], value: `[${lineNumber}]`, type: 'number' }
		],
		[
			{ labels: ['ref'], value: `${productCode}`, type: 'number' }
		],
		[
			{ labels: ['taxCountry'], value: `${taxCountry}`, type: 'number' }
		],
		[
			{ labels: ['taxType'], value: `${taxType}`, type: 'number' }
		],
		[
			{ labels: ['taxCode'], value: taxCode, type: 'number' }
		],
		[
			{ labels: ['quantityDebit'], value: debitAmount && debitAmount > 0 && quantity ? `${format(quantity, undefined, null)} x ${format(unitPrice)}/${unitOfMeasure}` : 'N/A', type: 'unity' }
		],
		[
			{ labels: ['quantity'], value: creditAmount && creditAmount > 0 && quantity ? `${format(quantity, undefined, null)} x ${format(unitPrice)}/${unitOfMeasure}` : 'N/A', type: 'unity' }
		],
		[
			{ labels: ['tax'], value: taxPercentage, type: 'percentage' }
		],
		[
			{ labels: ['debit_amount'], value: format(debitAmount), type: 'currency' }
		],
		[
			{ labels: ['credit_amount'], value: format(creditAmount), type: 'currency' },
		]
	]
}

const mapper = (json, card, details) => {

	return {
		...json,
		name: json.productCode,
		shortName: json.productCode,
		details: details ? mapDetails(json) : [],
		card: card ? mapCard(json) : []
	}
}

const header = [
	{ label: ["Description"], align: 'left', columnName: "description", sortable: true },
	{ label: ["[No]"], align: 'center' },
	{ label: ["Ref"], align: 'center' },
	{ label: ["Tax Country"], align: 'center' },
	{ label: ["Type"], align: 'center' },
	{ label: ["Tax Code"], align: 'center' },
	{ label: ["Quantity Debit"], align: 'right' },
	{ label: ["Quantity Credit"], align: 'right' },
	{ label: ["Tax %"], align: 'right' },
	{ label: ["Debit"], align: 'right', columnName: "debit_amount", sortable: true, totalizerName: "total_debit_amount" },
	{ label: ["Credit"], align: 'right', columnName: "credit_amount", sortable: true, totalizerName: "total_credit_amount" }
]

const stockmovementlineMapper = {
	map: mapper,
	header
}
export default stockmovementlineMapper;