import React, { useState } from "react";
import { useGlobalValue } from "colbi_web_ui/lib/state/GlobalProvider";
import styles from "./FileDetail.module.sass";
import { date, stringifyFilter } from "colbi_web_ui/lib/utils";
import Link from "colbi_web_ui/lib/components/Link/Link";
import { SimpleButton } from "colbi_web_ui/lib/components/inputs";
import Icon from "colbi_web_ui/lib/components/Icon/Icon";
import { camelize } from "../../utils/camelize";
import Modal from "colbi_web_ui/lib/components/Modal/Modal";
import { LoadingIndicator } from "colbi_web_ui/lib/components";
import { format } from "../../utils";

const downloadPDF = (importId, setDownloading, pushDialog, i18n) => {
	setDownloading(true);
	fetch("/api/download_pdf", {
		method: "POST",
		credentials: "same-origin",
		headers: { "Content-Type": "application/json" },
		body: JSON.stringify({ variables: { importId } }),
	})
		.then((t) => {
			setDownloading(false);
            if(t && t.status !== 200){
                pushDialog('AlertDialog', i18n`Document Export Error`, {
					title: i18n`Something went wrong when exporting the requested file`
				}).catch(()=>null)
                setDownloading(false);
            }else{
                return t.blob().then((b) => {
                    if (b && b.size > 20) {
                        const a = document.createElement("a");
                        const filename = t.headers.get("Filename");
                        a.href = URL.createObjectURL(b);
                        a.setAttribute("download", filename);
                        document.body.appendChild(a);
                        a.click();
                        document.body.removeChild(a);
                    } else {
                        console.error("empty file");
                    }
                });
            }
		})
		.catch(() => {
			setDownloading(false);
		});
};

const FileDetail = (props) => {
	const { modifiers, data } = props;
	const { i18n, params, history, pushDialog } = useGlobalValue();
	const { locale, projectId, section, fiscalYear } = params;
	const [downloading, setDownloading] = useState(false);
	const prefix = data && data.moduleByDataFileType ? data.moduleByDataFileType : "";

	const renderActivityDetails = () => (
		<>
		    <div className={styles["list-header"]}>
				<span>{i18n`Information`}</span>
			</div>
			<ul>
				<li>
					<small>{i18n`Document ID`}</small>
					<p>{data.id}</p>
				</li>
				<li>
					<small>{i18n`Name`}</small>
					<p>{data.name}</p>
				</li>
			</ul>
			<ul>
				<li>
					<small>{i18n`Document Type`}</small>
					<p>{data.type}</p>
				</li>
				<li>
					<small>{i18n`Document Version`}</small>
					<p>{data.version}</p>
				</li>
			</ul>
			<ul>
				<li>
					<small>{i18n`Uploaded By`}</small>
					<p>{data.uploadedByName}</p>
				</li>
				<li>
					<small>{i18n`Uploaded At`}</small>
					<p>{date(data.uploadedAt)}</p>
				</li>
			</ul>
			<div className={styles["list-header"]}>
				<span>{i18n`Processing State`}</span>
			</div>
			<ul>
				<li>
					<p>{data.status}</p>
				</li>
			</ul>
		</>
	);

	const renderFoldersDetails = () => (
		<>
			<div className={styles["list-header"]}>
				<span>{i18n`Information`}</span>
			</div>
			<div className={styles["center-items"]}>
				<ul>
					<li>
						<small>{i18n`Document ID`}</small>
						<p>{data.id}</p>
					</li>
					<li>
						<small>{i18n`Name`}</small>
						<p>{data.name}</p>
					</li>
				</ul>
				<ul>
					<li>
						<small>{i18n`Document Type`}</small>
						<p>{data.type}</p>
					</li>
					<li>
						<small>{i18n`Document Version`}</small>
						<p>{data.version}</p>
					</li>
				</ul>
				<ul>
					<li>
						<small>{i18n`Uploaded By`}</small>
						<p>{data.uploadedByName}</p>
					</li>
					<li>
						<small>{i18n`Uploaded At`}</small>
						<p>{date(data.uploadedAt)}</p>
					</li>
				</ul>
			</div>
			<div>
				<div className={styles["list-header"]}>
					<span>{i18n`Content`}</span>
					<span>{i18n`Count`}</span>
				</div>
				<ul className={styles["list-headers-summary"]}>
					{data && data.entitiesKpis && data.entitiesKpis.length ? (
						(data.entitiesKpis || []).map(({ entity, count }) => {
							return (
								<li key={entity}>
									<small>{i18n`${camelize(entity)}`}</small>
									<p>{format(count, 'normal', null, 0) || 0}</p>
								</li>
							);
						})
					) : (
						<small>{i18n`There are no results!`}</small>
					)}
				</ul>
			</div>
			<div>
				<div className={styles["list-header"]}>
					<span>{i18n`Processing State`}</span>
					<span>{i18n`Count`}</span>
				</div>
				<ul className={styles["list-severity-summary"]}>
					{data && data.totalFindings && data.totalFindings.length ? (
						data.totalFindings
							.sort((a, b) => b.severity.localeCompare(a.severity))
							.map(({ severity, count }) => (
								<li key={severity}>
									<small>{i18n`${severity}`}</small>
									<p>{format(count, 'normal', null, 0) || 0}</p>
								</li>
							))
					) : (
						<small>{i18n`There are no results!`}</small>
					)}
				</ul>
			</div>
		</>
	);

	const renderAttachmentsDetails = () => (
		<>
			<div className={styles["wrap-buttons"]}>
				<SimpleButton
					className={styles["download-button"]}
					onClick={() => {
						downloadPDF(data.id, setDownloading, pushDialog, i18n);
					}}
				>
					<Icon
						name={downloading ? "spinner" : "download"}
						className={downloading ? styles["spin"] : ""}
					/>
				</SimpleButton>
			</div>
            <div className={styles["list-header"]}>
				<span>{i18n`Information`}</span>
			</div>
			<ul>
                <li>
					<small>{i18n`File Name`}</small>
					<p>{data.fileName}</p>
				</li>
				<li>
					<small>{i18n`Document uploaded by`}</small>
					<p>{data.uploadedByName}</p>
				</li>
				<li>
					<small>{i18n`Document uploaded at`}</small>
					<p>{data.uploadedAt}</p>
				</li>
				<li>
					<small>{i18n`File Size`}</small>
					<p>{data.size}</p>
				</li>
				<li>
					<small>{i18n`Document Type`}</small>
					<p>{data.type}</p>
				</li>
			</ul>
		</>
	);

	const modifiersClasses = (modifiers || [])
		.map((m) => styles[m])
		.filter((m) => m)
		.join(" ");

	const renderDetail = () => {
		switch (data.__typename) {
			case "InvalidDataFile":
			case "StatementDetail":
				return renderActivityDetails();
			case "AttachmentDetail":
				return renderAttachmentsDetails();
			default:
				return renderFoldersDetails();
		}
	};
	const terminationLink = data && data.isNewCore ? "nc_audit" : `${prefix}/audit?filter=${stringifyFilter([
		{
			column: "ID",
			filters: [{ value: data && data.id? data.id + " | " + data.name : "" }],
		},
	])}&resetFilters=${Date.now()}`
	return (
		<Modal>
			<div className={styles["backdrop"]} />
			<div className={`${styles["modal-window"]}`}>
				<header className={styles["header"]}>
					<div>
                        <button className={styles['close-button']} onClick={() => history.goBack()}>
                            <Icon name="close" />
                        </button>
					</div>
					<div>
						<h5>{`${data ? data.id : ""} - ${data ? data.name || data.fileName : ""} `}</h5>
					</div>
				</header>
				<main className={styles["main"]}>
					<div className={`${styles["detail"]} ${modifiersClasses}`}>
					{!data && <LoadingIndicator className={`${styles["loading-indicator"]}`}/>}
						{data && data.totalFindings && data.totalFindings.length > 0 && (
							<div className={styles["wrap-buttons"]}>
								<Link
									to={`/${locale}/${projectId}/${
										fiscalYear ? fiscalYear : data.fiscalYear ?  data.fiscalYear : ""
									}/${data.isNewCore ? data.id : 'full'}/${terminationLink}`}
									className={styles["close"]}
									label={i18n`See Audit Content`}
								/>
							</div>
						)}
						{data && data.fiscalYear && data.status === "invalid" && (
							<div className={styles["wrap-buttons"]}>
								<Link
									to={`/${locale}/${projectId}/${
										data.fiscalYear ? data.fiscalYear : ""
									}/${data.isNewCore ? data.id : 'full'}/${prefix}/audit_schema?filter=${stringifyFilter([
										{
											column: "ID",
											filters: [{ value: data && data.id? data.id + " | " + data.name : "" }],
										},
									])}&resetFilters=${Date.now()}`}
									className={styles["close"]}
									label={i18n`See Audit Content`}
								/>
							</div>
						)}
						{data &&
							data.status === "imported" &&
							(data.type === "PtMod22File" ||
								data.type === "SaftPtMod30File") && (
								<div className={styles["wrap-buttons"]}>
									{data.type === "PtMod22File" ? (
										<Link
											to={`/${locale}/${projectId}/${fiscalYear}/full/pt_mod_22/cover_page`}
											className={styles["close"]}
											label={i18n`See Content`}
										/>
									) : (
										<div>
											<Link
												to={`/${locale}/${projectId}/${fiscalYear}/full/pt_mod_30/file`}
												className={styles["close"]}
												label={i18n`See Content`}
											/>
										</div>
									)}
								</div>
							)}
						{data &&
							!data.fiscalYear &&
							data.__typename !== "StatementDetail" &&
							data.__typename !== "AttachmentDetail" && (
								<div className={styles["wrap-buttons"]}>
									<Link
										to={`/${locale}/${projectId}/full/${prefix || "unknown"}/audit_findings?filter=${stringifyFilter([
											{
												column: "ID",
												filters: [{ value: data && data.id? data.id + " | " + data.name : "" }],
											},
										])}&resetFilters=${Date.now()}`}
										className={styles["close"]}
										label={i18n`Details`}
									/>
								</div>
							)}
						<div className={`${styles["content"]} ${styles[section]}`}>
							{data && renderDetail()}
						</div>
					</div>
				</main>
			</div>
		</Modal>
	);
};

export default FileDetail;