import { useEffect } from 'react'
import { matchRoutes } from 'react-router-config'
import { withRouter } from 'react-router'
import { parse } from 'query-string'

const buildQueries = (location, routes, modules) => {
	const branch = matchRoutes(routes, location.pathname)
	let routeQueries = []
	let globalQueries = []

	branch.forEach(({ route, match }) => {
		const componentQueries = route.component.queries || (() => [])
		const queryParams = parse(location.search) || {}
		const routeParams = match.params || {}
		const params = {
			...queryParams,
			...routeParams
		}
		const branchQueries = ((route.queries && route.queries(params)) || [])
			.filter(({ downloadOnly }) => !downloadOnly)
		const containerQueries = componentQueries(params, modules)
		if (branchQueries.length) {
			routeQueries.push(...branchQueries)
		}
		if (containerQueries.length) {
			globalQueries.push(...containerQueries)
		}
	})

	return [globalQueries, routeQueries]
}

const fetchData = (location, routes, dispatch, fetch, modules, refresh) => {
	const [globalQueries, routeQueries] = buildQueries(location, routes, modules)
	dispatch(fetch(globalQueries, refresh, true))
	dispatch(fetch(routeQueries, refresh))
	return Promise.resolve(null)
}

function DataLoader({ children, location, routes, dispatch, fetch, modules }) {
	useEffect(() => {
		fetchData(location, routes, dispatch, fetch, modules)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ dispatch, fetch, routes]);

	return children(() => {
		fetchData(location, routes, dispatch, fetch, modules, true)
	})
}

export default withRouter(DataLoader)