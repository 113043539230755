import React, { forwardRef, useRef, useImperativeHandle, useState, useEffect } from 'react'
import { TextInput } from 'colbi_web_ui/lib/components/inputs'
import { useGlobalValue } from 'colbi_web_ui/lib/state/GlobalProvider'
import { FORM_ACTIONS } from '../FormActions'
import styles from './GeneratorForm.module.sass'

const GeneratorForm = (props, ref) => {
	const { data,  status,onChange, onSave ,projectId} = props
	const initialDates = {
		"startDate": new Date(),
		"endDate": new Date()
	}
	const [inputs] = useState(initialDates)
	const formRef = useRef()
	const {
		fiscalYear,
	} = data || {}
	
	useEffect(() => {
		
	if (data) {
		data.projectId = projectId
	}
	}, [data, projectId])	
	
	const { i18n } = useGlobalValue()

	const handleChange = (e, prop, value) => {
		onChange && onChange({
			originalEvent: e,
			target: {
				value: {
					data: {
						...data,
						[prop]: value
					}
				}
			}
		})
	}

	

	useImperativeHandle(ref, () => ({
		submit: () => {
			formRef.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))
		}
	}))

	return status !== 'loading' ? (
		<form ref={formRef} onSubmit={(e) => {
			e.preventDefault()
			e.stopPropagation()
			if (formRef.current.checkValidity()) {
				data["__typename"] = "GeneratorPtConfiguration"
				for( const props in inputs ){
					data[`${props}`] = inputs[props]
				}
				onSave && onSave();
			} else {
				formRef.current.reportValidity()
			}
		}}>
			<div className={styles['group-content']}>
				<div className={styles['flex']}>
					
					<TextInput
						className={styles['input']}
						name={i18n`Fiscal Year`}
						placeholder={i18n`Enter a year`}
						value={fiscalYear || ''}
						required
						onChange={(e) => {
							handleChange(e, 'fiscalYear', e.target.value)
						}}
					/>
				</div>
			</div>
			
		</form>
	) : null

}


const GeneratorFormRef = forwardRef(GeneratorForm)

GeneratorFormRef.queries = (args) => {
	return [
		
	]
}

GeneratorFormRef.dataMapper = [ 'data']

GeneratorFormRef.actions = [FORM_ACTIONS.GENERATE]

export default GeneratorFormRef