const mapDetails = (json) => {
    const {
        movementReference, movementDate, movementType, lineNumber, deliveryId, deliveryDate, warehouseId, streetName, number, city, postalCode, country, addressType, region, fullAddress
    } = json;
    return [
        [
            {label: 'movementReference', value: movementReference, type: 'string'},
            {label: 'Movement Date', value: movementDate, type: 'string'},
            {label: 'Movement Type', value: movementType, type: 'string'},
            {label: 'Line Number', value: lineNumber, type: 'string'},
        ],
        [
            {label: 'Full Address', value: fullAddress, type: 'string'},
        ],
        [
            {label: 'Street Name', value: streetName, type: 'string'},
            {label: 'Number', value: number, type: 'string'},
            {label: 'City', value: city, type: 'string'},
            {label: 'Postal Code', value: postalCode, type: 'string'},
            {label: 'Country', value: country, type: 'string'},
            {label: 'Address Type', value: addressType, type: 'string'},
            {label: 'Region', value: region, type: 'string'},
        ],
        [
            {label: 'Delivery Id', value: deliveryId, type: 'string'},
            {label: 'Delivery Date', value: deliveryDate, type: 'string'},
            {label: 'Warehouse Id', value: warehouseId, type: 'string'},
        ]
    ]
};

const mapCard = (json) => {
    const {
        movementDate, deliveryId, deliveryDate, warehouseId, fullAddress, streetName, number, city, postalCode, country
    } = json;

    return [
        [
            {labels: ['Movement Date'], value: `${movementDate}`, type: 'string'},
        ],
        [
            {labels: ['Movement Type'], value: `${movementDate}`, type: 'string'},
        ],
        [
            {labels: ['Delivery ID'], value: `${deliveryId}`, type: 'string'},
        ],
        [
            {labels: ['Delivery Date'], value: `${deliveryDate}`, type: 'string'},
        ],
        [
            {labels: ['Warehouse ID'], value: `${warehouseId}`, type: 'string'},
        ],
        [
            {
                labels: ['Full Address'],
                value: `${fullAddress ? fullAddress : `${streetName} ${number} ${city} ${postalCode} ${country}`}`,
                type: 'string'
            },
        ],
    ]
};


const mapper = (json, card, details) => {

    return {
        ...json,
        name: json.description,
        shortName: json.lineNumber,
        details: details ? mapDetails(json) : [],
        card: card ? mapCard(json) : []
    }
};

const header = [
    {label: ['Movement Date'], align: 'left', columnName: "product_description", sortable: true},
    {label: ['Movement Type'], align: 'left', columnName: "product_description", sortable: true},
    {label: ['Delivery ID'], align: 'left', columnName: "product_description", sortable: true},
    {label: ['Delivery Date'], align: 'left', columnName: "product_description", sortable: true},
    {label: ['Warehouse ID'], align: 'left', columnName: "product_description", sortable: true},
    {label: ['Full Address'], align: 'left', columnName: "product_description", sortable: true}
];
const movementofgoodlineshiptoMapper =  {
    map: mapper,
    header
}
export default movementofgoodlineshiptoMapper;