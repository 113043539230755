import { date, format } from "../../../utils";

const mapDetails = (json) => {
	const {
		SapDocNo,
		SapDocType,
		InvoiceNo,
		InvoiceDate,
		InvoicePostingDate,
		TotalInvoiceValue,
		TotalTaxAmount,
		SupplierGSTIN,
		SupplierName,
		SupplierId,
		SupplierCountry,
		TaxableValue,
	} = json;

	return [
		[
			{ label: "SAP Document No.", value: SapDocNo, type: "string" },
			{ label: "SAP Document Type", value: SapDocType, type: "string" },
		],
		[
			{ label: "Invoice No.", value: InvoiceNo, type: "string" },
			{ label: "Invoice Date", value: date(InvoiceDate), type: "string" },
			{
				label: "Invoice Posting Date",
				value: date(InvoicePostingDate),
				type: "string",
			},
		],
		[
			{ label: "Supplier ID", value: SupplierId, type: "string" },

			{ label: "Supplier GSTIN", value: SupplierGSTIN, type: "string" },
			{ label: "Supplier Name", value: SupplierName, type: "string" },
			{ label: "Supplier Country", value: SupplierCountry, type: "string" },
		],

		[
			{
				label: "Taxable Value (₹)",
				value: format(TaxableValue, null, null, 2),
				type: "string",
				align: "left",
			},
			{
				label: "Tax Amount (₹)",
				value: format(TotalTaxAmount, null, null, 2),
				type: "string",
				align: "left",
			},
			{
				label: "Invoice Value (₹)",
				value: format(TotalInvoiceValue, null, null, 2),
				type: "string",
				align: "left",
			},
		],
	];
};

const mapCard = (json) => {
	const {
		SapDocNo,
		SapDocType,
		InvoiceNo,
		InvoiceDate,
		InvoicePostingDate,
		TotalInvoiceValue,
		TotalTaxAmount,
		SupplierGSTIN,
		TaxableValue,
	} = json;
	return [
		[
			{
				labels: ["SAP Document No."],
				value: SapDocNo,
				type: "string",
				basis: "25%",
			},
		],
		[
			{
				labels: ["SAP Document Type."],
				value: SapDocType,
				type: "string",
				basis: "25%",
			},
		],
		[{ labels: ["Invoice No."], value: InvoiceNo, type: "string" }],
		[{ labels: ["Invoice Date"], value: date(InvoiceDate), type: "string" }],
		[
			{
				labels: ["Invoice Posting Date"],
				value: date(InvoicePostingDate),
				type: "string",
			},
		],

		[{ labels: ["SupplierGSTIN"], value: SupplierGSTIN, type: "string" }],
		[
			{
				labels: ["Taxable Value (₹)"],
				value: format(TaxableValue, null, null, 2),
				type: "number",
				align: "right",
			},
		],
		[
			{
				labels: ["Tax Amount (₹)"],
				value: format(TotalTaxAmount, null, null, 2),
				type: "number",
				align: "right",
			},
		],
		[
			{
				labels: ["Invoice Value (₹)"],
				value: format(TotalInvoiceValue, null, null, 2),
				type: "number",
				align: "right",
			},
		],
	];
};

const mapper = (json, card, details) => {
	return {
		...json,
		name: json.name,
		shortName: json.name,
		details: details ? mapDetails(json) : [],
		card: card ? mapCard(json) : [],
	};
};

const header = [
	{
		label: ["SAP Document No."],
		align: "left",
		columnName: "SapDocNo",
		sortable: true,
		basis: "25%",
	},
	{
		label: ["SAP Document Type."],
		align: "left",
		columnName: "SapDocType",
		sortable: true,
		basis: "25%",
	},
	{
		label: ["Invoice No."],
		align: "left",
		columnName: "InvoiceNo",
		sortable: true,
	},
	{
		label: ["Invoice Date"],
		align: "left",
		columnName: "InvoiceDate",
		sortable: true,
	},
	{
		label: ["Invoice Posting Date"],
		align: "left",
		columnName: "InvoicePostingDate",
		sortable: true,
	},
	{
		label: ["Supplier GSTIN"],
		align: "left",
		columnName: "SupplierGSTIN",
		sortable: true,
	},
	{
		label: ["Taxable Value(₹)"],
		align: "right",
		columnName: "TaxableValue",
		sortable: false,
	},
	{
		label: ["Tax Amount (₹)"],
		align: "right",
		columnName: "TotalTaxAmount",
		sortable: true,
	},
	{
		label: ["Invoice Value (₹)"],
		align: "right",
		columnName: "TotalInvoiceValue",
		sortable: true,
	},
];

const pushcaseMapper = {
	map: mapper,
	header,
};

export default pushcaseMapper;
