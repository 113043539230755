import { format } from "colbi_web_ui/lib/utils"

export function convertBytesToMegaBytes(value, precison = 2){
	if( value ){
		return ((value/ Math.pow(1024,2)).toFixed(precison)).toString()
	}
	return "N/A"
}

export function adjustForTimezone(date){
	const adjustedDateTimeZone = new Date(date)
    const timeOffsetInMS = adjustedDateTimeZone.getTimezoneOffset() * 60000;
    return new Date(adjustedDateTimeZone.setTime(adjustedDateTimeZone.getTime() + timeOffsetInMS));
}

export function checkNumberValues(value) {
	if (value && value !== "N/A" ) {
		return format(value);
	}
	return "N/A";
}
