import { StackLayout } from '../layouts'
import { List, Filters, rows } from 'colbi_web_ui/lib/components'
import theme from 'colbi_web_ui/lib/style/_theme.json';
import { parseFilter } from 'colbi_web_ui/lib/utils'
import HeaderListButtons from '../actions/HeaderListButtons/HeaderListButtons'
import AppByFile from '../controllers/AppByFile/AppByFile';


const queries = (args = {}) => {
	return [
		
		{
			resource: 'generation_pt',
			args: {
				projectId: (args.projectId),
				fiscalYear: (args.fiscalYear),
				filter: args.filter ? { type: '[FilterInput!]', value: parseFilter(args.filter) } : undefined,
			},
			body: '__typename, hasMore, items{__typename, fileId, projectId,fiscalYear,dateFrom,fileType,dateTo,userId,status,fileLocation,message,userName,createdAt,fileTypeName}, filters(projectId:$projectId, fiscalYear:$fiscalYear){ prop, name, type, list }',
			downloadable: true
		}
	]
}

const generationPt = (path, navigation, exact, title) => ({
	path,
	navigation,
	component: AppByFile,
	queries,
	exact,
	title: [title, 'param(fiscalYear)'],
	navByImportId: true,
	components: [
		{
			component: Filters,
			props: {
				filter: 'param(filter)',
				availableFilters: 'query(0).filters',
				stickyTop: parseInt(theme.layout.$headerHeight, 10),
				anchor: 'results'
			}
		}
	],
	routes: [
		{
			path,
			exact: true,
			queries,
			component: StackLayout,
			components: [
				{
					component: List,
					modifiers: [
						'--root-margin-top-small',
						'--row-style-alternate'
					],
					props: {
						id: 'results',
						items: 'query(0).list',
						filter: 'param(filter)',
						basePath: path + '/rules',
						card: rows.EntityCard,
						metadata: 'query(0).metadata',
						page: 'param(page)',
						status: 'status(1)',
						pageSize: 'param(pageSize)',
						pageDefault: 10,
						header: 'query(0).header',
						headerListComponent: [
							{
								component: HeaderListButtons,
								props:{
									showGenerationPtButton: true,
								}
							}
						],
					}
				}
			]
		}
	]
})

export default generationPt
