import {mapCard, mapDetails, header} from "./findingStructure"

const mapper = (json, card) => {
    const name = json.name

    return {
        ...json,
        __typename: json.__typename,
        id: json.id,
        name,
        shortName: name,
        card: card ? mapCard(json) : [],
        details: mapDetails(json)
    }
}

const findingstructureMapper = {
    map: mapper,
    header
};

export default findingstructureMapper