import { format } from '../../../utils'

const mapDetails = (json) => {
	const {
		taxPaymentSlipId,
		taxPaymentSlipAmount
	} = json

	return [
		[
			{ label: 'taxPaymentSlipId', value: taxPaymentSlipId, type: 'string' }
		],
		[
			{ label: 'taxPaymentSlipAmount', value: format(taxPaymentSlipAmount), type: 'currency' }
		]
	]
}

const mapCard = (json) => {
	const {
		taxPaymentSlipId,
		taxPaymentSlipAmount
	} = json

	return [
		[
			{ labels: ['taxPaymentSlipId'], value: taxPaymentSlipId, type: 'string' },
		],
		[
			{ labels: ['taxPaymentSlipAmount'], value: format(taxPaymentSlipAmount), type: 'currency' }
		]
	]
}

const mapper = (json, card, details) => {
	return {
		...json,
		id: null,
		name: json.refNumber,
		shortName: json.refNumber,
		details: details ? mapDetails(json) : [],
		card: card ? mapCard(json) : []
	}
}

const header = [
	{ label: ["Tax Payment Slip Id"], align: 'left' },
	{ label: ["Tax Payment Slip Amount"], align: 'right' }
]

const taxpaymentslipmod30Mapper =  {
	map: mapper,
	header
}
export default taxpaymentslipmod30Mapper;