import { format } from "../../../utils"

const mapDetails = (json) => {
	const {
		description,
		productCode,
		barCode,
		type,
		group,
		kpiPercentageOnSales,
		kpiSalesTotalNet,
		kpiSalesDebitTotalNet,
		nomenclatureCodes,
		onuNumbers,
		taxEntity,
		goodsServicesId,
		valuationMethod,
		uomBase
	} = json

	return [
		[
			{ label: 'Code', value: productCode, type: 'string' },
			{ label: 'Bar Code', value: barCode, type: 'string' },
			{ label: 'Type', value: type, type: 'string' },
			{ label: 'Group', value: group, type: 'string' },
			{ label: 'Entity', value: taxEntity, type: 'string' },
			{ label: 'Entity', value: taxEntity, type: 'string' },
			{ label: 'Goods Services Id', value: goodsServicesId, type: 'string' },
			{ label: 'Valuation Method', value: valuationMethod, type: 'string' },
			{ label: 'Uom Base', value: uomBase, type: 'string' },
			{ label: 'Description', value: description, type: 'string' },
		],
		[
			{
				label: 'Percentage on sales',
				value: format((parseFloat(kpiPercentageOnSales) || 0).toFixed(2), 'normal', null),
				type: 'percentage',
				backgroundColor: '#74AF27',
				color: '#FFFFFF',
				icon: 'percentage'
			},
			{
				label: 'Sales Total (N)',
				value: format(kpiSalesTotalNet, 'fullValue'),
				type: 'currency',
				backgroundColor: '#8397A8',
				color: '#FFFFFF',
				icon: 'sales-amount'
			},
			{
				label: 'Total Credit and Returns (N)',
				value: format(kpiSalesDebitTotalNet, 'fullValue'),
				type: 'currency',
				backgroundColor: '#5B6975',
				color: '#FFFFFF',
				icon: 'returns'
			}
		],
		nomenclatureCodes && nomenclatureCodes.length ? {
			title: 'Nomenclature Codes',
			rows: [
				[
					{
						label: 'Codes',
						value: nomenclatureCodes.map((code) => (code.cnCode || 'N/A')).join('; ')
					}
				]
			]
		} : null,
		onuNumbers && onuNumbers.length ? {
			title: 'ONU Numbers',
			rows: [
				[
					{
						label: 'Numbers',
						value: onuNumbers.map((code) => (code.unNumber || 'N/A')).join('; ')
					}
				]
			]
		} : null
	]
}

const mapCard = (json) => {
	const {
		description,
		productCode,
		group
	} = json

	return [
		[
			{ labels: ['description'], value: `${ description }`, type: 'string' }
		],
		[
			{ labels: ['product_code'], value: `${ productCode }`, type: 'number' }
		],
		[
			{ labels: ['group'], value: group, type: 'number' }
		]
	]
}

const mapReports = (reports) => {
	const [salesProductPeriod, top, topClientsMovements] = reports
	const salesData = []
	for (let i=0; i < 12; i++) {
		salesData.push({
			period: i + 1,
			value: ((salesProductPeriod || []).find(({ period }) => period === i + 1) || {}).value || 0
		})
	}

	const topsTabs = []
	const topsData = []
	if ((top || []).length) {
		topsTabs.push('Top')
		topsData.push(
			top.map((json) => ({
				...json,
				__typename: 'Customer',
				percentage: Math.round(((json.value / json.total) * 100) * 100) / 100
			}))
		)
		topsData.push(
			(topClientsMovements || []).map((json) => ({
				...json,
				__typename: 'Customer',
				percentage: Math.round(((json.value / json.total) * 100) * 100) / 100
			}))
		)
	}
	if ((topClientsMovements || []).length) {
		topsTabs.push('Movements')
	}

	return (salesProductPeriod || []).length || (top || []).length || (topClientsMovements || []).length ? [
		{
			type: 'bar',
			icon: 'comparation',
			title: 'Sales (N)',
			colspan: 2,
			data: [salesData],
			links: [
				{
					filterColumn: 'period'
				}
			]
		},
		{
			type: 'tops',
			title: 'Clients (N)',
			tabs: topsTabs,
			colspan: 1,
			data: topsData
		},
	] : null
}

const mapper = (json, card, details) => {
	
	return {
		...json,
		name: json.description,
		shortName: json.productCode,
		details: details ? mapDetails(json) : [],
		card: card ? mapCard(json) : [],
		reports: mapReports(json.reports || [])
	}
}

const header = [
	{ label: ['Description'], align: 'left', columnName: "description", sortable: true },
	{ label: ['Code'], align: 'center' },
	{ label: ['Group'], align: 'center', columnName: "product_group", sortable: true }
]

const productMapper =  {
	map: mapper,
	header
}

export default productMapper;