import { checkNumberValues } from "../../../utils/convertUtils";

const mapCard = (json) => {
	const {
		value,
		nif,
		period
	} = json

	return [
		[
			{ labels: ['NIF'], value: nif, type: 'string', basis: '65%' },
		],
		[
			{ labels: ['Value'], value: checkNumberValues(value), type: 'number', basis: '20%' }
		],
		[
			{ labels: ['Period'], value: period, type: 'string', basis: '20%' }
		]
	]
}

const mapper = (json, card) => {
	return {
		...json,
		card: card ? mapCard(json) : []
	}
}

const header = [
	{ label: ["NIF"], align: 'left' },
	{ label: ["Value"], align: 'center' },
	{ label: ["Period"], align: 'left' },
]

const expensesMapper = {
	map: mapper,
	header
}

export default expensesMapper;