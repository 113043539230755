import I18n from '../../I18n'
import { datetime, format, convertBytesToMegaBytes, date } from '../../utils'
import { stringifyFilter } from 'colbi_web_ui/lib/utils'

const mapCard = (json) => {
	const {
		name,
		status,
		uploadedAt,
		submittedBy,
		fiscalYear,
		type,
		subType,
		statusCode,
		fileSizeDecompress,
		submissionStatus,
		startDate,
		endDate,
		responseFilePath,
		id,
		requestName, 
		requestAt
	} = json

	const hasFindings = statusCode ? ((status === "imported" && statusCode === 1008) ? 2 : 3) : null

	return [
		[
			{ labels: ['file_name'], value: name, type: 'string'}
		],
		[
			{ labels: ['fiscalYear'], value: fiscalYear, type: 'string'}
		],
		[
			{ labels: ['uploaded'], value: datetime(uploadedAt), type: 'datetime', mobileHidden: true }
		],
		[
			{ labels: ['fileSizeDecompress'], value: `${format(convertBytesToMegaBytes(fileSizeDecompress), null, null)} Mb`, type: 'number', mobileHidden: true, align: 'center'}
		],
		[
			{ labels: ['type'], value: I18n.translate`${type}`, type: 'string', mobileHidden: true}
		],
		[
			{ labels: ['subtype'], value: subType, type: 'string', mobileHidden: true}
		],
		[
			{ labels: ['user'], value: submittedBy, type: 'string', mobileHidden: true }
		],
		
		[
			{ 
				labels: ['status'], 
				value: [submissionStatus === "success"? "imported": submissionStatus, I18n.translate`${submissionStatus ? submissionStatus : 'To Submit'}`], 
				type: 'dataFileStatus',
				align: 'center'
			}
		],
		[
			{ labels: ['start'], value: date(startDate), type: 'datetime', mobileHidden: true , align: 'left'}
		],
		[
			{ labels: ['end'], value: date(endDate), type: 'datetime', mobileHidden: true, align: 'left' }
		],
		[
			{ labels: ['requestName'], value: requestName, type: 'string', mobileHidden: true, align: 'left' }
		],
		[
			{ labels: ['requestAt'], value: datetime(requestAt), type: 'datetime', mobileHidden: true, align: 'left' }
		],
		[
			{ labels: ['active'], value: hasFindings , type: 'is-active', align: 'left' }
		],
		[
            {
                labels: ['download'],
                value: responseFilePath? [responseFilePath, id] : null,
                type: 'downloadFile',
				align: 'center'
            }
        ],
		[
			{ labels: [], value: ['delete'], type: 'user_actions' }
		],

	]
}

const header = [
	{ label: ['Name'], align: 'left' , sortable: true,  columnName: "name"},
	{ label: ['Fiscal Year'], align: 'left' , sortable: true,  columnName: "fiscal_year"},
	{ label: ['Uploaded at'], align: 'left', sortable: true,  columnName: "uploaded_at", mobileHidden: true },
	{ label: ['File Size'], align: 'center', mobileHidden: true },
	{ label: ['Type'], align: 'left', mobileHidden: true},
	{ label: ['Sub Type'], align: 'left' , mobileHidden: true},
	{ label: ['Uploaded by'], align: 'left', mobileHidden: true },
	{ label: ['Status'], align: 'center'},
	{ label: ["Start Date"], align: 'left', mobileHidden: true },
	{ label: ["End Date"], align: 'left', mobileHidden: true },
	{ label: ["Requested By"], align: 'left', mobileHidden: true },
	{ label: ["Requested At"], align: 'left', mobileHidden: true },
	{ label: ['Findings'], align: 'left' },
	{ label: ["AT Response"], align: 'center', mobileHidden: true },
	{ label: ["checkbox"], align: 'right' },
]


const mapper = (json, card) => {
	const resType = json.type.toString().replace("File", "")
	return {
		...json,
		id: json.id,
		name: json.name,
		shortName: json.name,
		card: card ? mapCard(json) : [],
		link: {
			to: `/:locale(pt|en|lt|es)?/:projectId/repository/files_submission/${json.fiscalYear}/${resType}/${json.id}?filter=${
				stringifyFilter(
					[{ column: 'data_files.id', filters: [{ value: `${json.id}` }] }]
				)
				}`
		}
	}
}

const datafilesubmissionMapper = {
	map: mapper,
	header
}
export default datafilesubmissionMapper;