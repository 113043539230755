import I18n from "../../../I18n";

const mapDetails = json => {
    const { taxId, taxEntity, customerId, companyName, selfBilling} = json;

    return [
        [
            {label: 'Customer Id', value: customerId, type: 'string'},
            {label: 'Registration Number', value: taxId, type: 'string'},
            {label: 'Entity', value: taxEntity, type: 'string'},
            {label: 'Self Billing', value: selfBilling, type: 'boolean'},
            {label: 'Entity_Name', value: companyName, type: 'string'},
        ],
    ];
};

const mapCard = json => {
    const {
        companyName,
        taxId,
        taxEntity,
        customerId
    } = json;
    return [
        [{labels: ["Customer Id"], value: customerId, type: "string", basis: "12%"}],
        [{labels: ["Customer Name"], value: companyName, type: "string", basis: "50%"}],
        [{labels: ["nif"], value: `${taxId}`, type: "string"}],
        [{labels: ["entity"], value: `${taxEntity}`, type: "number"}],
    ];
};

const mapReports = reports => {
    const [salesClientPeriod, top, topProductsMovements] = reports;

    const salesData = [];

    for (let i = 0; i < 12; i++) {
        salesData.push({
            period: i + 1,
            value:
                ((salesClientPeriod || []).find(({period}) => period === i + 1) || {})
                    .value || 0
        });
    }

    const topsTabs = [];
    const topsData = [];
    if ((top || []).length) {
        topsTabs.push(I18n.translate("Top"));
        topsData.push(
            top.map(json => ({
                ...json,
                __typename: "Product",
                percentage: Math.round((json.value / json.total) * 100 * 100) / 100
            }))
        );
        topsData.push(
            topProductsMovements.map(json => ({
                ...json,
                __typename: "Product",
                percentage: Math.round((json.value / json.total) * 100 * 100) / 100
            }))
        );
    }

    return (salesClientPeriod || []).length ||
    (top || []).length ||
    (topProductsMovements || []).length
        ? [
            {
                type: "bar",
                icon: "comparation",
                title: I18n.translate("Sales (Net)"),
                colspan: 2,
                data: [salesData],
                links: [
                    {
                        filterColumn: "Period"
                    }
                ],
                currency: true
            },
            {
                type: "tops",
                title: I18n.translate("Products (N)"),
                tabs: topsTabs,
                colspan: 1,
                data: topsData
            }
        ]
        : null;
};

const mapper = (json, card, details) => {
    return {
        ...json,
        name: json.companyName,
        shortName: json.companyName,
        details: details ? mapDetails(json) : [],
        card: card ? mapCard(json) : [],
        reports: mapReports(json.reports || [])
    };
};

const header = [
    {label: ['Customer id'], align: 'left', columnName: "customer_id", sortable: true, basis: "12%"},
    {label: ['Entity_Name'], align: 'left', columnName: "customer_name", sortable: true, basis: "50%"},
    {label: ['Registration Number'], align: 'left', columnName: "customer_registration_number"},
    {label: ['Entity'], align: 'center', columnName: "entity"},
]

const customerMapper = {
    map: mapper,
    header
};

export default customerMapper;