import { format } from "../../utils";

const mapCard = (json) => {
	const {
		AccountID,
		Description,
		CreditAmount,
		DebitAmount,
		TransactionID,
		JournalID,
		Period,
	} = json;

	return [
		[{ labels: ["Account ID"], value: AccountID, type: "string", basis: "10%" }],
		[{ labels: ["Transaction ID"], value: TransactionID, type: "string", basis: "10%" }],
		[{ labels: ["Description"], value: Description, type: "string" }],
		[{ labels: ["Journal ID"], value: JournalID, type: "string", basis: "8%" }],
		[{ labels: ["Period"], value: Period, type: "number", basis: "5%" }],
		[
			{
				labels: ["Debit amount"],
				value: format(DebitAmount),
				type: "currency",
			},
		],
		[
			{
				labels: ["Credit amount"],
				value: format(CreditAmount),
				type: "currency",
			},
		],
	];
};

const header = [
	{ label: ["Account ID"], align: "left", basis: "10%" },
	{ label: ["Transaction ID"], align: "left", basis: "10%" },
	{ label: ["Description"], align: "left" },
	{ label: ["Journal ID"], align: "left", basis: "8%" },
	{ label: ["Period"], align: "center", basis: "5%" },
	{ label: ["Debit amount"], align: "right" },
	{ label: ["Credit amount"], align: "right" },
];

const mapper = (json, card) => {
	return {
		...json,
		name: json.name,
		shortName: json.name,
		card: card ? mapCard(json) : [],
		link: {
			to: `/:locale(pt|en|lt|es)?/:projectId/:fiscalYear([0-9]{4}|current)/:importId/:modulePrefix/transactions/${json.ID}`
		}
	};
};

const offsettingaccountMapper = {
	map: mapper,
	header
};

export default offsettingaccountMapper