const locales = () => {
	return [
		{
			id: "en",
			name: "EN",
		},
		{
			id: "pt",
			name: "PT",
		},
		{
			id: "lt",
			name: "LT",
		},
		{
			id: "es",
			name: "ES",
		},
		{
			id: "ro",
			name: "RO",
		},
	];
	
};

export default locales;
