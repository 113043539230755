import { checkNumberValues } from "../../../utils/convertUtils";

const mapCard = (json) => {
	const {
		diploma,
		period_allocation,
		benefit_period,
		nif,
		balance_of_transmitted_profit
	} = json

	return [
		[
			{ labels: ['NIF'], value: nif, type: 'string', basis: '20%' },
		],
		[
			{ labels: ['Diploma'], value: diploma, type: 'string', basis: '20%' }
		],
		[
			{ labels: ['Período a que respeita o benefício'], value: benefit_period, type: 'string', basis: '20%' }
		],
		[
			{ labels: ['Saldo do benefício transmitido'], value: checkNumberValues(balance_of_transmitted_profit), type: 'number', basis: '20%' }
		],
		[
			{ labels: ['Dotação do período'], value: checkNumberValues(period_allocation), type: 'number', basis: '20%' }
		],
	]
}

const mapper = (json, card) => {
	return {
		...json,
		card: card ? mapCard(json) : []
	}
}

const header = [
	{ label: ["NIF sociedade fundida, cindida ou contribuidora"], align: 'left', basis: '20%' },
	{ label: ["Diploma"], align: 'left', basis: '20%' },
	{ label: ["Período a que respeita o benefício"], align: 'left', basis: '20%' },
	{ label: ["Saldo do benefício transmitido"], align: 'left', basis: '20%' },
	{ label: ["Dotação do período"], align: 'center', basis: '20%' },
]

const colletiondeductionsboard077Mapper =  {
	map: mapper,
	header
}

export default colletiondeductionsboard077Mapper;