import { checkNumberValues } from "../../../utils/convertUtils";

const mapCard = (json) => {
	const {
		line_no,
		updated_cumulative_amount,
		updated_amount_enjoyed_financ,
		updated_amount_irc,
		updated_amount_imi,
		amount_enjoyed_imt,
		amount_enjoyed_selo,
		total_amount,
		cumulative_aid_intensity,
		amount_to_be_registered
	} = json

	return [
		[
			{ labels: ['N.º linha'], value: line_no, type: 'string', basis: "20%" },
		],
		[
			{ labels: ['Montante acumulado atualizado'], value: checkNumberValues(updated_cumulative_amount), type: 'number' },
		],
		[
			{ labels: ['Montante usufruido atualizado'], value: checkNumberValues(updated_amount_enjoyed_financ), type: 'number' },
		],
		[
			{ labels: ['IRC - Montante atualizado'], value: checkNumberValues(updated_amount_irc), type: 'number' },
		],
		[
			{ labels: ['IMI - Montante atualizado'], value: checkNumberValues(updated_amount_imi), type: 'number' },
		],
		[
			{ labels: ['IMT - Montante atualizado'], value: checkNumberValues(amount_enjoyed_imt), type: 'number' },
		],
		[
			{ labels: ['SELO - Montante atualizado'], value: checkNumberValues(amount_enjoyed_selo), type: 'number' },
		],
		[
			{ labels: ['Montante total atualizado dos benefícios usufruidos/utilizados'], value: checkNumberValues(total_amount), type: 'number' },
		],
		[
			{ labels: ['Intensidade de auxílio acumulada(em %)'], value: cumulative_aid_intensity ? cumulative_aid_intensity : 'N/A', type: 'percentage' },
		],
		[
			{ labels: ['Montante a inscrever no campo 372 do Q. 10 da M.22'], value: checkNumberValues(amount_to_be_registered), type: 'number' },
		]
	]
}

const mapper = (json, card) => {
	return {
		...json,
		card: card ? mapCard(json) : []
	}
}

const header = [
	{ label: ["N.º linha"], align: 'left', basis: "20%" },
	{ label: ["Montante acumulado atualizado"], align: 'left' },
	{ label: ["Montante usufruido atualizado"], align: 'left' },
	{ label: ["IRC - Montante atualizado"], align: 'left' },
	{ label: ["IMI - Montante atualizado"], align: 'left' },
	{ label: ["IMT - Montante atualizado"], align: 'left' },
	{ label: ["SELO - Montante atualizado"], align: 'left' },
	{ label: ["Montante total atualizado dos benefícios usufruidos/utilizados"], align: 'left' },
	{ label: ["Intensidade de auxílio acumulada(em %)"], align: 'left' },
	{ label: ["Montante a inscrever no campo 372 do Q. 10 da M.22"], align: 'left' },
]

const financialincentivesmod22Mapper = {
	map: mapper,
	header
}

export default financialincentivesmod22Mapper;