const mapDetails = (json) => {
    const {
        productCode, goodsServicesId, productGroup, description, uomBase
    } = json;

    return [
        [
            {label: 'Code', value: productCode, type: 'string'},
            {label: 'Group', value: productGroup, type: 'string'},
            {label: 'UOM Bases', value: uomBase, type: 'string'},
            {label: 'Good Service Id', value: goodsServicesId, type: 'string'},
        ],
        [],
        [
            {label: 'Description', value: description, type: 'string'},
        ]
    ]
};

const mapCard = (json) => {
    const {
        productCode, productGroup, description, goodsServicesId, uomBase
    } = json;

    return [
        [
            {labels: ['description'], value: `${description}`, type: 'string'}
        ],
        [
            {labels: ['product_code'], value: `${productCode}`, type: 'number'}
        ],
        [
            {labels: ['group'], value: productGroup, type: 'number'}
        ],
        [
            {labels: ['group'], value: goodsServicesId, type: 'number'}
        ],
        [
            {labels: ['group'], value: uomBase, type: 'number'}
        ]
    ]
};


const mapper = (json, card, details) => {

    return {
        ...json,
        name: json.description,
        shortName: json.productCode,
        details: details ? mapDetails(json) : [],
        card: card ? mapCard(json) : []
    }
};

const header = [
    {label: ['Description'], align: 'left', columnName: "description", sortable: true},
    {label: ['Code'], align: 'center', sortable: true, columnName: "product_code"},
    {label: ['Group'], align: 'center', columnName: "product_group", sortable: true},
    {label: ['Goods Services Id'], align: 'center', columnName: "goods_services_id", sortable: true},
    {label: ['Uom Base'], align: 'center', columnName: "uom_base", sortable: true}
];
const productMapper = {
    map: mapper,
    header
}

export default productMapper;