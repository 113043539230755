import { format } from '../../../utils'

const mapDetails = (json) => {
	const {
        warehouseId,
        productId,
        unitMeasure,
        
        openingQuantity,
        closingQuantity,
        unitPrice,
        openingValue,
        closingValue,

        stockOwner,
        stockOwnerId,
        numberOfCustomsProcedures,
        uomBaseConversionFactor,
        numberOfStockCharacteristics,
        numberOfPhysicalStockAcquisitions,
        taxEntity
	} = json
	return [
		[
            { label: 'Warehouse ID', value: warehouseId, type: 'string' },
            { label: 'Product Id', value: productId, type: 'string' },
            { label: 'Entity', value: taxEntity, type: 'string' },
            { label: 'Unit of Measure', value: unitMeasure, type: 'string' }
        ],
        [
            { label: 'Unit Price', value: format(unitPrice, 'fullValue'), type: 'currency' },
            { label: 'Opening Value', value: format(openingValue, 'fullValue'), type: 'currency' },
            { label: 'Closing Value', value: format(closingValue, 'fullValue'), type: 'currency' }
        ],
        [
            { label: 'Opening Quantity', value: format(openingQuantity, "fullValue", null), type: 'number' },
            { label: 'Closing Quantity', value: format(closingQuantity, "fullValue", null), type: 'number' },
            { label: 'Stock Owner', value: stockOwner, type: 'string' },
            { label: 'Stock Owner Id', value: stockOwnerId, type: 'string' },
            { label: 'Number of Customs Procedures', value: numberOfCustomsProcedures, type: 'string' },
            { label: 'UOM Base Conversion Factor', value: uomBaseConversionFactor, type: 'string' },
            { label: 'Number of Stock Characteristics', value: numberOfStockCharacteristics, type: 'string' },
            { label: 'Number of Physical Stock Acquisitions', value: numberOfPhysicalStockAcquisitions, type: 'string' }

        ]
	]
}

const header = [
    { label: ["Product Code"], align: 'left', columnName: "product_code", sortable: true},
    { label: ["Warehouse Id"], align: 'left', columnName: "warehouse_id", sortable: true},
    { label: ["Unit of Measure"], align: 'left', columnName: "uom", sortable: true },
    { label: ["Opening Quantity"], align: 'left', columnName: "opening_stock_quantity", sortable: true },
    { label: ["Closing Quantity"], align: 'left', columnName: "closing_stock_quantity", sortable: true },
    { label: ["Unit Price"], align: 'right', columnName: "unit_price", sortable: true },
    { label: ["Opening Value"], align: 'right', columnName: "opening_stock_value", sortable: true, totalizerName: 'total_opening_stock_value' },
    { label: ["Closing Value"], align: 'right', columnName: "closing_stock_value", sortable: true, totalizerName: 'total_closing_stock_value' }
]

const mapCard = (json) => {
	const {
        warehouseId,
        productId,
        unitMeasure,
        unitPrice,
        openingValue,
        closingValue,
        openingQuantity,
        closingQuantity
	} = json

	return [
        [
            { labels: ['code'], value: productId || 'N/A', type: 'string' }
        ],
        [
            { labels: ['warehouseId'], value: warehouseId || 'N/A', type: 'string' }
        ],
        [
            { labels: ['uom'], value: unitMeasure, type: 'string' }
        ],
        [
            { labels: ['opening_quantity'], value: format(openingQuantity, null, null), type: 'number' }
        ],
        [
            { labels: ['closing_quantity'], value: format(closingQuantity, null, null), type: 'number' }
        ],
        [
            { labels: ['unit_price'], value: format(unitPrice), type: 'currency' }
        ],
        [
            { labels: ['opening_value'], value: format(openingValue), type: 'currency' }
        ],
        [
            { labels: ['closing_value'], value: format(closingValue), type: 'currency' }
        ]
	]
}

const mapper = (json, card, details) => {
    const name = json.product_code
    
    return {
        ...json,
        __typename: json.__typename,
        id: json.id,
		name,
        shortName: name,
        details: details ? mapDetails(json) : [],
		card: card ? mapCard(json) : []
	}
}

const physicalstockMapper = {
    map: mapper,
    header
}

export default physicalstockMapper;