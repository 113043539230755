import { buildAddressRow } from '../mappersUtils'

const mapDetails = (json) => {
	const {
		taxId,
		taxEntity,
		accountId,
		phone,
		contact,
		supplierId,
		companyName,
		selfBilling,
		fax,
		email,
		website,
		billingAddress,
		shipAddresses
	} = json

	return [
		[
			{ label: 'Supplier Id', value: supplierId, type: 'string' },
			{ label: 'VAT', value: taxId, type: 'string' },
			{ label: 'Account Id', value: accountId, type: 'string' },
			{ label: 'Tax Entity', value: taxEntity, type: 'string' },
			{ label: 'Self Billing', value: selfBilling, type: 'boolean' },
			{ label: 'Company', value: companyName, type: 'string' },

		],
		[],
		{
			title: 'Contacts',
			rows: [
				[
					{ label: 'Contact', value: contact || 'N/A', type: 'string' },
					{ label: 'Phone', value: phone || 'N/A', type: 'string' },
					{ label: 'Fax', value: fax || 'N/A', type: 'string' },
					{ label: 'Email', value: email || 'N/A', type: 'string' },
					{ label: 'Website', value: website || 'N/A', type: 'string' },
				]
			]
		},
		(billingAddress || []).length ? {
			title: 'Billing Address',
			rows: [
				buildAddressRow(billingAddress)
			]
		} : null,
		(shipAddresses || []).length ? {
			title: 'Shipping Addresses',
			rows: (shipAddresses || []).map(address => buildAddressRow(address))
		} : null
	]
}

const mapCard = (json) => {
	const {
		companyName,
		taxId,
		taxEntity,
		taxIdCount,
		accountId,
		supplierId,
		country
	} = json

	return [
		[{ labels: ["Supplier Id"], value: supplierId, type: "string", basis: "12%" }],	
		[{ labels: ["name"], value: companyName, type: "string", basis: "50%" }],	
		[{ labels: ["nif"], value: `${taxId}`, type: "string" }],
		[{ labels: ["id"], value: `${accountId}`, type: "string" }],
		[{ labels: ["entity"], value: `${taxEntity}`, type: "number" }],
		[{ labels: ["country"], value: country, type: "string", basis: "5%" }],
		[{ labels: ["nif_count"], value: `(${taxIdCount})`, type: "number" }]
	]
}

const mapper = (json, card, details) => {
	
	return {
		...json,
		name: json.companyName,
		shortName: json.companyName,
		card: card ? mapCard(json) : [],
		details: details ? mapDetails(json) : []
	}
}

const header = [
	{ label: ['Supplier id'], align: 'left', columnName: "supplier_id", sortable: true, basis: "12%" },
	{ label: ['Entity_Name'], align: 'left', columnName: "company_name", sortable: true, basis: "50%" },
	{ label: ['VAT'], align: 'left' }, 
	{ label: ['Account ID'], align: 'left', columnName: "account_id", sortable: true },
	{ label: ['Tax Entity'], align: 'center', columnName: "tax_entity" },
	{ label: ['Country'], align: 'center', basis: "5%" },
	{ label: ['VAT (Count)'], align: 'center', columnName: "supplier_tax_id_dup", sortable: true }
]

const supplierMapper = {
	map: mapper,
	header
}

export default supplierMapper;