const mapDetails = json => {
    const { customerId, taxRegistrationNumber, taxRegistrationNumber1, customerName, streetName, city, postalCode, region, telephone, fax, accountid, toRemove, vendorVatNumber, country } = json;

    return [
        [
            { label: 'Customer Id', value: customerId, type: 'string' },
            { label: 'Entity Name', value: customerName, type: 'string' },
            { label: 'tax registration number', value: taxRegistrationNumber, type: 'string' },
            { label: 'taxRegistrationNumber1', value: taxRegistrationNumber1, type: 'string' },
            { label: 'account id', value: accountid, type: 'string' },
            { label: 'vendorVatNumber', value: vendorVatNumber, type: 'string' }
        ], [
            { label: 'telephone', value: telephone, type: 'string' },
            { label: 'fax', value: fax, type: 'string' }
        ], [
            { label: 'street', value: streetName, type: 'string' },
            { label: 'city', value: city, type: 'string' },
            { label: 'postal Code', value: postalCode, type: 'string' },
            { label: 'region', value: region, type: 'string' },
            { label: 'country', value: country, type: 'string' }
        ], [
            { label: 'toRemove', value: toRemove, type: 'string' },
        ]
    ];
};

const mapCard = json => {
    const {
        customerId,
        customerName,
        taxRegistrationNumber,
        country
    } = json;

    return [
        [{ labels: ["Customer Id"], value: customerId, type: "string", basis: "12%" }],
        [{ labels: ["Customer Name"], value: customerName, type: "string", basis: "50%" }],
        [{ labels: ["nif"], value: `${taxRegistrationNumber}`, type: "string" }],
        [{ labels: ["country"], value: `${country}`, type: "string" }],
    ];
};

const mapper = (json, card, details) => {
    return {
        ...json,
        name: json.customerId,
        shortName: json.customerId,
        details: details ? mapDetails(json) : [],
        card: card ? mapCard(json) : []
    };
};

const header = [
    { label: ['Customer id'], align: 'left', columnName: "customer_id", sortable: true, basis: "12%" },
    { label: ['Entity Name'], align: 'left', columnName: "customer_name", sortable: true, basis: "50%" },
    { label: ['Registration Number'], align: 'left', columnName: "taxRegistrationNumber" },
    { label: ['country'], align: 'left', columnName: "country" },
]
const customerMapper =  {
    map: mapper,
    header
};

export default customerMapper;

