import AppByFile from '../controllers/AppByFile/AppByFile'
import { AuditSummaryWidget, Grid, cards, Widget, charts } from 'colbi_web_ui/lib/components'

const queries = (args = {}) => {
	return args.fiscalYear ? [
		{
			resource: 'saftlt_project_summary',
			args: {
				projectId: (args.projectId),
                fiscalYear: (args.fiscalYear),
			},
			body: '...saftlt_project_summary'
		},
		{
			resource: 'audit_summary',
			args: {
				projectId: (args.projectId),
				fiscalYear: (args.fiscalYear),
				modulePrefix: 'saftlt',
				importId: "full"
			},
			body: 'severity, count'
		},
		{
			resource: `saftlt_invoices_summary`,
			args: {
				projectId: (args.projectId),
				fiscalYear: (args.fiscalYear),
				modulePrefix: 'saftlt'
			},
			body: 'summary{ key, value }'
		},
		{
			resource: 'saftlt_reports',
			args: {
				projectId: (args.projectId),
				fiscalYear: (args.fiscalYear),
				modulePrefix: 'saftlt'
			},
			body: 'report'
		},
		{
			resource: 'saftlt_project_dimension_master_data',
			args: {
				projectId: (args.projectId),
                fiscalYear: (args.fiscalYear),
                modulePrefix: 'saftlt'
			},
			body: '...saftlt_project_dimension_master_data'
		},
		{
			resource: 'saftlt_project_dimension_transaction',
			args: {
				projectId: (args.projectId),
                fiscalYear: (args.fiscalYear),
                modulePrefix: 'saftlt'
			},
			body: '...saftlt_project_dimension_transaction'
		},
		{
			resource: 'saftlt_project_dimension',
			args: {
				projectId: (args.projectId),
                fiscalYear: (args.fiscalYear),
                modulePrefix: 'saftlt'
			},
			body: '...saftlt_project_dimension'
		},
		{
			resource: `saftlt_purchase_invoices_summary`,
			args: {
				projectId: (args.projectId),
                fiscalYear: (args.fiscalYear),
				modulePrefix: 'saftlt'
			},
			body: 'summary{ key, value }'
		},
		{
			resource: 'multiple_check_has_data',
			args: {
				projectId: (args.projectId),
				fiscalYear: (args.fiscalYear),
				modulePrefix: "saftlt",
				entities: "invoices,purchase_invoices"
			},
			body: 'salesInvoices,purchaseInvoices,purchaseInvoicesSalesInvoices'
		},
	] : []
}

const overviewSaftLt = (path, navigation, exact, title) => ({
	path,
	navigation,
	component: AppByFile,
	queries,
	exact,
	title: [title],
	components: [
		{
			component: AuditSummaryWidget,
			modifiers: ['--root-margin-bottom-normal'],
			props: {
				icon: 'audit-icon',
				title: 'label(Audit Results)',
				summary: 'query(1).list',
				link: {
					to: 'audit',
					name: 'label(See All)'
				},
				overviewSummaryWidget: true
			}
		},
		{
			component: Widget,
			modifiers: ['--root-margin-bottom-normal'],
			props: {
				icon: 'sales',
				title: 'label(Sales Invoices KPIS)',
				setClose: "query(0).setClose",
				children: [
					{
						component: Grid,
						modifiers: ['--grid-border-inline'],
						props: {
							columnsTemplate: [1],
							items: [
								{
									data: [
										{
											name: 'label(Sales Gross Total)',
											value: 'currency(query(0).invoicesGrossTotal)',
											icon: 'gross-sales'
										},
										{
											name: 'label(Sales Net Total)',
											value: 'currency(query(0).invoicesNetTotal)',
											icon: 'net-sales'
										},
										{
											name: 'label(Invoice Avg Net)',
											value: 'currency(query(0).invoicesNetAverage)',
											icon: 'kpi-icon'
										},
										{
											name: 'label(Invoice Max Value)',
											value: 'currency(query(0).invoicesMaxValue)',
											icon: 'kpi-icon'
										}
									]
									
								}
							],
							cards: [charts.KpisLegend]
						}
					}
				]
			}
		},
		{
			component: Widget,
			modifiers: ['--root-margin-bottom-normal'],
			props: {
				icon: 'sales',
				title: 'label(Sales Invoices Summary)',
				setClose: "query(8).salesInvoices",
				summary: [
					{
						name: 'label(No. Invoices)',
						value: 'number(query(0).invoicesCount)'
					},
					{
						name: 'label(No. Invoices Lines)',
						value: 'number(query(0).invoiceLinesCount)'
					}
				],
				link: {
					to: 'invoices',
					name: 'label(Invoices)'
				},
				children: [
					{
						component: Grid,
						modifiers: ['--grid-border-inline'],
						props: {
							columnsTemplate: [1],
							aspect: ['31x10'],
							items: [
								{
									data: 'query(2).list',
									stacked: true,
									summarized: true,
									links: [{
										to: ['param(projectId)', 'param(fiscalYear)', 'param(importId)', 'param(modulePrefix)', '/invoices'],
										filterColumn: 'period',
									}],
									modifiers: ['--top-text-small'],
								},
								
							],
							
							cards: [charts.BarChart]
						}
					}
				]
			}
		},
		{
			component: Grid,
			modifiers: [
				'--root-margin-bottom-normal',
				'--cardholder-background-color-widget',
				'--mobile--cardholder-aspect-ratio-4x5'
			],
			props: {
				columnsTemplate: [1, 1, 1],
				aspect: ['5x4', '5x4', '5x4'],
				items: [
					{
						title: 'label(Sales (Net))',
						data: ['query(3).report.0'],
						path: "/:locale(pt|en|lt|es)?/:projectId/:fiscalYear([0-9]{4}|current)/:importId/:modulePrefix/master_data/products/:id"
					},
					{
						title: 'label(Customers (Net))',
						data: ['query(3).report.1'],
						path: "/:locale(pt|en|lt|es)?/:projectId/:fiscalYear([0-9]{4}|current)/:importId/:modulePrefix/master_data/customers/:id"
					},
					{
						title: 'label(Top sales by country)',
						data: ['query(3).report.4'],
						icon: 'top',
						path: "/:locale(pt|en|lt|es)?/:projectId/:fiscalYear([0-9]{4}|current)/:importId/:modulePrefix/invoices"
					}
				],
				cards: [cards.TopsCard, cards.TopsCard, cards.SalesByCountryCard]
			}
		},
		{
			component: Widget,
			modifiers: ['--root-margin-bottom-normal'],
			props: {
				icon: 'sales',
				title: 'label(Purchase Invoices KPIS)',
				setClose: "query(0).setClose",
				children: [
					{
						component: Grid,
						modifiers: ['--grid-border-inline'],
						props: {
							columnsTemplate: [1],
							items: [
								{
									data: [
										{
											name: 'label(Purchase Invoices Gross Total)',
											value: 'currency(query(0).purchasesGrossTotal)',
											icon: 'gross-sales'
										},
										{
											name: 'label(Purchase Invoices Net Total)',
											value: 'currency(query(0).purchasesNetTotal)',
											icon: 'net-sales'
										},
										{
											name: 'label(Purchase Invoice Avg Net)',
											value: 'currency(query(0).purchasesNetAverage)',
											icon: 'kpi-icon'
										},
										{
											name: 'label(Purchase Invoice Max Value)',
											value: 'currency(query(0).purchasesMaxValue)',
											icon: 'kpi-icon'
										}
									]
									
								}
							],
							cards: [charts.KpisLegend]
						}
					}
				]
			}
		},
		{
			component: Widget,
			modifiers: ['--root-margin-bottom-normal'],
			props: {
				icon: 'sales',
				title: 'label(Purchase Invoices Summary)',
				setClose: "query(8).purchaseInvoices",
				summary: [
					{
						name: 'label(No. Purchase Invoices)',
						value: 'number(query(0).purchasesCount)'
					},
					{
						name: 'label(No. Purchase Invoice Lines)',
						value: 'number(query(0).purchaseLinesCount)'
					}
				],
				link: {
					to: 'purchase_invoices',
					name: 'label(Purchases)'
				},
				children: [
					{
						component: Grid,
						modifiers: ['--grid-border-inline'],
						props: {
							columnsTemplate: [1],
							aspect: ['31x10'],
							items: [
								{
									data: 'query(7).list',
									stacked: true,
									summarized: true,
									links: [{
										to: ['param(projectId)', 'param(fiscalYear)','param(importId)', 'param(modulePrefix)','/purchase_invoices'],
										filterColumn: 'period',
									}],
									modifiers: ['--top-text-small'],
								},
								
							],
							cards: [charts.BarChart]
						}
					}
				]
			}
		},
		{
			component: Grid,
			modifiers: [
				'--root-margin-bottom-normal',
				'--cardholder-background-color-widget',
				'--mobile--cardholder-aspect-ratio-4x5'
			],
			props: {
				columnsTemplate: [1, 1, 1],
				aspect: ['5x4', '5x4', '5x4'],
				items: [
					{
						title: 'label(Purchase Invoices (Net))',
						data: ['query(3).report.5'],
						path: "/:locale(pt|en|lt|es)?/:projectId/:fiscalYear([0-9]{4}|current)/:importId/:modulePrefix/master_data/products/:id"
					},
					{
						title: 'label(Suppliers (Net))',
						data: ['query(3).report.6'],
						path: "/:locale(pt|en|lt|es)?/:projectId/:fiscalYear([0-9]{4}|current)/:importId/:modulePrefix/master_data/suppliers/:id"
					},
					{
						title: 'label(Top purchases by country)',
						data: ['query(3).report.7'],
						icon: 'top',
						path: "/:locale(pt|en|lt|es)?/:projectId/:fiscalYear([0-9]{4}|current)/:importId/:modulePrefix/purchase_invoices"
					}
				],
				cards: [cards.TopsCard, cards.TopsCard, cards.SalesByCountryCard]
			}
		},
		{
			component: Grid,
			modifiers: [
				'--grid-padding-none',
				'--root-margin-bottom-normal'
			],
			props: {
				columnsTemplate: [1],
				items: [
					{
						title: 'label(Sales vs Purchases)',
						icon: 'comparation',
						setClose: "query(8).purchaseInvoicesSalesInvoices",
						children: [
							{
								component: Grid,
								modifiers: ['--grid-border-inline', '--grid-margin-none', '--grid-padding-none'],
								props: {
									columnsTemplate: [1],
									aspect: ['31x10'],
									items: [
										{
											currency: true,
											data: ['query(3).report.2', 'query(3).report.3'],
											dataLabels: ['label(Sales)', 'label(Purchases)'],
											links: [
												{
													to: ['param(projectId)', 'param(fiscalYear)', 'param(importId)', 'param(modulePrefix)','/invoices'],
													filterColumn: 'period'
												},
												{
													to: ['param(projectId)', 'param(fiscalYear)', 'param(importId)', 'param(modulePrefix)', '/purchase_invoices'],
													filterColumn: 'period'
												}, {}
											]
										}
									],
									cards: [charts.BarChart]
								}
							}
						]
					},
				],
				cards: [Widget]
			}
		},
		{
			component: Grid,
			modifiers: [
				'--root-margin-bottom-normal',
				'--root-background-color-widget-background-color'
			],
			props: {
				columnsTemplate: [1, 1, 1],
				items: [
					{
						title: 'label(Master Data)',
						items: [
							{
								name: 'label(Customers)',
								count: 'number(query(4).customers)',
								link: `${path}/master_data/customers`
							},
							{
								name: 'label(Suppliers)',
								count: 'number(query(4).suppliers)',
								link: `${path}/master_data/suppliers`
							},
							{
								name: 'label(Products)',
								count: 'number(query(4).products)',
								link: `${path}/master_data/products`
							},
							{
								name: 'label(General Ledgers)',
								count: 'number(query(4).general_ledgers)',
								link: `${path}/master_data/general_ledgers`
							},
							{
								name: 'label(Journals)',
								count: 'number(query(4).journals)',
								link: `${path}/master_data/journals`
							},
							{
								name: 'label(Tax Table Entries)',
								count: 'number(query(4).tax_table_entries)',
								link: `${path}/master_data/tax_table_entries`
							},
							{
								name: 'label(Headers)',
								count: 'number(query(4).headers)',
								link: `${path}/master_data/headers`
							},
							{
								name: 'label(Uom Table Entries)',
								count: 'number(query(4).uom_table_entries)',
								link: `${path}/master_data/uom_table_entries`
							},
							{
								name: 'label(Movement Types)',
								count: 'number(query(4).movement_types)',
								link: `${path}/master_data/movement_types`
							},
							{
								name: 'label(Physical Stocks)',
								count: 'number(query(4).physical_stocks)',
								link: `${path}/master_data/physical_stocks`
							},
							{
								name: 'label(Owners)',
								count: 'number(query(4).owners)',
								link: `${path}/master_data/owners`
							},
							{
								name: 'label(Analysis Type Table Entries)',
								count: 'number(query(4).analysis_type_table_entries)',
								link: `${path}/master_data/analysis_type_table_entries`
							},
							{
								name: 'label(Assets)',
								count: 'number(query(4).assets)',
								link: `${path}/master_data/assets`
							},
							{
								name: 'label(General Ledger Accounts)',
								count: 'number(query(4).general_ledger_accounts)',
								link: `${path}/master_data/general_ledger_accounts`
							},
						]
					},
					{
						title: 'label(Dimensions)',
						items: [
							{
								name: 'label(Customer Countries)',
								count: 'number(query(6).customerCountries)'
							},
							{
								name: 'label(Supplier Countries)',
								count: 'number(query(6).supplierCountries)'
							},
							{
								name: 'label(Products Group)',
								count: 'number(query(6).productsGroup)'
							},
							{
								name: 'label(Products Code)',
								count: 'number(query(6).productsCode)'
							},
							{
								name: 'label(Customers on Sales)',
								count: 'number(query(6).customersOnSales)'
							},
							{
								name: 'label(Suppliers on Purchases)',
								count: 'number(query(6).supplierOnPurchases)'
							},
							{
								name: 'label(Products on Transactions)',
								count: 'number(query(6).productsOnTransaction)'
							}
						]
					},
					{
						title: 'label(Transactions)',
						items: [
							{
								name: 'label(Sales Invoices)',
								count: 'number(query(5).invoices)',
								link: `${path}/invoices`
							},
							{
								name: 'label(Sales Invoice Lines)',
								count: 'number(query(5).invoice_lines)'
							},
							{
								name: 'label(Purchase Invoices)',
								count: 'number(query(5).purchase_invoices)',
								link: `${path}/purchase_invoices`
							},
							{
								name: 'label(Purchase Invoice Lines)',
								count: 'number(query(5).purchase_invoice_lines)',
							},
							{
								name: 'label(Payments)',
								count: 'number(query(5).payments)',
								link: `${path}/payments`
							},
							{
								name: 'label(Payment Lines)',
								count: 'number(query(5).payment_lines)',
							},
							{
								name: 'label(Stock Movements)',
								count: 'number(query(5).stock_movements)',
								link: `${path}/stock_movements`
							},
							{
								name: 'label(Stock Movement Lines)',
								count: 'number(query(5).stock_movement_lines)',
							},
							{
								name: 'label(GL Transactions)',
								count: 'number(query(5).transactions)',
								link: `${path}/transactions`
							},
							{
								name: 'label(GL Transaction Lines)',
								count: 'number(query(5).transaction_lines)',
							},
							{
								name: 'label(Asset Transactions)',
								count: 'number(query(5).asset_transactions)',
								link: `${path}/asset_transactions`
							}
						]
					}

				],
				cards: [cards.LinksCard]
			}
		}
	]
})

export default overviewSaftLt
