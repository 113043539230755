import { format, date, datetime } from "../../../utils"

const mapDetails = (json) => {
	const {
		journal,
		period,
		glPostingDate,
		debit,
		credit,
		description,
		taxEntity,
		transactionDate,
		systemEntryDate,
		customer,
		supplier
	} = json

	return [
		[
			journal ? { label: 'Journal', value: journal.name, type: 'string' } : null,
			{ label: 'Period', value: period, type: 'number' },
			{ label: 'System Entry Date', value: datetime(systemEntryDate), type: 'date' },
			{ label: 'GL Posting Date', value: date(glPostingDate), type: 'date' },
			{ label: 'Transaction Date', value: date(transactionDate), type: 'date' },
			{ label: "Description", value: description, type: 'string' }
		],
		[
			{ label: 'Debit', value: format(debit, 'fullValue'), type: 'currency' },
			{ label: 'Credit', value: format(credit, 'fullValue'), type: 'currency' }
		],
		[
			customer ? { label: 'Customer Name', value: { id: customer.id, name: customer.name, __typename: 'Customer' }, type: 'object' } : null,
			supplier ? { label: 'Supplier Name', value: { id: supplier.id, name: supplier.name, __typename: 'Supplier' }, type: 'object' } : null,
			{ label: 'Entity', value: taxEntity, type: 'string' },
		]
	]
}

const mapCard = (json) => {

	const {
		transactionId,
		numberOfLines,
		description,
		journal,
		period,
		glPostingDate,
		transactionDate,
		debit,
		credit,
		customer,
		supplier
	} = json

	return [
		[{ labels: ['ID'], value: `${ transactionId }`, type: 'string', basis: '25%'}],
		[{ labels: ['journal'], value: journal ? journal.name : null, type: 'string', mobileHidden: true , basis: '25%'}],
		[{ labels: ['period'], value: period, type: 'number',align:'right', basis: '10%' }],
		[{ labels: ['date'], value: date(glPostingDate), type: 'date' }],
		[{ labels: ['date'], value: date(transactionDate), type: 'date', basis: '26%' }],
		[{ labels: ['description'], value: `${ description }`, type: 'string', mobileHidden: true, basis: '40%'}],
		[customer ? { label: 'Customer Name', value: { id: customer.id, name: customer.name, __typename: 'Customer' }, type: 'object' } : null],
		[supplier ? { label: 'Supplier Name', value: { id: supplier.id, name: supplier.name, __typename: 'Supplier' }, type: 'object' } : null],
		[{ labels: ['lines'], value: `${ numberOfLines }`, type: 'number', mobileHidden: true,align:'right', basis: '10%' }],
		[{ labels: ['debit'], value: format(debit, 'normal'), type: 'currency' }],
		[{ labels: ['credit'], value: format(credit), type: 'currency' }]
	]
}

const mapper = (json, card, details) => {

	return {
		...json,
		name: json.transactionId,
		shortName: json.transactionId,
		details: details ? mapDetails(json) : [],
		card: card ? mapCard(json) : []
	}
}

const header = [
	{ label: ["ID"], align: 'left', columnName: "transaction_id", sortable: true, basis: '25%' },
	{ label: ["Journal Type"], align: 'left', mobileHidden: true, basis: '25%',columnName: "journal__journal_type", sortable: true},
	{ label: ["Period"], align: 'center', columnName: "period", sortable: true, basis: '10%' },
	{ label: ["GL Posting Date"], align: 'center',columnName: "gl_posting_date", sortable: true},
	{ label: ["Transaction Date"], align: 'center',columnName: "transaction_date", sortable: true, basis: '26%' },
	{ label: ["Description"], align: 'left', mobileHidden: true, basis: '40%',columnName: "description", sortable: true  },
	{ label: ["Customer Name"], align: 'left', mobileHidden: true ,columnName: "customer__name", sortable: true },
	{ label: ["Supplier Name"], align: 'left', mobileHidden: true,columnName: "supplier__name", sortable: true  },
	{ label: ["Lines"], align: 'center', mobileHidden: true,columnName: "number_of_lines", sortable: true, basis: '10%'  },
	{ label: ["Debit"], align: 'right', columnName: "debit_amount", sortable: true,totalizerName: "total_debit"},
	{ label: ["Credit"], align: 'right', columnName: "credit_amount", sortable: true,totalizerName: "total_credit" }
]

const transactionMapper = {
	map: mapper,
	header
}
export default transactionMapper;