import Finding from '../controllers/Finding/Finding'
import { StackLayout } from '../layouts'
import { rows, List,Filters } from 'colbi_web_ui/lib/components'
import { parseFilter } from 'colbi_web_ui/lib/utils'
import theme from 'colbi_web_ui/lib/style/_theme.json';
const queries = (args = {}) => {
	return ([
		{
			resource: 'audit_efatura_findings',
			args: {
				projectId: (args.projectId),
				fiscalYear: (args.fiscalYear || ""),
				sort: args.sort ? { type: '[SortInput!]', value: parseFilter(args.sort) } : undefined,
				filter: args.filter ? { type: '[FilterInput!]', value: parseFilter(args.filter) } : undefined,
			},
			body: '__typename, hasMore, items{__typename, id, message, originalMessage, line, column, xmlBlock}, filters(projectId:$projectId, fiscalYear:$fiscalYear){ prop, name, type, list }',
			downloadable: true
		}
	]).filter(r => !!r.resource)
}


const page = (path, navigation, exact) => {
	return ({
	path,
	navigation,
	component: Finding,
	queries,
	exact,
	title: ["label(Findings)"],
	components: [
		{
			component: Filters,
			props: {
				filter: "param(filter)",
				availableFilters: "query(0).filters",
				stickyTop: parseInt(theme.layout.$headerHeight, 10),
				anchor: "results",
			},
		},
	],
	routes: [
		{
			path,
			exact: true,
			queries,
			component: StackLayout,
			components: [
				{
					component: List,
					modifiers: ["--row-style-alternate"],
					props: {
						basePath: path,
						items: "query(0).list",
						sort: "param(sort)",
						cardKey: "param(entity)",
						card: rows.EntityCard,
						header: "query(0).header",
						metadata: "query(0).metadata",
						page: "param(page)",
						status: "status(0)",
						pageSize: "param(pageSize)",
						footer: "query(0).footer",
						totalizers: 'query(0).totalizers'
					},
				},
			],
		},
	],
})};

export default page
