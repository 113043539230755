import React, { useState } from 'react'
import { Redirect } from 'react-router-dom'
import { parse } from 'query-string'
import { connect } from 'react-redux'
import { logout, resetPassword, setLocale } from '../../store/actions'
import { ActionButton, TextInput } from 'colbi_web_ui/lib/components/inputs'
import Icon from 'colbi_web_ui/lib/components/Icon/Icon'

import styles from './ResetPassword.module.sass'
import I18n from '../../I18n'
import { decryptSession } from 'colbi_web_ui/lib/utils/roleValidator'

const ResetPassword = (props) => {
	const { user, resetPasswordError, location, fetch, match } = props

	const [oldPassword, setOldPassword] = useState('')
	const [newPassword, setNewPassword] = useState('')
	const [confirmPassword, setConfirmPassword] = useState('')
	const [disabled, setDisabled] = useState(true)
	const [status, setStatus] = useState({
		lowUpperCase: false,
		oneNumber: false,
		eightCharacter: false
	})
	const [errorPasswordHideClass, setErrorPasswordHideClass] = useState(true)
	const [errorConfirmPasswordHideClass, setErrorConfirmPasswordHideClass] = useState(true)

	const checkStrength = (password) => {
		let strength = 0;

		if (password.match(/([a-z].*[A-Z])|([A-Z].*[a-z])/)) {
			strength += 2;

			setStatus(prevState => ({
				...prevState,
				lowUpperCase: true
			}))

			setErrorPasswordHideClass(true)

		} else {

			setStatus(prevState => ({
				...prevState,
				lowUpperCase: false
			}))

			setErrorPasswordHideClass(false)
		}

		if (password.match(/([0-9])/)) {
			strength += 1;

			setStatus(prevState => ({
				...prevState,
				oneNumber: true
			}))

			setErrorPasswordHideClass(true)

		} else {

			setStatus(prevState => ({
				...prevState,
				oneNumber: false
			}))

			setErrorPasswordHideClass(false)
		}

		if (password.length > 9) {
			strength += 1;

			setStatus(prevState => ({
				...prevState,
				eightCharacter: true
			}))

			setErrorPasswordHideClass(true)
		} else {

			setStatus(prevState => ({
				...prevState,
				eightCharacter: false
			}))

			setErrorPasswordHideClass(false)
		}

		if (password.match(/([!,%,&,@,#,$,^,*,?,_,~])/)) { strength += 1; }

		// Password Strength:
		switch (strength) {
			case 3: {
				return 'Weak'
			}
			case 4: {
				return 'Medium'
			}
			case 5: {
				return 'Strong'
			}
			default: {
				return 'Very Weak';
			}
		}
	}

	const handleSubmit = (user, e) => {
		const { id, organizationId, role } = user

		e.preventDefault()
		props.resetPassword(oldPassword, newPassword, confirmPassword, id, organizationId, role)
	}

	const handlePassword = (e) => {
		const { value } = e.target

		setNewPassword(value)

		if (checkStrength(value) !== 'Medium' || checkStrength(value) !== 'Strong') {
			setDisabled(true)
		}

		if (confirmPassword === value) {
			setErrorConfirmPasswordHideClass(true)

			if (checkStrength(value) === 'Strong' || checkStrength(value) === 'Medium') {
				setDisabled(false)
			}
		}
	}

	const handleConfirmPassword = (e) => {
		const confirmPassword = e.target.value

		setConfirmPassword(confirmPassword)

		if (newPassword === confirmPassword) {
			setErrorConfirmPasswordHideClass(true)

			if (checkStrength(newPassword) === 'Medium' || checkStrength(newPassword) === 'Strong') {
				setDisabled(false)
			}

		} else {
			setDisabled(true)
			setErrorConfirmPasswordHideClass(false)
		}
	}

	const { lowUpperCase, oneNumber, eightCharacter } = status

	const { redirect } = parse(location.search)

	const locale = match.params.locale || 'en'
	const i18n = I18n.use(locale)
	setLocale(locale)
	const version = (fetch.results[`${locale}_application_version`] || "").version

	let userInformation = decryptSession(user)
	if (!userInformation) {
		return <Redirect to={`/${locale}/login`} push />
	}

	if (userInformation && !parseInt(userInformation.mysqlPasswordReset)) {
		return <Redirect to={redirect || `/`} />
	}

	return (
		<div className={styles['root']}>
			<div className={styles['main']}>
				<div className={styles['login-container']}>
					<div className={styles['details']}>
						<h1 className={styles['title']}>{i18n`Reset Password`}</h1>
						{resetPasswordError && <div className={styles['error']}>{resetPasswordError}</div>}
						<form className={styles['form']} onSubmit={(e) => { handleSubmit(userInformation, e) }}>
							<label>
								<TextInput
									type="password"
									autoComplete="off"
									name={i18n`Old password`}
									placeholder=''
									required
									value={oldPassword}
									onChange={(e) => {
										setOldPassword(e.target.value)
									}}
								/>
							</label>
							<label>
								<TextInput
									type="password"
									autoComplete="off"
									name={i18n`New password`}
									placeholder=''
									required
									value={newPassword}
									onChange={(e) => {
										handlePassword(e)
									}}
								/>
								<div>
									<small className={errorPasswordHideClass ? styles['hide'] : styles['msg-input-error']}>
										{i18n`Enter a strong password. Check the requirements.`}
									</small>
								</div>
							</label>
							<label>
								<TextInput
									type="password"
									autoComplete="off"
									name={i18n`Confirm password`}
									placeholder=''
									required
									value={confirmPassword}
									onChange={(e) => { handleConfirmPassword(e) }}
								/>
								<div>
									<small className={errorConfirmPasswordHideClass ? styles['hide'] : styles['msg-input-error']}>
										{i18n`Passwords don't match`}
									</small>
								</div>
							</label>
							<div className={styles['reset-actions']}>
								<button className={styles['logout-button']} type = "button" onClick={props.logout}>
									<small><strong>{i18n`Another user account`}</strong></small>
								</button>
								<ActionButton
									primary
									className={styles['action-button']}
									type="submit"
									disabled={disabled}>{i18n`Reset`}</ActionButton>
							</div>
						</form>
					</div>
				</div>
			</div>
			<div className={styles['aside']}>
				<h2 className={styles['title']}>{i18n`Instructions`}</h2>
				{user.expired && <p className={styles['msg-expired']}>{i18n`Your password has expired, create a new password, please.`}</p>}
				<p className={styles['subtitle']}>{i18n`Minimum Requirements`}</p>
				<div className={styles["wrap-strength-password"]}>
					<ul className={styles["list-unstyled"]}>
						<li className={`${lowUpperCase ? styles['text-success'] : 'text'} `}>
							{lowUpperCase ? <Icon name="success" /> : <Icon name="danger" />}
							<span>{i18n`1 lowercase and 1 uppercase`}</span>
						</li>
						<li className={`${oneNumber ? styles['text-success'] : 'text'}`}>
							{oneNumber ? <Icon name="success" /> : <Icon name="danger" />}
							<span>{i18n`1 number (0-9)`}</span>
						</li>
						<li className={`${eightCharacter ? styles['text-success'] : 'text'}`}>
							{eightCharacter ? <Icon name="success" /> : <Icon name="danger" />}
							<span>{i18n`At least 10 Character`}</span>
						</li>
					</ul>
				</div>
				{version && <div className={styles['version']}>
					<small>{i18n`Version`}: {version}</small>
				</div>}
			</div>
		</div>
	)
}


ResetPassword.queries = (args) => ([
	{
		resource: 'application_version',
		body: 'version'
	}
])

export default connect(
	({ user, resetPasswordError, fetch }) => (
		{
			user,
			resetPasswordError,
			fetch
		}
	),
	{
		logout,
		resetPassword
	}
)(ResetPassword)
