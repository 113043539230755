import { format } from '../../../utils'

const header = [
    { label: ["Depreciation Method"], align: 'left' },
    { label: ["Account Id"], align: 'center' },
    { label: ["Depreciation"], align: 'right' },
]

const mapCard = (json) => {
	const {
        depreciationMethod,
        accountId,
        depreciation,
	} = json

	return [
        [
            { labels: ['depreciationMethod'], value: depreciationMethod, type: 'string' }
        ],
        [
            { labels: ['Account'], value: accountId || 'N/A', type: 'string' }
        ],
        [
            { labels: ['depreciation'], value: format(depreciation, "fullValue"), type: 'currency' }
        ]
	]
}

const mapper = (json, card) => {
    const name = json.account_id
    
    return {
        ...json,
        __typename: json.__typename,
        id: json.id,
		name,
        shortName: name,
		card: card ? mapCard(json) : []
	}
}
const assetvaluationextradepreciationMapper = {
    map: mapper,
    header
}
export default assetvaluationextradepreciationMapper;