export const mapDetails = (json) => {
    const {
        line,
        column,
        xmlBlock,
        originalMessage,
        type,
        referenceType
    } = json

    return [
        [
            { label: 'Type', value: type || 'N/A', type: 'string' },
            { label: 'Reference Type', value: referenceType || 'N/A', type: 'string' },
            { label: 'Line', value: line || 'N/A', type: 'string' },
            { label: 'Column', value: column || 'N/A', type: 'string' },

        ],
        [],
        [
            { label: 'Original Message', value: originalMessage || 'N/A', type: 'string' },
            { label: 'XML Block', value: xmlBlock || 'N/A', type: 'string' },
        ]
    ]

}

export const header = [
    { label: ["Original Message"], align: 'center', basis: '70%' },
    { label: ["Line"], align: 'center' },
    { label: ["Column"], align: 'center' },
]

export const mapCard = (json) => {
    const {
        originalMessage,
        line,
        column,
    } = json

    return [
        [
            { labels: ['originalMessage'], value: originalMessage ? originalMessage : '', type: 'string', basis: '70%' }
        ],
        [
            { labels: ['line'], value: line, type: 'number' }
        ],
        [
            { labels: ['column'], value: column, type: 'number' }
        ],

    ]
}