import I18n from '../../I18n'
const mapCard = (json) => {
    const {
        ruleId,
        ruleType,
        title,
        appliesTo,
        severity,
        ruleCategory,
        ruleSubgroup
    } = json
    return [
        [
            { labels: ['ruleId'], value: ruleId, type: 'string', basis: '20%' }
        ],
        [
            { labels: ['ruleCategory'], value: ruleCategory, type: 'string', basis: '10%' }
        ],
        [
            { labels: ['ruleSubgroup'], value: ruleSubgroup, type: 'string', basis: '10%' }
        ],
        [
            { labels: ['title'], value: title, type: 'string', basis: '50%' }
        ],
        [
            { labels: ['severity'], value: I18n.translate(`severity-${severity}`), type: 'number' },
        ],
        [
            { labels: ['ruleType'], value: ruleType, type: 'string' }
        ],
        [
            { labels: ['appliesTo'], value: appliesTo, type: 'string', align: 'right' }
        ]
    ]
}

const mapFilters = (filters) => {
    return filters.map((filter) => {
        if (filter.prop === 'severity') {
            return {
                ...filter,
                listMapper: {
                    '0': I18n.translate`Low`,
                    '1': I18n.translate`Medium`,
                    '2': I18n.translate`High`,
                    '3': I18n.translate`Critical`,
                }
            }
        }
        return filter
    })
}

const mapper = (json, card) => {
    return {
        ...json,
        name: json.name,
        shortName: json.name,
        card: card ? mapCard(json) : []
    }
}

const header = [
    { label: ['Identifier'], align: 'left', basis: '20%', columnName: "rule_id", sortable: true },
    { label: ['Group'], align: 'left', basis: '10%' },
    { label: ['SubGroup'], align: 'left', basis: '10%' },
    { label: ['Title'], align: 'left', basis: '50%', columnName: "description", sortable: true },
    { label: ['Severity'], align: 'center', columnName: "severity", sortable: true },
    { label: ['Category'], align: 'left', columnName: "rule_type", sortable: true },
    { label: ['Refers to'], align: 'right', columnName: "applies_to", sortable: true }
]

const ruleMapper = {
    map: mapper,
    mapFilters,
    header
};

export default ruleMapper