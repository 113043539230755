import I18n from '../../I18n'

const mapCard = (json) => {
	const {
		name,
		username,
		role,
		blocked,
		organizationName
	} = json
	return [
		[
			{ labels: ['name'], value: name, type: 'string' },
		],
		[
			{ labels: ['username'], value: username, type: 'string' }
		],
		[
			{ labels: ['organizationName'], value: organizationName, type: 'string', basis: "20%" }
		],
		[
			{
				labels: ['role'],
				value: I18n.translate(`role_${role}`),
				type: 'string',
			}
		],
		[
			{ labels: [], value: blocked, type: 'user-status' }
		]
	]
}

const mapper = (json, card, details) => {
	return {
		...json,
		name: json.name,
		shortName: json.name,
		card: card ? mapCard(json) : []
	}
}

const header = [
	{ label: ['Full name'], align: 'left', columnName: "name", sortable: true },
	{ label: ['Username'], align: 'left', columnName: "email", sortable: true },
	{ label: ['Organization'], align: 'left', basis: "20%", columnName: "organizations.name", sortable: true },
	{ label: ['Role'], align: 'left', columnName: "role", sortable: true },
	{ label: [""], align: 'center' },
]

const userMapper = {
	map: mapper,
	header
};

export default userMapper