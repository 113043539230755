const header = [
    {
        label: ['Analysis ID'],
        align: 'left',
        basis: '40%',
        columnName: "analysis_id", 
        sortable: true
    },
    {
        label: ['Analysis ID Description'],
        align: 'left',
        basis: '40%',
        columnName: "analysis_id_description", 
        sortable: true
    },
    {
        label: ['Analysis Type'],
        align: 'left',
        basis: '40%',
        columnName: "analysis_type", 
        sortable: true
    },
    {
        label: ['Analysis Type Description'],
        align: 'left',
        basis: '40%',
        columnName: "analysis_type_description", 
        sortable: true
    }
];

const mapCard = (json) => {
    const {
        analysisId,
        analysisIdDescription,
        analysisType,
        analysisTypeDescription,
    } = json;

    return [
        [
            {
                labels: ['analysis_id'],
                value: analysisId || 'N/A',
                type: 'string',
                basis: '40%'
            }
        ],
        [
            {
                labels: ['analysis_id_description'],
                value: analysisIdDescription || 'N/A',
                type: 'string',
                basis: '40%'
            }
        ],
        [
            {
                labels: ['analysis_type'],
                value: analysisType || 'N/A',
                type: 'string',
                basis: '40%'
            }
        ],
        [
            {
                labels: ['analysis_type_description'],
                value: analysisTypeDescription || 'N/A',
                type: 'string',
                basis: '40%'
            }
        ],
    ]
};

const mapper = (json, card) => {
    return {
        ...json,
        name: json.analysisId,
        shortName: json.analysisId,
        card: card ? mapCard(json) : []
    };
}

const analysistypeMapper = {
    map: mapper,
    header
}
export default analysistypeMapper;