import React, { useEffect, useState } from 'react'
import { Redirect, withRouter } from 'react-router-dom'
import { renderRoutes, matchRoutes } from 'react-router-config'
import { connect } from 'react-redux'
import { parse } from 'query-string'

import { GlobalProvider as ColbiUIProvider } from 'colbi_web_ui/lib/state/GlobalProvider'
import { GlobalProvider } from '../../state/globalProvider'

import { mapProperties, stringifyFilter } from 'colbi_web_ui/lib/utils'
import AsideMenu from 'colbi_web_ui/lib/components/layout/AsideMenu/AsideMenu'

import { FreshDeskWidget, LoadingIndicator } from 'colbi_web_ui/lib/components';
import Header from 'colbi_web_ui/lib/components/layout/Header/Header'
import ModalController from '../App/ModalController/ModalController'
import DialogController from '../App/DialogController/DialogController'
import { ReactComponent as logo } from '../../assets/logo.svg'

import {
	logout,
	pushModal,
	pushDialog,
	popModal,
	uploadAction,
	toggleMenu,
	setLocale,
	auditNoteSubmit,
	removeModal,
	setDataFileStatusAction,
	deleteFilePermanentlyAction,
	deleteInvalidFilePermanentlyAction,
	reprocessRepositoryAction,
	downloadAction
} from '../../store/actions'
import {filteredNavigationByModules} from '../Repository/navigation'
import I18n from '../../I18n'
import theme from '../../style/_theme.json'
import proxy from '../../routes/proxy'
import { decryptSession, checkRoles } from 'colbi_web_ui/lib/utils/roleValidator'
import styles from './Finding.module.sass'
import { UserRoles } from 'colbi_web_ui/lib/utils/userRoles'
import configsByTheme from '../../enums/configsByTheme'

const Provider = ({ children, ...props }) => {
	return (
		<GlobalProvider {...props}>
			<ColbiUIProvider {...props}>
				{children}
			</ColbiUIProvider>
		</GlobalProvider>
	)
}

const appTheme = process && process.env && process.env.REACT_APP_COLBI_THEME ? process.env.REACT_APP_COLBI_THEME.toLocaleLowerCase() : "";
const localesByAppTheme = configsByTheme(appTheme)['locales'];
const logoHorizontalDisplay = configsByTheme(appTheme)['logoHorizontalDisplay'] || false

const Finding = (props) => {
	const {
		user,
		uploads,
		fetch,
		match,
		setLocale,
		location,
		toggleMenu,
		logout,
		history,
		pushModal,
		popModal,
		menu,
		reload,
		pushDialog,
		uploadFiles,
		submitAuditNote,
		route,
		setDataFileStatus,
		deleteFilePermanently,
		deleteInvalidFilePermanently,
		downloadFile,
		hideFile,
		reprocessRepository,
		download
	} = props

	useEffect(() => {
		const pop = history.action === 'POP'
		if (!pop && !fetch.activity) {
			if (location.hash) {
				const scrollElement = window.document.querySelector(location.hash)
				if (scrollElement) {
					const headerOffset = 70

					const previousRect = scrollElement.previousElementSibling.getBoundingClientRect()
					let stuckOffset = previousRect.height
					const elementPosition = scrollElement.offsetTop
					const offsetPosition = elementPosition - headerOffset - stuckOffset - 100

					setTimeout(() => {
						window.scrollTo({
							top: offsetPosition,
							behavior: 'smooth'
						})
					}, 0)
				}
			} else if (location.search.search("page") === -1) {
				setTimeout(() => {
					window.scrollTo(0, 0)
				}, 0)
			}
		}
	}, [fetch.activity, location.hash, location.pathname, location.search, history.action])


	const locale = match.params.locale || 'en'
	const i18n = I18n.use(locale)

	const [hover, setHover] = useState(false);
	
	useEffect(() => {
		setLocale(locale)
	}, [locale, setLocale])

	let userInformation = decryptSession(user)
	if (!userInformation) {
		return <Redirect to={`/${locale}/login`} push />
	}

	if (userInformation && parseInt(userInformation.mysqlPasswordReset)) {
		return <Redirect to={`/${locale}/reset_password`} />
	}

	const projectId = match.params.projectId

	const version = (fetch.results[`${locale}_application_version`] || "").version

	const ticketSubjectEnvironment = fetch.results[`${locale}_get_environment_to_subject`] || ""

	const isToDisplaySupportIcon = (fetch.results[`${locale}_is_to_display_support_icon`] || "true").toLocaleLowerCase() === "true";

	if (!projectId) {
		return <Redirect to={`${locale ? `/${locale}` : ''}/`} />
	}

	const project = fetch.results[`${locale}_project_targetProjectId_${projectId}`] || {}
	const queryParams = parse(location.search) || {}
	const routeParams = match.params || {}
	const params = {
		...queryParams,
		...routeParams
	}

	const repositoryStatus = fetch.results[`${locale}_repository_status_fiscalYear_${match.params.fiscalYear}_projectId_${projectId}`] || { status: 1 }
	const projectModules = fetch.results[`${locale}_project_modules_projectId_${projectId}`] 
	
	let modules = []
	if(projectModules && projectModules.modules){
		modules.push(...projectModules.modules)
	}
	const navigationNav = mapProperties(
		{ navigation: filteredNavigationByModules(modules || []) },
		(fetch || {}).results,
		(fetch || {}).status,
		Finding.queries(params),
		{
			...props,
		},
		params,
		i18n,
		locale
	)

	const routeQueries = (route.queries && route.queries(params)) || []

	const children = (route.components || []).map((c, index) => {
		const Component = c.component

		const componentProps = mapProperties(
			match.isExact ? c.props : { ...c.props, checkbox: false },
			(fetch || {}).results,
			(fetch || {}).status,
			routeQueries,
			props,
			params,
			i18n,
			locale
		)

		const sectionClass = `--root-repository-${params.section}`
		const modifiersClasses = c.modifiers ? [...Object.values(c.modifiers), sectionClass] : [sectionClass]

		return (<Component key={index} modifiers={modifiersClasses} {...componentProps} active={fetch.activity} />)
	})

	const branch = matchRoutes([route, ...(route.routes || [])], location.pathname)

	const branchQueries = branch.reduce((allQueries, { route }) => {
		const innerQueries = route.queries ? route.queries(params) : []
		allQueries = [
			...allQueries,
			...innerQueries
		]
		return allQueries
	}, [])

	const downloadableQueries = branchQueries.filter(({ downloadable }) => downloadable)

	const goto = (page, filters, anchor) => {
		const pageUrl = page ? (typeof page.join === 'function' ? page.join('/') : page).replace(/\/+/g, '/') : null
		const urlFilters = filters ? stringifyFilter(filters) : ''
		const localePrefixed = pageUrl ? `${locale ? `/${locale}${!pageUrl.match(/^\//) ? '/' : ''}` : ''}` : null
		const gotoPage = pageUrl ? `${localePrefixed}${pageUrl}${urlFilters.length ? `?filter=${urlFilters}` : ''}` : `${location.pathname}?filter=${urlFilters}${anchor ? `#${anchor}` : ''}`
		history.push(gotoPage)
	}

	return (
		<div className={styles['app']}>
			<Provider
				user={userInformation}
				proxy={proxy}
				theme={theme}
				actions={{
					pushModal, pushDialog, popModal, uploadFiles, submitAuditNote, removeModal, setDataFileStatus, deleteFilePermanently, deleteInvalidFilePermanently, downloadFile, hideFile, reprocessRepository, logout,
					setLocale: (locale) => {
						history.push(`/${locale}${history.location.pathname.substring(3)}${history.location.search ? history.location.search : ''}`);
					},
					goto, download
				}}
				i18n={i18n}
				locale={locale ||localesByAppTheme[0].id}
				locales={localesByAppTheme}
				reload={reload}
				params={params}
				history={history}
				location={location}
				repositoryStatus={repositoryStatus}
				uploads={uploads}
			>
				<div className={`${styles['aside']} ${menu === 'expanded' ? '' : styles['is-collapsed']}`}>
					<AsideMenu
						logo={logo}
						subtitle={project && project.description && (menu === 'expanded' || hover) ? project.description : "" }
						title={menu === 'expanded' ? i18n`Findings` : ''}
						navigation={navigationNav.navigation}
						version={version}
						project={{ ...(project || {}), id: params.projectId, name: (project || {}).name || (project || {}).description, projectModules: (projectModules || {modules: []}).modules }}
						expanded={menu === 'expanded'}
						toggleMenu={ toggleMenu }
						hover = { hover }
						setHover = { setHover }
						settingsAction={checkRoles(userInformation, [UserRoles.ADMIN, UserRoles.OWNER, UserRoles.PP_SUPPORT, UserRoles.SUPPORT])}
						logoHorizontalDisplay={logoHorizontalDisplay}
					/>
				</div>
				<div className={styles['main']}>
					<Header
						className={styles['header']}
						logout={logout}
						proxy={proxy}
						theme={theme}
						title={i18n`${'Findings'}`}
						toggleMenu={toggleMenu}
						settingsAction={checkRoles(userInformation, [UserRoles.ADMIN, UserRoles.OWNER])}
						downloadableQueries={downloadableQueries}
						expanded={menu !== 'expanded'}
						project={{ ...(project || {}), id: params.projectId, name: (project || {}).name || (project || {}).description }}
						navigation={navigationNav.navigation}
						displaySupportIcon={isToDisplaySupportIcon}
					/>
					<main className={`${styles['content'] || ''} ${styles['foreground'] || ''}`}>
						{fetch.activity &&
							<LoadingIndicator
								className={`${
									styles['loading-indicator']} ${menu === 'expanded' ? '' :
										styles['small-margin']
									}`}
							/>
						}
						{children}

						{renderRoutes(route.routes)}
					</main>
				</div>
				<ModalController popModal={popModal} />
				<DialogController i18n={i18n} />
				<FreshDeskWidget environment={ticketSubjectEnvironment} emailFrom={userInformation.username} name={userInformation.name}/>
			</Provider>
		</div>
	)
}

Finding.queries = (args) => ([
	{
		resource: 'project',
		args: {
			targetProjectId: (args.projectId)
		},
		body: 'id,name, description, fiscalYears, taxRegistrationNumber'
	},
	{
		resource: 'repository_status',
		args: {
			projectId: (args.projectId),
			fiscalYear: (args.fiscalYear),
		},
		body: 'status'
	},
	{
		resource: 'application_version',
		body: 'version'
	},
	{
		resource: "is_to_display_support_icon",
	},
	{
		resource: 'project_modules',
		args: {
			projectId: (args.projectId),
		},
		body: 'modules'
	},
	{
		resource: "get_environment_to_subject",
	}
])

export default connect(
	({ user, fetch, menu, uploads }) => (
		{
			user,
			fetch,
			menu,
			uploads
		}
	),
	{
		logout,
		pushModal,
		popModal,
		removeModal,
		toggleMenu,
		setLocale,
		pushDialog,
		setDataFileStatus: setDataFileStatusAction,
		deleteFilePermanently: deleteFilePermanentlyAction,
		deleteInvalidFilePermanently: deleteInvalidFilePermanentlyAction,
		reprocessRepository: reprocessRepositoryAction,
		uploadFiles: uploadAction,
		submitAuditNote: auditNoteSubmit,
		download: downloadAction
	}
)(withRouter(Finding))
