import {
	Grid,
	cards,
	Widget,
	charts,
	AuditSummaryWidget,
} from "colbi_web_ui/lib/components";
import AppByFile from "../controllers/AppByFile/AppByFile";

const queries = (args = {}) => {
	return args.fiscalYear
		? [
				{
					resource: "generic_overview_reports",
					args: {
						projectId: args.projectId,
						fiscalYear: args.fiscalYear,
						importId: args.importId,
						modulePrefix: args.modulePrefix,
						entities: "sales_invoices,purchases_invoices,general_ledger_entries,payments"
					},
					body: "report, setClose",
				},
				{
					resource: "nc_audit_summary",
					args: {
						projectId: args.projectId,
						fiscalYear: args.fiscalYear,
						importId: args.importId,
						modulePrefix: args.modulePrefix,
					},
					body: "severity, count",
				},
		  ]
		: [];
};

const saftHuOverview = (
	path,
	navigation,
	exact,
	title,
	navByImportId = true
) => ({
	path,
	navigation,
	component: AppByFile,
	queries,
	exact,
	navByImportId,
	title: [title],
	components: [
		{
			component: AuditSummaryWidget,
			modifiers: ["--root-margin-bottom-normal"],
			props: {
				icon: "audit-icon",
				title: "label(Audit Results)",
				summary: "query(1).list",
				link: {
					to: `audit`,
					name: "label(See All)",
				},
				overviewSummaryWidget: true
			},
		},
		{
			component: Widget,
			modifiers: ['--root-margin-bottom-normal'],
			props: {
				icon: 'sales',
				title: 'label(Sales Summary)',
				summary: [
					{
						name: 'label(No. Sales)',
						value: 'query(0).report.0.SalesInvoicesTotal'
					},
					{
						name: 'label(No. Sales Lines)',
						value: 'query(0).report.0.SalesInvoicesLinesTotal'
					}
				],
				link: {
					to: 'sales_invoices',
					name: 'label(Sales Invoices)'
				},
				children: [
					{
						component: Grid,
						modifiers: ['--grid-border-inline'],
						props: {
							columnsTemplate: [1],
							aspect: ['31x10'],
							items: [
								{
									data: "query(0).report.1.0",
									labelsPosition: 'top',
									stacked: true,
									summarized: true,
									links: [{
										to: ['param(projectId)', 'param(fiscalYear)', 'param(importId)', "param(modulePrefix)", '/sales_invoices'],
										filterColumn: 'Period',
									}],
									modifiers: ['--top-text-small'],
									currency: true,
									currencyType : {
										localeCurrency : 'hu',
										currency: 'HUF'
									}
								},
							],
							cards: [charts.BarChart]
						}
					},
					
				]
			}
		},
		{
			component: Widget,
			modifiers: ['--root-margin-bottom-normal'],
			props: {
				icon: 'sales',
				title: 'label(Purchases Summary)',
				summary: [
					{
						name: 'label(No. Purchases)',
						value: 'query(0).report.0.PurchasesInvoicesTotal'
					},
					{
						name: 'label(No. Purchases Lines)',
						value: 'query(0).report.0.PurchasesInvoicesLinesTotal'
					}
				],
				link: {
					to: 'purchases_invoices',
					name: 'label(Purchases Invoices)'
				},
				children: [
					{
						component: Grid,
						modifiers: ['--grid-border-inline'],
						props: {
							columnsTemplate: [1],
							aspect: ['31x10'],
							items: [
								{
									data: "query(0).report.1.1",
									labelsPosition: 'top',
									stacked: true,
									summarized: true,
									links: [{
										to: ['param(projectId)', 'param(fiscalYear)', 'param(importId)', "param(modulePrefix)", '/purchases_invoices'],
										filterColumn: 'Period',
									}],
									modifiers: ['--top-text-small'],
									currency: true,
									currencyType : {
										localeCurrency : 'hu',
										currency: 'HUF'
									}
								},
							],
							cards: [charts.BarChart]
						}
					},
					
				]
			}
		},
		{
			component: Grid,
			modifiers: ["--grid-padding-none", "--root-margin-bottom-normal"],
			props: {
				columnsTemplate: [1, 1],
				items: [
					{
						icon: "sales",
						title: "label(GL Transactions per Type)",
						summary: [
							{
								name: "label(No. GL Transactions)",
								value: "query(0).report.0.GeneralLedgerEntriesTotal",
							},
							{
								name: "label(No. GL Transactions Lines)",
								value: "query(0).report.0.GeneralLedgerEntriesLinesTotal",
							},
						],
						link: {
							to: "general_ledger_entries",
							name: "label(GL Transactions)",
						},
						children: [
							{
								component: Grid,
								modifiers: ["--grid-border-inline"],
								props: {
									columnsTemplate: [1],
									aspect: ["21x10"],
									items: [
										{
											data: "query(0).report.1.2",
											labelsPosition: "top",
											stacked: true,
											summarized: true,
											links: [
												{
													to: [
														"param(projectId)",
														"param(fiscalYear)",
														"param(importId)",
														"param(modulePrefix)",
														"/general_ledger_entries",
													],
													filterColumn: "Period",
												},
											],
											modifiers: ['--top-text-small'],
										},
									],
									cards: [charts.BarChart],
								},
							},
						],
					},
					{
						icon: "sales",
						title: "label(Payments Summary)",
						summary: [
							{
								name: "label(No. Payments)",
								value: "query(0).report.0.PaymentsTotal",
							},
							{
								name: "label(No. Payments Lines)",
								value: "query(0).report.0.PaymentLinesTotal",
							},
						],
						link: {
							to: "payments",
							name: "label(Payments)",
						},
						children: [
							{
								component: Grid,
								modifiers: ["--grid-border-inline"],
								props: {
									columnsTemplate: [1],
									aspect: ["21x10"],
									items: [
										{
											data: "query(0).report.1.3",
											labelsPosition: "top",
											stacked: true,
											summarized: true,
											links: [
												{
													to: [
														"param(projectId)",
														"param(fiscalYear)",
														"param(importId)",
														"param(modulePrefix)",
														"/payments",
													],
													filterColumn: "Period",
												},
											],
											modifiers: ['--top-text-small'],
											currency: true,
											currencyType : {
												localeCurrency : 'hu',
												currency: 'HUF'
											}
										},
									],
									cards: [charts.BarChart],
								},
							},
						],
					},
				],
				cards: [Widget],
			},
		},
		{
			component: Grid,
			modifiers: [
				"--root-margin-bottom-normal",
				"--root-background-color-widget-background-color",
			],
			props: {
				columnsTemplate: [1, 1],
				items: [
					{
						title: "label(Master Data)",
						items: [
							{
								name: "label(Customers)",
								count: "number(query(0).report.0.customersTotal)",
								link: `${path}/master_data/customers`,
							},
							{
								name: "label(Suppliers)",
								count: "number(query(0).report.0.suppliersTotal)",
								link: `${path}/master_data/suppliers`,
							},
							{
								name: "label(Products)",
								count: "number(query(0).report.0.productsTotal)",
								link: `${path}/master_data/products`,
							},
							{
								name: "label(Products Group)",
								count: "number(query(0).report.0.ProductsGroupTotal)",
							},
							{
								name: "label(Journals)",
								count: "number(query(0).report.0.JournalsTotal)",
								link: `${path}/master_data/journals`,
							},
							{
								name: "label(General Ledger Accounts)",
								count: "number(query(0).report.0.GeneralLedgerAccountsTotal)",
								link: `${path}/master_data/general_ledger_accounts`,
							},
							{
								name: "label(Tax Table Entries)",
								count: "number(query(0).report.0.TaxTableEntriesTotal)",
								link: `${path}/master_data/tax_table_entries`,
							},
							{
								name: "label(Analysis Table)",
								count: "number(query(0).report.0.AnalysisTypeTableTotal)",
								link: `${path}/master_data/analysis_type_table`,
							},
							{
								name: "label(Assets Valuation)",
								count: "number(query(0).report.0.AssetsValuationTotal)",
								link: `${path}/master_data/assets_valuation`,
							},
							{
								name: "label(Movement Entries)",
								count: "number(query(0).report.0.MovementEntriesTotal)",
								link: `${path}/master_data/movement_type_table_entry`,
							},
							{
								name: "label(Owners)",
								count: "number(query(0).report.0.OwnersTotal)",
								link: `${path}/master_data/owner`,
							},
							{
								name: "label(Unit Of Measure)",
								count: "number(query(0).report.0.UomTotal)",
								link: `${path}/master_data/uom_table`,
							},
							{
								name: "label(Warehouses)",
								count: "number(query(0).report.0.WarehousesTotal)",
								link: `${path}/master_data/warehouses`,
							},
						],
					},
					{
						title: "label(Transaction Data)",
						items: [
							{
								name: 'label(Sales Invoices)',
								count: 'number(query(0).report.0.SalesInvoicesTotal)',
								link: `${path}/sales_invoices`,
							},
							{
								name: 'label(Sales Invoices Lines)',
								count: 'number(query(0).report.0.SalesInvoicesLinesTotal)'
							},
							{
								name: 'label(Purchases Invoices)',
								count: 'number(query(0).report.0.PurchasesInvoicesTotal)',
								link: `${path}/purchases_invoices`,
							},
							{
								name: 'label(Purchases Invoices Lines)',
								count: 'number(query(0).report.0.PurchasesInvoicesLinesTotal)'
							},
							{
								name: "label(Payments)",
								count: "number(query(0).report.0.PaymentsTotal)",
								link: `${path}/payments`,
							},
							{
								name: "label(Payment Lines)",
								count: "number(query(0).report.0.PaymentLinesTotal)",
							},
							
							{
								name: "label(GL Transactions)",
								count: "number(query(0).report.0.GeneralLedgerEntriesTotal)",
								link: `${path}/general_ledger_entries`,
							},
							{
								name: "label(GL Transaction Lines)",
								count: "number(query(0).report.0.GeneralLedgerEntriesLinesTotal)",
							},
							{
								name: "label(Stock Movement)",
								count: "number(query(0).report.0.StockMovementTotal)",
								link: `${path}/stock_movement`,
							},
							{
								name: "label(Stock Movement Lines)",
								count: "number(query(0).report.0.StockMovementLinesTotal)",
							},

							{
								name: "label(Asset Transaction)",
								count: "number(query(0).report.0.AssetTransactionTotal)",
								link: `${path}/asset_transactions`,
							},
							{
								name: "label(Asset Transaction Lines)",
								count: "number(query(0).report.0.AssetTransactionLinesTotal)",
							},
						],
					},
				],
				cards: [cards.LinksCard],
			},
		},
	],
});

export default saftHuOverview;
