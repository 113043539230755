const mapDetails = (json) => {
    const {
        uomStandard, uomToUomBaseConversionFactor
    } = json;

    return [
        [
            {label: 'UOM Standard', value: uomStandard, type: 'string'},
            {label: 'UOM To Uom Base Conversion Factor', value: uomToUomBaseConversionFactor, type: 'string'}
        ]
    ]
};

const mapCard = (json) => {
    const {
        productCode, uomStandard, uomToUomBaseConversionFactor
    } = json;

    return [
        [
            {labels: ['product_code'], value: productCode, type: 'string'}
        ],
        [
            {labels: ['uom_standard'], value: uomStandard, type: 'string'}
        ],
        [
            {labels: ['uom_to_uom_base_conversion_factor'], value: uomToUomBaseConversionFactor, type: 'number'}
        ]
    ]
};


const mapper = (json, card, details) => {

    return {
        ...json,
        name: json.description,
        shortName: json.productCode,
        details: details ? mapDetails(json) : [],
        card: card ? mapCard(json) : []
    }
};

const header = [
    {label: ['Product Code'], align: 'left', columnName: "product_code", sortable: true},
    {label: ['UOM Standard'], align: 'left',columnName: "uom_standard", sortable: true},
    {label: ['UOM To Uom Base Conversion Factor'], align: 'left', columnName: "uom_to_uom_base_conversion_factor", sortable: true}
];

const productsuomMapper = {
    map: mapper,
    header
}
export default productsuomMapper;