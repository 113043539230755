import { format, date } from "../../../utils";

const header = [
	{ label: ["Supplier"], align: "left" },
	{ label: ["Supplier Registration Number"], align: "left" },
	{ label: ["Invoice"], align: "left" },
	{ label: ["Date"], align: "center" },
	{ label: ["Invoice Date"], align: "center" },
	{ label: ["GL Posting date"], align: "center" },
	{ label: ["Quantity"], align: "center" },
	{ label: ["Stock Quantity"], align: "center" },
	{ label: ["Stock Value"], align: "right", compact: "fullValue" }
];

const mapCard = json => {
	const {
		supplierRegistrationNumber,
		supplierName,
		dateOfAcquisition,
		invoiceNo,
		invoiceDate,
		glPostingDate,
		acquiredQuantity,
		stockRemainderQuantity,
		stockRemainderAmount
	} = json;

	return [
		[{ labels: ["supplier"], value: supplierName, type: "string" }],
		[
			{
				labels: ["supplier_registration_number"],
				value: supplierRegistrationNumber,
				type: "string"
			}
		],
		[{ labels: ["invoice"], value: invoiceNo, type: "string" }],
		[{ labels: ["date"], value: date(dateOfAcquisition), type: "date" }],
		[{ labels: ["invoice_date"], value: date(invoiceDate), type: "date" }],
		[{ labels: ["gl_posting"], value: date(glPostingDate), type: "date" }],
		[{ labels: ["quantity"], value: acquiredQuantity, type: "number" }],
		[
			{
				labels: ["stock_quantity"],
				value: stockRemainderQuantity,
				type: "number"
			}
		],
		[
			{
				labels: ["stock_value"],
				value: format(stockRemainderAmount, 'fullValue'),
				type: "currency"
			}
		]
	];
};

const mapper = (json, card) => {
	const name = json.physical_stock_id;

	return {
		...json,
		__typename: json.__typename,
		id: json.id,
		name,
		shortName: name,
		card: card ? mapCard(json) : []
	};
};

const acquisitionMapper =  {
	map: mapper,
	header
};

export default acquisitionMapper;