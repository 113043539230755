const push = (id, view, title, data) => (
	{
		type: 'PUSH_DIALOG',
		payload: {
			view,
			title,
			data,
			id: `DIALOG_${ Date.now() }`
		}
	}
)

const pop = (id) => (
	{
		type: 'POP_DIALOG',
		payload: id
	}
)

const promises = {}

export const pushDialog = (view, title, data) => (
	(dispatch) => {
		const id = `DIALOG_${ Date.now() }`
		dispatch(push(id, view, title, data))
		return new Promise((resolve, reject) => {
			promises[id] = [resolve, reject]
		})
	}
)
export const popDialog = (id, success) => (
	(dispatch) => {
		dispatch(pop(id))
		const promiseActionIndex = success ? 0 : 1
		if (promises[id] && promises[id][promiseActionIndex]) {
			promises[id][promiseActionIndex]()
		}
		Reflect.deleteProperty(promises, id)
	}
)
