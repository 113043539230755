import { date } from "colbi_web_ui/lib/utils"
import { adjustForTimezone } from "../../utils/convertUtils"

const mapCard = (json) => {
	const {
		name, packageName, active, expirationDate
	} = json
	const dateToAdjust = expirationDate ? expirationDate : ""
	return [
		[
			{ labels: ['name'], value: name, type: 'string' }
		],
		[
			{ labels: ['expiration_date'], value: date(adjustForTimezone(dateToAdjust).getTime()), type: 'string' }
		],
		[
			{ labels: ['package_name'], value: packageName, type: 'string' }
		],
		[
			{ labels: ['active'], value: active, type: 'is-active' }
		]
	]
}

const mapper = (json, card, details) => {
	return {
		...json,
		name: json.name,
		shortName: json.name,
		card: card ? mapCard(json) : []
	}
}

const header = [
	{ label: ['Organization Name'], align: 'left', columnName: "name", sortable: true },
	{ label: ['Expiration Date'], align: 'left' },
	{ label: ['Package Name'], align: 'left', columnName: "profiles.name", sortable: true },
	{ label: ['Active'], align: 'center' },
]

const organizationadministrationMapper = {
	map: mapper,
	header
};

export default organizationadministrationMapper