import React, {
	forwardRef,
	useState,
	useRef,
	useImperativeHandle,
	useEffect,
} from "react";
import { useGlobalValue } from "colbi_web_ui/lib/state/GlobalProvider";
import {
	Dropdown,
	TextInput,
	TextArea,
	Checkbox,
} from "colbi_web_ui/lib/components/inputs";
import { FORM_ACTIONS } from "../FormActions";
import { checkRoles } from "colbi_web_ui/lib/utils/roleValidator";
import styles from "./NcRuleForm.module.sass";
import { UserRoles } from "../../../enums/userRoles";
import { datetime } from "../../../utils/datetime";
import CheckPermission from "colbi_web_ui/lib/components/CheckPermission/CheckPermission";

const NcRuleForm = (props, ref) => {
	const { data, disableForm, status, onChange, onSave, classifiers } = props;
	const { i18n, user } = useGlobalValue();
	const { severity } = classifiers || {};

	const {
		RuleID,
		RuleType,
		Severity: ruleSeverity,
		Description,
		DescriptionPreview,
		DescriptionDetail,
		Entity,
		AppliesTo,
		AppliesToVersion,
		CreatedAt,
		UpdatedAt,
		Metadata,
		Content,
		userDefined = 1,
	} = data || {};

	const [metadataFormatted, setMetadataFormatted] = useState("{}");
	const [contentFormatted, setContentFormatted] = useState("{}");

	useEffect(() => {
		try {
			if (Metadata) {
				setMetadataFormatted(
					JSON.stringify(JSON.parse(Metadata), undefined, 4)
				);
			}
			if (Content) {
				setContentFormatted(JSON.stringify(JSON.parse(Content), undefined, 4));
			}
		} catch (e) {
			console.log(e);
			//alert("Invalid Json");
		}
	}, [Metadata, Content]);

	const disableInputs =
		checkRoles(user, [UserRoles.OWNER]) && disableForm && userDefined === 0
			? true
			: false;

	const tabs = ["Headers", "Commands"];

	const formRef = useRef();
	const [selected, setSelected] = useState(0);

	useImperativeHandle(ref, () => ({
		submit: () => {
			formRef.current.dispatchEvent(
				new Event("submit", { cancelable: true, bubbles: true })
			);
		},
	}));

	const handleChange = (e, prop, value) => {
		onChange &&
			onChange({
				originalEvent: e,
				target: {
					value: {
						data: {
							...data,
							[prop]: value,
						},
					},
				},
			});
	};

	return status !== "loading" ? (
		<form
			ref={formRef}
			onSubmit={(e) => {
				e.stopPropagation();
				e.preventDefault();
				if (formRef.current.checkValidity()) {
					data["Metadata"] = metadataFormatted;
					data["Content"] = contentFormatted;
					data["Severity"] = parseInt(ruleSeverity);
					onSave && onSave();
				} else {
					formRef.current.reportValidity();
				}
			}}
		>
			<div className={styles["tabs"]}>
				{(tabs || []).map((tabName, index) => (
					<div
						key={index}
						className={`${styles["tab"]} ${
							selected === index ? styles["is-selected"] : ""
						}`}
						onClick={() => {
							setSelected(index);
						}}
					>
						<small>{i18n`${tabName}`}</small>
					</div>
				))}
			</div>
			<div className={`${styles["inputs"]} ${styles[`visible-${selected}`]}`}>
				<div className={styles["headers"]}>
					<div className={styles["input-field"]}>
						<TextInput
							className={styles["input"]}
							name={i18n`Identificador`}
							required
							value={RuleID || ""}
							placeholder={i18n`Enter an identificator`}
							onChange={(e) => {
								handleChange(
									e,
									disableInputs ? "disabled" : "RuleID",
									e.target.value
								);
							}}
						/>
					</div>
					<div className={styles["input-field"]}>
						<TextInput
							className={styles["input"]}
							name={i18n`RuleType`}
							value={RuleType || ""}
							placeholder={i18n`Enter a Rule Type`}
							required
							onChange={(e) => {
								handleChange(
									e,
									disableInputs ? "disabled" : "RuleType",
									e.target.value
								);
							}}
						/>
					</div>
					<div className={styles["input-field"]}>
						<TextInput
							className={styles["input"]}
							name={i18n`Title`}
							value={Description || ""}
							placeholder={i18n`Enter a Title`}
							required
							onChange={(e) => {
								handleChange(
									e,
									disableInputs ? "disabled" : "Description",
									e.target.value
								);
							}}
						/>
					</div>
					<div className={styles["input-field"]}>
						<TextInput
							className={styles["input"]}
							name={i18n`DescriptionPreview`}
							required
							value={DescriptionPreview || ""}
							placeholder={i18n`Enter a Description Preview`}
							onChange={(e) => {
								handleChange(
									e,
									disableInputs ? "disabled" : "DescriptionPreview",
									e.target.value
								);
							}}
						/>
					</div>
					<div className={styles["input-field"]}>
						<TextInput
							className={styles["input"]}
							name={i18n`DescriptionDetail`}
							required
							value={DescriptionDetail || ""}
							placeholder={i18n`Enter a Description Detail`}
							onChange={(e) => {
								handleChange(
									e,
									disableInputs ? "disabled" : "DescriptionDetail",
									e.target.value
								);
							}}
						/>
					</div>
					<div className={styles["input-field"]}>
						<TextInput
							className={styles["input"]}
							name={i18n`Entity`}
							required
							value={Entity || ""}
							placeholder={i18n`Enter a Referer Entity`}
							onChange={(e) => {
								handleChange(
									e,
									disableInputs ? "disabled" : "Entity",
									e.target.value
								);
							}}
						/>
					</div>
					<div className={styles["input-field"]}>
						<h5 className={styles["label"]}>{i18n`Severity`}</h5>
						<Dropdown
							value={
								(ruleSeverity || ruleSeverity === 0) &&
								severity.toString().length
									? severity.find(
											({ id }) => parseInt(id) === parseInt(ruleSeverity)
									  ) || severity[0]
									: null
							}
							options={severity || []}
							placeholder={""}
							className={styles["dropdown"]}
							onChange={(e) => {
								handleChange(
									e,
									disableInputs ? "disabled" : "Severity",
									parseInt(e.target.value.id)
								);
							}}
						/>
					</div>
					<div className={styles["group-content"]}>
						<div className={styles["flex"]}>
							<div className={styles["input-field"]}>
								<TextInput
									className={styles["input"]}
									name={i18n`Created At`}
									defaultValue={datetime(CreatedAt) || ""}
									disabled
								/>
							</div>
							<div className={styles["input-field"]}>
								<TextInput
									className={styles["input"]}
									name={i18n`Updated At`}
									defaultValue={datetime(UpdatedAt) || ""}
									disabled
								/>
							</div>
						</div>
					</div>
				</div>
				<div className={styles["commands"]}>
					<div className={styles["group-content"]}>
						<div className={styles["flex"]}>
							<div className={styles["input-field"]}>
								<TextInput
									className={styles["input"]}
									name={i18n`Applies To`}
									value={AppliesTo || ""}
									placeholder={i18n`Enter a AppliesTo`}
									required
									onChange={(e) => {
										handleChange(
											e,
											disableInputs ? "disabled" : "AppliesTo",
											e.target.value
										);
									}}
								/>
							</div>
							<div className={styles["input-field"]}>
								<TextInput
									className={styles["input"]}
									name={i18n`Applies To Version`}
									value={AppliesToVersion || ""}
									placeholder={i18n`Enter a Applies To Version`}
									onChange={(e) => {
										handleChange(
											e,
											disableInputs ? "disabled" : "AppliesToVersion",
											e.target.value
										);
									}}
								/>
							</div>
						</div>
						<div className={styles["input-field"]}>
							<TextArea
								className={styles["input"]}
								value={metadataFormatted || "{}"}
								name={i18n`Metadata`}
								required
								onChange={(e) => {
									setMetadataFormatted(e.target.value);
								}}
							/>
						</div>
						<div className={styles["input-field"]}>
							<CheckPermission roles={[UserRoles.ADMIN, UserRoles.PP_SUPPORT]}>
								<Checkbox
									style={{ marginTop: "10px" }}
									value={userDefined}
									onChange={(e) => {
										handleChange(
											e,
											disableInputs ? "disabled" : "userDefined",
											e.target.checked ? 1 : 0
										);
									}}
								>{i18n`User Rule`}</Checkbox>
							</CheckPermission>
						</div>
						<div className={styles["input-field"]}>
							{userDefined === 0 ? null : (
								<TextArea
									className={styles["input"]}
									value={contentFormatted || "{}"}
									name={i18n`Content`}
									required
									onChange={(e) => {
										setContentFormatted(e.target.value);
									}}
								/>
							)}
						</div>
					</div>
				</div>
			</div>
		</form>
	) : null;
};

const RuleFormRef = forwardRef(NcRuleForm);

RuleFormRef.queries = (args) => {
	return [
		{
			resource: "disable_rule_form",
		},
		{
			resource: "classifiers",
			body: `
				severity{id, name}
			`,
		},
		args && args.id
			? {
					resource: "nc_rule",
					args: { ruleId: args.id },
					body: "__typename,id,RuleID,RuleType,Severity,Content,Description,DescriptionPreview,DescriptionDetail,Entity,AppliesTo,AppliesToVersion,CreatedAt,UpdatedAt,Metadata,userDefined",
			  }
			: undefined,
	].filter((q) => q);
};

RuleFormRef.dataMapper = ["disableForm", "classifiers", "data"];

RuleFormRef.actions = [FORM_ACTIONS.SAVE];

export default RuleFormRef;
