import { format, date } from "../../../utils";

const header = [
	{ label: ["ID"], align: "left", columnName: "asset_transaction_id", sortable: true },
	{ label: ["Type"], align: "left", columnName: "asset_transaction_type", sortable: true },
	{ label: ["Period"], align: "center", columnName: "period", sortable: true },
	{ label: ["Date"], align: "center", columnName: "asset_transaction_date", sortable: true },
	{ label: ["Transaction"], align: "left", columnName: "transaction_id", sortable: true },
	{ label: ["Asset Transaction Amount"], align: "right", columnName: "asset_transaction_amount", sortable: true },
	{ label: ["Book Value On Transaction"], align: "right", columnName: "book_value_on_transaction", sortable: true },
	{ label: ["Acquisition And Production Cost So"], align: "right", columnName: "acquisition_and_production_cost_so", sortable: true },
	{ label: ["Debit Credit Indicator"], align: "center", columnName: "debit_credit_indicator", sortable: true },
];

const mapCard = json => {
	const {
		transactionType,
		assetTransactionId,
		period,
		assetTransactionDate,
		transactionId,
		transactionAmount,
		bookValueOnTransaction,
		acquisitionAndProductionCostSo,
		debitCreditIndicator
	} = json;

	return [
		[{ labels: ["id"], value: assetTransactionId, type: "string" }],
		[{ labels: ["type"], value: transactionType, type: "string" }],
		[{ labels: ["period"], value: period, type: "number" }],
		[{ labels: ["date"], value: date(assetTransactionDate), type: "date" }],
		[
			{
				labels: ["transaction"],
				value: transactionId || "N/A",
				type: "string"
			}
		],
		[
			{ labels: ["amount"], value: format(transactionAmount, "fullValue"), type: "currency" }
		],
		[
			{ labels: ["bookValueOnTransaction"], value: format(bookValueOnTransaction, "fullValue"), type: "currency" }
		],
		[
			{ labels: ["acquisitionAndProductionCostSo"], value: format(acquisitionAndProductionCostSo, "fullValue"), type: "currency" }
		],
		[{ labels: ["Debit Credit Indicator"], value: debitCreditIndicator || 'N/A', type: "string", align: 'center' }],
	];
};

const mapper = (json, card) => {
	return {
		...json,
		__typename: json.__typename,
		id: json.asset_transaction_id,
		name: json.asset_transaction_id,
		shortName: json.asset_transaction_id,
		card: card ? mapCard(json) : []
	};
};
const assettransactionvaluationMapper = {
	map: mapper,
	header
};

export default assettransactionvaluationMapper;
