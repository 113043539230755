import { date } from "../../../utils";

const mapCard = (json) => {
	const {
		SapDocNo,
		SapDocType,
		hsn_code,
		quantity,
		UnitOfMeasure,
		taxable_value,
		cgst_tax_code,
		cgst_rate,
		cgst_amount,
		igst_tax_code,
		igst_rate,
		igst_amount,
		sgst_tax_code,
		sgst_rate,
		sgst_amount,
		invoice_date,
	} = json;
	return [
		[{ labels: ["SapDocNo"], value: SapDocNo, type: "string" }],
		[{ labels: ["SapDocType"], value: SapDocType, type: "string" }],
		[{ labels: ["invoice_date"], value: date(invoice_date), type: "date" }],
		[{ labels: ["hsn_code"], value: hsn_code, type: "string" }],
		[{ labels: ["quantity"], value: quantity, type: "string" }],
		[{ labels: ["unit_of_measure"], value: UnitOfMeasure, type: "string" }],
		[{ labels: ["taxable_value"], value: taxable_value, type: "string" }],
		[{ labels: ["cgst_tax_code"], value: cgst_tax_code, type: "string" }],
		[{ labels: ["cgst_rate"], value: cgst_rate, type: "percentage" }],
		[{ labels: ["cgst_amount"], value: cgst_amount, type: "string" }],
		[{ labels: ["sgst_tax_code"], value: sgst_tax_code, type: "string" }],
		[{ labels: ["sgst_rate"], value: sgst_rate, type: "percentage" }],
		[{ labels: ["sgst_amount"], value: sgst_amount, type: "string" }],
		[{ labels: ["igst_tax_code"], value: igst_tax_code, type: "string" }],
		[{ labels: ["igst_rate"], value: igst_rate, type: "percentage" }],
		[{ labels: ["igst_amount"], value: igst_amount, type: "string" }],
	];
};

const mapper = (json, card, details) => {
	return {
		...json,
		name: json.name,
		shortName: json.name,
		//details: details ? mapDetails(json) : [],
		card: card ? mapCard(json) : [],
	};
};

const header = [
	{
		label: ["SAP Doc. No."],
		align: "left",
		columnName: "SapDocNo",
		sortable: false,
	},
	{
		label: ["SAP Doc. Type"],
		align: "left",
		columnName: "SapDocType",
		sortable: false,
	},
	{
		label: ["Invoice Date"],
		align: "center",
		columnName: "invoice_date",
		sortable: false,
	},
	{
		label: ["Hsn Code"],
		align: "left",
		columnName: "hsn_code",
		sortable: false,
	},
	{
		label: ["Quantity"],
		align: "left",
		columnName: "quantity",
		sortable: false,
	},
	{
		label: ["Unit Of Measure"],
		align: "left",
		columnName: "unit_of_measure",
		sortable: false,
	},
	{
		label: ["Taxable Value (₹)"],
		align: "left",
		columnName: "taxable_value",
		sortable: false,
	},
	{
		label: ["CGST Tax Code"],
		align: "left",
		columnName: "cgst_tax_code",
		sortable: false,
	},
	{
		label: ["CGST Rate (%)"],
		align: "left",
		columnName: "cgst_rate",
		sortable: false,
	},
	{
		label: ["CGST Amount (₹)"],
		align: "left",
		columnName: "cgst_amount",
		sortable: false,
	},
	{
		label: ["SGST Tax Code"],
		align: "left",
		columnName: "sgst_tax_code",
		sortable: false,
	},
	{
		label: ["SGST Rate (%)"],
		align: "left",
		columnName: "sgst_rate",
		sortable: false,
	},
	{
		label: ["SGST Amount (₹)"],
		align: "left",
		columnName: "sgst_amount",
		sortable: false,
	},
	{
		label: ["IGST Tax Code"],
		align: "left",
		columnName: "igst_tax_code",
		sortable: false,
	},
	{
		label: ["IGST Rate (%)"],
		align: "left",
		columnName: "igst_rate",
		sortable: false,
	},
	{
		label: ["IGST Amount (₹)"],
		align: "left",
		columnName: "igst_amount",
		sortable: false,
	},
];

const allgstinvoicelineMapper = {
	map: mapper,
	header,
};

export default allgstinvoicelineMapper;
