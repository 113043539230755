import { checkNumberValues } from "../../../utils/convertUtils";

const mapCard = (json) => {
	const {
		isFirstYearOfApplication,
		district_code,
		salary_mass,
		taxa_da_derrama,
		municipality_ratio,
		municipal_surtax,
		municipal_area_of_operation,
		installed_power,
		total_produced_electricity
	} = json

	return [
		[
			{ labels: ['Indique Se É O 1.º Ano De Aplicação Do Critério'], value: isFirstYearOfApplication, type: 'string', basis: '20%' },
		],
		[
			{ labels: ['Código Do Distrito / Município'], value: district_code, type: 'string', basis: '20%' },
		],
		[
			{ labels: ['Taxa De Derrama'], value: checkNumberValues(taxa_da_derrama), type: 'number', basis: '20%' }
		],
		[
			{ labels: ['Massa Salarial + Prestações De Serviços No Município'], value: checkNumberValues(salary_mass), type: 'number', basis: '20%' }
		],
		[
			{ labels: ['Área De Instal. Ou Explor. No Município'], value: checkNumberValues(municipal_area_of_operation), type: 'number', basis: '20%' }
		],
		[
			{ labels: ['Potência Instalada No Município'], value: checkNumberValues(installed_power), type: 'number', basis: '20%' }
		],
		[
			{ labels: ['Total Da Eletric. Produzida No Município], value: checkNumberValues(installed_power)'], value: checkNumberValues(total_produced_electricity), type: 'number', basis: '20%' }
		],
		[
			{ labels: ['Rácio Município'], value: checkNumberValues(municipality_ratio), type: 'number', basis: '20%' }
		],
		[
			{ labels: ['Derrama CalculadaCalculada'], value: checkNumberValues(municipal_surtax), type: 'number', basis: '20%' }
		]
	]
}

const mapper = (json, card) => {
	return {
		...json,
		card: card ? mapCard(json) : []
	}
}

const header = [
	{ label: ["Indique Se É O 1.º Ano De Aplicação Do Critério"], align: 'left', basis: '20%' },
	{ label: ["Código Do Distrito / Município"], align: 'left', basis: '20%' },
	{ label: ["Taxa De Derrama"], align: 'left', basis: '20%' },
	{ label: ["Massa Salarial + Prestações De Serviços No Município"], align: 'left', basis: '20%' },
	{ label: ["Área De Instal. Ou Explor. No Município"], align: 'left', basis: '20%' },
	{ label: ["Potência Instalada No Município"], align: 'left', basis: '20%' },
	{ label: ["Total Da Eletric. Produzida No Município"], align: 'left', basis: '20%' },
	{ label: ["Rácio Município"], align: 'left', basis: '20%' },
	{ label: ["Derrama Calculada"], align: 'left', basis: '20%' }
]

const municipalsurtaxspecificMapper = {
	map: mapper,
	header
}

export default municipalsurtaxspecificMapper;