import { format } from '../../../utils'

const mapCard = (json) => {
    const {
        uomStandard,
        uomToUomBaseConversionFactor
    } = json

    return [
        [
            { labels: ['uomStandard'], value: uomStandard, type: 'string' }
        ],
        [
            { labels: ['uomToUomBaseConversionFactor'], value: format(uomToUomBaseConversionFactor, "fullValue"), type: 'currency' }
        ]
    ]
}

const mapper = (json, card) => {
    const name = json.product_id

    return {
        ...json,
        __typename: json.__typename,
        id: json.id,
        name,
        shortName: name,
        card: card ? mapCard(json) : []
    }
}

const header = [
    { label: ["UOM Standard"], align: 'left' },
    { label: ["UOM To UOM Base Conversion Factor"], align: 'left' }
]

const productuomsMapper = {
    map: mapper,
    header
}

export default productuomsMapper;