const header = [
    { label: ["Tax Registration Number"], align: 'left' },
    { label: ["Tax Type"], align: 'center' },
    { label: ["Country"], align: 'left' },
]

const mapCard = (json) => {
	const {
        taxRegistrationNumber,
        taxType,
        country
	} = json

	return [
        [
            { labels: ['taxRegistrationNumber'], value: taxRegistrationNumber, type: 'string' }
        ],
        [
            { labels: ['taxType'], value: taxType, type: 'number' }
        ],
        [
            { labels: ['country'], value: country, type: 'string' }
        ]
	]
}

const mapper = (json, card) => {
    const name = json.customer_id || json.supplier_id
    
    return {
        ...json,
        __typename: json.__typename,
        id: '',
		name,
        shortName: name,
		card: card ? mapCard(json) : []
	}
}

const taxregistrationsMapper = {
    map: mapper,
    header
}

export default taxregistrationsMapper;