import { format, date } from "../../../utils"

const mapDetails = (json) => {
	const {
		user,
		journal,
		period,
		glPostingDate,
		transactionSystemEntryDate,
		debit,
		credit,
		type,
		description,

		archivalNumber,
		taxEntity,
		customer,
		supplier,
		transactionDate
	} = json

	return [
		[
			{ label: 'Type', value: type, type: 'string' },
			journal ? { label: 'Journal', value: journal.name, type: 'string' } : null,
			{ label: 'Period', value: period, type: 'number' },
			{ label: 'Post Date', value: date(glPostingDate), type: 'date' },
			{ label: 'Transaction Date', value: date(transactionDate), type: 'date' },
			{ label: 'Registry Date', value: date(transactionSystemEntryDate), type: 'date' },
			{ label: "Description", value: description, type: 'string' }
		],
		[
			{ label: 'Total Debit', value: format(debit), type: 'currency' },
			{ label: 'Total Credit', value: format(credit), type: 'currency' }
		],
		[
			{ label: 'User', value: user, type: 'string' },
			{ label: 'Archival', value: archivalNumber, type: 'string' },
			{ label: 'Tax Entity', value: taxEntity, type: 'string' },
			customer ? { label: 'Customer', value: { id: customer.id, name: customer.name, __typename: 'Customer' }, type: 'object' } : null,
			supplier ? { label: 'Supplier', value: { id: supplier.id, name: supplier.name, __typename: 'Supplier' }, type: 'object' } : null
		]
	]
}

const mapCard = (json) => {

	const {
		transactionId,
		numberOfLines,
		description,
		journal,
		period,
		glPostingDate,
		debit,
		credit,
		type
	} = json

	return [
		[{ labels: ['ID'], value: `${transactionId}`, type: 'string', basis: '25%' }],
		[{ labels: ['journal'], value: journal ? journal.name : null, type: 'string', mobileHidden: true, basis: '17%' }],
		[{ labels: ['period'], value: period, type: 'number', basis: '10%' }],
		[{ labels: ['date'], value: date(glPostingDate), type: 'date', basis: '12%' }],
		[{ labels: ['type'], value: `${type}`, type: 'number', mobileHidden: true }],
		[{ labels: ['description'], value: `${description}`, type: 'string', mobileHidden: true, basis: '60%' }],
		[{ labels: ['lines'], value: `${numberOfLines}`, type: 'number', mobileHidden: true, basis: '10%' }],
		[{ labels: ['debit'], value: format(debit, 'normal'), type: 'currency' }],
		[{ labels: ['credit'], value: format(credit), type: 'currency' }]
	]
}

const mapper = (json, card, details) => {

	return {
		...json,
		name: json.transactionId,
		shortName: json.transactionId,
		details: details ? mapDetails(json) : [],
		card: card ? mapCard(json) : []
	}
}

const header = [
	{ label: ["ID"], align: 'left', columnName: "transaction_id", sortable: true, basis: '25%' },
	{ label: ["Journal"], align: 'left', mobileHidden: true, basis: '17%' },
	{ label: ["Period"], align: 'center', columnName: "period", sortable: true, basis: '10%' },
	{ label: ["Date"], align: 'center', basis: '12%' },
	{ label: ["Type"], align: 'center', mobileHidden: true },
	{ label: ["Description"], align: 'left', mobileHidden: true, basis: '60%' },
	{ label: ["Lines"], align: 'center', mobileHidden: true, basis: '10%' },
	{ label: ["Debit"], align: 'right', columnName: "debit_amount", sortable: true, totalizerName: 'total_debit' },
	{ label: ["Credit"], align: 'right', columnName: "credit_amount", sortable: true, totalizerName: 'total_credit' }
]

const transactionMapper = {
	map: mapper,
	header
}

export default transactionMapper;