const header = [
	{ label: ["Street"], align: "left",  columnName: "street_name", sortable: true, basis: '120%' },
	{ label: ["Number"], align: "left",  columnName: "number", sortable: true },
	{ label: ["Postal Code"], align: "left",  columnName: "postal_code", sortable: true },
	{ label: ["City"], align: "left",  columnName: "city", sortable: true },
	{ label: ["Region"], align: "left", columnName: "region",  sortable: true },
	{ label: ["Country"], align: "left", columnName: "country",  sortable: true },
	{ label: ["Address type"], align: "left", columnName: "address_type" },
];

const mapCard = json => {
	const {
		street,
		number,
		city,
		postalCode,
		region,
		country,
		addressType
	} = json;

	return [
		[{ labels: ["street"], value: street, type: "string", basis: '120%' }],
		[{ labels: ["number"], value: number, type: "number" }],
		[{ labels: ["postalCode"], value: postalCode, type: "string" }],
		[{ labels: ["city"], value: city, type: "string" }],
		[{ labels: ["region"], value: region, type: "string" }],
		[{ labels: ["country"], value: country, type: "string" }],
		[{ labels: ["addressType"], value: addressType, type: "string" }]
	];
};

const mapper = (json, card) => {
	const name = json.customer_id || json.supplier_id;

	return {
		...json,
		__typename: json.__typename,
		id: json.id,
		name,
		shortName: name,
		card: card ? mapCard(json) : []
	};
};
const addressMapper = {
	map: mapper,
	header
};

export default addressMapper;
