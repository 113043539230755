import { date } from '../../../utils'

const header = [
    { label: ["Warehouse"], align: 'left',columnName: "warehouse_id", sortable: true, },
    { label: ["Delivery"], align: 'center', columnName: "delivery_id", sortable: true, },
    { label: ["Delivery Date"], align: 'center',columnName: "delivery_date", sortable: true },
    { label: ["Street"], align: 'left' },
    { label: ["Number"], align: 'left' },
    { label: ["Postal Code"], align: 'left',columnName: "postal_code"},
    { label: ["City"], align: 'left',columnName: "city", sortable: true, },
    { label: ["Country"], align: 'left',columnName: "country", sortable: true, },
]

const mapCard = (json) => {
	const {
        warehouseId,
        deliveryId,
        deliveryDate,
        streetName,
        number,
        postalCode,
        city,
        country,
	} = json

	return [
        [
            { labels: ['warehouse'], value: warehouseId, type: 'string' }
        ],
        [
            { labels: ['delivery'], value: deliveryId, type: 'number' }
        ],
        [
            { labels: ['date'], value: date(deliveryDate), type: 'date' }
        ],
        [
            { labels: ['street'], value: streetName, type: 'string' }
        ],
        [
            { labels: ['number'], value: number, type: 'string' }
        ],
        [
            { labels: ['postalCode'], value: postalCode, type: 'string' }
        ],
        [
            { labels: ['city'], value: city, type: 'string' }
        ],
        [
            { labels: ['country'], value: country, type: 'string' }
        ]
    ]
}

const mapper = (json, card) => {
    const name = json.invoice_id || json.purchase_invoice_line_id || json.purchase_invoice_id || json.stock_movement_line_id || json.invoice_line_id
    
    return {
        ...json,
        __typename: json.__typename,
        id: json.id,
		name,
		shortName: name,
		card: card ? mapCard(json) : []
	}
}

const shipfromMapper = {
    map: mapper,
    header
}

export default shipfromMapper;