const mapDetails = json => {
    const { 
        registrationNumber, taxEntity, customerId, name, country , vatRegistrationNumber
    } = json;

    return [
        [
			{label: 'Customer Id', value: customerId, type: 'string'},
			{label: 'Entity_Name', value: name, type: 'string'},
			{label: 'Registration Number', value: registrationNumber, type: 'string'},
            {label: 'Vat Registration Number', value: vatRegistrationNumber, type: 'string'},
            {label: 'Entity', value: taxEntity, type: 'string'},
			{label: 'Country', value: country, type: 'string'},
        ]
    ];
};

const mapCard = json => {
    const {
        customerId,
        name,
        registrationNumber,
        taxEntity,
        vatRegistrationNumber,
        country
    } = json;
    return [
        [{labels: ["Customer Id"], value: customerId, type: "string", basis: "12%"}],
        [{labels: ["Customer Name"], value: name, type: "string", basis: "50%"}],
        [{labels: ["Vat Registration Number"], value: `${vatRegistrationNumber}`, type: "string"}],
        [{labels: ["Registration Number"], value: `${registrationNumber}`, type: "string"}],
        [{labels: ["country"], value: `${country}`, type: "string"}],
        [{labels: ["entity"], value: `${taxEntity}`, type: "string"}],
    ];
};


const mapper = (json, card, details) => {
    return {
        ...json,
        name: json.name,
        shortName: json.name,
        details: details ? mapDetails(json) : [],
        card: card ? mapCard(json) : [],
    };
};

const header = [
    {label: ['Customer id'], align: 'left', columnName: "customer_id", sortable: true, basis: "12%"},
    {label: ['Customer Name'], align: 'left', columnName: "name", sortable: true, basis: "50%"},
    {label: ['Vat Registration Number'], align: 'left', columnName: "vat_registration_number", sortable: true},
    {label: ['Registration Number'], align: 'left', columnName: "registration_number", sortable: true},
    {label: ['Country'], align: 'left', columnName: "country", sortable: true},
    {label: ['Entity'], align: 'left'},
]
const customerMapper = {
    map: mapper,
    header
}
export default customerMapper;
