const navigation = [
	{
		priority: 888,
		type: 'separator'
	},
	{
		priority: 999,
		name: 'label(Home)',
		to: '/',
		exact: true,
		icon: 'back'
	}
]

export default navigation
