import { checkNumberValues } from "../../../utils/convertUtils"

const mapCard = (json) => {
	const {
		district_code,
		taxa_da_derrama,
		salary_mass,
		municipality_ratio,
		municipal_surtax
	} = json

	return [
		[
			{ labels: ['Código Do Distrito / Município'], value: district_code, type: 'string', basis: '65%' },
		],
		[
			{ labels: ['Taxa De Derrama'], value: checkNumberValues(taxa_da_derrama), type: 'number', basis: '20%' }
		],
		[
			{ labels: ['Massa Salarial Do Município'], value: checkNumberValues(salary_mass), type: 'number', basis: '20%' }
		],
		[
			{ labels: ['Rácio De Repartição'], value: checkNumberValues(municipality_ratio), type: 'number', basis: '20%' }
		],
		[
			{ labels: ['Derrama Calculada'], value: checkNumberValues(municipal_surtax), type: 'number', basis: '20%' }
		]
	]
}

const mapper = (json, card) => {
	return {
		...json,
		card: card ? mapCard(json) : []
	}
}

const header = [
	{ label: ["Código Do Distrito / Município"], align: 'left', basis: '65%' },
	{ label: ["Taxa De Derrama"], align: 'left', basis: '20%' },
	{ label: ["Massa Salarial Do Município"], align: 'left', basis: '20%' },
	{ label: ["Rácio De Repartição"], align: 'left', basis: '20%' },
	{ label: ["Derrama Calculada"], align: 'left', basis: '20%' },
]

const municipalsurtaxgeneralMapper =  {
	map: mapper,
	header
}
export default municipalsurtaxgeneralMapper;