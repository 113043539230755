import I18n from '../../I18n'

const header = [
    { label: ["Title"], align: 'left' },
    { label: ["Rule Id"], align: 'left' },
    { label: ["Severity"], align: 'center' },
    { label: ["Entity"], align: 'center' },
    { label: ["type"], align: 'center' },
    { label: ["Number of Occurrences"], align: 'center' }
]

const mapCard = (json) => {
    const {
        name,
        type,
        severity,
        rule_identifier,
        reference_type,
        total,
        ruleTitle
    } = json

    const isRulesFinding = rule_identifier === 'RulesFinding'

    const referenceTypeFormated = reference_type.split("::").pop()

    const totalFindingsRound = total !== 0 ? (total > 1000 ? `> 1000`: total) : total

    return [
        [
            { labels: ['title'], value: isRulesFinding ? (I18n.translate(`${ruleTitle}`) || 'N/A') : I18n.translate(`${name}`), type: 'string' },
        ],
        [
            { labels: ['name'], value: isRulesFinding ? (name || 'N/A') : '', type: 'string' }
        ],
        [
            { labels: ['severity'], value: [`severity-${severity}`, I18n.translate`${`severity-${severity}`}`, 'center'], type: 'status' }
        ],
        [
            { labels: ['entity'], value: referenceTypeFormated? I18n.translate("reference_" + referenceTypeFormated): '', type: 'string', align: 'center' }
        ],
        [
            { labels: ['type'], value: type, type: 'string', align: 'center' }
        ],
        [
            { labels: ['verified'], value: totalFindingsRound, type: 'string', align: 'center' }
        ]
    ]
}

const mapper = (json, card) => {
    const name = json.name
    const severity = json.severity
    return {
        ...json,
        __typename: json.__typename,
        name,
        shortName: name,
        card: card ? mapCard(json) : [],
        link: {
			to: `/:locale(pt|en|lt|es)?/:projectId/:fiscalYear([0-9]{4}|current)/:importId/:modulePrefix/audit/rules/${json.id}`,
	        filter: [{ "filters": [{"value": `${severity}`}], "column": "Severity", "operator": "equals" }]
		}
    }
}

const findingsummaryMapper =  {
    map: mapper,
    header
}

export default findingsummaryMapper