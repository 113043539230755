import locales from "../I18n/locales";

const configsByTheme = (appTheme = "") => {
    switch (appTheme) {
        case "ey":
            return {
                icon: 'ey-logo',
                defaultLocale: 'en',
                contactEmailPerTheme: "saftanalytics.support@lt.ey.com",
                loginApplicationName: "EY SAF-T LT Analytics",
                locales: [
                    {
                        id: "en",
                        name: "EN"
                    },
                    {
                        id: "pt",
                        name: "PT"
                    },
                    {
                        id: "lt",
                        name: "LT"
                    }
                ]
            }
        case "taxivity":
            return {
                icon: 'taxivity-logo',
                defaultLocale: 'lt',
                contactEmailPerTheme: "support@taxivity.com",
                loginApplicationName: null,
                locales: [
                    {
                        id: "en",
                        name: "EN"
                    },
                    {

                        id: "lt",
                        name: "LT"
                    }
                ]
            }
        case "sovos":
            return {
                icon: 'sovos-logo',
                defaultLocale: 'en',
                contactEmailPerTheme: "sovos@sovos.com",
                loginApplicationName: "APR SAF-T",
                logoHorizontalDisplay: true,
                locales: [
                    {
                        id: "en",
                        name: "EN"
                    },
                    {
                        id: "pt",
                        name: "PT"
                    },
                    {
                        id: "lt",
                        name: "LT"
                    }
                ]
            }
        case "at-cv":
            return {
                icon: 'logo',
                defaultLocale: 'pt',
                contactEmailPerTheme: "contact.center@petapilot.com",
                loginApplicationName: null,
                locales: [
                    {
                        id: "en",
                        name: "EN"
                    },
                    {
                        id: "pt",
                        name: "PT"
                    }
                ]
            }
            case "pwcro":
                return {
                    icon: 'pwcro-logo',
                    defaultLocale: 'ro',
                    contactEmailPerTheme: "pwc@pwc.com",
                    loginApplicationName: "Tax Reporting Hub",
                    locales: [
                        {
                            id: "en",
                            name: "EN"
                        },
                        {
                            id: "ro",
                            name: "RO"
                        }
                    ]
                }
        default:
            return {
                icon: 'logo',
                defaultLocale: 'en',
                contactEmailPerTheme: "contact.center@petapilot.com",
                loginApplicationName: null,
                locales: locales()
            }
    }
};

export default configsByTheme;