import * as fragments from './fragments'
import xlsxTranslations from '../I18n/xlsxLabels.json'

const isInt = (n) => (
	Number(n) === n && n % 1 === 0
)

export function number(value) {
	return value !== undefined ? Number(value) : undefined
}

export function array(value) {
	return (value || '').split(',').map(v => v.trim())
}

export function jsonFilter(filterString) {
	const jsonValue = {}
	const pairs = (filterString || '').split(',').filter(p => p)

	pairs.forEach((pair) => {
		const [key, value] = pair.split(':')
		jsonValue[key] = decodeURIComponent(value)
	})
	return jsonValue
}

export function searchFilter(json) {
	return Object.entries(json).map(([key, value]) => (`${key}:${value}`)).join(',')
}

export function parseFilter(filterString) {
	try {
		const s = decodeURIComponent(escape(window.atob(filterString)))
		return JSON.parse(s)
	} catch (_) {
		return {}
	}
}

export function stringifyFilter(filterString) {
	try {
		const s = unescape(encodeURIComponent(JSON.stringify(filterString)))
		return btoa(s)
	} catch (_) {
		return ''
	}
}

export function alias({ resource, args, locale, body }, appendBody, appendAll) {
	const aliasArgs = (args || {})

	const argsString = resource !== 'search' ? Object.entries(aliasArgs)
		.filter(([k, v]) => k !== 'page' && k !== 'pageSize' && v)
		.sort(([aK], [bK]) => aK.localeCompare(bK))
		.map(([k, v]) => (`${k}_${(Array.isArray(v) ? v.map((vv) => typeof vv === 'string' ? vv : JSON.stringify(vv)).join('_') : JSON.stringify(v))}`))
		.join('_')
		.normalize('NFD')
		.replace(/-/g, '_')
		.replace(/&/g, 'e')
		.replace(/\|/g, 'o')
		.replace(/[\u0300-\u036f]/g, '')
		.replace(/[^\w ]+/g, '')
		.replace(/ +/g, '_') : ''

	return `${locale ? `${locale}_` : ''}${resource}${argsString ? `_${argsString}` : ''}${appendBody && body ? `(${body})` : ''}${appendAll && (args.page || args.pageSize) ? `_pagging_${args.page}_${args.pageSize}` : ''}`
}

const getRequiredFragements = (fragment, fragmentsList, download) => {
	const fragments = []
	const { entity, body, xlsxExport } = fragmentsList[fragment] || {}
 
	const fragmentDependencies = ((download ? xlsxExport || body : body || '').match(/\.{3}[a-zA-Z0-9_]+/gm) || []).map(f => f.substring(3))

	fragmentDependencies.forEach((dependency) => {
		const innerFragments = getRequiredFragements(dependency, fragmentsList, download)
		fragments.push(...innerFragments)
	})
	if (entity && (download ? xlsxExport || body : body) && entity.length && (download ? xlsxExport || body : body).length) {
		fragments.push([
			fragment,
			`fragment ${fragment} on ${entity} {__typename,${(download ? xlsxExport || body : body)}}`
		])
	}
	return fragments
}

export function buildRequestBody(queries = [], locale, authToken = null, download = false) {
	if (!queries || !queries.length) {
		return null
	}
	const variables = authToken ? {authToken} : {}
	const variablesTypes = new Set()
	const queryFragments = {}
	const queriesString = []
	const qq = (queries || [])
	qq.forEach((query) => {
		const args = query.args || {}
		const queryStringArgs = []
		let increment = 0
		Object.entries(args).forEach(([key, argValue]) => {
			++increment
			const type = (argValue || {}).type ? argValue.type : (Array.isArray(argValue) ? 'array' : typeof argValue)
			const value = (argValue || {}).value ? argValue.value : argValue
			// TODO: add type for FilterInput
			const tempKey = (variables[key] && variables[key] !== value)? `${key}${increment}`: `${key}`
			switch (type) {
				case 'number':
					if (value >= 0) {
						const numberType = isInt(value) ? 'Int' : 'Float'
						variablesTypes.add(`$${tempKey}: ${numberType}`)
						variables[tempKey] = value
						queryStringArgs.push(`${key}:$${tempKey}`)
					}
					break
				case 'array':
					const innerType = typeof value[0] === 'number' ? isInt(value) ? 'Int' : 'Float' : 'String'
					variablesTypes.add(`$${tempKey}: [${innerType}]`)
					variables[tempKey] = value
					queryStringArgs.push(`${key}:$${tempKey}`)
					break
				case 'object':
					variablesTypes.add(`$${tempKey}: JSON`)
					variables[tempKey] = value
					queryStringArgs.push(`${key}:$${tempKey}`)
					break
				case 'undefined':
					break
				default:
					variablesTypes.add(`$${tempKey}: ${type !== 'string' ? type : 'String'}`)
					variables[tempKey]= value
					queryStringArgs.push(`${key}:$${tempKey}`)
					break
			}
		})

		const queryAlias = alias({
			resource: query.resource,
			args: query.args,
			locale
		})
		const queryString = `${queryAlias}:${query.resource}${queryStringArgs.length ? `(${queryStringArgs.join(',')})` : ''}${query.body ? `{${query.body}}` : ''}`
		queriesString.push(queryString)

		if (query.body) {
			const foundFragments = query.body.match(/\.{3}[a-zA-Z0-9_]+/gm)
			if (foundFragments) {
				foundFragments.forEach((fragment) => {
					const fragmentName = fragment.substring(3)
					const requiredFragments = getRequiredFragements(fragmentName, fragments, download)
					requiredFragments.forEach(([name, body]) => {
						if (!queryFragments[name]) {
							queryFragments[name] = body
						}
					})
				})
			}

		}

		if(query && query.resource){
			variables.entityName = query.resource;
		}

		if (query.xlsxTranslation) {
			const xlsxLabels = xlsxTranslations[locale][query.xlsxTranslation];
			variables.xlsxLabels = xlsxLabels;
		}
	})

	const query = `${[...Object.values(queryFragments)].join('\n')}query${variablesTypes.size ? `( ${[...variablesTypes].join(',')} )` : ''}{${queriesString.join()}}`
	return {
		query,
		variables
	}
}

export function checkForCustomFragment(modulePrefix, resource) {
	const entityResource = modulePrefix ? `${modulePrefix}_${resource}` : resource;
	let customFragment = false;

	if(entityResource && fragments && fragments[entityResource]){
		customFragment = true
	}

	return customFragment
}

export const camelizeResource = (str) => {
	return (str || "").replace(/_([a-z])/g, (v) => v[1].toUpperCase());
};

export const getEntityNameResource = (entity) => {
	return (entity || "").substring(0, (entity || "").length - 1);
};