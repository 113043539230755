const header = [
    { label: ["Description"], align: 'left' },
    { label: ["Analysis ID"], align: 'left' },
    { label: ["Type"], align: 'left' },
    { label: ["STI Analysis ID"], align: 'left' }
]

const mapCard = (json) => {
	const {
        analysisId,
        type,
        stiAnalysis,
        description
	} = json

	return [
        [
            { labels: ['description'], value: description, type: 'string' }
        ],
        [
            { labels: ['analysis_id'], value: analysisId, type: 'string' }
        ],
        [
            { labels: ['type'], value: type, type: 'string' }
        ],
        [
            { labels: ['stiAnalysis'], value: stiAnalysis, type: 'string' }
        ]
	]
}

const mapper = (json, card) => {
    const name = json.analysisID
    
    return {
        ...json,
        __typename: json.__typename,
        id: '',
		name,
		shortName: name,
		card: card ? mapCard(json) : []
	}
}

const analysistypesMapper = {
    map: mapper,
    header
}

export default analysistypesMapper;