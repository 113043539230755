import { format, date } from '../../../utils'

const header = [
    { label: ["Invoice Nº"], align: 'left' },
    { label: ["Debit / Credit Indicator"], align: 'center'},
    { label: ["Transaction"], align: 'center' },
    { label: ["Date"], align: 'center' },
    { label: ["GL Posting Date"], align: 'center' },
    { label: ["Amount Currency Code"], align: 'center' },
    { label: ["Unpaid Amount Currency Code"], align: 'center' },
    { label: ["Amount"], align: 'right' },
    { label: ["Amount Currency"], align: 'right' },
    { label: ["Unpaid Amount"], align: 'right' },
    { label: ["Unpaid Amount Currency"], align: 'right' }
]

const mapCard = (json) => {
	const {
        invoiceNo,
        debitCreditIndicator,
        transactionRowKey,
        transactionId,
        invoiceDate,
        glPostingDate,
        amountCurrencyCode,
        unpaidAmountCurrencyCode,
        amount,
        amountCurrency,
        unpaidAmount,
        unpaidAmountCurrency
	} = json

	return [
        [
            { labels: ['invoiceNo'], value: invoiceNo, type: 'string' }
        ],
        [
            { labels: ['debitCreditIndicator'], value: debitCreditIndicator, type: 'number' }
        ],
        [
            transactionRowKey ?
                { label: 'Transaction', value: { id: transactionRowKey, name: transactionId, __typename: 'Transaction' }, type: 'object' } : 
                { labels: ['Transaction'], value: transactionId || 'N/A', type: 'string' }
        ],
        [
            { labels: ['invoiceDate'], value: date(invoiceDate), type: 'date' }
        ],
        [
            { labels: ['glPostingDate'], value: date(glPostingDate), type: 'date' }
        ],
        [
            { labels: ['amountCurrencyCode'], value: amountCurrencyCode, type: 'number' }
        ],
        [
            { labels: ['unpaidAmountCurrencyCode'], value: unpaidAmountCurrencyCode, type: 'number' }
        ],
        [
            { labels: ['amount'], value: format(amount, "fullValue"), type: 'currency' }
        ],
        [
            { labels: ['amountCurrency'], value: format(amountCurrency, "fullValue"), type: 'currency' }
        ],
        [
            { labels: ['unpaidAmount'], value: format(unpaidAmount, "fullValue"), type: 'currency' }
        ],
        [
            { labels: ['unpaidAmountCurrency'], value: format(unpaidAmountCurrency, "fullValue"), type: 'currency' }
        ]
	]
}

const mapper = (json, card) => {
    const name = json.customer_id || json.supplier_id
    
    return {
        ...json,
        __typename: json.__typename,
        id: json.id,
		name,
		shortName: name,
		card: card ? mapCard(json) : []
	}
}

const openinvoiceMapper = {
    map: mapper,
    header
}
export default openinvoiceMapper;