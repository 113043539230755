import { date } from "colbi_web_ui/lib/utils";
import I18n from "../../I18n";

const severityRender = (type) => {
	switch (parseInt(type, 10)) {
		case 0:
			return "Low";
		case 1:
			return "Medium";
		case 2:
			return "High";
		case 3:
			return "Critical";
		default:
			return;
	}
};

const header = [
	{ label: ["Message"], align: "left" },
	{ label: ["Reference"], align: "left" },
	{ label: ["Created At"], align: "center" },
	{ label: ["Verified"], align: "center" },
];

const mapCard = (json) => {
	const { message, referenceType, verified, createdAt, referenceId } = json;

	const referenceTypeFormatted = referenceType
		? referenceType.split("::").pop()
		: "";

	return [
		[{ labels: ["message"], value: message || "N/A", type: "string" }],
		[
			referenceId
				? {
						label: "Refers to",
						value: {
							id: referenceId,
							name: referenceTypeFormatted,
							__typename: referenceTypeFormatted,
						},
						type: "object",
				  }
				: { labels: ["message"], value: "N/A", type: "string" },
		],
		[{ labels: ["createdAt"], value: date(createdAt), type: "date" }],
		[
			{
				labels: ["verified"],
				value: [
					verified ? "valid" : "invalid",
					verified ? I18n.translate`Verified` : I18n.translate`Not Verified`,
				],
				type: "audit",
			},
		],
	];
};

export const mapDetails = (json) => {
	const {
		line,
		column,
		xmlBlock,
		originalMessage,
		type,
		referenceType,
		createdAt,
		severity,
		referenceId,
	} = json;

	let others = [];

	const referenceTypeFormatted = referenceType
		? referenceType.split("::").pop()
		: "";

	if (type !== "RulesFinding") {
		others = [
			[
				{ label: "Line", value: line || "N/A", type: "string" },
				{ label: "Column", value: column || "N/A", type: "string" },
			],
			[
				{ label: "XML Block", value: xmlBlock || "N/A", type: "string" },
				{
					label: "Original Message",
					value: originalMessage || "N/A",
					type: "string",
				},
			],
		];
	}

	return [
		[
			{
				label: "Type",
				value: type,
				type: "string",
			},
			{
				label: "Severity",
				value: severityRender(severity),
				type: "string",
			},
			{
				label: "Refers to",
				value: {
					id: referenceId,
					name: referenceTypeFormatted,
					__typename: referenceTypeFormatted,
				},
				type: "object",
			},

			{
				label: "Creation Date",
				value: date(createdAt),
				type: "date",
			},
		],
		[],
		...others,
	];
};

const mapper = (json, card) => {
	const name = json.message;

	return {
		...json,
		__typename: json.__typename,
		id: json.id,
		name,
		shortName: name,
		card: card ? mapCard(json) : [],
		details: mapDetails(json),
		link: {
			to: `/:locale(pt|en|lt|es)?/:projectId/:fiscalYear([0-9]{4}|current)/:importId/:modulePrefix/audit/rules/:id/finding/${json.id}`,
		},
	};
};

const findinglinesMapper = {
	map: mapper,
	header,
};

export default findinglinesMapper;
