const header = [
    { label: ["Reference"], align: 'left' },
	{ label: ["Reason"], align: 'left' }
]

const mapCard = (json) => {
	const {
        reference,
        reason
	} = json

	return [
        [
            { labels: ['reference'], value: reference, type: 'string' }
        ],
        [
            { labels: ['reason'], value: reason, type: 'string' }
        ]
	]
}

const mapper = (json, card) => {
    const name = json.invoice_id || json.purchase_invoice_id
    
    return {
        ...json,
        __typename: json.__typename,
        id: json.id,
		name,
        shortName: name,
		card: card ? mapCard(json) : []
	}
}

const creditnoteMapper = {
    map: mapper,
    header
}

export default creditnoteMapper