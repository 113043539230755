import React, { forwardRef, useRef, useImperativeHandle } from "react";
import { useGlobalValue } from "colbi_web_ui/lib/state/GlobalProvider";
import { FORM_ACTIONS } from "../FormActions";
import styles from "./FileSubmissionForm.module.sass";
import { Dropdown, TextInput } from "colbi_web_ui/lib/components/inputs";
import { encryptPassword } from "../../../utils/passwordEncrypt";

const FileSubmissionForm = (props, ref) => {
	const {
		data,
		status,
		onChange,
		onSave,
		filenames,
		fileIds,
		projectId,
		filesWithProblems,
		filePeriods,
	} = props;
	const formRef = useRef();

	function monthName(mon) {
		return [
			"January",
			"February",
			"March",
			"April",
			"MonthMay",
			"June",
			"July",
			"August",
			"September",
			"October",
			"November",
			"December",
		][mon - 1];
	}

	const { i18n } = useGlobalValue();

	const monthArray = Array.from(Array(13), (_, x) => {
		return { id: x, name: monthName(x) };
	}).filter((elem) => elem.id !== 0);

	const handleChange = (e, prop, value) => {
		onChange &&
			onChange({
				originalEvent: e,
				target: {
					value: {
						data: {
							...data,
							[prop]: value,
						},
					},
				},
			});
	};

	useImperativeHandle(ref, () => ({
		submit: () => {
			formRef.current.dispatchEvent(new Event("submit", { cancelable: true, bubbles: true }));
		},
	}));

	return status !== "loading" ? (
		<form
			ref={formRef}
			autoComplete="off"
			onSubmit={(e) => {
				e.preventDefault();
				e.stopPropagation();
				if (formRef.current.checkValidity()) {
					data["__typename"] = "DataFileSubmission";
					data["dataFileIds"] = fileIds;
					data["projectId"] = projectId;
					data["userPasswordEncripted"] = encryptPassword(data["userPassword"]);
					data["filesWithProblems"] = filesWithProblems || [];
					data["filePeriods"] = filePeriods || [];
					onSave && onSave();
				} else {
					formRef.current.reportValidity();
				}
			}}
		>
			<div>
				<h5>{i18n`Files to Submit: ${(filenames || []).join(", ")}`}</h5>
			</div>
			<div className={styles["input-field"]}>
				<h5
					className={styles["input-label"]}
				>{i18n`Tax Authority Password`}</h5>
				<TextInput
					className={styles["password-input"]}
					placeholder={i18n`Enter password`}
					required
					onChange={(e) => {
						handleChange(e, "userPassword", e.target.value);
					}}
				/>
				<div className={styles["input-field"]}>
					<h5 className={styles["label"]}>{i18n`Period`}</h5>
					<Dropdown
						className={styles["dropdown"]}
						placeholder={i18n`Select a Period`}
						value={
							monthArray
								? monthArray.find(
										({ id }) => id === parseInt((data || {}).period)
								  )
								: null
						}
						options={monthArray || []}
						required
						filter
						onChange={(e) => {
							handleChange(e, "period", e.target.value.id);
						}}
					/>
				</div>
			</div>
		</form>
	) : null;
};

const FileSubmissionFormRef = forwardRef(FileSubmissionForm);

FileSubmissionFormRef.queries = () => {
	return [];
};

FileSubmissionFormRef.dataMapper = [];

FileSubmissionFormRef.actions = [FORM_ACTIONS.SUBMIT];

export default FileSubmissionFormRef;
