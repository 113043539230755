import audit from "./audit";
import auditSummary from "./auditSummary"
import auditNoteByFile from "./auditNoteByFile";
import auditByFile from "./auditByFile";
import findingRule from "./findingRule";
import finding from "./finding";
import repository from "./repository";
import generationRepository from "./generationRepository";
import entityWithoutHeader from "./entityWithoutHeader";
import defaultNavigation from "./navigation";
import masterDataByFile from "./masterDataByFile";
import entityByFile from "./entityByFile";
import transactionsByFile from "./transactionsByFile";
import reportByFile from "./reportByFile";
import findingRuleByFile from "./findingRuleByFile";
import saftHuOverview from "./saftHuOverview";
import reportSaftAo from "./reportSaftAo";
import detailEntity from "./detailEntity";
import genericOverview from "./genericOverview";
import legacyOverview from "./legacyOverview";
import report from "./report";
import legacyEntity from "./legacyEntity";
import legacyMasterData from "./legacyMasterData";
import legacyTransactions from "./legacyTransactions";
import legacyOverviewSaftLt from "./legacyOverviewSaftLt";
import efaturaAuditFindings from "./efaturaAuditFindings";
import efaturaMasterData from "./efaturaMasterData";
import efaturaOverviewData from "./efaturaOverviewData";
import efaturaTransactionalData from "./efaturaTransactionalData";
import statement from "./statement";
import entityWithoutFindings from "./entityWithoutFindings";
import dashboardEInvoice from "./dashboardEInvoice";
import entitiesDataWithoutFindings from "./entitiesDataWithoutFindings";
import gstReturnToSubmit from "./gstReturnToSubmit";
import gstr2aRequestSubmit from "./gstr2aRequestSubmit";
import isafTransactions from "./isafTransactions";
import overviewErp from "./overviewErp";
import fileSubmission from "./fileSubmission";
import auditSummaryByFile from './auditSummaryByFile';
import legacyGenericOverview from "./legacyGenericOverview";
import generationPt from "./generationPt";
import findingsSchemaWithoutFiscalYear from "./findingsSchemaWithoutFiscalYear";
import genericReportsByFile from './genericReportsByFile';

let navigation = [];

const menuRouting = {
	saft_ro: [
		{
			level: 0,
			priority: 1,
			name: "label(Overview)",
			to: "/:projectId/:fiscalYear([0-9]{4})/:importId([0-9]*|full)/saft_ro",
			exact: true,
			icon: "dashboard",
			routing: [
				genericOverview(
					"/:locale(pt|en|lt|es|ro)?/:projectId/:fiscalYear([0-9]{4}|current)?/:importId([0-9]*|full)?/:modulePrefix(saft_ro)?/",
					navigation,
					true,
					"label(Overview)",
					true
				),
			],
			queries: [],
		},
		{
			level: 0,
			priority: 2,
			name: "label(Audit)",
			icon: "audit-icon",
			badge: "prop(saftRoNcAuditSummaryTotal)",
			isToBuildMenu: true,
			navigation: [
				{
					name: [
						"label(Results)",
						" (",
						"prop(saftRoNcAuditSummaryTotal)",
						")",
					],
					to: "/:projectId/:fiscalYear([0-9]{4})/:importId/nc_audit",
				},
				{
					name: 'label(Execution Summary)',
					to: '/:projectId/:fiscalYear([0-9]{4})/:importId/nc_audit_execution_summary'
				},
				{
					name: "label(Audit Notes)",
					to: "/:projectId/:fiscalYear([0-9]{4})/:importId/audit_notes",
				},
			],
			routing: [
				auditByFile(
					"/:locale(pt|en|lt|es|ro)?/:projectId/:fiscalYear([0-9]{4}|current)/:importId/:entity(nc_audit|nc_audit_schema)?",
					navigation,
					true,
					"label(Audit)",
					true
				),
				auditSummaryByFile('/:locale(pt|en|lt|es|ro)?/:projectId/:fiscalYear([0-9]{4}|current)/:importId/nc_audit_execution_summary', navigation, true, 'label(Execution Summary)'),
				auditNoteByFile(
					"/:locale(pt|en|lt|es|ro)?/:projectId/:fiscalYear([0-9]{4}|current)/:importId/audit_notes",
					navigation,
					true,
					"label(Audit Notes)",
					true
				),
				findingRuleByFile(
					"/:locale(pt|en|lt|es|ro)?/:projectId/:fiscalYear([0-9]{4}|current)/:importId/:modulePrefix(saft_ro)?/audit/rules/:id",
					navigation,
					true,
					"label(Rules)",
					true
				),
			],
			queries: [
				{
					resource: "nc_audit_summary_total",
					args: {
						projectId: "",
						fiscalYear: "",
						genericPrefix: "saft_ro",
						importId: "",
					},
					body: "",
				},
			],
		},
		{
			priority: 2,
			name: "Reports",
			icon: "reports",
			level: 0,
			navigation: [
				{
					name: "label(Financial Reports)",
					exact: false,
					icon: "invoices",
					level: 1,
					navigation: [
                        {	name: 'label(Trial Balance)',
							to: '/:projectId/:fiscalYear([0-9]{4})/:importId/saft_ro/reports/nc_trial_balance',
							level: 2,
						},
						{
							name: "label(statement_accounts_accumulated)",
							to: "/:projectId/:fiscalYear([0-9]{4})/:importId/saft_ro/reports/nc_statement_accounts_accumulated",
							level: 2,
						},
						{
							name: "label(offsetting_account)",
							to: "/:projectId/:fiscalYear([0-9]{4})/:importId/saft_ro/reports/nc_offsetting_account",
							level: 2,
						},
					],
				},
			],
			routing: [
				reportByFile(
					"/:locale(pt|en|lt|es|ro)?/:projectId/:fiscalYear([0-9]{4}|current)/:importId/:modulePrefix(saft_ro)/reports/:id",
					navigation,
					true,
					"label(Reports)",
					true
				),
			],
		},
		{
			name: "label(SAFT-RO)",
			icon: "reports",
			priority: 5,
			level: 0,
			isToBuildMenu: true,
			navigation: [
				{
					level: 1,
					name: "label(Master Data)",
					icon: "master-data",
					navigation: "prop(saftRoGenericMenuMasterData)",
				},
				{
					level: 1,
					name: "label(TransactionsMenu)",
					icon: "transactions",
					navigation: "prop(saftRoGenericMenuTransactionalData)",
				},
			],
			routing: [
				masterDataByFile(
					"/:locale(pt|en|lt|es|ro)?/:projectId/:fiscalYear([0-9]{4}|current)/:importId/:modulePrefix(saft_ro)/master_data/:entity?",
					navigation,
					true,
					"label(Master Data)",
					true
				),
				entityByFile(
					"/:locale(pt|en|lt|es|ro)?/:projectId/:fiscalYear([0-9]{4}|current)/:importId/:modulePrefix(saft_ro)/master_data/:entities*",
					navigation,
					true,
					"label(Master Data)",
					true
				),
				transactionsByFile(
					"/:locale(pt|en|lt|es|ro)?/:projectId/:fiscalYear([0-9]{4}|current)/:importId/:modulePrefix(saft_ro)/:entity",
					navigation,
					true,
					"label(TransactionsMenu)",
					true
				),
				entityByFile(
					"/:locale(pt|en|lt|es|ro)?/:projectId/:fiscalYear([0-9]{4}|current)/:importId/:modulePrefix(saft_ro)/:entities*",
					navigation,
					true,
					"label(TransactionsMenu)",
					true
				),
			],
			queries: [
				{
					resource: "generic_menu_master_data",
					subEntity: "master_data",
					args: {
						projectId: "",
						fiscalYear: "",
						importId: "",
						genericPrefix: "saft_ro",
					},
					body: "entity, count",
				},
				{
					resource: "generic_menu_transactional_data",
					args: {
						projectId: "",
						fiscalYear: "",
						importId: "",
						genericPrefix: "saft_ro",
					},
					body: "entity, count",
				},
			],
		},
	],
	csv_ro: [
		{
			name: 'label(CSV Data)',
			icon: 'reports',
			priority: 5,
			level: 0,
			isToBuildMenu: true,
			navigation: [
				{
					level: 1,
					name: 'label(Master Data)',
					icon: 'master-data',
					navigation: 'prop(csvRoGenericMenuMasterData)',
				},
				{
					level: 1,
					name: 'label(TransactionsMenu)',
					icon: 'transactions',
					navigation: 'prop(csvRoGenericMenuTransactionalData)'
				},
			],
			routing: [
				masterDataByFile('/:locale(pt|en|lt|es|ro)?/:projectId/:fiscalYear([0-9]{4}|current)/:importId/:modulePrefix(csv_ro)/master_data/:entity?', navigation, true, 'label(Master Data)', true),
				entityByFile('/:locale(pt|en|lt|es|ro)?/:projectId/:fiscalYear([0-9]{4}|current)/:importId/:modulePrefix(csv_ro)/master_data/:entities*', navigation, true, 'label(Master Data)', true),
				generationRepository('/:locale(pt|en|lt|es|ro)?/:projectId/:fiscalYear([0-9]{4}|current)/:importId/:modulePrefix/generation_repository/', navigation, true, 'label(Generation Repository)'),
				transactionsByFile('/:locale(pt|en|lt|es|ro)?/:projectId/:fiscalYear([0-9]{4}|current)/:importId/:modulePrefix(csv_ro)/:entity', navigation, true, 'label(TransactionsMenu)', true),
				entityByFile('/:locale(pt|en|lt|es|ro)?/:projectId/:fiscalYear([0-9]{4}|current)/:importId/:modulePrefix(csv_ro)/:entities*', navigation, true, 'label(TransactionsMenu)', true),
			],
			queries: [
				{
					resource: "generic_menu_master_data",
					subEntity: "master_data",
					args: {
						projectId: '',
						fiscalYear: '',
						importId: '',
						genericPrefix: 'csv_ro',
					},
					body: "entity, count"
				},
				{
					resource: "generic_menu_transactional_data",
					args: {
						projectId: '',
						fiscalYear: '',
						importId: '',
						genericPrefix: 'csv_ro',
					},
					body: "entity, count"
				},
			]
		},
	],
	saft_lt: [
		{
			level: 0,
			priority: 1,
			name: "label(Overview)",
			to: "/:projectId/:fiscalYear([0-9]{4})/:importId([0-9]*|full)/saft_lt",
			exact: true,
			icon: "dashboard",
			routing: [
				legacyGenericOverview(
					"/:locale(pt|en|lt|es)?/:projectId/:fiscalYear([0-9]{4}|current)?/:importId([0-9]*|full)?/:modulePrefix(saft_lt)?/",
					navigation,
					true,
					"label(Overview)",
					true
				),
			],
			queries: [],
		},
		{
			level: 0,
			priority: 2,
			name: "label(Audit)",
			icon: "audit-icon",
			badge: "prop(saftLtNcAuditSummaryTotal)",
			isToBuildMenu: true,
			navigation: [
				{
					name: [
						"label(Results)",
						" (",
						"prop(saftLtNcAuditSummaryTotal)",
						")",
					],
					to: "/:projectId/:fiscalYear([0-9]{4})/:importId/nc_audit",
				},
				{
					name: "label(Audit Notes)",
					to: "/:projectId/:fiscalYear([0-9]{4})/:importId/audit_notes",
				},
			],
			routing: [
				auditByFile(
					"/:locale(pt|en|lt|es)?/:projectId/:fiscalYear([0-9]{4}|current)/:importId/:entity(nc_audit|nc_audit_schema)?",
					navigation,
					true,
					"label(Audit)",
					true
				),
				auditNoteByFile(
					"/:locale(pt|en|lt|es)?/:projectId/:fiscalYear([0-9]{4}|current)/:importId/audit_notes",
					navigation,
					true,
					"label(Audit Notes)",
					true
				),
				findingRuleByFile(
					"/:locale(pt|en|lt|es)?/:projectId/:fiscalYear([0-9]{4}|current)/:importId/:modulePrefix(saft_lt)?/audit/rules/:id",
					navigation,
					true,
					"label(Rules)",
					true
				),
			],
			queries: [
				{
					resource: "nc_audit_summary_total",
					args: {
						projectId: "",
						fiscalYear: "",
						genericPrefix: "saft_lt",
						importId: "",
					},
					body: "",
				},
			],
		},
		{
			name: "label(SAFT-LT)",
			icon: "reports",
			priority: 5,
			level: 0,
			isToBuildMenu: true,
			navigation: [
				{
					level: 1,
					name: "label(Master Data)",
					icon: "master-data",
					navigation: "prop(saftLtGenericMenuMasterData)",
				},
				{
					level: 1,
					name: "label(TransactionsMenu)",
					icon: "transactions",
					navigation: "prop(saftLtGenericMenuTransactionalData)",
				},
			],
			routing: [
				masterDataByFile(
					"/:locale(pt|en|lt|es)?/:projectId/:fiscalYear([0-9]{4}|current)/:importId/:modulePrefix(saft_lt)/master_data/:entity?",
					navigation,
					true,
					"label(Master Data)",
					true
				),
				entityByFile(
					"/:locale(pt|en|lt|es)?/:projectId/:fiscalYear([0-9]{4}|current)/:importId/:modulePrefix(saft_lt)/master_data/:entities*",
					navigation,
					true,
					"label(Master Data)",
					true
				),
				transactionsByFile(
					"/:locale(pt|en|lt|es)?/:projectId/:fiscalYear([0-9]{4}|current)/:importId/:modulePrefix(saft_lt)/:entity",
					navigation,
					true,
					"label(TransactionsMenu)",
					true
				),
				entityByFile(
					"/:locale(pt|en|lt|es)?/:projectId/:fiscalYear([0-9]{4}|current)/:importId/:modulePrefix(saft_lt)/:entities*",
					navigation,
					true,
					"label(TransactionsMenu)",
					true
				),
			],
			queries: [
				{
					resource: "generic_menu_master_data",
					subEntity: "master_data",
					args: {
						projectId: "",
						fiscalYear: "",
						importId: "",
						genericPrefix: "saft_lt",
					},
					body: "entity, count",
				},
				{
					resource: "generic_menu_transactional_data",
					args: {
						projectId: "",
						fiscalYear: "",
						importId: "",
						genericPrefix: "saft_lt",
					},
					body: "entity, count",
				},
			],
		},
	],
	saftlt: [
		{
			level: 0,
			priority: 1,
			name: "label(Overview)",
			to: "/:projectId/:fiscalYear([0-9]{4})/:importId(full)/saftlt",
			exact: true,
			icon: "dashboard",
			routing: [
				legacyOverviewSaftLt(
					"/:locale(pt|en|lt|es)?/:projectId/:fiscalYear([0-9]{4}|current)?/:importId(full)/:modulePrefix(saftlt)?/",
					navigation,
					true,
					"label(Overview)"
				),
			],
			queries: [
				{
					resource: "audit_summary",
					args: {
						projectId: "",
						fiscalYear: "",
						modulePrefix: "saftlt",
						importId: "full",
					},
					body: "severity, count",
				},
			],
		},
		{
			level: 0,
			priority: 2,
			name: "label(Audit)",
			icon: "audit-icon",
			badge: "number(prop(auditSummaryTotal))",
			isToBuildMenu: true,
			navigation: [
				{
					name: [
						"label(Results)",
						" (",
						"number(prop(auditSummaryTotal))",
						")",
					],
					to: "/:projectId/:fiscalYear([0-9]{4})/:importId(full)/saftlt/audit",
				},
				{
					name: "label(Execution Summary)",
					to: "/:projectId/:fiscalYear([0-9]{4})/:importId(full)/saftlt/audit_execution_summary",
				},
			],
			routing: [
				audit(
					"/:locale(pt|en|lt|es)?/:projectId/:fiscalYear([0-9]{4}|current)/:importId(full)/:modulePrefix(saftlt)/:entity(audit|audit_schema)",
					navigation,
					true,
					"label(Audit)"
				),
				findingRule(
					"/:locale(pt|en|lt|es)?/:projectId/:fiscalYear([0-9]{4}|current)/:importId(full)/:modulePrefix(saftlt)/audit/rules/:id",
					navigation,
					true,
					"label(Rules)"
				),
				finding(
					"/:locale(pt|en|lt|es)?/:projectId/:fiscalYear([0-9]{4}|current)/:importId(full)/:modulePrefix(saftlt)/audit/rules/:entityId/finding/:id",
					navigation,
					true,
					"label(Finding)"
				),
				auditSummary(
					"/:locale(pt|en|lt|es)?/:projectId/:fiscalYear([0-9]{4}|current)/:importId(full)/:modulePrefix(saftlt)/audit_execution_summary",
					navigation,
					true,
					"label(Execution Summary)"
				),
			],
			queries: [
				{
					resource: "audit_summary_total",
					args: {
						projectId: "",
						fiscalYear: "",
						modulePrefix: "saftlt",
						importId: "full",
					},
					body: "",
				},
			],
		},
		{
			name: "label(SAFT-LT)",
			icon: "xml-file",
			priority: 4,
			level: 0,
			isToBuildMenu: true,
			navigation: [
				{
					level: 1,
					name: "label(Master Data)",
					icon: "master-data",
					navigation: "prop(saftltMasterData)",
				},
				{
					level: 1,
					name: "label(TransactionsMenu)",
					icon: "transactions",
					navigation: "prop(saftltTransactionalData)",
				},
			],
			routing: [
				legacyMasterData(
					"/:locale(pt|en|lt|es)?/:projectId/:fiscalYear([0-9]{4}|current)/:importId(full)/:modulePrefix(saftlt)/master_data/:entity?",
					navigation,
					true,
					"label(Master Data)"
				),
				legacyEntity(
					"/:locale(pt|en|lt|es)?/:projectId/:fiscalYear([0-9]{4}|current)/:importId(full)/:modulePrefix(saftlt)/master_data/:entities*",
					navigation,
					true,
					"label(Master Data)"
				),
				legacyTransactions(
					"/:locale(pt|en|lt|es)?/:projectId/:fiscalYear([0-9]{4}|current)/:importId(full)/:modulePrefix(saftlt)/:entity",
					navigation,
					true,
					"label(TransactionsMenu)"
				),
				legacyEntity(
					"/:locale(pt|en|lt|es)?/:projectId/:fiscalYear([0-9]{4}|current)/:importId(full)/:modulePrefix(saftlt)/:entities*",
					navigation,
					true,
					"label(TransactionsMenu)"
				),
			],
			queries: [
				{
					resource: "saftlt_master_data",
					subEntity: "master_data",
					args: {
						projectId: "",
						fiscalYear: "",
						modulePrefixSaftlt: "saftlt",
						importId: "full",
					},
					body: "entity, count",
				},
				{
					resource: "saftlt_transactional_data",
					args: {
						projectId: "",
						fiscalYear: "",
						modulePrefixSaftlt: "saftlt",
						importId: "full",
					},
					body: "entity, count",
				},
			],
		},
	],
	lt_erp: [
		{
			level: 0,
			priority: 1,
			name: "label(Overview ERP)",
			to: "/:projectId/:fiscalYear([0-9]{4})/:importId([0-9]*|full)/lt_erp",
			exact: true,
			icon: "dashboard",
			routing: [
				overviewErp(
					"/:locale(pt|en|lt|es)?/:projectId/:fiscalYear([0-9]{4}|current)?/:importId([0-9]*|full)?/:modulePrefix(lt_erp)?/",
					navigation,
					true,
					"label(Overview)",
					true
				),
			],
			queries: [],
		},
		{
			name: "label(LT-ERP)",
			icon: "reports",
			priority: 5,
			level: 0,
			isToBuildMenu: true,
			navigation: [
				{
					level: 1,
					name: "label(Master Data)",
					icon: "master-data",
					navigation: "prop(ltErpMasterData)",
				},
				{
					level: 1,
					name: "label(TransactionsMenu)",
					icon: "transactions",
					navigation: "prop(ltErpTransactionalData)",
				},
			],
			routing: [
				legacyMasterData(
					"/:locale(pt|en|lt|es)?/:projectId/:fiscalYear([0-9]{4}|current)/:importId/:modulePrefix(lt_erp)/master_data/:entity?",
					navigation,
					true,
					"label(Master Data)",
					true
				),
				legacyEntity(
					"/:locale(pt|en|lt|es)?/:projectId/:fiscalYear([0-9]{4}|current)/:importId/:modulePrefix(lt_erp)/master_data/:entities*",
					navigation,
					true,
					"label(Master Data)",
					true
				),
				legacyTransactions(
					"/:locale(pt|en|lt|es)?/:projectId/:fiscalYear([0-9]{4}|current)/:importId/:modulePrefix(lt_erp)/:entity",
					navigation,
					true,
					"label(TransactionsMenu)",
					true
				),
				legacyEntity(
					"/:locale(pt|en|lt|es)?/:projectId/:fiscalYear([0-9]{4}|current)/:importId/:modulePrefix(lt_erp)/:entities*",
					navigation,
					true,
					"label(TransactionsMenu)",
					true
				),
			],
			queries: [
				{
					resource: "lt_erp_master_data",
					subEntity: "master_data",
					args: {
						projectId: "",
						fiscalYear: "",
						modulePrefixLtErp: "lt_erp",
						importId: "full",
					},
					body: "entity, count",
				},
				{
					resource: "lt_erp_transactional_data",
					args: {
						projectId: "",
						fiscalYear: "",
						modulePrefixLtErp: "lt_erp",
						importId: "full",
					},
					body: "entity, count",
				},
			],
		},
	],
	saft_pt: [
		{
			level: 0,
			priority: 1,
			name: "label(Overview)",
			to: "/:projectId/:fiscalYear([0-9]{4})/:importId([0-9]*|full)/saft_pt",
			exact: true,
			icon: "dashboard",
			routing: [
				genericOverview(
					"/:locale(pt|en|lt|es)?/:projectId/:fiscalYear([0-9]{4}|current)?/:importId([0-9]*|full)?/:modulePrefix(saft_pt)?/",
					navigation,
					true,
					"label(Overview)",
					true
				),
			],
			queries: [],
		},
		{
			level: 0,
			priority: 2,
			name: "label(Audit)",
			icon: "audit-icon",
			badge: "prop(saftPtNcAuditSummaryTotal)",
			isToBuildMenu: true,
			navigation : "prop(saftPtGenericMenuAudit)",
			routing: [
				auditByFile(
					"/:locale(pt|en|lt|es)?/:projectId/:fiscalYear([0-9]{4}|current)/:importId/:entity(nc_audit|nc_audit_schema)?",
					navigation,
					true,
					"label(Audit)",
					true
				),
				auditSummaryByFile('/:locale(pt|en|lt)?/:projectId/:fiscalYear([0-9]{4}|current)/:importId/nc_audit_execution_summary', navigation, true, 'label(Execution Summary)'),
				auditNoteByFile(
					"/:locale(pt|en|lt|es)?/:projectId/:fiscalYear([0-9]{4}|current)/:importId/audit_notes",
					navigation,
					true,
					"label(Audit Notes)",
					true
				),
				findingRuleByFile(
					"/:locale(pt|en|lt|es)?/:projectId/:fiscalYear([0-9]{4}|current)/:importId/:modulePrefix(saft_pt)?/audit/rules/:id",
					navigation,
					true,
					"label(Rules)",
					true
				),
			],
			queries: [
				{
					resource: "nc_audit_summary_total",
					args: {
						projectId: "",
						fiscalYear: "",
						genericPrefix: "saft_pt",
						importId: "",
					},
					body: "",
				},
				{
					resource: "generic_menu_audit",
					args: {
						projectId: "",
						fiscalYear: "",
						genericPrefix: "saft_pt",
						importId: "",
					},
					body: "entity, count, navigation, removeModule",
				},
			],
		},
		{
			name: "label(Reports)",
			icon: "reports",
			priority: 5,
			level: 0,
			isToBuildMenu: true,
			navigation: 'prop(saftPtGenericMenuReports)',
			routing: [
				genericReportsByFile(
					"/:locale(pt|en|lt|es)?/:projectId/:fiscalYear([0-9]{4}|current)/:importId/:modulePrefix(saft_pt)/reports/:entity?",
					navigation,
					true,
					"label(Reports)",
					true
				),
			],
			queries: [
				{
					resource: "generic_menu_reports",
					subEntity: "reports",
					args: {
						projectId: "",
						fiscalYear: "",
						importId: "",
						genericPrefix: "saft_pt",
					},
					body: "entity, count, navigation",
				}
			],
		},
		{
			name: "label(SAFT_PT)",
			icon: "reports",
			priority: 5,
			level: 0,
			isToBuildMenu: true,
			navigation: [
				{
					level: 1,
					name: "label(Master Data)",
					icon: "master-data",
					navigation: "prop(saftPtGenericMenuMasterData)",
				},
				{
					level: 1,
					name: "label(TransactionsMenu)",
					icon: "transactions",
					navigation: "prop(saftPtGenericMenuTransactionalData)",
				},
			],
			routing: [
				masterDataByFile(
					"/:locale(pt|en|lt|es)?/:projectId/:fiscalYear([0-9]{4}|current)/:importId/:modulePrefix(saft_pt)/master_data/:entity?",
					navigation,
					true,
					"label(Master Data)",
					true
				),
				entityByFile(
					"/:locale(pt|en|lt|es)?/:projectId/:fiscalYear([0-9]{4}|current)/:importId/:modulePrefix(saft_pt)/master_data/:entities*",
					navigation,
					true,
					"label(Master Data)",
					true
				),
				transactionsByFile(
					"/:locale(pt|en|lt|es)?/:projectId/:fiscalYear([0-9]{4}|current)/:importId/:modulePrefix(saft_pt)/:entity",
					navigation,
					true,
					"label(TransactionsMenu)",
					true
				),
				entityByFile(
					"/:locale(pt|en|lt|es)?/:projectId/:fiscalYear([0-9]{4}|current)/:importId/:modulePrefix(saft_pt)/:entities*",
					navigation,
					true,
					"label(TransactionsMenu)",
					true
				),
			],
			queries: [
				{
					resource: "generic_menu_master_data",
					subEntity: "master_data",
					args: {
						projectId: "",
						fiscalYear: "",
						importId: "",
						genericPrefix: "saft_pt",
					},
					body: "entity, count",
				},
				{
					resource: "generic_menu_transactional_data",
					args: {
						projectId: "",
						fiscalYear: "",
						importId: "",
						genericPrefix: "saft_pt",
					},
					body: "entity, count",
				},
			],
		},
	],
	csv_pt: [
		{
			name: 'label(CSV Data)',
			icon: 'reports',
			priority: 6,
			level: 0,
			isToBuildMenu: true,
			navigation: [
				{
					level: 1,
					name: 'label(Master Data)',
					icon: 'master-data',
					navigation: 'prop(csvPtGenericMenuMasterData)',
				},
				{
					level: 1,
					name: 'label(TransactionsMenu)',
					icon: 'transactions',
					navigation: 'prop(csvPtGenericMenuTransactionalData)'
				},
			],
			routing: [
				findingRuleByFile(
					"/:locale(pt|en|lt|es)?/:projectId/:fiscalYear([0-9]{4}|current)/:importId/:modulePrefix(csv_pt)/audit/rules/:id",
					navigation,
					true,
					"label(Rules)",
					true
				),
				masterDataByFile('/:locale(pt|en|lt)?/:projectId/:fiscalYear([0-9]{4}|current)/:importId([0-9]*|full)?/:modulePrefix(csv_pt)/master_data/:entity?', navigation, true, 'label(Master Data)', true),
				entityByFile('/:locale(pt|en|lt)?/:projectId/:fiscalYear([0-9]{4}|current)/:importId([0-9]*|full)?/:modulePrefix(csv_pt)/master_data/:entities*', navigation, true, 'label(Master Data)', true),
				transactionsByFile('/:locale(pt|en|lt)?/:projectId/:fiscalYear([0-9]{4}|current)/:importId([0-9]*|full)?/:modulePrefix(csv_pt)/:entity', navigation, true, 'label(TransactionsMenu)', true),
				entityByFile('/:locale(pt|en|lt)?/:projectId/:fiscalYear([0-9]{4}|current)/:importId([0-9]*|full)?/:modulePrefix(csv_pt)/:entities*', navigation, true, 'label(TransactionsMenu)', true),
			],
			queries: [
				{
					resource: "generic_menu_master_data",
					subEntity: "master_data",
					args: {
						projectId: '',
						fiscalYear: '',
						importId: 'full',
						genericPrefix: 'csv_pt',
					},
					body: "entity, count"
				},
				{
					resource: "generic_menu_transactional_data",
					args: {
						projectId: '',
						fiscalYear: '',
						importId: 'full',
						genericPrefix: 'csv_pt',
					},
					body: "entity, count"
				},
			]
		},
	],
	saftpt: [
		{
			level: 0,
			priority: 1,
			name: "label(Overview)",
			to: "/:projectId/:fiscalYear([0-9]{4})/:importId(full)/saftpt",
			exact: true,
			icon: "dashboard",
			routing: [
				legacyOverview(
					"/:locale(pt|en|lt|es)?/:projectId/:fiscalYear([0-9]{4}|current)?/:importId(full)/:modulePrefix(saftpt)?/",
					navigation,
					true,
					"label(Overview)"
				),
			],
			queries: [
				{
					resource: "audit_summary",
					args: {
						projectId: "",
						fiscalYear: "",
						modulePrefix: "saftpt",
						importId: "full",
					},
					body: "severity, count",
				},
			],
		},
		{
			level: 0,
			priority: 2,
			name: "label(Audit)",
			icon: "audit-icon",
			badge: "prop(auditSummaryTotal)",
			isToBuildMenu: true,
			navigation: [
				{
					name: ["label(Results)", " (", "prop(auditSummaryTotal)", ")"],
					to: "/:projectId/:fiscalYear([0-9]{4})/:importId(full)/saftpt/audit",
				},
				{
					name: "label(Execution Summary)",
					to: "/:projectId/:fiscalYear([0-9]{4})/:importId(full)/saftpt/audit_execution_summary",
				},
				{
					name: "label(taxonomy_anomaly)",
					to: `/:projectId/:fiscalYear([0-9]{4})/:importId(full)/saftpt/audit_taxonomy_anomaly`,
				},
			],
			routing: [
				audit(
					"/:locale(pt|en|lt|es)?/:projectId/:fiscalYear([0-9]{4}|current)/:importId(full)/:modulePrefix(saftpt)/:entity(audit|audit_schema)?",
					navigation,
					true,
					"label(Audit)"
				),
				findingRule(
					"/:locale(pt|en|lt|es)?/:projectId/:fiscalYear([0-9]{4}|current)/:importId(full)/:modulePrefix(saftpt)/audit/rules/:id",
					navigation,
					true,
					"label(Rules)"
				),
				finding(
					"/:locale(pt|en|lt|es)?/:projectId/:fiscalYear([0-9]{4}|current)/:importId(full)/:modulePrefix(saftpt)/audit/rules/:entityId/finding/:id",
					navigation,
					true,
					"label(Finding)"
				),
				auditSummary(
					"/:locale(pt|en|lt|es)?/:projectId/:fiscalYear([0-9]{4}|current)/:importId(full)/:modulePrefix(saftpt)/audit_execution_summary",
					navigation,
					true,
					"label(Execution Summary)"
				),
			],
			queries: [
				{
					resource: "audit_summary_total",
					args: {
						projectId: "",
						fiscalYear: "",
						modulePrefix: "saftpt",
						importId: "full",
					},
					body: "",
				},
			],
		},
		{
			priority: 2,
			name: "label(Reports)",
			icon: "reports",
			level: 0,
			navigation: [
				{
					name: "label(Financial Reports)",
					exact: false,
					icon: "invoices",
					level: 1,
					navigation: [
						{
							name: "label(balance_taxonomy_annual)",
							to: `/:projectId/:fiscalYear([0-9]{4})/:importId(full)/saftpt/reports/balance_taxonomy_annual`,
							level: 2,
						},
						{
							name: "label(profit_and_loss_taxonomy_annual)",
							to: `/:projectId/:fiscalYear([0-9]{4})/:importId(full)/saftpt/reports/profit_and_loss_taxonomy_annual`,
							level: 2,
						},
						{
							name: "label(Trial Balance)",
							to: "/:projectId/:fiscalYear([0-9]{4})/:importId(full)/saftpt/reports/trial_balance",
							level: 2,
						},
						{
							name: "label(trial_balance_annual)",
							to: "/:projectId/:fiscalYear([0-9]{4})/:importId(full)/saftpt/reports/trial_balance_annual",
							level: 2,
						},
						{
							name: "label(trial_balance_taxonomy_current_year)",
							to: "/:projectId/:fiscalYear([0-9]{4})/:importId(full)/saftpt/reports/trial_balance_taxonomy_current_year",
							level: 2,
						},
						{
							name: "label(trial_balance_taxonomy_annual)",
							to: "/:projectId/:fiscalYear([0-9]{4})/:importId(full)/saftpt/reports/trial_balance_taxonomy_annual",
							level: 2,
						},
						{
							name: "label(statement_accounts_accumulated)",
							to: `/:projectId/:fiscalYear([0-9]{4})/:importId(full)/saftpt/reports/statement_accounts_accumulated`,
							level: 2,
						},
						{
							name: "label(offsetting_account)",
							to: `/:projectId/:fiscalYear([0-9]{4})/:importId(full)/saftpt/reports/offsetting_account`,
							level: 2,
						},
					],
				},
				{
					name: "label(Sales Reports)",
					exact: false,
					icon: "reports",
					level: 1,
					navigation: [
						{
							name: "label(customers_report)",
							to: `/:projectId/:fiscalYear([0-9]{4})/:importId(full)/saftpt/accounting_reports/customer_sales_report`,
							level: 2,
						},
						{
							name: "label(products_report)",
							to: `/:projectId/:fiscalYear([0-9]{4})/:importId(full)/saftpt/accounting_reports/product_sales_report`,
							level: 2,
						},
						{
							name: "label(products_by_country_report)",
							to: `/:projectId/:fiscalYear([0-9]{4})/:importId(full)/saftpt/accounting_reports/sales_by_country_report`,
							level: 2,
						},
					],
				},
			],
			routing: [
				report(
					"/:locale(pt|en|lt|es)?/:projectId/:fiscalYear([0-9]{4}|current)/:importId(full)/:modulePrefix(saftpt)/reports/:id",
					navigation,
					true,
					"label(Reports)"
				),
				report(
					"/:locale(pt|en|lt|es)?/:projectId/:fiscalYear([0-9]{4}|current)/:importId(full)/:modulePrefix(saftpt)/audit_:id(taxonomy_anomaly)",
					navigation,
					true,
					"label(Audit)"
				),
				report(
					"/:locale(pt|en|lt|es)?/:projectId/:fiscalYear([0-9]{4}|current)/:importId(full)/:modulePrefix(saftpt)/accounting_reports/:id",
					navigation,
					true,
					"label(Sales Reports)"
				),
			],
		},
		{
			name: "label(SAF-T)",
			icon: "xml-file",
			priority: 5,
			level: 0,
			isToBuildMenu: true,
			navigation: [
				{
					level: 1,
					name: "label(Master Data)",
					icon: "master-data",
					navigation: "prop(masterData)",
				},
				{
					level: 1,
					name: "label(TransactionsMenu)",
					icon: "transactions",
					navigation: "prop(transactionalData)",
				},
			],
			routing: [
				legacyMasterData(
					"/:locale(pt|en|lt|es)?/:projectId/:fiscalYear([0-9]{4}|current)/:importId(full)/:modulePrefix(saftpt)/master_data/:entity?",
					navigation,
					true,
					"label(Master Data)"
				),
				legacyEntity(
					"/:locale(pt|en|lt|es)?/:projectId/:fiscalYear([0-9]{4}|current)/:importId(full)/:modulePrefix(saftpt)/master_data/:entities*",
					navigation,
					true,
					"label(Master Data)"
				),
				legacyTransactions(
					"/:locale(pt|en|lt|es)?/:projectId/:fiscalYear([0-9]{4}|current)/:importId(full)/:modulePrefix(saftpt)/:entity",
					navigation,
					true,
					"label(TransactionsMenu)"
				),
				legacyEntity(
					"/:locale(pt|en|lt|es)?/:projectId/:fiscalYear([0-9]{4}|current)/:importId(full)/:modulePrefix(saftpt)/:entities*",
					navigation,
					true,
					"label(TransactionsMenu)"
				),
			],
			queries: [
				{
					resource: "master_data",
					subEntity: "master_data",
					args: {
						projectId: "",
						fiscalYear: "",
						modulePrefixSaftpt: "saftpt",
						importId: "full",
					},
					body: "entity, count",
				},
				{
					resource: "transactional_data",
					args: {
						projectId: "",
						fiscalYear: "",
						modulePrefixSaftpt: "saftpt",
						importId: "full",
					},
					body: "entity, count",
				},
			],
		},
	],
	pt_erp: [
		{
			name: "label(PT-ERP)",
			icon: "reports",
			priority: 5,
			level: 0,
			isToBuildMenu: true,
			navigation: [
				{
					level: 1,
					name: "label(Master Data)",
					icon: "master-data",
					navigation: "prop(ptErpMasterData)",
				},
				{
					level: 1,
					name: "label(TransactionsMenu)",
					icon: "transactions",
					navigation: "prop(ptErpTransactionalData)",
				},
			],
			routing: [
				legacyMasterData(
					"/:locale(pt|en)?/:projectId/:fiscalYear([0-9]{4}|current)/:importId(full)/:modulePrefix(pt_erp)/master_data/:entity?",
					navigation,
					true,
					"label(Master Data)"
				),
				legacyEntity(
					"/:locale(pt|en)?/:projectId/:fiscalYear([0-9]{4}|current)/:importId(full)/:modulePrefix(pt_erp)/master_data/:entities*",
					navigation,
					true,
					"label(Master Data)"
				),
				legacyTransactions(
					"/:locale(pt|en)?/:projectId/:fiscalYear([0-9]{4}|current)/:importId(full)/:modulePrefix(pt_erp)/:entity",
					navigation,
					true,
					"label(TransactionsMenu)"
				),
				legacyEntity(
					"/:locale(pt|en)?/:projectId/:fiscalYear([0-9]{4}|current)/:importId(full)/:modulePrefix(pt_erp)/:entities*",
					navigation,
					true,
					"label(TransactionsMenu)"
				),
			],
			queries: [
				{
					resource: "pt_erp_master_data",
					subEntity: "master_data",
					args: {
						projectId: "",
						fiscalYear: "",
						modulePrefixPtErp: "pt_erp",
						importId: "full",
					},
					body: "entity, count",
				},
				{
					resource: "pt_erp_transactional_data",
					subEntity: "",
					args: {
						projectId: "",
						fiscalYear: "",
						modulePrefixPtErp: "pt_erp",
						importId: "full",
					},
					body: "entity, count",
				},
			],
		},
	],
	lt_vat_report: [
		{
			priority: 3,
			name: "label(Reports)",
			icon: "reports",
			level: 0,
			navigation: [
				{
					name: "label(VAT Report Invoices)",
					to: `/:projectId/:fiscalYear([0-9]{4})/:importId(full)/reports/lt_vat_report_invoices`,
					level: 1,
					icon: "invoices",
				},
				{
					name: "label(VAT Report Purchase Invoices)",
					to: `/:projectId/:fiscalYear([0-9]{4})/:importId(full)/reports/lt_vat_report_purchase_invoices`,
					level: 1,
					icon: "invoices",
				},
			],
			routing: [
				report(
					"/:locale(pt|en|lt|es)?/:projectId/:fiscalYear([0-9]{4}|current)/:importId(full)/reports/:id",
					navigation,
					true,
					"label(Reports)"
				),
			],
		},
	],
	i_saf_lt: [
		{
			name: "label(I-SAF)",
			icon: "reports",
			priority: 6,
			level: 0,
			isToBuildMenu: true,
			navigation: [
				{
					level: 1,
					name: "label(Isaf Master Data)",
					icon: "master-data",
					navigation: "prop(iSafLtMasterData)",
				},
				{
					level: 1,
					name: "label(Isaf Transactions)",
					icon: "transactions",
					navigation: "prop(iSafLtTransactionalData)",
				},
			],
			routing: [
				legacyMasterData(
					"/:locale(pt|en|lt|es)?/:projectId/:fiscalYear([0-9]{4}|current)/:importId(full)/:modulePrefix(i_saf_lt)/master_data/:entity?",
					navigation,
					true,
					"label(Isaf Master Data)"
				),
				legacyEntity(
					"/:locale(pt|en|lt|es)?/:projectId/:fiscalYear([0-9]{4}|current)/:importId(full)/:modulePrefix(i_saf_lt)/master_data/:entities*",
					navigation,
					true,
					"label(Isaf Master Data)"
				),
				isafTransactions(
					"/:locale(pt|en|lt|es)?/:projectId/:fiscalYear([0-9]{4}|current)/:importId(full)/:modulePrefix(i_saf_lt)/:entity",
					navigation,
					true,
					"label(Transactions)"
				),
				legacyEntity(
					"/:locale(pt|en|lt|es)?/:projectId/:fiscalYear([0-9]{4}|current)/:importId(full)/:modulePrefix(i_saf_lt)/:entities*",
					navigation,
					true,
					"label(Transactions)"
				),
			],
			queries: [
				{
					resource: "i_saf_lt_master_data",
					subEntity: "master_data",
					args: {
						projectId: "",
						fiscalYear: "",
						modulePrefixISafLt: "i_saf_lt",
						importId: "full",
					},
					body: "entity, count",
				},
				{
					resource: "i_saf_lt_transactional_data",
					args: {
						projectId: "",
						fiscalYear: "",
						modulePrefixISafLt: "i_saf_lt",
						importId: "full",
					},
					body: "entity, count",
				},
			],
		},
	],
	saft_ao: [
		{
			level: 0,
			priority: 1,
			name: "label(Overview)",
			to: "/:projectId/:fiscalYear([0-9]{4})/:importId([0-9]*|full)/saft_ao",
			exact: true,
			icon: "dashboard",
			routing: [
				legacyGenericOverview(
					"/:locale(pt|en|lt|es)?/:projectId/:fiscalYear([0-9]{4}|current)?/:importId([0-9]*|full)?/:modulePrefix(saft_ao)?/",
					navigation,
					true,
					"label(Overview)",
					false
				),
			],
			queries: [],
		},
		{
			level: 0,
			priority: 2,
			name: "label(Audit)",
			icon: "audit-icon",
			badge: "prop(auditSummaryTotal)",
			isToBuildMenu: true,
			navigation: [
				{
					name: ["label(Results)", " (", "prop(auditSummaryTotal)", ")"],
					to: "/:projectId/:fiscalYear([0-9]{4})/:importId(full)/saft_ao/audit",
				},
				{
					name: "label(Execution Summary)",
					to: "/:projectId/:fiscalYear([0-9]{4})/:importId(full)/saft_ao/audit_execution_summary",
				},
			],
			routing: [
				audit(
					"/:locale(pt|en|lt|es)?/:projectId/:fiscalYear([0-9]{4}|current)/:importId(full)/:modulePrefix(saft_ao)/:entity(audit|audit_schema)",
					navigation,
					true,
					"label(Audit)"
				),
				findingRule(
					"/:locale(pt|en|lt|es)?/:projectId/:fiscalYear([0-9]{4}|current)/:importId(full)/:modulePrefix(saft_ao)/audit/rules/:id",
					navigation,
					true,
					"label(Rules)"
				),
				finding(
					"/:locale(pt|en|lt|es)?/:projectId/:fiscalYear([0-9]{4}|current)/:importId(full)/:modulePrefix(saft_ao)/audit/rules/:entityId/finding/:id",
					navigation,
					true,
					"label(Finding)"
				),
				auditSummary(
					"/:locale(pt|en|lt|es)?/:projectId/:fiscalYear([0-9]{4}|current)/:importId(full)/:modulePrefix(saft_ao)/audit_execution_summary",
					navigation,
					true,
					"label(Execution Summary)"
				),
			],
			queries: [
				{
					resource: "audit_summary_total",
					args: {
						projectId: "",
						fiscalYear: "",
						modulePrefix: "saft_ao",
						importId: "full",
					},
					body: "",
				},
			],
		},
		{
			priority: 2,
			name: "label(Reports)",
			icon: "reports",
			level: 0,
			navigation: [
				{
					name: "label(Financial Reports)",
					exact: false,
					icon: "invoices",
					level: 1,
					navigation: [
						{
							name: "label(Trial Balance)",
							to: "/:projectId/:fiscalYear([0-9]{4})/:importId/saft_ao/reports/trial_balance",
							level: 2,
						},
						{
							name: "label(trial_balance_annual)",
							to: "/:projectId/:fiscalYear([0-9]{4})/:importId/saft_ao/reports/trial_balance_annual",
							level: 2,
						},
						{
							name: "label(statement_accounts_accumulated)",
							to: `/:projectId/:fiscalYear([0-9]{4})/:importId/saft_ao/reports/statement_accounts_accumulated`,
							level: 2,
						},
						{
							name: "label(offsetting_account)",
							to: `/:projectId/:fiscalYear([0-9]{4})/:importId/saft_ao/reports/offsetting_account`,
							level: 2,
						},
					],
				},
				{
					name: "label(Sales Reports)",
					exact: false,
					icon: "reports",
					level: 1,
					navigation: [
						{
							name: "label(customers_report)",
							to: `/:projectId/:fiscalYear([0-9]{4})/:importId/saft_ao/accounting_reports/customer_sales_report`,
							level: 2,
						},
						{
							name: "label(products_report)",
							to: `/:projectId/:fiscalYear([0-9]{4})/:importId/saft_ao/accounting_reports/product_sales_report`,
							level: 2,
						},
						{
							name: "label(products_by_country_report)",
							to: `/:projectId/:fiscalYear([0-9]{4})/:importId/saft_ao/accounting_reports/sales_by_country_report`,
							level: 2,
						},
					],
				},
			],
			routing: [
				reportSaftAo(
					"/:locale(pt|en|lt|es)?/:projectId/:fiscalYear([0-9]{4}|current)/:importId(full)/:modulePrefix(saft_ao)/reports/:id",
					navigation,
					true,
					"label(Reports)"
				),
				reportSaftAo(
					"/:locale(pt|en|lt|es)?/:projectId/:fiscalYear([0-9]{4}|current)/:importId(full)/:modulePrefix(saft_ao)/accounting_reports/:id",
					navigation,
					true,
					"label(Sales Reports)"
				),
			],
		},
		{
			name: "label(SAFT_AO)",
			icon: "reports",
			priority: 5,
			level: 0,
			isToBuildMenu: true,
			navigation: [
				{
					level: 1,
					name: "label(Master Data)",
					icon: "master-data",
					navigation: "prop(saftAoGenericMenuMasterData)",
				},
				{
					level: 1,
					name: "label(TransactionsMenu)",
					icon: "transactions",
					navigation: "prop(saftAoGenericMenuTransactionalData)",
				},
			],
			routing: [
				masterDataByFile(
					"/:locale(pt|en|lt|es)?/:projectId/:fiscalYear([0-9]{4}|current)/:importId/:modulePrefix(saft_ao)/master_data/:entity?",
					navigation,
					true,
					"label(Master Data)",
					false
				),
				detailEntity(
					"/:locale(pt|en|lt|es)?/:projectId/:fiscalYear([0-9]{4}|current)/:importId/:modulePrefix(saft_ao)/master_data/:entities*",
					navigation,
					true,
					"label(Master Data)",
					false
				),
				transactionsByFile(
					"/:locale(pt|en|lt|es)?/:projectId/:fiscalYear([0-9]{4}|current)/:importId/:modulePrefix(saft_ao)/:entity",
					navigation,
					true,
					"label(TransactionsMenu)",
					false
				),
				detailEntity(
					"/:locale(pt|en|lt|es)?/:projectId/:fiscalYear([0-9]{4}|current)/:importId/:modulePrefix(saft_ao)/:entities*",
					navigation,
					true,
					"label(TransactionsMenu)",
					false
				),
			],
			queries: [
				{
					resource: "generic_menu_master_data",
					subEntity: "master_data",
					args: {
						projectId: "",
						fiscalYear: "",
						importId: "",
						genericPrefix: "saft_ao",
					},
					body: "entity, count",
				},
				{
					resource: "generic_menu_transactional_data",
					args: {
						projectId: "",
						fiscalYear: "",
						importId: "",
						genericPrefix: "saft_ao",
					},
					body: "entity, count",
				},
			],
		},
	],
	pt_mod_22: [
		{
			level: 0,
			priority: 5,
			name: "label(Model 22)",
			to: "/:projectId/:fiscalYear([0-9]{4})/:importId(full)/pt_mod_22",
			exact: true,
			icon: "work-documents",
			navigation: [
				{
					name: ["label(Cover Page)"],
					to: "/:projectId/:fiscalYear([0-9]{4})/:importId(full)/pt_mod_22/cover_page",
				},
				{
					name: "label(Annex A)",
					to: "/:projectId/:fiscalYear([0-9]{4})/:importId(full)/pt_mod_22/annex_a",
				},
				{
					name: "label(Annex C)",
					to: "/:projectId/:fiscalYear([0-9]{4})/:importId(full)/pt_mod_22/annex_c",
				},
				{
					name: "label(Annex D)",
					to: "/:projectId/:fiscalYear([0-9]{4})/:importId(full)/pt_mod_22/annex_d",
				},
			],
			routing: [
				statement(
					"/:locale(pt|en|lt|es)?/:projectId/:fiscalYear([0-9]{4}|current)/:importId(full)/:modulePrefix(pt_mod_22)/:entity(cover_page)/:subEntity?",
					navigation,
					true,
					"label(Cover Page)"
				),
				statement(
					"/:locale(pt|en|lt|es)?/:projectId/:fiscalYear([0-9]{4}|current)/:importId(full)/:modulePrefix(pt_mod_22)/:entity(annex_a)/:subEntity?",
					navigation,
					true,
					"label(Annex A)"
				),
				statement(
					"/:locale(pt|en|lt|es)?/:projectId/:fiscalYear([0-9]{4}|current)/:importId(full)/:modulePrefix(pt_mod_22)/:entity(annex_c)/:subEntity?",
					navigation,
					true,
					"label(Annex C)"
				),
				statement(
					"/:locale(pt|en|lt|es)?/:projectId/:fiscalYear([0-9]{4}|current)/:importId(full)/:modulePrefix(pt_mod_22)/:entity(annex_d)/:subEntity?",
					navigation,
					true,
					"label(Annex D)"
				)
			],
		},
	],
	pt_mod_30: [
		{
			level: 0,
			priority: 6,
			name: "label(Model 30)",
			to: "/:projectId/:fiscalYear([0-9]{4})/:importId(full)/pt_mod_30/file",
			exact: true,
			icon: "work-documents",
			routing: [
				statement(
					"/:locale(pt|en|lt|es)?/:projectId/:fiscalYear([0-9]{4}|current)/:importId(full)/:modulePrefix(pt_mod_30)/:entity(file)/:subEntity?",
					navigation,
					true,
					"label(Header)"
				),
			],
		},
	],
	saft_cv: [
		{
			level: 0,
			priority: 1,
			name: "label(Overview)",
			to: "/:projectId/:fiscalYear([0-9]{4})/:importId([0-9]*|full)/saft_cv",
			exact: true,
			icon: "dashboard",
			routing: [
				genericOverview(
					"/:locale(pt|en|lt|es)?/:projectId/:fiscalYear([0-9]{4}|current)?/:importId([0-9]*|full)?/:modulePrefix(saft_cv)?/",
					navigation,
					true,
					"label(Overview)",
					true
				),
			],
			queries: [],
		},
		{
			level: 0,
			priority: 2,
			name: "label(Audit)",
			icon: "audit-icon",
			badge: "prop(saftCvNcAuditSummaryTotal)",
			isToBuildMenu: true,
			navigation: [
				{
					name: [
						"label(Results)",
						" (",
						"prop(saftCvNcAuditSummaryTotal)",
						")",
					],
					to: "/:projectId/:fiscalYear([0-9]{4})/:importId/nc_audit",
				},
				{
					name: 'label(Execution Summary)',
					to: '/:projectId/:fiscalYear([0-9]{4})/:importId/nc_audit_execution_summary'
				},
				{
					name: "label(Audit Notes)",
					to: "/:projectId/:fiscalYear([0-9]{4})/:importId/audit_notes",
				},
			],
			routing: [
				auditByFile(
					"/:locale(pt|en|lt|es)?/:projectId/:fiscalYear([0-9]{4}|current)/:importId/:entity(nc_audit|nc_audit_schema)?",
					navigation,
					true,
					"label(Audit)",
					true
				),
				auditSummaryByFile('/:locale(pt|en|lt)?/:projectId/:fiscalYear([0-9]{4}|current)/:importId/nc_audit_execution_summary', navigation, true, 'label(Execution Summary)'),
				auditNoteByFile(
					"/:locale(pt|en|lt|es)?/:projectId/:fiscalYear([0-9]{4}|current)/:importId/audit_notes",
					navigation,
					true,
					"label(Audit Notes)",
					true
				),
				findingRuleByFile(
					"/:locale(pt|en|lt|es)?/:projectId/:fiscalYear([0-9]{4}|current)/:importId/:modulePrefix(saft_cv)?/audit/rules/:id",
					navigation,
					true,
					"label(Rules)",
					true
				),
			],
			queries: [
				{
					resource: "nc_audit_summary_total",
					args: {
						projectId: "",
						fiscalYear: "",
						genericPrefix: "saft_cv",
						importId: "",
					},
					body: "",
				},
			],
		},
		{
			priority: 2,
			name: "Reports",
			icon: "reports",
			level: 0,
			navigation: [
				{
					name: "label(Financial Reports)",
					exact: false,
					icon: "invoices",
					level: 1,
					navigation: [
                        {	name: 'label(Trial Balance)',
							to: '/:projectId/:fiscalYear([0-9]{4})/:importId/saft_cv/reports/nc_trial_balance',
							level: 2,
						},
						{
							name: "label(statement_accounts_accumulated)",
							to: "/:projectId/:fiscalYear([0-9]{4})/:importId/saft_cv/reports/nc_statement_accounts_accumulated",
							level: 2,
						},
						{
							name: "label(offsetting_account)",
							to: "/:projectId/:fiscalYear([0-9]{4})/:importId/saft_cv/reports/nc_offsetting_account",
							level: 2,
						},
					],
				},
			],
			routing: [
				reportByFile(
					"/:locale(pt|en|lt|es)?/:projectId/:fiscalYear([0-9]{4}|current)/:importId/:modulePrefix(saft_cv)/reports/:id",
					navigation,
					true,
					"label(Reports)",
					true
				),
			],
		},
		{
			name: "label(SAFT_CV)",
			icon: "reports",
			priority: 5,
			level: 0,
			isToBuildMenu: true,
			navigation: [
				{
					level: 1,
					name: "label(Master Data)",
					icon: "master-data",
					navigation: "prop(saftCvGenericMenuMasterData)",
				},
				{
					level: 1,
					name: "label(TransactionsMenu)",
					icon: "transactions",
					navigation: "prop(saftCvGenericMenuTransactionalData)",
				},
			],
			routing: [
				masterDataByFile(
					"/:locale(pt|en|lt|es)?/:projectId/:fiscalYear([0-9]{4}|current)/:importId/:modulePrefix(saft_cv)/master_data/:entity?",
					navigation,
					true,
					"label(Master Data)",
					true
				),
				entityByFile(
					"/:locale(pt|en|lt|es)?/:projectId/:fiscalYear([0-9]{4}|current)/:importId/:modulePrefix(saft_cv)/master_data/:entities*",
					navigation,
					true,
					"label(Master Data)",
					true
				),
				transactionsByFile(
					"/:locale(pt|en|lt|es)?/:projectId/:fiscalYear([0-9]{4}|current)/:importId/:modulePrefix(saft_cv)/:entity",
					navigation,
					true,
					"label(TransactionsMenu)",
					true
				),
				entityByFile(
					"/:locale(pt|en|lt|es)?/:projectId/:fiscalYear([0-9]{4}|current)/:importId/:modulePrefix(saft_cv)/:entities*",
					navigation,
					true,
					"label(TransactionsMenu)",
					true
				),
			],
			queries: [
				{
					resource: "generic_menu_master_data",
					subEntity: "master_data",
					args: {
						projectId: "",
						fiscalYear: "",
						importId: "",
						genericPrefix: "saft_cv",
					},
					body: "entity, count",
				},
				{
					resource: "generic_menu_transactional_data",
					args: {
						projectId: "",
						fiscalYear: "",
						importId: "",
						genericPrefix: "saft_cv",
					},
					body: "entity, count",
				},
			],
		},
	],
	saft_hu: [
		{
			level: 0,
			priority: 1,
			name: "label(Overview)",
			to: "/:projectId/:fiscalYear([0-9]{4})/:importId([0-9]*|full)/saft_hu",
			exact: true,
			icon: "dashboard",
			routing: [
				saftHuOverview(
					"/:locale(pt|en|lt|es)?/:projectId/:fiscalYear([0-9]{4}|current)?/:importId([0-9]*|full)?/:modulePrefix(saft_hu)?/",
					navigation,
					true,
					"label(Overview)",
					true
				),
			],
			queries: [],
		},
		{
			level: 0,
			priority: 2,
			name: "label(Audit)",
			icon: "audit-icon",
			badge: "prop(saftHuNcAuditSummaryTotal)",
			isToBuildMenu: true,
			navigation: [
				{
					name: [
						"label(Results)",
						" (",
						"prop(saftHuNcAuditSummaryTotal)",
						")",
					],
					to: "/:projectId/:fiscalYear([0-9]{4})/:importId/nc_audit",
				},
				{
					name: "label(Audit Notes)",
					to: "/:projectId/:fiscalYear([0-9]{4})/:importId/audit_notes",
				},
			],
			routing: [
				auditByFile(
					"/:locale(pt|en|lt|es)?/:projectId/:fiscalYear([0-9]{4}|current)/:importId/:entity(nc_audit|nc_audit_schema)?",
					navigation,
					true,
					"label(Audit)",
					true
				),
				auditNoteByFile(
					"/:locale(pt|en|lt|es)?/:projectId/:fiscalYear([0-9]{4}|current)/:importId/audit_notes",
					navigation,
					true,
					"label(Audit Notes)",
					true
				),
				findingRuleByFile(
					"/:locale(pt|en|lt|es)?/:projectId/:fiscalYear([0-9]{4}|current)/:importId/:modulePrefix(saft_hu)?/audit/rules/:id",
					navigation,
					true,
					"label(Rules)",
					true
				),
			],
			queries: [
				{
					resource: "nc_audit_summary_total",
					args: {
						projectId: "",
						fiscalYear: "",
						genericPrefix: "saft_hu",
						importId: "",
					},
					body: "",
				},
			],
		},
		{
			name: "label(SAFT-HU)",
			icon: "reports",
			priority: 5,
			level: 0,
			isToBuildMenu: true,
			navigation: [
				{
					level: 1,
					name: "label(Master Data)",
					icon: "master-data",
					navigation: "prop(saftHuGenericMenuMasterData)",
				},
				{
					level: 1,
					name: "label(TransactionsMenu)",
					icon: "transactions",
					navigation: "prop(saftHuGenericMenuTransactionalData)",
				},
			],
			routing: [
				masterDataByFile(
					"/:locale(pt|en|lt|es)?/:projectId/:fiscalYear([0-9]{4}|current)/:importId/:modulePrefix(saft_hu)/master_data/:entity?",
					navigation,
					true,
					"label(Master Data)",
					true
				),
				entityByFile(
					"/:locale(pt|en|lt|es)?/:projectId/:fiscalYear([0-9]{4}|current)/:importId/:modulePrefix(saft_hu)/master_data/:entities*",
					navigation,
					true,
					"label(Master Data)",
					true
				),
				transactionsByFile(
					"/:locale(pt|en|lt|es)?/:projectId/:fiscalYear([0-9]{4}|current)/:importId/:modulePrefix(saft_hu)/:entity",
					navigation,
					true,
					"label(TransactionsMenu)",
					true
				),
				entityByFile(
					"/:locale(pt|en|lt|es)?/:projectId/:fiscalYear([0-9]{4}|current)/:importId/:modulePrefix(saft_hu)/:entities*",
					navigation,
					true,
					"label(TransactionsMenu)",
					true
				),
			],
			queries: [
				{
					resource: "generic_menu_master_data",
					subEntity: "master_data",
					args: {
						projectId: "",
						fiscalYear: "",
						importId: "",
						genericPrefix: "saft_hu",
					},
					body: "entity, count",
				},
				{
					resource: "generic_menu_transactional_data",
					args: {
						projectId: "",
						fiscalYear: "",
						importId: "",
						genericPrefix: "saft_hu",
					},
					body: "entity, count",
				},
			],
		},
	],
	efatura: [
		{
			name: "label(E-Fatura)",
			icon: "transactions",
			priority: 7,
			level: 0,
			isToBuildMenu: true,
			navigation: [
				{
					level: 1,
					name: "label(efatura_purchases_vs_sales_invoices)",
					icon: "dashboard",
					to: "/:projectId/:fiscalYear([0-9]{4}|current)/:importId(full)/efatura/overview",
				},
				{
					level: 1,
					name: "label(Efatura Master Data)",
					icon: "master-data",
					navigation: "prop(efaturaMasterData)",
				},
				{
					level: 1,
					name: "label(Efatura Transactions)",
					icon: "transactions",
					navigation: "prop(efaturaTransactionalData)",
				},
			],
			routing: [
				efaturaAuditFindings(
					"/:locale(pt|en|lt|es)?/:projectId/audit_efatura_findings",
					navigation,
					true,
					"label(Findings)"
				),
				efaturaOverviewData(
					"/:locale(pt|en)?/:projectId/:fiscalYear([0-9]{4}|current)/:importId/:modulePrefix(efatura)/:entity(overview)?",
					navigation,
					true,
					"label(Efatura)"
				),
				efaturaMasterData(
					"/:locale(pt|en)?/:projectId/:fiscalYear([0-9]{4}|current)/:importId/:modulePrefix(efatura)/master_data/:entity?",
					navigation,
					true,
					"label(Efatura)"
				),
				legacyEntity(
					"/:locale(pt|en)?/:projectId/:fiscalYear([0-9]{4}|current)/:importId/:modulePrefix(efatura)/master_data/:entities*",
					navigation,
					true
				),
				efaturaTransactionalData(
					"/:locale(pt|en)?/:projectId/:fiscalYear([0-9]{4}|current)/:importId/:modulePrefix(efatura)/:entity?",
					navigation,
					true,
					"label(Efatura)"
				),
				legacyEntity(
					"/:locale(pt|en)?/:projectId/:fiscalYear([0-9]{4}|current)/:importId/:modulePrefix(efatura)/:entities*",
					navigation
				),
			],
			queries: [
				{
					resource: "efatura_transactional_data",
					args: {
						projectId: "",
						fiscalYear: "",
						modulePrefixEfatura: "efatura",
						importId: "full",
					},
					body: "entity, count",
				},
				{
					resource: "efatura_master_data",
					subEntity: "master_data",
					args: {
						projectId: "",
						fiscalYear: "",
						modulePrefixEfatura: "efatura",
						importId: "full",
					},
					body: "entity, count",
				},
			],
		},
	],
	in_einvoice: [
		{
			level: 0,
			priority: 1,
			name: "label(Dashboard E-Invoice)",
			to: "/:projectId/:fiscalYear([0-9]{4})/:importId([0-9]*|full)/in_einvoice",
			exact: true,
			icon: "dashboard",
			routing: [
				dashboardEInvoice(
					"/:locale(pt|en|lt|es)?/:projectId/:fiscalYear([0-9]{4}|current)?/:importId([0-9]*|full)?/:modulePrefix(in_einvoice)?/",
					navigation,
					true,
					"label(Dashboard)"
				),
			],
		},
		{
			name: "label(E-Invoices)",
			icon: "master-data",
			priority: 2,
			level: 0,
			isToBuildMenu: true,
			navigation: "prop(einvoiceData)",
			routing: [
				entitiesDataWithoutFindings(
					"/:locale(en|lt)?/:projectId/:fiscalYear([0-9]{4}|current)?/:importId([0-9]*|full)?/:modulePrefix(in_einvoice)/:entity?",
					navigation,
					true,
					["label(E-Invoices Data)", "label(param(entity))"]
				),
				entityWithoutFindings(
					"/:locale(en|lt)?/:projectId/:fiscalYear([0-9]{4}|current)?/:importId([0-9]*|full)?/:modulePrefix(in_einvoice)/:entities*",
					navigation,
					true,
					"label(E-Invoices Data)"
				),
			],
			queries: [
				{
					resource: "einvoice_data",
					args: {
						projectId: "",
						fiscalYear: "",
						importId: "",
						modulePrefixInEinvoice: "in_einvoice",
					},
					body: "entity, count",
				},
			],
		},
	],
	in_gstr: [
		{
			name: "label(GSTR1)",
			icon: "master-data",
			priority: 3,
			level: 0,
			isToBuildMenu: true,
			navigation: "prop(gstData)",
			routing: [
				gstReturnToSubmit(
					"/:locale(pt|en|lt|es)?/:projectId/:fiscalYear([0-9]{4}|current)/:importId([0-9]*|full)?/:modulePrefix(in_gstr)/:entity(gst_return_submit)",
					navigation,
					true
				),

				entitiesDataWithoutFindings(
					"/:locale(pt|en|lt|es)?/:projectId/:fiscalYear([0-9]{4}|current)?/:importId([0-9]*|full)?/:modulePrefix(in_gstr)/:entity?",
					navigation,
					true,
					["label(GST Returns)", "label(param(entity))"]
				),
				entityWithoutFindings(
					"/:locale(pt|en|lt|es)?/:projectId/:fiscalYear([0-9]{4}|current)?/:importId([0-9]*|full)?/:modulePrefix(in_gstr)/:entities*",
					navigation,
					true,
					"label(GST Data)"
				),
			],
			queries: [
				{
					resource: "gst_data",
					args: {
						projectId: "",
						fiscalYear: "",
						importId: "",
						modulePrefixInGstr: "in_gstr",
					},
					body: "entity, count",
				},
			],
		},
	],
	in_gstr2a: [
		{
			name: "label(GSTR2A)",
			icon: "reports",
			priority: 4,
			level: 0,
			isToBuildMenu: true,
			navigation: [
				{
					level: 1,
					name: "label(GSTR Purchase Invoices)",
					icon: "master-data",
					navigation: "prop(inGstr2aGenericMenuMasterData)",
				},
				{
					level: 1,
					name: "label(SAP Purchase Invoices)",
					icon: "master-data",
					navigation: "prop(gst2aData)",
				},
			],
			routing: [
				gstr2aRequestSubmit(
					"/:locale(pt|en|lt|es)?/:projectId/:fiscalYear([0-9]{4}|current)/:importId([0-9]*|full)?/:modulePrefix(in_gstr2a)/:entity(report)",
					navigation,
					true
				),
				masterDataByFile(
					"/:locale(pt|en|lt|es)?/:projectId/:fiscalYear([0-9]{4}|current)/:importId/:modulePrefix(in_gstr2a)/master_data/:entity?",
					navigation,
					true,
					"label(GST Purchase Invoices)",
					false
				),
				entitiesDataWithoutFindings(
					"/:locale(pt|en|lt|es)?/:projectId/:fiscalYear([0-9]{4}|current)?/:importId([0-9]*|full)?/:modulePrefix(in_gstr2a)/:entity?",
					navigation,
					true,
					["", "label(param(entity))"]
				),
				entityWithoutFindings(
					"/:locale(pt|en|lt|es)?/:projectId/:fiscalYear([0-9]{4}|current)?/:importId([0-9]*|full)?/:modulePrefix(in_gstr2a)/:entities*",
					navigation,
					true,
					"label(GST2A Data)"
				),
			],
			queries: [
				{
					resource: "generic_menu_master_data",
					subEntity: "master_data",
					args: {
						projectId: "",
						fiscalYear: "",
						importId: "",
						genericPrefix: "in_gstr2a",
					},
					body: "entity, count",
				},
				{
					resource: "gst2a_data",
					args: {
						projectId: "",
						fiscalYear: "",
						importId: "",
						modulePrefixInGstr2a: "in_gstr2a",
					},
					body: "entity, count",
				},
			],
		},
	],
	generator: [
		{
			level: 0,
			priority: 99,
			module: "generator",
			name: "label(i.SAF-T Generation)",
			to: "/:projectId/:fiscalYear([0-9]{4})/generation_repository",
			exact: true,
			icon: "repository",
			routing: [
				generationRepository(
					"/:locale(pt|en|lt|es)?/:projectId/:fiscalYear([0-9]{4}|current)/generation_repository/",
					navigation,
					true,
					"label(Generation Repository)"
				),
			],
			queries: [
				{
					resource: "generator_repository_status",
					args: {
						projectId: "",
						fiscalYear: "",
					},
					body: "status",
				},
			],
		},
	],
	'gen_pt': [
		{
			level: 0,
			priority: 99,
			module: 'generator',
			name: 'label(SAF-T PT Generation)',
			to: '/:projectId/:fiscalYear([0-9]{4})/full/generation_pt',
			exact: true,
			icon: 'repository',
			routing: [
				generationPt('/:locale(pt|en)?/:projectId/:fiscalYear([0-9]{4}|current)/:importId/generation_pt/', navigation, true, 'label(Generation)'),
			],
			queries: [
				{
					resource: "generator_repository_status",
					args: {
						projectId: '',
						fiscalYear: ''
					},
					body: "status"
				},
			]
		},
	],
	repository: [
		{
			level: 0,
			priority: 100,
			name: "label(Repository)",
			to: "/:projectId/repository/activity/status",
			exact: true,
			icon: "repository",
			routing: [
				repository(
					"/:locale(pt|en|lt|es|ro)?/:projectId/repository/:section(efatura_request)",
					true
				),
				repository(
					"/:locale(pt|en|lt|es|ro)?/:projectId/repository/:section(efatura_deleted_data)",
					false
				),
				fileSubmission(
					"/:locale(pt|en|lt|ro)?/:projectId/repository/:section(files_submission)"
				),
				repository(
					"/:locale(pt|en|lt|es|ro)?/:projectId/repository/:section?/:fiscalYear?/:fileType?",
					false
				),
				findingsSchemaWithoutFiscalYear('/:locale(pt|en|lt|es|ro)?/:projectId/:importId/:modulePrefix/:section(audit_findings)', defaultNavigation, true, 'label(Findings)'),
				finding(
					"/:locale(pt|en|lt|es|ro)?/:projectId/:importId/:modulePrefix/:section(audit_findings)/:id",
					defaultNavigation,
					true,
					"label(Finding)"
				),
				entityWithoutHeader(
					"/:locale(pt|en|lt|es|ro)?/:projectId/:entities(audit_efatura_findings)/:id",
					true
				),
			],
			queries: [
				{
					resource: "repository_status",
					args: {
						projectId: "",
						fiscalYear: "",
					},
					body: "status",
				},
			],
		},
	],
};

const filteredQueriesByModules = (modules) => {
	if (modules && modules.length > 0) {
		const filteredRoutes = (modules || []).reduce((acc, elem) => {
			const menuArray = menuRouting[`${elem}`];
			const queries = (menuArray || [])
				.filter((elem) => elem.queries !== undefined)
				.map(({ queries }) => {
					return queries;
				});
			acc.push(...queries);
			return acc;
		}, []);
		return filteredRoutes.flat();
	}
	return [];
};

const filteredQueriesByModulesForMenu = (modules) => {
	if (modules && modules.length > 0) {
		return (modules || []).reduce((acc, elem) => {
			const menuArray = menuRouting[`${elem}`];
			const queries = (menuArray || [])
				.filter((elem) => elem.isToBuildMenu && elem.queries !== undefined)
				.map(({ queries }) => {
					return queries;
				});

			acc.push({ [`${elem}`]: queries.flat() });
			return acc;
		}, []);
	}
	return [];
};

const filteredNavigationByModules = (modules) => {
	let filteredRoutes = [];
	if (modules && modules.length > 0) {
		const reorderedArray = reorderRouting(modules);
		iterateNavigation(reorderedArray, filteredRoutes);
	}
	return filteredRoutes;
};

const reorderRouting = (modules) => {
	if (modules && modules.length > 0) {
		const filteredRoutes = (modules || []).reduce((acc, elem) => {
			const menuArray = menuRouting[`${elem}`];
			if (menuArray) {
				acc.push(...menuArray);
			}
			return acc;
		}, []);
		return filteredRoutes.sort(function (a, b) {
			return a.priority - b.priority;
		});
	}
	return [];
};

const getFirstModuleOfArray = (modules) => {
	if (modules && modules.length > 0) {
		const firstModule = Object.keys(menuRouting).find(
			(element) => modules.indexOf(element) >= 0
		);
		return firstModule || "";
	}
	return [];
};

const filteredRoutesByModules = (modules) => {
	let filteredRoutes = [];
	if ((modules || []).length !== 0) {
		const reorderedArray = reorderRouting(modules);
		navigation.splice(0, navigation.length, ...constructMenu(reorderedArray)); //prevent navigation to duplicate entries
		iterateRouting(reorderedArray, filteredRoutes);
	}

	return filteredRoutes.flat();
};

const constructMenu = (reorderedArray) => {
	const menuToUse = [...JSON.parse(JSON.stringify(reorderedArray))]; //deep copy of array, to prevent any mutation on the original routing

	const navigationContent = menuToUse.map(
		({ routing, queries, ...restNavigation }) => {
			return restNavigation;
		}
	);

	return [...navigationContent, ...defaultNavigation];
};

const iterateNavigation = (navigation, arrayOfNavigations) => {
	arrayOfNavigations.push(...navigation);
};

const iterateRouting = (navigation, arrayOfNavigations) => {
	const mergeTo = navigation.map(({ routing, queries, ...restNavigation }) => {
		if (restNavigation && Array.isArray(restNavigation)) {
			iterateRouting(restNavigation, arrayOfNavigations);
		}
		return routing || [];
	});
	arrayOfNavigations.push(...(mergeTo.flat() || []));
};

export {
	menuRouting,
	filteredQueriesByModules,
	filteredRoutesByModules,
	filteredNavigationByModules,
	filteredQueriesByModulesForMenu,
	getFirstModuleOfArray,
};
