import { date } from '../../../utils'

const mapCard = (json) => {
	const {
        referenceNo,
        referenceDate,
	} = json

	return [
        [
            { labels: ['reference_no'], value: referenceNo, type: 'string' }
        ],
        [
            { labels: ['date'], value: date(referenceDate), type: 'date' }
        ]
	]
}

const mapper = (json, card) => {

	return {
        ...json,
        __typename: json.__typename,
        id: json.id,
		card: card ? mapCard(json) : []
	}
}

const header = [
    { label: ["Reference No"], align: 'left',columnName: "reference_no", sortable: true },
    { label: ["Reference Date"], align: 'center', columnName: "reference_date", sortable: true },
]

const referenceMapper =  {
	map: mapper,
	header
}

export default referenceMapper;