import React, { forwardRef, useRef, useImperativeHandle } from 'react'
import { Dropdown, MultiSelectionInput } from 'colbi_web_ui/lib/components/inputs';
import { useGlobalValue } from 'colbi_web_ui/lib/state/GlobalProvider';
import { FORM_ACTIONS } from '../FormActions';

import styles from './DeleteUserDataForm.module.sass';

const DeleteUserDataForm = (props, ref) => {
  const { data, status, efaturaprojectfiscalyears, onChange, onSave, efaturainvoicetypes } = props
  const formRef = useRef()
  const {
    deleteRequests = [],
    fiscalYearToRemove,
  } = data || {}

  const { i18n } = useGlobalValue()

  const handleChange = (e, prop, value) => {
    onChange && onChange({
      originalEvent: e,
      target: {
        value: {
          data: {
            ...data,
            [prop]: value
          }
        }
      }
    })
  }

  useImperativeHandle(ref, () => ({
    submit: () => {
      formRef.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))
    }
  }))

  return status !== 'loading' ? (
    <form ref={formRef} onSubmit={(e) => {
      e.preventDefault()
      e.stopPropagation()
      if (formRef.current.checkValidity()) {
        data['__typename'] = 'DeleteUserData'

        onSave && onSave();
      } else {
        formRef.current.reportValidity()
      }
    }}>
      <div className={styles['group-content']}>
        <div className={styles['input-field']}>
          <h5 className={styles['input']}>{i18n`Fiscal Year *`}</h5>
          <Dropdown
            value={fiscalYearToRemove && efaturaprojectfiscalyears ? efaturaprojectfiscalyears.find(({ name }) => name === fiscalYearToRemove) || efaturaprojectfiscalyears[0] : null}
            options={efaturaprojectfiscalyears || []}
            className={styles['dropdown']}
            placeholder={i18n`-- Select a fiscal year --`}
            required
            onChange={(e) => {
              handleChange(e, 'fiscalYearToRemove', e.target.value.name);
            }}
          />
        </div>
        <div className={styles['input-field']}>
          <MultiSelectionInput
            className={styles['input']}
            name={i18n`Efatura Request Type`}
            placeholder={i18n`-- Select an option --`}
            value={(typeof deleteRequests === 'string' ? JSON.parse(deleteRequests || '[]') : deleteRequests || []).filter(p => p)}
            options={efaturainvoicetypes || []}
            onChange={(e) => {
              handleChange(e, 'deleteRequests', e.target.value)
            }}
          />
        </div>
        <b>{i18n`Attention message`}</b>
      </div>
    </form>
  ) : null

}

const DeleteUserDataFormRef = forwardRef(DeleteUserDataForm)

DeleteUserDataFormRef.queries = (args) => {
  return [
    args && args.projectId ?
      {
        resource: 'efatura_project_fiscal_years',
        args: {
          'projectId': args.projectId,
        },
        body: 'id, name'
      } : undefined,
      args && args.projectId ?
      {
        resource: 'efatura_invoice_types',
        args: {
          'projectId': args.projectId,
          'locale': args.locale
        },
        body: 'id, name'
      } : undefined,
  ].filter(q => q)
}

DeleteUserDataFormRef.dataMapper = ['efaturaprojectfiscalyears', 'efaturainvoicetypes', 'data']

DeleteUserDataFormRef.actions = [FORM_ACTIONS.SAVE]

export default DeleteUserDataFormRef