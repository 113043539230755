import { format } from '../../../utils'

const mapCard = (json) => {
    const {
        code,
        country,
        taxType,
        stiTaxCode,
        description,
        taxPercentage,
        taxCodeDescription
    } = json

    return [
        [
            { labels: ['code'], value: code, type: 'string', basis: '13%' }
        ],
        [
            { labels: ['country'], value: country, type: 'string' }
        ],
        [
            { labels: ['type'], value: taxType, type: 'string', basis: '13%'  }
        ],
        [
            { labels: ['sti_tax_code'], value: stiTaxCode, type: 'string' , basis: '18%'}
        ],
        [
            { labels: ['description'], value: description, type: 'string', basis: '40%' }
        ],
        [
            { labels: ['tax description'], value: taxCodeDescription, type: 'string', basis: '40%' }
        ],
        [
            { labels: ['percentage'], value: format(taxPercentage,'normal', null,2), type: 'percentage', align:"right",basis: '20%' }
        ],
    ]
}

const mapper = (json, card) => {
    const name = json.tax_type

    return {
        ...json,
        __typename: json.__typename,
        id: json.id,
        name,
        shortName: name,
        card: card ? mapCard(json) : []
    }
}

const header = [
    { label: ["Code"], align: 'left',columnName: "tax_code", sortable: true, basis: '13%' },
    { label: ["Country"], align: 'left',columnName: "country" },
    { label: ["Type"], align: 'left',columnName: "tax_type", sortable: true, basis: '13%'  },
    { label: ["STI Tax Code"], align: 'left',columnName: "sti_tax_code", sortable: true, basis: '18%' },
    { label: ["Description"], align: 'left',columnName: "tax_description", basis: '40%' },
    { label: ["Tax Code Description"], align: 'left',columnName: "tax_code_description", basis: '40%' },
    { label: ["Percentage"], align: 'right',columnName: "tax_percentage", sortable: true },
]

const taxtableentryMapper = {
    map: mapper,
    header
}

export default taxtableentryMapper;