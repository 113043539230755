import React, {
	forwardRef,
	useRef,
	useImperativeHandle,
	useState,
	useEffect,
} from "react";
import { useGlobalValue } from "colbi_web_ui/lib/state/GlobalProvider";
import styles from "./GstReturnSubmissionDialog.module.sass";
import {
	TextInput,
	Dropdown,
	ActionButton,
} from "colbi_web_ui/lib/components/inputs";

const GstReturnSubmissionDialog = (props, ref) => {
	const { data, onChange, status, onSave, gst_otp_gstin } = props;
	const formRef = useRef();

	const { i18n } = useGlobalValue();
	const { period } = data || {};
	const [inputOTP, setinputOTP] = useState(false);

	const [newOTP, setNewOTP] = useState("null");

	const [statePeriod, setStatePeriod] = useState(true);
	const [stateRequestOTP, setStateRequestOTP] = useState(false);
	const [stateSaveOTP, setStateSaveOTP] = useState(false);

	useEffect(() => {
		if (gst_otp_gstin !== undefined) {
			if (gst_otp_gstin[0].apiPortal === "false") {
				setStateRequestOTP(true);
			}
			//Set gstin
			data.gstin = gst_otp_gstin[0].name;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [gst_otp_gstin, period]);

	const months = [
		{ id: "01", name: "January" },
		{ id: "02", name: "February" },
		{ id: "03", name: "March" },
		{ id: "04", name: "April" },
		{ id: "05", name: "May" },
		{ id: "06", name: "June" },
		{ id: "07", name: "July" },
		{ id: "08", name: "August" },
		{ id: "09", name: "September" },
		{ id: "10", name: "October" },
		{ id: "11", name: "November" },
		{ id: "12", name: "December" },
	];

	useImperativeHandle(ref, () => ({
		submit: () => {
			formRef.current.dispatchEvent(
				new Event("submit", { cancelable: true, bubbles: true })
			);
		},
	}));

	const handleSave = () => {
		data.type = "save";
		data.otp = newOTP;
		if (formRef.current.checkValidity()) {
			onSave && onSave();
		} else {
			formRef.current.reportValidity();
		}
	};

	const handleTmp = () => {
		if (formRef.current.checkValidity()) {
			onSave && onSave();
		} else {
			formRef.current.reportValidity();
		}

		setStateRequestOTP(false);
		setinputOTP(true);
	};

	const handleChange = (e, prop, value) => {
		onChange &&
			onChange({
				originalEvent: e,
				target: {
					value: {
						data: {
							...data,
							[prop]: value,
						},
					},
				},
			});
	};

	return status !== "loading" && data ? (
		<form
			ref={formRef}
			onSubmit={(e) => {
				e.stopPropagation();
				e.preventDefault();

				if (formRef.current.checkValidity()) {
					onSave && onSave();
				} else {
					formRef.current.reportValidity();
				}
			}}
		>
			<div className={styles["root"]}>
				{stateRequestOTP && statePeriod === false && (
					<div id="otpChange">
						<p>No active session. Need to order a new otp</p>
						<ActionButton
							primary
							icon="success"
							onClick={() => {
								data.type = "otp";
								handleTmp();
							}}
							className={`${styles["simple-button"]}`}
						>
							Request OTP
						</ActionButton>
					</div>
				)}
				{inputOTP && (
					<div className={styles["input-otp"]}>
						<p>
							A new otp was sent to your email or mobile phone it will be valid
							for 60 seconds please enter the new OTP and save
						</p>
						<TextInput
							placeholder={"OTP"}
							className={styles["simple-input"]}
							onChange={(e) => {
								setNewOTP(e.target.value);
							}}
						/>
						{newOTP !== "null" && (
							<ActionButton
								primary
								icon="success"
								onClick={() => {
									setinputOTP(false);
									setStateSaveOTP(true);
									handleSave();
								}}
								className={`${styles["simple-button"]}`}
							>
								Save OTP
							</ActionButton>
						)}
					</div>
				)}
				{statePeriod && (
					<div className={styles["content"]}>
						<div className={styles["input-field"]}>
							<div>
								<h4 className={styles["label"]}>
									Please enter the return period.
								</h4>
							</div>
							<h5 className={styles["label"]}>Period of submission</h5>
							<Dropdown
								className={styles["dropdown"]}
								name={i18n`Period of submission`}
								placeholder={i18n`Select one period`}
								value={period ? months.find(({ id }) => id === period) : null}
								options={months || []}
								required
								onChange={(e) => {
									handleChange(e, "period", e.target.value.id);
								}}
							/>
							{period != null && (
								<ActionButton
									primary
									icon="success"
									onClick={() => {
										setStatePeriod(false);
									}}
									className={`${styles["simple-button"]}`}
								>
									Save
								</ActionButton>
							)}
						</div>
					</div>
				)}

				{stateSaveOTP && (
					<div className={styles["input-otp"]}>
						<ActionButton
							primary
							icon="success"
							onClick={() => {
								data.type = "ends";
								setStatePeriod(false);
							}}
							className={`${styles["simple-button"]}`}
						>
							Submission
						</ActionButton>
					</div>
				)}

				{statePeriod === false && stateRequestOTP === false && (
					<div className={styles["input-otp"]}>
						<ActionButton
							primary
							icon="success"
							className={`${styles["simple-button"]}`}
						>
							Submission
						</ActionButton>
					</div>
				)}
			</div>
		</form>
	) : null;
};

const gstReturnSubmissionDialogRef = forwardRef(GstReturnSubmissionDialog);

gstReturnSubmissionDialogRef.queries = (args) => {
	return [
		{
			resource: "in_gstr_return_gstin",
			body: "id, name, __typename",
			args: {
				projectId: args.projectId,
			},
		},
		{
			resource: "in_gstr_otp_gstin",
			body: "name,apiPortal",
			args: {
				projectId: args.projectId,
				fiscalYear: args.fiscalYear,
			},
		},
	];
};

gstReturnSubmissionDialogRef.dataMapper = [
	"gst_return_gstin",
	"gst_otp_gstin",
	"data",
];

gstReturnSubmissionDialogRef.actions = [];

export default gstReturnSubmissionDialogRef;
