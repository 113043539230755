import { date } from '../../../utils'

const mapDetails = (json) => {
	const {
		taxEntity,
		invoiceNo,
		invoiceType,
		invoiceDate,
		supplierInfoSupplierId,
		supplierName,
		supplierInfoCountry,
		vatpointDate,
		specialTaxation,
		supplierInfoVatRegistrationNumber,
		supplierInfoRegistrationNumber,
		numberOfReferences,
		numberOfDocumentTotals,
		registrationAccountDate

	} = json
	return [
		[	
			{ label: 'Invoice No', value: invoiceNo, type: 'string' },
			{ label: 'Entity', value: taxEntity, type: 'string' },
			{ label: 'Type', value: invoiceType, type: 'string' },
			{ label: 'Invoice Date', value: date(invoiceDate), type: 'date' },
			{ label: 'Vatpoint Date', value: date(vatpointDate), type: 'date' },
			{ label: 'Registration Account Date', value: date(registrationAccountDate), type: 'date' },
		],
		[
			{ label: 'References', value: numberOfReferences, type: 'number' },
			{ label: 'Document Totals', value: numberOfDocumentTotals, type: 'number' },
		],
		[	
			{ label: 'Supplier Id', value: supplierInfoSupplierId, type: 'string' },
			{ label: 'Supplier Name', value: supplierName, type: 'string' },
			{ label: 'Supplier Country', value: supplierInfoCountry, type: 'string' },
			{ label: 'Vat Registration Number', value: supplierInfoVatRegistrationNumber, type: 'string' },
			{ label: 'Registration Number', value: supplierInfoRegistrationNumber, type: 'string' },
			{ label: 'Special Taxation', value: specialTaxation, type: 'string' },
		],

	]
}

const mapCard = (json) => {
	const {
		invoiceNo,
		invoiceType,
		invoiceDate,
		supplierInfoSupplierId,
		supplierName,
		supplierInfoCountry,
		vatpointDate,
		specialTaxation
	} = json

	return [
		[{ labels: ['Invoice No'], align:'left', value: invoiceNo, type: 'string',basis: '22%' }],
		[{ labels: ['type'], align:'left', value: invoiceType, type: 'string',basis: '22%' }],
		[{ labels: ['date'], value: date(invoiceDate), align: 'center', type: 'date' }],
		[{ labels: ['Supplier Id'], value: supplierInfoSupplierId, type: 'string' }],
		[{ labels: ['Supplier name'], value: supplierName, type: 'string' }],
		[{ labels: ['country'], value: supplierInfoCountry, type: 'string' }],
		[{ labels: ['Vatpoint Date'], value: date(vatpointDate), type: 'date', align: 'center' }],
		[{ labels: ['Special Taxation'], value: specialTaxation, type: 'string' }],
	]
}

const mapper = (json, card, details) => {
	return {
		...json,
		name: json.refNumber,
		shortName: json.refNumber,
		details: details ? mapDetails(json) : [],
		card: card ? mapCard(json) : [],
	}
}

const header = [
	{ label: ["Invoice No"], align:'left', columnName: "invoice_no", sortable: true,basis: '22%' },
	{ label: ["Type"], align:'left', columnName: "invoice_type", sortable: true,basis: '22%' },
	{ label: ["Invoice date"], align: 'center', columnName: "invoice_date", sortable: true },
	{ label: ["Supplier Id"], columnName: "supplier_info_supplier_id" },
	{ label: ["Supplier Name"], columnName: "supplier_info_name" },
	{ label: ["Country"] },
	{ label: ["Vatpoint Date"], columnName: "vatpoint_date", align: 'center', sortable: true },
	{ label: ["Special Taxation"], columnName: "special_taxation", },
]

const pruchaseinvoiceMapper =  {
	map: mapper,
	header
}
export default pruchaseinvoiceMapper;