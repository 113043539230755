import I18n from '../../../I18n'

const header = [
    { label: ["rule"], align: 'left', basis: '20%' },
    { label: ["severity"], align: 'center' },
    { label: ["category"], align: 'left' },
    { label: ["description"], align: 'left', basis: '50%' },
    { label: ["occurrences"], align: 'right' },
    { label: ["validation"], align: 'right' }
]

const mapCard = (json) => {
    const {
        rule,
        severity,
        category,
        description,
        occurrences,
        validation
    } = json

    return [
        [
            { labels: ['rule'], value: rule, type: 'string', basis: '20%' }
        ],
        [
            { labels: ['severity'], value: [`severity-${severity}`, I18n.translate`${`severity-${severity}`}`, 'center'], type: 'status' }
        ],
        [
            { labels: ['category'], value: category, type: 'string' }
        ],
        [
            { labels: ['description'], value: description, type: 'string', basis: '50%' }
        ],
        [
            { labels: ['occurrences'], value: occurrences, type: 'string', align: 'right' }
        ],
        [
            { labels: ['validation'], value: `${validation}/${occurrences}`, type: 'string', align: 'right' }
        ]
    ]
}

const mapper = (json, card) => {
    const name = json.occurrences

    return {
        ...json,
        __typename: json.__typename,
        id: `${json.ruleId}/finding/${json.findingId}`,
        name,
        shortName: name,
        card: card ? mapCard(json) : []
    }
}

const auditrecordMapper = {
    map: mapper,
    header
}
export default auditrecordMapper;