import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'

import 'colbi_web_ui/lib/style/variableInjector.scss'
import 'colbi_web_ui/lib/style/index.sass'

import './polyfills/object.entries.js'

import React from 'react'
import ReactDOM from 'react-dom'

import { BrowserRouter as Router } from 'react-router-dom'
import { Provider } from 'react-redux'

import CheckPermissionsRouting from './components/CheckPermissionsRouting'
import configureStore from './store'
import { fetchAction } from './store/actions'
import * as serviceWorker from './serviceWorker'

export const {store, persistor} = configureStore({})


ReactDOM.render(
	<Provider store={store}>
		<Router>
			<CheckPermissionsRouting store={store} fetchAction={fetchAction} />
		</Router>
	</Provider>,
	document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()