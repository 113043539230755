import { List, rows, Filters, Widget, Grid, charts, } from "colbi_web_ui/lib/components";
import { parseFilter } from "colbi_web_ui/lib/utils";
import AppByFile from "../controllers/AppByFile/AppByFile";

const queries = (args = {}) => {
    const entityResource = args.modulePrefix ?  `${args.modulePrefix}_${args.entity}` : args.entity
    return [
        {
            resource: entityResource,
            args: {
                projectId: (args.projectId),
                fiscalYear: (args.fiscalYear),
                modulePrefix: args.modulePrefix,
                filter: args.filter ? { type: '[FilterInput!]', value: parseFilter(args.filter) } : undefined,
                sort: args.sort ? { type: '[SortInput!]', value: parseFilter(args.sort) } : undefined,
                page: parseInt(args.page, 10) || 0
            },
            body: `__typename, items{ ...${entityResource} }, hasMore, total, totalizers,filters(projectId:$projectId,fiscalYear:$fiscalYear,modulePrefix:$modulePrefix){ prop, name, type, list }`,
            downloadable: true,
            xlsxTranslation: entityResource
        },
        {
            resource: `${entityResource}_summary`,
            args: {
                projectId: (args.projectId),
                fiscalYear: (args.fiscalYear),
                modulePrefixEfatura: args.modulePrefix,
            },
            body: 'summary{ key, value }'
        },
        {
            resource: `${entityResource}_kpis`,
            args: {
                projectId: (args.projectId),
                fiscalYear: (args.fiscalYear),
                modulePrefixEfatura: args.modulePrefix,
            },
            body: `...${entityResource}_kpis`
        },
    ]
}

const efaturaTransactionalData = (path, navigation, exact, title) => ({
    path,
    navigation,
    component: AppByFile,
    queries,
    exact,
    title: [title, `label(param(entity))`],
    components: [
        {
            component: Widget,
            modifiers: ['--root-margin-bottom-normal'],
            props: {
                icon: 'sales',
                title: ["param(entity)", " kpis"],
                children: [
                    {
                        component: Grid,
                        modifiers: ['--grid-border-inline'],
                        props: {
                            columnsTemplate: [1],
                            items: [
                                {
                                    data: [
                                        {
                                            name: 'label(Gross Total)',
                                            value: 'currency(query(2).invoicesGrossTotal)',
                                            icon: 'gross-sales'
                                        },
                                        {
                                            name: 'label(Net Total)',
                                            value: 'currency(query(2).invoicesNetTotal)',
                                            icon: 'net-sales'
                                        },
                                        {
                                            name: 'label(Total Tax Amount)',
                                            value: 'currency(query(2).invoicesTaxAmount)',
                                            icon: 'net-sales'
                                        },
                                        {
                                            name: 'label(Invoice Avg Net)',
                                            value: 'currency(query(2).invoicesNetAverage)',
                                            icon: 'kpi-icon'
                                        },
                                        {
                                            name: 'label(Invoice Max Value)',
                                            value: 'currency(query(2).invoicesMaxValue)',
                                            icon: 'kpi-icon'
                                        }
                                    ]

                                }
                            ],
                            cards: [charts.KpisLegend]
                        }
                    }
                ]
            }
        },
        {
            component: Widget,
            modifiers: ['--root-margin-bottom-small'],
            props: {
                icon: 'sales',
                title: ["Total Value of ", "param(entity)", " by Period"],
                children: [
                    {
                        component: Grid,
                        props: {
                            columnsTemplate: [1],
                            aspect: ['25x8'],
                            items: [
                                {
                                    data: 'query(1).list',
                                    currency: true,
                                    stacked: true,
                                    summarized: true,
                                    dataLabels: ['label(Period)'],
                                    links: [
                                        {
                                            to: ['param(projectId)', 'param(fiscalYear)', 'efatura', 'param(entity)'],
                                            filterColumn: 'Period',
                                        }
                                    ]
                                }
                            ],
                            cards: [charts.BarChart]
                        }
                    }
                ]
            }
        },
        {
            component: Filters,
            props: {
                filter: 'param(filter)',
                availableFilters: 'query(0).filters',
                stickyTop: 40,
                anchor: 'results'
            }
        },
        {
            component: List,
            modifiers: [
                '--root-margin-top-small',
                '--row-style-alternate'
            ],
            props: {
                id: 'results',
                items: 'query(0).list',
                filter: 'param(filter)',
                sort: 'param(sort)',
                basePath: path,
                cardKey: 'param(entity)',
                card: rows.EntityCard,
                header: 'query(0).header',
                metadata: 'query(0).metadata',
                page: 'param(page)',
                status: 'status(0)',
                pageSize: 'param(pageSize)',
                footer: 'query(0).footer',
                totalizers: 'query(0).totalizers'
            }
        }
    ]

})


export default efaturaTransactionalData;