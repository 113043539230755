import { format } from "colbi_web_ui/lib/utils";

const header = [
	{ label: ["Customer Country"], align: "left", basis: "20%" },
	{ label: ["client Id"], align: "left" },
	{ label: ["Company"], align: "left", basis: "45%" },
	{ label: ["Tax ID"], align: "left" },
	{ label: ["Total Documents"], align: "right", columnName: "total_documents", sortable: true },
	{ label: ["Tax Payable"], align: "right", columnName: "document_totals_tax_payable", sortable: true, totalizerName: "total_document_totals_tax_payable" },
	{ label: ["Net Total"], align: "right", columnName: "document_totals_net_total", sortable: true, totalizerName: "total_document_totals_net_total" },
	{ label: ["Gross Total"], align: "right", columnName: "document_totals_gross_total", sortable: true, totalizerName: "total_document_totals_gross_total" },
];

const mapCard = (json) => {
	const {
		customerCountry,
		customerId,
		companyName,
		customerTaxId,
		totalDocuments,
		totalTaxPayable,
		totalNet,
		totalGross,
	} = json;

	return [
		[{ labels: ["customerCountry"], value: customerCountry, type: "string", basis: "20%" }],
		[{ labels: ["customerId"], value: customerId, type: "string" }],
		[{ labels: ["companyName"], value: companyName, type: "string", basis: "45%" }],
		[{ labels: ["customerTaxId"], value: customerTaxId, type: "string" }],
		[{ labels: ["totalDocuments"], value: totalDocuments, type: "number", align: "right" }],
		[{ labels: ["totalTaxPayable"], value: format(totalTaxPayable), type: "currency" }],
		[{ labels: ["totalNet"], value: format(totalNet), type: "currency" }],
		[{ labels: ["totalGross"], value: format(totalGross), type: "currency" }],
	];
};

const mapper = (json, card) => {
	const name = json.accountId;

	return {
		...json,
		name,
		shortName: name,
		card: card ? mapCard(json) : [],
		link: {
			to: `/:locale(pt|en|lt|es)?/:projectId/:fiscalYear([0-9]{4}|current)/:importId/:modulePrefix/master_data/customers/${json.customerRowKey}`,
		}
	};
};

const customersalesMapper = {
	map: mapper,
	header,
};

export default customersalesMapper;
