import { format, date } from "../../../utils"

const mapDetails = (json) => {
	const {
		lineNumber,
		tax,
		settlementAmount,
		creditAmount,
		debitAmount,
		customer,
		description,
		type,
		series,
		serialNumber,
		paymentStatus,
		transactionDate,
		paymentType,
		refNumber
	} = json
	return [
		[
			{
				label: 'Line Number',
				value: lineNumber || 'N/A',
				type: 'number'
			},
			{
				label: 'Payment Ref. No.',
				value: refNumber || 'N/A',
				type: 'string'
			},
			{
				label: 'Description',
				value: description || 'N/A',
				type: 'string'
			},
			{
				label: 'Type',
				value: type || 'N/A',
				type: 'string'
			},
			{
				label: 'Payment Status',
				value: paymentStatus || 'N/A',
				type: 'string'
			},
			{
				label: 'Payment Type',
				value: paymentType || 'N/A',
				type: 'string'
			}
		],
		[
			{
				label: 'Credit Amount',
				value: format(creditAmount),
				type: 'currency'
			},
			{
				label: 'Debit Amount',
				value: format(debitAmount),
				type: 'currency'
			},
			{
				label: 'Settlement Amount',
				value: format(settlementAmount),
				type: 'currency'
			},
		],
		[
			{
				label: 'Series',
				value: series || 'N/A',
				type: 'number'
			},
			{
				label: 'Serial Number',
				value: serialNumber || 'N/A',
				type: 'number'
			},
			{
				label: 'Transaction Date',
				value: date(transactionDate) || 'N/A',
				type: 'date'
			},
			customer ? {
				label: 'Customer',
				value: {
					id: customer.id,
					name: customer.name,
					__typename: 'Customer'
				},
				type: 'object'
			} : null
		],
		tax ? {
			title: 'Tax Information',
			rows: [
				[
					{
						label: 'Tax Entity',
						value: tax.taxEntity || 'N/A',
						type: 'string'
					},
					{
						label: 'Tax Type',
						value: tax.taxType || 'N/A',
						type: 'string'
					},
					{
						label: 'Tax Code',
						value: tax.taxCode || 'N/A',
						type: 'string'
					},
					{
						label: 'Tax Percentage',
						value: format((parseFloat(tax.taxPercentage) || 0).toFixed(2), 'normal', null),
						type: 'percentage'
					},
					{
						label: 'Tax Amount',
						value: format(tax.taxAmount),
						type: 'currency'
					},
					{
						label: 'Tax Date',
						value: date(tax.taxDate),
						type: 'date'
					},
					{
						label: 'Tax Country',
						value: tax.country || 'N/A',
						type: 'string'
					},
				], [
					{
						label: 'Tax Exemption Code',
						value: tax.taxExemptionCode || 'N/A',
						type: 'string'
					},
					{
						label: 'Tax Exemption Reason',
						value: tax.taxExemptionReason || 'N/A',
						type: 'string'
					},
				]
			]
		} : null,
	]
}

const mapCard = (json) => {
	const {
		lineNumber,
		refNumber,

		series,
		serialNumber,
		paymentStatus,
		transactionDate,

		settlementAmount,
		debitAmount,
		creditAmount
	} = json
	return [
		[
			{ labels: ['ref'], value: `${refNumber}`, type: 'string' }
		],
		[
			{ labels: ['number'], value: `[${lineNumber}]`, type: 'number' }
		],
		[
			{ labels: ['series'], value: `${series}`, type: 'number' }
		],
		[
			{ labels: ['serial_number'], value: `${serialNumber}`, type: 'number' }
		],
		[
			{ labels: ['status'], value: `${paymentStatus}`, type: 'number' }
		],
		[
			{ labels: ['date'], value: date(transactionDate), type: 'date' }
		],
		[
			{ labels: ['settlement_amount'], value: format(settlementAmount), type: 'currency' }
		],
		[
			{ labels: ['debit_amount'], value: format(debitAmount), type: 'currency' }
		],
		[
			{ labels: ['credit_amount'], value: format(creditAmount), type: 'currency' }
		]
	]
}

const mapper = (json, card, details) => {
	return {
		...json,
		name: '[' + json.lineNumber + '] ' + json.refNumber,
		shortName: json.refNumber,
		details: details ? mapDetails(json) : [],
		card: card ? mapCard(json) : []
	}
}

const header = [
	{ label: ["Ref"], align: 'left', columnName: "payment_ref_no", sortable: true },
	{ label: ["[No]"], align: 'center' },
	{ label: ["Series"], align: 'center' },
	{ label: ["Serial Number"], align: 'center' },
	{ label: ["Status"], align: 'center', columnName: "document_status_payment_status", sortable: true },
	{ label: ["Date"], align: 'center' },
	{ label: ["Settlement"], align: 'right', columnName: "settlement_amount", sortable: true, totalizerName: "total_settlement_amount" },
	{ label: ["Debit"], align: 'right', columnName: "debit_amount", sortable: true, totalizerName: "total_debit_amount" },
	{ label: ["Credit"], align: 'right', columnName: "credit_amount", sortable: true, totalizerName: "total_credit_amount" }
]

const paymentlineMapper = {
	map: mapper,
	header
}

export default paymentlineMapper;