import { date } from "../../../utils";

const mapCard = (json) => {
	const {
		SapDocNo,
		SapDocType,
		UserName,
		CancelRsnCode,
		CancelRmrk,
		CreatedAt,
		Status,
		InvoiceNo,
	} = json;
	return [
		[{ labels: ["sapDocNo"], value: SapDocNo, type: "string", basis: "25%" }],
		[
			{
				labels: ["sapDocType"],
				value: SapDocType,
				type: "string",
				basis: "25%",
			},
		],
		[
			{
				labels: ["Invoice Number"],
				value: InvoiceNo,
				type: "string",
				basis: "25%",
			},
		],
		[{ labels: ["userName"], value: UserName, type: "string" }],
		[{ labels: ["cancelRsnCode"], value: CancelRsnCode, type: "string" }],
		[{ labels: ["cancelRmrk"], value: CancelRmrk, type: "string" }],
		[{ labels: ["createdAt"], value: date(CreatedAt), type: "string" }],
		[{ labels: ["Status"], value: Status, type: "string" }],
	];
};

const mapper = (json, card) => {
	return {
		...json,
		name: json.name,
		shortName: json.name,
		card: card ? mapCard(json) : [],
	};
};

const header = [
	{
		label: ["SAP DOCUMENT NO"],
		align: "left",
		columnName: "SapDocNo",
		sortable: true,
		basis: "25%",
	},
	{
		label: ["SAP DOCUMENT TYPE"],
		align: "left",
		columnName: "SapDocType",
		sortable: true,
		basis: "25%",
	},
	{
		label: ["Invoice Number"],
		align: "left",
		columnName: "InvoiceNo",
		sortable: true,
		basis: "25%",
	},
	{
		label: ["User"],
		align: "left",
		columnName: "UserName",
		sortable: true,
	},
	{
		label: ["Cancellation Code"],
		align: "left",
		columnName: "CancelRsnCode",
		sortable: true,
	},
	{
		label: ["Cancellation Remarks"],
		align: "left",
		columnName: "CancelRmrk",
		sortable: false,
	},
	{
		label: ["Requested On"],
		align: "left",
		columnName: "CreatedAt",
		sortable: false,
	},
	{
		label: ["Status"],
		align: "left",
		columnName: "Status",
		sortable: false,
	},
];

const einvoiceCancelMapper = {
	map: mapper,
	header,
};

export default einvoiceCancelMapper;
