import { date, format } from '../../../utils'

const mapCard = (json) => {
	const {
        taxableValue,
        taxCode,
        taxPercentage,
        amount,
        vatPointDate2
	} = json

	return [
        [
            { labels: ['tax_Code'], value: taxCode, type: 'string' }
        ],
        [
            { labels: ['date'], value: date(vatPointDate2), type: 'date' }
        ],
        [
            { labels: ['tax_percentage'], value: format(taxPercentage, 'normal', null, 2),type: 'percentage' }
        ],
        [
            { labels: ['amount'], value: format(amount), type: 'currency', align: 'right' }
        ],
        [
            { labels: ['taxable_value'], value: format(taxableValue), type: 'currency', align: 'right' }
        ]
	]
}

const mapper = (json, card) => {

	return {
        ...json,
        __typename: json.__typename,
        id: json.id,
		card: card ? mapCard(json) : []
	}
}

const header = [
    { label: ["Tax Code"], align: 'left' },
    { label: ["Vat Point Date"], align: 'center', columnName: "vat_point_date2", sortable: true },
    { label: ["Tax Percentage"], align: 'right', columnName: "tax_percentage", sortable: true },
    { label: ["Amount"], align: 'right',totalizerName: 'total_amount', columnName: "amount", sortable: true },
    { label: ["Taxable Value"], align: 'right',totalizerName: 'total_taxable_value', columnName: "taxable_value", sortable: true },
]

const documenttotalMapper = {
	map: mapper,
	header
}

export default documenttotalMapper;