import React, { useEffect, useState } from 'react'
import { Redirect, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import { parse } from 'query-string'

import I18n from '../../I18n'
import theme from '../../style/_theme.json'
import proxy from '../../routes/proxy'

import { mapProperties, hydrateRoute, number, parseFilter, stringifyFilter } from 'colbi_web_ui/lib/utils'
import { logout, pushModal, pushDialog, popModal, uploadAction, toggleMenu, setLocale, auditNoteSubmit, removeModal } from '../../store/actions'

import { List, Filters, LoadingIndicator, ImageRender, FreshDeskWidget } from 'colbi_web_ui/lib/components'
import AsideMenu from 'colbi_web_ui/lib/components/layout/AsideMenu/AsideMenu'
import Header from 'colbi_web_ui/lib/components/layout/Header/Header'

import { EntityCard } from 'colbi_web_ui/lib/components/rows'
import { FloatButton } from 'colbi_web_ui/lib/components/inputs'
import { GlobalProvider as ColbiUIProvider } from 'colbi_web_ui/lib/state/GlobalProvider'
import { GlobalProvider } from '../../state/globalProvider'
import ModalController from '../App/ModalController/ModalController'

import { manageNavigation } from './navigation'

import styles from './Settings.module.sass'

import { UserRoles } from "../../enums/userRoles"
import DialogController from '../App/DialogController/DialogController'
import { decryptSession, checkRoles } from 'colbi_web_ui/lib/utils/roleValidator'
import configsByTheme from '../../enums/configsByTheme'

const allTabs = [
	{
		section: 'organizations',
		label: 'Organizations',
		createType: 'Organization'
	},
	{
		section: 'projects',
		label: 'Projects',
		createType: 'Project'
	},
	{
		section: 'users',
		label: 'Users',
		createType: 'User'
	}
]

const filtersTabs = [
	{
		section: 'projects',
		label: 'Projects',
		createType: 'Project'
	},
	{
		section: 'users',
		label: 'Users',
		createType: 'User'
	}
]

const Provider = ({ children, ...props }) => {
	return (
		<GlobalProvider {...props}>
			<ColbiUIProvider {...props}>
				{children}
			</ColbiUIProvider>
		</GlobalProvider>
	)
}

const appTheme = process && process.env && process.env.REACT_APP_COLBI_THEME ? process.env.REACT_APP_COLBI_THEME.toLocaleLowerCase() : "";
const asideMenuLogo = configsByTheme(appTheme)["icon"]
const localesByAppTheme = configsByTheme(appTheme)['locales'];
const logoHorizontalDisplay = configsByTheme(appTheme)['logoHorizontalDisplay'] || false

const logo = () => {
	return (
		process && process.env &&
		<ImageRender
			icon={`${appTheme && appTheme.length ? asideMenuLogo : "logo"}`}
		/>
	);
};



const Settings = (props) => {
	const {
		user,
		fetch,
		match,
		setLocale,
		location,
		toggleMenu,
		logout,
		history,
		pushModal,
		popModal,
		menu,
		reload,
		pushDialog,
		uploads,
		uploadFiles,
		submitAuditNote
	} = props

	let userInformation = decryptSession(user)

	const tabs = (!checkRoles(userInformation, [UserRoles.ADMIN, UserRoles.BACKOFFICE])) ? filtersTabs : allTabs
	const locale = match.params.locale || 'en'
	const i18n = I18n.use(locale)

	useEffect(() => {
		setLocale(locale)
	}, [locale, setLocale])

	const [hover, setHover] = useState(false);

	if (!userInformation) {
		return <Redirect to={'/login'} push />
	}

	if (userInformation && parseInt(userInformation.mysqlPasswordReset)) {
		return <Redirect to={`/${locale}/reset_password`} />
	}

	if (!checkRoles(userInformation, [UserRoles.ADMIN, UserRoles.OWNER, UserRoles.BACKOFFICE, UserRoles.PP_SUPPORT, UserRoles.SUPPORT])) {
		return null
	}

	fetch.results[`${locale}_is_new_rules`] ? tabs.push({section: 'nc_rules',label: 'Rules',createType: 'NcRule'}) : tabs.push({section: 'rules',label: 'Rules',createType: 'Rule'})

	let navigation = []
	if (fetch.results[`${locale}_hide_rule_tab`] !== undefined) {
		const hideRuleTab = fetch.results[`${locale}_hide_rule_tab`].hide === true && (checkRoles(userInformation, [UserRoles.OWNER, UserRoles.SUPPORT]))
		const isNewRules = fetch.results[`${locale}_is_new_rules`];

		if (hideRuleTab) {
			navigation = manageNavigation([{
				name: 'Home',
				to: '/',
				exact: true,
				icon: 'back'
			}])
		} else {
			navigation = manageNavigation(
				[{
					name: 'Rules',
					to: isNewRules ? '/settings/nc_rules' : '/settings/rules',
					exact: true,
					icon: 'rules'
				},
				{
					type: 'separator'
				},
				{
					name: 'Home',
					to: '/',
					exact: true,
					icon: 'back'
				}]
			)
		}
	}

	if (!checkRoles(userInformation, [UserRoles.ADMIN, UserRoles.BACKOFFICE, UserRoles.PP_SUPPORT])) {
		navigation.shift();
	}

	if (checkRoles(userInformation, [UserRoles.BACKOFFICE])) {
		navigation.pop();
	}

	navigation.forEach((elem) => {
		elem.name = I18n.translate`${elem.name}`;
		return elem;
	});

	const queryParams = parse(location.search) || {}
	const routeParams = match.params || {}
	const params = {
		...queryParams,
		...routeParams
	}

	const data = mapProperties(
		{ items: 'query(0).list', header: 'query(0).header', footer: 'query(0).footer', metadata: 'query(0).metadata', page: 'param(page)', status: 'status(0)', pageSize: 'param(pageSize)', pageDefault: 10, filter: 'param(filter)', sort: "param(sort)", availableFilters: 'query(0).filters' },
		(fetch || {}).results,
		(fetch || {}).status,
		(Settings.queries && Settings.queries(params)) || [],
		props,
		params,
		i18n,
		locale
	)
	const currentSection = (match.params.section || '/')
	let floatCreateTypename = (tabs.find(({ section }) => section === currentSection) || {}).createType

	const version = (fetch.results[`${locale}_application_version`] || "").version

	const ticketSubjectEnvironment = fetch.results[`${locale}_get_environment_to_subject`] || ""

	const isToDisplaySupportIcon = (fetch.results[`${locale}_is_to_display_support_icon`] || "true").toLocaleLowerCase() === "true";

	if (currentSection === '/') {
		const basePath = "/settings/:organizationId?"
		const hydrated = hydrateRoute(basePath, { ...params })
		return <Redirect to={`${hydrated}${tabs[0].section}`} replace />
	}

	const goto = (page, filters, anchor) => {
		const pageUrl = page ? (typeof page.join === 'function' ? page.join('/') : page).replace(/\/+/g, '/') : null
		const urlFilters = filters ? (Array.isArray(filters) ? stringifyFilter(filters) : stringifyFilter([filters])) : ''
		const localePrefixed = pageUrl ? `${locale ? `/${locale}${!pageUrl.match(/^\//) ? '/' : ''}` : ''}` : null
		const gotoPage = pageUrl ? `${localePrefixed}${pageUrl}${urlFilters.length ? `?filter=${urlFilters}&resetFilters=${Date.now()}` : ''}` : `${location.pathname}?filter=${urlFilters}&resetFilters=${Date.now()}${anchor ? `#${anchor}` : ''}`
		history.push(gotoPage)
	}

	const handleHover = (state) => {
		setHover(state);
	};

	return (
		<div className={styles['app']}>
			<Provider
				user={userInformation}
				proxy={proxy}
				theme={theme}
				uploads={uploads}
				actions={{
					pushModal, pushDialog, popModal, uploadFiles, submitAuditNote, removeModal, logout,
					setLocale: (locale) => {
						history.push(`/${locale}${history.location.pathname.substring(3)}`);
					},
					goto
				}}
				i18n={i18n}
				locale={locale || localesByAppTheme[0].id}
				locales={localesByAppTheme}
				reload={reload}
				params={params}
				history={history}
				location={location}
			>
				<div onMouseEnter={() => menu === 'collapsed' ? handleHover(true) : null} onMouseLeave={() => menu === 'collapsed' ? handleHover(false) : null} className={`${styles['aside']} ${menu === 'expanded' ? '' : styles['is-collapsed']}`}>
					<AsideMenu
						logo={logo}
						logout={logout}
						title={menu === 'expanded' || hover ? i18n`Settings` : ' '}
						navigation={navigation}
						locales={localesByAppTheme}
						i18n={i18n}
						locale={locale}
						history={history}
						expanded={menu === 'expanded'}
						version={version}
						params={{
							...match.params
						}}
						toggleMenu={toggleMenu}
						hover={hover}
						setHover={setHover}
						reservedRights={configsByTheme(appTheme)["reservedRights"]}
                		applicationName={configsByTheme(appTheme)["loginApplicationName"]}
						logoHorizontalDisplay={logoHorizontalDisplay}
					/>
				</div>
				<div className={styles['main']}>
					<Header
						className={styles['header']}
						logout={logout}
						navigation={navigation}
						locales={localesByAppTheme}
						i18n={i18n}
						locale={locale}
						search={false}
						history={history}
						params={{
							...match.params
						}}
						theme={theme}
						title={i18n`${match.params.section || ''}`}
						toggleMenu={toggleMenu}
						expanded={menu !== 'expanded'}
						menuLogo={logo}
						menuTitle={i18n`Settings`}
						displaySupportIcon={isToDisplaySupportIcon}
					/>
					<main className={`${styles['content'] || ''} ${styles['foreground'] || ''}`}>
						{fetch.activity && <LoadingIndicator className={`${styles['loading-indicator']} ${menu === 'expanded' ? '' : styles['small-margin']}`} />}
						<Filters
							filter={data.filter}
							availableFilters={data.availableFilters}
							stickyTop={theme}
							anchor='results'
						/>
						<List
							modifiers={['--root-margin-top-small', '--row-style-alternate']}
							{...(data || {})}
							card={EntityCard}
							onClick={(e) => {
								e.originalEvent.preventDefault()
								e.originalEvent.stopPropagation()
								const { id, card, __typename } = e.target.value || {}
								const title = (
									card && card[0] && card[0][0] ?
										card[0][0].value :
										''
								)
								pushModal({
									title,
									view: `${__typename}Form`,
									args: { id }
								}).then(() => {
									history.push(match.url)
									reload && reload()
								})
									.catch(() => null)
							}}
						/>
						{floatCreateTypename ? <FloatButton
							className={styles['float-button']}
							icon={`new-${(floatCreateTypename || '').toLowerCase()}`}
							onClick={() => {
								pushModal({
									title: i18n`New ${floatCreateTypename}`,
									view: `${floatCreateTypename}Form`,
									data: { __typename: floatCreateTypename }
								}).then(() => {
									reload && reload()
								})
									.catch(() => null)
							}
							} /> : null}
					</main>
					<ModalController />
				</div>
				<DialogController i18n={i18n} />
				<FreshDeskWidget environment={ticketSubjectEnvironment} emailFrom={userInformation.username} name={userInformation.name}/>
			</Provider>
		</div>
	)
}

Settings.queries = (args) => {
	const { section, pageSize, page, filter } = args || {}


	return section ? [
		{
			resource: section,
			args: {
				pageSize: number(pageSize),
				page: page ? number(page) : 0,
				filter: filter ? { type: '[FilterInput!]', value: parseFilter(filter) } : undefined,
				sort: args.sort ? { type: "[SortInput!]", value: parseFilter(args.sort) } : undefined,
			},
			body: `__typename, items{...${section}},hasMore,total,filters{ prop, name, type, list, disableOperators }`
		},
		{
			resource: 'application_version',
			body: 'version'
		},
		{
			resource: 'hide_rule_tab',
			body: 'hide'
		},
		{
			resource: "is_to_display_support_icon",
		},
		{
			resource: 'is_new_rules'
		},
		{
			resource: "get_environment_to_subject",
		}
	] : []
}

export default connect(
	({ user, fetch, menu, uploads }) => (
		{
			user,
			fetch,
			menu,
			uploads
		}
	),
	{
		toggleMenu,
		logout,
		setLocale,
		pushModal,
		popModal,
		removeModal,
		pushDialog,
		uploadFiles: uploadAction,
		submitAuditNote: auditNoteSubmit
	}
)(withRouter(Settings))
