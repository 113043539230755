function getInitialState() {
	try {
		const session = window.localStorage.getItem("persist:session");
		return (session && JSON.parse(session)) || null;
	} catch (e) {
		console.error("100 - Local storage");
		return null;
	}
}

const authToken = (state = getInitialState(), action) => {
	switch (action.type) {
		case "LOGIN_SUCCESS_TOKEN":
			return action.payload.data;
		default:
			break;
	}
	return state;
};

const userState = () => {
	return getInitialState()
}

const user = (state = getInitialState(), action) => {
	switch (action.type) {
		case "LOGIN_SUCCESS":
			return action.payload.data;
		case "LOGIN_ERROR":
		case "LOGOUT_SUCCESS":
			window.localStorage.clear()
			return null;
		default:
			break;
	}
	return state;
};

const loginError = (state = null, action) => {
	switch (action.type) {
		case "LOGIN_SUCCESS":
			return null;
		case "LOGIN_ERROR":
			return action.payload.data || "ERROR";
		default:
			break;
	}
	return state;
};

//RESET PASSWORD
const resetPasswordError = (state = null, action) => {
	switch (action.type) {
		case "RESET_SUCCESS":
			return null;
		case "RESET_ERROR":
			return action.payload.msg;
		default:
			break;
	}
	return state;
};

const forgetPasswordMessage = (state = null, action) => {
	switch (action.type) {
		case "FORGET_PASSWORD_MESSAGE":
			return action.payload.msg;
		default:
			break;
	}
	return state;
};

export {
	user,
	authToken,
	loginError,
	resetPasswordError,
	forgetPasswordMessage,
	userState
};
