import React, { useEffect, useRef } from 'react'
import { connect } from 'react-redux'

import { popModal, fetchModalData, updateModalData, saveModalData, saveTicketModal, submitFilesModal} from '../../../store/actions'

import { ActionButton, RegularButton } from 'colbi_web_ui/lib/components/inputs'
import { views } from '../../../store'
import { FORM_ACTIONS } from '../../../store/views/FormActions'
import Icon from 'colbi_web_ui/lib/components/Icon/Icon'

import { useGlobalValue } from 'colbi_web_ui/lib/state/GlobalProvider'

import styles from './ModalViewController.module.sass'

const ModalViewController = (props) => {
	const {
		id,
		view,
		title,
		data,
		args,
		status,
		errors,
		pop,
		fetchData,
		updateData,
		saveData,
		saveTicket,
		modalType,
		submitFilesModal
	} = props

	const { i18n, logout } = useGlobalValue()
	const View = views[view]
	const form = useRef(null)

	const actions = (View.actions || []).map((action) => {

		switch (action) {
			case FORM_ACTIONS.SAVE:
				return (
					<ActionButton primary key="action-save" type="submit"
						onClick={() => {
							if (form.current) {
								form.current.submit()
							}
						}}
					><small>{i18n`Save`}</small></ActionButton>
				)
			case FORM_ACTIONS.DELETE:
				return (
					<ActionButton danger key="action-delete">{<small>{i18n`Delete`}</small>}</ActionButton>
				)

			case FORM_ACTIONS.SUBMIT:
				return (
					<ActionButton primary key="action-save" type="submit"
						onClick={() => {
							if (form.current) {
								form.current.submit()
							}
						}}
					><small>{i18n`Submit`}</small></ActionButton>
				)

			case FORM_ACTIONS.GENERATE:
				return (
					<ActionButton primary key="action-save" type="submit"
						onClick={() => {
							if (form.current) {
								form.current.submit()
							}
						}}
					><small>{i18n`Run`}</small></ActionButton>
				)

			case FORM_ACTIONS.TICKETSUBMITION:
				return (
					<div key="form-div">
						<RegularButton key="action-cancel"
							onClick={() => {
								logout()
							}}
						>
							<small>{i18n`Logout`}</small>
						</RegularButton>
						<ActionButton
							key="action-save"
							type="submit"
							onClick={() => {
								if (form.current) {
									form.current.submit()
								}
							}}
						>
							<small>{i18n`Confirm`}</small>
						</ActionButton>
					</div>
				)

			default:
				return null;
		}
	})


	useEffect(() => {
		if (View && status === 'initial') {
			const queries = (View.queries && View.queries(args || {})) || []
			const mapper = View.dataMapper || []

			if (queries.length && mapper.length) {
				fetchData({ id, queries, mapper })
			} else {
				updateData({ id, data })
			}
		}
	}, [id, data, View, args, fetchData, updateData, status])

	return (
		<>
			<header className={styles['header']}>
				<div>
					<button className={styles['close-button']} onClick={() => { pop(id) }}>
						<Icon name="close" />
					</button>
				</div>
				<div>
					<h5>{title}</h5>
				</div>
				{modalType !== 'center' ? <div className={styles['actions']}>
					{actions}
				</div> : null}
			</header>
			<main className={styles['main']}>
				<div className={styles['errors-container']}>
					{(errors || []).map(({ message }) => (
						<div className={styles['error']} key={message}>
							<small>{i18n`${message}`}</small>
						</div>
					))}
				</div>
				{View && <View
					{...(data || {})}
					ref={form}
					status={status}
					onChange={(e) => {
						updateData({
							id,
							data: e.target.value
						})
					}}
					onSave={() => {
					switch (view) {
						case "TicketForm":
							saveTicket(id)
						break;
						case "FileSubmissionForm":
							submitFilesModal(id)
						break;
						default:
							saveData(id) 
						break;
					}
					}}
				/>}
				{modalType === 'center' ? <div className={styles['actions-center']}>
					{actions}
				</div> : null}
			</main>
		</>
	)
}

const mapStateToProps = (state, ownProps) => {
	return {
		args: (state.modalsData[ownProps.id] || {}).args,
		data: (state.modalsData[ownProps.id] || {}).data,
		status: (state.modalsData[ownProps.id] || {}).status,
		errors: (state.modalsData[ownProps.id] || {}).errors
	}
}

const mapDispatchToProps = {
	pop: popModal,
	fetchData: fetchModalData,
	updateData: updateModalData,
	saveData: saveModalData,
	saveTicket: saveTicketModal,
	submitFilesModal: submitFilesModal
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ModalViewController)
