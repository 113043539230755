import { format } from '../../../utils'

const mapDetails = (json) => {
    const {
        taxType,
        taxCode,
        taxPercentage,
        taxBase,
        taxBaseDescription,
        taxAmount,
        taxAmountCurrencyCode,
        taxCurrencyAmount,
        taxExemptionReason,
        taxDeclarationPeriod,
    } = json
    return [
        [
            { label: 'Type', value: taxType, type: 'string' },
            { label: 'Declaration', value: taxDeclarationPeriod, type: 'string' },
            { label: 'code', value: taxCode, type: 'string' },
            { label: 'Base', value: taxBase, type: 'string' }
        ],
        [
            { label: 'Currency Amount', value: taxCurrencyAmount, type: 'string' },
            { label: 'Currency', value: taxAmountCurrencyCode, type: 'string' },
            { label: 'Tax', value: format(taxAmount, 'fullValue'), type: 'currency' },
            { label: 'Total', value: format(taxPercentage,'normal', null,2), type: 'percentage' }
        ],
        [
            { label: 'Reason', value: taxExemptionReason || 'N/A', type: 'string' },
            { label: 'Description', value: taxBaseDescription || 'N/A', type: 'string' }
        ]
    ]
}

const header = [
    { label: ["Tax Code"], align: 'left', columnName: "tax_code", sortable: true, basis:'15%'},
    { label: ["Tax Base Description"], align: 'left', columnName: "tax_base_description", basis:'62%' },
    { label: ["Amount"], align: 'center', columnName: "amount", sortable: true},
    { label: ["Currency Amount"], align: 'center', columnName: "currency_amount", sortable: true },
    { label: ["Currency"], align: 'center', columnName: "currency_code", sortable: true , basis:'20%'},
    { label: ["Tax Base"], align: 'right', columnName: "tax_base", sortable: true,totalizerName: "total_tax_base", basis:'20%', compact: "fullValue" },
    { label: ["Tax Percentage"], align: 'right', columnName: "tax_percentage", sortable: true }
]

const mapCard = (json) => {
    const {
        taxCode,
        taxBaseDescription,
        taxCurrencyAmount,
        taxAmountCurrencyCode,
        taxAmount,
        taxPercentage,
        taxBase
    } = json

    return [
        [
            { labels: ['code'], value: taxCode, type: 'string', basis:'15%' }
        ],
        [
            { labels: ['description'], value: taxBaseDescription, type: 'string', basis:'60%' }
        ],
        [
            { labels: ['tax'], align: 'right', value: format(taxAmount, "fullValue"), type: 'currency'}
        ],
        [
            { labels: ['currencyamount'], value: format(taxCurrencyAmount,'fullValue',null,2), type: 'number', align: 'right' }
        ],
        [
            { labels: ['currency'], value: taxAmountCurrencyCode, type: 'string', align: 'center' , basis:'20%'}
        ],
        [
            { labels: ['tax'], align: 'right', value: format(taxBase, "fullValue"), type: 'currency', basis:'20%'}
        ],
        [
            { labels: ['total'], value: format(taxPercentage, 'normal', null,2), type: 'percentage' }
        ]
    ]
}

const mapper = (json, card, details) => {
    const name = json.invoice_id || json.invoice_line_id || json.sales_invoice__id || json.sales_invoice_line__id || json.purchase_invoice_line_id || json.purchase_invoice__id || json.purchase_invoice_line__id || json.stock_movement_line_id || json.stock_movement__id || json.stock_movement_line__id || json.transaction_line_id || json.transaction__id || json.transaction_line__id

    return {
        ...json,
        __typename: json.__typename,
        id: json.id,
        name,
        shortName: name,
        details: details ? mapDetails(json) : [],
        card: card ? mapCard(json) : []
    }
}
const taxinformationMapper = {
    map: mapper,
    header
}

export default taxinformationMapper