import { format } from "../../../utils";

const mapCard = (json) => {
	const {
		AccountID,TaxonomyCode,Debit,Credit,BalDebit,BalCredit,SumBalDebit,SumBalCredit
	} = json;

	return [
		[{ labels: ["Account ID"], value: AccountID, type: "string"}],
		[{ labels: ["Taxonomy Code"], value: TaxonomyCode, type: "string"}],
		[
			{
				labels: ["Debit amount"],
				value: format(Debit, "normal", 'CVE', 2, 'pt-CV'),
				type: "currency",
			},
		],
		[
			{
				labels: ["Credit amount"],
				value: format(Credit, "normal", 'CVE', 2, 'pt-CV'),
				type: "currency",
			},
		],
		[
			{
				labels: ["Accumulated Balance Debit"],
				value: format(SumBalDebit, "normal", 'CVE', 2, 'pt-CV'),
				type: "currency",
			},
		],
		[
			{
				labels: ["Accumulated Balance Credit"],
				value: format(SumBalCredit, "normal", 'CVE', 2, 'pt-CV'),
				type: "currency",
			},
		],
		[
			{
				labels: ["Accumulated Balance Credit"],
				value: format(BalDebit, "normal", 'CVE', 2, 'pt-CV'),
				type: "currency",
			},
		],
		[
			{
				labels: ["Accumulated Balance Credit"],
				value: format(BalCredit, "normal", 'CVE', 2, 'pt-CV'),
				type: "currency",
			},
		],
	];
};

const header = [
	{ label: ['Account Id'], align: 'left' },
	{ label: ['Taxonomy'], align: 'left' },
	{ label: ['Period Debit'], align: 'right' },
	{ label: ['Period Credit'], align: 'right' },
	{ label: ['Accumulated Debit'], align: 'right' },
	{ label: ['Accumulated Credit'], align: 'right' },
	{ label: ['Debit Balance'], align: 'right' },
	{ label: ['Credit Balance'], align: 'right' }
];

const mapper = (json, card) => {
	return {
		...json,
		name: json.name,
		shortName: json.name,
		card: card ? mapCard(json) : [],
		link: {
			to: '/:locale(pt|en|lt)?/:projectId/:fiscalYear([0-9]{4}|current)/:importId/:modulePrefix/reports/nc_statement_accounts_accumulated',
			filter: [
				{ "filters": [{ "value": json.AccountID }], "column": "AccountID" },
				{ "filters": [{ "value": `0#${json.MaxPeriod}` }], "column": "Period", operator: "between" }
			]
		}
	};
};

const nctrialbalanceMapper = {
	map: mapper,
	header
};

export default nctrialbalanceMapper