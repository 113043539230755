import { checkNumberValues } from "../../../utils/convertUtils";
const mapCard = (json) => {
	const {
		value,
		description
	} = json

	return [
		[
			{ labels: ['Field Description'], value: description, type: 'string', basis: '65%' },
		],
		[
			{ labels: ['Value'], value: checkNumberValues(value), type: 'number', basis: '20%' }
		]
	]
}

const mapper = (json, card) => {
	return {
		...json,
		card: card ? mapCard(json) : []
	}
}

const header = [
	{ label: ["Field Description"], align: 'left', basis: '65%' },
	{ label: ["Value"], align: 'left', basis: '20%' },
]

const taxableincomecalculationMapper = {
	map: mapper,
	header
}
export default taxableincomecalculationMapper;