import NotFound from '../controllers/NotFound/NotFound'

const notFound = (path, navigation) => ({
	path,
	navigation,
	component: NotFound,
	exact: true,
})

export default notFound
