import { date, format } from "../../../utils";

const mapDetails = (json) => {
	const {
		SapDocNo,
		sapDocType,
		invoice_no,
		invoice_date,
		taxable_value,
		invoice_value,
		pos,
		ctin,
		customer_name,
		tax_class,
		FromGstin,
		customer_country,
		plant_region,
		shipping_bill_port_code,
		shipping_bill_number,
		shipping_bill_date,
		gross_advance_received,
		cgst_rate,
		cgst_amount,
		igst_rate,
		igst_amount,
		sgst_rate,
		sgst_amount,
		gstr1_submission_date,
	} = json;

	return [
		[
			{ label: "SAP Document No.", value: SapDocNo, type: "string" },
			{ label: "SAP Document Type", value: sapDocType, type: "string" },
			{ label: "GSTIN", value: FromGstin, type: "string" },
			{
				label: "Submission Return Period",
				value: gstr1_submission_date,
				type: "string",
			},
		],
		[
			{ label: "Invoice No.", value: invoice_no, type: "string" },
			{ label: "Invoice Date", value: date(invoice_date), type: "string" },
		],
		[
			{ label: "Customer GSTIN", value: ctin, type: "string" },
			{ label: "Customer Name", value: customer_name, type: "string" },
			{ label: "Tax Class", value: tax_class, type: "string" },
		],
		[
			{ label: "Customer Country", value: customer_country, type: "string" },
			{ label: "Place Of Supply", value: pos, type: "string" },
			{ label: "Plant Region", value: plant_region, type: "string" },
		],
		[
			{
				label: "Shipping Bill Port Code",
				value: shipping_bill_port_code,
				type: "string",
			},
			{
				label: "Shipping Bill Number",
				value: shipping_bill_number,
				type: "string",
			},
			{
				label: "Shipping Bill Date",
				value: date(shipping_bill_date),
				type: "string",
			},
		],
		[
			{
				label: "Invoice Value (₹)",
				value: format(invoice_value, null, null, 2),
				type: "string",
				align: "left",
			},
			{
				label: "Taxable Value (₹)",
				value: format(taxable_value, null, null, 2),
				type: "string",
				align: "left",
			},
			{
				label: "Gross Advance Received (₹)",
				value: gross_advance_received,
				type: "string",
			},
		],

		[
			{ label: "CGST Rate (%)", value: cgst_rate, type: "string" },
			{
				label: "CGST Amount (₹)",
				value: format(cgst_amount, null, null, 2),
				type: "string",
				align: "left",
			},
		],
		[
			{ label: "SGST Rate (%)", value: sgst_rate, type: "string" },
			{
				label: "SGST Amount (₹)",
				value: format(sgst_amount, null, null, 2),
				type: "string",
				align: "left",
			},
		],
		[
			{ label: "IGST Rate (%)", value: igst_rate, type: "string" },
			{
				label: "IGST Amount (₹)",
				value: format(igst_amount, null, null, 2),
				type: "string",
				align: "left",
			},
		],
	];
};

const mapCard = (json) => {
	const {
		SapDocNo,
		sapDocType,
		invoice_no,
		invoice_date,
		taxable_value,
		invoice_value,
		ctin,
		customer_name,
		pos,
		cgst_amount,
		igst_amount,
		sgst_amount,
	} = json;
	return [
		[
			{
				labels: ["SAP Document No."],
				value: SapDocNo,
				type: "string",
				basis: "25%",
			},
		],
		[
			{
				labels: ["SAP Document Type."],
				value: sapDocType,
				type: "string",
				basis: "25%",
			},
		],
		[{ labels: ["Invoice No."], value: invoice_no, type: "string" }],
		[{ labels: ["Invoice Date"], value: date(invoice_date), type: "string" }],
		[{ labels: ["Customer GSTIN"], value: ctin, type: "string" }],
		[{ labels: ["Customer Name"], value: customer_name, type: "string" }],
		[{ labels: ["Place of Supply"], value: pos, type: "string", basis: "10%" }],
		[
			{
				labels: ["Taxable Value (₹)"],
				value: format(taxable_value, null, null, 2),
				type: "number",
				align: "right",
			},
		],
		[
			{
				labels: ["CGST Amount (₹)"],
				value: format(cgst_amount, null, null, 2),
				type: "number",
				align: "right",
			},
		],
		[
			{
				labels: ["SGST Amount (₹)"],
				value: format(sgst_amount, null, null, 2),
				type: "number",
				align: "right",
			},
		],
		[
			{
				labels: ["IGST Amount (₹)"],
				value: format(igst_amount, null, null, 2),
				type: "number",
				align: "right",
			},
		],
		[
			{
				labels: ["Invoice Value (₹)"],
				value: format(invoice_value, null, null, 2),
				type: "number",
				align: "right",
			},
		],
	];
};

const mapper = (json, card, details) => {
	return {
		...json,
		name: json.name,
		shortName: json.name,
		details: details ? mapDetails(json) : [],
		card: card ? mapCard(json) : [],
	};
};

const header = [
	{
		label: ["SAP Document No."],
		align: "left",
		columnName: "SapDocNo",
		sortable: true,
		basis: "25%",
	},
	{
		label: ["SAP Document Type."],
		align: "left",
		columnName: "SapDocType",
		sortable: true,
		basis: "25%",
	},
	{
		label: ["Invoice No."],
		align: "left",
		columnName: "invoice_no",
		sortable: true,
	},
	{
		label: ["Invoice Date"],
		align: "left",
		columnName: "invoice_date",
		sortable: true,
	},
	{
		label: ["Customer GSTIN"],
		align: "left",
		columnName: "ctin",
		sortable: false,
	},
	{
		label: ["Customer Name"],
		align: "left",
		columnName: "customer_name",
		sortable: true,
	},
	{
		label: ["Place of Supply"],
		align: "left",
		columnName: "pos",
		sortable: false,
		basis: "10%",
	},
	{
		label: ["Taxable Value (₹)"],
		align: "right",
		columnName: "taxable_value",
		sortable: true,
	},
	{
		label: ["CGST Amount (₹)"],
		align: "right",
		columnName: "cgst_amount",
		sortable: false,
	},
	{
		label: ["SGST Amount (₹)"],
		align: "right",
		columnName: "sgst_amount",
		sortable: false,
	},
	{
		label: ["IGST Amount (₹)"],
		align: "right",
		columnName: "igst_amount",
		sortable: false,
	},
	{
		label: ["Invoice Value (₹)"],
		align: "right",
		columnName: "invoice_value",
		sortable: false,
	},
];

const gstinvoiceMapper = {
	map: mapper,
	header,
};

export default gstinvoiceMapper;
