import { date } from "../../../utils";

const mapDetails = json => {
	const {
		transactionType,
		period,
		assetTransactionDate,
		transactionId,
		assetTransactionId,
		description
	} = json;
	return [
		[
			{ label: "ID", value: assetTransactionId, type: "string" },
			{ label: "Type", value: transactionType, type: "string" },
			{ label: "Period", value: period, type: "number" },
			{ label: "Date", value: date(assetTransactionDate), type: "date" },
		],
		[
			transactionId
				? {
					label: "Transaction",
					value: {
						id: transactionId,
						name: transactionId,
						__typename: "ErpGeneralLedger"
					},
					type: "object"
				}
				: {
					label: "Transaction",
					value: assetTransactionId || "N/A",
					type: "string"
				},
				{ label: "Description", value: description, type: "string" },
		]
	];
};

const header = [
	{ label: ["ID"], align: "left", columnName: "asset_transaction_id", sortable: true },
	{ label: ["Type"], align: "left", columnName: "asset_transaction_type", sortable: true },
	{ label: ["Period"], align: "center", columnName: "period", sortable: true },
	{ label: ["Date"], align: "center", columnName: "asset_transaction_date", sortable: true },
	{ label: ["Transaction"], align: "left", columnName: "transaction_id", sortable: true },
];

const mapCard = json => {
	const {
		assetTransactionId,
		transactionType,
		period,
		assetTransactionDate,
		transactionId,
	} = json;

	return [
		[{ labels: ["id"], value: assetTransactionId, type: "string" }],
		[{ labels: ["type"], value: transactionType, type: "string" }],
		[{ labels: ["period"], value: period, type: "number" }],
		[{ labels: ["date"], value: date(assetTransactionDate), type: "date" }],
		[
			{
				labels: ["transaction"],
				value: transactionId || "N/A",
				type: "string"
			}
		],
	];
};

const mapper = (json, card, details) => {
	return {
		...json,
		__typename: json.__typename,
		id: json.id,
		name: json.asset_transaction_id,
		shortName: json.asset_transaction_id,
		details: details ? mapDetails(json) : [],
		card: card ? mapCard(json) : []
	};
};

const assettransactionMapper = {
	map: mapper,
	header
};

export default assettransactionMapper;
