import I18n from '../../I18n'

const mapCard = (json) => {
    const {
        RuleID, RuleType, Severity, Description, AppliesTo
    } = json
    return [
        [
            { labels: ['ruleId'], value: RuleID, type: 'string', basis: '20%' }
        ],
        [
            { labels: ['description'], value: Description, type: 'string', basis: '50%' }
        ],
        [
            { labels: ['severity'], value: I18n.translate(`severity-${Severity}`), type: 'number' },
        ],
        [
            { labels: ['ruleType'], value: RuleType, type: 'string' }
        ],
        [
            { labels: ['appliesTo'], value: AppliesTo, type: 'string', align: 'right' }
        ]
    ]
}

const mapper = (json, card) => {
    return {
        ...json,
        name: json.name,
        shortName: json.name,
        card: card ? mapCard(json) : []
    }
}

const header = [
    { label: ['Identifier'], align: 'left', basis: '20%', columnName: "RuleID", sortable: true },
    { label: ['Description'], align: 'left', basis: '50%', columnName: "Description", sortable: true },
    { label: ['Severity'], align: 'center', columnName: "Severity", sortable: true },
    { label: ['Rule Type'], align: 'left', columnName: "RuleType", sortable: true },
    { label: ['Refers to'], align: 'right', columnName: "AppliesTo", sortable: true }
]

const ncruleMapper = {
    map: mapper,
    header
};

export default ncruleMapper