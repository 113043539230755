import { format } from '../../../utils'


const mapDetails = () => {
    return []
};

const mapCard = (json) => {
    const {
        paymentRefNo, settlementDiscount, settlementAmount, settlementAmountCurrencyCode, settlementAmountCurrencyAmount, settlementDate, paymentMechanism, debitCreditIndicator, grossTotal
    } = json;

    return [
        [
            {labels: ['payment_ref_no'], value: paymentRefNo, type: 'number'},
        ],
        [
            {labels: ['settlement_discount'], value: settlementDiscount, type: 'string'},
        ],
        [
            {labels: ['settlement_amount'], value: format(settlementAmount, "fullValue"), type: 'number'},
        ],
        [
            {labels: ['settlement_amount_currency_code'], value: settlementAmountCurrencyCode, type: 'string'},
        ],
        [
            {labels: ['settlement_amount_currency_amount'], value: format(settlementAmountCurrencyAmount, "fullValue"), type: 'string'},
        ],
        [
            {labels: ['settlement_date'], value: settlementDate, type: 'string'},
        ],
        [
            {labels: ['payment_mechanism'], value: paymentMechanism, type: 'string'},
        ],
        [
            {labels: ['debit_credit_indicator'], value: debitCreditIndicator, type: 'string'},
        ],
        [
            {labels: ['gross_total'], value: format(grossTotal, "fullValue"), type: 'number'}
        ]
    ]
};

const mapper = (json, card, details) => {
    return {
        ...json,
        name: json.refNumber,
        shortName: json.refNumber,
        details: details ? mapDetails(json) : [],
        card: card ? mapCard(json) : []
    }
};

const header = [
    {label: ["Transaction Id"], align: 'left'},
    {label: ["Payment Ref No"], align: 'left'},
    {label: ["Settlement Discount"], align: 'left'},
    {label: ["Settlement Amount"], align: 'left'},
    {label: ["Settlement Amount Currency Code"], align: 'left'},
    {label: ["Settlement Amount Currency Amount"], align: 'left'},
    {label: ["Settlement Date"], align: 'left'},
    {label: ["Payment Mechanism"], align: 'left'},
    {label: ["Debit Credit Indicator"], align: 'left'},
    {label: ["Gross Total"], align: 'left'},
];

const paymentsettlementMapper = {
    map: mapper,
    header
}

export default paymentsettlementMapper;