import { format, date } from "../../../utils";

const header = [
    {label: ["invoice No"], align: 'left', sortable: true, columnName: "invoice_no", basis: '15%'},
    {label: ["invoice Date"], align: 'left', sortable: true, columnName: "invoice_date"},
    {label: ["gl Posting Date"], align: 'left', sortable: true, columnName: "gl_posting_date"},
    {label: ["transaction Id"], align: 'left', sortable: true, columnName: "transaction_id"},
    {label: ["amount"], align: 'left', sortable: true, columnName: "amount", basis: '13%'},
    {label: ["Currency amount"], align: 'left', sortable: true, columnName: "currency_amount"},
    {label: ["amount Currency Code"], align: 'left', sortable: true, columnName: "amount_currency_code"},
    {label: ["unpaid Amount"], align: 'left', sortable: true, columnName: "unpaid_amount", basis: '13%'},
    {label: ["Currency unpaid Amount"], align: 'left', sortable: true, columnName: "unpaid_currency_amount"},
    {label: ["unpaid Amount Currency Code"], align: 'center', sortable: true, columnName: "unpaid_amount_current_code"},
    {label: ["debit Credit Indicator"], align: 'center', sortable: true, columnName: "debit_credit_indicator"}
];

const mapCard = (json) => {
    const {
        invoiceNo,
        invoiceDate,
        glPostingDate,
        transactionId,
        amount,
        amountCurrency,
        amountCurrencyCode,
        unpaidAmount,
        unpaidAmountCurrency,
        unpaidAmountCurrencyCode,
        debitCreditIndicator
    } = json;

    return [
        [
            {labels: ['invoice No'], value: invoiceNo || 'N/A', type: 'string', basis: '15%'},
        ],
        [
            {labels: ['invoice Date'], value: date(invoiceDate) || 'N/A', type: 'date'},
        ],
        [
            {labels: ['gl Posting Date'], value: date(glPostingDate) || 'N/A', type: 'date'},
        ],
        [
            {labels: ['transaction Id'], value: transactionId || 'N/A', type: 'string'},
        ],
        [
            {labels: ['amount'], value: format(amount, "fullValue") || 'N/A', type: 'currency', basis: '13%'},
        ],
        [
            {labels: ['amount Currency'], value: format(amountCurrency,'fullValue',null,2) || 'N/A', type: 'currency'},
        ],
        [
            {labels: ['amount Currency Code'], value: amountCurrencyCode || 'N/A', type: 'string', align: 'center'},
        ],
        [
            {labels: ['unpaid Amount'], value: format(unpaidAmount, "fullValue") || 'N/A', type: 'currency', basis: '13%'},
        ],
        [
            {labels: ['unpaid Amount Currency'], value: format(unpaidAmountCurrency,'fullValue',null,2) || 'N/A', type: 'currency'},
        ],
        [
            {labels: ['unpaid Amount Currency Code'], value: unpaidAmountCurrencyCode || 'N/A', type: 'string', align: 'center'},
        ],
        [
            {labels: ['debit Credit Indicator'], value: debitCreditIndicator || 'N/A', type: 'string', align: 'center'},
        ]
    ]
};

const mapper = (json, card) => {
    const name = json.customer_id || json.supplier_id;

    return {
        ...json,
        __typename: json.__typename,
        id: json.id,
        name,
        shortName: name,
        card: card ? mapCard(json) : []
    }
};

const opensalesinvoiceMapper = {
    map: mapper,
    header
}

export default opensalesinvoiceMapper;