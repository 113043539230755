const header = [
    { label: ["Code"], align: 'left', columnName: "movement_type", sortable: true },
    { label: ["Group"], align: 'left', columnName: "tax_entity", sortable: true },
    { label: ["Description"], align: 'left', columnName: "description", sortable: true }
]

const mapCard = (json) => {
	const {
        movementType,
        taxEntity,
        description
	} = json

	return [
        [
            { labels: ['code'], value: movementType, type: 'string' }
        ],
        [
            { labels: ['group'], value: taxEntity, type: 'string' }
        ],
        [
            { labels: ['description'], value: description, type: 'string' }
        ]
	]
}

const mapper = (json, card) => {
    const name = json.movement_type
    
    return {
        ...json,
        __typename: json.__typename,
        id: json.id,
		name,
		shortName: name,
		card: card ? mapCard(json) : []
	}
}

const movementtypeMapper = {
    map: mapper,
    header
}
export default movementtypeMapper;