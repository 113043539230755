const navigation = [
	{
		name: 'Organizations',
		to: '/settings/organizations',
		exact: true,
		icon: 'projects'
	},
	{
		name: 'Projects',
		to: '/settings/projects',
		exact: true,
		icon: 'projects'
	},
	{
		name: 'Users',
		to: '/settings/users',
		exact: true,
		icon: 'users'
	},
	{
		type: 'separator'
	}
]

export const manageNavigation = (tabs) => {
	const tempNavigation = JSON.parse(JSON.stringify(navigation))

	return [...tempNavigation, ...tabs]
}

