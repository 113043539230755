import { List, rows } from 'colbi_web_ui/lib/components'
import { parseFilter } from 'colbi_web_ui/lib/utils'
import ExportButton from '../components/ExportButton/ExportButton'
import AppByFile from '../controllers/AppByFile/AppByFile'

const queries = (args = {}) => ([
	{
		resource: args.section,
		args: {
			projectId: (args.projectId),
			modulePrefix: args.modulePrefix,
			importId: args.importId,
			locale: (args.locale) || 'en',
			filter: args.filter
				? { type: "[FilterInput!]", value: parseFilter(args.filter) }
				: undefined,
			sort: args.sort
				? { type: "[SortInput!]", value: parseFilter(args.sort) }
				: undefined,
			page: parseInt(args.page, 10) || 0,
			pageSize: (args.pageSize) || 24
		},
		downloadable: true,
		body: `__typename, hasMore, total, items{...${args.section}}`
	}
])

const findingsSchemaWithoutFiscalYear = (path, navigation, exact, title) => ({
	path,
	navigation,
	component: AppByFile,
	exact,
	queries,
	title: [title],
	components: [
		{
			component: ExportButton,
			props: {
				data: "query(0).list",
			}
		},
		{
			component: List,
			modifiers: [
				'--root-margin-top-small',
				'--row-style-alternate'
			],
			props: {
				items: 'query(0).list',
				card: rows.EntityCard,
				metadata: 'query(0).metadata',
				page: 'param(page)',
				status: 'status(0)',
				pageSize: 'param(pageSize)',
				pageDefault: 10,
				footer: 'query(0).footer',
				header: 'query(0).header'
			}
		}
	]
})

export default findingsSchemaWithoutFiscalYear
