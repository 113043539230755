const navigation = {
	"repository": [
		{
			level: 0,
			priority: 1,
			name: 'label(Data Upload)',
			to: '/:projectId/repository/activity/status',
			exact: true,
			icon: 'activity'
		},
		{
			level: 0,
			priority: 2,
			name: 'label(Folders)',
			to: '/:projectId/repository/folders',
			icon: 'folders',
			navigation: 'prop(folderData)'
		},
		{
			level: 0,
			priority: 3,
			name: 'label(Invalid Files)',
			to: '/:projectId/repository/invalid_files',
			icon: 'folders',
			exact: true
		},
		{
			level: 0,
			priority: 4,
			name: 'label(Deleted Files)',
			to: '/:projectId/repository/deleted_file_logs',
			icon: 'folders',
			exact: true
		}],
	"efatura": [
		{
			level: 0,
			priority: 5,
			name: 'label(Efatura Request)',
			to: '/:projectId/repository/efatura_request',
			exact: true,
			icon: 'repository',
		},
		{
			level: 0,
			priority: 6,
			name: 'label(Efatura Deleted Requests)',
			to: '/:projectId/repository/efatura_deleted_data',
			exact: true,
			icon: 'repository',
		},
	],
	"attachments": [
		{
			level: 0,
			priority: 7,
			name: 'label(Attachments)',
			to: '/:projectId/repository/attachments',
			icon: 'folders',
			navigation: 'prop(attachmentData)',
			queries: [
				{
					resource: 'attachment_data',
					args: {
						projectId: ""
					},
					body: 'fiscalYear, count'
				},
			]
		},
	],
	"files_submission": [
		{
			level: 0,
			priority: 5,
			name: 'label(Files Submission)',
			to: '/:projectId/repository/files_submission',
			exact: true,
			icon: 'repository',
		},
	]
}

const filteredQueriesByModules = (modules) => {
	if(modules && modules.length > 0){
		const filteredRoutes = (modules || []).reduce((acc, elem) => {
			const menuArray = navigation[`${elem}`]
			const queries = (menuArray || []).filter((elem)=>elem.queries !== undefined).map(({queries}) => {
				return queries
			})
			acc.push(...queries)
			return acc
		}, [])
		return filteredRoutes.flat()
	}
	return []
}

const filteredNavigationByModules = (modules) => {
	let resNavigation = []
	if(modules && modules.length > 0){
		const filteredNavigation = (modules || []).reduce((acc, elem) => {
			const menuArray = navigation[`${elem}`]
			if( menuArray){
				acc.push(...menuArray)
			}
			return acc
		}, [])
		const orderedNavigation = filteredNavigation.sort(function(a, b){
			return a.priority - b.priority;
		  }); 
		
		resNavigation.push(...orderedNavigation)
	}
	return resNavigation
}

export {filteredNavigationByModules, filteredQueriesByModules}
