const dialog = (state = [], action) => {
	switch (action.type) {
		case 'PUSH_DIALOG': {
			const result = JSON.parse(JSON.stringify(state))
			result.push(action.payload)
			return result
		}
		case 'POP_DIALOG': {
			const result = JSON.parse(JSON.stringify(state))
			const modalIndex = state.findIndex(({ id }) => (action.payload))
			if (modalIndex >= 0) {
				result.splice(modalIndex, 1)
			}
			return result
		}
		default: break
	}
	return state
}

export default dialog