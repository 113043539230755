import { format } from '../../../utils'
const mapDetails = (json) => {
	const {
		description,
		customerId,
		supplierId,
		transactionId,
		recordId,
		debitCreditIndicator,
		debitCreditAmount,
		debitCreditCurrencyAmount,
		debitCreditCurrencyCode,
		period,
		periodYear

	} = json

	return [
		[
			{ label: 'Transaction Id', value: transactionId, type: 'string' },
			{ label: 'Record Id', value: recordId, type: 'string' },
			{ label: 'Period', value: period, type: 'string' },
			{ label: 'Period Year', value: periodYear, type: 'string' },
			{ label: 'customer Id', value: customerId, type: 'string' },
			{ label: 'Supplier Id', value: supplierId, type: 'string' },
		],
		[
			{ label: 'Debit Credit Indicator', value: debitCreditIndicator, type: 'string' },
			{ label: 'Debit Credit Amount', value: format(debitCreditAmount, 'fullValue'), type: 'currency' }
		],
		[
			{ label: 'Currency Amount', value: format(debitCreditCurrencyAmount,'fullValue',null,2), type: 'number' },
			{ label: 'Currency code', value: debitCreditCurrencyCode, type: 'string' },
		],
		[
			{ label: 'Description', value: description || 'N/A', type: 'string' }
		]
	]
}

const mapCard = json => {
	const {
		accountId,
		description,
		recordId,
		debitCreditIndicator,
		debitCreditAmount,
		debitCreditCurrencyAmount,
		debitCreditCurrencyCode
	} = json;

	return [
		[{ labels: ["Record Id"], value: recordId, align: "left", type: "string", basis: '12%' }],
		[{ labels: ["Account Id"], value: accountId || "N/A", type: "string", basis: '14%' }],
		[{ labels: ["Description"], value: description, type: "string",  basis: '70%' }],
		[{ labels: ["Debit Credit Amount"], value: format(debitCreditAmount), type: "number", align: "right", basis: "20%" }],
		[{ labels: ["Debit Credit Currency Amount"],value: format(debitCreditCurrencyAmount,'normal',null,2), type: "currency",basis:'25%' }],
		[{ labels: ["Debit Credit Currency code"], value: debitCreditCurrencyCode, type: "string", align: "center" }],
		[{ labels: ["Debit Credit Indicator"], value: debitCreditIndicator, type: "string",align: "center" }],

	];
};


const mapper = (json, card, details) => {
	return {
		...json,
		name: `${ json.accountId } - ${ json.description }`,
		shortName: json.accountId,
		details: details ? mapDetails(json) : [],
		card: card ? mapCard(json) : [],
	}
}

const header = [
	{ label: ["Record Id"], align: 'left', columnName: "record_id", sortable: true, basis: '12%' },
	{ label: ["Account Id"], align: 'left', columnName: "account_id", sortable: true, basis: '14%' },
	{ label: ["Description"], align: 'left', columnName: "description",  basis: '70%' },
	{ label: ["Amount"], align: 'right', columnName: "debit_credit_amount", sortable: true,  basis: '20%' },
	{ label: ["Currency Amount"], align: 'right',basis:'25%', columnName: "debit_credit_currency_amount", sortable: true },
	{ label: ["Currency Code "], align: 'center', columnName: "debit_credit_currency_code", sortable: true },
	{ label: ["Debit Credit Indicator"], align: 'left', columnName: "debit_credit_indicator", sortable: true },

]
const generalledgerentrylineMapper = {
	map: mapper,
	header
};

export default generalledgerentrylineMapper;