import I18n from '../../I18n'
import {  stringifyFilter  } from 'colbi_web_ui/lib/utils'
import { date } from '../../../src/utils'


const mapCard = (json) => {
    const {
        userId,fileId,
        fiscalYear,
        projectId,
        status,createdAt
    } = json

    return [
        [
            { labels: ['id'], value: fileId, type: 'string', align: 'left', basis: '15%' }
        ],
        [
            { labels: ['user'], value: userId, type: 'string', align: 'left', basis: '15%' }
        ],
       
        [
            { labels: ['fiscalYear'], value: fiscalYear, type: 'string', align: 'center' }
        ],
        [
            { labels: ['projectId'], value: projectId, type: 'string', align: 'center' }
        ],
        [
            { labels: ['createdAt'], value: date(createdAt), type: 'date', align: 'center' }
        ],
       
        [
            { labels: ['status'], value: [status, I18n.translate`${status}`], type: 'dataFileStatus', align: 'center' }
        ],
    ]
}

const mapper = (json, card) => {
    const linkToUse = `/:locale(pt|en|lt)?/:projectId/audit_efatura_findings/`
    return {
        ...json,
        id: json.id,
        name: json.name,
        shortName: json.name,
        card: card ? mapCard(json) : [],
        link: (json.status === 'imported' || 'error') && json.hasFindings === 1 ? {
            to: `${linkToUse}?filter=${stringifyFilter(
                [{ column: 'reference_id', filters: [{ value: `${json.referenceId}` }] }]
            )
                }`
        } : null
    }
}

const header = [
    { label: ['file Id'], align: 'left', columnName: "id", basis: '15%' },
    { label: ['User'], align: 'left', columnName: "requestUser", basis: '15%' },
    { label: ['fiscalYear'], align: 'center', columnName: "fiscalYear" },
    { label: ['projectId'], align: 'center', columnName: "projectId" },
    { label: ['create At'], align: 'center', columnName: "create_at" },

    { label: ['Status'], align: 'center', columnName: "status" },
  
]

const generatorptMapper = {
    map: mapper,
    header
};

export default generatorptMapper