import { checkNumberValues } from "../../../utils/convertUtils";

const mapCard = (json) => {
	const {
		line_no,
		type,
		incentive_code,
		investment_start_date,
		investment_end_date,
		type_of_investment,
		official_identification,
		eligible_region,
		cae_code,
		total_amount,
		updated_total_amount
	} = json

	return [
		[
			{ labels: ['N.º linha'], value: line_no, type: 'string', basis: "20%" },
		],
		[
			{ labels: ['Tipo'], value: type, type: 'string' },
		],
		[
			{ labels: ['N.º projeto/Código do incentivo'], value: incentive_code, type: 'string' },
		],
		[
			{ labels: ['Data de início do investimento'], value: investment_start_date, type: 'string' },
		],
		[
			{ labels: ['Data de fim do investimento'], value: investment_end_date, type: 'string' },
		],
		[
			{ labels: ['Tipologia de investimento'], value: type_of_investment, type: 'string' },
		],
		[
			{ labels: ['Identicação social do incentivo financeiro'], value: official_identification, type: 'string' },
		],
		[
			{ labels: ['Região elegível'], value: eligible_region, type: 'string' },
		],
		[
			{ labels: ['Código CAE'], value: cae_code, type: 'string' },
		],
		[
			{ labels: ['Montante total'], value: checkNumberValues(total_amount), type: 'number' },
		],
		[
			{ labels: ['Montante total atualizado'], value: checkNumberValues(updated_total_amount), type: 'number' },
		]
	]
}

const mapper = (json, card) => {
	return {
		...json,
		card: card ? mapCard(json) : []
	}
}

const header = [
	{ label: ["N.º linha"], align: 'left', basis: "20%" },
	{ label: ["Tipo"], align: 'left' },
	{ label: ["N.º projeto/Código do incentivo"], align: 'left' },
	{ label: ["Data de início do investimento"], align: 'left' },
	{ label: ["Data de fim do investimento"], align: 'left' },
	{ label: ["Tipologia de investimento"], align: 'left' },
	{ label: ["Identicação social do incentivo financeiro"], align: 'left' },
	{ label: ["Região elegível"], align: 'left' },
	{ label: ["Código CAE"], align: 'left' },
	{ label: ["Montante total"], align: 'left' },
	{ label: ["Montante total atualizado"], align: 'left' },
]
const detailsannexd29mod22Mapper ={
	map: mapper,
	header
}

export default detailsannexd29mod22Mapper;