import { format } from '../../../utils'

const header = [
    { label: ["Account"], align: 'left' },
    { label: ["Opening Debit"], align: 'right', totalizerName: 'total_opening_debit', compact: "fullValue" },
    { label: ["Closing Debit"], align: 'right', totalizerName: 'total_closing_debit', compact: "fullValue" },
    { label: ["Opening Credit"], align: 'right', totalizerName: 'total_opening_credit', compact: "fullValue"},
    { label: ["Closing Credit"], align: 'right', totalizerName: 'total_closing_credit', compact: "fullValue" }
]

const mapCard = (json) => {
    const {
        accountId,
        accountRowKey,
        openingDebit,
        openingCredit,
        closingDebit,
        closingCredit
    } = json

    return [
        [
            accountRowKey ?
                { label: 'Account', value: { id: accountRowKey, name: accountId, __typename: 'GeneralLedger' }, type: 'object' } :
                { labels: ['Account'], value: accountId || 'N/A', type: 'string' }
        ],
        [
            { labels: ['openingDebit'], value: format(openingDebit, 'fullValue'), type: 'currency' }
        ],
        [
            { labels: ['closingDebit'], value: format(closingDebit, 'fullValue'), type: 'currency' }
        ],
        [
            { labels: ['openingCredit'], value: format(openingCredit, 'fullValue'), type: 'currency' }
        ],
        [
            { labels: ['closingCredit'], value: format(closingCredit, 'fullValue'), type: 'currency' }
        ]
    ]
}

const mapper = (json, card) => {
    const name = json.accountId

    return {
        ...json,
        __typename: json.__typename,
        id: json.id,
        name,
        shortName: name,
        card: card ? mapCard(json) : []
    }
}

const accountMapper = {
    map: mapper,
    header
}

export default accountMapper