import { format } from "../../../utils"

const mapDetails = (json) => {
    const {
        issuerTaxID,
        issuerName,
        invoicesNetTotal, 
        invoicesGrossTotal,
        invoicesNetAverage, 
        invoicesMaxValue, 
        invoicesTaxAmount,
    } = json
    return [
        [
            { label: 'Supplier Tax ID', value: issuerTaxID, type: 'string' },
            { label: 'Supplier Name', value: issuerName, type: 'string' },
        ],
        [
            {
                label: 'Net Total',
                value: format(invoicesNetTotal),
                type: 'currency',
                backgroundColor: "#74AF27",
                color: '#FFFFFF',
                icon: 'sales-amount'
            },
            {
                label: 'Total Tax Amount',
                value: format(invoicesTaxAmount),
                type: 'currency',
                backgroundColor: "#8397A8",
                color: '#FFFFFF',
                icon: 'sales-amount'
            },
			{   
				label: 'Gross Total',
				value: format(invoicesGrossTotal),
				type: 'currency',
				backgroundColor: "#5B6975",
				color: '#FFFFFF',
				icon: 'sales-amount'
			},
			{
				label: 'Invoice Avg Net',
				value: format(invoicesNetAverage),
				type: 'currency',
				backgroundColor: "#8397A8",
				color: '#FFFFFF',
				icon: 'sales-amount'
			},
			{
				label: 'Invoice Max Value (G)',
				value: format(invoicesMaxValue),
				type: 'currency',
				backgroundColor: "#8397A8",
				color: '#FFFFFF',
				icon: 'sales-amount'
            },
		],
    ]
}

const mapCard = (json) => {
    const {
        issuerTaxID,
        issuerName,
        invoicesGrossTotal,
        invoicesNetTotal,
        invoicesTaxAmount
    } = json

    return [
        [{ labels: ['issuerTaxId'], align: 'left', value: issuerTaxID, type: 'string', basis: "10%" }],
        [{ labels: ['issuerName'], align: 'left', value: issuerName, type: 'string'}],
        [{ labels: ['invoicesNetTotal'], value: format(invoicesNetTotal), type: 'currency',},],
        [{ labels: ['invoicesTaxAmount'], value: format(invoicesTaxAmount), type: 'currency',},],
        [{ labels: ['invoicesGrossTotal'], value: format(invoicesGrossTotal), type: 'currency' },],
    ]
}

const mapReports = (reports) => {
	const [filledResult] = reports
	const salesData = []
	for (let i=0; i < 12; i++) {
		salesData.push({
			period: i + 1,
			value: ((filledResult || []).find(({ period }) => period === i + 1) || {}).value || 0
		})
    }
    
    return (salesData || []).length ? [
		{
            type: 'bar',
            currency: true,
			icon: 'comparation',
			title: 'Purchases (N)',
			colspan: 2,
			data: [salesData],
			links: [
                {
                    filterColumn: 'Period',
                }
            ]
		},
	] : null
}

const mapper = (json, card, details) => {
    return {
        ...json,
        name: json.refNumber,
        shortName: json.refNumber,
        card: card ? mapCard(json) : [],
        details: details ? mapDetails(json) : [],
        reports: mapReports(json.reports || []),
    }
}

const header = [
    { label: ["Supplier Tax ID"], align: 'left', columnName: "IssuerTaxID", sortable: true, basis: "10%" },
    { label: ["Supplier Name"], align: 'left', columnName: "IssuerName", sortable: true },
    { label: ["Net Total"], align: 'right', columnName: "NetTotal", sortable: true },
    { label: ["Total Tax Amount"], align: 'right', columnName: "CustomerTotalTaxAmount", sortable: true },
    { label: ["Gross Total"], align: 'right', columnName: "GrossTotal", sortable: true },
]

const supplierMapper =  {
    map: mapper,
    header
}

export default supplierMapper