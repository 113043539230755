import React, { forwardRef, useRef, useImperativeHandle, useState } from 'react'
import { useGlobalValue } from 'colbi_web_ui/lib/state/GlobalProvider'
import { FORM_ACTIONS } from '../FormActions'
import styles from './RequestUserDataForm.module.sass'
import { MultiSelectionInput, TextInput, DatePicker } from 'colbi_web_ui/lib/components/inputs'

const RequestUserDataForm = (props, ref) => {
  const { data, status, onChange, onSave, efaturainvoicetypes } = props
  const initialDates = {
    "startDate": new Date(),
    "endDate": new Date(),
  }
  const [inputs, setInputs] = useState(initialDates)
  const formRef = useRef()
  const {
    scrapperRequests = []
  } = data || {}

  const { i18n } = useGlobalValue()

  const handleChange = (e, prop, value) => {
    onChange && onChange({
      originalEvent: e,
      target: {
        value: {
          data: {
            ...data,
            [prop]: value
          }
        }
      }
    })
  }

  const handleDateChange = (e, fieldName) => {
    setInputs(inputs => ({
      ...inputs,
      [fieldName]: e
    }))
  };

  useImperativeHandle(ref, () => ({
    submit: () => {
      formRef.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))
    }
  }))

  return status !== 'loading' ? (
    <form ref={formRef} onSubmit={(e) => {
      e.preventDefault()
      e.stopPropagation()
      if (formRef.current.checkValidity()) {
        data["__typename"] = "RequestUserData"

        for (const props in inputs) {
          data[`${props}`] = inputs[props]
        }
        onSave && onSave();
      } else {
        formRef.current.reportValidity()
      }
    }}>
      <div className={styles['group-content']}>
        <div className={styles['flex']}>
          <DatePicker
            className={styles['wrap-datepicker']}
            name={i18n`Start Date`}
            value={inputs["startDate"]}
            required
            onChange={(e) => {
              handleDateChange(e, "startDate")
            }}
            dateFormat="yyyy-MM"
          />
          <DatePicker
            className={styles['wrap-datepicker']}
            name={i18n`End Date`}
            value={inputs["endDate"]}
            required
            onChange={(e) => {
              handleDateChange(e, "endDate")
            }}
            dateFormat="yyyy-MM"
          />
        </div>
        <div className={styles['input-field']}>
          {<TextInput
            className={styles['input']}
            name={i18n`Tax Authority Password`}
            placeholder={i18n`Enter password`}
            type="password"
            required
            onChange={(e) => {
              handleChange(e, 'userPassword', e.target.value)
            }}
          />}
          <MultiSelectionInput
            className={styles['input']}
            name={i18n`Efatura Request Type`}
            placeholder={i18n`-- Select an option --`}
            value={(typeof scrapperRequests === 'string' ? JSON.parse(scrapperRequests || '[]') : scrapperRequests || []).filter(p => p)}
            options={efaturainvoicetypes || []}
            onChange={(e) => {
              handleChange(e, 'scrapperRequests', e.target.value)
            }}
          />
        </div>
      </div>
    </form>
  ) : null

}


const RequestUserDataFormRef = forwardRef(RequestUserDataForm)

RequestUserDataFormRef.queries = (args) => {
  return [
    args && args.projectId ?
      {
        resource: 'efatura_invoice_types',
        args: {
          'projectId': args.projectId,
          'locale': args.locale
        },
        body: 'id, name'
      } : undefined,
  ].filter(q => q)
}

RequestUserDataFormRef.dataMapper = ['efaturainvoicetypes', 'data']

RequestUserDataFormRef.actions = [FORM_ACTIONS.SUBMIT]

export default RequestUserDataFormRef



