import {format} from '../../../utils'


const mapDetails = (json) => {

    return []
};

const mapCard = (json) => {
    const {
        paymentRefNo, analysisType, analysisId, analysisAmount, analysisAmountCurrencyCode, analysisCurrencyAmount
    } = json;

    return [
        [
            {labels: ['payment_ref_no'], value: paymentRefNo, type: 'string', basis: '12%'},
        ],
        [
            {labels: ['analysis_type'], value: analysisType, type: 'string'},
        ],
        [
            {labels: ['analysis_id'], value: analysisId, type: 'number'},
        ],
        [
            {labels: ['analysis_amount'], value: format(analysisAmount, "fullValue"), type: 'number'},
        ],
        [
            {labels: ['analysis_amount_currency_code'], value: analysisAmountCurrencyCode, type: 'string'},
        ],
        [
            {labels: ['analysis_currency_amount'], value: format(analysisCurrencyAmount, "fullValue"), type: 'number'},
        ]
    ]
};

const mapper = (json, card, details) => {
    return {
        ...json,
        name: json.refNumber,
        shortName: json.refNumber,
        details: details ? mapDetails(json) : [],
        card: card ? mapCard(json) : []
    }
};

const header = [
    {label: ["Payment Ref No"], align: 'left',columnName: "payment_ref_no", sortable: true, basis: '12%'},
    {label: ["Analysis Type"], align: 'left',columnName: "analysis_type", sortable: true},
    {label: ["Analysis Id"], align: 'left',columnName: "analysis_id", sortable: true},
    {label: ["Analysis Amount"], align: 'left',columnName: "analysis_amount", sortable: true},
    {label: ["Analysis Amount Currency Code"], align: 'left',columnName: "analysis_amount_currency_code", sortable: true},
    {label: ["Analysis Currency Amount"], align: 'left',columnName: "analysis_currency_amount", sortable: true}
];

const paymentlineanalyseMapper = {
    map: mapper,
    header
}

export default paymentlineanalyseMapper;