import { format } from "../../../utils";

const header = [
	{ label: ["Account ID"], align: "left" },
	{ label: ["Account Table ID"], align: "left" },
	{ label: ["Description"], align: "left" },
	{ label: ["Grouping Code"], align: "left" },
	{ label: ["Grouping Category"], align: "left" },
	{ label: ["Opening Debit Balance"], align: "right" },
	{ label: ["Opening Credit Balance"], align: "right" },
	{ label: ["Closing Debit Balance"], align: "right" },
	{ label: ["Closing Credit Balance"], align: "right" }
];

const mapDetails = (json) => {
	const {
		accountId,
		accountTableId,
		accountType,
		description,
		groupingCategory,
		groupingCode,
		openingDebitBalance,
		openingCreditBalance,
		closingDebitBalance,
		closingCreditBalance
	} = json
	return [
		[
			{ label: 'Account ID', value: accountId, type: 'string' },
			{ label: 'Account Table ID', value: accountTableId, type: 'string' },
			{ label: 'Account Type', value: accountType, type: 'string' },
			{ label: 'Description', value: description, type: 'string' },
			{ label: 'Account Table Description', value: accountTableId, type: 'string' },
			{ label: 'Grouping Category', value: groupingCategory, type: 'string' },
			{ label: 'Grouping Code', value: groupingCode, type: 'string' },
			{ label: 'Opening Debit Balance', value: format(openingDebitBalance), type: 'currency' },
			{ label: 'Opening Credit Balance', value: format(openingCreditBalance), type: 'currency' },
			{ label: 'Closing Debit Balance', value: format(closingDebitBalance), type: 'currency' },
			{ label: 'Closing Credit Balance', value: format(closingCreditBalance), type: 'currency' }
		]
	]
}

const mapCard = json => {
	const {
		accountId,
		accountTableId,
		description,
		groupingCode,
		groupingCategory,
		openingDebitBalance,
		openingCreditBalance,
		closingDebitBalance,
		closingCreditBalance
	} = json;

	return [
		[{ labels: ["accountId"], value: accountId || "N/A", type: "string" }],
		[
			{
				labels: ["accountTableId"],
				value: accountTableId || "N/A",
				type: "string"
			}
		],
		[{ labels: ["description"], value: description, type: "string" }],
		[{ labels: ["groupingCode"], value: groupingCode, type: "string" }],
		[{ labels: ["groupingCategory"], value: groupingCategory, type: "string" }],
		[
			{
				labels: ["openingDebitBalance"],
				value: format(openingDebitBalance),
				type: "currency"
			}
		],
		[
			{
				labels: ["openingCreditBalance"],
				value: format(openingCreditBalance),
				type: "currency"
			}
		],
		[
			{
				labels: ["closingDebitBalance"],
				value: format(closingDebitBalance),
				type: "currency"
			}
		],
		[
			{
				labels: ["closingCreditBalance"],
				value: format(closingCreditBalance),
				type: "currency"
			}
		]
	];
};

const mapper = (json, card,details) => {
	const name = json.account_id;

	return {
		...json,
		__typename: json.__typename,
		id: json.id,
		name,
		shortName: name,
		details: details ? mapDetails(json) : [],
		card: card ? mapCard(json) : []
	};
};

const generalledgeraccountMapper = {
	map: mapper,
	header
};

export default generalledgeraccountMapper;
