import { date } from '../../../utils'

const header = [
    {label: ["invoice No"], align: 'left'},
    {label: ["invoice Date"], align: 'left'},
    {label: ["gl Posting Date"], align: 'left'},
    {label: ["transaction Id"], align: 'left', basis: '35%'},
    {label: ["amount"], align: 'left'},
    {label: ["amount Currency"], align: 'left'},
    {label: ["amount Currency Code"], align: 'left'},
    {label: ["unpaid Amount"], align: 'left'},
    {label: ["unpaid Amount Currency"], align: 'left'},
    {label: ["unpaid Amount Currency Code"], align: 'left'},
    {label: ["debit Credit Indicator"], align: 'left'}
];

const mapCard = (json) => {
    const {
        invoiceNo,
        invoiceDate,
        glPostingDate,
        transactionId,
        amount,
        amountCurrency,
        amountCurrencyCode,
        unpaidAmount,
        unpaidAmountCurrency,
        unpaidAmountCurrencyCode,
        debitCreditIndicator
    } = json;

    return [
        [
            {labels: ['invoice No'], value: invoiceNo || 'N/A', type: 'string'},
        ],
        [
            {labels: ['invoice Date'], value: date(invoiceDate) || 'N/A', type: 'date'},
        ],
        [
            {labels: ['gl Posting Date'], value: date(glPostingDate) || 'N/A', type: 'date'},
        ],
        [
            {labels: ['transaction Id'], value: transactionId || 'N/A', type: 'string', basis: '35%'},
        ],
        [
            {labels: ['amount'], value: amount || 'N/A', type: 'string'},
        ],
        [
            {labels: ['amount Currency'], value: amountCurrency || 'N/A', type: 'string'},
        ],
        [
            {labels: ['amount Currency Code'], value: amountCurrencyCode || 'N/A', type: 'string'},
        ],
        [
            {labels: ['unpaid Amount'], value: unpaidAmount || 'N/A', type: 'string'},
        ],
        [
            {labels: ['unpaid Amount Currency'], value: unpaidAmountCurrency || 'N/A', type: 'string'},
        ],
        [
            {labels: ['unpaid Amount Currency Code'], value: unpaidAmountCurrencyCode || 'N/A', type: 'string'},
        ],
        [
            {labels: ['debit Credit Indicator'], value: debitCreditIndicator || 'N/A', type: 'string'},
        ]
    ]
};

const mapper = (json, card) => {
    const name = json.customer_id || json.supplier_id;

    return {
        ...json,
        __typename: json.__typename,
        id: json.id,
        name,
        shortName: name,
        card: card ? mapCard(json) : []
    }
};

const openpurchaseinvoiceMapper = {
    map: mapper,
    header
}

export default openpurchaseinvoiceMapper;