import {format,date} from '../../../utils'

const mapDetails = (json) => {
    const {
        movementDate, movementType, lineNumber, accountId, transactionId, customerId, supplierIdd, productCode, quantity, unitOfMeasure, uomtoUomphysicalStockConversionFactor, bookValue, movementSubType, movementComments, debitCreditIndicator
    } = json;
    return [
        [
            {label: 'Movement Date', value: date(movementDate), type: 'string'},
            {label: 'Movement Type', value: movementType, type: 'string'},
            {label: 'Movement SubType', value: movementSubType, type: 'string'},
            {label: 'Movement Comment', value: movementComments, type: 'string'},
        ],
        [
            {label: 'customer ID', value: customerId, type: 'string'},
            {label: 'supplier ID', value: supplierIdd, type: 'string'}
        ],
        [
            {label: 'Line Number', value: lineNumber, type: 'string'},
            {label: 'Account ID', value: accountId, type: 'string'},
            {label: 'Transaction ID', value: transactionId, type: 'string'},
            {label: 'Product Code', value: productCode, type: 'string'},
        ],[
            {label: 'Quantity', value: format(quantity, 'normal', null), type: 'string'},
            {label: 'Unit Of Measure', value: unitOfMeasure, type: 'string'},
            {label: 'UOM to UOM physical Stock Conversion Factor', value: uomtoUomphysicalStockConversionFactor, type: 'string'},
            {label: 'Book Value', value: format(bookValue, 'fullValue'), type: 'currency'},
            {label: 'Debit Credit Indicator', value: debitCreditIndicator, type: 'string'}
        ]
    ]
};

const mapCard = (json) => {
    const {
        lineNumber, transactionId, quantity, bookValue,debitCreditIndicator
    } = json;

    return [
        [
            {labels: ['Line Number'], value: `${lineNumber}`, type: 'string'}
        ],
        [
            {labels: ['Transaction Id'], value: `${transactionId}`, type: 'string'}
        ],
        [
            {labels: ['Book Value'], value: format(bookValue), type: 'number'}
        ],
        [
            {labels: ['Quantity'], value: format(quantity, 'normal', null), type: 'number'}
        ],
        [
            {labels: ['Debit Credit Indicator'], value: debitCreditIndicator, type: 'string'}
        ]
    ]
};


const mapper = (json, card, details) => {

    return {
        ...json,
        name: json.description,
        shortName: json.lineNumber,
        details: details ? mapDetails(json) : [],
        card: card ? mapCard(json) : []
    }
};

const header = [
    {label: ['Line Number'], align: 'left', columnName: "line_number", sortable: true},
    {label: ['Transaction Id'], align: 'left', columnName: "transaction_id", sortable: true,basis: '25%'},
    {label: ['Book Value'], align: 'left',basis: '23%', columnName: "book_value", sortable: true},
    {label: ['Quantity'], align: 'left',basis: '14%', columnName: "quantity", sortable: true},
    {label: ['Debit Credit Indicator'], align: 'left', columnName: "debit_credit_indicator", sortable: true},
    
];
const movementofgoodlineMapper = {
    map: mapper,
    header
}

export default movementofgoodlineMapper;