import Unauthorized from '../controllers/Unauthorized/Unauthorized'

const unauthorized = (path, navigation) => ({
	path,
	navigation,
	component: Unauthorized,
	exact: true,
})

export default unauthorized
