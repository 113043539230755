import { format, date } from '../../../utils'

const mapper = (json, card, details) => {
	const {
		__typename,
		id,
		code,
		description,
		taxCountryRegion,
		taxExpirationDate,
		taxPercentage
	} = json
	return {
		__typename,
		id: id,
		card: [
			[
				{ labels: ['code', 'description'], value: `${ code } - ${ description }`, type: 'string' }
			],
			[
				{ labels: ['country', 'region'], value: taxCountryRegion, type: 'string' }
			],
			[
				{ labels: ['expiration'], value: date(taxExpirationDate), type: 'date' }
			],
			[
				{ labels: ['percentage'], value: format((parseFloat(taxPercentage) || 0).toFixed(2), 'normal', null), type: 'percentage' }
			]
		]
	}
}

const header = [
	{ label: ['ID - Description'], align: 'left' },
	{ label: ['Country/Region'], align: 'left', columnName: "tax_country_region", sortable: true },
	{ label: ['Expiration'], align: 'center' },
	{ label: ['Percentage'], align: 'right', columnName: "tax_percentage", sortable: true },
]

const taxtableMapper = {
	map: mapper,
	header
}

export default taxtableMapper;