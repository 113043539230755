import { date } from "../../../utils"

const mapDetails = (json) => {
	const {
		taxRegistrationNumber,
		startDate,
		endDate,
		taxAccountingBasis,
		defaultCurrencyCode,
		softwareCompanyName,
		softwareVersion,
		softwareId,
		taxEntity,
		accountId
	} = json

	return [
		[
			{ label: 'Registration Number', value: taxRegistrationNumber, type: 'string' },
			{ label: 'Entity', value: taxEntity, type: 'string' },
			{ label: 'Start Date', value: date(startDate), type: 'date' },
			{ label: 'End Date', value: date(endDate), type: 'date' },
			{ label: 'Tax Accounting Basis', value: taxAccountingBasis, type: 'string' },
			{ label: 'Default Currency Code', value: defaultCurrencyCode, type: 'string' },
			{ label: 'System', value: softwareCompanyName, type: 'string' },
			{ label: 'System Version', value: softwareVersion, type: 'string' },
			{ label: 'System ID', value: softwareId, type: 'string' },
			{ label: 'Account ID', value: accountId, type: 'string' },
		]
	]
}

const mapCard = (json) => {
	const {
		taxRegistrationNumber,
		businessName,
		startDate,
		endDate,
		fileName,
		fileId
	} = json

	return [
		[
			{ labels: ['nif'], value: `${ taxRegistrationNumber }`, type: 'string' }
		],
		[
			{ labels: ['business_name'], value: `${ businessName ? businessName : 'N/A' }`, type: 'string' }
		],
		[
			{ labels: ['file_name'], value: { id: fileId , name: fileName, __typename: 'FileDetail' }, type: 'object' }
		],
		[
			{ labels: ['start_date'], value: date(startDate), type: 'date' }
		],
		[
			{ labels: ['end_date'], value: date(endDate), type: 'date' }
		]
	]
}

const mapper = (json, card, details) => {
	
	return {
		...json,
		details: details ? mapDetails(json) : [],
		card: card ? mapCard(json) : []
	}
}

const header = [
	{ label: ['Registration Number'], align: 'left' },
	{ label: ['Business'], align: 'left', columnName: "company_name", sortable: true },
	{ label: ['FileName'], align: 'left'},
	{ label: ['Start'], align: 'center', columnName: "fiscal_year_from", sortable: true },
	{ label: ['End'], align: 'center', columnName: "fiscal_year_to", sortable: true },
]
const headerMapper = {
	map: mapper,
	header
}

export default headerMapper;