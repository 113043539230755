import {format, date} from '../../../utils'
const mapDetails = (json) => {
    const {
        paymentRefNo, transactionId, transactionDate, paymentMethod, bankAccountIbanNumber, bankAccountNumber, description, systemId,grossTotal
    } = json;

    return [
        [
            {label: 'Transaction ID', value: transactionId, type: 'string'},
            {label: 'Payment Reference No', value: paymentRefNo, type: 'string'},
            {label: 'Transaction Date', value: date(transactionDate), type: 'string'},
        ],
        [
            {label: 'Payment Method', value: paymentMethod, type: 'string'},
            {label: 'Bank Account IBAN Number', value: bankAccountIbanNumber, type: 'string'},
            {label: 'Bank Account Number', value: bankAccountNumber, type: 'string'},
            {label: 'System ID', value: systemId, type: 'string'},
            {label: 'Gross Total', value: format(grossTotal, 'fullValue'), type: 'currency'}
        ], [
            {label: 'Description', value: description, type: 'string'},
        ]
    ]
};

const mapCard = (json) => {
    const {
        paymentRefNo, transactionId, transactionDate, paymentMethod,grossTotal,systemId
    } = json;

    return [
        [
            {labels: ['transaction_id'], value: transactionId, type: 'string'}
        ],
        [
            {labels: ['payment_ref_no'], value: paymentRefNo, type: 'string'}
        ],
        [
            {labels: ['system_id'], value: systemId, type: 'string'}
        ],
        [
            {labels: ['transaction_date'], value: date(transactionDate), type: 'date', align:'center'}
        ],
        [
            {labels: ['payment_method'], value: paymentMethod, type: 'string'}
        ],
        [
            {labels: ['gross_total'], value: format(grossTotal), type: 'currency'}
        ]
    ]
};

const mapper = (json, card, details) => {
    return {
        ...json,
        name: json.refNumber,
        shortName: json.refNumber,
        details: details ? mapDetails(json) : [],
        card: card ? mapCard(json) : []
    }
};

const header = [
    {label: ["Transaction Id"], align: 'left', columnName: "transaction_id", sortable: true},
    {label: ["Payment Reference No"], align: 'left', columnName: "payment_ref_no", sortable: true},
    {label: ["System Id"], align: 'left', columnName: "system_id", sortable: true},
    {label: ["Transaction Date"], align: 'center', columnName: "transaction_date", sortable: true},
    {label: ["Payment Method"], align: 'left', columnName: "payment_method", sortable: true},
    {label: ["Gross Total"], align: 'right', columnName: "gross_total", sortable: true}
];

const paymentMapper = {
    map: mapper,
    header
}

export default paymentMapper;