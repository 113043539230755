import { format } from '../../../utils'

const mapDetails = (json) => {
	const {
        type,
        status,
        warehouseId,
        productId,
        unitMeasure,
        
        openingQuantity,
        closingQuantity,
        unitPrice,
        openingValue,
        closingValue,

        stockOwner,
        stockOwnerId,
        numberOfCustomsProcedures,
        uomBaseConversionFactor,
        numberOfStockCharacteristics,
        numberOfPhysicalStockAcquisitions
	} = json
	return [
		[
            { label: 'Type', value: type, type: 'string' },
            { label: 'Status', value: status, type: 'string' },
            { label: 'Warehouse ID', value: warehouseId, type: 'string' },
            { label: 'Product Id', value: productId, type: 'string' },
            { label: 'Unit of Measure', value: unitMeasure, type: 'string' }
        ],
        [
            { label: 'Unit Price', value: format(unitPrice), type: 'currency' },
            { label: 'Opening Value', value: format(openingValue), type: 'currency' },
            { label: 'Closing Value', value: format(closingValue), type: 'currency' }
        ],
        [
            { label: 'Opening Quantity', value: openingQuantity, type: 'number' },
            { label: 'Closing Quantity', value: closingQuantity, type: 'number' },
            { label: 'Stock Owner', value: stockOwner, type: 'string' },
            { label: 'Stock Owner Id', value: stockOwnerId, type: 'string' },
            { label: 'Number of Customs Procedures', value: numberOfCustomsProcedures, type: 'string' },
            { label: 'UOM Base Conversion Factor', value: uomBaseConversionFactor, type: 'string' },
            { label: 'Number of Stock Characteristics', value: numberOfStockCharacteristics, type: 'string' },
            { label: 'Number of Physical Stock Acquisitions', value: numberOfPhysicalStockAcquisitions, type: 'string' }

        ]
	]
}

const header = [
    { label: ["[Type]"], align: 'left'},
    { label: ["Status"], align: 'left'},
    { label: ["Unit of Measure"], align: 'left' },
    { label: ["Opening Quantity"], align: 'center' },
    { label: ["Closing Quantity"], align: 'center' },
    { label: ["Unit Price"], align: 'right' },
    { label: ["Opening Value"], align: 'right' },
    { label: ["Closing Value"], align: 'right' }
]

const mapCard = (json) => {
	const {
        type,
        status,
        unitMeasure,
        unitPrice,
        openingValue,
        closingValue,
        openingQuantity,
        closingQuantity
	} = json

	return [
        [
            { labels: ['type'], value: type || 'N/A', type: 'string' }
        ],
        [
            { labels: ['status'], value: status, type: 'string' }
        ],
        [
            { labels: ['uom'], value: unitMeasure, type: 'string' }
        ],
        [
            { labels: ['opening_quantity'], value: openingQuantity, type: 'number' }
        ],
        [
            { labels: ['closing_quantity'], value: closingQuantity, type: 'number' }
        ],
        [
            { labels: ['unit_price'], value: format(unitPrice), type: 'currency' }
        ],
        [
            { labels: ['opening_value'], value: format(openingValue), type: 'currency' }
        ],
        [
            { labels: ['closing_value'], value: format(closingValue), type: 'currency' }
        ]
	]
}

const mapper = (json, card, details) => {
    const name = json.product_code
    
    return {
        ...json,
        __typename: json.__typename,
        id: json.id,
		name,
        shortName: name,
        details: details ? mapDetails(json) : [],
		card: card ? mapCard(json) : []
	}
}

const physicalstockMapper = {
    map: mapper,
    header
}

export default physicalstockMapper;