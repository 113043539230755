import { StackLayout } from "../layouts";
import {
	List,
	headers,
	Tabs,
	Filters,
	DynamicReportsGrid,
	rows,
	RawData
} from "colbi_web_ui/lib/components";
import theme from "colbi_web_ui/lib/style/_theme.json";
import {filteredNavigationByModules}  from "../controllers/Repository/navigation"
import AppByFile from "../controllers/AppByFile/AppByFile";

const camelize = str => {
	return (str || "").replace(/_([a-z])/g, v => v[1].toUpperCase());
};

const getEntityName = entity => {
	return (entity || "").substring(0, (entity || "").length - 1);
};

const queries = (args = {}) => {
	const entities = (args.entities || "").split("/");
	const entityType = entities[0]
	const entityId = args.id
	const entity = getEntityName(entityType);
	return [
		{
			resource: entity,
			args: {
				projectId: args.projectId,
				fiscalYear: args.fiscalYear,
				[`${camelize(entity)}Id`]: entityId,
				locale: (args.locale) || 'en',
				modulePrefix: args.modulePrefix
			},
			body: `...${entity}`
		},
	].filter(q => !!q.resource);
};

const entityWithoutHeader = (path, exact, title) => ({
	path,
	navigation: filteredNavigationByModules(['repository']),
	component: AppByFile,
	queries,
	exact,
	title: [title, "label(query(0).__typename)", "query(0).shortName"].filter(
		l => l
	),
	components: [
		{
			component: RawData,
			props: {
				label: "label(Raw Data)",
				data: "query(0).raw_data"
			}
		},
		{
			component: headers.EntityHeader,
			modifiers: ["--root-margin-bottom-normal"],
			props: {
				type: "query(0).__typename",
				data: "query(0).details"
			}
		},
		{
			component: DynamicReportsGrid,
			modifiers: [
				"--root-margin-bottom-normal",
				"--cardholder-background-color-widget",
				"--cardholder-aspect-ratio-16x9"
			],
			props: {
				type: "query(0).__typename",
				data: "query(0).reports"
			}
		},
		{
			component: Filters,
			props: {
				filter: "param(filter)",
				availableFilters: "query(1).filters",
				stickyTop: parseInt(theme.layout.$headerHeight, 10) + 40,
				anchor: "results"
			}
		},
		{
			component: Tabs,
			modifiers: ["--root-margin-top-small", "--root-margin-bottom-small"],
			props: {
				basePath: `${path}`,
				showCount: true,
				data: "query(0).entities",
				labelKey: "entity",
				routeKey: "model",
				redirectEnabler: true,
				stickyTop: Math.min(39, parseInt(theme.layout.$headerHeight, 10) || 39)
			}
		}
	],
	routes: [
		{
			path,
			exact: true,
			queries: queries,
			component: StackLayout,
			components: [
				{
					component: List,
					modifiers: ["--row-style-alternate"],
					props: {
						basePath: path,
						header: "query(1).header",
						sort: "param(sort)",
						items: "query(1).list",
						card: rows.EntityCard,
						handleNoResults: true,
						metadata: "query(1).metadata",
						page: "param(page)",
						status: "status(0)",
						pageSize: "param(pageSize)",
						pageDefault: 10,
						footer: "query(1).footer",
						totalizers: 'query(1).totalizers'
					}
				}
			]
		}
	]
});

export default entityWithoutHeader;
