import { checkNumberValues } from "../../../utils/convertUtils";

const mapDetails = (json) => {
	const {
		nif,
		period,
		board602LucroTributVelTotalCampo302313DoQ09Art36ADoEbf4,
		board602LucroTributVelNaZonaFrancaDaMadeiraCampo313DoQ0916,
		board602MassaSalarialTotal28,
		board602O1AnoDeAplicaODoRegime40,
		board602TotalDaReaDeInstalaOOuExploraOHa241,
		board602TotalDaPotNciaInstaladaMw53,
		board602TotalDaEletricidadeProduzidaGwh65,
		board602TotalDaProduOBocaDaMinaEmEuros77,
		board602TotalDaMassaSalarialPrestaEsDeServiOs89,
		board602SomaAlgBricaMatRiaColetVelRegEspecialELucroTributVelRegGeral101,
		board699DerramaCalculadaATransportarParaC364DoQ10DaDeclaraO10,
		board699DerramaCalculadaATransportarParaC364DoQ10DaDeclaraO22,
		board699DerramaCalculadaATransportarParaC364DoQ10DaDeclaraO34,
		board699DerramaCalculadaATransportarParaC364DoQ10DaDeclaraO46,
		board699CritRioGeralMassaSalarialTotal58,
		board699CritRioGeralZfmMassaSalarialTotal70,
	} = json
	return [
		[
			{ label: 'NIF', value: nif, type: 'string' },
			{ label: 'Período', value: period, type: 'string' },
		],
		{
			title: "Q03 - Informação Relevante",
			rows: [
				[
					{
						label: "Q03-1 - Dados Gerais - Lucro tributável total(campo 302 + 313 do Q. 09)",
						value: checkNumberValues(board602LucroTributVelTotalCampo302313DoQ09Art36ADoEbf4),
						type: "number"
					},
					{
						label: "Q03-2 - Dados Gerais - Lucro tributável na ZFM (campo 313 do Q. 09) (art.º 36.º-A do EBF)",
						value: checkNumberValues(board602LucroTributVelNaZonaFrancaDaMadeiraCampo313DoQ0916),
						type: "number"
					},
					{
						label: "Q03-10 - Dados Gerais - Soma da matéria coletável do regime especial e do lucro tributável do regime geral (campo 300 + campo 302)",
						value: checkNumberValues(board602SomaAlgBricaMatRiaColetVelRegEspecialELucroTributVelRegGeral101),
						type: "number"
					},
					{
						label: "Q03-3 - Dados Gerais - Massa salarial total",
						value: checkNumberValues(board602MassaSalarialTotal28),
						type: "number"
					}

				],
				[
					{
						label: "Q03-4 - Dados específicos  - É o 1.º ano de aplicação do regime?",
						checkboxLabel: "Declaração do grupo",
						value: checkNA(board602O1AnoDeAplicaODoRegime40),
						type: "checkbox"
					},
					{
						label: "Q03-5 - Dados Gerais - Total da área de instalação ou exploração (ha2)",
						value: checkNumberValues(board602TotalDaReaDeInstalaOOuExploraOHa241),
						type: "number"
					},
					{
						label: "Q03-6 - Dados Gerais - Total da potência instalada (MW)",
						value: checkNumberValues(board602TotalDaPotNciaInstaladaMw53),
						type: "number"
					},
					{
						label: "Q03-7 - Dados Gerais - Total da eletricidade produzida (GWh)",
						value: checkNumberValues(board602TotalDaEletricidadeProduzidaGwh65),
						type: "number"
					},
					{
						label: "Q03-8 - Dados Gerais - Valor total da produção à boca da mina (em euros)",
						value: checkNumberValues(board602TotalDaProduOBocaDaMinaEmEuros77),
						type: "number"
					},
					{
						label: "Q03-9 - Dados Gerais - Total da massa salarial + prestações de serviços",
						value: checkNumberValues(board602TotalDaMassaSalarialPrestaEsDeServiOs89),
						type: "number"
					}
				]
			]
		},
		{
			title: "Q04 - Cálculo da derrama Municipal",
			rows:
				[
					[
						{
							label: "Q04-04A-7 - Critério Geral - Massa salarial total",
							value: checkNumberValues(board699CritRioGeralMassaSalarialTotal58),
							type: "number"
						},
						{
							label: "Q04-04A-6  - Critério Geral - derrama calculada (a transportar para C.364 do Q.10 da declaração)",
							value: checkNumberValues(board699DerramaCalculadaATransportarParaC364DoQ10DaDeclaraO10),
							type: "number"
						}
					],
					[
						{
							label: "Q04-04B-7  - Critério Geral (Madeira) - Massa salarial total",
							value: checkNumberValues(board699CritRioGeralZfmMassaSalarialTotal70),
							type: "number"
						},
						{
							label: "Q04-04B-6  - Critério Geral (Madeira) - Derrama calculada (a transportar para C.364 do Q.10 da declaração)",
							value: checkNumberValues(board699DerramaCalculadaATransportarParaC364DoQ10DaDeclaraO22),
							type: "number"
						}
					],
					[
						{
							label: "Q04-04C-9  - Critério Especifico (Centros eletroprodutores) - Derrama calculada (a transportar para C.364 do Q.10 da M22)",
							value: checkNumberValues(board699DerramaCalculadaATransportarParaC364DoQ10DaDeclaraO34),
							type: "number"
						}
					],
					[
						{
							label: "Q04-04D-8 - Critério Especifico (Minas) - Derrama calculada (a transportar para C 364 do Q10 da declaração)",
							value: checkNumberValues(board699DerramaCalculadaATransportarParaC364DoQ10DaDeclaraO46),
							type: "number"
						}
					]
				]
		}
	]
}

const mapper = (json, card, details) => {
	return {
		...json,
		name: json.declarationType,
		shortName: `${json.period}`,
		details: details ? mapDetails(json) : []
	};
};

const checkNA = string => {
	if (string !== "N/A" && parseInt(string, 10) > 0) {
		return 1;
	}
	return 0;
};

const mod22aannexMapper = {
	map: mapper
};

export default mod22aannexMapper ;