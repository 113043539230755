import { logoutSuccess } from '../store/actions/userActions'

export function errorManagement(err, options) {
	if(!err) return

    const {locale, dispatch} = options

    if( Array.isArray(err.errors) ){
        for(let error of err.errors){
            switch (error.code) {
                case "GRAPHQL_VALIDATION_FAILED":
                case "GRAPHQL_PARSE_FAILED":
                case "404":
                    return window.location.href = `/${locale || 'en'}/not_found`;
                case "403":
                    return dispatch && dispatch(logoutSuccess())
                case "401":
                    return window.location.href = `/${locale || 'en'}/unauthorized`;
                default:
                    break;
            }
        }
    }
}