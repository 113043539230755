import { format } from '../../../utils'

const mapDetails = json => {
    const { 
        assetId,
        valuationType,
        valuationClass,
        accountId,
        aquisitionAndProductionCost,
        aquisitionAndProductionCostSe,
        assetLifeYears,
        assetLifeMonths,
        bookValueBegin,
        depreciationForPeriod,
        bookValueEnd,
        acucumulatedDepreciation,
        depreciationMethod
    } = json;

    return [
        [
            {label: 'Asset Id',value: assetId,type: 'string'},
            {label: 'Valuation Type',value: valuationType,type: 'string'},
            {label: 'Valuation Class',value: valuationClass,type: 'string'},
            {label: 'Account Id', value: accountId, type: 'string'},
        ],
        [
            {label: 'Aquisition And Production Cost', value: format(aquisitionAndProductionCost, 'fullValue'), type: 'currency'},
            {label: 'Aquisition And Production Cost Se', value: format(aquisitionAndProductionCostSe, 'fullValue'), type: 'currency'},
            {label: 'Asset Life Years',value: format(assetLifeYears, null, null, 0),type: 'number'},
            {label: 'Asset Life Months',value: format(assetLifeMonths, null, null, 0),type: 'number'},
            {label: 'Book Value Begin', value: format(bookValueBegin, 'fullValue'), type: 'currency'},
            {label: 'Book Value End', value: format(bookValueEnd, 'fullValue'), type: 'currency'},
            {label: 'Depreciation For Period', value: format(depreciationForPeriod, 'fullValue'), type: 'currency'},
            {label: 'Acumulated Depreciation',value: format(acucumulatedDepreciation, 'fullValue'),type: 'currency'},
            {label: 'Depreciation Method',value: depreciationMethod,type: 'string'},
        ],
    ];
};

const header = [
    { label: ["Asset Id"], align: 'left',columnName: "asset_id" },
    { label: ["Class"], align: 'left',columnName: "valuation_class",sortable: true },
    { label: ["Account Id"], align: 'left' ,columnName: "account_id",sortable: true},
    { label: ["Asset Life Years"], align: 'left',columnName: "asset_life_years",sortable: true },
    { label: ["Asset Life Months"], align: 'left',columnName: "asset_life_months",sortable: true }
]

const mapCard = (json) => {
	const {
        assetId,
        valuationClass,
        accountId,
        assetLifeYears,
        assetLifeMonths
	} = json

	return [
        [
            { labels: ['Class'], value: assetId, type: 'string' }
        ],
        [
            { labels: ['Class'], value: valuationClass, type: 'string' }
        ],
        [
            { labels: ['Account'], value: accountId || 'N/A', type: 'string' }
        ],
        [
            { labels: ['bookValueBegin'], value: format(assetLifeYears, null, null), type: 'number' }
        ],
        [
            { labels: ['bookValueEnd'], value: format(assetLifeMonths, null, null), type: 'number' }
        ]
	]
}

const mapper = (json, card, details) => {
    const name = json.assetId
    
    return {
        ...json,
        __typename: json.__typename,
		name,
        shortName: name,
        card: card ? mapCard(json) : [],
        details: details ? mapDetails(json) : [],
	}
}
const assetvaluationMapper = {
    map: mapper,
    header
}

export default assetvaluationMapper;