import { format } from "../../utils"

const formatLabelValue=(value)=>{
	return value  && value !== 0 ? format(value, 'normal', 'EUR', 2) : "-";
}

const mapCard = (json) => {
	const {
        taxCodes,
		totalValue,
		JanValue,
		FevValue,
		MarValue,
		AprValue,
		MayValue,
		JunValue,
		JulValue,
		AugValue,
		SetValue,
		OutValue,
		NovValue,
		DezValue,
		Description,
		Code
	} = json

	return [
		[
			{ labels: ['Vat Codes'], value: taxCodes, type: 'string', basis: "30%" }

		],
		[
			{ labels: ['Code'], value:  Code, type: 'string', align: 'center', basis: "10%" }

		],
		[
			{ labels: ['SubType'], value:  Description, type: 'string', align: 'left', basis: "40%" }

		],
		[
			{ labels: ['Taxable Value'], value: formatLabelValue(JanValue), type: 'currency' }

		],
		[
			{ labels: ['Taxable Value'], value: formatLabelValue(FevValue), type: 'currency' }

		],
		[
			{ labels: ['Taxable Value'], value: formatLabelValue(MarValue), type: 'currency' }

		],
		[
			{ labels: ['Taxable Value'], value: formatLabelValue(AprValue), type: 'currency' }

		],
		[
			{ labels: ['Taxable Value'], value: formatLabelValue(MayValue), type: 'currency' }

		],
		[
			{ labels: ['Taxable Value'], value: formatLabelValue(JunValue), type: 'currency' }

		],
		[
			{ labels: ['Taxable Value'], value: formatLabelValue(JulValue), type: 'currency' }

		],
		[
			{ labels: ['Taxable Value'], value: formatLabelValue(AugValue), type: 'currency' }

		],
		[
			{ labels: ['Taxable Value'], value: formatLabelValue(SetValue), type: 'currency' }

		],
		[
			{ labels: ['Taxable Value'], value: formatLabelValue(OutValue), type: 'currency' }

		],
		[
			{ labels: ['Taxable Value'], value: formatLabelValue(NovValue), type: 'currency' }

		],
		[
			{ labels: ['Taxable Value'], value: formatLabelValue(DezValue), type: 'currency' }

		],
        [
			{ labels: ['Total'], value: formatLabelValue(totalValue), type: 'currency' }

		],
	]
}

const header = [
	{ label: ['Tax Codes'], align: 'left', basis: "30%" },
	{ label: ['FR600 Code'], align: 'center', basis: "10%" },
	{ label: ['Description'], align: 'left', basis: "40%" },
	{ label: ['Jan'], align: 'right' },
	{ label: ['Feb'], align: 'right' },
	{ label: ['Mar'], align: 'right' },
	{ label: ['Apr'], align: 'right' },
	{ label: ['May'], align: 'right' },
	{ label: ['Jun'], align: 'right' },
	{ label: ['Jul'], align: 'right' },
	{ label: ['Aug'], align: 'right' },
	{ label: ['Sep'], align: 'right' },
	{ label: ['Oct'], align: 'right' },
	{ label: ['Nov'], align: 'right' },
	{ label: ['Dec'], align: 'right' },
	{ label: ['Total For the Whole Period'], align: 'right' },
]

const mapper = (json, card) => {
	return {
		...json,
		name: json.name,
		shortName: json.name,
		card: card ? mapCard(json) : [],
	}
}

const ltvatreportMapper = {
	map: mapper,
	header
}
export default ltvatreportMapper;