const mapDetails = (json) => {
    const {
        movementReference,movementDate,movementType,lineNumber,taxType,taxCode,taxPercentage,taxBase,taxBaseDescription,taxAmount,taxAmountCurrencyCode,taxCurrencyAmount,taxExemptionReason,taxDeclarationPeriod
    } = json;
    return [
        [
            {label: 'movement Reference', value: movementReference, type: 'string'},
            {label: 'movement Date', value: movementDate, type: 'string'},
            {label: 'movement Type', value: movementType, type: 'string'},
            {label: 'line Number', value: lineNumber, type: 'string'},
            {label: 'tax Type', value: taxType, type: 'string'},
            {label: 'tax Type', value: taxType, type: 'string'},
            {label: 'tax Code', value: taxCode, type: 'string'},
            {label: 'tax Percentage', value: taxPercentage, type: 'string'},
            {label: 'tax Base', value: taxBase, type: 'string'},
            {label: 'tax Base Description', value: taxBaseDescription, type: 'string'},
            {label: 'tax Amount', value: taxAmount, type: 'string'},
            {label: 'tax Amount Currency Code', value: taxAmountCurrencyCode, type: 'string'},
            {label: 'tax Currency Amount', value: taxCurrencyAmount, type: 'string'},
            {label: 'tax Exemption Reason', value: taxExemptionReason, type: 'string'},
            {label: 'tax Declaration Period', value: taxDeclarationPeriod, type: 'string'},
        ]
    ]
};

const mapCard = (json) => {
    const {
        taxType,taxCode,taxPercentage,taxBase,taxBaseDescription,taxAmount,taxAmountCurrencyCode,taxCurrencyAmount
    } = json;

    return [
        [
            {labels: ['Tax Type'], value: `${taxType}`, type: 'string'},
        ],
        [
            {labels: ['Tax Code'], value: `${taxCode}`, type: 'string'},
        ],
        [
            {labels: ['Tax Percentage'], value: `${taxPercentage}`, type: 'string'},
        ],
        [
            {labels: ['Tax Base'], value: `${taxBase}`, type: 'string'},
        ],
        [
            {labels: ['Tax Base Description'], value: `${taxBaseDescription}`, type: 'string'},
        ],
        [
            {labels: ['Tax Amount'], value: `${taxAmount}`, type: 'string'},
        ],
        [
            {labels: ['Tax Amount Currency Code'], value: `${taxAmountCurrencyCode}`, type: 'string'},
        ],
        [
            {labels: ['Tax Currency Amount'], value: `${taxCurrencyAmount}`, type: 'string'},
        ]
    ]
};


const mapper = (json, card, details) => {

    return {
        ...json,
        name: json.description,
        shortName: json.lineNumber,
        details: details ? mapDetails(json) : [],
        card: card ? mapCard(json) : []
    }
};

const header = [
    {label: ['Tax Type'], align: 'left', columnName: "product_description", sortable: true},
    {label: ['Tax Code'], align: 'left', columnName: "product_description", sortable: true},
    {label: ['Tax Percentage'], align: 'left', columnName: "product_description", sortable: true},
    {label: ['Tax Base'], align: 'left', columnName: "product_description", sortable: true},
    {label: ['Tax Base Description'], align: 'left', columnName: "product_description", sortable: true},
    {label: ['Tax Amount'], align: 'left', columnName: "product_description", sortable: true},
    {label: ['Tax Amount Currency Code'], align: 'left', columnName: "product_description", sortable: true},
    {label: ['Tax Currency Amount'], align: 'left', columnName: "product_description", sortable: true}
];

const movementofgoodlinetaxinformationMapper = {
    map: mapper,
    header
}

export default movementofgoodlinetaxinformationMapper;