import I18n from '../../../I18n'
import { format, date } from '../../../utils'

const mapDetails = (json) => {
	const {
		registryDate,
		customerId,
		transactionId,
		type,
		period,
		invoiceDate,
		grossTotal,
		netTotal,
		refNumber,
		accountId
	} = json
	return [
		[	
			{ label: 'Invoice No', value: refNumber, type: 'string' },
			{ label: 'Type', value: type, type: 'string' },
			{ label: 'Period', value: period, type: 'number' },
			{ label: 'Account Id', value: accountId, type: 'string' },
			{ label: 'Invoice Date', value: date(invoiceDate), type: 'date' },
			{ label: 'Gl Posting Date', value: date(registryDate), type: 'date' },
		],
		[
			{ label: 'Gross Total', value: format(grossTotal, 'fullValue'), type: 'currency' },
			{ label: 'Net Total', value: format(netTotal, 'fullValue'), type: 'currency' }
		],
		[
			customerId ? { label: 'Customer', value: { id: customerId, name: customerId, __typename: 'ErpCustomer' }, type: 'object' } : null,
			transactionId ? {
				label: 'Transaction',
				value: {
					id: transactionId,
					name: transactionId,
					__typename: 'ErpGeneralLedger'
				},
				type: 'object'
			} : null
		],
	]
}

const mapCard = (json) => {
	const {
		refNumber,
		type,
		invoiceDate,
		grossTotal,
		netTotal,
		country,
		period,
		customerId,
		gltransactionId,
		companyName
	} = json

	return [
		[{ labels: ['id'], align: 'left', value: refNumber, type: 'string',basis: "12%"}],
		[{ labels: ['type'], align: 'center', value: I18n.translate`${type}`, type: 'number' }],
		[{ labels: ['date'], value: date(invoiceDate), type: 'date' }],
		customerId?
			[{ labels: ['customer'], align: 'left', value: { id: customerId, name: customerId, __typename: 'ErpCustomer' }, type: 'object', mobileHidden: true }] 
			: [{ labels: ['customer name'], align: 'left', value: `${ companyName ? companyName : "N/A"}`, type: 'string' }],
		[{ labels: ['country'], align: 'left', value: I18n.translate`${country}`, type: 'string'}],
		[{ labels: ['period'], value: I18n.translate`${period}`, type: 'number', basis: '10%' }],
		gltransactionId? 
			[{ labels: ['transaction'], align: 'left', value: { id: gltransactionId, name: gltransactionId, __typename: 'ErpGeneralLedger' }, type: 'object', mobileHidden: true }] 
			: [{ labels: ['transaction'], align: 'left', value: `${gltransactionId? gltransactionId : "N/A"}`, type: 'string' }],
		[{ labels: ['net'], value: format(netTotal), type: 'currency' }],
		[{ labels: ['gross'], value: format(grossTotal), type: 'currency' }]
	]
}

const mapper = (json, card, details) => {
	return {
		...json,
		name: json.refNumber,
		shortName: json.refNumber,
		details: details ? mapDetails(json) : [],
		card: card ? mapCard(json) : [],
		raw: json.raw
	}
}

const header = [
	{ label: ["Invoice No"], align: 'left', columnName: "invoice_no", sortable: true,basis: "12%" },
	{ label: ["Type"], align: 'center', columnName: "invoice_type", sortable: true },
	{ label: ["Date"], align: 'center', columnName: "invoice_date", sortable: true },
	{ label: ["entity_customer_id"], align: 'left', columnName: "customer_id", sortable: true},
	{ label: ["Country"], align: 'left' },
	{ label: ["Period"], columnName: "period", sortable: true, basis: '10%'},
	{ label: ["Transaction"], align: 'left', mobileHidden: true },
	{ label: ["Net Total"], align: 'right', columnName: "net_total", sortable: true },
	{ label: ["Gross Total"], align: 'right', columnName: "gross_total", sortable: true }
]

const salesinvoiceMapper = {
	map: mapper,
	header
}

export default salesinvoiceMapper