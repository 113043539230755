import I18n from "../../I18n"
import { toast } from 'react-toastify';

export const handleDownloadMultipleFiles = (props) => {
    const {checkList, pushDialog, i18n, downloadFiles, setIsAllSelected, setCheckList, childList} = props
    const selectedFiles = (
        checkList
            .filter(x => x.checked && x.status !== "deleted") || [])
        .map(i => ({
            id: i.id,
            name: i.name,
            status: i.status,
            fileLocation: i.fileLocation,
        })
        )

    if (selectedFiles.length === 0)
        return true

    const filename = (selectedFiles.map(x => x.name) || '').join(', ')

    pushDialog('ConfirmDialog', i18n`Download Files`, {
        title: i18n`Do you want to download the file${selectedFiles.length > 1 && `s`}: ${filename}?`,
        text: i18n`Please confirm that you want to proceed!`
    })
        .then(() => {
            setIsAllSelected(false)
            setCheckList(childList)
            downloadFiles({files: selectedFiles}, pushDialog, i18n)
        })
        .catch((e) => console.log(e))
}

export const handleDeleteFilePermanently = (props) => {
    const {pushDialog, i18n, reload, checkList, params, deleteFilePermanently, user} = props

    const selectedFiles = (
        checkList
            .filter(x => x.checked) || [])
        .map(i => ({
            id: i.id,
            name: i.name,
            status: i.status,
        })
        )

    if (selectedFiles.length === 0)
        return true

    const filename = (selectedFiles.map(x => x.name) || '').join(', ')

    pushDialog('ConfirmDialog', i18n`Delete File Permanently`, {
        title: i18n`Are you sure you want to delete permanently the file${selectedFiles.length > 1 && `s`}: ${filename}?`,
        text: i18n`Please confirm that you want to proceed!`
    })
        .then(() => {
            deleteFilePermanently({
                fiscalYear: params.fiscalYear || "",
                projectId: params.projectId,
                files: selectedFiles.map(i => parseInt(i.id)),
                userId: user.id
            })
                .then(() => {
                    reload && reload()
                })
                .catch(() => null)
        })
        .catch(() => null)
}

export const handleMarkAsDeleted = (props) => {
    const {pushDialog, i18n, reload, checkList, params, setDataFileStatus} = props

    const selectedFiles = (
        checkList
            .filter(x => x.checked && x.status === 'imported') || [])
        .map(i => ({
            id: i.id,
            name: i.name,
            status: i.status,
        })
        )

    if (selectedFiles.length === 0)
        return true

    const filename = (selectedFiles.map(x => x.name) || '').join(', ')

    pushDialog('ConfirmDialog', i18n`Mark as Delete`, {
        title: i18n`Are you sure you want to mark as delete the file${selectedFiles.length > 1 && `s`}: ${filename}?`,
        text: i18n`Please confirm that you want to proceed!`
    })
        .then(() => {
            setDataFileStatus({
                fiscalYear: params.fiscalYear,
                projectId: params.projectId,
                files: selectedFiles.map(i => parseInt(i.id)),
                status: 'deleted'
            })
                .then(() => {
                    reload && reload()
                })
                .catch(() => null)
        })
        .catch(() => null)
}

export const handleMarkAsActived = (props) => {
    const {pushDialog, i18n, reload, checkList, params, setDataFileStatus} = props

    const selectedFiles = (
        checkList
            .filter(x => x.checked && x.status === 'deleted') || [])
        .map(i => ({
            id: i.id,
            name: i.name,
            status: i.status,
        })
        )

    if (selectedFiles.length === 0)
        return true

    const filename = (selectedFiles.map(x => x.name) || '').join(', ')

    pushDialog('ConfirmDialog', i18n`Mark as Active`, {
        title: i18n`Are you sure you want to mark as active the file${selectedFiles.length > 1 && `s`}: ${filename}?`,
        text: i18n`Please confirm that you want to proceed!`
    })
        .then(() => {
            setDataFileStatus({
                fiscalYear: params.fiscalYear,
                projectId: params.projectId,
                files: selectedFiles.map(i => parseInt(i.id)),
                status: 'imported'
            })
                .then(() => {
                    reload && reload()
                })
                .catch(() => null)
        })
        .catch(() => null)
}

export const handleReprocessRepository = (props) => {
    const {pushDialog, i18n, reload, params, reprocessRepository, user} = props

    pushDialog('ConfirmDialog', i18n`Process All`, {
        title: i18n`Are you sure you want to process repository?`,
        text: i18n`Please confirm that you want to proceed!`
    })
        .then(() => {
            reprocessRepository({
                fiscalYear: params.fiscalYear,
                projectId: params.projectId,
                userId: user.id
            })
                .then(() => {
                    reload()
                })
                .catch(() => null)
        })
        .catch(() => null)
}

export const handleGstReturnSubmission = (props) => {
    const {pushModal, reload, checkList, params} = props

    const selectedRows = 
        (checkList || [])
            .filter(row => row.checked)
            .map(row => ({
                id: row.id
            }));
    
    if (selectedRows.length === 0) {
        return true;
    } else {
        pushModal({
            title: 'GSTR1 Submission',
            data: {
                fiscalYear: params.fiscalYear,
                projectId: params.projectId,
                gstin: "",
                period: null,
                invoiceIds: selectedRows.map(x => x.id || '').join(","),
                __typename: "GstReturn"
            },
            args: {
                fiscalYear: params.fiscalYear,
                projectId: params.projectId,
              },
            view: 'GstReturnSubmissionDialog'
        }).then(() => {
            reload();
        }).catch(() => null);
    }
};

export const handleGst2aRequestSubmit = (props) => {
	const { pushModal, reload, params } = props;

	pushModal({
		title: "GSTR2A Request Data",
		data: {
			fiscalYear: params.fiscalYear,
			projectId: params.projectId,
			gstin: "",
			period: null,
			__typename: "GstReturn",
		},
		args: {
			fiscalYear: params.fiscalYear,
			projectId: params.projectId,
		},
		view: "Gst2aRequestDialog",
	})
		.then(() => {
			reload();
		})
		.catch(() => null);
};

export const handleFileSubmissionSubmission = (props) => {
    const { checkList, i18n, params, pushModal } = props

    const selectedFiles = (checkList|| []).reduce((acc, elem) => 
        {
            if(elem.checked){
                for (const key in acc) {
                    if(key==='id'){
                        acc[key].push(parseInt(elem[key]))
                    }else{
                        acc[key].push(elem[key])
                    }
                }
            }
            return acc
        }, {
            id: [],
            name: [],
            hasHighFindings: [],
            period: []
        })

    const filenames = selectedFiles["name"]
    const fileIds = selectedFiles["id"]
    const filesWithProblems = (selectedFiles["hasHighFindings"] || []).map((value, index) => {
        if(value){
            return filenames[index];
        }
        return null
    }).filter((r) => r)
    const filesPeriods = selectedFiles["period"]

    pushModal({
        title: i18n`SAF-T Files Submission`,
        data: { __typename: "DataFileSubmission", filenames, fileIds, projectId: params.projectId, filesWithProblems, filePeriods: filesPeriods},
        args: {
            fiscalYear: params.fiscalYear,
            projectId: params.projectId,
            },
        view: 'FileSubmissionForm',
        modalType: 'center'
    }).then(()=> {
        toast.success(I18n.translate`Request successfully made`, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            onClose: window.location.reload(),
        });
    })
    .catch((e) => {
        return null
    })
}
    