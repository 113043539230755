import { format } from "../../../utils";

const header = [
	{ label: ["Type"], align: "left" },
	{ label: ["Entity"], align: "left" },
	{ label: ["Debit Credit Indicator"], align: "center" },
	{ label: ["Asset Transaction Amount"], align: "right" },
	{ label: ["Book Value on Transaction"], align: "right" },
	{ label: ["Acquisition and Production Costs on Transaction"], align: "right" }
];

const mapCard = json => {
	const {
		assetValuationType,
		taxEntity,
		debitCreditIndicator,
		assetTransactionAmount,
		bookValueOnTransaction,
		acquisitionAndProductionCostsOnTransaction
	} = json;

	return [
		[{ labels: ["type"], value: assetValuationType || "N/A", type: "string" }],
		[{ labels: ["tax_entity"], value: taxEntity || "N/A", type: "string" }],
		[
			{
				labels: ["debit_credit_indicator"],
				value: debitCreditIndicator,
				type: "string",
				align: 'center'
			}
		],
		[
			{
				labels: ["asset_transaction_amount"],
				value: format(assetTransactionAmount, "fullValue"),
				type: "currency"
			}
		],
		[
			{
				labels: ["book_value_on_transaction"],
				value: format(bookValueOnTransaction, "fullValue"),
				type: "currency"
			}
		],
		[
			{
				labels: ["acquisition_production_costs"],
				value: format(acquisitionAndProductionCostsOnTransaction, "fullValue"),
				type: "currency"
			}
		]
	];
};

const mapper = (json, card) => {
	const name = json.asset_transaction_id;

	return {
		...json,
		__typename: json.__typename,
		id: json.id,
		name,
		shortName: name,
		card: card ? mapCard(json) : []
	};
};

const assettransactionvaluationMapper = {
	map: mapper,
	header
};

export default assettransactionvaluationMapper;