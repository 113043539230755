import { format } from '../../../utils'

const mapDetails = (json) => {
	const {
		ptTaxRegistrationNumber,
		taxRegistrationNumber,
		issuerTaxRegistrationNumber,
		taxCode,
		incomeType,
		residencyCountryCode,
		shareCapitalPercentage,
		taxablePersonShareCapitalPercentage,
		taxPercentage,
		incomeAmount,
		withheldTaxAmount,
	} = json

	return [
		[
			{ label: 'PT Tax Registration Number', value: ptTaxRegistrationNumber, type: 'string' },
			{ label: 'Tax Registration Number', value: taxRegistrationNumber, type: 'string' },
			{ label: 'Issuer Tax Registration Number', value: issuerTaxRegistrationNumber, type: 'string' },
			{ label: 'Share Capital Percentage', value: format((parseFloat(shareCapitalPercentage) || 0).toFixed(2), 'normal', null), type: 'percentage' },
			{ label: 'Taxable Person Share Capital Percentage', value: format((parseFloat(taxablePersonShareCapitalPercentage) || 0).toFixed(2), 'normal', null), type: 'percentage' },
			{ label: 'Tax Percentage', value: format((parseFloat(taxPercentage) || 0).toFixed(2), 'normal', null), type: 'percentage' }
		],
		[
			{ label: 'Income Amount', value: format(incomeAmount), type: 'currency' },
			{ label: 'With Held Tax Amount', value: format(withheldTaxAmount), type: 'currency' }
		],
		[
			{ label: 'Tax Code', value: taxCode, type: 'string' },
			{ label: 'Income Type', value: incomeType, type: 'string' },
			{ label: 'Residency Country Code', value: residencyCountryCode, type: 'string' },
		]
	]
}

const mapCard = (json) => {
	const {
		ptTaxRegistrationNumber,
		residencyCountryCode,
		shareCapitalPercentage,
		incomeAmount,
		taxPercentage,
		withheldTaxAmount,
	} = json

	return [
		[
			{ labels: ['ptTaxRegistrationNumber'], value: ptTaxRegistrationNumber, type: 'string', basis: '20%' },
		],
		[
			{ labels: ['residencyCountryCode'], value: residencyCountryCode, type: 'number', basis: '20%' }
		],
		[
			{ label: 'shareCapitalPercentage', align: 'center', value: format((parseFloat(shareCapitalPercentage) || 0).toFixed(2), 'normal', null), type: 'percentage', basis: '15%' }
		],
		[
			{ label: 'taxPercentage', align: 'center', value: format((parseFloat(taxPercentage) || 0).toFixed(2), 'normal', null), type: 'percentage', basis: '15%' }

		],
		[
			{ labels: ['incomeAmount'], value: format(incomeAmount), type: 'currency', basis: '15%' }
		],
		[
			{ labels: ['withheldTaxAmount'], value: format(withheldTaxAmount), type: 'currency', basis: '15%' }
		]
	]
}

const mapper = (json, card, details) => {
	return {
		...json,
		name: json.refNumber,
		shortName: json.refNumber,
		details: details ? mapDetails(json) : [],
		card: card ? mapCard(json) : []
	}
}

const header = [
	{ label: ["PT Tax Registration Number"], align: 'left', basis: '20%' },
	{ label: ["Residency Country Code"], align: 'center', basis: '20%' },
	{ label: ["Share Capital Percentage"], align: 'center', mobileHidden: true, basis: '15%' },
	{ label: ["Tax Percentage"], align: 'center', basis: '15%' },
	{ label: ["Income Amount"], align: 'right', mobileHidden: true, basis: '15%' },
	{ label: ["With Held Tax Amount"], align: 'right', mobileHidden: true, basis: '15%' },
]

const incomebeneficiariesmod30Mapper = {
	map: mapper,
	header
}
export default incomebeneficiariesmod30Mapper;