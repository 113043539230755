const mapCard = (json) => {
	const {
		nif
	} = json

	return [
		[
			{ labels: ['NIF'], value: nif, type: 'string', basis: '65%' },
		]
	]
}

const mapper = (json, card) => {
	return {
		...json,
		card: card ? mapCard(json) : []
	}
}

const header = [
	{ label: ["NIF"], align: 'left', basis: '65%' },
]

const associatedcompanymod22Mapper = {
	map: mapper,
	header
}

export default associatedcompanymod22Mapper