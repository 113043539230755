import { datetime } from '../../utils'

const mapCard = (json) => {
	const {
		fileId, deletedBy, deletedAt, fileName, fileType
	} = json
	return [
		[
			{ labels: ['id'], value: fileId, type: 'string', align: 'align-left', basis: '10%' }
		],
		[
			{ labels: ['fileName'], value: fileName, type: 'string', align: 'align-left', basis: '50%' }
		],
		[
			{ labels: ['fileType'], value: fileType, type: 'string', align: 'align-left', basis: '30%' }
		],
		[
			{ labels: ['deletedBy'], value: deletedBy, type: 'string', align: 'align-left', basis: '22%' }
		],
		[
			{ labels: ['deletedAt'], value: datetime(deletedAt), type: 'date', align: 'align-left', basis: '22%' }
		],
	]
}

const mapper = (json, card) => {
	return {
		...json,
		name: json.name,
		shortName: json.name,
		card: card ? mapCard(json) : [],
	}
}

const header = [
	{ label: ['Id'], align: 'left', columnName: "id", basis: '10%' },
	{ label: ['File Name'], align: 'left', columnName: "fileName", basis: '50%' },
	{ label: ['File Type'], align: 'left', columnName: "fileType", basis: '30%' },
	{ label: ['Deleted By'], align: 'left', columnName: "deletedBy", basis: '22%' },
	{ label: ['Deleted At'], align: 'left', columnName: "deleted_at", basis: '22%' },

]

const deletefilelogsMapper = {
	map: mapper,
	header
};

export default deletefilelogsMapper