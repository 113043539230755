const header = [
	{ label: ["[Type]"], align: "left" },
	{ label: ["Street"], align: "left" },
	{ label: ["Number"], align: "left" },
	{ label: ["Full Address"], align: "left" },
	{ label: ["Postal Code"], align: "left" },
	{ label: ["City"], align: "left" },
	{ label: ["Country"], align: "left" },
	{ label: ["Region"], align: "left" }
];

const mapCard = json => {
	const {
		street,
		number,
		city,
		postalCode,
		country,
		addressType,
		region,
		detail
	} = json;

	return [
		[{ labels: ["type"], value: addressType || "N/A", type: "string" }],
		[{ labels: ["street"], value: street, type: "string" }],
		[{ labels: ["number"], value: number, type: "string" }],
		[{ labels: ["detail"], value: detail, type: "string" }],
		[{ labels: ["postalCode"], value: postalCode, type: "string" }],
		[{ labels: ["city"], value: city, type: "string" }],
		[{ labels: ["country"], value: country, type: "string" }],
		[{ labels: ["region"], value: region, type: "string" }]
	];
};

const mapper = (json, card) => {
	const name = json.customer_id || json.supplier_id;

	return {
		...json,
		__typename: json.__typename,
		id: json.id,
		name,
		shortName: name,
		card: card ? mapCard(json) : []
	};
};

const addressMapper = {
	map: mapper,
	header
};

export default addressMapper;