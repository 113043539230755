import OrganizationsProjects from "../controllers/Organization/OrganizationsProjects";
import { parseFilter } from 'colbi_web_ui/lib/utils'

const queries = (args) => {
	const {filter, organizationId} = args
	return [
		{
			resource: "user_projects_organizations",
			args:{
				filter: filter
					? { type: "[FilterInput!]", value: parseFilter(args.filter) }
					: undefined
			},
			body: 'id,name,projects{id,name,fiscalYears,status,taxRegistrationNumber,active,projectExpired,modules{navigation_by_import_id,name,default_route,default_module}},organizationExpired, organizationActive',
			forceRefresh: true
		},
		organizationId ?
		{
			resource: "user_projects_organizations_filters",
			args: {
				organizationId: (args.organizationId),
			},
			body: "prop, name, type, list, disableOperators, mandatory"
		}: null
    ].filter(r=>r)
}

const userProjectsByOrganization = (path, navigation, exact, title) => ({
	path,
	navigation: navigation,
	component: OrganizationsProjects,
	queries,
	exact,
	title: [`label(${title})`],
});

export default userProjectsByOrganization;
