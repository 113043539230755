import AppByFile from '../controllers/AppByFile/AppByFile'
import { AuditSummaryWidget, Grid, cards, Widget, charts } from 'colbi_web_ui/lib/components'

const queries = (args = {}) => {
	return args.fiscalYear ? [
		{
			resource: 'saftlt_project_summary',
			args: {
				projectId: (args.projectId),
				fiscalYear: (args.fiscalYear)
			},
			body: '...saftlt_project_summary'
		},
		{
			resource: 'audit_summary',
			args: {
				projectId: (args.projectId),
				fiscalYear: (args.fiscalYear),
				modulePrefix: 'lt_erp',
				importId: "full"
			},
			body: 'severity, count'
		},
		{
			resource: `lt_erp_sales_invoices_summary`,
			args: {
				projectId: (args.projectId),
				fiscalYear: (args.fiscalYear),
				modulePrefix: "lt_erp"
			},
			body: 'summary{ key, value }'
		},
		{
			resource: 'lt_erp_reports',
			args: {
				projectId: (args.projectId),
				fiscalYear: (args.fiscalYear),
				modulePrefix: "lt_erp"
			},
			body: 'report'
		},
		{
			resource: `lt_erp_purchase_invoices_summary`,
			args: {
				projectId: (args.projectId),
				fiscalYear: (args.fiscalYear),
				modulePrefix: "lt_erp"
			},
			body: 'summary{ key, value }'
		},
		{
			resource: 'lt_erp_project_dimension_master_data',
			args: {
				projectId: (args.projectId),
				fiscalYear: (args.fiscalYear),
				modulePrefix: "lt_erp"
			},
			body: '...lt_erp_project_dimension_master_data'
		},
		{
			resource: 'lt_erp_project_dimension_transaction',
			args: {
				projectId: (args.projectId),
				fiscalYear: (args.fiscalYear),
				modulePrefix: "lt_erp"
			},
			body: '...lt_erp_project_dimension_transaction'
		},
		{
			resource: 'saftlt_project_dimension',
			args: {
				projectId: (args.projectId),
				fiscalYear: (args.fiscalYear),
				modulePrefix: "lt_erp"
			},
			body: '...saftlt_project_dimension'
		},
		
	] : []
}

const overviewErp = (path, navigation, exact, title) => ({
	path,
	navigation,
	component: AppByFile,
	queries,
	exact,
	title: [title],
	components: [
		{
			component: AuditSummaryWidget,
			modifiers: ['--root-margin-bottom-normal'],
			props: {
				icon: 'audit-icon',
				title: 'label(Audit Results)',
				summary: 'query(1).list',
				link: {
					to: 'lt_erp/audit',
					name: 'label(See All)'
				},
				overviewSummaryWidget: true
			}
		},
		{
			component: Widget,
			modifiers: ['--root-margin-bottom-normal'],
			props: {
				icon: 'sales',
				title: 'label(Sales KPIS)',
				children: [
					{
						component: Grid,
						modifiers: ['--grid-border-inline'],
						props: {
							columnsTemplate: [1],
							items: [
								{
									data: [
										{
											name: 'label(Sales Gross Total)',
											value: 'currency(query(0).erpInvoicesGrossTotal)',
											icon: 'gross-sales'
										},
										{
											name: 'label(Sales Net Total)',
											value: 'currency(query(0).erpInvoicesNetTotal)',
											icon: 'net-sales'
										},
										{
											name: 'label(Invoice Avg Net)',
											value: 'currency(query(0).erpInvoicesNetAverage)',
											icon: 'kpi-icon'
										},
										{
											name: 'label(Invoice Max Value)',
											value: 'currency(query(0).erpInvoicesMaxValue)',
											icon: 'kpi-icon'
										}
									]
									
								}
							],
							cards: [charts.KpisLegend]
						}
					}
				]
			}
		},
		{
			component: Widget,
			modifiers: ['--root-margin-bottom-normal'],
			props: {
				icon: 'sales',
				title: 'label(Sales Summary)',
				summary: [
					{
						name: 'label(No. Invoices)',
						value: 'number(query(0).erpInvoicesCount)'
					},
					{
						name: 'label(No. Invoices Lines)',
						value: 'number(query(0).erpInvoiceLinesCount)'
					}
				],
				link: {
					to: 'link(/:projectId/:fiscalYear/lt_erp/sales_invoices)',
					name: 'label(Invoices)'
				},
				children: [
					{
						component: Grid,
						modifiers: ['--grid-border-inline'],
						props: {
							columnsTemplate: [1],
							aspect: ['31x10'],
							items: [
								{	
									data: 'query(2).list',
									stacked: true,
									summarized: true,
									links: [{
										to: ['param(projectId)', 'param(fiscalYear)', 'lt_erp','/sales_invoices'],
										filterColumn: 'period'
									}],
									modifiers: ['--top-text-small'],
								},
								
							],
							cards: [charts.BarChart]
						}
					}
				]
			}
		},
		{
			component: Grid,
			modifiers: [
				'--root-margin-bottom-normal',
				'--cardholder-background-color-widget',
				'--mobile--cardholder-aspect-ratio-4x5'
			],
			props: {
				columnsTemplate: [1, 1, 1],
				aspect: ['5x4', '5x4', '5x4'],
				items: [
					{
						title: 'label(Sales (Net))',
						data: ['query(3).report.0'],
						path: "/:locale(pt|en|lt|es)?/:projectId/:fiscalYear([0-9]{4}|current)/lt_erp/master_data/products/:id"
					},
					{
						title: 'label(Customers (Net))',
						data: ['query(3).report.1'],
						path: "/:locale(pt|en|lt|es)?/:projectId/:fiscalYear([0-9]{4}|current)/lt_erp/master_data/customers/:id"
					},
					{
						title: 'label(Top sales by country)',
						data: ['query(3).report.2'],
						icon: 'top',
						entity: 'sales_invoices',
						filterColumn: "customer_address_country",
						prefix: "lt_erp"
					}
				],
				cards: [cards.TopsCard, cards.TopsCard, cards.SalesByCountryCard]
			}
		},
		{
			component: Widget,
			modifiers: ['--root-margin-bottom-normal'],
			props: {
				icon: 'sales',
				title: 'label(Purchase Invoices KPIS)',
				children: [
					{
						component: Grid,
						modifiers: ['--grid-border-inline'],
						props: {
							columnsTemplate: [1],
							items: [
								{
									data: [
										{
											name: 'label(Purchase Invoices Gross Total)',
											value: 'currency(query(0).erpPurchasesGrossTotal)',
											icon: 'gross-sales'
										},
										{
											name: 'label(Purchase Invoices Net Total)',
											value: 'currency(query(0).erpPurchasesNetTotal)',
											icon: 'net-sales'
										},
										{
											name: 'label(Purchase Invoice Avg Net)',
											value: 'currency(query(0).erpPurchasesNetAverage)',
											icon: 'kpi-icon'
										},
										{
											name: 'label(Purchase Invoice Max Value)',
											value: 'currency(query(0).erpPurchasesMaxValue)',
											icon: 'kpi-icon'
										}
									]
									
								}
							],
							cards: [charts.KpisLegend]
						}
					}
				]
			}
		},
		{
			component: Widget,
			modifiers: ['--root-margin-bottom-normal'],
			props: {
				icon: 'sales',
				title: 'label(Purchase Invoices Summary)',
				summary: [
					{
						name: 'label(No. Purchase Invoices)',
						value: 'number(query(0).erpPurchasesCount)'
					},
					{
						name: 'label(No. Purchase Invoice Lines)',
						value: 'number(query(0).erpPurchaseLinesCount)'
					}
				],
				link: {
					to: 'link(/:projectId/:fiscalYear/lt_erp/purchase_invoices)',
					name: 'label(Purchases)'
				},
				children: [
					{
						component: Grid,
						modifiers: ['--grid-border-inline'],
						props: {
							columnsTemplate: [1],
							aspect: ['31x10'],
							items: [
								{	
									data: 'query(4).list',
									stacked: true,
									summarized: true,
									links: [{
										to: ['param(projectId)', 'param(fiscalYear)', '/lt_erp','/purchase_invoices'],
										filterColumn: 'period',
									}],
									modifiers: ['--top-text-small'],
								},
								
							],
							cards: [charts.BarChart]
						}
					}
				]
			}
		},
		{
			component: Grid,
			modifiers: [
				'--root-margin-bottom-normal',
				'--cardholder-background-color-widget',
				'--mobile--cardholder-aspect-ratio-4x5'
			],
			props: {
				columnsTemplate: [1, 1, 1],
				aspect: ['5x4', '5x4', '5x4'],
				items: [
					{
						title: 'label(Purchase Invoices (Net))',
						data: ['query(3).report.3'],
						path: "/:locale(pt|en|lt|es)?/:projectId/:fiscalYear([0-9]{4}|current)/lt_erp/master_data/products"
					},
					{
						title: 'label(Suppliers (Net))',
						data: ['query(3).report.4'],
						path: "/:locale(pt|en|lt|es)?/:projectId/:fiscalYear([0-9]{4}|current)/lt_erp/master_data/suppliers"
					},
					{
						title: 'label(Top purchases by country)',
						data: ['query(3).report.5'],
						icon: 'top',
						entity: 'purchase_invoices',
						filterColumn: "customer_address_country",
						prefix: "lt_erp"
					}
				],
				cards: [cards.TopsCard, cards.TopsCard, cards.SalesByCountryCard]
			}
		},
		{
			component: Grid,
			modifiers: [
				'--grid-padding-none',
				'--root-margin-bottom-normal'
			],
			props: {
				columnsTemplate: [1],
				items: [
					{
						title: 'label(Sales vs Purchases)',
						icon: 'comparation',
						children: [
							{
								component: Grid,
								modifiers: ['--grid-border-inline', '--grid-margin-none', '--grid-padding-none'],
								props: {
									columnsTemplate: [1],
									aspect: ['31x10'],
									items: [
										{
											currency: true,
											data: ['query(3).report.6', 'query(3).report.7'],
											dataLabels: ['label(Sales)', 'label(Purchases)'],
											links: [
												{
													to: ['param(projectId)', 'param(fiscalYear)', '/erp_sales_invoices'],
													filterColumn: 'period'
												},
												{
													to: ['param(projectId)', 'param(fiscalYear)', '/erp_purchase_invoices'],
													filterColumn: 'period'
												}, {}
											]
										}
									],
									cards: [charts.BarChart]
								}
							}
						]
					},
				],
				cards: [Widget]
			}
		},
		{
			component: Grid,
			modifiers: [
				'--root-margin-bottom-normal',
				'--root-background-color-widget-background-color'
			],
			props: {
				columnsTemplate: [1, 1, 1],
				items: [
					{
						title: 'label(Master Data)',
						items: [
							{
								name: 'label(Customers)',
								count: 'number(query(5).customers)',
								link: `${path.replace('/erp','/lt_erp')}/master_data/customers`
							},
							{
								name: 'label(Suppliers)',
								count: 'number(query(5).suppliers)',
								link: `${path.replace('/erp','/lt_erp')}/master_data/suppliers`
							},
							{
								name: 'label(Products)',
								count: 'number(query(5).products)',
								link: `${path.replace('/erp','/lt_erp')}/master_data/products`
							},
							{
								name: 'label(Tax Table Entries)',
								count: 'number(query(5).tax_table_entries)',
								link: `${path.replace('/erp','/lt_erp')}/master_data/tax_table_entries`
							},
							{
								name: 'label(Uom Table Entries)',
								count: 'number(query(5).uom_table_entries)',
								link: `${path.replace('/erp','/lt_erp')}/master_data/uom_table_entries`
							},
							{
								name: 'label(Movement Types)',
								count: 'number(query(5).movement_types)',
								link: `${path.replace('/erp','/lt_erp')}/master_data/movement_types`
							},
							{
								name: 'label(Physical Stocks)',
								count: 'number(query(5).physical_stocks)',
								link: `${path.replace('/erp','/lt_erp')}/master_data/physical_stocks`
							},
							{
								name: 'label(Owners)',
								count: 'number(query(5).owners)',
								link: `${path.replace('/erp','/lt_erp')}/master_data/owners`
							},
							{
								name: 'label(Analysis Type Table Entries)',
								count: 'number(query(5).analysis_type_table_entries)',
								link: `${path.replace('/erp','/lt_erp')}/master_data/analyses_type_entries`
							},
							{
								name: 'label(Assets)',
								count: 'number(query(5).assets)',
								link: `${path.replace('/erp','/lt_erp')}/master_data/assets`
							},
							{
								name: 'label(General Ledger Accounts)',
								count: 'number(query(5).general_ledger_accounts)',
								link: `${path.replace('/erp','/lt_erp')}/master_data/general_ledger_accounts`
							},
						]
					},
					{
						title: 'label(Dimensions)',
						items: [
							{
								name: 'label(Customer Countries)',
								count: 'number(query(7).customerCountries)'
							},
							{
								name: 'label(Supplier Countries)',
								count: 'number(query(7).supplierCountries)'
							},
							{
								name: 'label(Products Group)',
								count: 'number(query(7).productsGroup)'
							},
							{
								name: 'label(Products Code)',
								count: 'number(query(7).productsCode)'
							},
							{
								name: 'label(Customers on Sales)',
								count: 'number(query(7).customersOnSales)'
							},
							{
								name: 'label(Suppliers on Purchases)',
								count: 'number(query(7).supplierOnPurchases)'
							},
							{
								name: 'label(Products on Transactions)',
								count: 'number(query(7).productsOnTransaction)'
							}
						]
					},
					{
						title: 'label(Transactions)',
						items: [
							{
								name: 'label(Sales Invoices)',
								count: 'number(query(6).invoices)',
								link: `${path.replace('/erp','/lt_erp')}/sales_invoices`
							},
							{
								name: 'label(Sales Invoice Lines)',
								count: 'number(query(6).invoice_lines)'
							},
							{
								name: 'label(Purchase Invoices)',
								count: 'number(query(6).purchase_invoices)',
								link: `${path.replace('/erp','/lt_erp')}/purchase_invoices`
							},
							{
								name: 'label(Purchase Invoice Lines)',
								count: 'number(query(6).purchase_invoice_lines)',
							},
							{
								name: 'label(Payments)',
								count: 'number(query(6).payments)',
								link: `${path.replace('/erp','/lt_erp')}/payments`
							},
							{
								name: 'label(Payment Lines)',
								count: 'number(query(6).payment_lines)',
							},
							{
								name: 'label(GL Transactions)',
								count: 'number(query(6).transactions)',
								link: `${path.replace('/erp','/lt_erp')}/general_ledgers`
							},
							{
								name: 'label(GL Transaction Lines)',
								count: 'number(query(6).transaction_lines)',
							},
							{
								name: 'label(Movement Of Goods)',
								count: 'number(query(6).movement_of_goods)',
								link: `${path.replace('/erp','/lt_erp')}/movement_of_goods`
							},
							{
								name: 'label(Movement Of Good Lines)',
								count: 'number(query(6).movement_of_good_lines)',
							},
							{
								name: 'label(Asset Transactions)',
								count: 'number(query(6).asset_transactions)',
								link: `${path.replace('/erp','/lt_erp')}/asset_transactions`
							}
						]
					}
					
				],
				cards: [cards.LinksCard]
			}
		}
	]
})

export default overviewErp
