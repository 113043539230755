import I18n from "../../../I18n";
import { format } from "../../../utils";

const mapDetails = json => {
	const {
		registrationNumber,
		taxEntity,
		customerId,
		companyName,
		selfBilling,
		kpiPercentageOnSales,
		kpiSalesTotalNet,
		kpiSalesDebitTotalNet,
	} = json;

	return [
		[
			{ label: 'Customer Id', value: customerId, type: 'string' },
			{ label: 'Registration Number', value: registrationNumber, type: 'string' },
			{ label: 'Entity', value: taxEntity, type: 'string' },
			{ label: 'Self Billing', value: selfBilling, type: 'boolean' },
			{ label: 'Customer Name', value: companyName, type: 'string' },
		],
		[
			{
				label: "Percentage on sales",
				value: format(
					(parseFloat(kpiPercentageOnSales) || 0).toFixed(2),
					"normal",
					null
				),
				type: "percentage",
				backgroundColor: '#74AF27',
				color: "#FFFFFF",
				icon: "percentage"
			},
			{
				label: "Sales Total (N)",
				value: format(kpiSalesTotalNet, 'fullValue'),
				type: "currency",
				backgroundColor: '#8397A8',
				color: "#FFFFFF",
				icon: "sales-amount"
			},
			{
				label: "Total Credit and Returns (N)",
				value: format(kpiSalesDebitTotalNet, 'fullValue'),
				type: "currency",
				backgroundColor: '#5B6975',
				color: "#FFFFFF",
				icon: "returns"
			}
		],
	];
};

const mapCard = json => {
	const {
		companyName,
		registrationNumber,
		taxEntity,
		customerId
	} = json;
	return [
		[{ labels: ["Customer Id"], value: customerId, type: "string", basis: "12%" }],	
		[{ labels: ["name"], value: companyName, type: "string", basis: "50%" }],	
		[{ labels: ["Registration Number"], value: `${registrationNumber}`, type: "string" }],
		[{ labels: ["entity"], value: `${taxEntity}`, type: "number" }]
	];
};

const mapReports = reports => {
	const [salesClientPeriod, top, topProductsMovements] = reports;

	const salesData = [];

	for (let i = 0; i < 12; i++) {
		salesData.push({
			period: i + 1,
			value:
				((salesClientPeriod || []).find(({ period }) => period === i + 1) || {})
					.value || 0
		});
	}

	const topsTabs = [];
	const topsData = [];
	if ((top || []).length) {
		topsTabs.push(I18n.translate("Top"));
		topsData.push(
			top.map(json => ({
				...json,
				__typename: "Product",
				percentage: Math.round((json.value / json.total) * 100 * 100) / 100
			}))
		);
		topsData.push(
			topProductsMovements.map(json => ({
				...json,
				__typename: "Product",
				percentage: Math.round((json.value / json.total) * 100 * 100) / 100
			}))
		);
	}

	return (salesClientPeriod || []).length ||
		(top || []).length ||
		(topProductsMovements || []).length
		? [
				{
					type: "bar",
					icon: "comparation",
					title: I18n.translate("Sales (Net)"),
					colspan: 2,
					data: [salesData],
					links: [
						{
							filterColumn: "period"
						}
					],
					currency: true
				},
				{
					type: "tops",
					title: I18n.translate("Products (N)"),
					tabs: topsTabs,
					colspan: 1,
					data: topsData
				}
		  ]
		: null;
};

const mapper = (json, card, details) => {
	return {
		...json,
		name: json.companyName,
		shortName: json.companyName,
		details: details ? mapDetails(json) : [],
		card: card ? mapCard(json) : [],
		reports: mapReports(json.reports || [])
	};
};

const header = [
	{ label: ['Customer id'], align: 'left', columnName: "customer_id", sortable: true, basis: "12%" },
	{ label: ['Entity_Name'], align: 'left', columnName: "name", sortable: true, basis: "50%" },
	{ label: ['Registration Number'], align: 'left', columnName: "registration_number", sortable: true },
	{ label: ['Entity'], align: 'center', columnName: "tax_entity" },
]
const customerMapper = {
	map: mapper,
	header
};

export default customerMapper;