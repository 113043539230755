import { format } from '../../../utils'

const mapCard = (json) => {
	const {
        addressCountry,
        debitAmount,
        creditAmount,
        documentTotalsTaxPayable,
        documentTotalsNetTotal,
        documentTotalsGrossTotal,
        totalDocuments
	} = json

	return [
		[
			{ labels: ['addressCountry'], value: addressCountry, type: 'string', basis: "10%" }

		],
		[
			{ labels: ['totalDocuments'], value: format(totalDocuments,null,null,0), type: 'currency' }
		],
		[
			{ labels: ['debitAmount'], value: format(debitAmount, 'normal', 'AOA', 2, 'pt-AO'), type: 'currency' }

		],
		[
			{ labels: ['creditAmount'], value: format(creditAmount, 'normal', 'AOA', 2, 'pt-AO'), type: 'currency'}

		],
		[
			{ labels: ['documentTotalsTaxPayable'], value: format(documentTotalsTaxPayable, 'normal', 'AOA', 2, 'pt-AO'), type: 'currency' }

        ],
        [
			{ labels: ['documentTotalsNetTotal'], value: format(documentTotalsNetTotal, 'normal', 'AOA', 2, 'pt-AO'), type: 'currency' }

        ],
        [
			{ labels: ['documentTotalsGrossTotal'], value: format(documentTotalsGrossTotal, 'normal', 'AOA', 2, 'pt-AO'), type: 'currency' }

		],
	]
}

const header = [
	{ label: ['Address Country'], align: 'left', basis: "10%"},
    { label: ['Total Documents'], align: 'right', columnName: "total_documents", sortable: true},
	{ label: ['Debit Amount'], align: 'right', columnName: "debit_amount", sortable: true, totalizerName: "total_debit_amount" },
	{ label: ['Credit Amount'], align: 'right', columnName: "credit_amount", sortable: true, totalizerName: "total_credit_amount" },
	{ label: ['Tax Payable'], align: 'right', columnName: "document_totals_tax_payable", sortable: true, totalizerName: "total_document_totals_tax_payable", options: {currency: 'AOA', locale: 'pt-AO'} },
    { label: ['Net Total'], align: 'right', columnName: "document_totals_net_total", sortable: true, totalizerName: "total_document_totals_net_total", options: {currency: 'AOA', locale: 'pt-AO'} },
    { label: ['Gross Total'], align: 'right', columnName: "document_totals_gross_total", sortable: true, totalizerName: "total_document_totals_gross_total", options: {currency: 'AOA', locale: 'pt-AO'} },
]

const mapper = (json, card) => {
	return {
		...json,
		name: json.name,
		shortName: json.name,
		card: card ? mapCard(json) : [],
		link: {
			to: '/:locale(pt|en|lt|es)?/:projectId/:fiscalYear([0-9]{4}|current)/:importId/saft_ao/invoices',
			filter: [{ "filters": [{ "value": json.addressCountry }], "column": "CustomerBillingAddressCountry", "operator": "equals" }]
		}
	}
}


const salesbycountryMapper = {
	map: mapper,
	header
};

export default salesbycountryMapper;