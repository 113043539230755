import { format, date } from "../../../utils"

const mapDetails = (json) => {
	const {
		description,
		generalLedger,
		debit,
		credit,
		type,
		transactionId,
		transactionDate,
		transactionPeriod,
		invoice,
		customer,
		supplier,
		journalId,
		docArchivalNumber,
		taxEntity
	} = json
	return [
		[
			{ label: 'Type', value: type, type: 'string' },
			{ label: 'Transaction', value: transactionId, type: 'string' },
			{
				label: 'Period',
				value: transactionPeriod,
				type: 'number'
			},
			{ label: 'Date', value: date(transactionDate), type: 'date' },
			generalLedger ? { label: 'Account', value: { id: generalLedger.id, name: generalLedger.name, __typename: 'GeneralLedger' }, type: 'object' } : null,
			invoice ? { label: 'Invoice', value: { id: invoice.id, name: invoice.name, __typename: 'Invoices' }, type: 'object' } : null,
			{ label: 'Description', value: description, type: 'string' }
		],

		[
			{ label: 'Debit Amount', value: format(debit), type: 'currency' },
			{ label: 'Credit Amount', value: format(credit), type: 'currency' }
		],
		[
			{ label: 'Journal', value: journalId, type: 'string' },
			{ label: 'Archival Number', value: docArchivalNumber, type: 'number' },
			{ label: 'Entity', value: taxEntity, type: 'string' },
			customer ? { label: 'Customer', value: { id: customer.id, name: customer.name, __typename: 'Customer' }, type: 'object' } : null,
			supplier ? { label: 'Supplier', value: { id: supplier.id, name: supplier.name, __typename: 'Supplier' }, type: 'object' } : null,
		]
	]
}

const mapCard = (json) => {
	const {
		recordId,
		generalLedger = {},
		description,
		debit,
		credit
	} = json

	return [
		[
			{ labels: ['line'], value: recordId, type: 'string', columnName: "record_id", sortable: true, basis: '5%' }
		],
		[
			{ labels: ['account'], value: generalLedger ? generalLedger.name : 'N/A', type: 'string', basis: '31%' }
		],
		[
			{ labels: ['description'], value: description, type: 'string', basis: '30%' }
		],
		[
			{ labels: ['debit'], value: format(debit), type: 'currency', columnName: "debit_amount", sortable: true, basis: '15%' }
		],
		[
			{ labels: ['credit'], value: format(credit), type: 'currency', columnName: "credit_amount", sortable: true, basis: '15%' }
		]
	]
}

const mapper = (json, card, details) => {
	return {
		...json,
		name: json.description,
		shortName: json.description,
		details: details ? mapDetails(json) : [],
		card: card ? mapCard(json) : []
	}
}

const header = [
	{ label: ["Line"], align: 'left', columnName: "record_id", sortable: true, basis: '5%' },
	{ label: ["Account - Name"], align: 'left', basis: '31%' },
	{ label: ["Description"], align: 'left', basis: '30%' },
	{ label: ["Debit"], align: 'right', columnName: "debit_amount", sortable: true, totalizerName: "total_debit_amount", basis: '15%' },
	{ label: ["Credit"], align: 'right', columnName: "credit_amount", sortable: true, totalizerName: "total_credit_amount", basis: '15%' }
]

const transactionlineMapper = {
	map: mapper,
	header
}
export default transactionlineMapper;