import { date } from "../../../utils"
import I18n from '../../../I18n'

const with_warnings = 'with_warnings'
const without_warnings = 'without_warnings'
const not_executed = 'not_executed'

const mapCard = (json) => {
	const {
		ruleIdentifier,
		result,
		createdAt,
		rulesTitle,
		dataFilesName
	} = json

	const renderResult = (result) => {
		switch (result) {
			case 0:
				return [not_executed, I18n.translate`${not_executed}`, 'center']
			case 1:
				return [with_warnings, I18n.translate`${with_warnings}`, 'center']
			case 2:
				return [without_warnings, I18n.translate`${without_warnings}`, 'center']
			default:
				return ['', I18n.translate`${''}`, 'center']
		}
	}

	return [
		[
			{ labels: ['rule'], value: ruleIdentifier, type: 'string', basis: '10%' }
		],
		[
			{ labels: ['title'], value: rulesTitle, type: 'string', basis: '50%' }
		],
		[
			{ labels: ['file'], value: dataFilesName, type: 'string' }
		],
		[
			{ labels: ['status'], value: renderResult(result), type: 'status' }
		],
		[
			{ labels: ['date'], value: date(createdAt), type: 'date' }
		]
	]
}

const mapFilters = (filters) => {
	return filters.map((filter) => {
		if (filter.prop === 'result') {
			return {
				...filter,
				listMapper: {
					'0': I18n.translate`${not_executed}`,
					'1': I18n.translate`${with_warnings}`,
					'2': I18n.translate`${without_warnings}`
				}
			}
		}
		return filter
	})
}

const mapper = (json, card) => {
	return {
		...json,
		name: json.ruleIdentifier,
		shortName: json.ruleIdentifier,
		card: card ? mapCard(json) : []
	}
}

const header = [
	{ label: ['Rule'], align: 'left', columnName: "rule_identifier", sortable: true, basis: '10%' },
	{ label: ['Title'], align: 'left', basis: '49.3%' },
	{ label: ['File'], align: 'left' },
	{ label: ['Status'], align: 'center', columnName: "result", sortable: true },
	{ label: ['End Date'], align: 'center', columnName: "created_at", sortable: true }
]

const auditexecutionsummaryMapper = {
	map: mapper,
	mapFilters,
	header
}

export default auditexecutionsummaryMapper;