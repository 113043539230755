import I18n from '../../I18n'

const header = [
    { label: ["Description"], align: 'left' },
    { label: ["Severity"], align: 'center' },
    { label: ["Number of Occurrences"], align: 'center' }
]

const mapCard = (json) => {
    const {
        name,
        severity,
        total
    } = json

    const totalFindingsRound = total !== 0 ? (total > 1000 ? `> 1000`: total) : total

    return [
        [
            { labels: ['description'], value: I18n.translate(name), type: 'string' },
        ],
        [
            { labels: ['severity'], value: [`severity-${severity}`, I18n.translate`${`severity-${severity}`}`, 'center'], type: 'status' }
        ],
        [
            { labels: ['verified'], value: totalFindingsRound, type: 'string', align: 'center' }
        ]
    ]
}

const mapper = (json, card) => {
    const name = json.name
    const severity = json.severity
    return {
        ...json,
        __typename: json.__typename,
        name,
        shortName: name,
        card: card ? mapCard(json) : [],
        link: {
			to: `/:locale(pt|en|lt|es)?/:projectId/:fiscalYear([0-9]{4}|current)/:importId/:modulePrefix/audit/rules/${json.id}`,
	        filter: [{ "filters": [{"value": `${severity}`}], "column": "Severity", "operator": "equals" }]
		}
    }
}

const findingsummaryMapper =  {
    map: mapper,
    header
}

export default findingsummaryMapper