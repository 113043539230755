import I18n from '../../I18n'
import { datetime } from '../../utils'
import { stringifyFilter } from 'colbi_web_ui/lib/utils'

const mapCard = (json) => {
	const {
		id,
		name,
		status,
		uploadedAt,
		submitedBy,
		type
	} = json

	return [
		[
			{ labels: ['id'], value: id, type: 'string', align: 'left', basis: '20%' }
		],
		[
			{ labels: ['name'], value: name, type: 'string', align: 'left' }
		],
		[
			{ labels: ['fileType'], value: type, type: 'string', align: 'left'}
		],
		[
			{ labels: ['user'], value: submitedBy, type: 'string', align: 'left'}
		],
		[
			{ labels: ['uploaded'], value: datetime(uploadedAt), type: 'datetime', align: "center" }
		],
		[
			{
				labels: ['status'],
				value: [status, I18n.translate`${status}`],
				type: 'dataFileStatus'
			}
		],
		[
			{ labels: [], value: ['delete'], type: 'user_actions' }
		]
	]
}

const mapper = (json, card) => {
	const fileType = (json.type || "").toString().startsWith("LtErp") ? "LtErpFile" : (json.type || "").toString()
	const resType = fileType.replace("File", "")
	return {
		...json,
		id: json.id,
		name: json.name,
		shortName: json.name,
		card: card ? mapCard(json) : [],
		link: {
			to: `/:locale(pt|en|lt|es)?/:projectId/repository/invalid_files/status/${resType}/${json.id}?filter=${stringifyFilter(
				[{ column: 'id', filters: [{ value: `${json.id}` }] }]
			)
				}`
		}
	}
}

const header = [
	{ label: ['Id'], align: 'left', basis: '20%' },
	{ label: ['Name'], align: 'left', columnName: "name", sortable: true},
	{ label: ['File Type'], align: 'left', columnName: "type" },
	{ label: ['Uploaded by'], align: 'left' },
	{ label: ['Uploaded at'], align: 'center', columnName: "uploaded_at", sortable: true },
	{ label: ['Status'], align: 'center' },
	{ label: ["checkbox"], align: 'right'}
]

const invaliddatafileMapper = {
	map: mapper,
	header
};

export default invaliddatafileMapper