import { format, date, datetime } from "../../../utils"

const mapDetails = (json) => {
	const {
		taxEntity,
		auditFileVersion,
		auditFileDateCreated,
		dataType,
		softwareCompanyName,
		softwareName,
		softwareVersion,
		companyRegistrationNumber,
		numberOfParts,
		partNumber,
		selectionCriteriaStartDate,
		selectionCriteriaEndDate,
		numberOfMasterFilesCustomers,
		numberOfMasterFilesSuppliers,
		numberOfSourceDocumentsPurchaseInvoices,
		numberOfSourceDocumentsSalesInvoices,
		numberOfSettlementAndPayments,

	} = json

	return [
		[
			{ label: 'Entity', value: taxEntity, type: 'string' },
			{ label: 'Version', value: auditFileVersion, type: 'string' },
			{ label: 'Created Date', value: datetime(auditFileDateCreated), type: 'date' },
			{ label: 'Data Type', value: dataType, type: 'string' },
			{ label: 'Software company Name', value: softwareCompanyName, type: 'string' },
			{ label: 'Software Name', value: softwareName, type: 'string' },
			{ label: 'Software Version', value: softwareVersion, type: 'string' },
			{ label: 'company Registration Number', value: companyRegistrationNumber, type: 'string' },
			{ label: 'Software Version', value: softwareVersion, type: 'string' },
			{ label: 'Number of Parts', value: numberOfParts, type: 'number' },
			{ label: 'Part Number', value: partNumber, type: 'string' },
			{ label: 'Start Date', value: date(selectionCriteriaStartDate), type: 'date' },
			{ label: 'End Date', value: date(selectionCriteriaEndDate), type: 'date' },
			
		],
		[],
		[
			{ label: 'Customers', value: format(numberOfMasterFilesCustomers, null, null), type: 'number' },
			{ label: 'Suppliers', value: format(numberOfMasterFilesSuppliers, null, null), type: 'number' },
			{ label: 'Purchase Invoices', value: format(numberOfSourceDocumentsPurchaseInvoices, null, null), type: 'number' },
			{ label: 'Sales Invoices', value: format(numberOfSourceDocumentsSalesInvoices, null, null), type: 'number' },
			{ label: 'Settlements And Payments', value: format(numberOfSettlementAndPayments, null, null), type: 'number' },
		]
	]
}

const mapCard = (json) => {
	const {
		companyRegistrationNumber,
		softwareCompanyName,
		auditFileVersion,
		selectionCriteriaStartDate,
		selectionCriteriaEndDate,
		numberOfMasterFilesCustomers,
		numberOfMasterFilesSuppliers,
		numberOfSourceDocumentsPurchaseInvoices,
		numberOfSourceDocumentsSalesInvoices,
		numberOfSettlementAndPayments,
		fileName,
		fileId
	} = json

	return [
		[
			{ labels: ['nif'], value: `${ companyRegistrationNumber }`, type: 'string', basis: '23%' }
		],
		[
			{ labels: ['software Company Name'], value: `${ softwareCompanyName }`, type: 'string', basis: '35%' }
		],
		[
			{ labels: ['file_name'], value: { id: fileId , name: fileName, __typename: 'FileDetail' }, type: 'object' }
		],
		[
			{ labels: ['version'], value: `${ auditFileVersion }`, type: 'string' }
		],
		[
			{ labels: ['start_date'], value: date(selectionCriteriaStartDate), type: 'date' }
		],
		[
			{ labels: ['end_date'], value: date(selectionCriteriaEndDate), type: 'date' }
		],
		[
			{ labels: ['customers'], value: format(numberOfMasterFilesCustomers, null, null), type: 'number', basis: '14%' }
		],
		[
			{ labels: ['suppliers'], value: format(numberOfMasterFilesSuppliers, null, null), type: 'number', basis: '13%' }
		],
		[
			{ labels: ['Purchase Invoices'], value: format(numberOfSourceDocumentsPurchaseInvoices, null, null), type: 'number' }
		],
		[
			{ labels: ['Sales Invoices'], value: format(numberOfSourceDocumentsSalesInvoices, null, null), type: 'number' }
		],
		[
			{ labels: ['Settlements And Payments'], value: format(numberOfSettlementAndPayments, null, null), type: 'number' }
		],

	]
}

const mapper = (json, card, details) => {
	
	return {
		...json,
		details: details ? mapDetails(json) : [],
		card: card ? mapCard(json) : []
	}
}

const header = [
	{ label: ['Registration Number'], align: 'left', basis: '23%' },
	{ label: ['Software Company Name'], align: 'left', columnName: "software_company_name", sortable: true, basis: '35%' },
	{ label: ['FileName'], align: 'left'},
	{ label: ['Version'], align: 'left' },
	{ label: ['Start'], align: 'center', columnName: "selection_criteria_start_date", sortable: true },
	{ label: ['End'], align: 'center', columnName: "selection_criteria_end_date", sortable: true },
	{ label: ['Customers'], align: 'left', basis: '14%' },
	{ label: ['Suppliers'], align: 'left', basis: '13%' },
	{ label: ['Purchase Invoices'], align: 'left' },
	{ label: ['Sales Invoices'], align: 'left' },
	{ label: ['Settlements And Payments'], align: 'left' },
]

const headerMapper =  {
	map: mapper,
	header
}

export default headerMapper;