const mapDetails = json => {
    return [
        { label: '', value: "", type: 'string' },
    ];
};

const mapCard = json => {
    const {
        taxCode, taxPercentage, country, description, inactiveTax, taxType, transactionCode
    } = json;

    return [
        [{ labels: ["description"], value: description, type: "string" }],
        [{ labels: ["tax_percentage"], value: taxPercentage, type: "number" }],
        [{ labels: ["country"], value: country, type: "string" }],
        [{ labels: ["tax_code"], value: taxCode, type: "string" }],
        [{ labels: ["tax_type"], value: taxType, type: "string" }],
        [{ labels: ["transaction_code"], value: transactionCode, type: "string" }],
        [{ labels: ["inactive_tax"], value: inactiveTax ? inactiveTax : '', type: "string" }],
    ];
};

const mapper = (json, card, details) => {
    return {
        ...json,
        name: json.customerId,
        shortName: json.customerId,
        details: details ? mapDetails(json) : [],
        card: card ? mapCard(json) : []
    };
};

const header = [
    { label: ['Description'], align: 'left', columnName: "descrition", sortable: true },
    { label: ['Tax Percentage'], align: 'center', columnName: "tax_percentage", sortable: true },
    { label: ['Country'], align: 'rigth', columnName: "tax_percentage", sortable: true },
    { label: ['Tax Code'], align: 'left', columnName: "tax_code", sortable: true },
    { label: ['Tax Type'], align: 'rigth', columnName: "tax_type", sortable: true },
    { label: ['Transaction Code'], align: 'rigth', columnName: "transaction_code", sortable: true },
    { label: ['Inactive Tax'], align: 'rigth', columnName: "inactive_tax", sortable: true },
]

const taxeMapper = {
    map: mapper,
    header
};

export default taxeMapper;
