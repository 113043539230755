import { date } from '../../../utils'

const mapDetails = (json) => {
	const {
		assetId,
        assetsRegistrationId,
        acquisitionDate,
        startUpDate,
        description,
        accountId,
        taxEntity
	} = json
	return [
		[
            { label: 'Account ID', value: accountId, type: 'string' },
            { label: 'Asset', value: assetId, type: 'string' },
            { label: 'Entity', value: taxEntity, type: 'string' },
            { label: 'Registration ID', value: assetsRegistrationId, type: 'string' },
            { label: "Acquisition", value: date(acquisitionDate), type: 'date' },
            { label: "Start up Date", value: date(startUpDate), type: 'date' },
            { label: "Description", value: description, type: 'string' }
        ]
	]
}

const header = [
    { label: ["Account Id"], align: 'left', columnName: "account_id", sortable: true },
    { label: ["Description"], align: 'left', columnName: "description", sortable: true },
    { label: ["Asset"], align: 'left', columnName: "asset_id", sortable: true },
    { label: ["Registration ID"], align: 'left', columnName: "assets_registration_id", sortable: true },
    { label: ["Acquisition"], align: 'center', columnName: "acquisition_date", sortable: true },
    { label: ["Start up Date"], align: 'center', columnName: "start_up_date", sortable: true }
]

const mapCard = (json) => {
	const {
        assetId,
        assetsRegistrationId,
        acquisitionDate,
        startUpDate,
        description,
        accountId
	} = json

	return [
        [
            { labels: ['accountId'], value: accountId, type: 'string' }
        ],
        [
            { labels: ['description'], value: description, type: 'string' }
        ],
        [
            { labels: ['asset'], value: assetId, type: 'string' }
        ],
        [
            { labels: ['registration_id'], value: assetsRegistrationId, type: 'string' }
        ],
        [
            { labels: ['acquisition'], value: date(acquisitionDate), type: 'date' }
        ],
        [
            { labels: ['startUpDate'], value: date(startUpDate), type: 'date' }
        ]
	]
}

const mapper = (json, card, details) => {
    const name = json.assetID
    
    return {
        ...json,
        __typename: json.__typename,
        id: json.id,
		name,
        shortName: name,
        details: details ? mapDetails(json) : [],
		card: card ? mapCard(json) : []
	}
}

const assetMapper = {
    map: mapper,
    header
}
export default assetMapper;