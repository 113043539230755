const modals = (state = [], action) => {
	switch (action.type) {
		case 'PUSH_MODAL': {
			const result = JSON.parse(JSON.stringify(state))
			result.push({
				id: action.id,
				args: action.args,
				...(action.payload || {})
			})
			return result
		}
		case 'POP_MODAL': {
			const result = JSON.parse(JSON.stringify(state))

			const modalIndex = state.findIndex(({ id }) => (action.id === id))
			if (modalIndex >= 0) {
				result[modalIndex].transition = 'leave'
			}
			return result
		}
		case 'REMOVE_MODAL': {
			const result = JSON.parse(JSON.stringify(state))
			const modalIndex = state.findIndex(({ id }) => (action.id === id))
			if (modalIndex >= 0) {
				result.splice(modalIndex, 1)
			}
			return result
		}
		default: break
	}
	return state
}

export default modals