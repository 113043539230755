const downloads = (state = false, action) => {
	switch (action.type) {
		case 'DOWNLOAD_START': {
			return true
		}
		case 'DOWNLOAD_END': {
			return false
		}
		default: break
	}
	return state
}

export default downloads