import { format } from "../../../utils"

const mapDetails = (json) => {
	const {
		lineNumber,
		type,
		quantity,
		transaction,
		supplier,
		customer,
		product,
		bookValue,
		accountId,
		debitCreditIndicator,
		unitOfMeasure,
		movementReference
	} = json
	return [
		[
			{ label: 'Movement Reference', value: movementReference, type: 'string' },
			{ label: 'Line Number', value: lineNumber, type: 'string' },
			{ label: 'Account Id', value: accountId, type: 'string' },
			{
				label: 'Product',
				value: {
					id: product.id,
					name: product.name,
					__typename: 'Product'
				},
				type: 'object'
			},
			customer ? {
				label: 'Customer',
				value: {
					id: customer.id,
					name: customer.name,
					__typename: 'Customer'
				},
				type: 'object'
			} : null,
			supplier ? {
				label: 'Supplier',
				value: {
					id: supplier.id,
					name: supplier.name,
					__typename: 'Supplier'
				},
				type: 'object'
			} : null,
			transaction ? {
				label: 'GlTransaction Id',
				value: {
					id: transaction.id,
					name: transaction.name,
					__typename: 'Transaction'
				},
				type: 'object'
			} : null,
			{
				label: 'Movement Type',
				value: type,
				type: 'string'
			},
		],
		[
			{ label: 'Book Value', value: format(bookValue, 'fullValue'), type: 'currency' },
			{ label: 'Debit Credit Indicator', value: debitCreditIndicator, type: 'string' }
		],
		[
			{
				label: 'Quantity',
				value: quantity,
				type: 'number'
			},
			{
				label: 'Unit Of Measure',
				value: unitOfMeasure,
				type: 'string'
			},
		],
	]
}

const mapCard = (json) => {
	const {
		lineNumber,
		productCode,
		quantity,
		unitOfMeasure,
		bookValue,
		debitCreditIndicator,
		accountId
	} = json

	return [
		[
			{ labels: ['Line Number'], value: `${lineNumber}`, type: 'string' }
		],
		[
			{ labels: ['accountID'], value: accountId, type: 'string' }
		],
		[
			{ labels: ['code'], value: productCode || 'N/A', type: 'number'}
		],
		[
			{ labels: ['quantity'], value: quantity, type: 'number' }
		],
		[
			{ labels: ['unit_of_measure'], value: unitOfMeasure, type: 'string', align: 'center' }
		],
		[
			{ labels: ['book_value'], value: format(bookValue), type: 'currency' }
		],
		[
			{ labels: ['debit_credit_indicator'], value: debitCreditIndicator, type: 'string', align: 'center' }
		]
	]
}

const mapper = (json, card, details) => {

	return {
		...json,
		name: json.productCode,
		shortName: json.productCode,
		details: details ? mapDetails(json) : [],
		card: card ? mapCard(json) : []
	}
}

const header = [
	{ label: ["Line Number"], align: 'left' },
	{ label: ["Account Id"], align: 'left', columnName: "account_id", sortable: true },
	{ label: ["CODE"], align: 'center', columnName: "product_code", sortable: true },
	{ label: ["Quantity"], align: 'center', columnName: "quantity", sortable: true },
	{ label: ["Unit Of Measure"], align: 'center', columnName: "unit_of_measure", sortable: true },
	{ label: ["Book Value"], align: 'right', columnName: "book_value", sortable: true },
	{ label: ["Debit Credit Indicator"], align: 'center', columnName: "debit_credit_indicator", sortable: true },
]

const stockmovementlineMapper = {
	map: mapper,
	header
}

export default stockmovementlineMapper;