import AppByFile from "../controllers/AppByFile/AppByFile";
import { Grid, charts, Widget } from "colbi_web_ui/lib/components";

const queries = (args = {}) => {
	return args.fiscalYear
		? [
				{
					resource: "dashboardeinvoice",
					args: {
						projectId: args.projectId,
						fiscalYear: args.fiscalYear,
					},
					body: "...dashboardeinvoice",
				},
				{
					resource: `dashboardeinvoicesummary`,
					args: {
						projectId: args.projectId,
						fiscalYear: args.fiscalYear,
					},
					body: "infos",
				},
		  ]
		: [];
};

const dashboardbill = (path, navigation, exact, title) => ({
	path,
	navigation,
	component: AppByFile,
	queries,
	exact,
	title: [title],
	components: [
		{
			component: Widget,
			modifiers: ["--root-margin-bottom-normal"],
			props: {
				title: "label(E-Invoice KPIS)",
				icon: "stack",
				children: [
					{
						component: Grid,
						props: {
							columnsTemplate: [1],
							items: [
								{
									data: [
										{
											name: "label(Total E-Invoice Submitted)",
											value: "query(0).totalEInvoiceSubmitted",
											icon: "severity-0",
										},
										{
											name: "label(Total E-Invoice Cancellactions)",
											value: "query(0).totalEInvoiceCancellactions",
											icon: "severity-3",
										},
										{
											name: "label(Total E-Invoice Error)",
											value: "query(0).totalEInvoiceError",
											icon: "severity-1",
										},
									],
								},
							],
							cards: [charts.KpisLegend],
						},
					},
				],
			},
		},
		{
			component: Widget,
			modifiers: ["--root-margin-bottom-normal"],
			props: {
				title: "label(E-Invoice Submitted KPIS)",
				icon: "upload",
				children: [
					{
						component: Grid,
						modifiers: ["--grid-border-inline"],
						props: {
							columnsTemplate: [1],
							items: [
								{
									data: [
										{
											name: "label(Total Submitted With EWB)",
											value: "query(0).totalSubmittedWithEWB",

											icon: "projects",
										},
										{
											name: "label(Total Submitted Without EWB)",
											value: "query(0).totalSubmittedWithoutEWB",
											icon: "work-documents",
										},
									],
								},
							],
							cards: [charts.KpisLegend],
						},
					},
				],
			},
		},

		{
			component: Widget,
			modifiers: ["--root-margin-bottom-normal"],
			props: {
				title: "label(E-Invoice per Month)",
				icon: "top",
				children: [
					{
						component: Grid,
						modifiers: ["--grid-border-inline"],
						props: {
							columnsTemplate: [1],
							aspect: ["31x10"],
							items: [
								{
									data: [
										"query(1).infos.submit",
										"query(1).infos.invalid",
										"query(1).infos.cancel",
									],
									dataLabels: ["SUBMITED", "ERROR", "CANCELLED"],
								},
							],
							cards: [charts.BarChart],
						},
					},
				],
			},
		},
	],
});

export default dashboardbill;
