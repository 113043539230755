import { date, datetime } from "../../../utils"

const mapDetails = (json) => {
	const {
		description,
		transactionDate,
		systemId,
		systemEntryDate,
		glPostingDate,
		transactionId,
		period,
		periodYear,
		journalType

	} = json

	return [
		[
			{ label: 'Journal Type', value: journalType, type: 'string' },
			{ label: 'Period', value: period, type: 'number' },
			{ label: 'Period Year', value: periodYear, type: 'number' },
			{ label: 'Transaction Date', value: date(transactionDate) || 'N/A', type: 'string' },
			{ label: 'Post Date', value: date(glPostingDate) || 'N/A', type: 'string' }
		],
		[
			{ label: 'System Id', value: systemId, type: 'string' },
			{ label: 'System Date', value: datetime(systemEntryDate), type: 'date' },
			{ label: 'Transaction Id', value: transactionId, type: 'string' },
		],
		[
			{ label: 'Description', value: description || 'N/A', type: 'string' }
		]
	]
}

const mapCard = json => {
	const {
		journalId,
		journalDescription,
		transactionId,
		glPostingDate,
		period,
		journalType,
		description,
	} = json;

	return [
		[{ labels: ["journal Id"], value: journalId || "N/A", type: "string", basis: '16%' }],
		[{ labels: ["Journal Description"], value: journalDescription, type: "string", basis: '45%'}],
		[{ labels: ["Transaction Id"], value: transactionId, type: "string"}],
		[{ labels: ["Description"], value: description, type: "string" , basis: '55%'}],
		[{ labels: ["Posting Date"], value: date(glPostingDate), type: "string" , basis: '15%'}],
		[{ labels: ["Period"], value: period, type: "number", basis: '10%'}],
		[{ labels: ["Journal Type"], value: journalType, type: "string", basis: '30%'}],
	];
};

const mapReports = (reports) => {
	const [balanceByPeriod, transactionsByPeriod] = reports
	return (balanceByPeriod || []).length || (transactionsByPeriod || []).length ? [
		{
			type: 'bar',
			icon: 'comparation',
			title: 'Balance at period',
			colspan: 1,
			data: [balanceByPeriod]
		},
		{
			type: 'bar',
			icon: 'comparation',
			title: 'Period transactions',
			colspan: 1,
			data: [transactionsByPeriod]
		},
	] : null
}

const mapper = (json, card, details) => {
	return {
		...json,
		name: `${ json.accountId } - ${ json.description }`,
		shortName: json.accountId,
		details: details ? mapDetails(json) : [],
		card: card ? mapCard(json) : [],
		reports: mapReports(json.reports || [])
	}
}

const header = [
	{ label: ["journal Id"], align: 'left', columnName: "journal_id", sortable: true, basis: '16%'},
	{ label: ["Journal Description"], align: 'left', columnName: "journal_description", basis: '45%' },
	{ label: ["Transaction Id"], align: 'left', columnName: "transaction_id", sortable: true },
	{ label: ["Description"], align: 'left', columnName: "description" , basis: '55%'},
	{ label: ["Gl Posting Date"], align: 'left', columnName: "gl_posting_date", sortable: true, basis: '15%'},
	{ label: ["Period"], columnName: "period", sortable: true, basis: '10%'},
	{ label: ["Journal Type"], align: 'left', columnName: "journal_type", sortable: true , basis: '30%'},
]
const generalledgerMapper = {
	map: mapper,
	header
}

export default generalledgerMapper;