import React from "react"
import { UserRoles } from '../../enums/userRoles'
import { datetime } from '../../utils/datetime'

const HeaderListButtons = (props) => {
    const { showGenerationButton, user, styles, params, pushModal, showEfaturaRequestData, i18n, reload, RegularButton, showGenerationPtButton, showRefreshButton } = props
    const validateUser = [UserRoles.ADMIN, UserRoles.OWNER, UserRoles.WRITER, UserRoles.PP_SUPPORT, UserRoles.SUPPORT].includes(user.role)
    return (
       <>
        {(showGenerationPtButton && validateUser) &&
                <div>
                    <div style={{ display: "flex", justifyContent: "flex-end" }}>
                        <RegularButton
                            primary
                            icon='success'
                            children={i18n('SAF-T PT Generation')}
                            className={`${styles['simple-button']} ${styles['btn-mark-active']}`}
                            onClick={() => {
                                pushModal({
                                    title: "GENERATE SAF-T PT",
                                    data: {
                                        projectId: params.projectId,
                                        fiscalYear: params.fiscalYear
                                    },
                                    args: params,
                                    view: 'GeneratorPtForm'
                                }).then(()=> reload && reload());
                            }
                            }
                        />
                    </div>
                </div>
            }
            {(showGenerationButton && validateUser) &&
                <div>
                    <div style={{ display: "flex", justifyContent: "flex-end" }}>
                        <RegularButton
                            primary
                            icon='success'
                            children={i18n('i.SAF-T Generation')}
                            className={`${styles['simple-button']} ${styles['btn-mark-active']}`}
                            onClick={() => {
                                pushModal({
                                    title: "GENERATE I.SAF-T",
                                    data: {
                                        projectId: params.projectId,
                                        fiscalYear: params.fiscalYear
                                    },
                                    args: params,
                                    view: 'GeneratorForm'
                                })
                            }
                            }
                        />
                    </div>
                </div>
            }
            {
                <div className={styles["actions-buttons"]}>
                    <div>
                        {(showEfaturaRequestData && validateUser) &&
                            <>
                                <RegularButton
                                    icon='refresh'
                                    onClick={() => reload && reload()}
                                    iconClassName={`${styles['small-background-color']}`}
                                    className={`${styles['simple-button']} ${styles['btn-refresh']} ${styles['btn-background-color']}`} />
                                <small className={`${styles['btn-refresh']} ${styles['small-background-color']}`}>{i18n`Last update:`} {datetime(new Date())}</small>
                            </>
                        }
                    </div>
                </div>
            }
            {(showEfaturaRequestData && validateUser) &&
                <div>
                    <div style={{ display: "flex", justifyContent: "flex-end" }}>
                        <div style={{ display: "flex", justifyContent: "flex-end", marginRight: '20px' }}>
                            <RegularButton
                                danger
                                icon='success'
                                className={`${styles['simple-button']} ${styles['btn-delete-permanently']}`}
                                onClick={() => {
                                    pushModal({
                                        title: i18n`Delete Efatura Data from the system`,
                                        data: {
                                            projectId: params.projectId,
                                            fiscalYear: ""
                                        },
                                        args: params,
                                        view: 'DeleteUserDataForm',
                                        modalType: 'center'
                                    }).then(() => {
                                        window.location.reload();
                                    });
                                }
                                }
                                title={i18n`This action will delete the data from the system and, consequently, making the data unavailable for the selected fiscal year`}
                            >
                                {i18n`Delete permanently`}
                            </RegularButton>
                        </div>
                        <RegularButton
                            primary
                            icon='success'
                            className={`${styles['simple-button']} ${styles['btn-mark-active']}`}
                            onClick={() => {
                                pushModal({
                                    title: i18n`Request Efatura Data to Tax Authority Website`,
                                    data: {
                                        projectId: params.projectId,
                                        fiscalYear: ""
                                    },
                                    args: params,
                                    view: 'RequestUserDataForm',
                                    modalType: 'center'
                                }).then(() => {
                                    window.location.reload();
                                })
                            }
                            }
                        >{i18n`Request Data`}</RegularButton>
                    </div>
                    <p></p>
                </div>
            }
            { (showRefreshButton && validateUser) &&
                <div className={styles["actions-buttons"]}>
                    <div>
                            <RegularButton
                                icon='refresh'
                                onClick={() => reload && reload()}
                                iconClassName={`${styles['small-background-color']}`}
                                className={`${styles['simple-button']} ${styles['btn-refresh']} ${styles['btn-background-color']}`} />
                            <small className={`${styles['btn-refresh']} ${styles['small-background-color']}`}>{i18n`Last update:`} {datetime(new Date())}</small>
                    </div>
                </div>
            }
        </>
    )
}

export default HeaderListButtons