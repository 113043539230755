import { format } from "../../../utils"

const mapDetails = (json) => {
	const {
		accountId,
		taxonomyCode,
		taxEntity,
		description,
		openingDebit,
		closingDebit,
		openingCredit,
		closingCredit,
		groupingCategory,
		groupingCode,
		taxonomyReference,
		level,
		leafAccount
	} = json

	return [
		[
			{ label: 'Account Id', value: accountId, type: 'string' },
			{ label: 'Category', value: groupingCategory, type: 'string' },
			{ label: 'Taxonomy', value: taxonomyCode || 'N/A', type: 'string' },
			{ label: 'Grouping Code', value: groupingCode || 'N/A', type: 'string' },
			{ label: 'Taxonomy Reference', value: taxonomyReference, type: 'string' }
		],
		[
			{ label: 'Opening Debit', value: format(openingDebit, 'fullValue'), type: 'currency' },
			{ label: 'Closing Debit', value: format(closingDebit, 'fullValue'), type: 'currency' },
			{ label: 'Opening Credit', value: format(openingCredit, 'fullValue'), type: 'currency' },
			{ label: 'Closing Credit', value: format(closingCredit, 'fullValue'), type: 'currency' }
		],
		[
			{ label: 'Level', value: level || 'N/A', type: 'string' },
			{ label: 'Description', value: description || 'N/A', type: 'string' },
			{ label: 'Entity', value: taxEntity || 'N/A', type: 'string' },
			{ label: 'Leaf Account', value: leafAccount, type: 'boolean' }
		]
	]
}

const mapCard = (json) => {
	const {
		accountId,
		taxonomyCode,
		groupingCode,
		groupingCategory,
		description,
		openingDebit,
		closingDebit,
		openingCredit,
		closingCredit
	} = json

	return [
		[{ labels: ['account id'], value: `${accountId}`, type: 'number', align: 'left', basis: '14%' }],
		[{ labels: ['description'], value: `${description}`, type: 'string', align: 'left', basis: '50%' }],
		[{ labels: ['Category'], value: groupingCategory, type: 'string', align: 'center' }],
		[{ labels: ['Grouping Code'], value: groupingCode || 'N/A', type: 'string', align: 'center' }],
		[{ labels: ['taxonomy'], value: `${taxonomyCode || 'N/A'}`, type: 'number' }],
		[{ labels: ['opening_debit'], value: format(openingDebit), type: 'currency' }],
		[{ labels: ['opening_credit'], value: format(openingCredit), type: 'currency' }],
		[{ labels: ['closing_debit'], value: format(closingDebit), type: 'currency' }],
		[{ labels: ['closing_credit'], value: format(closingCredit), type: 'currency' }]
	]
}

const mapReports = (reports) => {
	const [balanceByPeriod, transactionsByPeriod] = reports
	return (balanceByPeriod || []).length || (transactionsByPeriod || []).length ? [
		{
			type: 'bar',
			icon: 'comparation',
			title: 'Balance at period',
			colspan: 1,
			data: [balanceByPeriod]
		},
		{
			type: 'bar',
			icon: 'comparation',
			title: 'Period transactions',
			colspan: 1,
			data: [transactionsByPeriod]
		},
	] : null
}

const mapper = (json, card, details) => {
	return {
		...json,
		name: `${json.accountId} - ${json.description}`,
		shortName: json.accountId,
		details: details ? mapDetails(json) : [],
		card: card ? mapCard(json) : [],
		reports: mapReports(json.reports || [])
	}
}

const header = [
	{ label: ["Account ID"], align: 'left', columnName: "account_id", sortable: true, basis: '14%' },
	{ label: ["Description"], align: 'left', basis: '50%' },
	{ label: ["Grouping Category"], align: 'left', columnName: "grouping_category", sortable: true },
	{ label: ["Grouping Code"], align: 'center', columnName: "grouping_code", sortable: true },
	{ label: ["Taxonomy Code"], align: 'center', columnName: "taxonomy_code", sortable: true },
	{ label: ["Opening Debit"], align: 'right', columnName: "opening_debit_balance", sortable: true, totalizerName: 'total_opening_debit' },
	{ label: ["Opening Credit"], align: 'right', columnName: "opening_credit_balance", sortable: true, totalizerName: 'total_opening_credit' },
	{ label: ["Closing Debit"], align: 'right', columnName: "closing_debit_balance", sortable: true, totalizerName: 'total_closing_debit' },
	{ label: ["Closing Credit"], align: 'right', columnName: "closing_credit_balance", sortable: true, totalizerName: 'total_closing_credit' },
]

const generalledgerMapper = {
	map: mapper,
	header
}

export default generalledgerMapper;