import {format, date} from '../../../utils'

const mapDetails = (json) => {
    const {
      ownerId, ownerName, accountId, streetName, number, city, PostalCode, country, addressType, region, fullAddress, sharesQuantity, sharesAmount, sharesType, sharesAcquisitionDate, sharesTransfersDate
    } = json;
    return [
        [
            {label: 'owner Id', value: ownerId, type: 'string'},
            {label: 'Name', value: ownerName, type: 'string'},
            {label: "Country", value: country, type: 'string'},
            {label: 'Shares Transfers Date', value: date(sharesTransfersDate), type: 'date'},
            {label: 'Shares Acquisition Date', value: date(sharesAcquisitionDate), type: 'date'}
        ],
        [
            {label: 'Shares Quantity', value: format(sharesQuantity, 'fullValue'), type: 'currency'},
            {label: 'Shares Amount', value: format(sharesAmount, 'fullValue'), type: 'currency'}
        ],
        [
            {label: 'Shares Type', value: sharesType, type: 'string'},
            {label: 'Account', value: accountId || 'N/A', type: 'string'}
        ],[
            {label: 'streetName', value: streetName, type: 'string'},
            {label: 'number', value: number, type: 'string'},
            {label: 'city', value: city, type: 'string'},
            {label: 'PostalCode', value: PostalCode, type: 'string'},
            {label: 'addressType', value: addressType, type: 'string'},
            {label: 'region', value: region, type: 'string'},
            {label: 'fullAddress', value: fullAddress, type: 'string'}
        ]
    ]
};

const header = [
    {label: ["Name"], align: 'left'},
    {label: ["Owner ID"], align: 'left'},
    {label: ["Country"], align: 'left'},
    {label: ["Shares Acquisition Date"], align: 'center'},
    {label: ["Shares Transfers Date"], align: 'center'},
    {label: ["Shares Quantity"], align: 'center'},
    {label: ["Shares Amount"], align: 'right'}
];

const mapCard = (json) => {
    const {
        ownerId, ownerName, country, sharesQuantity, sharesAmount, sharesAcquisitionDate, sharesTransfersDate
    } = json;

    return [
        [
            {labels: ['name'], value: ownerName, type: 'string'}
        ],
        [
            {labels: ['owner_id'], value: ownerId, type: 'string'}
        ],
        [
            {labels: ['country'], value: country, type: 'string'}
        ],
        [
            {labels: ['acquisition_date'], value: date(sharesAcquisitionDate), type: 'date'}
        ],
        [
            {labels: ['transfer_date'], value: date(sharesTransfersDate), type: 'date'}
        ],
        [
            {labels: ['shares_quantity'], value: sharesQuantity, type: 'number'}
        ],
        [
            {labels: ['shares_amount'], value: format(sharesAmount), type: 'currency'}
        ]
    ]
};

const mapper = (json, card, details) => {
    const name = json.name;

    return {
        ...json,
        __typename: json.__typename,
        id: json.id,
        name,
        shortName: name,
        details: details ? mapDetails(json) : [],
        card: card ? mapCard(json) : []
    }
};
const ownerMapper = {
    map: mapper,
    header
}

export default ownerMapper;