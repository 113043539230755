import { format, date } from "../../../utils";

const mapDetails = json => {
	const {
		transactionType,
		period,
		date: dateAssetTransaction,
		description,
		transactionAmount,
		bookValueOnTransaction,
		acquisitionAndProductionCostsOnTransaction,
		taxEntity,
		supplier,
		transaction,
		asset
	} = json;
	return [
		[
			{ label: "Type", value: transactionType, type: "string" },
			{ label: "Entity", value: taxEntity, type: "string" },
			{ label: "Period", value: period, type: "number" },
			{ label: "Date", value: date(dateAssetTransaction), type: "date" },
			{ label: "Description", value: description, type: "string" }
		],
		[
			{ label: "Amount", value: format(transactionAmount, 'fullValue'), type: "currency" },
			{
				label: "Book Value",
				value: format(bookValueOnTransaction, 'fullValue'),
				type: "currency"
			},
			{
				label: "Acquisition and Production Costs",
				value: format(acquisitionAndProductionCostsOnTransaction, 'fullValue'),
				type: "currency"
			}
		],
		[
			transaction
				? {
					label: "Transaction ID",
					value: {
						id: transaction.id,
						name: transaction.name,
						__typename: "LtTransaction"
					},
					type: "object"
				}
				: {
					label: "Transaction ID",
					value: "N/A",
					type: "string"
				},

				asset
				? {
					label: "Asset ID",
					value: { id: asset.id, name: asset.name, __typename: "Asset" },
					type: "object"
				}
				: { label: "Asset ID", value: "N/A", type: "string" },

				supplier
				? {
					label: "Supplier",
					value: {
						id: supplier.id,
						name: supplier.name,
						__typename: "Supplier"
					},
					type: "object"
				}
				: { label: "Supplier", value: "N/A", type: "string" }
		]
	];
};

const header = [
	{ label: ["Asset Transaction ID"], align: "left", columnName: "asset_transaction_id", sortable: true, basis: '25%' },
	{ label: ["Asset ID"], align: "left", columnName: "asset_id", sortable: true , basis: '20%'},
	{ label: ["Type"], align: "left", columnName: "asset_transaction_type", sortable: true, basis: '15%' },
	{ label: ["Description"], align: "left" , columnName: "description", sortable: true,basis: '30%'},
	{ label: ["Period"], align: "right", columnName: "period", sortable: true, basis: '12%' },
	{ label: ["Asset Transaction Date"], align: "center", columnName: "asset_transaction_date", sortable: true },
	{ label: ["Supplier"], align: "left", columnName: "supplier_id", sortable: true },
	{ label: ["Transaction"], align: "left", columnName: "transaction_id", sortable: true },
	{ label: ["Asset Transaction Amount"], align: "right", columnName: "asset__asset_transaction_amount", sortable: true },
	{ label: ["Book Value on Transaction"], align: "right", columnName: "asset__book_value_on_transaction", sortable: true },
	{ label: ["Acquisition And Production Costs On Transaction"], align: "right", columnName: "asset__acquisition_and_production_costs_on_transaction", sortable: true }
];

const mapCard = json => {
	const {
		description,
		transactionType,
		assetTransactionId,
		period,
		date: dateAssetTransaction,
		transactionAmount,
		bookValueOnTransaction,
		acquisitionAndProductionCostsOnTransaction,
		supplier,
		transaction,
		asset
	} = json;

	return [
		[{ labels: ["id"], value: assetTransactionId, type: "string" , basis: '25%'}],
		[asset
			? {
				label: "transaction",
				value: {
					id: asset.id,
					name: asset.name,
					__typename: "Asset"
				},
				type: "object"
			}
			: null],
		[{ labels: ["type"], value: transactionType, type: "string", basis: '15%' }],
		[{ labels: ["description"], value: description, type: "string",basis: '30%' }],
		[{ labels: ["period"], value: period, type: "number", align: 'right', basis: '12%' }],
		[{ labels: ["date"], value: date(dateAssetTransaction), type: "date" }],
		[
			supplier
				? {
					label: "supplier",
					value: {
						id: supplier.id,
						name: supplier.name,
						__typename: "Supplier"
					},
					type: "object"
				}
				: null
		],
		[
			transaction
				? {
					label: "transaction",
					value: {
						id: transaction.id,
						name: transaction.name,
						__typename: "Transaction"
					},
					type: "object"
				}
				: null
		],
		[
			{ labels: ["amount"], value: format(transactionAmount), type: "currency" }
		],
		[
			{
				labels: ["book"],
				value: format(bookValueOnTransaction),
				type: "currency"
			}
		],
		[
			{
				labels: ["acquisition"],
				value: format(acquisitionAndProductionCostsOnTransaction),
				type: "currency"
			}
		]
	];
};

const mapper = (json, card, details) => {
	const name = json.asset_transaction_id;

	return {
		...json,
		__typename: json.__typename,
		id: json.id,
		name,
		shortName: name,
		details: details ? mapDetails(json) : [],
		card: card ? mapCard(json) : []
	};
};

const assettransactionMapper = {
	map: mapper,
	header
};

export default assettransactionMapper;
