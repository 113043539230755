import { List, rows, Filters } from "colbi_web_ui/lib/components";
import theme from "colbi_web_ui/lib/style/_theme.json";
import { parseFilter } from "colbi_web_ui/lib/utils";
import ExportButton from "../components/ExportButton/ExportButton";
import AppByFile from "../controllers/AppByFile/AppByFile";

const queries = (args = {}) => {
	let result = [
		{
			resource: args.id,
			args: {
				projectId: args.projectId,
				fiscalYear: args.fiscalYear,
                modulePrefix: args.modulePrefix,
				locale: args.locale,
				filter: args.filter
					? { type: "[FilterInput!]", value: parseFilter(args.filter) }
					: undefined,
				sort: args.sort
					? { type: "[SortInput!]", value: parseFilter(args.sort) }
					: undefined,
				page: parseInt(args.page, 10) || 0,
			},
			body: `__typename, items{ ...${args.id} }, hasMore, total, totalizers, filters(projectId:$projectId,fiscalYear:$fiscalYear,modulePrefix:$modulePrefix){ prop, name, type, list, disableOperators, mandatory }`,
			downloadable: [
				"offsetting_account",
			].includes(args.id)
				? false
				: true,
			xlsxTranslation: args.id,
		},
	];

	if (args.id === "offsetting_account") {
		result.push(
			{
				resource:`${args.id}_export`,
				args: {
					projectId: args.projectId,
					fiscalYear: args.fiscalYear,
					modulePrefix: args.modulePrefix,
					locale: (args.locale) || 'en',
					filter: args.filter
						? { type: "[FilterInput!]", value: parseFilter(args.filter) }
						: undefined,
					page: parseInt(args.page, 10) || 0,
				},
				body: `report`,
				downloadable: true,
				downloadOnly: true,
				xlsxTranslation: args.id,
			}
		);
	}

	return result;
};

const reportSaftAo = (path, navigation, exact, title) => ({
	path,
	navigation,
	component: AppByFile,
	queries,
	exact,
	title: [title, "label(param(id))"],
	components: [
		{
			component: Filters,
			props: {
				filter: "param(filter)",
				availableFilters: "query(0).filters",
				stickyTop: parseInt(theme.layout.$headerHeight, 10),
				anchor: "results",
			},
		},
		{
			component: ExportButton,
			props: {
				data: "query(0).list",
			}
		},
		{
			component: List,
			modifiers: ["--root-margin-top-small", "--row-style-alternate"],
			props: {
				items: "query(0).list",
				cardKey: "param(id)",
				card: rows.EntityCard,
				metadata: "query(0).metadata",
				page: "param(page)",
				status: "status(0)",
				pageSize: "param(pageSize)",
				pageDefault: 10,
				footer: "query(0).footer",
				header: "query(0).header",
				totalizers: "query(0).totalizers",
				sort: "param(sort)",
			},
		},
	],
});

export default reportSaftAo;
