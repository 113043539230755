import { datetime, date } from '../../utils'
import I18n from '../../I18n'

const STATUS = {
    0 : "IN_PROGRESS",
    1 : "SUCCESS",
    2 : "ERROR"
}
const mapCard = (json) => {
	const {
        message,
        userName,
        dateFrom,
        dateTo,
        fileType,
        createdAt,
        fileLocation,
        status,
        fileTypeName,
        fileId
	} = json

	return [
        [
            {labels: ['message'], value: message, type: 'string', basis: '95%'}
        ],
        [
            {labels: ['user'], value: userName, type: 'string', align: 'center'}
        ],
        [
            {labels: ['date_from'], value: date(dateFrom), type: 'date'},
            {labels: ['date_to'], value: date(dateTo), type: 'date'}
        ],
        [
            {
                labels: ['file_type'],
                value: fileType + " - " + (fileTypeName || 'N/A'),
                type: 'string',
            },
        ], 
        [
            {labels: ['request_at'], value: datetime(createdAt), type: 'datetime'}
        ],
        [
			{ labels: ['status'], value: [status, I18n.translate`${STATUS[status]}`,'center'], type: 'fileStatus', basis: '13%' }
		],
        [
            {
                labels: ['download'],
                value: status === 1 ? [fileLocation, fileId] : null,
                type: 'downloadFile'
            }
        ],
        [
            (status === 2) ?
            {
                labels: ['hide_file'],
                value: fileId || null,
                type: 'hideFile',
                basis: '5%'
            } : { 
                basis: '5%',
                value: ''
                }
        ]
	]
}

const mapper = (json, card) => {
	return {
		...json,
		id: json.id,
		name: json.name,
		shortName: json.name,
		card: card ? mapCard(json) : [],
	}
}

const header = [
	{ label: ['Message'], align: 'left', columnName: "message", basis: '80%' },
	{ label: ['User'], align: 'center', mobileHidden: true },
	{ label: ["date_from", "date_to"], align: 'center', mobileHidden: true, columnName: "uploaded_at"},
	{ label: ['file_type'], align: 'left' },
	{ label: ["requested_at"], align: 'left', mobileHidden: true },
	{ label: ["status"], align: 'left', mobileHidden: true },
	{ label: [""], align: 'center', width: 'actions-width' }
]

const generatordatafileMapper = {
    map: mapper,
    header
};

export default generatordatafileMapper