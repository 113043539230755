import React from "react";

import { ActionButton } from "colbi_web_ui/lib/components/inputs";

import styles from "./AlertDialog.module.sass";

const ConfirmDialog = (props) => {
    const { data, cancel, i18n, confirm } = props
    const { title, text, cancelEvent = true  } = data

    return (
        <div className={styles['root']}>
            <div className={styles['content']}>
                <h5 className={styles['title']}>{title}</h5>
                <small>{text}</small>
                <div className={styles['actions']}>
                    <ActionButton
                        className={styles['action-button']}
                        type="button"
                        primary
                        onClick={ () => { cancelEvent ? cancel && cancel() : confirm && confirm()} }>
                        <strong>{i18n`Confirm`}</strong>
                    </ActionButton>
                </div>
            </div>
        </div>
    )
}

export default ConfirmDialog