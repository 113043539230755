import { datetime } from '../../utils'

const mapCard = (json) => {
	const {
		fileName,
		size,
		uploadedAt,
		uploadedByName,
	} = json

	return [
		[
			{ labels: ['name'], value: fileName, type: 'string', basis: '50%' }
		],
		[
			{ labels: ['user'], value: uploadedByName, type: 'string', mobileHidden: true, basis: '30%' }
		],
		[
			{ labels: ['size'], value: size, type: 'number', basis: '20%', align: 'center' }
		],
		[
			{ labels: ['uploaded'], value: datetime(uploadedAt), type: 'datetime', mobileHidden: true, basis: '20%', align: 'center' }
		]

	]
}

const mapper = (json, card) => {
	return {
		...json,
		id: json.id,
		name: json.name,
		shortName: json.name,
		card: card ? mapCard(json) : [],
		link: {
			to: `/:locale(pt|en|lt|es)?/:projectId/repository/attachments/${json.year}/Pdf/${json.id}`
		}
	}
}

const header = [
	{ label: ['Name'], align: 'left', columnName: "name", sortable: true, basis: '50%' },
	{ label: ['Uploaded by'], align: 'left', mobileHidden: true, basis: '30%' },
	{ label: ['Size'], align: 'center', basis: '20%' },
	{ label: ['Uploaded at'], align: 'center', mobileHidden: true, columnName: "uploaded_at", sortable: true, basis: '20%' },
]

const attachmentMapper = {
	map: mapper,
	header
};

export default attachmentMapper