export const GLOBAL_ROUTE_PREFIX =
	"/:locale(pt|en|lt|es)?/:projectId/:fiscalYear([0-9]{4}|current)/:importId/:modulePrefix";

const proxy = {
	Customer: `${GLOBAL_ROUTE_PREFIX}/master_data/customers/:id`,
	Account: `${GLOBAL_ROUTE_PREFIX}/master_data/accounts/:id`,
	Product: `${GLOBAL_ROUTE_PREFIX}/master_data/products/:id`,
	GeneralLedger: `${GLOBAL_ROUTE_PREFIX}/master_data/general_ledgers/:id`,
	TaxTableEntry: `${GLOBAL_ROUTE_PREFIX}/master_data/tax_table_entries/:id`,
	TaxTableEntryDetail: `${GLOBAL_ROUTE_PREFIX}/master_data/tax_table_entry_details/:id`,
	UomTableEntry: `${GLOBAL_ROUTE_PREFIX}/master_data/uom_table_entries/:id`,
	MovementType: `${GLOBAL_ROUTE_PREFIX}/master_data/movement_types/:id`,
	GeneralLedgerAccount: `${GLOBAL_ROUTE_PREFIX}/master_data/general_ledger_accounts/:id`,
	Owner: `${GLOBAL_ROUTE_PREFIX}/master_data/owners/:id`,
	AnalysisTypeTableEntry: `${GLOBAL_ROUTE_PREFIX}/master_data/analysis_type_table_entries/:id`,
	Asset: `${GLOBAL_ROUTE_PREFIX}/master_data/assets/:id`,
	Header: `${GLOBAL_ROUTE_PREFIX}/master_data/headers/:id`,
	Supplier: `${GLOBAL_ROUTE_PREFIX}/master_data/suppliers/:id`,
	PhysicalStock: `${GLOBAL_ROUTE_PREFIX}/master_data/physical_stocks/:id`,
	Journal: `${GLOBAL_ROUTE_PREFIX}/master_data/journals/:id`,
	Transaction: `${GLOBAL_ROUTE_PREFIX}/transactions/:id`,
	Invoice: `${GLOBAL_ROUTE_PREFIX}/invoices/:id`,
	PurchaseInvoice: `${GLOBAL_ROUTE_PREFIX}/purchase_invoices/:id`,
	StockMovement: `${GLOBAL_ROUTE_PREFIX}/stock_movements/:id`,
	WorkDocument: `${GLOBAL_ROUTE_PREFIX}/work_documents/:id`,
	Payment: `${GLOBAL_ROUTE_PREFIX}/payments/:id`,
	Rule: `${GLOBAL_ROUTE_PREFIX}/audit/rules/:id`,
	AssetTransaction: `${GLOBAL_ROUTE_PREFIX}/asset_transactions/:id`,
};

export default proxy;
