import { date, format } from '../../../utils'

const header = [
    {label: ["invoice No"], align: 'left', basis: '20%'},
    {label: ["invoice Date"], align: 'center'},
    {label: ["gl Posting Date"], align: 'center'},
    {label: ["transaction Id"], align: 'left'},
    {label: ["amount"], align: 'center'},
    {label: ["amount Currency"], align: 'right', totalizerName: 'total_amount_currency', compact: "fullValue" },
    {label: ["amount Currency Code"], align: 'center'},
    {label: ["unpaid Amount"], align: 'right' },
    {label: ["unpaid Amount Currency"], align: 'right', totalizerName: 'total_unpaid_amount_currency', compact: "fullValue"},
    {label: ["unpaid Amount Currency Code"], align: 'center'},
    {label: ["debit Credit Indicator"], align: 'left'}
];

const mapCard = (json) => {
    const {
        invoiceNo,
        invoiceDate,
        glPostingDate,
        transactionId,
        amount,
        amountCurrency,
        amountCurrencyCode,
        unpaidAmount,
        unpaidAmountCurrency,
        unpaidAmountCurrencyCode,
        debitCreditIndicator
    } = json;

    return [
        [
            {labels: ['invoice No'], value: invoiceNo || 'N/A', type: 'string', basis: '20%'},
        ],
        [
            {labels: ['invoice Date'], value: date(invoiceDate) || 'N/A', type: 'date'},
        ],
        [
            {labels: ['gl Posting Date'], value: date(glPostingDate) || 'N/A', type: 'date'},
        ],
        [
            {labels: ['transaction Id'], value: transactionId || 'N/A', type: 'string'},
        ],
        [
            {labels: ['amount'], value: format(amount, "fullValue", null) || 'N/A', type: 'number'},
        ],
        [
            {labels: ['amount Currency'], value: format(amountCurrency, "fullValue") || 'N/A', type: 'currency'},
        ],
        [
            {labels: ['amount Currency Code'], value: amountCurrencyCode || 'N/A', type: 'string', align: 'center'},
        ],
        [
            {labels: ['unpaid Amount'], value: format(unpaidAmount, "fullValue", null) || 'N/A', type: 'number', align: 'right'},
        ],
        [
            {labels: ['unpaid Amount Currency'], value: format(unpaidAmountCurrency, "fullValue") || 'N/A', type: 'currency'},
        ],
        [
            {labels: ['unpaid Amount Currency Code'], value: unpaidAmountCurrencyCode || 'N/A', type: 'string', align: 'center'},
        ],
        [
            {labels: ['debit Credit Indicator'], value: debitCreditIndicator || 'N/A', type: 'string', align: 'center'},
        ]
    ]
};

const mapper = (json, card) => {
    const name = json.customer_id || json.supplier_id;

    return {
        ...json,
        __typename: json.__typename,
        id: json.id,
        name,
        shortName: name,
        card: card ? mapCard(json) : []
    }
};

const openpurchaseinvoiceMapper = {
    map: mapper,
    header
}
export default openpurchaseinvoiceMapper;