import { format } from "../../../utils"


const mapDetails = (json) => {
	const {
		lineNumber,
		debitCreditIndicator,
		creditAmount,
		debitAmount,
		description,
		paymentLineAmount,
		paymentLineAmountCurrencyCode,
		paymentLineCurrencyAmount,
		refNumber,
		customerId,
		supplierId,
		accountId
	} = json
	return [
		[
			{
				label: 'Payment Ref. No.',
				value: refNumber || 'N/A',
				type: 'string'
			},
			{
				label: 'Line Number',
				value: lineNumber || 'N/A',
				type: 'number'
			},
			{
				label: 'accountId',
				value: accountId || 'N/A',
				type: 'string'
			},
			{ label: 'Customer Id', value: customerId, type: 'string' },
			{ label: 'Supplier Id', value: supplierId, type: 'string' },
		],
		[
			{
				label: 'Debit Credit Indicator',
				value: debitCreditIndicator,
				type: 'string'
			},
			{
				label: 'Credit Amount',
				value: format(creditAmount, 'fullValue'),
				type: 'currency'
			},
			{
				label: 'Debit Amount',
				value: format(debitAmount, 'fullValue'),
				type: 'currency'
			}
		],
		[
			{
				label: 'Amount',
				value: format(paymentLineAmount, 'fullValue', null, 2),
				type: 'number'
			},
			{
				label: 'Amount Currency Code',
				value: paymentLineAmountCurrencyCode,
				type: 'string'
			},
			{
				label: 'Currency Amount',
				value: format(paymentLineCurrencyAmount, 'fullValue', null, 2),
				type: 'currency'
			},
		],
		[
			{
				label: 'Description',
				value: description || 'N/A',
				type: 'string'
			},
		],

	]
}

const mapCard = (json) => {
	const {
		lineNumber,
		accountId,
		debitAmount,
		creditAmount,
		description,
		debitCreditIndicator
	} = json
	return [
		[
			{ labels: ['number'], value: lineNumber, type: 'string' }
		],
		[
			{ labels: ['accountId'], value: accountId, type: 'string' }
		],
		[
			{ labels: ['description'], value: description, type: 'string' }
		],
		[
			{ labels: ['debit_amount'], value: format(debitAmount), type: 'currency' }
		],
		[
			{ labels: ['credit_amount'], value: format(creditAmount), type: 'currency' }
		],
		[
			{ labels: ['debit_credit_indicator'], value: debitCreditIndicator, type: 'string', align:'center' }
		]
	]
}

const mapper = (json, card, details) => {
	return {
		...json,
		name: '[' + json.lineNumber + '] ' + json.refNumber,
		shortName: json.refNumber,
		details: details ? mapDetails(json) : [],
		card: card ? mapCard(json) : []
	}
}

const header = [
	{ label: ["Line Number"], align: 'left', columnName: "line_number", sortable: true },
	{ label: ["Account Id"], align: 'left', columnName: "account_id", sortable: true },
	{ label: ["Description"], align: 'left', columnName: "description", sortable: true },
	{ label: ["Debit"], align: 'right', columnName: "debit_amount", sortable: true },
	{ label: ["Credit"], align: 'right', columnName: "credit_amount", sortable: true },
	{ label: ["Debit Credit Indicator"], align: 'center', columnName: "Debit Credit Indicator", sortable: true }
]

const paymentlineMapper = {
	map: mapper,
	header
}

export default paymentlineMapper;