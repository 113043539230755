import { format, date } from '../../../utils'

const mapDetails = (json) => {
	const {
		taxType,
        code,
        foreignCurrency,
        effectiveDate,
        expirationDate,
        taxPercentage,
        flatTaxRateAmount,
        flatTaxRateCurrencyAmount,
        country,
        description,
        stiTaxCode
	} = json

	return [
		[
			{ label: 'Tax Type', value: taxType, type: 'string' },
			{ label: 'Code', value: code, type: 'string' },
			{ label: 'Flat Tax Rate Currency Code', value: foreignCurrency, type: 'string' },
			{ label: 'Effective Date', value: date(effectiveDate), type: 'date' },
			{ label: 'Expiration Date', value: date(expirationDate), type: 'date' },
			{ label: 'Tax Percentage', value: format(taxPercentage, 'normal', null, 2), type: 'percentage' }
		],
		[
			{ label: 'Flat Tax Rate Amount', value: format(flatTaxRateAmount, 'fullValue'), type: 'currency' },
			{ label: 'Flat Tax Rate Currency Amount', value: format(flatTaxRateCurrencyAmount, 'fullValue'), type: 'currency' }
        ],
        [
            { label: 'STI Tax Code', value: stiTaxCode, type: 'string' },
            { label: 'Country', value: country, type: 'string' },
            { label: 'Description', value: description, type: 'string' }
        ]
	]
}


const mapCard = (json) => {
    const {
        code,
        country,
        taxType,
        stiTaxCode,
        description,
        flatTaxRateCurrencyCode,
        taxPercentage,
        flatTaxRateAmount,
        flatTaxRateCurrencyAmount
    } = json

    return [
        [
            { labels: ['code'], value: code, type: 'string', basis: '15%'}
        ],
        [
            { labels: ['country'], value: country, type: 'string' }
        ],
        [
            { labels: ['type'], value: taxType, type: 'string', align: 'left' }
        ],
        [
            { labels: ['sti_tax_code'], value: stiTaxCode, type: 'string' }
        ],
        [
            { labels: ['description'], value: description, type: 'string', align: 'left' }
        ],
        [
            { labels: ['percentage'], value: format(taxPercentage, 'normal', null, 2), type: 'percentage' }
        ],
        [
            { labels: ['flat_amount'], value: format(flatTaxRateAmount), type: 'currency' }
        ],
        [
            { labels: ['currency_amount'], value: format(flatTaxRateCurrencyAmount), type: 'currency' }
        ],
        [
            { labels: ['foreign_currency'], value: flatTaxRateCurrencyCode, type: 'string', align: 'center' }
        ],
    ]
}

const mapper = (json, card, details) => {
    const name = json.tax_type

    return {
        ...json,
        __typename: json.__typename,
        id: json.id,
        name,
        shortName: name,
        details: details ? mapDetails(json) : [],
        card: card ? mapCard(json) : []
    }
}

const header = [
    { label: ["Code"], align: 'left', columnName: "tax_code", sortable: true, basis: '15%'},
    { label: ["Country"], align: 'left'},
    { label: ["Type"], align: 'left' },
    { label: ["STI Tax Code"], align: 'left', columnName: "stitax_code", sortable: true },
    { label: ["Description"], align: 'left' },
    { label: ["Tax Table Percentage"], align: 'right', columnName: "tax_percentage", sortable: true },
    { label: ["Flat Amount"], align: 'right', columnName: "flat_tax_rate_amount", sortable: true },
    { label: ["Currency Amount"], align: 'right', columnName: "flat_tax_rate_currency_amount", sortable: true },
    { label: ["Currency"], align: 'center', columnName: "flat_tax_rate_currency_code", sortable: true }
]

const taxtableMapper = {
    map: mapper,
    header
}
export default taxtableMapper;