export const buildAddressRow = (address = {}) => {
	return [
		{ label: 'Street', value: address.street || 'N/A', type: 'string' },
		{ label: 'Details', value: address.detail || 'N/A', type: 'string' },
		{ label: 'Number', value: address.number || 'N/A', type: 'string' },
		{ label: 'Postal Code', value: address.postalCode || 'N/A', type: 'string' },
		{ label: 'City', value: address.city || 'N/A', type: 'string' },
		{ label: 'Region', value: address.region || 'N/A', type: 'string' },
		{ label: 'Country', value: address.country || 'N/A', type: 'string' },
	]
}
