import { date, format, getValueFromJson } from "../../utils/index";
import I18n from '../../I18n'
import { datetime, stringifyFilter } from "colbi_web_ui/lib/utils";

const formatValueByType = (json, value, type, options, defaultValue, mask) => {
	let formatedValue = ""
	switch (type) {
		case "date":
			formatedValue = date(value, mask);
			break;
		case "currency":
			formatedValue = format(value, 'normal', getValueFromJson(options, 'currency'), getValueFromJson(options, 'fractionDigits'), getValueFromJson(options, 'locale'));
			break;
		case "number":
			formatedValue = format(
				value,
				'normal',
				null,
				getValueFromJson(options, 'fractionDigits')
			);
			break;
		case "percentage":
			formatedValue = format(value, "normal", null, getValueFromJson(options, 'fractionDigits'));
			break;
		case "datetime":
			formatedValue = datetime(value, mask);
			break;
		case "object":
			const keyValue = (json[getValueFromJson(options, "id")] || {}).value

			const objToReturn = keyValue ?
				{
					id: (json[getValueFromJson(options, "id")] || {}).value,
					name: (json[getValueFromJson(options, "name")] || {}).value,
					__typename: getValueFromJson(options, "__typename")
				} : ((json[getValueFromJson(options, "name")] || {}).value || defaultValue);

			return { type: keyValue ? type : "string", value: objToReturn }
		case "boolean":
			formatedValue = !!value === true ? I18n.translate(`Yes`) : I18n.translate(`No`)
			break;
		default:
			formatedValue = value || defaultValue;
			break;
	}

	return { type, value: formatedValue }
};

const mapCard = (json) => {
	const { TypeByField, EntityName, EntityID, DataFile } = json;
	const entityType = EntityName.split("::").pop();

	const result = Object.keys(TypeByField).map((item)=>{
		const tempItem = item.replace("T", "F");
		return [
			{
				label: tempItem,
				...formatValueByType(json, json[tempItem], TypeByField[item].type, TypeByField[item].options, TypeByField[item].defaultValue, TypeByField[item].mask),
				align: TypeByField[item] && TypeByField[item].align  ? TypeByField[item].align : 'left',
				basis: TypeByField[item].basis
			},
		]
	}).filter((filter)=> filter)

	const linkObj = {
		labels: ["Refers Id"],
		value: {
			id: EntityID,
			name: I18n.translate`${entityType}`,
			__typename: `${entityType}`,
		},
		type: "object",
		align: "center",
	}

	if(EntityName === 'File'){
		linkObj.value[`link`] = { to: `/:locale(pt|en|lt|es)?/:projectId/repository/activity/status/?filter=${stringifyFilter([{ column: 'id', filters: [{ value: `${json.ImportID}` }] }])}` };
	}
	result.push([linkObj]);
	result.push([{labels: ["file"],value: DataFile, type: "string", align: 'left'}])
	return result;
};

const header = (json) => {
	const headerMapping = Object.keys(json).map((elem)=>{
		if(json[elem]){
			const {name: label, align, basis} = json[elem];
			return {label: [label] , align: align ? align : 'left', basis }
		}else{
			return undefined
		}
	})
	headerMapping.push({label: ['reference'], align: 'center'})
	headerMapping.push({label: ['file'], align: 'left'})
	return headerMapping
};

const mapper = (json, card) => {
	return {
		...json,
		__typename: json.__typename,
		card: card ? mapCard(json).filter((elem) => elem.length !== 0) : [],
	};
};

const ncfindingMapper = {
	map: mapper,
	header
};

export default ncfindingMapper