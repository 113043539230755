import React from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import I18n from "../../I18n";
import { logout, setLocale } from "../../store/actions";
import styles from "./Unauthorized.module.sass";
import { ActionButton } from "colbi_web_ui/lib/components/inputs";
import warning from "../../assets/unauthorized.png";
import { decryptSession } from 'colbi_web_ui/lib/utils/roleValidator'

const Unauthorized = (props) => {
    const { user, match, history } = props;

    const locale = match.params.locale || "en";
    const i18n = I18n.use(locale);
    const handleClick = (history) => {
        history.go(-2);
    };

    const handleClickGoHome = () => {
        history.push(`/${locale}/`);
    };

    let userInformation = decryptSession(user)

    if (!userInformation) {
        return <Redirect to={"/login"} push />;
    }

    if (userInformation && parseInt(userInformation.mysqlPasswordReset)) {
        return <Redirect to={`/${locale}/reset_password`} />;
    }

    return (
        <div className={styles["unauthorized-container"]}>
            <img src={warning} alt="Warning" />
            <div>
                <h2>{i18n`unauthorized`}</h2>
                <h1 className={styles["unauthorized-401"]}>401</h1>
                <p>{i18n`unauthorized_description`} </p>
                <ActionButton
                    primary
                    className={styles["action-button"]}
                    onClick={() => handleClick(history)}
                >
                    {i18n`not_found_redirect`}
                </ActionButton>
                <ActionButton
                    primary
                    className={styles["go-home"]}
                    onClick={() => handleClickGoHome()}
                >
                    {i18n`Home`}
                </ActionButton>
            </div>
        </div>
    );
};
export default connect(
    ({ user, fetch }) => ({
        user,
        fetch,
    }),
    {
        logout,
        setLocale,
    }
)(Unauthorized);
