import { date } from '../../../utils'

const header = [
    { label: ["Warehouse"], align: 'left' },
    { label: ["Delivery"], align: 'center' },
    { label: ["Delivery Date"], align: 'center' },
    { label: ["[Type]"], align: 'center' },
    { label: ["Street - Number"], align: 'left' },
    { label: ["Full Address"], align: 'left' },
    { label: ["Postal Code"], align: 'left' },
    { label: ["City"], align: 'left' },
    { label: ["Country"], align: 'left' },
    { label: ["Region"], align: 'left' }
]

const mapCard = (json) => {
    const {
        warehouseId,
        deliveryId,
        deliveryDate,
        addressType,
        streetName,
        number,
        fullAddress,
        postalCode,
        city,
        country,
        region
    } = json

    return [
        [
            { labels: ['warehouse'], value: warehouseId, type: 'string' }
        ],
        [
            { labels: ['delivery'], value: deliveryId, type: 'number' }
        ],
        [
            { labels: ['date'], value: date(deliveryDate), type: 'date' }
        ],
        [
            { labels: ['type'], value: addressType || 'N/A', type: 'number' }
        ],
        [
            { labels: ['street', 'number'], template: '{0} - {1}', values: [streetName ? streetName : 'N/A', number ? number : 'N/A'], type: 'string' }
        ],
        [
            { labels: ['fullAddress'], value: fullAddress, type: 'string' }
        ],
        [
            { labels: ['postalCode'], value: postalCode, type: 'string' }
        ],
        [
            { labels: ['city'], value: city, type: 'string' }
        ],
        [
            { labels: ['country'], value: country, type: 'string' }
        ],
        [
            { labels: ['region'], value: region || 'N/A', type: 'string' }
        ]
    ]
}

const mapper = (json, card) => {
    const name = json.invoice_id || json.purchase_invoice_line_id || json.purchase_invoice_id || json.stock_movement_line_id || json.invoice_line_id

    return {
        ...json,
        __typename: json.__typename,
        id: json.id,
        name,
        shortName: name,
        card: card ? mapCard(json) : []
    }
}

const shiptoMapper = {
    map: mapper,
    header
}

export default shiptoMapper;