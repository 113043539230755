import { format } from "../../../utils"
import I18n from '../../../I18n'
const mapCard = (json) => {
	const {
		name,
		currency,
		currencyN1,
		currencyN2,
		currencyN3,
	} = json

	return [
		[
			{ labels: ['name'], value: I18n.translate`${name}`, type: 'string', basis: '45%' }
		],
		[
			{ labels: ['currency'], value: format(currency), type: 'currency', basis: '12%' }
		],
		[
			{ labels: ['currencyN1'], value: format(currencyN1 || 0), type: 'currency', basis: '10%' }
		],
		[
			{ labels: ['currencyN2'], value: format(currencyN2 || 0), type: 'currency', basis: '10%' }
		],
		[
			{ labels: ['currencyN3'], value: format(currencyN3 || 0), type: 'currency', basis: '10%' }
		]
	]
}

const header = [
	{ label: ['Description'], align: 'left', basis: '45%' },
	{ label: ['Current'], align: 'right', basis: '10%' },
	{ label: ['N -1'], align: 'right', basis: '10%' },
	{ label: ['N -2'], align: 'right', basis: '10%' },
	{ label: ['N -3'], align: 'right', basis: '10%' },
]

const mapper = (json, card) => {
	return {
		...json,
		name: json.name,
		shortName: json.name,
		card: card ? mapCard(json) : [],
		link: {
			to: '/:locale(pt|en|lt|es)?/:projectId/:fiscalYear([0-9]{4}|current)/:importId/:modulePrefix/reports/trial_balance_taxonomy_annual',
			appendFilters: ['a01'],
			filter: [{ "filters": json.taxonomyCode.map(elem => ({ "value": elem })), "column": "d01" }, { "filters": [{ "value": json.a02 }], "column": "a02" }]
		}
	}
}

const mapFilters = (filters) => {
	return filters.map((filter) => {
		if (filter.prop === 'l_01') {
			return {
				...filter,
				listMapper: {
					'Capital Proprio + Passivo': I18n.translate`Liabilities and Owner's Equitity`,
					'Ativo': I18n.translate`Assets`,
					'Gastos': I18n.translate`Expenses`,
					'Rendimentos': I18n.translate`Income`,
					'Resultados': I18n.translate`resultados`,
				}
			}
		}

		return filter
	})
}

const profitandlossannualMapper = {
	map: mapper,
	mapFilters,
	header
};

export default profitandlossannualMapper