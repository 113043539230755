import {format} from '../../../utils'
const mapDetails = (json) => {
    const {
        paymentRefNo, transactionId, lineNumber, sourceDocumentId, accountId, customerId, supplierId, description, debitCreditIndicator, paymentLineAmount, paymentLineAmountCurrencyCode, paymentLineCurrencyAmount
    } = json;

    return [
        [
            {label: 'Transaction ID', value: transactionId, type: 'number'},
            {label: 'Payment Reference No', value: paymentRefNo, type: 'string'},
            {label: 'Line Number', value: lineNumber, type: 'number'},
            {label: 'Source Document Id', value: sourceDocumentId, type: 'string'},
        ],
        [
            {label: 'Debit Credit Indicator', value: debitCreditIndicator, type: 'string'},
            {label: 'Payment Line Amount', value: format(paymentLineAmount, "fullValue"), type: 'number'},
            {label: 'Payment Line Amount Currency Code', value: paymentLineAmountCurrencyCode, type: 'string'},
            {label: 'Payment Line Currency Amount', value: format(paymentLineCurrencyAmount,'fullValue',null,2), type: 'number'},
            
        ],
        [
            {label: 'Account Id', value: accountId, type: 'string'},
            customerId || supplierId ? {
                label: ` ${customerId ? 'Customer' : 'supplier'} ID`,
                value: customerId ? customerId : supplierId,
                type: 'string'
            } : null,
            {label: 'Description', value: description, type: 'string'},
        ]
    ]
};

const mapCard = (json) => {
    const {
        paymentRefNo, lineNumber, sourceDocumentId, accountId,paymentLineAmount,paymentLineCurrencyAmount,paymentLineAmountCurrencyCode,debitCreditIndicator
    } = json;

    return [

        [
            {labels: ['payment_ref_no'], value: paymentRefNo, type: 'string', basis: '23%'}
        ],
        [
            {labels: ['line_number'], value: lineNumber,align: 'center', type: 'string', basis: '15%'}
        ],
        [
            {labels: ['source_document_id'], value: sourceDocumentId, type: 'string'}
        ],
        [
            {labels: ['account_id'], value: accountId, type: 'string'}
        ],
        [
            {labels: ['payment_line_amount'], value: format(paymentLineAmount), type: 'currency'}
        ],
        [
            {labels: ['payment_line_currency_amount'], value: format(paymentLineCurrencyAmount,'normal',null,2), type: 'number', align:'right'}
        ],
        [
            {labels: ['payment_line_amount_currency_code'], value: paymentLineAmountCurrencyCode, type: 'string',align: 'center'}
        ],
        [
            {labels: ['Debit Credit Indicator'], value: debitCreditIndicator, type: 'string', align: 'center'},
        ]
    ]
};

const mapper = (json, card, details) => {
    return {
        ...json,
        name: json.refNumber,
        shortName: json.refNumber,
        details: details ? mapDetails(json) : [],
        card: card ? mapCard(json) : []
    }
};

const header = [
    {label: ["Payment Reference No"], align: 'left',columnName: "payment_ref_no", sortable: true, basis: '23%'},
    {label: ["Line Number"], columnName: "line_number", sortable: true, basis: '15%'},
    {label: ["Source Document Id"], align: 'left',columnName: "source_document_id", sortable: true},
    {label: ["Account Id"],columnName: "account_id", align: 'left'},
    {label: ["Amount"], align: 'right', columnName: "payment_line_amount", sortable: true},
    {label: ["Currency Amount"], align: 'right',columnName: "payment_line_currency_amount", sortable: true},
    {label: ["Currency code"], align: 'center', columnName: "payment_line_amount_currency_code", sortable: true},
    {label: ["Debit Credit Indicator"], align: 'center', columnName: "debit_credit_indicator", sortable: true},
];
const paymentlineMapper = {
    map: mapper,
    header
}

export default paymentlineMapper;