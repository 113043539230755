import { datetime } from "../../utils"

const mapCard = (json) => {
	const {
		AuditStatus,
		AuditNote,
		CreatedBy,
		CreationDate
	} = json
	return [
		[
			{ labels: ['AuditNote'], value: `${AuditNote}`, type: 'string' }
		],
		[
			{ labels: ["AuditStatus"], value: [AuditStatus.replaceAll(' ', '-').toLowerCase(), AuditStatus, 'center'], type: "status" },
		],
		[
			{ labels: ['CreatedBy'], value: `${CreatedBy}`, type: 'string', align: 'center' }
		],
		[
			{ labels: ['CreatedIn'], value: `${datetime(CreationDate)}`, type: 'string', align: 'center' }
		]
	]
}

const header = [
	{ label: ['Audit Note'], align: 'left' },
	{ label: ['Audit Status'], align: 'center' },
	{ label: ['Created By'], align: 'center' },
	{ label: ['Created In'], align: 'center' },
]

const mapper = (json, card) => {

	return {
		...json,
		__typename: json.__typename,
		card: card ? mapCard(json) : [],
	}
}

const auditnoterecordMapper = {
	map: mapper,
	header
};

export default auditnoterecordMapper