// export default routes
import Login from '../controllers/Login/Login'
import ResetPassword from '../controllers/ResetPassword/ResetPassword'
import Settings from '../controllers/Settings/Settings'
import notFound from './notFound'
import unauthorized from './unauthorized'
import { filteredRoutesByModules} from './manageRouting'
import SetNewPassword from '../controllers/SetNewPassword/SetNewPassowrd'
import userProjectsByOrganizations from "./userProjectsByOrganization"
import ExternalApplication from "../controllers/ExternalApplication/ExternalApplication"

const defaultRoutes = [
	{
		path: '/:locale(pt|en|lt|es|ro)?/external_application/:tokenAccess',
		component: ExternalApplication,
		exact: true
	},
	{
		path: '/:locale(pt|en|lt|es|ro)?/login',
		component: Login,
		exact: true
	},
	{
		path: '/:locale(pt|en|lt|es|ro)?/my_profile/reset_password',
		component: SetNewPassword,
		exact: false
	},
	{
		path: '/:locale(pt|en|lt|es|ro)?/reset_password',
		component: ResetPassword,
		exact: true
	},
	userProjectsByOrganizations('/:locale(pt|en|lt|ro)?', [], true, "Organizations"),
	userProjectsByOrganizations('/:locale(pt|en|lt|ro)?/organizations/:organizationId', [], true, "Organizations"),
	{
		path: '/:locale(pt|en|lt|es|ro)?/settings/:section?',
		component: Settings,
		exact: false
	},
	notFound('/:locale(pt|en|lt|es|ro)?/not_found'),
	unauthorized('/:locale(pt|en|lt|es|ro)?/unauthorized'),
]

const filteredRoutes = (modules) => {
	let getFilteredRoutes = filteredRoutesByModules(modules)
	
	return [
		...defaultRoutes,
		...getFilteredRoutes,
		notFound('/:locale(pt|en|lt|es|ro)?/:projectId?/:fiscalYear([0-9]{4}|current)?/*'),
		unauthorized('/:locale(pt|en|lt|es|ro)?/:projectId?/:fiscalYear([0-9]{4}|current)?/*'),
	]
}

export {filteredRoutes, defaultRoutes}