
const mapDetails = (json) => {
	const {
		registrationNumber,
		taxEntity,
		supplierId,
		companyName,
		selfBilling,
	} = json

	return [
		[
			{ label: 'Supplier Id', value: supplierId, type: 'string' },
			{ label: 'Registration Number', value: registrationNumber, type: 'string' },
			{ label: 'Entity', value: taxEntity, type: 'string' },
			{ label: 'Self Billing', value: selfBilling, type: 'boolean' },
			{ label: 'Supplier Name', value: companyName, type: 'string' },

		]
	]
}

const mapCard = (json) => {
	const {
		companyName,
		registrationNumber,
		taxEntity,
		supplierId,
	} = json

	return [
		[{ labels: ["Supplier Id"], value: supplierId, type: "string", basis: "12%" }],	
		[{ labels: ["name"], value: companyName, type: "string", basis: "50%" }],	
		[{ labels: ["Registration Number"], value: `${registrationNumber}`, type: "string" }],
		[{ labels: ["entity"], value: `${taxEntity}`, type: "number" }],
	]
}

const mapper = (json, card, details) => {
	
	return {
		...json,
		name: json.companyName,
		shortName: json.companyName,
		card: card ? mapCard(json) : [],
		details: details ? mapDetails(json) : []
	}
}

const header = [
	{ label: ['Supplier id'], align: 'left', columnName: "supplier_id", sortable: true, basis: "12%" },
	{ label: ['Entity_Name'], align: 'left', columnName: "name", sortable: true, basis: "50%" },
	{ label: ['Registration Number'], align: 'left' }, 
	{ label: ['Entity'], align: 'center', columnName: "tax_entity" },
]

const supplierMapper = {
	map: mapper,
	header
}

export default supplierMapper;