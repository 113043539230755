import React, { forwardRef, useRef, useImperativeHandle } from "react";
import { TextArea, Dropdown } from 'colbi_web_ui/lib/components/inputs'
import { useGlobalValue } from 'colbi_web_ui/lib/state/GlobalProvider'
import { FORM_ACTIONS } from "../FormActions";

import styles from "./AuditNotesForm.module.sass";
import { datetime } from "../../../utils";

let origin;

const snakeCaseToPascalCase = (str) => {
    const camelize = str.replace(/_\w/g, (m) => m[1].toUpperCase());
    return camelize.substring(0, 1).toUpperCase() + camelize.substring(1);
}

const AuditNotesForm = (props, ref) => {
	const { data, status, onChange, onSave } = props;

	const formRef = useRef();
	const {
		AuditStatus,
		AuditNote,
		CreatedBy,
		ModifiedBy,
		CreatedAt,
		UpdatedAt,
	} = data || {};

	const { i18n, params } = useGlobalValue();

	const { projectId, importId, id: ruleId, fiscalYear, modulePrefix, entities : allEntities } = params;

	const entities = (allEntities || "").split("/");
	const entityPairs = (entities || []).reduce((pairs, current) => {
		if (!pairs[pairs.length - 1] || pairs[pairs.length - 1].length === 2) {
			pairs.push([]);
		}
		if (pairs[pairs.length - 1]) {
			pairs[pairs.length - 1].push(current);
		}
		return pairs;
	}, []);

	const entityIndex =
		entities.length % 2 === 0 ? entityPairs.length - 1 : entityPairs.length - 2;

	const entityName = (entityPairs[entityIndex] || [])[0];
	const entityId = (entityPairs[entityIndex] || [])[1];

	const handleChange = (e, prop, value) => {
		onChange &&
			onChange({
				originalEvent: e,
				target: {
					value: {
						data: {
							...data,
							[prop]: value,
						},
					},
				},
			});
	};
	const auditStatusTypes = [
		{ id: "To be Validated", name: `To be Validated` },
		{ id: "Compliant", name: `Compliant` },
		{ id: "Fraud", name: `Fraud` },
	];
	useImperativeHandle(ref, () => ({
		submit: () => {
			formRef.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
		},
	}));

	return status !== "loading" ? (
		<form
			ref={formRef}
			onSubmit={(e) => {
				e.preventDefault();
				e.stopPropagation();
				if (formRef.current.checkValidity()) {
					data["__typename"] = "NcAuditNote";
					data["projectId"] = projectId;
					data["ruleId"] = ruleId;
					data["importId"] = importId;
					data["fiscalYear"] = fiscalYear;
					data["Origin"] = origin;
					data["RowID"] = entityId;
					data["EntityName"] = snakeCaseToPascalCase(entityName || "").slice(0, -1);
					data["modulePrefix"] = modulePrefix;
					onSave && onSave();
				} else {
					formRef.current.reportValidity();
				}
			}}
		>
			<div className={styles["group-content"]}>
				<div className={styles["text-container"]}>
					<h5 className={styles["label"]}>{i18n`Audit Status`}</h5>
					<Dropdown
						className={styles["dropdown"]}
						placeholder={i18n`Audit Status`}
						value={
							AuditStatus && auditStatusTypes
								? auditStatusTypes.find(({ id }) => id === AuditStatus) ||
								  auditStatusTypes[0]
								: null
						}
						options={auditStatusTypes}
						required
						onChange={(e) => {
							handleChange(e, "AuditStatus", e.target.value.id);
						}}
					/>
					<TextArea
						className={styles["input"]}
						value={AuditNote || ""}
						name={i18n`Audit Note`}
						placeholder={i18n`Enter a Audit Note`}
						required
						onChange={(e) => {
							handleChange(e, "AuditNote", e.target.value);
						}}
					/>
					{CreatedBy ? (
						<div className={styles["raw-content"]}>
							<h5 className={styles["label"]}>{i18n`Created By: ${CreatedBy}`}</h5>
							<h5 className={styles["label"]}>
								{`Created At: ${datetime(CreatedAt)}`}
							</h5>
						</div>
					) : null}
					{CreatedAt ? (
						<div className={styles["raw-content"]}>
							<h5 className={styles["label"]}>{i18n`Modified By: ${ModifiedBy}`}</h5>
							<h5 className={styles["label"]}>
								{i18n`Updated At: ${datetime(UpdatedAt)}`}
							</h5>
						</div>
					) : null}
				</div>
			</div>
		</form>
	) : null;
};

const AuditNotesFormRef = forwardRef(AuditNotesForm);

AuditNotesFormRef.queries = (args) => {
	origin = (args || {}).origin;
	return [
		args && args.id
			? {
					resource: `nc_audit_note_by_rule`,
					args: {
						projectId: args.projectId,
						fiscalYear: args.fiscalYear,
						importId: args.importId,
						ruleId: args.id,
						modulePrefix: args.modulePrefix
					},
					body: `id,AuditStatus,AuditNote,CreatedBy,ModifiedBy,CreatedAt,UpdatedAt`,
			  }
			: undefined,
	].filter((q) => q);
};

AuditNotesFormRef.dataMapper = ["data"];

AuditNotesFormRef.actions = [FORM_ACTIONS.SAVE];

export default AuditNotesFormRef;
