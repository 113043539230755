import React, { forwardRef, useRef, useImperativeHandle } from 'react'
import { TextInput, Dropdown } from 'colbi_web_ui/lib/components/inputs'
import { useGlobalValue } from 'colbi_web_ui/lib/state/GlobalProvider'
import { FORM_ACTIONS } from '../FormActions'

import styles from './TicketForm.module.sass'


const TicketForm = (props, ref) => {
	const { data, projects, status, onChange, onSave } = props
    const formRef = useRef()
    const {
		ticketNumber,
        ticketDescription,
        projectId
    } = data || {}
    
	const { i18n, user } = useGlobalValue()
    
    const handleChange = (e, prop, value) => {
		onChange && onChange({
			originalEvent: e,
			target: {
				value: {
					data: {
						...data,
						[prop]: value
					}
				}
			}
		})
    }
    
    const allProjects = (
		projects && projects.items
			? projects.items.map(({ id, description, organization, taxRegistrationNumber }) => ({ id, name: `${taxRegistrationNumber} - ${description} (${(organization || {}).name})` }))
			: []
	)

	useImperativeHandle(ref, () => ({
		submit: () => {
			formRef.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))
		}
	}))

	return status !== 'loading' ? (
        <form ref={formRef} onSubmit={(e) => {
			e.preventDefault()
			e.stopPropagation()
			if (formRef.current.checkValidity()) {
                data["__typename"] = "Ticket"
                data["userId"] = (user || {}).id
				onSave && onSave()
			} else {
				formRef.current.reportValidity()
			}
		}}>
			<div className={styles['group-content']}>
            <div className={ styles['text-container'] }>
                    <h5 className={ styles['label'] }>{ i18n`Project` }</h5>
					<Dropdown
						className={styles['dropdown']}
						placeholder={i18n `Select Project`}
						value={allProjects ? allProjects.find(({ id }) => id === projectId) : null}
						options={allProjects || []}
						filter
						required
						onChange={(e) => {
                            handleChange(e, 'projectId', e.target.value.id)
                        }}
					/>
					<TextInput
                            key={"ticket-number"}
							name={i18n`Ticket Number`}
							placeholder={i18n`Enter a ticket number`}
							value={ticketNumber || ''}
							required
							onChange={(e) => {
                                handleChange(e, 'ticketNumber', e.target.value)
							}}
					/>
					<TextInput
                            key={"ticket-description"}
							name={i18n`Description`}
							placeholder={i18n`Enter a ticket description`}
							value={ticketDescription || ''}
							required
							onChange={(e) => {
                                handleChange(e, 'ticketDescription', e.target.value)
                        }}
					/>
				</div>
			</div>
		</form>
	) : null
}

const TicketFormRef = forwardRef(TicketForm)

TicketFormRef.queries = (args) => {
	return [
		{
			resource: 'projects',
			args: { pageSize: 0 },
			body: 'items{id,taxRegistrationNumber,description,organization{name}}'
		},
	].filter(q => q)
}

TicketFormRef.dataMapper = ['projects','ticket','data']

TicketFormRef.actions = [FORM_ACTIONS.SAVE]

export default TicketFormRef