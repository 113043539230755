import { format } from '../../../utils'

const mapDetails = (json) => {
	const {
		taxRegistrationNumber,
		tocTaxRegistrationNumber,
		periodYear,
		sfCode,
		typeOfDeclaration,
		periodMonth,
		socialParticipationWithheldTaxAmount,
		capitalApplicationWithheldTaxAmount,
		royaltiesWithheldTaxAmount,
		employmentWithheldTaxAmount,
		selfEmploymentWithheldTaxAmount,
		commissionsWithheldTaxAmount,
		propertiesWithheldTaxAmount,
		pensionsWithheldTaxAmount,
		servicesProvisioningWithheldTaxAmount,
		publicRenumerationsWithheldTaxAmount,
		publicPensionsWithheldTaxAmount,
		othersWithheldTaxAmount,
		numberOfTaxPaymentSlip,
		numberOfIncomeBeneficiaries
	} = json
	return [
		[
			{ label: 'Tax Registration Number', value: taxRegistrationNumber, type: 'string' },
			{ label: 'Toc TaxRegistration Number', value: tocTaxRegistrationNumber, type: 'string' },
			{ label: 'Period Year', value: periodYear, type: 'number' },
			{ label: 'SF Code', value: sfCode, type: 'number' },
			{ label: 'Type of Declaration', value: typeOfDeclaration, type: 'number' },
			{ label: 'Period Month', value: periodMonth, type: 'number' }
		],
		[
			{ label: 'Social Participation with Held Tax Amount', value: format(socialParticipationWithheldTaxAmount), type: 'currency' },
			{ label: 'Capital Application with Held Tax Amount', value: format(capitalApplicationWithheldTaxAmount), type: 'currency' },
			{ label: 'Royalties with Held Tax Amount', value: format(royaltiesWithheldTaxAmount), type: 'currency' },
			{ label: 'Employment with Held Tax Amount', value: format(employmentWithheldTaxAmount), type: 'currency' },
			{ label: 'Self Employment with Held Tax Amount', value: format(selfEmploymentWithheldTaxAmount), type: 'currency' },
			{ label: 'Commissions with Held Tax Amount', value: format(commissionsWithheldTaxAmount), type: 'currency' },
			{ label: 'Properties with Held Tax Amount', value: format(propertiesWithheldTaxAmount), type: 'currency' },
			{ label: 'Pensions with Held Tax Amount', value: format(pensionsWithheldTaxAmount), type: 'currency' },
			{ label: 'Services Provisioning with Held Tax Amount', value: format(servicesProvisioningWithheldTaxAmount), type: 'currency' },
			{ label: 'Public Renumerations with Held Tax Amount', value: format(publicRenumerationsWithheldTaxAmount), type: 'currency' },
			{ label: 'Public Pensions with Held Tax Amount', value: format(publicPensionsWithheldTaxAmount), type: 'currency' },
			{ label: 'Others with Held Tax Amount', value: format(othersWithheldTaxAmount), type: 'currency' },

		],
		[
			{ label: 'Number of Tax Payment Slip', value: numberOfTaxPaymentSlip, type: 'string' },
			{ label: 'Number of Income Beneficiaries', value: numberOfIncomeBeneficiaries, type: 'string' }
		]
	]
}


const mapper = (json, card, details) => {
	return {
		...json,
		name: json.refNumber,
		shortName: json.refNumber,
		details: details ? mapDetails(json) : []
	}
}

const headermod30Mapper = {
	map: mapper
}

export default headermod30Mapper;