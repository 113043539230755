import { date } from "../../../utils"

const mapDetails = (json) => {
	const {
		settlementRefNo,
        settlementType,
        settlementDate,
        paymentSupplierCustomerId,
        paymentVatRegistrationNumber,
        paymentRegistrationNumber,
        paymentCountry,
        paymentName,
        numberOfReferencesToInvoices

	} = json

	return [
		[
			{ label: 'Settlement Ref No', value: settlementRefNo, type: 'string' },
			{ label: 'Settlement Type', value: settlementType, type: 'string' },
			{ label: 'Settlement Date', value: date(settlementDate), type: 'date' },
			{ label: 'Payment Supplier/Customer Id', value: paymentSupplierCustomerId, type: 'string' },
			{ label: 'Vat Registration Number', value: paymentVatRegistrationNumber, type: 'string' },
			{ label: 'Registration Number', value: paymentRegistrationNumber, type: 'string' },
		],
		[
            { label: 'References To Invoices', value: numberOfReferencesToInvoices, type: 'number' },
        ],
		[
			{ label: 'Payment Country', value: paymentCountry, type: 'string' },
			{ label: 'Payment Name', value: paymentName, type: 'string' },
		]
	]
}

const mapCard = (json) => {
	const {
        settlementRefNo,
        settlementType,
        settlementDate,
        paymentSupplierCustomerId,
        paymentName,
		paymentVatRegistrationNumber
	} = json

	return [
		[
			{ labels: ['settlementRefNo'], value: `${ settlementRefNo }`, type: 'string', basis: '25%' }
		],
		[
			{ labels: ['settlementType'], value: `${ settlementType }`, type: 'string', basis: '25%' }
		],
		[
			{ labels: ['settlementDate'], value: date(settlementDate), type: 'date' }
		],
		[
			{ labels: ['paymentSupplierCustomerId'], value: paymentSupplierCustomerId, type: 'string' }
		],
		[
			{ labels: ['paymentName'], value: paymentName, type: 'string' }
		],
		[
			{ labels: ['paymentVatRegistrationNumber'], value: paymentVatRegistrationNumber, type: 'string' }
		],
	]
}

const mapper = (json, card, details) => {
	
	return {
		...json,
		details: details ? mapDetails(json) : [],
		card: card ? mapCard(json) : []
	}
}

const header = [
	{ label: ['Settlement Ref No'], align: 'left', basis: '25%' },
	{ label: ['Settlement Type'], align: 'left', columnName: "settlement_type", sortable: true, basis: '25%' },
	{ label: ['Settlement Date'], align: 'center',columnName: "settlement_date", sortable: true },
	{ label: ['Payment Supplier/Customer Id'], align: 'left', columnName: "payment_supplier_customer_id", sortable: true },
	{ label: ['Payment Name'], align: 'left', columnName: "payment_name", sortable: true },
	{ label: ['Payment Vat Registration Number'], align: 'left', columnName: "payment_vat_registration_number", sortable: true },
]

const settlementandpaymentMapper =  {
	map: mapper,
	header
}

export default settlementandpaymentMapper