import { date, format } from "../../../utils";
const header = [
	{
		label: ["SAP Document No."],
		align: "left",
		columnName: "SapDocNo",
		sortable: false,
	},
	{
		label: ["SAP Document Type."],
		align: "left",
		columnName: "SapDocumentType",
		sortable: false,
	},
	{
		label: ["GSTIN"],
		align: "left",
		columnName: "CLB_GSTIN",
		sortable: false,
	},
	{
		label: ["Invoice No."],
		align: "left",
		columnName: "invoice_no",
		sortable: false,
	},
	{
		label: ["Invoice Date"],
		align: "center",
		columnName: "invoice_date",
		sortable: true,
	},
	{
		label: ["Taxable Value"],
		align: "right",
		columnName: "taxable_value",
		sortable: true,
	},
	{
		label: ["Invoice Value"],
		align: "right",
		columnName: "invoice_value",
		sortable: true,
	},
	{
		label: ["checkbox"],
		align: "right",
	},
];

const mapCard = (json) => {
	const {
		sapDocNo,
		sapDocType,
		invoiceNo,
		invoiceDate,
		gstin,
		taxableValue,
		invoiceValue,
	} = json;

	return [
		[{ labels: ["SAP Document No."], value: sapDocNo, type: "string" }],
		[{ labels: ["SAP Document Type."], value: sapDocType, type: "string" }],
		[{ labels: ["GSTIN"], value: gstin, type: "string" }],
		[{ labels: ["Invoice No."], value: invoiceNo, type: "string" }],
		[{ labels: ["Invoice Date"], value: date(invoiceDate), type: "date" }],
		[
			{
				labels: ["TaxTable Value"],
				value: format(taxableValue, null, null, 2),
				type: "number",
				align: "right",
			},
		],
		[
			{
				labels: ["Invoice Value"],
				value: format(invoiceValue, null, null, 2),
				type: "number",
				align: "right",
			},
		],
		[{ labels: ["Checkbox"], value: ["delete"], type: "user_actions" }],
	];
};

const mapper = (json, card) => {
	return {
		...json,
		id: json.id,
		name: json.name,
		shortName: json.name,
		card: card ? mapCard(json) : [],
		basePath: null,
	};
};

const gstreturnMapper = {
	map: mapper,
	header,
};

export default gstreturnMapper;
