import I18n from "../I18n";
import { format } from '.'

export function mapPropertiesForMenu(menuQueries, fetchData = {}, locale, fiscalYear, projectId, importId) {
    const i18n = I18n.use(locale);

    const resolvedMenuNav = (menuQueries || []).reduce((menuData, elem)=> {
        const module = Object.keys(elem)[0]
        const queries = Object.values(elem)[0]
        const camelStr = camelize(module)

        const moduleNavData = (queries || []).reduce((entities, {resource, subEntity, args}) => {
            let getListResult = (
                    fetchData[
                    `${locale}_${resource}_fiscalYear_${fiscalYear}_projectId_${projectId}`
                    ] || { list: [] }
                ).list;
  
            if(!getListResult || getListResult.length === 0){
                const camelStr = camelize(module)
                if( args.genericPrefix ){
                    getListResult = (
                        fetchData[
                            `${locale}_${resource}_fiscalYear_${fiscalYear}_genericPrefix_${args.genericPrefix}${'_importId_' + importId}_projectId_${projectId}`
                        ] || { list: [] }
                    ).list;
                }else{
                    getListResult = (
                        fetchData[
                            `${locale}_${resource}_fiscalYear_${fiscalYear}${'_importId_' + importId}${args.modulePrefix ? '_modulePrefix_' + args.modulePrefix : `_modulePrefix${camelStr.charAt(0).toUpperCase() + camelStr.slice(1)}_${module}`}_projectId_${projectId}`
                        ] || { list: [] }
                    ).list;
                }
            }

            if(/([a-zA-Z]+_)+total/.test(resource)) {
                let data = fetchData[
                    `${locale}_${resource}_fiscalYear_${fiscalYear}${importId? '_importId_' + importId : ''}${args.modulePrefix ? '_modulePrefix_' + args.modulePrefix : `_modulePrefix${camelStr.charAt(0).toUpperCase() + camelStr.slice(1)}_${module}`}_projectId_${projectId}`
                ] || 0
                if((!data || data.length === 0) && args.genericPrefix){
                    let data = fetchData[
                        `${locale}_${resource}_fiscalYear_${fiscalYear}_genericPrefix_${args.genericPrefix}${'_importId_' + importId}_projectId_${projectId}`
                    ] || 0 
                    const resourceToUse = args.genericPrefix? `${args.genericPrefix}_${resource}` : `${resource}`
                    entities[`${camelize(resourceToUse)}`] = data
                }else{
                    entities[`${camelize(resource)}`] = data
                }
            } else {
                const subEntityToUse = subEntity? `/${subEntity}`: ''
                const entitesResult = (getListResult || []).map(({ entity, count, navigation, removeModule }) => {
                    const removedNullCount = parseInt(count) <=  0 ? "" : `(${format(count, 'normal', null, 0)})`
                    changeDeepValue(navigation || {}, "name")
                    return {
                        name: `${i18n`${entity}`} ${removedNullCount}`,
                        to: `/:projectId/:fiscalYear([0-9]{4})/:importId?/${removeModule ? "": module}${subEntityToUse}/${entity}`,
                        navigation
                    };
                });
                const resourceToUse = args.genericPrefix? `${args.genericPrefix}_${resource}` : `${resource}`
                entities[`${camelize(resourceToUse)}`] = entitesResult
            }
            return entities
        }, {});
        menuData = Object.assign(menuData, moduleNavData)
        return menuData
    }, {});
    return resolvedMenuNav || {}
}

const camelize = str => {
	return (str || "").replace(/_([a-z])/g, v => v[1].toUpperCase());
};

function changeDeepValue(obj, prop){
    if (typeof obj == 'object'){
      if (obj.hasOwnProperty(prop))
        obj[prop] = I18n.translate`${obj[prop]}`;
        
      for (let i in obj)
        changeDeepValue(obj[i], prop);
    }
  }