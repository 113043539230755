import React from 'react'
import I18n from '../../I18n'
import styles from './FileMessageSalesSubmissionInformation.module.sass'

const FileMessageSalesSubmissionInformation = (props) => {
    const { value } = props
	
	return (
		<div className={ `${ styles['message'] } ${ value === '1' ? styles['message-success'] : ""}` }>
			<p>{I18n.translate`${ value === '1' ? "Data submitted": "Data not submitted"}`}</p>
		</div>
	)
}

export default FileMessageSalesSubmissionInformation
