import { format, date } from '../../../utils'

const mapDetails = (json) => {
    const {
        invoiceNo,
        invoiceDate,
        documentID,
        acquirerTaxID,
        issuerTaxID,
        period,
        totalValue,
        totalTaxBase,
        totalTaxAmount,
        issuerActivity,
        issuerActivityDesc,
        benefitStatusDesc,
        issuerBenefitStatus,
        issuerBenefitStatusDesc,
        registrationOrigin,
        issuerName,
        registrationOriginDesc,
    } = json
    return [
        [
            { label: 'Invoice No', value: invoiceNo, type: 'string' },
            { label: 'Document ID', value: documentID, type: 'string' },
            { label: 'Period', value: period, type: 'string' },
            { label: 'Invoice Date', value: date(invoiceDate), type: 'date' },
        ],
        [
            { label: 'Net Total', value: format(totalTaxBase), type: 'currency', backgroundColor: "#74AF27", color: "#FFFFFF", icon: 'sales-amount' },
            { label: 'Total Tax Amount', value: format(totalTaxAmount), type: 'currency', backgroundColor: "#8397A8", color: "#FFFFFF", icon: 'sales-amount' },
            { label: 'Gross Total', value: format(totalValue), type: 'currency', backgroundColor: "#5B6975", color: '#FFFFFF', icon: 'sales-amount' },
        ],
        [
            { label: 'Acquirer Tax ID', value: acquirerTaxID, type: 'string' },
            { label: 'Issuer Tax ID', value: issuerTaxID, type: 'string' },
            { label: 'Issuer Name', value: issuerName, type: 'string' },
            { label: 'Issuer Activity', value: issuerActivity, type: 'string' },
            { label: 'Issuer Activity Description', value: issuerActivityDesc, type: 'string' },
            { label: 'Benefit Status Description', value: benefitStatusDesc, type: 'string' },
            { label: 'Issuer Benefit Status', value: issuerBenefitStatus, type: 'string' },
            { label: 'Issuer Benefit Status Description', value: issuerBenefitStatusDesc, type: 'string' },
            { label: 'Registration Origin', value: registrationOrigin, type: 'string' },
            { label: 'Registration Origin Description', value: registrationOriginDesc, type: 'string' },
        ],
    ]
}

const mapCard = (json) => {
    const {
        invoiceDate,
        invoiceNo,
        issuerTaxID,
        totalTaxAmount,
        totalTaxBase,
        totalValue,
        invoiceType,
        benefitStatus,
        issuerName
    } = json

    return [
        [{ labels: ['invoiceNo'], align: 'left', value: invoiceNo, type: 'string', basis: "20%" }],
        [{ labels: ['invoiceDate'], align: 'center', value: date(invoiceDate), type: 'date', }],
        [{ labels: ['invoiceType'], align: 'center', value: invoiceType, type: 'string', }],
        [{ labels: ['benefitStatus'], align: 'center', value: benefitStatus, type: 'string', }],
        [{ labels: ['issuerTaxID'], align: 'center', value: issuerTaxID, type: 'string', basis: "30%" }],
        [{ labels: ['issuerName'], align: 'left', value: issuerName, type: 'string', basis:"50%" }],
        [{ labels: ['totalTaxBase'], align: 'right', value: format(totalTaxBase), type: 'currency', }],
        [{ labels: ['totalTaxAmount'], align: 'right', value: format(totalTaxAmount), type: 'currency', }],
        [{ labels: ['totalValue'], align: 'right', value: format(totalValue), type: 'currency', }],
    ]
}

const mapper = (json, card, details) => {
    return {
        ...json,
        name: json.refNumber,
        shortName: json.refNumber,
        details: details ? mapDetails(json) : [],
        card: card ? mapCard(json) : [],
        raw: json.raw
    }
}

const header = [
    { label: ["Invoice No"], align: 'left', columnName: "InvoiceNo", sortable: true, basis: "20%" },
    { label: ["Invoice Date"], align: 'center', columnName: "InvoiceDate", sortable: true },
    { label: ["Invoice Type"], align: 'center', columnName: "InvoiceType", sortable: true },
    { label: ["Invoice Status"], align: 'center', columnName: "BenefitStatus", sortable: true },
    { label: ["Issuer Tax ID"], align: 'center', columnName: "IssuerTaxID", sortable: true, basis: "30%" },
    { label: ["Issuer Name"], align: 'left', columnName: "IssuerName", sortable: true, basis:"50%" },
    { label: ["Net Total"], align: 'right', columnName: "TotalTaxBase", sortable: true },
    { label: ["Total Tax Amount"], align: 'right', columnName: "TotalTaxAmount", sortable: true },
    { label: ["Gross Total"], align: 'right', columnName: "TotalValue", sortable: true },
]

const purchaseinvoiceMapper = {
    map: mapper,
    header
}

export default purchaseinvoiceMapper;