import { date } from "../../utils"
import I18n from '../../I18n'

const mapCard = (json) => {
	const {
		ruleIdentifier,
		result,
		createdAt,
		description,
		dataFileName
	} = json

	const renderResult = (value) => {
		switch (parseInt(value)) {
			case 0:
				return ["NOT_EXECUTED", I18n.translate`Not Executed`, 'center']
			case 1:
				return ["EXECUTED_WITH_FINDINGS", I18n.translate`Executed with Findings`, 'center']
			case 2:
				return ["EXECUTED_SUCCESSFULLY", I18n.translate`Executed Successfully`, 'center']
			case 3:
				return ["WAITING_FOR_DATA", I18n.translate`Waiting For Data`, 'center']
			case 4:
				return ["DATA_NOT_RECEIVED", I18n.translate`Data Not Received`, 'center']
			case 5:
				return ["NEW", I18n.translate`New Execution`, 'center']
			case 6:
				return ["IN_PROGRESS", I18n.translate`In Progress`, 'center']
			default:
				return ['', I18n.translate`${''}`, 'center']
		}
	}

	return [
		[
			{ labels: ['rule'], value: ruleIdentifier, type: 'string', basis: '10%' }
		],
		[
			{ labels: ['title'], value: description, type: 'string', basis: '50%' }
		],
		[
			{ labels: ['file'], value: dataFileName, type: 'string' }
		],
		[
			{ labels: ['status'], value: renderResult(result), type: 'audit-execution-summary-status' }
		],
		[
			{ labels: ['date'], value: date(createdAt), type: 'date' }
		]
	]
}

const mapper = (json, card) => {
	return {
		...json,
		name: json.ruleIdentifier,
		shortName: json.ruleIdentifier,
		card: card ? mapCard(json) : []
	}
}

const header = [
	{ label: ['Rule'], align: 'left', columnName: "rule_identifier", sortable: true, basis: '10%' },
	{ label: ['Title'], align: 'left', basis: '49.3%' },
	{ label: ['File'], align: 'left' },
	{ label: ['Status'], align: 'center', columnName: "result", sortable: true },
	{ label: ['End Date'], align: 'center', columnName: "created_at", sortable: true }
]

const ncAuditexecutionsummaryMapper = {
	map: mapper,
	header
};

export default ncAuditexecutionsummaryMapper