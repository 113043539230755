import { format, date } from '../../../utils'

const mapDetails = (json) => {
    const {
        assetId,
        valuationType,
        valuationClass,
        generalLedgerAccountRowKey,
        accountId,
        transfersDate,
        assetDisposalDate,
        depreciationDate,
        transfers,
        investmentSupport,
        acquisitionAndProductionCostsStart,
        acquisitionAndProductionCostsEnd,
        assetDisposal,
        assetLifeYears,
        assetLifeMonths,
        depreciationForPeriod,
        bookValueBegin,
        bookValueEnd,
        accumulatedDepreciation,
        accumulatedDepreciationAppreciation,
        depreciationPercentage,
        numberOfImpairments,
        depreciationMethod,
        numberOfAppreciations,
        numberOfExtraDepreciations
    } = json
    return [
        [
            { label: 'Asset', value: assetId, type: 'string' },
            { label: 'Valuation Type', value: valuationType, type: 'string' },
            { label: 'Valuation Class', value: valuationClass, type: 'string' },
            generalLedgerAccountRowKey ?
                { label: 'Account', value: { id: generalLedgerAccountRowKey, name: accountId, __typename: 'LtGeneralLedger' }, type: 'object' } :
                { label: 'Account', value: accountId || 'N/A', type: 'string' },
            { label: 'Transfers Date', value: date(transfersDate), type: 'date' },
            { label: 'Asset Disposal Date', value: date(assetDisposalDate), type: 'date' },
            { label: 'Depreciation Date', value: date(depreciationDate), type: 'date' }
        ],
        [
            { label: 'Transfers', value: format(transfers, 'fullValue'), type: 'currency' },
            { label: 'Investment Support', value: format(investmentSupport, 'fullValue'), type: 'currency' },
            { label: 'Acquisition and Production Costs Start', value: format(acquisitionAndProductionCostsStart, 'fullValue'), type: 'currency' },
            { label: 'Acquisition and Production Costs End', value: format(acquisitionAndProductionCostsEnd, 'fullValue'), type: 'currency' },
            { label: 'Asset Disposal', value: format(assetDisposal, 'fullValue'), type: 'currency' },
            { label: 'Asset Life Years', value: format(assetLifeYears,'fullValue', null), type: 'number' },
            { label: 'Asset Life Months', value: format(assetLifeMonths,'fullValue', null), type: 'number' },
            { label: 'Depreciation for Period', value: format(depreciationForPeriod, 'fullValue'), type: 'currency' },
            { label: 'Book Value Begin', value: format(bookValueBegin, 'fullValue'), type: 'currency' },
            { label: 'Book Value End', value: format(bookValueEnd, 'fullValue'), type: 'currency' },
            { label: 'Accumulated Depreciation', value: format(accumulatedDepreciation, 'fullValue'), type: 'currency' },
            { label: 'Accumulated Depreciation of Appreciation', value: format(accumulatedDepreciationAppreciation, 'fullValue'), type: 'currency' }
        ],
        [
            { label: 'Depreciation Percentage', value: format(depreciationPercentage, 'normal', null,2), type: 'percentage' },
            { label: 'Number of Impairments', value: numberOfImpairments, type: 'string' },
            { label: 'Depreciation Method', value: depreciationMethod, type: 'string' },
            { label: 'Number of Appreciations', value: numberOfAppreciations, type: 'string' },
            { label: 'Number of ExtraDepreciations', value: numberOfExtraDepreciations, type: 'string' }
        ]
    ]
}

const header = [
    { label: ["Type"], align: 'left', basis: '25%' },
    { label: ["Valuation Class"], align: 'left' },
    { label: ["Account"], align: 'center' },
    { label: ["Transfers Date"], align: 'center' },
    { label: ["Asset Disposal Date"], align: 'center' },
    { label: ["Depreciation Date"], align: 'center' },
    { label: ["Depreciation Percentage"], align: 'right' },
    { label: ["Extraordinary Depreciation for Period"], align: 'right', totalizerName: 'total_depreciation_for_period' },
    { label: ["Acquisition and Production Costs Begin"], align: 'right', totalizerName: 'total_acquisition_and_production_costs_start' },
    { label: ["Acquisition and Production Costs End"], align: 'right', totalizerName: 'total_acquisition_and_production_costs_end' },
    { label: ["Opening Book Value"], align: 'right', totalizerName: 'total_book_value_begin' },
    { label: ["Closing Book Value"], align: 'right', totalizerName: 'total_book_value_end' }
]

const mapCard = (json) => {
    const {
        valuationType,
        valuationClass,
        generalLedgerAccountRowKey,
        accountId,
        transfersDate,
        assetDisposalDate,
        depreciationDate,
        depreciationPercentage,
        depreciationForPeriod,
        acquisitionAndProductionCostsStart,
        acquisitionAndProductionCostsEnd,
        bookValueBegin,
        bookValueEnd
    } = json

    return [
        [
            { labels: ['type'], value: valuationType, type: 'string', basis: '25%' }
        ],
        [
            { labels: ['Class'], value: valuationClass, type: 'string' }
        ],
        [
            generalLedgerAccountRowKey ?
                { label: 'Account', value: { id: generalLedgerAccountRowKey, name: accountId, __typename: 'LtGeneralLedger' }, type: 'object' } :
                { labels: ['Account'], value: accountId || 'N/A', type: 'string' }
        ],
        [
            { labels: ['transfersDate'], value: date(transfersDate), type: 'date' }
        ],
        [
            { labels: ['assetDisposalDate'], value: date(assetDisposalDate), type: 'date' }
        ],
        [
            { labels: ['depreciationDate'], value: date(depreciationDate), type: 'date' }
        ],
        [
            { labels: ['depreciationPercentage'], value: format(depreciationPercentage, 'normal', null,2), type: 'percentage' }
        ],
        [
            { labels: ['depreciationForPeriod'], value: format(depreciationForPeriod), type: 'currency' }
        ],
        [
            { labels: ['acquisitionAndProductionCostsStart'], value: format(acquisitionAndProductionCostsStart), type: 'currency' }
        ],
        [
            { labels: ['acquisitionAndProductionCostsEnd'], value: format(acquisitionAndProductionCostsEnd), type: 'currency' }
        ],
        [
            { labels: ['bookValueBegin'], value: format(bookValueBegin), type: 'currency' }
        ],
        [
            { labels: ['bookValueEnd'], value: format(bookValueEnd), type: 'currency' }
        ]
    ]
}

const mapper = (json, card, details) => {
    const name = json.assetId

    return {
        ...json,
        __typename: json.__typename,
        id: json.id,
        name,
        shortName: name,
        details: details ? mapDetails(json) : [],
        card: card ? mapCard(json) : []
    }
}

const assetvaluationMapper = {
    map: mapper,
    header
}

export default assetvaluationMapper;