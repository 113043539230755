import { format, date } from "../../../utils"

const mapDetails = (json) => {
	const {
		invoiceLineAmount,
		invoiceLineAmountCurrencyCode,
		lineNumber,
		invoiceNo,
		quantity,
		productCode,
		unit,
		taxDate,
		invoiceLineCurrencyAmount,
		debitCreditIndicator,
		description,
		accountId
	} = json

	return [
		[
			{ label: 'Invoice No.', value: invoiceNo, type: 'string' },
			{ label: 'Line Number', value: lineNumber, type: 'number' },
			{ label: 'Product Code', value: productCode, type: 'string' },
			{ label: 'Tax Date', value: date(taxDate), type: 'string' },
			{ label: 'Account Id', value: accountId, type: 'string' },
		],
		[
			{
				label: 'Quantity',
				value: format(quantity, null, null),
				type: 'number'
			},
			{
				label: 'Unit of Measure',
				value: unit || 'N/A',
				type: 'string'
			}
		],
		[
			{
				label: 'Amount',
				value: format(invoiceLineAmount, "fullValue"),
				type: 'number'
			},
			{ label: 'Debit Credit Indicator', value: debitCreditIndicator, type: 'string' },
			{
				label: 'Currency Code',
				value: invoiceLineAmountCurrencyCode,
				type: 'string'
			},
			{
				label: 'Currency Amount',
				value: format(invoiceLineCurrencyAmount, 'fullValue'),
				type: 'currency'
			},
		],
		[
			{ label: 'Description', value: description, type: 'string' },
		]

	]

}

const mapCard = (json) => {
	const {
		lineNumber,
		invoiceLineAmount,
		taxDate,
		invoiceLineCurrencyAmount,
		debitCreditIndicator,
		invoiceLineAmountCurrencyCode,
		quantity
		
	} = json
	return [
		[
			{ labels: ['line_number'], value: `${lineNumber}`, type: 'number', basis: "20%" ,align: 'left'}
		],
		[
			{ labels: ['date'], value: date(taxDate), type: 'date'}
		],
		[
			{ labels: ['quantity'], value: format(quantity, null, null) || 'N/A', type: 'number',align: "right",basis: '9%' }
		],
		[
			{ labels: ['invoiceLineAmount'], value: format(invoiceLineAmount), align: "right",basis: '20%',type: 'currency' }
		],
		[
			{ labels: ['invoiceLineCurrencyAmount'], value: format(invoiceLineCurrencyAmount, 'normal', null, 2), type: 'number', align: "right" }
		],
		[
			{ labels: ['invoiceLineAmountCurrencyCode'], value:invoiceLineAmountCurrencyCode, type: 'string', align: "center" }
		],
		[
			{ labels: ['debitCreditIndicator'], value: debitCreditIndicator, type: 'string', align: "center"}
		]
	]
}

const mapper = (json, card, details) => {
	return {
		...json,
		name: '[' + json.lineNumber + '] ' + json.productCode,
		shortName: json.productCode,
		details: details ? mapDetails(json) : [],
		card: card ? mapCard(json) : []
	}
}

const header = [
	{ label: ["Line Number"], align: 'left' , basis: "20%" },
	{ label: ["Tax Date"], align: 'center', columnName: "tax_point_date", sortable: true},
	{ label: ["Quantity"], align: 'center', columnName: "quantity", sortable: true },
	{ label: ["Amount"], align: 'center', columnName: "invoice_line_amount", sortable: true, basis: '20%'},
	{ label: ["Currency Amount"], align: 'center', columnName: "invoice_line_currency_amount", sortable: true},
	{ label: ["Currency Code"], align: 'center', columnName: "invoice_line_amount_currency_code", sortable: true},
	{ label: ["Debit Credit Indicator"], align: 'left', columnName: "debit_credit_indicator", sortable: true }
]
const invoicelineMapper = {
	map: mapper,
	header
}

export default invoicelineMapper;