import I18n from '../../I18n'
import { datetime, format, convertBytesToMegaBytes } from '../../utils'
import { stringifyFilter } from 'colbi_web_ui/lib/utils'

const finalStatus = ['imported', 'error', 'invalid']

const mapCard = (json) => {
	const {
		id,
		name,
		flowStatus,
		status,
		uploadedAt,
		submitedBy,
		fiscalYear,
		type,
		subType,
		statusCode,
		fileSizeDecompress
	} = json

	const hasFindings = statusCode ? ((status === "imported" && statusCode === 1008) ? 2 : 3) : null
	return [
		[
			{ labels: ['id'], value: id, type: 'string', align: 'align-left', basis: "10%"}
		],
		[
			{ labels: ['name'], value: name, type: 'string', align: 'align-left'}
		],
		[
			{ labels: ['fiscalYear'], value: fiscalYear, type: 'string', align: 'align-left'}
		],
		[
			{ labels: ['uploaded'], value: datetime(uploadedAt), type: 'datetime', mobileHidden: true }
		],
		[
			{ labels: ['fileSizeDecompress'], value: `${format(convertBytesToMegaBytes(fileSizeDecompress), null, null)} Mb`, type: 'number', mobileHidden: true }
		],
		[
			{ labels: ['type'], value: I18n.translate`${type}`, type: 'string', mobileHidden: true }
		],
		[
			{ labels: ['subtype'], value: subType, type: 'string', mobileHidden: true }
		],
		[
			{ labels: ['user'], value: submitedBy, type: 'string', align: 'left', mobileHidden: true }
		],

		[
			{
				labels: ['status'],
				value: [status, I18n.translate`${status}`],
				type: 'dataFileStatus',
				basis: '20%'
			}
		],
		[
			{ labels: ['active'], value: finalStatus.includes(status) ? hasFindings : null, type: 'is-active' , align: 'center' }
		],
		[
			{
				labels: ['status'],
				value: [status, I18n.translate`${status}`, flowStatus],
				type: 'stageFlow',
			}
		]
	]
}

const mapper = (json, card) => {
	const fileType = (json.type || "").toString().startsWith("LtErp") ? "LtErpFile" : (json.type || "").toString()
	const resType = fileType.replace("File", "")

	const linkToUse = json.status === 'imported' ?
		`/:locale(pt|en|lt|es)?/:projectId/repository/folders/${json.fiscalYear}/${resType}`
		:
		`/:locale(pt|en|lt|es)?/:projectId/repository/invalid_files`
	return {
		...json,
		id: json.id,
		name: json.name,
		shortName: json.name,
		card: card ? mapCard(json) : [],
		link: finalStatus.includes(json.status) ? {
			to: `${linkToUse}?filter=${stringifyFilter(
				[{ column: 'id', filters: [{ value: `${json.id}` }] }]
			)
				}`
		} : null
	}
}

const header = [
	{ label: ['Id'], align: 'left', basis: "10%"},
	{ label: ['Name'], align: 'left'},
	{ label: ['Fiscal Year'], align: 'left', columnName: "fiscal_year", sortable: true },
	{ label: ['Uploaded at'], align: 'center', mobileHidden: true, columnName: "uploaded_at", sortable: true },
	{ label: ['File Size'], align: 'center', mobileHidden: true, columnName: "file_size_decompress", sortable: true },
	{ label: ['Type'], align: 'left', mobileHidden: true, columnName: "type", sortable: true },
	{ label: ['Sub Type'], align: 'left', mobileHidden: true, columnName: "sub_type", sortable: true },
	{ label: ['Uploaded by'], align: 'left', mobileHidden: true },
	{ label: ['Status'], align: 'center', columnName: "status", sortable: true },
	{ label: ['Findings'], align: 'center' },
	{ label: ['Stage'], align: 'center' },
]

const activityMapper = {
	map: mapper,
	header
};
export default activityMapper