import { format, date } from '../../../utils'

const mapDetails = (json) => {
	const {
		type,
		period,
		transactionDate,
		grossTotal,
		netTotal,
		periodYear,
		shipFrom,
		shipTo,
		transaction,
		refNumber,
		taxEntity
	} = json

	return [
		[
			{ label: 'Payment Ref No', value: refNumber, type: 'string' },
			{ label: 'Entity', value: taxEntity, type: 'string' },
			{ label: 'Payment Method', value: type, type: 'string' },
			{ label: 'Period Year', value: periodYear, type: 'number' },
			{ label: 'Period', value: period, type: 'number' },

		],
		[
			transaction ? { label: 'Transaction', value: { id: transaction.id, name: transaction.name, __typename: 'Transaction' }, type: 'object' } : null,
			{ label: 'Transaction Date', value: date(transactionDate), type: 'date' },
		],
		[
			{ label: 'Gross Total', value: format(grossTotal, 'fullValue'), type: 'currency' },
			{ label: 'Net Total', value: format(netTotal, 'fullValue'), type: 'currency' },
		],

		shipFrom ? {
			title: 'Ship From',
			rows: [
				[
					{ label: 'ID', value: shipFrom.deliveryId || 'N/A', type: 'string' },
					{ label: 'Location', value: shipFrom.locationId || 'N/A', type: 'string' },
					{ label: 'Warehouse', value: shipFrom.warehouseId || 'N/A', type: 'string' },
					{ label: 'Date', value: date(shipFrom.deliveryDate) || 'N/A', type: 'date' }
				],
				[
					{ label: 'Address Details', value: shipFrom.address.detail || 'N/A', type: 'string' },
					{ label: 'Street', value: shipFrom.address.streetName || 'N/A', type: 'string' },
					{ label: 'Number', value: shipFrom.address.buildingNumber || 'N/A', type: 'string' },
					{ label: 'City', value: shipFrom.address.city || 'N/A', type: 'string' },
					{ label: 'Postal Code', value: shipFrom.address.postalCode || 'N/A', type: 'string' },
					{ label: 'Region', value: shipFrom.address.region || 'N/A', type: 'string' },
					{ label: 'Country', value: shipFrom.address.country || 'N/A', type: 'string' },
				]
			]
		} : null,
		shipTo ? {
			title: 'Ship To',
			rows: [
				[
					{ label: 'ID', value: shipTo.deliveryId || 'N/A', type: 'string' },
					{ label: 'Location', value: shipTo.locationId || 'N/A', type: 'string' },
					{ label: 'Warehouse', value: shipTo.warehouseId || 'N/A', type: 'string' },
					{ label: 'Date', value: shipTo.deliveryDate || 'N/A', type: 'date' }
				],
				[
					{ label: 'Address Details', value: shipTo.address.detail || 'N/A', type: 'string' },
					{ label: 'Street', value: shipTo.address.streetName || 'N/A', type: 'string' },
					{ label: 'Number', value: shipTo.address.buildingNumber || 'N/A', type: 'string' },
					{ label: 'City', value: shipTo.address.city || 'N/A', type: 'string' },
					{ label: 'Postal Code', value: shipTo.address.postalCode || 'N/A', type: 'string' },
					{ label: 'Region', value: shipTo.address.region || 'N/A', type: 'string' },
					{ label: 'Country', value: shipTo.address.country || 'N/A', type: 'string' },
				]
			]
		} : null
	]
}

const mapCard = (json) => {
	const {
		refNumber,
		numberOfLines,
		type,
		period,
		transactionDate,
		grossTotal,
		netTotal,
		numberOfSettlements,
		transaction
	} = json

	return [
		[
			{ labels: ['id'], value: refNumber, type: 'string', basis: '20%' },
		],
		transaction && transaction.id ? [
			{
				labels: ['Transaction'],
				value: {
					id: transaction.id,
					name: transaction.name,
					__typename: 'Transaction'
				},
				type: 'object',
				mobileHidden: true
			}
		] : [
				{ labels: ['customer'], value: 'N/A', type: 'string', mobileHidden: true }
			],
		[
			{ labels: ['lines'], value: numberOfLines, type: 'number' }
		],
		[
			{ labels: ['type'], value: type, type: 'number' }
		],
		[
			{ labels: ['period'], value: period, type: 'number' }
		],
		[
			{ labels: ['date'], value: date(transactionDate), type: 'date' }
		],
		[
			{ labels: ['net'], value: format(netTotal), type: 'currency' }
		],
		[
			{ labels: ['gross'], value: format(grossTotal), type: 'currency' }
		],
		[
			{ labels: ['Number of Settlements'], value: numberOfSettlements, type: 'number', basis: '25%' }
		],
	]
}

const mapper = (json, card, details) => {
	return {
		...json,
		name: json.refNumber,
		shortName: json.refNumber,
		details: details ? mapDetails(json) : [],
		card: card ? mapCard(json) : []
	}
}

const header = [
	{ label: ["Payment Ref No"], align: 'left', columnName: "payment_ref_no", sortable: true, basis: '20%' },
	{ label: ["Transaction Id"], align: 'left', columnName: "transaction_id", sortable: true },
	{ label: ["Lines"], align: 'center', columnName: "number_of_lines", sortable: true},
	{ label: ["Payment Method"], align: 'center', columnName: "payment_method", sortable: true },
	{ label: ["Period"], align: 'center', columnName: "period", sortable: true },
	{ label: ["Transaction Date"], align: 'center', columnName: "transaction_date", sortable: true },
	{ label: ["Net Total"], align: 'right', columnName: "net_total", sortable: true },
	{ label: ["Gross Total"], align: 'right', columnName: "gross_total", sortable: true },
	{ label: ["Number of Settlements"], align: 'center', basis: '25%', columnName: "number_of_settlements", sortable: true },
]

const paymentMapper =  {
	map: mapper,
	header
}
export default paymentMapper