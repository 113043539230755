import { date, format } from "../../../utils";

const mapDetails = (json) => {
	const {
		InvoiceNumber,
		InvoiceDate,
		DescriptionReport,
		InvoiceValueDiff,
		TaxableValueDiff,
		SupplierGSTIN,
		SupplierInvoiceValue,
		SapInvoiceValue,
		SapTaxableValue,
		SupplierTaxableValue,
		TaxAmountDiff,
		SapTaxAmount,
		SupplierTaxAmount,
	} = json;

	return [
		[
			{
				label: "Invoice No.",
				value: InvoiceNumber,
				type: "string",
			},
			{ label: "Invoice Date", value: InvoiceDate, type: "string" },
			{ label: "Supplier GSTIN", value: SupplierGSTIN, type: "string" },
		],
		[
			{
				label: "Taxable Value Diff (₹)",
				value: format(TaxableValueDiff, null, null, 2),
				type: "number",
			},
			{
				label: "Tax Amount Diff (₹)",
				value: format(TaxAmountDiff, null, null, 2),
				type: "number",
			},
			{
				label: "Invoice Value Diff (₹)",
				value: format(InvoiceValueDiff, null, null, 2),
				type: "number",
			},
		],

		[
			{
				label: "SAP Taxable Value (₹)",
				value: format(SapTaxableValue, null, null, 2),
				type: "number",
			},

			{
				label: "GST Taxable Value (₹)",
				value: format(SupplierTaxableValue, null, null, 2),
				type: "number",
			},
		],
		[
			{
				label: "SAP Tax Amount Value (₹)",
				value: format(SapTaxAmount, null, null, 2),
				type: "number",
			},

			{
				label: "GST Tax Amount Value (₹)",
				value: format(SupplierTaxAmount, null, null, 2),
				type: "number",
			},
		],
		[
			{
				label: "SAP Invoice Value  (₹)",
				value: format(SapInvoiceValue, null, null, 2),
				type: "number",
			},
			{
				label: "GST Invoice Value (₹)",
				value: format(SupplierInvoiceValue, null, null, 2),
				type: "number",
			},
		],

		[{ label: "Status", value: DescriptionReport, type: "string" }],
	];
};

const mapCard = (json) => {
	const {
		SapDocNumber,
		InvoiceNumber,
		InvoiceDate,
		DescriptionReport,
		InvoiceValueDiff,
		TaxableValueDiff,
		TaxAmountDiff,
		SupplierGSTIN,
		SapDocType,
	} = json;
	return [
		[
			{
				labels: ["Sap Doc No."],
				value: SapDocNumber,
				type: "string",
				basis: "25%",
			},
		],
		[
			{
				labels: ["Sap Doc Type"],
				value: SapDocType,
				type: "string",
			},
		],
		[
			{
				labels: ["Invoice No."],
				value: InvoiceNumber,
				type: "string",
				basis: "25%",
			},
		],
		[{ labels: ["Invoice Date"], value: date(InvoiceDate), type: "string" }],
		[{ labels: ["Supplier GSTIN"], value: SupplierGSTIN, type: "string" }],

		[
			{
				labels: ["TaxableValueDiff"],
				value: format(TaxableValueDiff, null, null, 2),
				type: "number",
			},
		],
		[
			{
				labels: ["TaxAmountDiff"],
				value: format(TaxAmountDiff, null, null, 2),
				type: "number",
			},
		],
		[
			{
				labels: ["Invoice Value Diff"],
				value: format(InvoiceValueDiff, null, null, 2),
				type: "number",
			},
		],

		[{ labels: ["Report"], value: DescriptionReport, type: "string" }],
	];
};

const mapper = (json, card, details) => {
	return {
		...json,
		name: json.name,
		shortName: json.name,
		details: details ? mapDetails(json) : [],
		card: card ? mapCard(json) : [],
	};
};

const header = [
	{
		label: ["Sap Doc No."],
		align: "left",
		columnName: "SapDocNumber",
		sortable: true,
		basis: "25%",
	},
	{
		label: ["Sap Doc Type"],
		align: "left",
		columnName: "SapDocType",
		sortable: true,
	},
	{
		label: ["Invoice No."],
		align: "left",
		columnName: "InvoiceNumber",
		sortable: true,
		basis: "25%",
	},
	{
		label: ["Invoice Date"],
		align: "left",
		columnName: "InvoiceDate",
		sortable: true,
	},
	{
		label: ["Supplier GSTIN"],
		align: "left",
		columnName: "SupplierGSTIN",
		sortable: true,
	},

	{
		label: ["Taxable Value Diff (₹)"],
		align: "left",
		columnName: "TaxableValueDiff",
		sortable: true,
	},
	{
		label: ["Tax Amount Diff (₹)"],
		align: "left",
		columnName: "TaxAmountDiff",
		sortable: true,
	},
	{
		label: ["Invoice Value Diff (₹)"],
		align: "left",
		columnName: "InvoiceValueDiff",
		sortable: true,
	},
	{
		label: ["Status"],
		align: "left",
		columnName: "DescriptionReport",
		sortable: true,
	},
];

const gstreturnMapper = {
	map: mapper,
	header,
};

export default gstreturnMapper;
