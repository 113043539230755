import { SectionTitle, headers, rows, inputs } from 'colbi_web_ui/lib/components'
import AppByFile from '../controllers/AppByFile/AppByFile'

const queries = (args = {}) => {
	return ([
		{
			resource: 'finding',
			args: {
				projectId: (args.projectId),
				modulePrefix: args.modulePrefix,
				id: (args.id),
				locale: args.locale || "en"
			},
			body: `...finding`
		}
	])
}

const finding = (path, navigation, exact, title) => ({
	path,
	navigation,
	component: AppByFile,
	queries,
	exact,
	title: ['label(Audit)', title, 'param(id)', 'param(fiscalYear)'],
	components: [
		{
			component: SectionTitle,
			props: {
				title: ['query(0).id',' - ', 'query(0).ruleTitle'],
				subtitle: 'query(0).message',
				parameters: 'query(0).messageParameter',
				sticky: true
			}
		},
		{
			component: headers.GenericEntityHeader,
			modifiers: [
				'--root-margin-top-small'
			],
			props: {
				//basePath: path,
				data: 'query(0).details',
				cardKey: 'param(id)',
				card: rows.EntityCard
			}
		},
		{
			component: inputs.AuditNote,
			props: {
				projectId: 'query(0).id',
				data: 'query(0).audit_note'
			}
		}
	]
})

export default finding
