import { format, date } from '../../../utils'

const mapDetails = (json) => {
	const {
		taxType,
        code,
        foreignCurrency,
        effectiveDate,
        expirationDate,
        taxPercentage,
        flatTaxRateAmount,
        flatTaxRateCurrencyAmount,
        country,
        description,
        stiTaxCode
	} = json

	return [
		[
			{ label: 'Tax Type', value: taxType, type: 'string' },
			{ label: 'Code', value: code, type: 'string' },
			{ label: 'Flat Tax Rate Currency Code', value: foreignCurrency, type: 'string' },
			{ label: 'Effective Date', value: date(effectiveDate), type: 'date' },
			{ label: 'Expiration Date', value: date(expirationDate), type: 'date' },
			{ label: 'Tax Percentage', value: format((parseFloat(taxPercentage) || 0).toFixed(2), 'normal', null), type: 'percentage' }
		],
		[
			{ label: 'Flat Tax Rate Amount', value: format(flatTaxRateAmount), type: 'currency' },
			{ label: 'Flat Tax Rate Currency Amount', value: format(flatTaxRateCurrencyAmount), type: 'currency' }
        ],
        [
            { label: 'STI Tax Code', value: stiTaxCode, type: 'string' },
            { label: 'Country', value: country, type: 'string' },
            { label: 'Description', value: description, type: 'string' }
        ]
	]
}


const mapCard = (json) => {
    const {
        code,
        country,
        taxType,
        stiTaxCode,
        description,
        flatTaxRateCurrencyCode,
        taxPercentage,
        flatTaxRateAmount,
        flatTaxRateCurrencyAmount
    } = json

    return [
        [
            { labels: ['code'], value: code, type: 'string' }
        ],
        [
            { labels: ['country'], value: country, type: 'string' }
        ],
        [
            { labels: ['type'], value: taxType, type: 'string' }
        ],
        [
            { labels: ['sti_tax_code'], value: stiTaxCode, type: 'string' }
        ],
        [
            { labels: ['description'], value: description, type: 'string' }
        ],
        [
            { labels: ['foreign_currency'], value: flatTaxRateCurrencyCode, type: 'string' }
        ],
        [
            { labels: ['percentage'], value: format((parseFloat(taxPercentage) || 0).toFixed(2), 'normal', null), type: 'percentage' }
        ],
        [
            { labels: ['flat_amount'], value: format(flatTaxRateAmount), type: 'currency' }
        ],
        [
            { labels: ['currency_amount'], value: format(flatTaxRateCurrencyAmount), type: 'currency' }
        ]
    ]
}

const mapper = (json, card, details) => {
    const name = json.tax_type

    return {
        ...json,
        __typename: json.__typename,
        id: json.id,
        name,
        shortName: name,
        details: details ? mapDetails(json) : [],
        card: card ? mapCard(json) : []
    }
}

const header = [
    { label: ["Code"], align: 'left' },
    { label: ["Country"], align: 'left' },
    { label: ["Type"], align: 'left' },
    { label: ["STI Tax Code"], align: 'left' },
    { label: ["Description"], align: 'left' },
    { label: ["Foreign Currency"], align: 'left' },
    { label: ["Percentage"], align: 'center' },
    { label: ["Flat Amount"], align: 'right' },
    { label: ["Foreign Currency Amount"], align: 'right' }
]

const taxtableentrydetailsMapper = {
    map: mapper,
    header
}

export default taxtableentrydetailsMapper;