export function camelize(str) {
    return (str || "").replace(/_([a-z])/g, v => v[1].toUpperCase());
}

export function getOrganizationName(organization, project){

    const metadata = {
		Organization : (organization || {}).name,
		Project: `${(project|| {}).taxRegistrationNumber} - ${(project|| {}).name}`,
		Url: window.location.href
	};
    const metadataString = (JSON.stringify(metadata, null, 2) || "").replace(/{|}|"/gi, "")
    return metadataString;
}

