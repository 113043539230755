import React from 'react'
import styles from './JsonViewer.module.sass'

const renderJson = (json) => {
	return Object.entries(json).map(([key, value], index) => {
		if (value && typeof value === 'object') {
			return (
				<div key={ index } className={ styles['inner-object'] }>
					<strong className={ styles['label'] }>{ key }</strong>
					<div className={ styles['group'] }>
						{ renderJson(value || {}) }
					</div>
				</div>
			)
		}
		return (
			<div key={ index } className={ styles['entry'] }>
				<div><small className={ styles['label'] }>{ key }</small></div>
				<div><span className={ styles['input'] }>{ value }</span></div>
			</div>
		)
	})
}

const JsonViewer = props => {
	const { data } = props

	const json = renderJson(data || {})

	return (
		<div className={ styles['root'] }>
			<div className={ styles['content'] }>
				{ json }
			</div>
		</div>
	)
}

export default JsonViewer
