import React, { forwardRef, useRef, useImperativeHandle } from 'react'
import { TextInput } from 'colbi_web_ui/lib/components/inputs'
import { useGlobalValue } from 'colbi_web_ui/lib/state/GlobalProvider'
import { FORM_ACTIONS } from '../FormActions'

import styles from './PackageForm.module.sass'

const PackageForm = (props, ref) => {
	const { data, status, onChange, onSave } = props
	const formRef = useRef()

	const {
		name,
        profile_id
	} = data || {}

	const { i18n } = useGlobalValue()

	const handleChange = (e, prop, value) => {
		onChange && onChange({
			originalEvent: e,
			target: {
				value: {
					data: {
						...data,
						[prop]: value
					}
				}
			}
		})
	}
	useImperativeHandle(ref, () => ({
		submit: () => {
			formRef.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))
		}
	}))

	return status !== 'loading' ? (
		<form ref={formRef} onSubmit={(e) => {
			e.preventDefault()
			e.stopPropagation()
			if (formRef.current.checkValidity()) {
				data["__typename"] = "Package";
				onSave && onSave()
			} else {
				formRef.current.reportValidity()
			}
		}}>
			<div className={styles['group-content']}>
				<div className={styles['flex']}>
					<TextInput
						className={styles['input']}
						name={i18n`Profile Id`}
						placeholder={i18n`Enter a profile Id`}
						value={profile_id || ''}
						required
						onChange={(e) => {
							handleChange(e, 'profile_id', e.target.value)
						}}
					/>
					<TextInput
						className={styles['input']}
						name={i18n`Profile Name`}
						placeholder={i18n`Enter a name`}
						value={name || ''}
						required
						onChange={(e) => {
							handleChange(e, 'name', e.target.value)
						}}
					/>
				</div>
			</div>
			
		</form>
	) : null
}

const PackageFormRef = forwardRef(PackageForm)

PackageFormRef.queries = (args) => {
	return [
		args && args.id ? {
			resource: 'profile',
			args: { 'profileId': args.id },
			body: '__typename,id,name,profile_id'
		} : undefined,
	].filter(q => q)
}

PackageFormRef.dataMapper = ['data']

PackageFormRef.actions = [FORM_ACTIONS.SAVE]

export default PackageFormRef