import I18n from "../../I18n"


const header = [
    { label: ["Description"], align: 'left' },
    { label: ["Rule Type"], align: 'left' },
    { label: ["Severity"], align: 'center' },
    { label: ["Entity"], align: 'center' },
    { label: ["Number Of Occurrences"], align: 'center' },
]

const mapCard = (json) => {
    const {
        DescriptionPreview,
        Severity,
        RuleType,
        Entity,
        NumberOfFindings
    } = json

    const referenceTypeFormated = Entity ? Entity.split("::").pop() : null;

    return [
        [
            { labels: ['description'], value: DescriptionPreview, type: 'string' },
        ],
        [
            { labels: ['rule_type'], value: RuleType, type: 'string' },
        ],
        [
            { labels: ['severity'], value: [`severity-${Severity}`, I18n.translate`${`severity-${Severity}`}`, 'center'], type: 'status' }
        ],
        [
            { labels: ['entity'], value: referenceTypeFormated ? I18n.translate("reference_" + referenceTypeFormated) : '', type: 'string', align: 'center' }
        ],
        [
            { labels: ['NumberOfFindings'], value: NumberOfFindings, type: 'number', align: 'center' }
        ],
    ]
}

const mapper = (json, card) => {
    const name = json.name
    return {
        ...json,
        __typename: json.__typename,
        name,
        shortName: name,
        card: card ? mapCard(json) : [],
        link: {
            to: `/:locale(pt|en|lt|es)?/:projectId/:fiscalYear([0-9]{4}|current)/:importId${json.ModulePrefixByFileType ? "/"+json.ModulePrefixByFileType : ""}/audit/rules/${json.id}`,
        }
    }
}

const ncrulefindingMapper = {
    map: mapper,
    header
};

export default ncrulefindingMapper