
const mapCard = json => {
	const {
		number,
		street,
		country,
		city,
		postalCode,
		address_type,
		region,
		full_address
	} = json;

	return [
		[{ labels: ["street"], value: `${street}`, type: "string" }],
		[{ labels: ["number"], value: `${number}`, type: "string" }],
		[{ labels: ["postalCode"], value: `${postalCode}`, type: "string" }],
		[{ labels: ["city"], value: `${city}`, type: "string" }],
		[{ labels: ["country"], value: `${country}`, type: "string" }],
        [{ labels: ["address_type"], value: `${address_type}`, type: "string" }],
        [{ labels: ["region"], value: `${region}`, type: "string" }],
        [{ labels: ["full_address"], value: `${full_address}`, type: "string" }],
    ]
	
};

const mapper = (json, card) => {
	return {
		...json,
		card: card ? mapCard(json) : []
	};
};

const header = [
	{ label: ["Street "]},
	{ label: ["Number"] },
	{ label: ["Postal Code"]},
	{ label: ["City"] },
	{ label: ["Country"]},
	{ label: ["Type"] },
	{ label: ["Region"]},
	{ label: ["Full Address"]}
];

const headeraddressMapper =  {
	map: mapper,
	header
};

export default headeraddressMapper;