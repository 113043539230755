const collapse = () => (
	{
		type: 'COLLAPSE_MENU'
	}
)
const expand = () => (
	{
		type: 'EXPAND_MENU'
	}
)

export const toggleMenu = () => (
	(dispatch, getState) => {
		const state = getState() || {}
		if (state.menu === 'expanded') {
			dispatch(collapse())
		} else {
			dispatch(expand())
		}
	}
)
