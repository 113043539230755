import I18n from '../../../I18n'
import { format } from "../../../utils"

const mapDetails = (json) => {
	const {
		tax,

		settlementAmount,

		customer,
		shipTo,
		product,
		lineNumber,

		invoiceNo,

		quantity,

		unitPrice,
		creditAmount,
		debitAmount,
		unit
	} = json

	return [
		[
			{ label: 'Invoice No.', value: invoiceNo, type: 'string' },

			{ label: 'Line Number', value: lineNumber, type: 'number' },

			{
				label: 'Quantity',
				value: quantity,
				type: 'number'
			},

			{
				label: 'Settlement Amount',
				value: format(settlementAmount),
				type: 'currency'
			},

		],
		[
			{
				label: 'Unit Price',
				value: format(unitPrice),
				type: 'currency'
			},
			{
				label: 'Debit Amount',
				value: format(debitAmount),
				type: 'currency'
			},
			{
				label: 'Credit Amount',
				value: format(creditAmount),
				type: 'currency'
			}
		],
		[
			customer ? {
				label: 'Customer',
				value: {
					id: customer.id,
					name: customer.name,
					__typename: 'Customer'
				},
				type: 'object'
			} : null,
			product ? {
				label: 'Product ID',
				value: {
					id: product.id,
					name: product.name,
					__typename: 'Product'
				},
				type: 'object'
			} : null,
			{
				label: 'Unit of Measure',
				value: unit || 'N/A',
				type: 'string'
			}
		],
		tax ? {
			title: 'Tax Information',
			rows: [
				[
					{
						label: 'Tax Code',
						value: tax.code || 'N/A',
						type: 'string'
					},
					{
						label: 'Tax Type',
						value: tax.type || 'N/A',
						type: 'string'
					},
					{
						label: 'Tax Country',
						value: tax.countryRegion || 'N/A',
						type: 'string'
					},
					{
						label: 'Percentage',
						value: format((parseFloat(tax.percentage) || 0).toFixed(2), 'normal', null),
						type: 'percentage'
					},
					{
						label: 'Tax Amount',
						value: format(tax.amount),
						type: 'currency'
					},
					{
						label: 'Tax Exemption Code',
						value: tax.exemptionCode || 'N/A',
						type: 'string'
					},
					{
						label: 'Tax Exemption Reason',
						value: tax.exemptionReason || 'N/A',
						type: 'string'
					},
					{
						label: 'Tax Entity',
						value: tax.entity || 'N/A',
						type: 'string'
					},
					{
						label: 'Tax Base',
						value: tax.Base || 'N/A',
						type: 'string'
					},
				]
			]
		} : null,
		shipTo ? {
			title: 'Ship To',
			rows: [
				[
					{
						label: 'Postal Code',
						value: shipTo.postal_code || 'N/A',
						type: 'string'
					},
					{
						label: 'City',
						value: shipTo.city || 'N/A',
						type: 'string'
					},
					{
						label: 'Region',
						value: shipTo.region || 'N/A',
						type: 'string'
					},
					{
						label: 'Country',
						value: shipTo.country || 'N/A',
						type: 'string'
					}
				]
			]
		} : null,

	]

}

const mapCard = (json) => {
	const {
		lineNumber,
		description,
		productCode,
		taxPercentage,
		debitAmount,
		quantity,
		unit,
		unitPrice,
		creditAmount
	} = json
	return [
		[
			{ labels: ['description'], value: description, type: 'string' }
		],
		[
			{ labels: ['number'], value: `[${lineNumber}]`, type: 'number' }
		],
		[
			{ labels: ['code'], value: productCode || 'N/A', type: 'number' }
		],
		[
			{ labels: ['quantity'], value: debitAmount && debitAmount > 0 && quantity ? I18n.translate`${format(quantity, undefined, null)} x ${format(unitPrice)}/${unit}` : 'N/A', type: 'number' }
		],
		[
			{ labels: ['quantity'], value: creditAmount && creditAmount > 0 && quantity ? I18n.translate`${format(quantity, undefined, null)} x ${format(unitPrice)}/${unit}` : 'N/A', type: 'number' }
		],
		[
			{ labels: ['tax_percentage'], value: taxPercentage || 0, type: 'percentage' }
		],
		[
			{ labels: ['debit_amount'], value: format(debitAmount), type: 'currency' }
		],
		[
			{ labels: ['credit_amount'], value: format(creditAmount), type: 'currency' }
		]
	]
}

const mapper = (json, card, details) => {
	return {
		...json,
		name: '[' + json.lineNumber + '] ' + json.productCode,
		shortName: json.productCode,
		details: details ? mapDetails(json) : [],
		card: card ? mapCard(json) : []
	}
}

const header = [
	{ label: ["Description"], align: 'left', columnName: "description", sortable: true },
	{ label: ["[No]"], align: 'center' },
	{ label: ["CODE"], align: 'center' },
	{ label: ["Quantity Debit Details"], align: 'center' },
	{ label: ["Quantity Credit Details"], align: 'center' },
	{ label: ["Tax Percentage"], align: 'right' },
	{ label: ["Debit"], align: 'right', columnName: "debit_amount", sortable: true, totalizerName: "total_debit_amount" },
	{ label: ["Credit"], align: 'right', columnName: "credit_amount", sortable: true, totalizerName: "total_credit_amount" }
]

const invoicelineMapper = {
	map: mapper,
	header
}
export default invoicelineMapper;