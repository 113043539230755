const header = [
	{ label: ["Name"], align: "left" },
	{ label: ["ID"], align: "left" },
	{ label: ["Type"], align: "left" },
	{ label: ["Street - Number"], align: "left" },
	{ label: ["Full Address"], align: "left" },
	{ label: ["Postal Code"], align: "left" },
	{ label: ["City"], align: "left" },
	{ label: ["Country"], align: "left" },
	{ label: ["Region"], align: "left" }
];

const mapCard = json => {
	const {
		supplierName,
		supplierRowKey,
		supplierId,
		addressType,
		streetName,
		addressNumber,
		fullAddress,
		postalCode,
		city,
		country,
		region
	} = json;

	return [
		[{ labels: ["type"], value: supplierName, type: "string" }],
		[
			supplierRowKey
				? {
						label: "Supplier",
						value: {
							id: supplierRowKey,
							name: supplierId,
							__typename: "LtSupplier"
						},
						type: "object"
				  }
				: { labels: ["Supplier"], value: supplierId || "N/A", type: "string" }
		],
		[{ labels: ["type"], value: addressType || "N/A", type: "string" }],
		[
			{
				labels: ["street", "number"],
				value: `${streetName} - ${addressNumber}`,
				type: "string"
			}
		],
		[{ labels: ["fullAddress"], value: fullAddress, type: "string" }],
		[{ labels: ["postalCode"], value: postalCode, type: "string" }],
		[{ labels: ["city"], value: city, type: "string" }],
		[{ labels: ["country"], value: country, type: "string" }],
		[{ labels: ["region"], value: region || "N/A", type: "string" }]
	];
};

const mapper = (json, card) => {
	const name = json.assetId;

	return {
		...json,
		__typename: json.__typename,
		id: "",
		name,
		shortName: name,
		card: card ? mapCard(json) : []
	};
};

const assetsupplierMapper = {
	map: mapper,
	header
};

export default assetsupplierMapper;
