import { checkNumberValues } from "../../../utils/convertUtils";

const mapCard = (json) => {
	const {
		workIdentificationNumber,
		startDateOfWork,
		dateOfCompletionOfWork,
		balanceNotDeducted,
		incentiveAmountInThePeriod,
		deductionInPeriod,
		balanceCarried,
		repaymentValue,
		valueToReplace,
	} = json

	return [
		[
			{ labels: ['work_identification_number'], value: workIdentificationNumber, type: 'string', basis: '20%' },
		],
		[
			{ labels: ['start_date_of_work'], value: startDateOfWork, type: 'string', basis: '20%' }
		],
		[
			{ labels: ['date_of_completion_of_work'], value: dateOfCompletionOfWork, type: 'string', basis: '20%' }
		],
		[
			{ labels: ['balance_not_deducted'], value: checkNumberValues(balanceNotDeducted), type: 'number', basis: '20%' }
		],
		[
			{ labels: ['incentive_amount_in_the_period'], value: checkNumberValues(incentiveAmountInThePeriod), type: 'number', basis: '20%' }
		],
		[
			{ labels: ['deduction_in_period'], value: checkNumberValues(deductionInPeriod), type: 'number', basis: '20%' }
		],
		[
			{ labels: ['balance_carried'], value: checkNumberValues(balanceCarried), type: 'number', basis: '20%' }
		],
		[
			{ labels: ['repayment_value'], value: checkNumberValues(repaymentValue), type: 'number', basis: '20%' }
		],
		[
			{ labels: ['value_to_replace'], value: checkNumberValues(valueToReplace), type: 'number', basis: '20%' }
		],
	]
}

const mapper = (json, card) => {
	return {
		...json,
		card: card ? mapCard(json) : []
	}
}

const header = [
	{ label: ["N.º de identificação da obra"], align: 'left', basis: '20%' },
	{ label: ["Data do início da obra"], align: 'left', basis: '20%' },
	{ label: ["Data de conclusão da obra"], align: 'left', basis: '20%' },
	{ label: ["Saldo não deduzido no período anterior"], align: 'left', basis: '20%' },
	{ label: ["Valor do incentivo no período"], align: 'left', basis: '20%' },
	{ label: ["Dedução do período"], align: 'left', basis: '20%' },
	{ label: ["Saldo que transita para periodo seguinte"], align: 'left', basis: '20%' },
	{ label: ["Valor a reembolsar"], align: 'left', basis: '20%' },
	{ label: ["Valor a repôr"], align: 'left', basis: '20%' },
]

const taxincentivemod22Mapper = {
	map: mapper,
	header
}

export default taxincentivemod22Mapper;