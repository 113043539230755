import { AuditSummaryWidget, Grid, cards, Widget, charts } from 'colbi_web_ui/lib/components'
import AppByFile from '../controllers/AppByFile/AppByFile'

const queries = (args = {}) => {
	return args.fiscalYear ? [
		{
			resource: 'projectSummary',
			args: {
				projectId: (args.projectId),
				fiscalYear: (args.fiscalYear),
				modulePrefix: 'saft_pt'
			},
			body: '...projectSummary'
		},
		{
			resource: 'audit_summary',
			args: {
				projectId: (args.projectId),
				fiscalYear: (args.fiscalYear),
				modulePrefix: 'saftpt',
				importId: 'full'
			},
			body: 'severity, count'
		},
		{
			resource: 'reports',
			args: {
				projectId: (args.projectId),
				fiscalYear: (args.fiscalYear)
			},
			body: 'report, setClose'
		},
		{
			resource: 'balance',
			args: {
				projectId: (args.projectId),
				fiscalYear: (args.fiscalYear)
			},
			body: 'report, setClose'
		},
		{
			resource: 'profitAndLoss',
			args: {
				projectId: (args.projectId),
				fiscalYear: (args.fiscalYear)
			},
			body: 'report, setClose'
		},
		{
			resource: 'saftpt_invoices_summary',
			args: {
				projectId: (args.projectId),
				fiscalYear: (args.fiscalYear),
				modulePrefix: (args.modulePrefix)
			},
			body: 'summary{ key, value }'
		},
		{
			resource: 'summary_report',
			args: {
				projectId: (args.projectId),
				fiscalYear: (args.fiscalYear),
			},
			body: 'report',
			downloadable: true,
			downloadOnly: true,
			xlsxTranslation: "summary_report"
		},
		{
			resource: 'topProducts',
			args: {
				projectId: (args.projectId),
				fiscalYear: (args.fiscalYear),
				modulePrefix: 'saft_pt'
			},
			body: 'report, setClose'
		},
		{
			resource: 'topCustomers',
			args: {
				projectId: (args.projectId),
				fiscalYear: (args.fiscalYear),
				modulePrefix: 'saft_pt'
			},
			body: 'report, setClose'
		},
		{
			resource: 'close_overview_widget',
			args: {
				projectId: (args.projectId),
				fiscalYear: (args.fiscalYear),
				modulePrefix: "saft_pt",
				entity: "invoices"
			}
		},
	] : []
}

const legacyOverview = (path, navigation, exact, title) => ({
	path,
	navigation,
	component: AppByFile,
	queries,
	exact,
	title: [title],
	showCsvButton: true,
	components: [
		{
			component: AuditSummaryWidget,
			modifiers: ['--root-margin-bottom-normal'],
			props: {
				icon: 'audit-icon',
				title: 'label(Audit Results)',
				summary: 'query(1).list',
				link: {
					to: 'audit',
					name: 'label(See All)'
				},
				overviewSummaryWidget: true
			}
		},
		{
			component: Widget,
			modifiers: ['--root-margin-bottom-normal'],
			props: {
				icon: 'sales',
				title: 'label(Sales KPIS)',
				setClose: "query(0).setClose",
				children: [
					{
						component: Grid,
						modifiers: ['--grid-border-inline'],
						props: {
							columnsTemplate: [1],
							items: [
								{
									data: [
										{
											name: 'label(Sales Gross Total)',
											value: 'currency(query(0).invoicesGrossTotal)',
											icon: 'gross-sales'
										},
										{
											name: 'label(Sales Net Total)',
											value: 'currency(query(0).invoicesNetTotal)',
											icon: 'net-sales'
										},
										{
											name: 'label(Invoice Avg Net)',
											value: 'currency(query(0).invoicesNetAverage)',
											icon: 'kpi-icon'
										},
										{
											name: 'label(Invoice Max Value)',
											value: 'currency(query(0).invoicesMaxValue)',
											icon: 'kpi-icon'
										}
									]
									
								}
							],
							cards: [charts.KpisLegend]
						}
					}
				]
			}
		},
		{
			component: Widget,
			modifiers: ['--root-margin-bottom-normal'],
			props: {
				icon: 'sales',
				title: 'label(Sales Summary)',
				setClose: "query(9)",
				summary: [
					{
						name: 'label(No. Invoices)',
						value: 'query(0).invoicesCount'
					},
					{
						name: 'label(No. Invoices Lines)',
						value: 'query(0).invoiceLinesCount'
					}
				],
				link: {
					to: 'invoices',
					name: 'label(Invoices)'
				},
				children: [
					{
						component: Grid,
						modifiers: ['--grid-border-inline'],
						props: {
							columnsTemplate: [1],
							aspect: ['31x10'],
							items: [
								{
									data: 'query(5).list',
									labelsPosition: 'top',
									stacked: true,
									summarized: true,
									links: [{
										to: ['param(projectId)', 'param(fiscalYear)', 'param(importId)', 'param(modulePrefix)', '/invoices'],
										filterColumn: 'period',
									}],
									modifiers: ['--top-text-small'],
								},
							],
							cards: [charts.BarChart]
						}
					}
				]
			}
		},
		{
			component: Grid,
			modifiers: [
				'--grid-padding-none',
				'--root-margin-bottom-normal'
			],
			props: {
				columnsTemplate: [1,1],
				items: [
					{
						title: 'label(Top 10 Products)',
						icon: 'top',
						setClose: 'query(7).setClose',
						children: [
							{
								component: Grid,
								modifiers: [
									'--root-margin-bottom-normal',
									'--cardholder-background-color-widget',
									'--mobile--cardholder-aspect-ratio-4x5'
								],
								props: {
									columnsTemplate: [1],
									items: [
										{
											data: 'query(7).report',
											link: `${path}/master_data/products/`,
											currencyType:{
												localeCurrency : 'sk-Sk',
												currency: 'EUR'
											}
										},
									],
									cards: [charts.PieChart]
								}
							},
						]
					},
					{
						title: 'label(Top 10 Customers)',
						icon: 'top',
						setClose: 'query(8).setClose',
						children: [
							{
								component: Grid,
								modifiers: [
									'--root-margin-bottom-normal',
									'--cardholder-background-color-widget',
									'--mobile--cardholder-aspect-ratio-4x5'
								],
								props: {
									columnsTemplate: [1],
									items: [
										{
											data: 'query(8).report',
											link: `${path}/master_data/customers/`,
											chartColor: "--chart-secondary-color",
											currencyType:{
												localeCurrency : 'sk-Sk',
												currency: 'EUR'
											}
										},
									],
									cards: [charts.PieChart]
								}
							},
						]
					},
				],
				cards: [Widget]
			}
		},
		{
			component: Grid,
			modifiers: [
				'--grid-padding-none',
				'--root-margin-bottom-normal'
			],
			props: {
				columnsTemplate: [1, 1],
				items: [
					{
						title: 'label(Balance Sheet)',
						icon: 'balance-sheet',
						setClose: 'query(3).setClose',
						link: {
							to: 'link(/:projectId/:fiscalYear/:importId/:modulePrefix/reports/balance_taxonomy_annual)',
							name: 'label(Report)'
						},
						children: [
							{
								component: Grid,
								modifiers: ['--grid-margin-none', '--grid-padding-none'],
								props: {
									columnsTemplate: [2,1],
									aspect: ['4x3'],
									items: [
										{
											data: 'query(3).report',
											link: {
												to: ['param(projectId)', 'param(fiscalYear)', 'param(importId)', 'param(modulePrefix)', '/reports/balance_taxonomy_annual'],
											}
										},
										{
											data: 'query(3).report'
										}
									],
									cards: [charts.BalanceSheetChart, charts.BalanceSheetChartLegend]
								}
							}
						]
					},
					{
						title: 'label(Profit and Loss)',
						icon: 'profit-loss',
						setClose: 'query(4).setClose',
						link: {
							to: 'link(/:projectId/:fiscalYear/:importId/:modulePrefix/reports/profit_and_loss_taxonomy_annual)',
							name: 'label(Report)'
						},
						children: [
							{
								component: Grid,
								modifiers: ['--grid-margin-none', '--grid-padding-none'],
								props: {
									aspect: ['4x3'],
									columnsTemplate: [2, 1],
									items: [
										{
											data: 'query(4).report',
											link: {
												to: ['param(projectId)', 'param(fiscalYear)', 'param(importId)', 'param(modulePrefix)', '/reports/profit_and_loss_taxonomy_annual'],
												filterColumn: 'code',
											}
										},
										{ data: 'query(4).report' }
									],
									cards: [charts.ProfitAndLossChart, charts.ProfitAndLossChartLegend]
								}
							}
						]
					}
				],
				cards: [Widget]
			}
		},
		{
			component: Grid,
			modifiers: [
				'--grid-padding-none',
				'--root-margin-bottom-normal'
			],
			props: {
				columnsTemplate: [1, 1],
				items: [
					{
						title: 'label(Sales vs Accounting)',
						icon: 'comparation',
						setClose: 'query(3).setClose',
						children: [
							{
								component: Grid,
								modifiers: ['--grid-border-inline', '--grid-margin-none', '--grid-padding-none'],
								props: {
									columnsTemplate: [1],
									aspect: ['21x9'],
									items: [
										{
											currency: true,
											data: ['query(2).report.2', 'query(2).report.3.result'],
											dataLabels: ['label(Commercial Documents Issued (NET))', 'label(Revenues (CTB))'],
											links: [
												{
													to: ['param(projectId)', 'param(fiscalYear)', 'param(importId)', 'param(modulePrefix)', '/invoices'],
													filterColumn: 'period'
												},
												{
													to: ['param(projectId)', 'param(fiscalYear)', 'param(importId)', 'param(modulePrefix)', '/reports/trial_balance_taxonomy_current_year'],
													filterColumn: 'a01',
													filter: 'query(2).report.3.filters'
												}, {}
											]
										}
									],
									cards: [charts.BarChart]
								}
							}
						]
					},
					{
						title: 'label(VAT Paid / Deducted)',
						icon: 'comparation',
						setClose: 'query(2).setClose',
						children: [
							{
								component: Grid,
								modifiers: ['--grid-border-inline', '--grid-margin-none', '--grid-padding-none'],
								props: {
									columnsTemplate: [1],
									aspect: ['21x9'],
									items: [
										{
											currency: true,
											data: ['query(2).report.4.result', 'query(2).report.5.result'],
											dataLabels: ['label(Paid VAT)', 'label(Deducted VAT)'],
											links: [
												{
													to: ['param(projectId)', 'param(fiscalYear)', 'param(importId)', 'param(modulePrefix)', '/transactions'],
													filterColumn: 'period',
													filter: 'query(2).report.4.filters'
												},
												{
													to: ['param(projectId)', 'param(fiscalYear)', 'param(importId)', 'param(modulePrefix)', '/transactions'],
													filterColumn: 'period',
													filter: 'query(2).report.5.filters'
												}, {}
											]
										}
									],
									cards: [charts.BarChart]
								}
							}
						]
					}
				],
				cards: [Widget]
			}
		},
		{
			component: Grid,
			modifiers: [
				'--root-margin-bottom-normal',
				'--cardholder-background-color-widget',
				'--mobile--cardholder-aspect-ratio-4x5'
			],
			props: {
				columnsTemplate: [1, 1, 1],
				aspect: ['5x4', '5x4', '5x4'],
				items: [
					{
						title: 'label(Sales (Net))',
						data: ['query(2).report.0'],
						path: "/:locale(pt|en|lt|es)?/:projectId/:fiscalYear([0-9]{4}|current)/:importId/:modulePrefix/master_data/products/:id"
					},
					{
						title: 'label(Customers (Net))',
						data: ['query(2).report.1'],
						path: "/:locale(pt|en|lt|es)?/:projectId/:fiscalYear([0-9]{4}|current)/:importId/:modulePrefix/master_data/customers/:id"
					},
					{
						title: 'label(Top sales by country)',
						data: ['query(2).report.6'],
						path: "/:locale(pt|en|lt|es)?/:projectId/:fiscalYear([0-9]{4}|current)/:importId/:modulePrefix/invoices"
					}
				],
				cards: [cards.TopsCard, cards.TopsCard, cards.SalesByCountryCard]
			}
		},
		{
			component: Grid,
			modifiers: [
				'--root-margin-bottom-normal',
				'--root-background-color-widget-background-color'
			],
			props: {
				columnsTemplate: [1, 1, 1],
				items: [
					{
						title: 'label(Customers/Suppliers)',
						items: [
							{
								name: 'label(Customers)',
								count: 'number(query(0).customersNifCount)',
								link: `${path}/master_data/customers`
							},
							{
								name: 'label(Customers Code)',
								count: 'number(query(0).customersCount)'
							},
							{
								name: 'label(Customers Countries)',
								count: 'number(query(0).customersCountriesCount)'
							},
							{
								name: 'label(Suppliers)',
								count: 'number(query(0).suppliersNifCount)',
								link: `${path}/master_data/suppliers`
							},
							{
								name: 'label(Suppliers Code)',
								count: 'number(query(0).suppliersCount)'
							},
							{
								name: 'label(Suppliers Countries)',
								count: 'number(query(0).suppliersCountriesCount)'
							}
						]
					},
					{
						title: 'label(Products)',
						items: [
							{
								name: 'label(Products)',
								count: 'number(query(0).productsCount)',
								link: `${path}/master_data/products`
							},
							{
								name: 'label(Products Group)',
								count: 'number(query(0).productsGroupCount)'
							},
							{
								name: 'label(Products Code)',
								count: 'number(query(0).productsCodeCount)'
							},
							{
								name: 'label(Tax Codes)',
								count: 'number(query(0).taxCodesCount)'
							}
						]
					},
					{
						title: 'label(Transactions)',
						items: [
							{
								name: 'label(Customers on Transactions)',
								count: 'number(query(0).customersInvoicesCount)'
							},
							{
								name: 'label(Products on Transactions)',
								count: 'number(query(0).productsInvoicesCount)'
							},
							{
								name: 'label(Invoices)',
								count: 'number(query(0).invoicesCount)',
								link: `${path}/invoices`
							},
							{
								name: 'label(Invoice Lines)',
								count: 'number(query(0).invoiceLinesCount)'
							},
							{
								name: 'label(Financial Accounts)',
								count: 'number(query(0).generalLedgersCount)'
							},
							{
								name: 'label(Posting Accounts)',
								count: 'number(query(0).leafAccountsCount)'
							},
							{
								name: 'label(GL Transactions)',
								count: 'number(query(0).transactionsCount)',
								link: `${path}/transactions`
							},
							{
								name: 'label(GL Transaction Lines)',
								count: 'number(query(0).transactionLinesCount)'
							}
						]
					}
				],
				cards: [cards.LinksCard]
			}
		}
	]
})

export default legacyOverview;
