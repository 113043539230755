const mapDetails = json => {
    const { 
        registrationNumber, taxEntity, supplierId, name, country , vatRegistrationNumber
    } = json;

    return [
        [
			{label: 'Supplier Id', value: supplierId, type: 'string'},
			{label: 'Entity_Name', value: name, type: 'string'},
			{label: 'Registration Number', value: registrationNumber, type: 'string'},
            {label: 'Vat Registration Number', value: vatRegistrationNumber, type: 'string'},
            {label: 'Entity', value: taxEntity, type: 'string'},
			{label: 'Country', value: country, type: 'string'},
        ]
    ];
};

const mapCard = json => {
    const {
        supplierId,
        name,
        registrationNumber,
        taxEntity,
        vatRegistrationNumber,
        country
    } = json;
    return [
        [{labels: ["Supplier Id"], value: supplierId, type: "string", basis: "12%"}],
        [{labels: ["Entity_Name"], value: name, type: "string", basis: "50%"}],
        [{labels: ["Vat Registration Number"], value: `${vatRegistrationNumber}`, type: "string"}],
        [{labels: ["Registration Number"], value: `${registrationNumber}`, type: "string"}],
        [{labels: ["Country"], value: `${country}`, type: "string"}],
        [{labels: ["entity"], value: `${taxEntity}`, type: "string"}],
    ];
};


const mapper = (json, card, details) => {
    return {
        ...json,
        name: json.name,
        shortName: json.name,
        details: details ? mapDetails(json) : [],
        card: card ? mapCard(json) : [],
    };
};

const header = [
    {label: ['Supplier id'], align: 'left', columnName: "supplier_id", sortable: true, basis: "12%"},
    {label: ['Supplier Name'], align: 'left', columnName: "name", sortable: true, basis: "50%"},
    {label: ['Vat Registration Number'], align: 'left', columnName: "vat_registration_number", sortable: true},
    {label: ['Registration Number'], align: 'left', columnName: "registration_number", sortable: true},
    {label: ['Country'], align: 'left', columnName: "country", sortable: true},
    {label: ['Entity'], align: 'left'},
]

const supplierMapper = {
    map: mapper,
    header
};

export default supplierMapper;