import { date, format } from '../../../utils'

const header = [
    { label: ["Invoice Type"], align: 'left', columnName: "invoice_type", sortable: true, basis: '13%'  },
    { label: ["Discount"], align: 'left', columnName: "settlement_discount", sortable: true  },
    { label: ["Due Date"], align: 'center' , columnName: "settlement_due_date", sortable: true },
    { label: ["Date"], align: 'center', columnName: "settlement_date", sortable: true  },
    { label: ["Payment Mechanism"], align: 'center', columnName: "payment_mechanism", sortable: true  },
    { label: ["Amount"], align: 'right', columnName: "amount", sortable: true  },
    { label: ["Currency Code"], align: 'center', columnName: "currency_code", sortable: true  },
    { label: ["Currency Amount"], align: 'center' , columnName: "currency_amount", sortable: true },
    { label: ["Debit Credit Indicator"], align: 'center', columnName: "debit_credit_indicator", sortable: true  },

]

const mapCard = (json) => {
	const {
        settlementAmountCurrencyCode,
        settlementDiscount,
        settlementDueDate,
        settlementDate,
        paymentMechanism,
        debitCreditIndicator,
        settlementAmount,
        settlementCurrencyAmount,
        invoiceType
	} = json

	return [
        [
            { labels: ['invoyce_type'], value: invoiceType || 'N/A', type: 'string', basis: '13%' }
        ],
        [
            { labels: ['discount'], value: settlementDiscount || 'N/A', type: 'string' }
        ],
        [
            { labels: ['duoDate'], value: date(settlementDueDate), type: 'date' }
        ],
        [
            { labels: ['date'], value: date(settlementDate), type: 'date' }
        ],
        [
            { labels: ['paymentMechanism'], value: paymentMechanism, type: 'number' }
        ],
        [
            { labels: ['amount'], value: format(settlementAmount, "fullValue"), type: 'currency', align: "right" }
        ],
        [
            { labels: ['currencyCode'], value: settlementAmountCurrencyCode || 'N/A', type: 'string', align:'center' }
        ],
        [
            { labels: ['currencyAmount'], value: format(settlementCurrencyAmount,'fullValue', null, 2), type: 'currency' }
        ],
        [
            { labels: ['debitCreditIndicator'], value: debitCreditIndicator, type: 'number' }
        ],
	]
}

const mapper = (json, card) => {
    const name = json.invoice_id || json.purchase_invoice_id || json.payment_id
    
    return {
        ...json,
        __typename: json.__typename,
        id: json.id,
		name,
		shortName: name,
		card: card ? mapCard(json) : []
	}
}

const settlementMapper = {
    map: mapper,
    header
}

export default settlementMapper;