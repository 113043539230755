import crypto from "crypto";
export function encryptPassword(text) {
    try {
        const ALG = 'aes-256-gcm'
  
        const iv = crypto.randomBytes(16)
  
        const salt = crypto.randomBytes(64)
  
        const key = crypto.pbkdf2Sync("8jiue5gT05km9yJfq4lDrFoJFcaloYAW", salt, 2145, 32, 'sha512')
  
        const cipher = crypto.createCipheriv(ALG, key, iv)
  
        const encrypted = Buffer.concat([cipher.update(text, 'utf8'), cipher.final()])
  
        const tag = cipher.getAuthTag()
  
        return Buffer.concat([salt, iv, tag, encrypted]).toString('base64')
    } catch (e) {
        throw new Error('Somethings Went Wrong!')
    }
  }