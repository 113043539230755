import { date } from '../../../utils'

const header = [
    { label: ["Origination On"], align: 'left' },
	{ label: ["Orde Date"], align: 'center' }
]

const mapCard = (json) => {
	const {
        originatingOn,
        orderDate
	} = json

	return [
        [
            { labels: ['originationOn'], value: originatingOn, type: 'string' }
        ],
        [
            { labels: ['date'], value: date(orderDate), type: 'date' }
        ]
	]
}

const mapper = (json, card) => {
    const name = json.invoice_line_id || json.purchase_invoice_line_id
    
    return {
        ...json,
        __typename: json.__typename,
        id: json.id,
		name,
		shortName: name,
		card: card ? mapCard(json) : []
	}
}

const orderreferenceMapper =  {
    map: mapper,
    header
}

export default orderreferenceMapper;