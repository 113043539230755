const load = () => {
	try {
		return localStorage.getItem('menu-expanded') || 'expanded'
	} catch(e) {
		console.log(e)
		return 'expanded'
	}
}

const save = (value) => {
	try {
		localStorage.setItem('menu-expanded', value)
	} catch(e) {
		console.log(e)
	}
}

const menu = (state = load(), action) => {
	switch (action.type) {
		case 'COLLAPSE_MENU':
			save('collapsed')
			return 'collapsed'
		case 'EXPAND_MENU':
			save('expanded')
			return 'expanded'
		default: break
	}
	return state
}

export default menu
