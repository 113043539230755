import {
	List,
	rows,
	charts,
	Filters,
	Grid,
	Widget,
} from "colbi_web_ui/lib/components";
import { parseFilter } from "colbi_web_ui/lib/utils";
import ExportButton from "../components/ExportButton/ExportButton";
import AppByFile from "../controllers/AppByFile/AppByFile";

const queries = (args = {}) => {
	const entityResource = args.modulePrefix ?  `${args.modulePrefix}_${args.entity}` : args.entity
	let result = [
		{
			resource: args.modulePrefix ?  `${args.modulePrefix}_${args.entity}` : args.entity,
			args: {
				projectId: args.projectId,
				fiscalYear: args.fiscalYear,
				modulePrefix: args.modulePrefix,
				filter: args.filter
					? { type: "[FilterInput!]", value: parseFilter(args.filter) }
					: undefined,
				sort: args.sort
					? { type: "[SortInput!]", value: parseFilter(args.sort) }
					: undefined,
				page: parseInt(args.page, 10) || 0,
			},
			body: `__typename, items{ ...${entityResource} }, hasMore, total, totalizers, filters(projectId:$projectId,fiscalYear:$fiscalYear,modulePrefix:$modulePrefix){ prop, name, type, list, disableOperators, mandatory }`,
		},
		{
			resource: `${entityResource}_summary`,
			args: {
				projectId: args.projectId,
				fiscalYear: args.fiscalYear,
				modulePrefix: args.modulePrefix,
			},
			body: "summary{ key, value }",
		},
		{
			resource: `${entityResource}_export`,
			args: {
				projectId: args.projectId,
				fiscalYear: args.fiscalYear,
				modulePrefix: args.modulePrefix,
				filter: args.filter
					? { type: "[FilterInput!]", value: parseFilter(args.filter) }
					: undefined,
				sort: args.sort
					? { type: "[SortInput!]", value: parseFilter(args.sort) }
					: undefined,
				page: parseInt(args.page, 10) || 0,
			},
			body: `items{...${entityResource}}`,
			downloadable: true,
			downloadOnly: true,
			xlsxTranslation: entityResource,
		}
	];

	if (args.entity === "invoices" || args.entity === "transactions") {
		result.push(
			{
				resource: `${entityResource}_with_lines_export`,
				args: {
					projectId: args.projectId,
					fiscalYear: args.fiscalYear,
					filter: args.filter
						? { type: "[FilterInput!]", value: parseFilter(args.filter) }
						: undefined,
				},
				body: `items{...${entityResource}_with_lines_export}`,
				downloadable: true,
				downloadOnly: true,
				xlsxTranslation: `${entityResource}_with_lines_export`,
			}
		);
	}

	return result

};

const legacyTransactions = (path, navigation, exact, title) => ({
	path,
	navigation,
	component: AppByFile,
	queries,
	exact,
	title: [title, "label(param(entity))"].filter((l) => l),
	components: [
		{
			component: Widget,
			modifiers: ["--root-margin-bottom-small"],
			props: {
				icon: "sales",
				title: ["Number of ", "param(entity)", " per type"],
				entity: "param(entity)",
				children: [
					{
						component: Grid,
						props: {
							columnsTemplate: [1],
							aspect: ["18x6"],
							items: [
								{
									data: "query(1).list",
									labelsPosition: "inside",
									stacked: true,
									summarized: true,
									links: [
										{
											to: [
												"param(projectId)",
												"param(fiscalYear)",
												"param(importId)",
												"param(modulePrefix)",
												"/",
												"param(entity)",
											],
											filterColumn: "period",
											options: {
												payments: "payment_type",
												stock_movements: "movement_type",
												transactions: "transaction_type",
												work_documents: "work_type",
												asset_transactions: "asset_transaction_type"
											},
										},
									],
								},
							],
							cards: [charts.BarChart],
						},
					},
				],
			},
		},
		{
			component: Filters,
			props: {
				filter: "param(filter)",
				availableFilters: "query(0).filters",
				stickyTop: 40,
				anchor: "results",
			},
		},
		{
			component: ExportButton,
			props: {
				data: "query(0).list"
			}
		},
		{
			component: List,
			modifiers: ["--root-margin-top-small", "--row-style-alternate"],
			props: {
				id: "results",
				items: "query(0).list",
				filter: "param(filter)",
				sort: "param(sort)",
				basePath: path,
				cardKey: "param(entity)",
				card: rows.EntityCard,
				header: "query(0).header",
				metadata: "query(0).metadata",
				page: "param(page)",
				status: "status(0)",
				pageSize: "param(pageSize)",
				footer: "query(0).footer",
				totalizers: "query(0).totalizers",
			},
		},
	],
});

export default legacyTransactions;
