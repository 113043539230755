const header = [
    {label: ["Tax Type"], align: 'left'},
    {label: ["Tax Code"], align: 'left'}
];

const mapCard = (json) => {
    const {taxType, taxCode} = json;

    return [
        [
            {labels: ['taxType'], value: taxType, type: 'string'}
        ],
        [
            {labels: ['taxCode'], value: taxCode, type: 'string'}
        ]
    ]
};

const mapper = (json, card) => {
    const name = json.productCode;

    return {
        ...json,
        __typename: json.__typename,
        id: json.id,
        name,
        shortName: name,
        card: card ? mapCard(json) : []
    }
};

const producttaxeMapper = {
    map: mapper,
    header
}

export default producttaxeMapper;