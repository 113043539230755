import React, { useEffect } from "react";
import { connect } from "react-redux";
import I18n from "../../I18n";
import { GlobalProvider as ColbiUIProvider } from "colbi_web_ui/lib/state/GlobalProvider";
import { loginWithToken, setLocale } from "../../store/actions";
import { GlobalProvider } from "../../state/globalProvider";
import {
	LoadingIndicator,
} from "colbi_web_ui/lib/components";
import { decryptSession } from 'colbi_web_ui/lib/utils/roleValidator'

const Provider = ({ children, ...props }) => {
	return (
		<GlobalProvider {...props}>
			<ColbiUIProvider {...props}>{children}</ColbiUIProvider>
		</GlobalProvider>
	);
};

const ExternalApplication = (props) => {
	const {
		location,
		match,
		loginWithToken
	} = props;

	const locale = match.params.locale
		
	const i18n = I18n.use(locale);
	setLocale(locale);

	const appTheme = process && process.env && process.env.REACT_APP_COLBI_THEME ? process.env.REACT_APP_COLBI_THEME.toLocaleLowerCase() : "";
	localStorage.setItem("theme", appTheme);
	
	const session = window.localStorage.getItem("persist:session");
	let userInformation =  decryptSession(JSON.parse(session));

	useEffect(() => {
		if(!userInformation){
			loginWithToken(match.params.tokenAccess, locale)
		}
    }, [loginWithToken, match.params.tokenAccess, locale, userInformation]);

	return (
		<Provider
			{...{
				i18n,
				location,
			}}
		>
			<LoadingIndicator/>
		</Provider>
	);
};

export default connect(
	({user, fetch}) => ({
		user,
		fetch
	}),
	{
		loginWithToken,
		setLocale,
	}
)(ExternalApplication);
