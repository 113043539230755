import { checkNumberValues } from "../../../utils/convertUtils";
const mapDetails = json => {
	const {
		declarationType,
		recordType,
		nif,
		periodFrom,
		periodTo,
		exercise,
		currencyIndicator,
		board003CDigoDoServiODeFinanAs,
		board003TipoDeSujeitoPassivo,
		board003Geral,
		board003NifDaSociedadeDominanteResponsVelArt69ANS3E4,
		board003PretendeExercerAOpOPelasTaxasDoArt87N1,
		board003OcorreuAlgumaDasSituaEsReferidasNoArt87N7,
		board003DataDeclaraODeSubstituiOArt122N3,
		board003TipoDeDeclaraO,
		board003DeclaracaoDoGrupo,
		board003DataDaTransmissOAquisiOEntidadesNOResidentesSemEstabEstVel,
		board003DataDaCessaO,
		board003AnexoARegimeSimplificadoIndicadorDeAnexoAnteriorA2010,
		board003AnexoBRegimeSimplificadoIndicadorDeAnexoAnteriorA2010,
		board003AnexoCRegiEsAutNomasIndicadorDeAnexo,
		board003AnexoDBenefCiosFiscais,
		board003NifDoRepresentanteLegal,
		board003NifDoContabilistaCertificado,
		board003QualificaODaEmpresaNosTermos,
		board003TransfDeResidCessaOActivArtS8384E54AN11,
		board003AnexoERegimeSimplificadoIndicadorDeAnexoPosteriorA2013,
		board003IndiqueQueSeTrataDeUmOrgDeInvColTributadoNosTermosDoArt22DoEbf,
		board003Artigo36ADoEbf,
		board003AnexoFOic,
		board003AntesDissolucao,
		board003DataDaDissoluO,
		board003ConsideradoUmEstabelecimentoEstVelParaEfeitosDeImputaO,
		board003RegimeEspecialAtividadesDeTransporteMarTimoDecLeiN,
		board003AnexoGTransporteMarTimo,
		board003IsencaoDefinitiva,
		board003IsencaoTemporaria,
		board003ReducaoTaxa,
		board003Simplificado,
		board003TransparenciaFiscal,
		board003GruposDeSociedades,
		board003DeclaracaoDoPeriodoDeLiquidacao,
		board003DeclaracaoDoPeriodoDeCessacao,
		board003AposAAlteracao,
		board003DeclaracaoDoPeriodoDoInicioDeAtividade,
		board003AntesDaAlteracao,
		board003AposDissolucao,
		board008EstabelecimentosDeEnsinoParticularExArt56DoEbf,
		board008BenefCiosRelativosInterioridadeArt41BEExArt43DoEbf,
		board008AntigoEstatutoFiscalCooperativoArt7N3DaLei859816Dez,
		board008EntidadesLicenciadasNaZonaFrancaDaMadeiraExArt35DoEbf,
		board008RegiOAutNomaAOresDecLegRegionalN21999A201,
		board008RegiOAutNomaMadeiraDecLegRegionalN22001M202Nova,
		board008RendimentosPrediaisDeEntidadesArt87N4,
		board008MaisValiasImobiliRiasIncrementosPatrimoniaisObtidosPorEntidadesNOResid,
		board008OutrosRendimentosObtidosPorEntidadesNOResidentesSem,
		board008EntidadesLicenciadasNaZonaFrancaDaMadeiraArt36E36ADo,
		board0081PrejuZoFiscalRegimeGeral,
		board0082LucroTributVelRegimeGeral,
		board008PrejuZosFiscaisDedutVeisRegimeGeral,
		board008BenefCiosFiscaisRegimeGeral,
		board0084MatRiaColectVel23RegimeGeral,
		board0082LucroTributVelReduOTaxa,
		board0081PrejuZoFiscalReduOTaxa,
		board008MaisValiasMobiliRiasObtidasPorEntidadesNOResidentesSemEstab,
		board008RendDecorrentesDaAlienDeUnidadesArt22AN1AlCDo,
		board008AjustamentoReaidArt5N1BAnexoLeiN6120142608,
		board008MatRiaColetVelDoRegimeEspecialC11DeQ04DoAnexoG,
		board009PrejuZosFiscaisDedutVeisRedTaxa,
		board009BenefCiosFiscaisRedTaxa,
		board0094MatRiaColectVel23RedTaxa,
		board0091PrejuZoFiscalComIsenO,
		board0092LucroTributVelComIsenO,
		board009PrejuZosFiscaisDedutVeisComIsenO,
		board009ZfmMatColetVelQueExcedeArtS36N3E36AN4Do,
		board0132LucroTributVelRegimesSimplificadoEmVigorAt2010,
		board0133DeduEsPrejFiscaisDeduzidosRegSimpEmVigorAt2010,
		board013BenefCiosFiscaisRegSimpEmVigorAt2010,
		board013TotalDoValorUtilizadoNoPerOdo397A397B,
		board0134MatRiaColectVel23RegSimpEmVigorAt2010,
		board010BenefCiosFiscaisComIsenO,
		board0103DeduEsPrejFiscaisDeduzidosComIsenO,
		board0104MatRiaColectVel23ComIsenO,
		board010MatRiaColectVelTotal311399322336Ou409Ou,
		board010SomaAlgBricaDosResultFinaisRegEspGrupSoc,
		board010LucrosDistribuDosExArt70N2RegEspGrupSoc,
		board010ResultadoFiscalDoGrupo,
		board010TotalDeRendimentosDoPerOdo,
		board010VolumeDeNegCiosDoPerOdoARepartirNoQuadro11BSeForCaso,
		board010ResultadosInternosEliminadosAoAbrigoDoAnteriorRtlcAIncluirNo,
		board028ColetividadesDesportivasDeduODasImportNcias,
		board028GastosDeFinanciamentoLQuidosOpOPrevistaNoArt67N5,
		board028ModalidadeDePagamentoDoImpostoCorrespondenteArt83N2,
		board028IrcDerramaEstadualValorDoPagamentoDiferidoOuFracionado,
		board028TotalDePagamentosDiferidosOuFracionados377A377B,
		board028DerramaMunicipalValorDoPagamentoDiferidoOuFracionado,
		board028TotalAPagar3673770,
		board028TotalARecuperar367Ou3683770,
		board015PrejFiscaisAutorizadosTransmitidosArt75NS1E3RegGeral,
		board015PrejuZosFiscaisNODedutVeisRegGeral,
		board015PrejuZosFiscaisNODedutVeisRegRedTx,
		board015PrejFiscaisAutorizadosTransmitidosArt75NS1E3RegRedTx,
		board015PrejFiscaisAutorizadosTransmitidosArt75NS1E3ComIsenO,
		board015PrejFiscaisAutorizadosTransmitidosArt75NS1E3RegSimp,
		board015PrejuZosFiscaisNODedutVeisRegSimpEmVigorAt2010,
		board015PrejuZosFiscaisNODedutVeisComIsenO,
		board011DiferenAPositivaEntreOValorConsideradoParaEfeitosDeLiquidaO,
		board011JurosCompensatRiosDeclaradosPorAtrasoNaEntregaDaDeclaraO,
		board011JurosCompensatRiosPorOutrosMotivos,
		board022TratandoSeDeMicroentidadeIndiqueSeOptaPelaAplNormasContabE,
		board022OcorreuNoPerOdoDaTributaOUmaOperaODeFusOComEficCia,
		board014AidDePerdasPorParidadeEmCrDitos,
		board014AidDeBenefCiosPSEmpregoOuALongoPrazoDeEmpregados,
		board014OutrosAid,
		board014CapitalPrPrio,
		board014CrDitoTributRio,
		board014DataDeEntradaEmLiquidaO,
		board022VolumeGlobalDeNegCiosNOIsento,
		board022VolumeDeNegCiosNOIsentoRam,
		board022VolumeDeNegCiosNOIsentoRaa,
		board022RCio1RamCampo2Campo1,
		board022RCio2RaaCampo3Campo1,
		board022RCio3Cont1RCio1RCio2,
		board030TotalDoCidtjiComCdtSaldoNODeduzido,
		board030TotalDoCidtjiComCdtCrDitoDoImpostoNoPerOdo,
		board030TotalDoCidtjiComCdtDeduOEfetuadaNoPerOdo,
		board030TotalDoCidtjiComCdtSaldoQueTransita,
		board030TotalDoCidtjiSemCdtSaldoNODeduzido,
		board030TotalDoCidtjiSemCdtCrDitoDoImpostoNoPerOdo,
		board030TotalDoCidtjiSemCdtDeduOEfetuadaNoPerOdo,
		board030TotalDoCidtjiSemCdtSaldoQueTransita,
		board030TotalDoCidtjiSaldoNODeduzido,
		board030TotalDoCidtjiCrDitoDoImpostoNoPerOdo,
		board030TotalDoCidtjiDeduOEfetuadaNoPerOdo,
		board030TotalDoCidtjiSaldoQueTransita,
	} = json;
	return [
		[
			{ label: "Declaration Type", value: declarationType, type: "string" },
			{
				label: "Código do serviço de finanças",
				value: board003CDigoDoServiODeFinanAs,
				type: "string"
			},
			{ label: "Nif", value: nif, type: "number" },
			{ label: "Record Type", value: recordType, type: "number" },
			{ label: "Year", value: exercise, type: "string" },
			{ label: "Currency Indicator", value: currencyIndicator, type: "string" },
			{ label: "Period From", value: periodFrom, type: "string" },
			{ label: "Period To", value: periodTo, type: "string" }
		],
		{
			title: "Q03 - Identificação e Caracterização do Sujeito Passivo",
			rows: [
				[
					{
						label: "Q03-3 - Tipo de sujeito passivo",
						value: board003TipoDeSujeitoPassivo || "N/A",
						type: "string"
					}
				],
				[
					{
						label:
							"Q03-3A - Qualificação Da Empresa Nos Termos Do Anexo Ao Decreto-lei N.º 372/2007, De 6 De Novembro",
						value: board003QualificaODaEmpresaNosTermos || "N/A",
						type: "string"
					}
				],
				[
					{
						label: "Q03-3B | Organismos De Investimento Coletivo",
						checkboxLabel: "Organismos De Investimento Coletivo",
						value: checkNA(
							board003IndiqueQueSeTrataDeUmOrgDeInvColTributadoNosTermosDoArt22DoEbf
						),
						type: "checkbox"
					}
				],
				[
					{
						label: "Q03-3C | Imputação De Rendimentos (Art.º 5.º, N.º 9)",
						checkboxLabel: "Imputação De Rendimentos (Art.º 5.º, N.º 9)",
						value: checkNA(
							board003ConsideradoUmEstabelecimentoEstVelParaEfeitosDeImputaO
						),
						type: "checkbox"
					}
				],
				[
					{
						label: "Q03-4-1 - Regimes De Tributação Dos Rendimentos",
						checkboxLabel: "Q03-4-1 - Geral",
						value: checkNA(board003Geral),
						type: "checkbox"
					},
					{
						checkboxLabel: "Q03-4-3 - Isenção definitiva",
						value: checkNA(board003IsencaoDefinitiva),
						type: "checkbox"
					},
					{
						checkboxLabel: "Q03-4-4 - Isenção temporária",
						value: checkNA(board003IsencaoTemporaria),
						type: "checkbox"
					},
					{
						checkboxLabel: "Q03-4-5 - Redução de taxa",
						value: checkNA(board003ReducaoTaxa),
						type: "checkbox"
					},
					{
						checkboxLabel: "Q03-4-6 - Simplificado",
						value: checkNA(board003Simplificado),
						type: "checkbox"
					},
					{
						checkboxLabel: "Q03-4-7 - Transparência fiscal",
						value: checkNA(board003TransparenciaFiscal),
						type: "checkbox"
					},
					{
						label: "",
						checkboxLabel: "Q03-4-8 - Grupos de sociedades",
						value: checkNA(board003GruposDeSociedades),
						type: "checkbox"
					},
					{
						checkboxLabel:
							"Q03-4-10 - Pretende exercer a opção pelas taxas do art.º 87.º, n.º 1? (art.º  91.º, n.º 2 da Lei n.º 3 - B/2010, de 28 de abril)",
						value: checkNA(board003PretendeExercerAOpOPelasTaxasDoArt87N1),
						type: "checkbox"
					},
					{
						checkboxLabel:
							"Q03-4-11 - Ocorreu alguma das situações referidas no ex-art.º 87.º, n.º 7?",
						value: checkNA(board003OcorreuAlgumaDasSituaEsReferidasNoArt87N7),
						type: "checkbox"
					},
					{
						checkboxLabel: "Q03-4-12 - Artigo 36.º-Ado EBF",
						value: checkNA(board003Artigo36ADoEbf),
						type: "checkbox"
					},
					{
						checkboxLabel:
							"Q03-4-13 - Regime especial das atividades de transporte marítimo (Dec.-lei n.º 92/2018, de 13 de novembro)",
						value: checkNA(
							board003RegimeEspecialAtividadesDeTransporteMarTimoDecLeiN
						),
						type: "checkbox"
					},
					{
						label:
							"Q03-4-9 - NIF da sociedade dominante / Responsável(art.º 69.º-A, n.ºs 3 e 4)",
						value:
							board003NifDaSociedadeDominanteResponsVelArt69ANS3E4 || "N/A",
						type: "string"
					}
				],
				[
					{
						label:
							"Q03-4A - Transferência de residência/cessação da atividade de estabelecimento estável/afetação de elementos patrimoniais (Art.ºs 83.º, 84.º E 54.º-a, N.º 11)",
						value: board003TransfDeResidCessaOActivArtS8384E54AN11 || "N/A",
						type: "string"
					}
				]
			]
		},
		{
			title: "Q04 - Características da Declaração",
			rows: [
				[
					{
						label: "Q04-1 - Tipo De Declaração",
						value: board003TipoDeDeclaraO || "N/A",
						type: "string"
					}
				],
				[
					{
						label: "Q04-2 - Declarações Especiais",
						checkboxLabel: "Declaração do grupo",
						value: checkNA(board003DeclaracaoDoGrupo),
						type: "checkbox"
					},
					{
						checkboxLabel: "Declaração do período de liquidação",
						value: checkNA(board003DeclaracaoDoPeriodoDeLiquidacao),
						type: "checkbox"
					},
					{
						checkboxLabel: "Declaração do periodo de cessação",
						value: checkNA(board003DeclaracaoDoPeriodoDeCessacao),
						type: "checkbox"
					},
					{
						checkboxLabel: "Declaração com período especial de tributação antes da alteração",
						value: checkNA(board003AntesDaAlteracao),
						type: "checkbox"
					},
					{
						checkboxLabel:
							"Declaração com período especial de tributação após a alteração",
						value: checkNA(board003AposAAlteracao),
						type: "checkbox"
					},
					{
						checkboxLabel: "Antes da dissolução",
						value: checkNA(board003AntesDissolucao),
						type: "checkbox"
					},
					{
						checkboxLabel: "Após a dissolução",
						value: checkNA(board003AposDissolucao),
						type: "checkbox"
					},
					{
						checkboxLabel: "Declaração do período do início de atividade",
						value: checkNA(board003DeclaracaoDoPeriodoDoInicioDeAtividade),
						type: "checkbox"
					},
					{
						label: "Data da cessação",
						value: board003DataDaCessaO || "N/A",
						type: "date"
					},
					{
						label: "Data da transmissão/aquisição (entidades não residentes sem estabelecimento estável)",
						value: board003DataDaTransmissOAquisiOEntidadesNOResidentesSemEstabEstVel,
						type: "string"
					},
					{
						label: "Data da dissolução",
						value: board003DataDaDissoluO || "N/A",
						type: "string"
					},
					{
						label: "Data da declaração de substituição (art.º 122.º n.º 3)",
						value: board003DataDeclaraODeSubstituiOArt122N3 || "N/A",
						type: "string"
					}
				],
				[
					{
						label: "3 | Anexos",
						checkboxLabel: "Anexo A | Derrama Municipal",
						value: checkNA(
							board003AnexoARegimeSimplificadoIndicadorDeAnexoAnteriorA2010
						),
						type: "checkbox"
					},
					{
						checkboxLabel: "Anexo B | Antigo regime simplificado em vigor até 2010",
						value: checkNA(
							board003AnexoBRegimeSimplificadoIndicadorDeAnexoAnteriorA2010
						),
						type: "checkbox"
					},
					{
						checkboxLabel: "Anexo C | Regiões Autónomas",
						value: checkNA(board003AnexoCRegiEsAutNomasIndicadorDeAnexo),
						type: "checkbox"
					},
					{
						checkboxLabel: "Anexo D | Benefícios Fiscais",
						value: checkNA(board003AnexoDBenefCiosFiscais),
						type: "checkbox"
					},
					{
						checkboxLabel: "Anexo E | Regime Simplificado",
						value: checkNA(
							board003AnexoERegimeSimplificadoIndicadorDeAnexoPosteriorA2013
						),
						type: "checkbox"
					},
					{
						checkboxLabel: "Anexo F | OIC",
						value: checkNA(board003AnexoFOic),
						type: "checkbox"
					},
					{
						checkboxLabel: "Anexo G | Transporte Marítimo",
						value: checkNA(board003AnexoGTransporteMarTimo),
						type: "checkbox"
					}
				]
			]
		},
		{
			title:
				"Q05 - Identificação do Representante Legal e do Contabilista Certificado",
			rows: [
				[
					{
						label: "NIF do Representante Legal",
						value: board003NifDoRepresentanteLegal || "N/A",
						type: "string"
					},
					{
						label: "NIF do contabilista certificado",
						value: board003NifDoContabilistaCertificado || "N/A",
						type: "string"
					},
				]
			]
		},
		{
			title: "Q08 - Regimes de taxa",
			rows: [
				[
					{
						label: "1 | Regimes de Redução de Taxa",
						checkboxLabel:
							"Estabelecimentos de ensino particular (ex-art.º 56.º do EBF)",
						value: checkNA(
							board008EstabelecimentosDeEnsinoParticularExArt56DoEbf
						),
						type: "checkbox"
					},
					{
						checkboxLabel:
							"Benefícios relativos à interioridade (art.º 41.º-B e ex-art.º 43.º do EBF)",
						value: checkNA(
							board008BenefCiosRelativosInterioridadeArt41BEExArt43DoEbf
						),
						type: "checkbox"
					},
					{
						checkboxLabel:
							"Antigo Estatuto Fiscal Cooperativo (art.º 7.º, n.º 3 da Lei n.º 85/98, de 16/12)",
						value: checkNA(
							board008AntigoEstatutoFiscalCooperativoArt7N3DaLei859816Dez
						),
						type: "checkbox"
					},
					{
						checkboxLabel:
							"Entidades licenciadas na Zona Franca da Madeira (ex-art.º 35.º do EBF)",
						value: checkNA(
							board008EntidadesLicenciadasNaZonaFrancaDaMadeiraExArt35DoEbf
						),
						type: "checkbox"
					},
					{
						checkboxLabel:
							"Entidades licenciadas na Zona Franca da Madeira (art.os 36.º e 36.º-A do EBF)",
						value: checkNA(
							board008EntidadesLicenciadasNaZonaFrancaDaMadeiraArt36E36ADo
						),
						type: "checkbox"
					}
				],
				[
					{
						label: "2 | Regime Geral",
						checkboxLabel:
							"Região Autónoma dos Açores (Dec. Leg. Regional n.º 2/1999/A, de 20/1)",
						value: checkNA(board008RegiOAutNomaAOresDecLegRegionalN21999A201),
						type: "checkbox"
					},
					{
						checkboxLabel:
							"Região Autónoma da Madeira (Dec. Leg. Regional n.º 2/2001/M, de 20/2)",
						value: checkNA(
							board008RegiOAutNomaMadeiraDecLegRegionalN22001M202Nova
						),
						type: "checkbox"
					},
					{
						checkboxLabel:
							"Rendimentos prediais de entidades não residentes sem estabelecimento estável (art.º 87.º, n.º 4)",
						value: checkNA(board008RendimentosPrediaisDeEntidadesArt87N4),
						type: "checkbox"
					},
					{
						checkboxLabel:
							"Mais-valias imobiliárias / incrementos patrimoniais obtidos por entidades não residentes sem estabelecimento estável (art.º87.º, n.º 4)",
						value: checkNA(
							board008MaisValiasImobiliRiasIncrementosPatrimoniaisObtidosPorEntidadesNOResid
						),
						type: "checkbox"
					},
					{
						checkboxLabel:
							"Mais-valias mobiliárias obtidas por entidades não residentes sem estabelecimento estável (art.º 87.º, n.º 4) ",
						value: checkNA(
							board008MaisValiasMobiliRiasObtidasPorEntidadesNOResidentesSemEstab
						),
						type: "checkbox"
					},
					{
						checkboxLabel:
							"Rendimentos decorrentes da alienação de unidades de participação em FII e de participações sociais em SII, auferidos porentidades não residentes sem estabelecimento estável (art.º 22.º-A, n.º 1, al. c) do EBF)) ",
						value: checkNA(
							board008RendDecorrentesDaAlienDeUnidadesArt22AN1AlCDo
						),
						type: "checkbox"
					},
					{
						checkboxLabel:
							"Outros rendimentos obtidos por entidades não residentes sem estabelecimento estável",
						value: checkNA(
							board008OutrosRendimentosObtidosPorEntidadesNOResidentesSem
						),
						type: "checkbox"
					}
				]
			]
		},
		{
			title: "Q09 - Apuramento Da Matéria Coletável",
			rows: [
				[
					{
						label: "Prejuízo Fiscal - Regime geral",
						value: checkNumberValues(board0081PrejuZoFiscalRegimeGeral),
						type: "number"
					},
					{
						label: "Prejuízo Fiscal - Com redução de taxa",
						value: checkNumberValues(board0081PrejuZoFiscalReduOTaxa),
						type: "number"
					},
					{
						label: "Prejuízo Fiscal - Com isenção",
						value: checkNumberValues(board0091PrejuZoFiscalComIsenO),
						type: "number"
					}
				],
				[
					{
						label: "Lucro Tributável - Regime geral",
						value: checkNumberValues(board0082LucroTributVelRegimeGeral ),
						type: "number"
					},
					{
						label: "Lucro Tributável - Com redução de taxa",
						value: checkNumberValues(board0082LucroTributVelReduOTaxa),
						type: "number"
					},
					{
						label: "Lucro Tributável - Com isenção",
						value: checkNumberValues(board0092LucroTributVelComIsenO),
						type: "number"
					},
					{
						label: "Lucro Tributável - Regime simplificado (em vigor até 2010)",
						value:
							checkNumberValues(board0132LucroTributVelRegimesSimplificadoEmVigorAt2010),
						type: "number"
					}
				],
				[
					{
						label:
							"Regime especial dos grupos de sociedades - Soma algébrica dos resultados fiscais",
						value: checkNumberValues(board010SomaAlgBricaDosResultFinaisRegEspGrupSoc),
						type: "number"
					},
					{
						label:
							"Regime especial dos grupos de sociedades - Lucros distribuídos(ex-art.º 70.º, n.º 2)",
						value: checkNumberValues(board010LucrosDistribuDosExArt70N2RegEspGrupSoc),
						type: "number"
					},
					{
						label:
							"Regime especial dos grupos de sociedades - Gastos de financiamento líquidos(opção prevista no art.º 67.º, n.º 5)",
						value:
							checkNumberValues(board028GastosDeFinanciamentoLQuidosOpOPrevistaNoArt67N5),
						type: "number"
					},
					{
						label:
							"Regime especial dos grupos de sociedades - Ajustamento REAID (art.º 5.º, n.º 1 al. b) do Anexo à Lei n.º 61/2014, de 26 agosto)",
						value: checkNumberValues(board008AjustamentoReaidArt5N1BAnexoLeiN6120142608),
						type: "number"
					},
					{
						label:
							"Regime especial dos grupos de sociedades - Resultados internos eliminados ao abrigo do anterior RTLC, a incluir no lucro tributável do período",
						value:
							checkNumberValues(board010ResultadosInternosEliminadosAoAbrigoDoAnteriorRtlcAIncluirNo),
						type: "number"
					},
					{
						label:
							"Regime especial dos grupos de sociedades - Resultado fiscal do grupo",
						value: checkNumberValues(board010ResultadoFiscalDoGrupo),
						type: "number"
					}
				],
				[
					{
						label: "Prejuízos fiscais dedutíveis - Regime geral",
						value: checkNumberValues(board008PrejuZosFiscaisDedutVeisRegimeGeral),
						type: "number"
					},
					{
						label: "Prejuízos fiscais dedutíveis - Com redução de taxa",
						value: checkNumberValues(board009PrejuZosFiscaisDedutVeisRedTaxa),
						type: "number"
					},
					{
						label: "Prejuízos fiscais dedutíveis - Com isenção",
						value: checkNumberValues(board009PrejuZosFiscaisDedutVeisComIsenO),
						type: "number"
					},
					{
						label:
							"Prejuízos fiscais dedutíveis - Regime simplificado (em vigor até 2010)",
						value:
							checkNumberValues(board0133DeduEsPrejFiscaisDeduzidosRegSimpEmVigorAt2010),
						type: "number"
					}
				],
				[
					{
						label:
							"Prejuízos fiscais autorizados/transmitidos (art.º 75.º, n.ºs 1 e 3) - Regime geral",
						value:
							checkNumberValues(board015PrejFiscaisAutorizadosTransmitidosArt75NS1E3RegGeral),
						type: "number"
					},
					{
						label:
							"Prejuízos fiscais autorizados/transmitidos (art.º 75.º, n.ºs 1 e 3) - Com redução de taxa",
						value:
							checkNumberValues(board015PrejFiscaisAutorizadosTransmitidosArt75NS1E3RegRedTx),
						type: "number"
					},
					{
						label:
							"Prejuízos fiscais autorizados/transmitidos (art.º 75.º, n.ºs 1 e 3) - Com isenção",
						value:
							checkNumberValues(board015PrejFiscaisAutorizadosTransmitidosArt75NS1E3ComIsenO),
						type: "number"
					},
					{
						label:
							"Prejuízos fiscais autorizados/transmitidos (art.º 75.º, n.ºs 1 e 3) - Regime simplificado (em vigor até 2010)",
						value:
							checkNumberValues(board015PrejFiscaisAutorizadosTransmitidosArt75NS1E3RegSimp),
						type: "number"
					}
				],
				[
					{
						label:
							"Alteração da titularidade de mais de 50% do capital social ou da maioria dos direitos de voto - Prejuízos fiscais não dedutíveis (art.º 52.º, n.º 8) - Regime geral",
						value: checkNumberValues(board015PrejuZosFiscaisNODedutVeisRegGeral),
						type: "number"
					},
					{
						label:
							"Alteração da titularidade de mais de 50% do capital social ou da maioria dos direitos de voto - Prejuízos fiscais não dedutíveis (art.º 52.º, n.º 8) - Com redução de taxa",
						value: checkNumberValues(board015PrejuZosFiscaisNODedutVeisRegRedTx),
						type: "number"
					},
					{
						label:
							"Alteração da titularidade de mais de 50% do capital social ou da maioria dos direitos de voto - Prejuízos fiscais não dedutíveis (art.º 52.º, n.º 8) - Com isenção",
						value: checkNumberValues(board015PrejuZosFiscaisNODedutVeisComIsenO),
						type: "number"
					},
					{
						label:
							"Alteração da titularidade de mais de 50% do capital social ou da maioria dos direitos de voto - Prejuízos fiscais não dedutíveis (art.º 52.º, n.º 8) - Regime simplificado (em vigor até 2010)",
						value:
							checkNumberValues(board015PrejuZosFiscaisNODedutVeisRegSimpEmVigorAt2010),
						type: "number"
					}
				],
				[
					{
						label: "DEDUÇÕES: Prejuízos fiscais deduzidos - Regime geral",
						value: checkNumberValues(board0081PrejuZoFiscalRegimeGeral),
						type: "number"
					},
					{
						label:
							"DEDUÇÕES: Prejuízos fiscais deduzidos - Com redução de taxa",
						value: checkNumberValues(board0081PrejuZoFiscalReduOTaxa),
						type: "number"
					},
					{
						label: "DEDUÇÕES: Prejuízos fiscais deduzidos - Com isenção",
						value: checkNumberValues(board0103DeduEsPrejFiscaisDeduzidosComIsenO),
						type: "number"
					},
					{
						label:
							"DEDUÇÕES: Prejuízos fiscais deduzidos - Regime simplificado (em vigor até 2010)",
						value:
							checkNumberValues(board0133DeduEsPrejFiscaisDeduzidosRegSimpEmVigorAt2010),
						type: "number"
					}
				],
				[
					{
						label: "Benefícios fiscais - Regime geral",
						value: checkNumberValues(board008BenefCiosFiscaisRegimeGeral),
						type: "number"
					},
					{
						label: "Benefícios fiscais - Com redução de taxa",
						value: checkNumberValues(board009BenefCiosFiscaisRedTaxa),
						type: "number"
					},
					{
						label: "Benefícios fiscais - Com isenção",
						value: checkNumberValues(board010BenefCiosFiscaisComIsenO),
						type: "number"
					},
					{
						label:
							"Benefícios fiscais - Regime simplificado (em vigor até 2010)",
						value: checkNumberValues(board013BenefCiosFiscaisRegSimpEmVigorAt2010),
						type: "number"
					}
				],
				[
					{
						label: "Matéria Coletável - Regime geral",
						value: checkNumberValues(board0084MatRiaColectVel23RegimeGeral),
						type: "number"
					},
					{
						label: "Matéria Coletável - Com redução de taxa",
						value: checkNumberValues(board0094MatRiaColectVel23RedTaxa),
						type: "number"
					},
					{
						label: "Matéria Coletável - Com isenção",
						value: checkNumberValues(board0104MatRiaColectVel23ComIsenO),
						type: "number"
					},
					{
						label:
							"Matéria Coletável - Regime simplificado (em vigor até 2010)",
						value: checkNumberValues(board0134MatRiaColectVel23RegSimpEmVigorAt2010),
						type: "number"
					},
					{
						label:
							"ZFM - Matéria coletável que excede os plafondsmáximos (art.ºs 36.º, n.º 3 e 36.º-A, n.º 4 do EBF) - Regime Geral",
						value: checkNumberValues(board009ZfmMatColetVelQueExcedeArtS36N3E36AN4Do),
						type: "number"
					}
				],
				[
					{
						label:
							"COLETIVIDADES DESPORTIVAS - Dedução das importâncias investidas até 50% da matéria coletável (art.º 54.º, n.º 2 do EBF) - Regime Geral",
						value: checkNumberValues(board028ColetividadesDesportivasDeduODasImportNcias),
						type: "number"
					},
					{
						label:
							"Total do valor utilizado no período (397-A + 397-B) - Regime Geral",
						value: checkNumberValues(board013TotalDoValorUtilizadoNoPerOdo397A397B),
						type: "number"
					}
				],
				[
					{
						label:
							"Matéria Coletável do regime especial (campo 11 do quadro 04 do anexo G)",
						value:
							checkNumberValues(board008MatRiaColetVelDoRegimeEspecialC11DeQ04DoAnexoG),
						type: "number"
					}
				],
				[
					{
						label:
							"MATÉRIA COLETÁVEL NÃO ISENTA, [(311 - 399) + 322 + 336] ou 409 ou campo 42 do anexo E, exceto o campo 300",
						value: checkNumberValues(board010MatRiaColectVelTotal311399322336Ou409Ou),
						type: "number"
					}
				]
			]
		},
		{
			title:
				"Q10 - Cálculo do imposto",
			rows: [
				[
					{
						label: "A - Juros compensatórios | Juros compensatórios declarados por atraso na entrega da declaração",
						value: checkNumberValues(board011JurosCompensatRiosDeclaradosPorAtrasoNaEntregaDaDeclaraO),
						type: "number"
					},
					{
						label: "Juros compensatórios declarados por outros motivos",
						value: checkNumberValues(board011JurosCompensatRiosPorOutrosMotivos),
						type: "number"
					}
				],
				[
					{
						label: "B - Modalidade de pagamento do imposto correspondente (art.o 83.o, n.o 2):",
						value: checkNumberValues(board028ModalidadeDePagamentoDoImpostoCorrespondenteArt83N2),
						type: "number"
					}
				], [
					{
						label: "Valor do pagamento diferido ou fracionado | IRC + Derrama estadual",
						value: checkNumberValues(board028IrcDerramaEstadualValorDoPagamentoDiferidoOuFracionado),
						type: "number"
					},
					{
						label: "Derrama municipal",
						value: checkNumberValues(board028DerramaMunicipalValorDoPagamentoDiferidoOuFracionado),
						type: "number"
					},
					{
						label: "Total dos pagamentos diferidos ou fracionados (377-A + 377-B)",
						value: checkNumberValues(board028TotalDePagamentosDiferidosOuFracionados377A377B),
						type: "number"
					}
				],
				[
					{
						label: "Total A Pagar (367 - 377) > 0",
						value: checkNumberValues(board028TotalAPagar3673770),
						type: "number"
					},
					{
						label: "Total A Recuperar [367 Ou (- 368) - 377] < 0",
						value: checkNumberValues(board028TotalARecuperar367Ou3683770),
						type: "number"
					}
				]
			]
		},
		{
			title: "Q11 - Outras Informações",
			rows: [
				[
					{
						label: "Total de rendimentos do período",
						value: checkNumberValues(board010TotalDeRendimentosDoPerOdo),
						type: "number"
					},
					{
						label:
							"Volume de negócios do período (a repartir no quadro 11-B, se for caso disso)",
						value:
						checkNumberValues(board010VolumeDeNegCiosDoPerOdoARepartirNoQuadro11BSeForCaso),
						type: "number"
					},
					{
						label:
							"Diferença positiva entre o valor considerado para efeitos de liquidação do IMT e o valor constante do contrato, nos casos em que houve recurso ao procedimento previsto no art.º 139.º",
						value: checkNumberValues(board011DiferenAPositivaEntreOValorConsideradoParaEfeitosDeLiquidaO),
						type: "number"
					},
					{
						label:
							"Tratando-se de microentidade, indique se, em alternativa às normas contabilísticas para microentidades (NC-ME), pequenas entidades (NCRF-PE) ou das normas contabilísticas e de relato financeiro (NCRF)",
						checkboxLabel: "Sim",
						value: checkNA(
							board022TratandoSeDeMicroentidadeIndiqueSeOptaPelaAplNormasContabE
						),
						type: "checkbox"
					},
					{
						label:
							"Ocorreu no período de tributação uma operação de fusão com efi cácia retroativa (n.º 11 do art.º 8.º) da qual é socie-dade benefi ciária?",
						checkboxLabel: "Sim",
						value: checkNA(
							board022OcorreuNoPerOdoDaTributaOUmaOperaODeFusOComEficCia
						),
						type: "checkbox"
					}
				]
			]
		},
		{
			title: "Q11A - Outras Informações",
			rows: [
				[
					{
						label:
							"Discriminação dos AID inscritos nas demonstrações financeiras a que respeita a Mod.22: AID de perdas por imparidade em créditos abrangidos  pelo REAID",
						value: checkNumberValues(board014AidDePerdasPorParidadeEmCrDitos),
						type: "number"
					},
					{
						label:
							"Discriminação dos AID inscritos nas demonstrações financeiras a que respeita a Mod.22: AID de benefícios pós-emprego ou a longo prazo de empregados abrangidos pelo REAID",
						value: checkNumberValues(board014AidDeBenefCiosPSEmpregoOuALongoPrazoDeEmpregados),
						type: "number"
					},
					{
						label:
							"Discriminação dos AID inscritos nas demonstrações financeiras a que respeita a Mod.22: Outros AID",
						value: checkNumberValues(board014OutrosAid),
						type: "number"
					},
					{
						label: "Informação adicional: Capital próprio",
						value: checkNumberValues(board014CapitalPrPrio),
						type: "number"
					},
					{
						label: "Informação adicional: Crédito Tributário",
						value: checkNumberValues(board014CrDitoTributRio),
						type: "number"
					},
					{
						label: "Informação adicional: Data da entrada em liquidação",
						value: board014DataDeEntradaEmLiquidaO,
						type: "string"
					}
				]
			]
		},
		{
			title:
				"Q11B - Repartição do volume anual de negócios do Período pelas circunscrições (Continente, Açores e Madeira)",
			rows: [
				[
					{
						label:
							"A empresa possui sucursais, delegações, agências, escritórios, instalações ou quaisquer formas de representação permanente sem personalidade jurídica própria em mais do que uma circunscrição?",
						value: checkNumberValues(board014AidDePerdasPorParidadeEmCrDitos),
						type: "number"
					},
					{
						label: "Volume global de negócios não isento",
						value: checkNumberValues(board022VolumeGlobalDeNegCiosNOIsento),
						type: "number"
					},
					{
						label:
							"Volume de negócios, não isento, imputável às instalações situadas na Região Autónoma da Madeira (RAM)",
						value: checkNumberValues(board022VolumeDeNegCiosNOIsentoRam),
						type: "number"
					},
					{
						label:
							"Volume de negócios, não isento, imputável às instalações situadas na Região Autónoma dos Açores (RAA)",
						value: checkNumberValues(board022VolumeDeNegCiosNOIsentoRaa),
						type: "number"
					},
					{
						label: "Rácio 1 (RAM) = (campo 2  :  campo 1)",
						value: checkNumberValues(board022RCio1RamCampo2Campo1),
						type: "number"
					},
					{
						label: "Rácio 2 (RAA) = (campo 3  :  campo 1)",
						value: checkNumberValues(board022RCio2RaaCampo3Campo1),
						type: "number"
					},
					{
						label: "Rácio 3 (CONTINENTE) =  1 - (rácio 1 + rácio 2)",
						value: checkNumberValues(board022RCio3Cont1RCio1RCio2),
						type: "number"
					}
				]
			]
		},
		{
			title:
				"Q14 - Crédito de Imposto por Dupla Tributação Jurídica Internacional (CIDTJI)",
			rows: [
				[
					{
						label: "Total do CIDTJI com CDT: Saldo não deduzido",
						value: checkNumberValues(board030TotalDoCidtjiComCdtSaldoNODeduzido),
						type: "number"
					},
					{
						label: "Total do CIDTJI com CDT: Crédito de imposto do período",
						value: checkNumberValues(board030TotalDoCidtjiComCdtCrDitoDoImpostoNoPerOdo),
						type: "number"
					},
					{
						label: "Total do CIDTJI com CDT: Dedução efetuada no período",
						value: checkNumberValues(board030TotalDoCidtjiComCdtDeduOEfetuadaNoPerOdo),
						type: "number"
					},
					{
						label: "Total do CIDTJI com CDT: Saldo que transita",
						value: checkNumberValues(board030TotalDoCidtjiComCdtSaldoQueTransita),
						type: "number"
					}
				],
				[
					{
						label: "Total do CIDTJI sem CDT: Saldo não deduzido",
						value: checkNumberValues(board030TotalDoCidtjiSemCdtSaldoNODeduzido),
						type: "number"
					},
					{
						label: "Total do CIDTJI sem CDT: Crédito de imposto do período",
						value: checkNumberValues(board030TotalDoCidtjiSemCdtCrDitoDoImpostoNoPerOdo),
						type: "number"
					},
					{
						label: "Total do CIDTJI sem CDT: Dedução efetuada no período",
						value: checkNumberValues(board030TotalDoCidtjiSemCdtDeduOEfetuadaNoPerOdo),
						type: "number"
					},
					{
						label: "Total do CIDTJI sem CDT: Saldo que transita",
						value: checkNumberValues(board030TotalDoCidtjiSemCdtSaldoQueTransita),
						type: "number"
					}
				],
				[
					{
						label: "Total do CIDTJI: Saldo não deduzido",
						value: checkNumberValues(board030TotalDoCidtjiSaldoNODeduzido),
						type: "number"
					},
					{
						label: "Total do CIDTJI: Crédito de imposto do período",
						value: checkNumberValues(board030TotalDoCidtjiCrDitoDoImpostoNoPerOdo),
						type: "number"
					},
					{
						label: "Total do CIDTJI: Dedução efetuada no período",
						value: checkNumberValues(board030TotalDoCidtjiDeduOEfetuadaNoPerOdo),
						type: "number"
					},
					{
						label: "Total do CIDTJI: Saldo que transita",
						value: checkNumberValues(board030TotalDoCidtjiSaldoQueTransita),
						type: "number"
					}
				]
			]
		}
	];
};

const mapper = (json, card, details) => {
	return {
		...json,
		name: json.declarationType,
		shortName: `${json.periodFrom} a ${json.periodTo}`,
		details: details ? mapDetails(json) : []
	};
};

const checkNA = string => {
	if (string !== "N/A" && parseInt(string, 10) > 0) {
		return 1;
	}
	return 0;
};

const coverpagemod22Mapper = {
	map: mapper
};

export default coverpagemod22Mapper;
