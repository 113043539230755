import {
	List,
	rows,
	Filters,
} from "colbi_web_ui/lib/components";
import theme from 'colbi_web_ui/lib/style/_theme.json';
import { parseFilter } from "colbi_web_ui/lib/utils";
import { StackLayout } from '../layouts'
import ExportButton from "../components/ExportButton/ExportButton";
import AppByFile from "../controllers/AppByFile/AppByFile";

const queries = (args = {}) => {
	const entityResource = args.modulePrefix ?  `${args.modulePrefix}_${args.entity}` : args.entity
	const result = [
		{
			resource: args.modulePrefix ?  `${args.modulePrefix}_${args.entity}` : args.entity,
			args: {
				projectId: args.projectId,
                fiscalYear: args.fiscalYear,
                modulePrefix: args.modulePrefix,
				filter: args.filter
					? { type: "[FilterInput!]", value: parseFilter(args.filter) }
					: undefined,
				sort: args.sort
					? { type: "[SortInput!]", value: parseFilter(args.sort) }
					: undefined,
				page: parseInt(args.page, 10) || 0,
			},
			body: `__typename, items{ ...${entityResource} }, hasMore, total, totalizers,filters(projectId:$projectId,fiscalYear:$fiscalYear,modulePrefix:$modulePrefix){ prop, name, type, list }`,
			downloadable: true,
		}
	];

	if (args.entity === "sales_invoices" || args.entity === "purchase_invoices") {
			result.push(
				{
					resource: `${entityResource}_with_lines_export`,
					args: {
						projectId: args.projectId,
						fiscalYear: args.fiscalYear,
						modulePrefix: args.modulePrefix,
						filter: args.filter
							? { type: "[FilterInput!]", value: parseFilter(args.filter) }
							: undefined,
					},
					body: `items{...${entityResource}_with_lines_export}`,
					downloadable: true,
					downloadOnly: true,
					xlsxTranslation: `${entityResource}_with_lines_export`,
				}
			);
		}
	
		return result;

};

const isafTransactions = (path, navigation, exact, title) => ({
	path,
	navigation,
	component: AppByFile,
	queries,
	exact,
	title: ['label( Isaf Transactional Data)', 'label(param(entity))'],
	components: [
		{
			component: Filters,
			props: {
				filter: 'param(filter)',
				availableFilters: 'query(0).filters',
				stickyTop: parseInt(theme.layout.$headerHeight, 10),
				anchor: 'results'
			}
		},
		{
			component: ExportButton,
			props: {
				data: "query(0).list",
			},
		}
	],
	routes: [
		{
			path,
			exact: true,
			queries,
			component: StackLayout,
			components: [
				{
					component: List,
					modifiers: [
						'--row-style-alternate'
					],
					props: {
						basePath: path,
						items: 'query(0).list',
						sort: 'param(sort)',
						cardKey: 'param(entity)',
						card: rows.EntityCard,
						header: 'query(0).header',
						metadata: 'query(0).metadata',
						page: 'param(page)',
						status: 'status(0)',
						pageSize: 'param(pageSize)',
						footer: 'query(0).footer',
						totalizers: 'query(0).totalizers'
					}
				}
			]
		}
	]
});

export default isafTransactions;
