import { format, date } from "../../../utils"

const mapDetails = (json) => {
	const {
		taxDate,

		settlementAmount,

		customer,
		shipTo,
		product,
		lineNumber,

		invoiceNo,

		quantity,

		unitPrice,
		creditAmount,
		debitAmount,
		debitCreditIndicator,
		unit,
		accountId,
		amountCurrencyAmount,
		amountCurrencyCode
	} = json

	return [
		[
			{ label: 'Invoice No.', value: invoiceNo, type: 'string' },

			{ label: 'Line Number', value: lineNumber, type: 'number' },
			{ label: 'Account Id', value: accountId, type: 'string' },

			{
				label: 'Quantity',
				value: quantity,
				type: 'number'
			},

			{
				label: 'Settlement Amount',
				value: format(settlementAmount, 'fullValue'),
				type: 'currency'
			},

		],
		[
			{
				label: 'Unit Price',
				value: format(unitPrice, 'fullValue'),
				type: 'currency'
			},
			{
				label: 'Debit Amount',
				value: format(debitAmount, 'fullValue'),
				type: 'currency'
			},
			{
				label: 'Credit Amount',
				value: format(creditAmount, 'fullValue'),
				type: 'currency'
			}
		],
		[
			{ label: 'Tax Point Date', value: date(taxDate), type: 'date' },
			{ label: 'Debit Credit Indicator', value: debitCreditIndicator, type: 'string' },
			{ label: 'Currency', value: amountCurrencyCode, type: 'string' },
			{ label: 'Currency Amount', value: format(amountCurrencyAmount, 'fullValue'), type: 'currency' },
		],
		[
			customer ? {
				label: 'Customer',
				value: {
					id: customer.id,
					name: customer.name,
					__typename: 'Customer'
				},
				type: 'object'
			} : null,
			product ? {
				label: 'Product ID',
				value: {
					id: product.id,
					name: product.name,
					__typename: 'Product'
				},
				type: 'object'
			} : null,
			{
				label: 'Unit of Measure',
				value: unit || 'N/A',
				type: 'string'
			}
		],
		shipTo ? {
			title: 'Ship To',
			rows: [
				[
					{
						label: 'Postal Code',
						value: shipTo.postal_code || 'N/A',
						type: 'string'
					},
					{
						label: 'City',
						value: shipTo.city || 'N/A',
						type: 'string'
					},
					{
						label: 'Region',
						value: shipTo.region || 'N/A',
						type: 'string'
					},
					{
						label: 'Country',
						value: shipTo.country || 'N/A',
						type: 'string'
					}
				]
			]
		} : null,

	]

}

const mapCard = (json) => {
	const {
		lineNumber,
		description,
		productCode,
		debitAmount,
		quantity,
		creditAmount,
		accountId,
		unitPrice,
		debitCreditIndicator
	} = json
	return [
		[
			{ labels: ['number'], value: lineNumber, type: 'number', align: 'left', basis: '15%'}
		],
		[
			{ labels: ['code'], value: productCode || 'N/A', type: 'string', }
		],
		[
			{ labels: ['accountId'], value: accountId, type: 'string',}
		],
		[
			{ labels: ['description'], value: description, type: 'string',}
		],
		[
			{ labels: ['quantity'], value: format(quantity, null, null), type: 'number',}
		],
		[
			{ labels: ['unitPrice'], value: format(unitPrice), type: 'number',  }
		],
		[
			{ labels: ['debit_amount'], value: format(debitAmount),  type: 'currency',  }
		],
		[
			{ labels: ['credit_amount'], value: format(creditAmount), type: 'currency',  }
		],
		[
			{ labels: ['debit_credit_indicator'], value: debitCreditIndicator, align: 'center', type: 'string',  }
		],
	]
}

const mapper = (json, card, details) => {
	return {
		...json,
		name: '[' + json.lineNumber + '] ' + json.productCode,
		shortName: json.productCode,
		details: details ? mapDetails(json) : [],
		card: card ? mapCard(json) : []
	}
}

const header = [
	{ label: ["Line Number"], align: 'left', basis: '15%' },
	{ label: ["CODE"], align: 'left',  },
	{ label: ["Account Id"], align: 'left', columnName: "accountId",},
	{ label: ["Description"], align: 'left', columnName: "description",},
	{ label: ["Quantity"], align: 'center', columnName: "quantity", sortable: true, },
	{ label: ["Unit Price"], align: 'center', columnName: "unit_price", sortable: true, },
	{ label: ["Debit"], align: 'right', columnName: "debit_amount", sortable: true, },
	{ label: ["Credit"], align: 'right', columnName: "credit_amount", sortable: true, },
	{ label: ["D/C Indicator"], align: 'center', columnName: "debit_credit_indicator", sortable: true, },
]

const invoicelineMapper = {
	map: mapper,
	header
}

export default invoicelineMapper;