import { format, date } from "../../../utils"

const mapDetails = (json) => {
	const {
		description,
		debit,
		credit,
		transactionId,
		transactionDate,
		transactionPeriod,
		customer,
		supplier,
		journalId,
		taxEntity,
		recordId,
		accountId,
		glPostingDate
	} = json
	return [
		[
			{ label: 'Transaction', value: transactionId, type: 'string' },
			{ label: 'Record Id', value: recordId, type: 'string' },
			{ label: 'Account Id', value: accountId, type: 'string' },
			{
				label: 'Period',
				value: transactionPeriod,
				type: 'number'
			},
			{ label: 'Posting Date', value: date(glPostingDate), type: 'date' },
			{ label: 'Transaction Date', value: date(transactionDate), type: 'date' },
			{ label: 'Description', value: description, type: 'string' }
		],

		[
			{ label: 'Debit Amount', value: format(debit, 'fullValue'), type: 'currency' },
			{ label: 'Credit Amount', value: format(credit, 'fullValue'), type: 'currency' }
		],
		[
			{ label: 'Journal', value: journalId, type: 'string' },
			{ label: 'Entity', value: taxEntity, type: 'string' },
			customer ? { label: 'Customer Name', value: { id: customer.id, name: customer.name, __typename: 'Customer' }, type: 'object' } : null,
			supplier ? { label: 'Supplier Name', value: { id: supplier.id, name: supplier.name, __typename: 'Supplier' }, type: 'object' } : null,
		]
	]
}

const mapCard = (json) => {
	const {
		recordId,
		accountId,
		description,
		debit,
		credit,
		customer,
		supplier,
	} = json

	return [
		[
			{ labels: ['Record Id'], value: recordId, type: 'string', basis:"20%" }
		],
		[
			{ labels: ['Account Id'], value: accountId, type: 'string' }
		],
		[
			{ labels: ['description'], value: description, type: 'string' }
		],
		[
			supplier ? { label: 'Supplier Name', value: { id: supplier.id, name: supplier.name, __typename: 'Supplier' }, type: 'object' } : null,
		],
		[
			customer ? { label: 'Customer Name', value: { id: customer.id, name: customer.name, __typename: 'Customer' }, type: 'object' } : null,
		],
		[
			{ labels: ['debit'], value: format(debit), type: 'currency', columnName: "debit_amount", sortable: true }
		],
		[
			{ labels: ['credit'], value: format(credit), align:'right', type: 'currency', columnName: "credit_amount", sortable: true }
		]
	]
}

const mapper = (json, card, details) => {
	return {
		...json,
		name: json.description,
		shortName: json.description,
		details: details ? mapDetails(json) : [],
		card: card ? mapCard(json) : []
	}
}

const header = [
	{ label: ["Record Id"], align: 'left', basis:"20%" },
	{ label: ["Account Id"], align: 'left' },
	{ label: ["Description"], align: 'left' },
	{ label: ["Supplier"], align: 'left' },
	{ label: ["Customer"], align: 'left' },
	{ label: ["Debit"], align: 'right', columnName: "debit_amount", sortable: true ,totalizerName: "total_debit"},
	{ label: ["Credit"], align: 'right', columnName: "credit_amount", sortable: true, totalizerName: "total_credit" }
]

const transactionlineMapper = {
	map: mapper,
	header
}
export default transactionlineMapper;