import { format } from '../../../utils'

const header = [
    { label: ["Analysis ID"], align: 'left', columnName: "analysis_id", sortable: true },
    { label: ["Analysis Type"], align: 'center', columnName: "analysis_type", sortable: true },
    { label: ["Currency"], align: 'center', columnName: "analysis_amount_currency_code", sortable: true },
    { label: ["Analysis Amount"], align: 'right', columnName: "analysis_amount", totalizerName: "total_analysis_amount", sortable: true, compact: "fullValue" },
    { label: ["Currency Amount"], align: 'right', columnName: "analysis_currency_amount", sortable: true }
]

const mapCard = (json) => {
	const {
        analysisId,
        analysisType,
        analysisAmountCurrencyCode,
        analysisAmount,
        analysisCurrencyAmount
	} = json

	return [
        [
            { labels: ['analysisId'], value: analysisId, type: 'string' }
        ],
        [
            { labels: ['analysisType'], value: analysisType, type: 'number' }
        ],
        [
            { labels: ['foreignCurrency'], value: analysisAmountCurrencyCode, type: 'number' }
        ],
        [
            { labels: ['analysisAmount'], value: format(analysisAmount, "fullValue"), type: 'currency' }
        ],
        [
            { labels: ['foreignCurrencyAmount'], value: format(analysisCurrencyAmount,'fullValue',null,2), type: 'currency' }
        ]
	]
}

const mapper = (json, card) => {
    const name = json.invoice_line_id || json.payment_line_id || json.purchase_invoice_line_id || json.transaction_line_id
    
    return {
        ...json,
        __typename: json.__typename,
        id: json.id,
		name,
		shortName: name,
		card: card ? mapCard(json) : []
	}
}
const analyisMapper = {
    map: mapper,
    header
}

export default analyisMapper;