import {format, date} from '../../../utils'

const mapDetails = (json) => {
    const {
        lineNumber,
        invoiceType,
        quantity,
        invoiceUOM,
        debitCreditIndicator,
        unitPrice,
        invoiceLineAmount,
        invoiceLineCurrencyAmount,
        shippingCostsAmount,
        shippingCostsCurrencyAmount,
        invoiceLineAmountCurrencyCode,
        shippingCostsAmountCurrencyCode,
        taxPointDate,
        description,
        generalLedgerAccountRowKey,
        accountId,
        uomBaseConversionFactor,
        productRowKey,
        productCode
    } = json;
    return [
        [
            {label: 'Line Number', value: lineNumber, type: 'string'},
            {label: 'Type', value: invoiceType, type: 'string'},
            {label: 'Quantity', value: quantity, type: 'number'},
            {label: 'UOM', value: invoiceUOM, type: 'string'},
            {label: 'UOM Base Conversion Factor', value: format(uomBaseConversionFactor, 'fullValue',null,2), type: 'number'},
            {label: 'Debit Credit Indicator', value: debitCreditIndicator, type: 'string'}
        ],
        [
            {label: 'Unit Price', value: format(unitPrice, 'fullValue'), type: 'currency'},
            {label: 'Amount', value: format(invoiceLineAmount, 'fullValue'), type: 'currency'},
            {label: 'Currency Amount', value: format(invoiceLineCurrencyAmount, 'fullValue'), type: 'currency'},
            {label: 'Currency Code', value: invoiceLineAmountCurrencyCode, type: 'string'},
            {label: 'Shipping Costs Amount', value: format(shippingCostsAmount, 'fullValue'), type: 'currency'},
            {label: 'Shipping Costs Currency Amount', value: format(shippingCostsCurrencyAmount, 'fullValue'), type: 'currency'},
            {label: 'Shipping Currency Code', value: shippingCostsAmountCurrencyCode, type: 'string'},
        ],
        [
            {label: 'Tax Point Date', value: date(taxPointDate), type: 'date'},
            generalLedgerAccountRowKey ?
                {
                    label: 'Account ID',
                    value: {id: generalLedgerAccountRowKey, name: accountId, __typename: 'GeneralLedger'},
                    type: 'object'
                } :
                {label: 'Account ID', value: accountId || 'N/A', type: 'string'},
            productRowKey ?
                {
                    label: 'Product',
                    value: {id: productRowKey, name: productCode, __typename: 'Product'},
                    type: 'object'
                } :
                {label: 'Product', value: productCode || 'N/A', type: 'string'},
        ],
        [
            {label: 'Description', value: description, type: 'string'}
        ]
    ]
};

const header = [
    {label: ["Line"], align: 'left',columnName: "line_number", sortable: true, basis: '7%'},
    {label: ["Account ID"], align: 'left',columnName: "account_id", sortable: true},
    {label: ["Description"], align: 'left'},
    {label: ["Product Code"], align: 'center',columnName: "product_code", sortable: true},
    {label: ["Quantity"], align: 'center',columnName: "quantity", sortable: true, basis: '8%'},
    {label: ["UOM"], align: 'center', basis: '13%'},
    {label: ["Unit Price"], align: 'center',columnName: "unit_price", sortable: true, basis: '15%'},
    {label: ["Invoice Line Amount"], align: 'right',columnName: "invoice_line_amount", sortable: true},
    {label: ["Invoice Line Currency Amount"], align: 'right',columnName: "invoice_line_currency_amount", sortable: true},
    {label: ["Debit Credit Indicator"], align: 'center',columnName: "debit_credit_indicator", sortable: true}
];

const mapCard = (json) => {
    const {
        description,
        lineNumber,
        quantity,
        invoiceUOM,
        unitPrice,
        invoiceLineAmount,
        productCode,
        accountId,
        debitCreditIndicator,
        invoiceLineCurrencyAmount,
    } = json;
    return [
        [
            {labels: ['line'], value: lineNumber || 'N/A', type: 'string', basis: '7%'}
        ],
        [
            {labels: ['accountId'], value: accountId, type: 'string'}
        ],
        [
            {labels: ['description'], value: description || 'N/A', type: 'string'}
        ],
        [
            {labels: ['productCode'], value: productCode, type: 'string',align: 'center'}
        ],
        [
            {labels: ['quantity'], value: quantity || 'N/A', type: 'number', basis: '8%'}
        ],
        [
            {labels: ['uom'], value: invoiceUOM || 'N/A', type: 'number', basis: '13%'}
        ],
        [
            {labels: ['unitprice'], value: format(unitPrice) || 'N/A', type: 'currency', basis: '15%'}
        ],
        [
            {labels: ['amount'], value: format(invoiceLineAmount), type: 'currency'}
        ],
        [
            {labels: ['currencyAmount'], value: format(invoiceLineCurrencyAmount,'normal',null,2), type: 'currency'}
        ],
        [
            {labels: ['debitCreditIndicator'], value: debitCreditIndicator, type: 'string', align: 'center'}
        ]
    ]
};

const mapper = (json, card, details) => {
    const name = json.description;

    return {
        ...json,
        __typename: json.__typename,
        id: json.id,
        name,
        shortName: name,
        details: details ? mapDetails(json) : [],
        card: card ? mapCard(json) : []
    }
};

const purchaseinvoicelineMapper = {
    map: mapper,
    header
}

export default purchaseinvoicelineMapper;