import { date, format } from '../../../utils'

const header = [
    { label: ["Discount"], align: 'left' },
    { label: ["Amount"], align: 'center' },
    { label: ["Currency Amount"], align: 'right', totalizerName: "total_settlement_amount_currency_amount", compact: "fullValue" },
    { label: ["Currency Code"], align: 'center' },
    { label: ["Date"], align: 'center' },
    { label: ["Payment Mechanism"], align: 'center' },
    { label: ["Debit Credit Indicator"], align: 'center' },
]

const mapCard = (json) => {
    const {
        settlementAmountCurrencyCode,
        settlementDiscount,
        settlementDate,
        paymentMechanism,
        debitCreditIndicator,
        settlementAmount,
        settlementCurrencyAmount
    } = json

    return [
        [
            { labels: ['discount'], value: settlementDiscount || 'N/A', type: 'string' }
        ],
        [
            { labels: ['amount'], value: format(settlementAmount, "fullValue", null), type: 'number' }
        ],
        [
            { labels: ['currencyAmount'], value: format(settlementCurrencyAmount, "fullValue"), type: 'currency' }
        ],
        [
            { labels: ['code'], value: settlementAmountCurrencyCode || 'N/A', type: 'string', align: 'center' }
        ],
        [
            { labels: ['date'], value: date(settlementDate), type: 'date' }
        ],
        [
            { labels: ['paymentMechanism'], value: paymentMechanism, type: 'number' }
        ],
        [
            { labels: ['debitCreditIndicator'], value: debitCreditIndicator, type: 'number' }
        ],
    ]
}

const mapper = (json, card) => {
    const name = json.invoice_id || json.purchase_invoice_id || json.payment_id

    return {
        ...json,
        __typename: json.__typename,
        id: json.id,
        name,
        shortName: name,
        card: card ? mapCard(json) : []
    }
}

const settlementMapper = {
    map: mapper,
    header
}

export default settlementMapper;