import React, {
	useState,
	forwardRef,
	useRef,
	useImperativeHandle,
	useEffect,
} from "react";
import { useGlobalValue } from "colbi_web_ui/lib/state/GlobalProvider";
import Icon from "colbi_web_ui/lib/components/Icon/Icon";
import {
	Dropdown,
	TextInput,
	Switch,
} from "colbi_web_ui/lib/components/inputs";
import { FORM_ACTIONS } from "../FormActions";
import styles from "./UserForm.module.sass";

const UserForm = (props, ref) => {
	const { data, organizations, status, onChange, onSave, roles = [] } = props;
	const { i18n } = useGlobalValue();
	const [passwordsMatchError] = useState(false);
	const [resetPassword, setResetPassword] = useState(false);
	const [newUser, setNewUser] = useState(true);

	const generateRandomPassword = () => {
		const newPassword = `#${Math.floor(
			Math.random() * Math.floor(10)
		)}${Math.random().toString(36).slice(2)}${Math.random()
			.toString(36)
			.toUpperCase()
			.slice(2)}`;
		return newPassword;
	};

	const [newPasswordConfirm] = useState(() => generateRandomPassword());

	const formRef = useRef();
	const {
		id,
		name,
		username: email,
		projects: userProjects,
		userOrganizations,
		password,
		blocked = 0,
	} = data || {};

	useEffect(() => {
		if (id) {
			setNewUser(false);
		}
	}, [id]);

	const tempAllProjects = (userOrganizations || [])
		.map((userOrg) => {
			const getAvailableOrganization = (organizations || []).find(
				({ id }) => id === `${userOrg.id}`
			);
			const getAvailableOrgProjects = (
				(getAvailableOrganization || {}).projects || []
			).map(({ id, name, role }) => ({
				id,
				name: `${name} (${userOrg.name})`,
				role,
			}));
			return getAvailableOrgProjects;
		})
		.flat()
		.filter((elem) => elem);

	const allProjects = [
		...new Map(tempAllProjects.map((item) => [item["id"], item])).values(),
	];

	const tempAllUserOrganizations = (organizations || []).map(
		({ id, name }) => ({ id, name })
	);

	useImperativeHandle(ref, () => ({
		submit: () => {
			formRef.current.dispatchEvent(
				new Event("submit", { cancelable: true, bubbles: true })
			);
		},
	}));

	const handleChange = (e, prop, value) => {
		onChange &&
			onChange({
				originalEvent: e,
				target: {
					value: {
						data: {
							...data,
							[prop]: value,
						},
					},
				},
			});
	};

	const organizationsItems = (userOrganizations || []).map(
		(organization, index) => {
			return (
				<div key={index} className={styles["project-entry"]}>
					<div>
						<Dropdown
							className={styles["dropdown"]}
							placeholder={""}
							value={organization}
							options={tempAllUserOrganizations}
							filter
							required
							onChange={(e) => {
								const userOrganization = userOrganizations[index];
								const nextOrganization = [...(userOrganizations || [])];
								nextOrganization.splice(index, 1, {
									...userOrganization,
									...(e.target.value || {}),
								});
								handleChange(e, "userOrganizations", nextOrganization);
							}}
						/>
					</div>
					<div>
						<Dropdown
							className={styles["dropdown"]}
							placeholder={""}
							value={roles.find(({ id }) => id === organization.role)}
							required
							filter
							options={roles}
							onChange={(e) => {
								const userOrganization = userOrganizations[index];
								const nextOrganization = [...(userOrganizations || [])];
								nextOrganization.splice(index, 1, {
									...userOrganization,
									role: (e.target.value || {}).id,
								});
								handleChange(e, "userOrganizations", nextOrganization);
							}}
						/>
					</div>
					<div>
						<button
							type="button"
							className={styles["remove-button"]}
							onClick={(e) => {
								const nextOrganization = [...(userOrganizations || [])];
								nextOrganization.splice(index, 1);
								handleChange(e, "userOrganizations", nextOrganization);
							}}
						>
							<Icon name="remove" />
						</button>
					</div>
				</div>
			);
		}
	);

	const projectItems = (userProjects || []).map((project, index) => {
		return (
			<div key={index} className={styles["project-entry"]}>
				<div>
					<Dropdown
						className={styles["dropdown"]}
						placeholder={""}
						value={project}
						options={allProjects}
						filter
						required
						onChange={(e) => {
							const userProject = userProjects[index];
							const nextProjects = [...(userProjects || [])];
							nextProjects.splice(index, 1, {
								...userProject,
								...(e.target.value || {}),
							});
							handleChange(e, "projects", nextProjects);
						}}
					/>
				</div>
				<div>
					<Dropdown
						className={styles["dropdown"]}
						placeholder={""}
						value={roles.find(({ id }) => id === (project || {}).role)}
						required
						filter
						options={roles}
						onChange={(e) => {
							const userProject = userProjects[index];
							if (userProject && userProject.id) {
								const nextProjects = [...(userProjects || [])];
								nextProjects.splice(index, 1, {
									...userProject,
									role: (e.target.value || {}).id,
								});
								handleChange(e, "projects", nextProjects);
							}
						}}
					/>
				</div>
				<div>
					<button
						type="button"
						className={styles["remove-button"]}
						onClick={(e) => {
							const nextProjects = [...(userProjects || [])];
							nextProjects.splice(index, 1);
							handleChange(e, "projects", nextProjects);
						}}
					>
						<Icon name="remove" />
					</button>
				</div>
			</div>
		);
	});

	return status !== "loading" ? (
		<form
			ref={formRef}
			onSubmit={(e) => {
				e.stopPropagation();
				e.preventDefault();
				if (formRef.current.checkValidity()) {
					if (!resetPassword) {
						data["password"] = newUser ? newPasswordConfirm : undefined;
					}
					onSave && onSave();
				} else {
					formRef.current.reportValidity();
				}
			}}
		>
			{passwordsMatchError && (
				<div className={styles["error"]}>
					<small>{i18n`Password and confirm password do not match.`}</small>
				</div>
			)}
			<div className={styles["group-content"]}>
				<div className={styles["flex"]}>
					<TextInput
						className={styles["input"]}
						name={i18n`Fullname`}
						value={name || ""}
						placeholder={i18n`Enter a name`}
						required
						onChange={(e) => {
							handleChange(e, "name", e.target.value);
						}}
					/>
					<TextInput
						className={styles["input"]}
						name={i18n`Email`}
						value={email || ""}
						placeholder={i18n`Enter an email`}
						required
						onChange={(e) => {
							handleChange(e, "username", e.target.value);
						}}
					/>
				</div>
			</div>
			<div className={styles["input-field"]}>
				<h5 className={styles["label"]}>{i18n`Organizations`}</h5>
				<div>
					{organizationsItems.length ? (
						organizationsItems
					) : (
						<small>{i18n`No organizations assigned`}</small>
					)}
				</div>
				<div>
					<button
						className={styles["add-button"]}
						type="button"
						onClick={(e) => {
							handleChange(e, "userOrganizations", [
								...(userOrganizations || []),
								tempAllUserOrganizations[0],
							]);
						}}
					>
						<small>{i18n`[+] Add new Organization`}</small>
					</button>
				</div>
			</div>

			<div className={styles["input-field"]}>
				<h5 className={styles["label"]}>{i18n`Projects`}</h5>
				<div>
					{projectItems.length ? (
						projectItems
					) : (
						<small>{i18n`No projects assigned`}</small>
					)}
				</div>
				<div>
					<button
						className={styles["add-button"]}
						type="button"
						onClick={(e) => {
							handleChange(e, "projects", [
								...(userProjects || []),
								allProjects[0],
							]);
						}}
					>
						<small>{i18n`[+] Add new project`}</small>
					</button>
				</div>
			</div>
			<div className={styles["user-management"]}>
				<h4 className={styles["title"]}>
					{i18n`User Management`}{" "}
					<small className={`${styles[`user-status-${blocked}`]}`}>
						{blocked
							? i18n`(The user is currently blocked)`
							: i18n`(The user is currently active)`}
					</small>
				</h4>
				<div>
					<Switch
						name={i18n`Disable User`}
						checked={blocked}
						onChange={(e) => {
							handleChange(e, "blocked", e.target.value);
						}}
					/>
				</div>
				<div className={styles["group-content"]}>
					<Switch
						name={i18n`Set new password`}
						checked={newUser ? true : resetPassword}
						onChange={(e) => {
							if (newUser === false) {
								data["password"] = generateRandomPassword();
								setResetPassword(e.target.value);
							}
						}}
					/>
				</div>
				{(resetPassword || newUser) && (
					<div className={styles["group-content"]}>
						<div className={styles["flex"]}>
							<TextInput
								className={styles["input"]}
								name={i18n`New Password`}
								autoComplete="off"
								placeholder={i18n`New Password`}
								defaultValue={password || newPasswordConfirm}
								disabled={true}
							/>
						</div>
					</div>
				)}
			</div>
		</form>
	) : null;
};

const UserFormRef = forwardRef(UserForm);

UserFormRef.queries = (args) => {
	return [
		{
			resource: "user_projects_organizations",
			body: "id,name,projects{id,name},userRole",
		},
		{
			resource: "roles_to_form",
			body: "id,name",
		},
		args && args.id
			? {
					resource: "user",
					args: { userId: args.id },
					body: "__typename,id,username,name,blocked,projects{id,name,role},userOrganizations{id,role,name}",
			  }
			: undefined,
	].filter((q) => q);
};

UserFormRef.dataMapper = ["organizations", "roles", "data"];

UserFormRef.actions = [FORM_ACTIONS.SAVE];

export default UserFormRef;
