import { format, date } from "../../../utils";

const mapCard = (json) => {
	const {
		account_id,
		description,
		debit_amount,
		credit_amount,
		accumulated_net,
		transaction__transaction_date,
		transaction__period,
		general_ledger__account_description,
		transaction__transaction_id,
		transaction__journal_id
	} = json;

	return [
		[{ labels: ["Id - Account"], value: `${account_id} - ${general_ledger__account_description || "N/A"}`, type: "string", basis: "25%" }],
		[{ labels: ["Transaction ID"], value: transaction__transaction_id, type: "string" }],
		[{ labels: ["Description"], value: description, type: "string" }],
		[{ labels: ["Journal ID"], value: transaction__journal_id, type: "string", basis: "8%" }],
		[
			{
				labels: ["Transaction Date"],
				value: date(transaction__transaction_date),
				type: "date",
			},
		],
		[{ labels: ["Period"], value: transaction__period, type: "number", basis: "5%" }],
		[
			{
				labels: ["Debit amount"],
				value: format(debit_amount),
				type: "currency",
			},
		],
		[
			{
				labels: ["Credit amount"],
				value: format(credit_amount),
				type: "currency",
			},
		],
		[
			{
				labels: ["Accumulatted_net"],
				value: format(accumulated_net),
				type: "currency",
			},
		],
	];
};

const header = [
	{ label: ["Id - Account"], align: "left", basis: "25%" },
	{ label: ["Transaction Id"], align: "left" },
	{ label: ["Description"], align: "left" },
	{ label: ["Journal Id"], align: "left", basis: "8%" },
	{ label: ["Transaction Date"], align: "center" },
	{ label: ["Period"], align: "center", basis: "5%" },
	{ label: ["Debit amount"], align: "right" },
	{ label: ["Credit amount"], align: "right" },
	{ label: ["Accumulated Net"], align: "right" },
];

const mapper = (json, card) => {
	return {
		...json,
		name: json.name,
		shortName: json.name,
		card: card ? mapCard(json) : [],
		link: {
			to: `/:locale(pt|en|lt|es)?/:projectId/:fiscalYear([0-9]{4}|current)/:importId/:modulePrefix/transactions/${json.transaction_id}`,
			filter: [{ "filters": [{ "value": json.account_id }], "column": "account_id" }]
		}
	};
};

const statementaccountaccumulatedMapper = {
	map: mapper,
	header
};

export default statementaccountaccumulatedMapper
