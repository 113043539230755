import { format, date } from '../../../utils'

const header = [
    { label: ["Account"], align: 'center' },
    { label: ["Impairment Date"], align: 'center' },
    { label: ["Elimination Date"], align: 'center' },
    { label: ["impairment"], align: 'right' },
    { label: ["Elimination"], align: 'right' }
]

const mapCard = (json) => {
	const {
        generalLedgerAccountRowKey,
        accountId,
        impairmentDate,
        eliminationDate,
        impairment,
        elimination
	} = json

	return [
        [
            generalLedgerAccountRowKey ?
                { label: 'Account', value: { id: generalLedgerAccountRowKey, name: accountId, __typename: 'GeneralLedger' }, type: 'object' } : 
                { labels: ['Account'], value: accountId || 'N/A', type: 'string' }
        ],
        [
            { labels: ['impairmentDate'], value: date(impairmentDate), type: 'date' }
        ],
        [
            { labels: ['eliminationDate'], value: date(eliminationDate), type: 'date' }
        ],
        [
            { labels: ['impairment'], value: format(impairment), type: 'currency' }
        ],
        [
            { labels: ['elimination'], value: format(elimination), type: 'currency' }
        ]
	]
}

const mapper = (json, card) => {
    const name = json.accountId
    
    return {
        ...json,
        __typename: json.__typename,
        id: json.id,
		name,
        shortName: name,
		card: card ? mapCard(json) : []
	}
}

const assetvaluationimpairmentMapper = {
    map: mapper,
    header
}
export default assetvaluationimpairmentMapper