const auditNote = (state = {}, action) => {
	switch (action.type) {
		case 'SUBMIT_SUCCESS': {
			const { data } = action.payload || {}
			return {id: data, message: 'Justification Sent'}
		}
		case 'SUBMIT_ERROR': {
			const { data } = action.payload || {}
			return {id: data, message: 'ERROR'}
		}
		default: break
	}
	return state
}

export default auditNote
