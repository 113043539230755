import React, { useEffect, useRef, useState } from 'react'
import { NavLink, withRouter } from 'react-router-dom'

import { hydrateRoute, checkIfStuck } from 'colbi_web_ui/lib/utils'

import styles from './MainTabs.module.sass'

const MainTabs = ({ className, routes, params }) => {
	const elem = useRef(null)
	const [stuck, setStuck] = useState(false)
	const tabs = (routes || []).map(({ path, name, exact }) => {
		return (
			<NavLink
				key={ path }
				to={ hydrateRoute(path, params) }
				exact={ exact }
				className={ styles['tab'] }
				activeClassName={ styles['is-selected'] }
			><small>{ name }</small></NavLink>
		)
	})
	useEffect(() => {
		const handleScroll = () => {
			const isStuck = checkIfStuck(elem.current)
			setStuck(isStuck)
		}
		window.addEventListener('scroll', handleScroll)
		window.addEventListener('resize', handleScroll)

		const isStuck = elem && elem.current && checkIfStuck(elem.current)
		setStuck((prevState) => {
			if (prevState !== isStuck) {
				return isStuck
			}
			return prevState
		})

		if (elem && elem.current) {
			const selected = elem.current.querySelector(`.${ styles['is-selected'] }`)
			if (selected) {
				const rect = selected.getBoundingClientRect()
				
				if (rect.x < 0) {
					selected.parentNode.scrollLeft = selected.offsetLeft - 20
				} else if (rect.x + rect.width > window.innerWidth) {
					const offset = (rect.x + rect.width) - window.innerWidth
					selected.parentNode.scrollLeft = selected.parentNode.scrollLeft + offset + 20
				}
			}
		}

		return () => {
			window.removeEventListener('scroll', handleScroll)
			window.removeEventListener('resize', handleScroll)
		}
	}, [elem])
	return (
		<div ref={ elem } className={ `${ styles['root'] } ${ className || '' } ${ stuck ? styles['stuck'] : '' }` }>
			<div className={ styles['content'] }>
				{ tabs }
			</div>
		</div>
	)
}

export default withRouter(MainTabs)
