import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { parse } from 'query-string'
import I18n from '../../I18n'

import { mapProperties } from '../../utils'

const StackLayout = (props) => {
	const { location, match, route, fetch } = props
	const queryParams = parse(location.search) || {}
	const routeParams = match.params || {}
	const params = {
		...queryParams,
		...routeParams
	}

	return (route.components || []).map((c, index) => {
		const Component = c.component
		const componentProps = mapProperties(
			c.props,
			(fetch || {}).results,
			(fetch || {}).status,
			(route.queries && route.queries(params)) || [],
			props,
			{ ...match.params, ...parse(location.search) },
			I18n.translate,
			match.params.locale || 'en'
		)
		return (<Component key={ index } modifiers={ c.modifiers } { ...componentProps } activity={ fetch.activity } />)
	})
}

export default connect(
	({ fetch }) => (
		{
			fetch
		}
	)
)(withRouter(StackLayout))