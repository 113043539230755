const modalsData = (state = {}, action) => {
	switch (action.type) {
		case 'UPDATE_MODAL_DATA': {
			const result = JSON.parse(JSON.stringify(state))
			result[action.id] = {
				args: action.args,
				data: action.payload,
				errors: [],
				status: action.status || 'success'
			}
			return result
		}
		case 'UPDATE_MODAL_STATUS': {
			const result = JSON.parse(JSON.stringify(state))
			result[action.id] = {
				...(result[action.id] || {}),
				errors: action.errors,
				status: action.status || (result[action.id] || {}).status
			}
			return result
		}
		case 'FETCH_MODAL_DATA': {
			const result = JSON.parse(JSON.stringify(state))
			result[action.id] = {
				data: result[action.id].data,
				errors: [],
				status: 'loading'
			}
			return result
		}
		case 'SAVE_MODAL_DATA': {
			const result = JSON.parse(JSON.stringify(state))
			result[action.id] = {
				data: result[action.id].data,
				errors: [],
				status: 'saving'
			}
			return result
		}
		case 'REMOVE_MODAL_DATA': {
			const result = JSON.parse(JSON.stringify(state))
			Reflect.deleteProperty(result, action.id)
			return result
		}
		default: break
	}
	return state
}

export default modalsData