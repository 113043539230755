import { checkNumberValues } from "../../../utils/convertUtils";

const mapCard = (json) => {
	const {
		benefitCode,
		eligibleRegion,
		caeCode,
		amountOfRelevantApplications,
		taxIncentivesIrc,
		taxIncentivesOthers,
		nonTaxIncentives,
		total,
	} = json

	return [
		[
			{ labels: ['benefit_code'], value: benefitCode, type: 'string', basis: '20%' },
		],
		[
			{ labels: ['eligible_region'], value: eligibleRegion, type: 'string', basis: '20%' }
		],
		[
			{ labels: ['cae_code'], value: caeCode, type: 'string', basis: '20%' }
		],
		[
			{ labels: ['amount_of_relevant_applications'], value: checkNumberValues(amountOfRelevantApplications), type: 'number', basis: '20%' }
		],
		[
			{ labels: ['tax_incentives_irc'], value: checkNumberValues(taxIncentivesIrc), type: 'number', basis: '20%' }
		],
		[
			{ labels: ['tax_incentives_others'], value: checkNumberValues(taxIncentivesOthers), type: 'number', basis: '20%' }
		],
		[
			{ labels: ['non_tax_incentives'], value: checkNumberValues(nonTaxIncentives), type: 'number', basis: '20%' }
		],
		[
			{ labels: ['total'], value: checkNumberValues(total), type: 'number', basis: '20%' }
		],
	]
}

const mapper = (json, card) => {
	return {
		...json,
		card: card ? mapCard(json) : []
	}
}

const header = [
	{ label: ["Código do benefício"], align: 'left', basis: '20%' },
	{ label: ["Região elegível"], align: 'left', basis: '20%' },
	{ label: ["Código CAE da atividade a que se destina o investimento "], align: 'left', basis: '20%' },
	{ label: ["Montante das aplicações relevantes"], align: 'left', basis: '20%' },
	{ label: ["Incentivos Fiscais IRC"], align: 'left', basis: '20%' },
	{ label: ["Incentivos Fiscais IMI, IMT e SELO"], align: 'left', basis: '20%' },
	{ label: ["Incentivos Não Fiscais"], align: 'left', basis: '20%' },
	{ label: ["Incentivos Total"], align: 'left', basis: '20%' },
]

const detailannexd22Mapper = {
	map: mapper,
	header
}

export default detailannexd22Mapper;