import { checkNumberValues } from "../../../utils/convertUtils";

const mapCard = (json) => {
	const {
		diploma,
		balance_not_deducted,
		period_allocation,
		period_deduction,
		periods_balance,
		balance_lapsed,
		benefit_period,
		nif,
		balance_of_transmitted_profit
	} = json

	return [
		[
			{ labels: ['NIF da soc. Individual (RETGS)'], value: nif, type: 'string', basis: '20%' },
		],
		[
			{ labels: ['Diploma'], value: diploma, type: 'string', basis: '20%' }
		],
		[
			{ labels: ['Período a que respeita o benefício'], value: benefit_period, type: 'string', basis: '20%' }
		],
		[
			{ labels: ['Saldo caducado'], value: checkNumberValues(balance_lapsed), type: 'number', basis: '20%' }
		],
		[
			{ labels: ['Saldo não deduzido no período anterior'], value: checkNumberValues(balance_not_deducted), type: 'number', basis: '20%' }
		],
		[
			{ labels: ['Dotação do período'], value: checkNumberValues(period_allocation), type: 'number', basis: '20%' }
		],
		[
			{ labels: ['Dedução do período'], value: checkNumberValues(period_deduction), type: 'number', basis: '20%' }
		],
		[
			{ labels: ['Saldo que transita para período seguinte'], value: checkNumberValues(periods_balance), type: 'number', basis: '20%' }
		],
		[
			{ labels: ['Saldo do benefício transmitido'], value: checkNumberValues(balance_of_transmitted_profit), type: 'number', basis: '20%' }
		]
	]
}

const mapper = (json, card) => {
	return {
		...json,
		card: card ? mapCard(json) : []
	}
}

const header = [
	{ label: ["NIF da soc. Individual (RETGS)"], align: 'left', basis: '20%' },
	{ label: ["Diploma"], align: 'left', basis: '20%' },
	{ label: ["Período a que respeita o benefício"], align: 'left', basis: '20%' },
	{ label: ["Saldo caducado"], align: 'right', basis: '25%' },
	{ label: ["Saldo não deduzido no período anterior"], align: 'right', basis: '20%' },
	{ label: ["Dotação do período"], align: 'right', basis: '25%' },
	{ label: ["Dedução do período"], align: 'right', basis: '25%' },
	{ label: ["Saldo que transita para período seguinte"], align: 'right', basis: '20%' },
	{ label: ["Saldo do benefício transmitido"], align: 'right', basis: '20%' },
]
const colletiondeductionsmod22Mapper =  {
	map: mapper,
	header
}

export default colletiondeductionsmod22Mapper;