import { format } from '../../../utils'

const header = [
    { label: ["Analysis ID"], align: 'left' },
    { label: ["Analysis Type"], align: 'center',columnName: "analysis_type",sortable: true },
    { label: ["Currency"], align: 'center',columnName: "currency_code",sortable: true },
    { label: ["Amount"], align: 'right' ,  columnName: "amount",sortable: true },
    { label: ["Currency Amount"], align: 'right',columnName: "currency_amount",sortable: true}
]

const mapCard = (json) => {
	const {
        analysisId,
        analysisType,
        currencyCode,
        amount,
        currencyAmount
	} = json

	return [
        [
            { labels: ['analysisId'], value: analysisId, type: 'string' }
        ],
        [
            { labels: ['analysisType'], value: analysisType, type: 'number' }
        ],
        [
            { labels: ['foreignCurrency'], value: currencyCode, type: 'string', align: 'center' }
        ],
        [
            { labels: ['amount'], value: format(amount, "fullValue"), type: 'currency' }
        ],
        [
            { labels: ['CurrencyAmount'], value: format(currencyAmount, 'fullValue', null, 2), type: 'currency' }
        ]
	]
}

const mapper = (json, card) => {
    const name = json.invoice_line_id || json.payment_line_id || json.purchase_invoice_line_id || json.transaction_line_id
    
    return {
        ...json,
        __typename: json.__typename,
        id: json.id,
		name,
		shortName: name,
		card: card ? mapCard(json) : []
	}
}
const generalledgerentryanalysesMapper = {
    map: mapper,
    header
}

export default generalledgerentryanalysesMapper;