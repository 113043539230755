const mapDetails = (json) => {
	const {
		journalId,
		taxEntity,
		description
	} = json
	return [
		[
			{ label: 'Journal Id', value: journalId, type: 'string' },
			{ label: 'Tax Entity', value: taxEntity, type: 'string' },
			{ label: 'Description', value: description, type: 'string' }
		]
	]
}

const mapCard = (json) => {
	const {
		journalId,
		description
	} = json
	return [
		[
			{ labels: ['id'], value: `${ journalId }`, type: 'string' }
		],
		[
			{ labels: ['description'], value: `${ description }`, type: 'string' }
		]
	]
}

const mapper = (json, card, details) => {
	
	return {
		...json,
		name: json.description,
		shorName: json.description,
		details: details ? mapDetails(json) : [],
		card: card ? mapCard(json) : []
	}
}

const header = [
	{ label: ['ID'], align: 'left' },
	{ label: ['Description'], align: 'left', columnName: "description", sortable: true }
]

const journalMapper = {
	map: mapper,
	header
} 
export default journalMapper;