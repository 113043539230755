import I18n from "../../../I18n";
import { format } from "../../../utils";
import { buildAddressRow } from "../mappersUtils";

const mapDetails = json => {
	const {
		taxId,
		taxEntity,
		accountId,
		phone,
		contact,
		customerId,
		companyName,
		selfBilling,
		kpiPercentageOnSales,
		kpiSalesTotalNet,
		kpiSalesDebitTotalNet,
		fax,
		email,
		website,
		billingAddress,
		shipAddresses
	} = json;

	return [
		[
			{ label: 'Customer Id', value: customerId, type: 'string' },
			{ label: 'VAT', value: taxId, type: 'string' },
			{ label: 'Account Id', value: accountId, type: 'string' },
			{ label: 'Tax Entity', value: taxEntity, type: 'string' },
			{ label: 'Self Billing', value: selfBilling, type: 'boolean' },
			{ label: 'Company', value: companyName, type: 'string' },
		],
		[
			{
				label: "Percentage on Sales",
				value: format(
					(parseFloat(kpiPercentageOnSales) || 0).toFixed(2),
					"normal",
					null
				),
				type: "percentage",
				backgroundColor: "#74AF27",
				color: "#FFFFFF",
				icon: "percentage"
			},
			{
				label: "Sales Total (N)",
				value: format(kpiSalesTotalNet),
				type: "currency",
				backgroundColor: "#8397A8",
				color: "#FFFFFF",
				icon: "sales-amount"
			},
			{
				label: "Total Credit and Returns (N)",
				value: format(kpiSalesDebitTotalNet),
				type: "currency",
				backgroundColor: "#5B6975",
				color: "#FFFFFF",
				icon: "returns"
			}
		],
		{
			title: "Contacts",
			rows: [
				[
					{ label: "Contact", value: contact || "N/A", type: "string" },
					{ label: "Phone", value: phone || "N/A", type: "string" },
					{ label: "Fax", value: fax || "N/A", type: "string" },
					{ label: "Email", value: email || "N/A", type: "string" },
					{ label: "Website", value: website || "N/A", type: "string" }
				]
			]
		},
		{
			title: "Billing Address",
			rows: [buildAddressRow(billingAddress)]
		},
		{
			title: "Shipping Addresses",
			rows: (shipAddresses || []).map(address => buildAddressRow(address))
		}
	];
};

const mapCard = json => {
	const {
		companyName,
		taxId,
		taxEntity,
		taxIdCount,
		accountId,
		billingAddress,
		customerId
	} = json;
	return [
		[{ labels: ["Customer Id"], value: customerId, type: "string", basis: "12%" }],	
		[{ labels: ["name"], value: companyName, type: "string", basis: "50%" }],	
		[{ labels: ["nif"], value: `${taxId}`, type: "string" }],
		[{ labels: ["id"], value: `${accountId}`, type: "string" }],
		[{ labels: ["entity"], value: `${taxEntity}`, type: "number" }],
		[{ labels: ["country"], value: billingAddress.country, type: "string", basis: "5%" }],
		[{ labels: ["nif_count"], value: `(${taxIdCount})`, type: "number" }]
	];
};

const mapReports = reports => {
	const [salesClientPeriod, top, topProductsMovements] = reports;

	const salesData = [];

	for (let i = 0; i < 12; i++) {
		salesData.push({
			period: i + 1,
			value:
				((salesClientPeriod || []).find(({ period }) => period === i + 1) || {})
					.value || 0
		});
	}

	const topsTabs = [];
	const topsData = [];
	if ((top || []).length) {
		topsTabs.push(I18n.translate("Top"));
		topsData.push(
			top.map(json => ({
				...json,
				__typename: "Product",
				percentage: Math.round((json.value / json.total) * 100 * 100) / 100
			}))
		);
		topsData.push(
			topProductsMovements.map(json => ({
				...json,
				__typename: "Product",
				percentage: Math.round((json.value / json.total) * 100 * 100) / 100
			}))
		);
	}

	return (salesClientPeriod || []).length ||
		(top || []).length ||
		(topProductsMovements || []).length
		? [
				{
					type: "bar",
					icon: "comparation",
					title: I18n.translate("Sales (Net)"),
					colspan: 2,
					data: [salesData],
					links: [
						{
							filterColumn: "period"
						}
					],
					currency: true
				},
				{
					type: "tops",
					title: I18n.translate("Products (N)"),
					tabs: topsTabs,
					colspan: 1,
					data: topsData
				}
		  ]
		: null;
};

const mapper = (json, card, details) => {
	return {
		...json,
		name: json.companyName,
		shortName: json.companyName,
		details: details ? mapDetails(json) : [],
		card: card ? mapCard(json) : [],
		reports: mapReports(json.reports || [])
	};
};

const header = [
	{ label: ['Customer id'], align: 'left', columnName: "customer_id", sortable: true, basis: "12%" },
	{ label: ['Entity_Name'], align: 'left', columnName: "company_name", sortable: true, basis: "50%" },
	{ label: ['VAT'], align: 'left' }, 
	{ label: ['Account ID'], align: 'left', columnName: "account_id", sortable: true },
	{ label: ['Tax Entity'], align: 'center', columnName: "tax_entity" },
	{ label: ['Country'], align: 'center', basis: "5%" },
	{ label: ['VAT (Count)'], align: 'center', columnName: "customer_tax_id_dup", sortable: true }
]
 
const customerMapper = {
	map: mapper,
	header
};

export default customerMapper;