import React, { forwardRef, useRef, useImperativeHandle, useState } from 'react'
import { TextInput, Dropdown, DatePicker } from 'colbi_web_ui/lib/components/inputs'
import { useGlobalValue } from 'colbi_web_ui/lib/state/GlobalProvider'
import { FORM_ACTIONS } from '../FormActions'

import styles from './GeneratorForm.module.sass'

const GeneratorForm = (props, ref) => {
	const { data, file_types, status, onChange, onSave } = props
	const initialDates = {
		"startDate": new Date(),
		"endDate": new Date()
	}
	const [inputs, setInputs] = useState(initialDates)
	const formRef = useRef()
	const {
		fileTypeId,
		fiscalYear,
	} = data || {}
	
	const { i18n } = useGlobalValue()

	const handleChange = (e, prop, value) => {
		onChange && onChange({
			originalEvent: e,
			target: {
				value: {
					data: {
						...data,
						[prop]: value
					}
				}
			}
		})
	}

	const handleDateChange = (e, fieldName) => {
		setInputs(inputs => ({
            ...inputs,
            [fieldName]: e
        }))
	};

	useImperativeHandle(ref, () => ({
		submit: () => {
			formRef.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))
		}
	}))

	return status !== 'loading' ? (
		<form ref={formRef} onSubmit={(e) => {
			e.preventDefault()
			e.stopPropagation()
			if (formRef.current.checkValidity()) {
				data["__typename"] = "GeneratorConfiguration"
				for( const props in inputs ){
					data[`${props}`] = inputs[props]
				}
				onSave && onSave();
			} else {
				formRef.current.reportValidity()
			}
		}}>
			<div className={styles['group-content']}>
				<div className={styles['flex']}>
					<DatePicker 
						className={styles['wrap-datepicker']}
						name={i18n`Start Date`}
						value={inputs["startDate"]}
						required
						onChange={(e) => {
							handleDateChange(e, "startDate")
						}}
						dateFormat="yyyy-MM-dd"
					/>
					<DatePicker 
						className={styles['wrap-datepicker']}
						name={i18n`End Date`}
						value={inputs["endDate"]}
						required
						onChange={(e) => {
							handleDateChange(e, "endDate")
						}}
						dateFormat="yyyy-MM-dd"
					/>
					<TextInput
						className={styles['input']}
						name={i18n`Fiscal Year`}
						placeholder={i18n`Enter a year`}
						value={fiscalYear || ''}
						required
						onChange={(e) => {
							handleChange(e, 'fiscalYear', e.target.value)
						}}
					/>
				</div>
			</div>
			<div className={styles['input-field']}>
				<h5 className={styles['label']}>{i18n`File Types`}</h5>
				<Dropdown
					value={fileTypeId && file_types ? file_types.find(({ id }) => id === fileTypeId) || file_types[0] : null}
					options={file_types || []}
					className={styles['dropdown']}
					placeholder={i18n`-- Select an File Type --`}
					required
					onChange={(e) => {
						handleChange(e, 'fileTypeId', e.target.value.id)
					}}
				/>
			</div>
		</form>
	) : null

}


const GeneratorFormRef = forwardRef(GeneratorForm)

GeneratorFormRef.queries = (args) => {
	return [
		{
			resource: 'file_types',
			body: 'id,name'
		},
		undefined
	].filter(q => q)
}

GeneratorFormRef.dataMapper = ['file_types', 'data']

GeneratorFormRef.actions = [FORM_ACTIONS.GENERATE]

export default GeneratorFormRef