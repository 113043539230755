import I18n from "../../I18n";
import { buildRequestBody } from "../../utils";
import { pushDialog } from "./dialogActions";

const downloadStart = () => (
	{
		type: 'DOWNLOAD_START'
	}
)
const downloadEnd = () => (
	{
		type: 'DOWNLOAD_END'
	}
)

export const downloadAction = (argQueries, locale) => (
	(dispatch, getState) => {

		const queries = (argQueries || []).map(q => {
			return {
				...q,
				args: {
					...(q.args || {}),
					page: 0,
					pageSize: 5000
				}
			}
		})

		let queriesBodies = buildRequestBody(queries, locale, getState().user ? getState().user.authToken : "", true)
		queriesBodies.query = (queriesBodies.query || {}).replace('__typename,id,', '')

		dispatch(downloadStart())
		fetch('/api/download_xls', {
			method: 'POST',
			credentials: 'same-origin',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify(queriesBodies)
		}).then((t) => {
			if (t.status !== 200) {
				dispatch(downloadEnd())
				dispatch(pushDialog('AlertDialog', I18n.translate`Xlsx Export Error`, {
					title: I18n.translate`Something went wrong when generating the requested file`
				}))
			} else {
				dispatch(downloadEnd())
				return t.blob().then((b) => {
					if (b && b.size > 20) {
						const a = document.createElement("a")
						const filename = t.headers.get("Filename")
						a.href = URL.createObjectURL(b)
						a.setAttribute("download", `${filename}.xlsx`)
						document.body.appendChild(a)
						a.click()
						document.body.removeChild(a)
					} else {
						console.error('empty file')
					}
				});
			}
		}).catch(() => {
			dispatch(downloadEnd())
			dispatch(pushDialog('AlertDialog', I18n.translate`Xlsx ExportError`, {
				title: I18n.translate`Something went wrong when generating the requested file`
			}))
		})
	}
)