const header = [
    { label: ["IBAN Number"], align: 'left' },
	{ label: ["Bank Account Number"], align: 'center' }
]

const mapCard = (json) => {
	const {
        ibanNumber,
        bankAccountNumber
	} = json

	return [
        [
            { labels: ['ibanNumber'], value: ibanNumber, type: 'string' }
        ],
        [
            { labels: ['bankAccountNumber'], value: bankAccountNumber, type: 'number' }
        ]
	]
}

const mapper = (json, card) => {
    const name = json.customer_id || json.supplier_id
    
    return {
        ...json,
        __typename: json.__typename,
        id: json.id,
		name,
        shortName: name,
		card: card ? mapCard(json) : []
	}
}

const bankaccountMapper = {
    map: mapper,
    header
}

export default bankaccountMapper;