const mapCard = (json) => {
	const {
		SapDocNo,
		SapDocType,
		HsnCode,
		Quantity,
		UnitOfMeasure,
		UnitPrice,
		TaxableValue,
		CgstRate,
		CgstAmount,
		IgstRate,
		IgstAmount,
		SgstRate,
		SgstAmount,
	} = json;

	return [
		[{ labels: ["SapDocNo"], value: SapDocNo, type: "string" }],
		[{ labels: ["SapDocType"], value: SapDocType, type: "string" }],
		[{ labels: ["HsnCode"], value: HsnCode, type: "string" }],
		[{ labels: ["Quantity"], value: Quantity, type: "string" }],
		[{ labels: ["UnitOfMeasure"], value: UnitOfMeasure, type: "string" }],
		[{ labels: ["UnitPrice"], value: UnitPrice, type: "string" }],
		[{ labels: ["TaxableValue"], value: TaxableValue, type: "string" }],
		[{ labels: ["CgstRate"], value: CgstRate, type: "percentage" }],
		[{ labels: ["CgstAmount"], value: CgstAmount, type: "string" }],
		[{ labels: ["SgstRate"], value: SgstRate, type: "percentage" }],
		[{ labels: ["SgstAmount"], value: SgstAmount, type: "string" }],
		[{ labels: ["IgstRate"], value: IgstRate, type: "percentage" }],
		[{ labels: ["IgstAmount"], value: IgstAmount, type: "string" }],
	];
};

const mapper = (json, card, details) => {
	return {
		...json,
		name: json.name,
		shortName: json.name,
		//details: details ? mapDetails(json) : [],
		card: card ? mapCard(json) : [],
	};
};

const header = [
	{
		label: ["SAP Doc. No."],
		align: "left",
		columnName: "SapDocNo",
		sortable: false,
	},
	{
		label: ["SAP Doc. Type"],
		align: "left",
		columnName: "SapDocType",
		sortable: false,
	},
	{
		label: ["Hsn Code"],
		align: "left",
		columnName: "HsnCode",
		sortable: false,
	},
	{
		label: ["Quantity"],
		align: "left",
		columnName: "Quantity",
		sortable: false,
	},
	{
		label: ["Unit Of Measure"],
		align: "left",
		columnName: "UnitOfMeasure",
		sortable: false,
	},
	{
		label: ["Unit Price"],
		align: "left",
		columnName: "UnitPrice",
		sortable: false,
	},
	{
		label: ["Taxable Value (₹)"],
		align: "left",
		columnName: "TaxableValue",
		sortable: false,
	},
	{
		label: ["CGST Rate (%)"],
		align: "left",
		columnName: "CgstRate",
		sortable: false,
	},
	{
		label: ["CGST Amount (₹)"],
		align: "left",
		columnName: "CgstAmount",
		sortable: false,
	},
	{
		label: ["SGST Rate (%)"],
		align: "left",
		columnName: "SgstRate",
		sortable: false,
	},
	{
		label: ["SGST Amount (₹)"],
		align: "left",
		columnName: "SgstAmount",
		sortable: false,
	},
	{
		label: ["IGST Rate (%)"],
		align: "left",
		columnName: "IgstRate",
		sortable: false,
	},
	{
		label: ["IGST Amount (₹)"],
		align: "left",
		columnName: "IgstAmount",
		sortable: false,
	},
];

const alleinvoicelinesMapper = {
	map: mapper,
	header,
};

export default alleinvoicelinesMapper;
