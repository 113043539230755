import { Widget, Grid, charts, cards, AuditSummaryWidget } from "colbi_web_ui/lib/components";
import AppByFile from "../controllers/AppByFile/AppByFile";

const queries = (args = {}) => {
    return [
        {
            resource: `efatura_sales_invoices_kpis`,
            args: {
                projectId: (args.projectId),
                fiscalYear: (args.fiscalYear),
                modulePrefixEfatura: args.modulePrefix
            },
            body: `...efatura_sales_invoices_kpis`
        },
        {
            resource: `efatura_purchases_invoices_kpis`,
            args: {
                projectId: (args.projectId),
                fiscalYear: (args.fiscalYear),
                modulePrefixEfatura: args.modulePrefix
            },
            body: `...efatura_purchases_invoices_kpis`
        },
        {
            resource: `efatura_sales_invoices_graphs`,
            args: {
                projectId: (args.projectId),
                fiscalYear: (args.fiscalYear),
                modulePrefixEfatura: args.modulePrefix
            },
            body: 'report'
        },
        {
            resource: `efatura_purchases_invoices_graphs`,
            args: {
                projectId: (args.projectId),
                fiscalYear: (args.fiscalYear),
                modulePrefixEfatura: args.modulePrefix
            },
            body: 'report'
        },
        {
            resource: `efatura_sales_by_issuer_activity`,
            args: {
                projectId: (args.projectId),
                fiscalYear: (args.fiscalYear),
            },
            body: 'report'
        },
        {
            resource: `efatura_purchases_by_issuer_activity`,
            args: {
                projectId: (args.projectId),
                fiscalYear: (args.fiscalYear),
            },
            body: 'report'
        },
        {
            resource: `efatura_sales_by_issuer`,  
            args: {
                projectId: (args.projectId),
                fiscalYear: (args.fiscalYear),   
            },
            body: 'report'
        },
        {
            resource: `efatura_purchases_by_issuer`,
            args: {
                projectId: (args.projectId),
                fiscalYear: (args.fiscalYear), 
            },
            body: 'report'
        },
        {
			resource: 'audit_summary',
			args: {
				projectId: (args.projectId),
				fiscalYear: (args.fiscalYear),
				modulePrefix: 'saftpt',
                importId: args.importId
			},
			body: 'severity, count'
		},
    ]
}

const efaturaOvewiewData = (path, navigation, exact, title) => ({
    path,
    navigation,
    component: AppByFile,
    queries,
    exact,
    title: [title, `label(param(entity))`],
    components: [
        {
			component: AuditSummaryWidget,
			modifiers: ['--root-margin-bottom-normal'],
			props: {
				icon: 'audit-icon',
				title: 'label(Audit Results)',
				summary: 'query(8).list',
				link: {
                    to: 'link(/:projectId/:fiscalYear/saft_pt/audit)',
                    name: 'label(See All)'
                },
			}
		},
        {
            component: Widget,
            modifiers: ['--root-margin-bottom-normal'],
            props: {
                icon: 'sales',
                title: ["sales_invoices kpis"],
                children: [
                    {
                        component: Grid,
                        modifiers: ['--grid-border-inline'],
                        props: {
                            columnsTemplate: [1],
                            items: [
                                {
                                    data: [
                                        {
                                            name: 'label(Gross Total)',
                                            value: 'currency(query(0).invoicesGrossTotal)',
                                            icon: 'gross-sales'
                                        },
                                        {
                                            name: 'label(Net Total)',
                                            value: 'currency(query(0).invoicesNetTotal)',
                                            icon: 'net-sales'
                                        },
                                        {
                                            name: 'label(Total Tax Amount)',
                                            value: 'currency(query(0).invoicesTaxAmount)',
                                            icon: 'net-sales'
                                        },
                                        {
                                            name: 'label(Invoice Avg Net)',
                                            value: 'currency(query(0).invoicesNetAverage)',
                                            icon: 'kpi-icon'
                                        },
                                        {
                                            name: 'label(Invoice Max Value)',
                                            value: 'currency(query(0).invoicesMaxValue)',
                                            icon: 'kpi-icon'
                                        }
                                    ]

                                }
                            ],
                            cards: [charts.KpisLegend]
                        }
                    }
                ]
            }
        },
        {
            component: Widget,
            modifiers: ['--root-margin-bottom-normal'],
            props: {
                icon: 'sales',
                title: ["purchases_invoices kpis"],
                children: [
                    {
                        component: Grid,
                        modifiers: ['--grid-border-inline'],
                        props: {
                            columnsTemplate: [1],
                            items: [
                                {
                                    data: [
                                        {
                                            name: 'label(Gross Total)',
                                            value: 'currency(query(1).invoicesGrossTotal)',
                                            icon: 'gross-sales'
                                        },
                                        {
                                            name: 'label(Net Total)',
                                            value: 'currency(query(1).invoicesNetTotal)',
                                            icon: 'net-sales'
                                        },
                                        {
                                            name: 'label(Total Tax Amount)',
                                            value: 'currency(query(1).invoicesTaxAmount)',
                                            icon: 'net-sales'
                                        },
                                        {
                                            name: 'label(Invoice Avg Net)',
                                            value: 'currency(query(1).invoicesNetAverage)',
                                            icon: 'kpi-icon'
                                        },
                                        {
                                            name: 'label(Invoice Max Value)',
                                            value: 'currency(query(1).invoicesMaxValue)',
                                            icon: 'kpi-icon'
                                        }
                                    ]

                                }
                            ],
                            cards: [charts.KpisLegend]
                        }
                    }
                ]
            }
        },
        {
            component: Widget,
            modifiers: ['--root-margin-bottom-small'],
            props: {
                icon: 'comparation',
                title: 'label(Sales vs Purchases)',
                children: [
                    {
                        component: Grid,
                        modifiers: ['--grid-border-inline', '--grid-margin-none', '--grid-padding-none'],
                        props: {
                            columnsTemplate: [1],
                            aspect: ['25x8'],
                            items: [
                                {
                                    currency: true,
                                    data: ['query(2).report', 'query(3).report'],
                                    dataLabels: ['label(Sales)', 'label(Purchases)'],
                                    links: [
                                        {
                                            to: ['param(projectId)', 'param(fiscalYear)', 'efatura', 'sales_invoices'],
                                            filterColumn: 'Period',
                                        },
                                        {
                                            to: ['param(projectId)', 'param(fiscalYear)', 'efatura', 'purchases_invoices'],
                                            filterColumn: 'Period',
                                        },
                                    ]
                                }
                            ],
                            cards: [charts.BarChart]
                        }
                    }
                ]
            }
        },
        {
            component: Grid,
            modifiers: [
                '--grid-padding-none',
                '--root-margin-bottom-normal'
            ],
            props: {
                columnsTemplate: [1, 1],
                items: [
                    {
                        title: 'label(Sales by Issuer Activity)',
                        icon: 'top',
                        children: [
                            {
                                component: Grid,
                                modifiers: [
                                    '--root-margin-bottom-normal',
                                    '--cardholder-background-color-widget',
                                    '--mobile--cardholder-aspect-ratio-4x5'
                                ],
                                props: {
                                    columnsTemplate: [1],
                                    items: [
                                        {
                                            data: 'query(4).report',
                                            currencyType:{
												localeCurrency : 'sk-Sk',
												currency: 'EUR'
											}
                                        },
                                    ],
                                    cards: [charts.PieChart]
                                }
                            },
                        ]
                    },
                    {
                        title: 'label(Purchases by Issuer Activity)',
                        icon: 'top',
                        children: [
                            {
                                component: Grid,
                                modifiers: [
                                    '--root-margin-bottom-normal',
                                    '--cardholder-background-color-widget',
                                    '--mobile--cardholder-aspect-ratio-4x5'
                                ],
                                props: {
                                    columnsTemplate: [1],
                                    items: [
                                        {
                                            data: 'query(5).report',
                                            chartColor: "--chart-secondary-color",
                                            currencyType:{
												localeCurrency : 'sk-Sk',
												currency: 'EUR'
											}
                                        },
                                    ],
                                    cards: [charts.PieChart]
                                }
                            },
                        ]
                    },
                ],
                cards: [Widget]
            }
        },
        {
            component: Grid,
            modifiers: [
                '--root-margin-bottom-normal',
                '--cardholder-background-color-widget',
                '--mobile--cardholder-aspect-ratio-4x5'
            ],
            props: {
                columnsTemplate: [1, 1],
                aspect: ['5x4', '5x4'],
                items: [
                    {
                        title: 'label(Sales By Issuer (Net))',
                        data: ['query(6).report'],
                        path: "/:locale(pt|en|lt|es)?/:projectId/:fiscalYear([0-9]{4}|current)/efatura/master_data/customers/:id"
                    },
                    {
                        title: 'label(Purchases By Issuer (Net))',
                        data: ['query(7).report'],
                        path: "/:locale(pt|en|lt|es)?/:projectId/:fiscalYear([0-9]{4}|current)/efatura/master_data/suppliers/:id"
                    },
                ],
                cards: [cards.TopsCard, cards.TopsCard]
            }
        },
    ]

})


export default efaturaOvewiewData;