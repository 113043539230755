import { format } from "../../../utils";

const mapCard = (json) => {
	const { Quantity, TaxableValue, TaxCode, TaxType, TaxPercentage } = json;
	return [
		[{ labels: ["Quantity"], value: Quantity, type: "string" }],
		[
			{
				labels: ["Taxable Value (₹)"],
				value: format(TaxableValue, "normal", null, 2),
				type: "string",
			},
		],
		[{ labels: ["Tax Code"], value: TaxCode, type: "string" }],
		[{ labels: ["Tax Type"], value: TaxType, type: "string" }],
		[
			{
				labels: ["Tax Percentage"],
				value: format(TaxPercentage, "normal", null, 2),
				type: "percentage",
			},
		],
	];
};

const mapper = (json, card) => {
	return {
		...json,
		name: json.name,
		shortName: json.name,
		card: card ? mapCard(json) : [],
	};
};

const header = [
	{
		label: ["Quantity"],
		align: "left",
		columnName: "Quantity",
		sortable: false,
	},
	{
		label: ["Taxable Value (₹)"],
		align: "left",
		columnName: "TaxableValues",
		sortable: false,
	},
	{
		label: ["Tax Code"],
		align: "left",
		columnName: "TaxCode",
		sortable: false,
	},

	{
		label: ["Tax Type"],
		align: "left",
		columnName: "TaxType",
		sortable: false,
	},
	{
		label: ["Tax Percentage"],
		align: "left",
		columnName: "TaxPercentage",
		sortable: false,
	},
];

const pushcaseLinesMapper = {
	map: mapper,
	header,
};

export default pushcaseLinesMapper;
