import React, { useEffect, useState } from "react";
import { Redirect, withRouter } from "react-router-dom";
import { renderRoutes, matchRoutes } from "react-router-config";
import { connect } from "react-redux";
import { parse } from "query-string";

import { GlobalProvider as ColbiUIProvider } from "colbi_web_ui/lib/state/GlobalProvider";
import { GlobalProvider } from "../../state/globalProvider";

import {
	mapProperties,
	hydrateRoute,
	stringifyFilter,
} from "colbi_web_ui/lib/utils";
import AsideMenu from "colbi_web_ui/lib/components/layout/AsideMenu/AsideMenu";

import {
	FreshDeskWidget,
	ImageRender,
	LoadingIndicator,
} from "colbi_web_ui/lib/components";
import Header from "colbi_web_ui/lib/components/layout/Header/Header";
import ModalController from "../App/ModalController/ModalController";
import DialogController from "../App/DialogController/DialogController";
import { UserRoles } from "../../enums/userRoles";
import {
	logout,
	pushModal,
	pushDialog,
	popModal,
	uploadAction,
	toggleMenu,
	setLocale,
	auditNoteSubmit,
	removeModal,
	setDataFileStatusAction,
	deleteFilePermanentlyAction,
	deleteInvalidFilePermanentlyAction,
	reprocessRepositoryAction,
	downloadAction,
	downloadFilesAction,
	downloadFileAction,
	hideFileAction,
} from "../../store/actions";
import {
	filteredNavigationByModules,
	filteredQueriesByModules,
} from "./navigation";
import I18n from "../../I18n";
import theme from "../../style/_theme.json";
import proxy from "../../routes/proxy";
import {
	decryptSession,
	checkRoles,
} from "colbi_web_ui/lib/utils/roleValidator";
import styles from "./Repository.module.sass";
import { format } from "../../utils";
import socketIOClient from "socket.io-client";
import configsByTheme from "../../enums/configsByTheme";
import { getOrganizationName } from "../../utils/camelize";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { mapProjectsByOrganization } from "../../utils/mapProjectsByOrganization";

const tabs = [
	{
		section: "activity",
		label: "Activity",
		createType: "Activity",
	},
	{
		section: "folders",
		label: "Folders",
		createType: "Folder",
	},
];

const appTheme =
	process && process.env && process.env.REACT_APP_COLBI_THEME
		? process.env.REACT_APP_COLBI_THEME.toLocaleLowerCase()
		: "";
const asideMenuLogo = configsByTheme(appTheme)["icon"];
const localesByAppTheme = configsByTheme(appTheme)["locales"];
const logoHorizontalDisplay = configsByTheme(appTheme)['logoHorizontalDisplay'] || false

const Provider = ({ children, ...props }) => {
	return (
		<GlobalProvider {...props}>
			<ColbiUIProvider {...props}>{children}</ColbiUIProvider>
		</GlobalProvider>
	);
};

const logo = () => {
	return (
		process &&
		process.env && (
			<ImageRender
				icon={`${appTheme && appTheme.length ? asideMenuLogo : "logo"}`}
			/>
		)
	);
};

const Repository = (props) => {
	const {
		user,
		uploads,
		fetch,
		match,
		setLocale,
		location,
		toggleMenu,
		logout,
		history,
		pushModal,
		popModal,
		menu,
		reload,
		pushDialog,
		uploadFiles,
		submitAuditNote,
		route,
		setDataFileStatus,
		deleteFilePermanently,
		deleteInvalidFilePermanently,
		hideFile,
		downloadFile,
		reprocessRepository,
		downloadFiles,
		download,
		modules,
	} = props;

	useEffect(() => {
		const pop = history.action === "POP";
		if (!pop && !fetch.activity) {
			if (location.hash) {
				const scrollElement = window.document.querySelector(location.hash);
				if (scrollElement) {
					const headerOffset = 70;

					const previousRect =
						scrollElement.previousElementSibling.getBoundingClientRect();
					let stuckOffset = previousRect.height;
					const elementPosition = scrollElement.offsetTop;
					const offsetPosition =
						elementPosition - headerOffset - stuckOffset - 100;

					setTimeout(() => {
						window.scrollTo({
							top: offsetPosition,
							behavior: "smooth",
						});
					}, 0);
				}
			} else if (location.search.search("page") === -1) {
				setTimeout(() => {
					window.scrollTo(0, 0);
				}, 0);
			}
		}
	}, [
		fetch.activity,
		location.hash,
		location.pathname,
		location.search,
		history.action,
	]);

	const locale = match.params.locale || "en";
	const i18n = I18n.use(locale);

	useEffect(() => {
		setLocale(locale);
	}, [locale, setLocale]);

	const [hover, setHover] = useState(false);
	let userInformation = decryptSession(user);
	const socketOptions = {
		reconnection: false,
	    path: "/socket.io"
	};
	useEffect(() => {
		const socket = socketIOClient(socketOptions);
		socket.on(`${match.params.projectId}_notifications`, (data) => {
			setNotifications((prevState) => [...prevState, data]);
		});
		socket.on("connect_error", (err) =>
			console.log("SocketIO cannot reach the server")
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const [notifications, setNotifications] = useState([]);

	if (!userInformation) {
		return <Redirect to={"/login"} push />;
	}

	if (userInformation && parseInt(userInformation.mysqlPasswordReset)) {
		return <Redirect to={`/${locale}/reset_password`} />;
	}

	const projectId = match.params.projectId;

	const projectsByOrganization = mapProperties(
		{ organizations: "query(0).list", filter: "param(filter)" },
		fetch.results,
		fetch.status,
		Repository.queries(match.params),
		props,
		match.params,
		i18n,
		locale
	);

	const currentOrganization = (projectsByOrganization.organizations || []).find(
		({ projects }) => (projects || []).some(({ id }) => id === projectId)
	);

	const version = (fetch.results[`${locale}_application_version`] || "")
		.version;
	
	const isToDisplaySupportIcon = (fetch.results[`${locale}_is_to_display_support_icon`] || "true").toLocaleLowerCase() === "true";

	const ticketSubjectEnvironment =
		fetch.results[`${locale}_get_environment_to_subject`] || "";

	const attachmentData = (
		fetch.results[`${locale}_attachment_data_projectId_${projectId}`] || {
			list: [],
		}
	).list;
	const attachmentDataNav = attachmentData.map(({ fiscalYear, count }) => {
		return {
			name: `${i18n`${fiscalYear}`} (${count})`,
			to: `/:projectId/repository/attachments/${fiscalYear}`,
		};
	});

	if (!projectId) {
		return <Redirect to={`${locale ? `/${locale}` : ""}/`} />;
	}

	const project = currentOrganization
		? (currentOrganization.projects || []).find(({ id }) => id === projectId) ||
		  {}
		: {};

	const queryParams = parse(location.search) || {};
	const routeParams = match.params || {};
	const params = {
		...queryParams,
		...routeParams,
	};

	const currentSection = params.section;
	const hydrated = hydrateRoute(route.path, { ...params });

	if (userInformation && checkRoles(userInformation, [UserRoles.BACKOFFICE])) {
		return <Redirect to={`/${locale}/settings/`} />;
	}

	if (!currentSection) {
		return <Redirect to={`${hydrated}${tabs[0].section}/status`} replace />;
	}
	if (params.section === "activity" && !params.fiscalYear) {
		return <Redirect to={`${hydrated}status`} replace />;
	}

	const folderData = (
		fetch.results[`${locale}_folder_data_projectId_${projectId}`] || {
			list: [],
		}
	).list;

	const folderDataNav = folderData.map(
		({ fileType, fileTypeCount, fileTypeFiscalYears }) => {
			const yearsByType = (fileTypeFiscalYears || []).map(
				({ fiscalYear, count }) => {
					return {
						name: `${i18n`${fiscalYear}`} (${format(
							count,
							"normal",
							null,
							0
						)})`,
						level: 2,
						to: `/:projectId/repository/folders/${fiscalYear}/${fileType}`,
					};
				}
			);

			return {
				name: `${i18n`${fileType}`} (${format(
					fileTypeCount,
					"normal",
					null,
					0
				)})`,
				level: 1,
				navigation: [...yearsByType],
			};
		}
	);

	const repositoryStatus = fetch.results[
		`${locale}_repository_status_fiscalYear_${match.params.fiscalYear}_projectId_${projectId}`
	] || { status: 1 };

	const routeQueries = (route.queries && route.queries(params)) || [];

	const children = (route.components || []).map((c, index) => {
		const Component = c.component;

		const componentProps = mapProperties(
			match.isExact ? c.props : { ...c.props, checkbox: false },
			(fetch || {}).results,
			(fetch || {}).status,
			routeQueries,
			props,
			params,
			i18n,
			locale
		);
		return (
			<Component
				key={index}
				modifiers={c.modifiers}
				{...componentProps}
				active={fetch.activity}
			/>
		);
	});

	const branch = matchRoutes(
		[route, ...(route.routes || [])],
		location.pathname
	);

	const branchQueries = branch.reduce((allQueries, { route }) => {
		const innerQueries = route.queries ? route.queries(params) : [];
		allQueries = [...allQueries, ...innerQueries];
		return allQueries;
	}, []);

	const downloadableQueries = branchQueries.filter(
		({ downloadable }) => downloadable
	);

	const goto = (page, filters, anchor) => {
		const pageUrl = page
			? (typeof page.join === "function" ? page.join("/") : page).replace(
					/\/+/g,
					"/"
			  )
			: null;
		const urlFilters = filters ? stringifyFilter(filters) : "";
		const localePrefixed = pageUrl
			? `${locale ? `/${locale}${!pageUrl.match(/^\//) ? "/" : ""}` : ""}`
			: null;
		const gotoPage = pageUrl
			? `${localePrefixed}${pageUrl}${
					urlFilters.length ? `?filter=${urlFilters}` : ""
			  }`
			: `${location.pathname}?filter=${urlFilters}${
					anchor ? `#${anchor}` : ""
			  }`;
		history.push(gotoPage);
	};

	const handleHover = (state) => {
		setHover(state);
	};

	const metadata = getOrganizationName(currentOrganization, project);

	const mapOrganizationsAndProjectsForMenu = (
		projectsByOrganization.organizations || []
	).reduce((acc, organization) => {
		if (
			organization.projects &&
			organization.projects.length &&
			organization.organizationExpired === 0 &&
			organization.organizationActive === 1
		) {
			acc.push({
				type: "organization",
				name: organization.name,
			});
			const tempProjects =
				mapProjectsByOrganization(organization, projectId) || [];
			acc.push(...tempProjects.filter((elem) => elem.to));
		}

		return acc;
	}, []);

	let tempNavigation = filteredNavigationByModules(modules || []);
	const mappedProject =
		mapOrganizationsAndProjectsForMenu.find((elem) => elem.id === projectId) ||
		{};

	if (
		mappedProject &&
		mappedProject.fiscalYears &&
		Object.keys(mappedProject.fiscalYears).length !== 0 &&
		mappedProject.to
	) {
		tempNavigation.push({
			name: "label(Overview)",
			to: `${mappedProject.to}`,
			exact: true,
			icon: "back",
		});
	}

	const navigationNav = mapProperties(
		{ navigation: tempNavigation },
		(fetch || {}).results,
		(fetch || {}).status,
		Repository.queries(params),
		{
			...props,
			folderData: folderDataNav,
			attachmentData: attachmentDataNav,
		},
		params,
		i18n,
		locale
	);

	return (
		<Provider
			user={userInformation}
			proxy={proxy}
			theme={theme}
			actions={{
				pushModal,
				pushDialog,
				popModal,
				uploadFiles,
				submitAuditNote,
				removeModal,
				setDataFileStatus,
				deleteFilePermanently,
				deleteInvalidFilePermanently,
				hideFile,
				reprocessRepository,
				logout,
				downloadFiles,
				downloadFile,
				setLocale: (locale) => {
					history.push(`/${locale}${history.location.pathname.substring(3)}`);
				},
				goto,
				download,
			}}
			i18n={i18n}
			locale={locale || localesByAppTheme[0].id}
			locales={localesByAppTheme}
			reload={reload}
			params={params}
			history={history}
			location={location}
			repositoryStatus={repositoryStatus}
			uploads={uploads}
		>
			<div className={styles["app"]}>
				<div
					onMouseEnter={() => (menu === "collapsed" ? handleHover(true) : null)}
					onMouseLeave={() =>
						menu === "collapsed" ? handleHover(false) : null
					}
					className={`${styles["aside"]} ${
						menu === "expanded" ? "" : styles["is-collapsed"]
					}`}
				>
					<AsideMenu
						logo={logo}
						title={menu === "expanded" || hover ? i18n`Repository` : " "}
						subtitle={
							project && project.description && (menu === "expanded" || hover)
								? project.description
								: ""
						}
						navigation={navigationNav.navigation}
						organizations={mapOrganizationsAndProjectsForMenu}
						version={version}
						project={project}
						organization={currentOrganization}
						expanded={menu === "expanded"}
						toggleMenu={toggleMenu}
						hover={hover}
						setHover={setHover}
						settingsAction={checkRoles(userInformation, [
							UserRoles.ADMIN,
							UserRoles.OWNER,
							UserRoles.PP_SUPPORT,
							UserRoles.SUPPORT,
						])}
						reservedRights={configsByTheme(appTheme)["reservedRights"]}
                		applicationName={configsByTheme(appTheme)["loginApplicationName"]}
						logoHorizontalDisplay={logoHorizontalDisplay}
						showAsideMenuButtonRow={true}
					/>
				</div>
				<div className={styles["main"]}>
					<Header
						className={styles["header"]}
						logout={logout}
						proxy={proxy}
						theme={theme}
						title={
							params.section === "folders"
								? [i18n`${params.section || ""}`, params.fiscalYear]
								: i18n`${params.section || ""}`
						}
						displayInfo={i18n`information message data upload`}
						toggleMenu={toggleMenu}
						settingsAction={checkRoles(userInformation, [
							UserRoles.ADMIN,
							UserRoles.OWNER,
							UserRoles.PP_SUPPORT,
							UserRoles.SUPPORT,
						])}
						downloadableQueries={downloadableQueries}
						expanded={menu !== "expanded"}
						project={project}
						navigation={navigationNav.navigation}
						organizations={projectsByOrganization}
						menuLogo={logo}
						notifications={notifications.length}
						notificationList={notifications}
						menuTitle={i18n`Repository`}
						displaySupportIcon={isToDisplaySupportIcon}
					/>
					<main
						className={`${styles["content"] || ""} ${
							styles["foreground"] || ""
						}`}
					>
						{fetch.activity && (
							<LoadingIndicator
								className={`${styles["loading-indicator"]} ${
									menu === "expanded" ? "" : styles["small-margin"]
								}`}
							/>
						)}
						{children}

						{renderRoutes(route.routes)}
					</main>
				</div>
				<ModalController popModal={popModal} />
				<DialogController i18n={i18n} />
				<FreshDeskWidget
					environment={`${ticketSubjectEnvironment} - ${
						(project || {}).taxRegistrationNumber
					}`}
					emailFrom={userInformation.username}
					name={userInformation.name}
					metadata={metadata}
				/>
				<ToastContainer />
			</div>
		</Provider>
	);
};

Repository.queries = (args, modules) => {
	const queries = filteredQueriesByModules(modules);

	const deepCopyQueries = JSON.parse(JSON.stringify(queries));
	if (deepCopyQueries) {
		//needed to change arguments to the respective value
		deepCopyQueries.forEach((query) =>
			Object.keys(query.args).forEach((k) =>
				query.args[k]
					? query.args[k]
					: (query.args[k] = args[k] || query.args[k])
			)
		);
	}

	return [
		{
			resource: "user_projects_organizations",
			body: "id,name,projects{id,name,fiscalYears,status,taxRegistrationNumber,active,projectExpired,modules{navigation_by_import_id,name,default_route,default_module}},organizationExpired, organizationActive",
		},
		{
			resource: "folder_data",
			args: {
				projectId: args.projectId,
			},
			body: "fileType, fileTypeCount, fileTypeFiscalYears{fiscalYear,count}",
		},
		{
			resource: "repository_status",
			args: {
				projectId: args.projectId,
				fiscalYear: args.fiscalYear,
			},
			body: "status",
		},
		{
			resource: "application_version",
			body: "version",
		},
		{
			resource: "is_to_display_support_icon",
		},
		{
			resource: "get_environment_to_subject",
		},
		...deepCopyQueries,
	];
};

export default connect(
	({ user, fetch, menu, uploads }) => ({
		user,
		fetch,
		menu,
		uploads,
	}),
	{
		logout,
		pushModal,
		popModal,
		removeModal,
		toggleMenu,
		setLocale,
		pushDialog,
		setDataFileStatus: setDataFileStatusAction,
		deleteFilePermanently: deleteFilePermanentlyAction,
		downloadFiles: downloadFilesAction,
		hideFile: hideFileAction,
		downloadFile: downloadFileAction,
		deleteInvalidFilePermanently: deleteInvalidFilePermanentlyAction,
		reprocessRepository: reprocessRepositoryAction,
		uploadFiles: uploadAction,
		submitAuditNote: auditNoteSubmit,
		download: downloadAction,
	}
)(withRouter(Repository));
