import { format } from "colbi_web_ui/lib/utils"

const mapCard = (json) => {
	const {
        productCode,
        productDescription,
		productGroup,
        quantityCredit,
        productType,
        debitAmount,
        creditAmount,
        documentTotal,
		totalNumberDocuments,
	} = json

	return [
		[
			{ labels: ['productCode'], value: productCode, type: 'string', basis: '20%'}

		],
		[
			{ labels: ['productDescription'], value: productDescription, type: 'string' }

		],
		[
			{ labels: ['productGroup'], value: productGroup, type: 'string' }
		],
		[
			{ labels: ['productType'], value: productType, type: 'string', align: 'center', basis: "10%"}

		],
		[
			{ labels: ['totalNumberDocuments'], value: totalNumberDocuments, type: 'currency' }
		],
		[
			{ labels: ['quantityCredit'], value: quantityCredit, type: 'currency' }

		],
		[
			{ labels: ['debitAmount'], value: format(debitAmount), type: 'currency' }

		],
		[
			{ labels: ['creditAmount'], value: format(creditAmount), type: 'currency'}

		],
		[
			{ labels: ['documentTotal'], value: format(documentTotal), type: 'currency' }

		],
	]
}

const header = [
	{ label: ['Product Code'], align: 'left', basis: '20%'},
	{ label: ['Product Description'], align: 'left'},
	{ label: ['Product Group'], align: 'left'},
	{ label: ['Product Type'], align: 'center', basis: "10%"},
	{ label: ['Total Documents'], align: 'right', columnName: "total_number_documents", sortable: true },
	{ label: ['Quantity'], align: 'right', columnName: "quantity", sortable: true },
	{ label: ['Debit Amount'], align: 'right', columnName: "debit_amount", sortable: true, totalizerName: "total_debit_amount" },
	{ label: ['Credit Amount'], align: 'right', columnName: "credit_amount", sortable: true, totalizerName: "total_credit_amount" },
	{ label: ['Document Total'], align: 'right', columnName: "document_total", sortable: true, totalizerName: "total_document_total" },
]

const mapper = (json, card) => {
	return {
		...json,
		name: json.name,
		shortName: json.name,
		card: card ? mapCard(json) : [],
		link: {
			to: `/:locale(pt|en|lt|es)?/:projectId/:fiscalYear([0-9]{4}|current)/:importId/:modulePrefix/master_data/products/${json.rowKey}`,
		}
	}
}
const productsalesMapper = {
	map: mapper,
	header
}
export default productsalesMapper;