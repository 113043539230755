import { StackLayout } from "../layouts";
import { List, rows, Filters } from "colbi_web_ui/lib/components";
import { parseFilter } from "colbi_web_ui/lib/utils";
import theme from "colbi_web_ui/lib/style/_theme.json";
import AppByFile from "../controllers/AppByFile/AppByFile";
import ExportButton from "../components/ExportButton/ExportButton";
import { checkForCustomFragment } from "../utils/queryUtils";

const queries = (args = {}) => {
	const entityResource = args.modulePrefix ?  `${args.modulePrefix}_${args.entity}` : args.entity
	const result = [];

	const customEntityFragment = checkForCustomFragment(
		args.modulePrefix,
		args.entity
	);

	if(customEntityFragment){
		result.push(
			{
			resource: entityResource,
			args: {
				projectId: args.projectId,
				fiscalYear: args.fiscalYear,
				importId: args.importId,
				modulePrefix: args.modulePrefix,
				filter: args.filter
					? { type: "[FilterInput!]", value: parseFilter(args.filter) }
					: undefined,
				sort: args.sort
					? { type: "[SortInput!]", value: parseFilter(args.sort) }
					: undefined,
				page: parseInt(args.page, 10) || 0,
			},
			body: `__typename, items{ ...${entityResource} }, hasMore, total, totalizers, filters(projectId:$projectId,fiscalYear:$fiscalYear,modulePrefix:$modulePrefix,importId:$importId){ prop, name, type, list, disableOperators, mandatory }`,
			downloadable: true,
			xlsxTranslation: entityResource
		},
		)
	}else{
		result.push({
			resource: 'generic_views',
			args: {
				projectId: args.projectId,
				fiscalYear: args.fiscalYear,
				importId: args.importId,
				modulePrefix: args.modulePrefix,
				entity: args.entity,
				filter: args.filter
					? { type: "[FilterInput!]", value: parseFilter(args.filter) }
					: undefined,
				sort: args.sort
					? { type: "[SortInput!]", value: parseFilter(args.sort) }
					: undefined,
				page: parseInt(args.page, 10) || 0,
			},
			body: `__typename, items, headerItems, hasMore, total, totalizers, entity, filters(projectId:$projectId,fiscalYear:$fiscalYear,modulePrefix:$modulePrefix,entity:$entity,importId:$importId){ prop, name, type, list, disableOperators, mandatory }`,
		},
		{
			resource: 'generic_views_export',
			args: {
				projectId: args.projectId,
				fiscalYear: args.fiscalYear,
				importId: args.importId,
				modulePrefix: args.modulePrefix,
				entity: args.entity,
				filter: args.filter
					? { type: "[FilterInput!]", value: parseFilter(args.filter) }
					: undefined,
				sort: args.sort
					? { type: "[SortInput!]", value: parseFilter(args.sort) }
					: undefined,
				page: parseInt(args.page, 10) || 0,
			},
			body: `__typename, items, headerItems, hasMore, total, totalizers, entity, filters(projectId:$projectId,fiscalYear:$fiscalYear,modulePrefix:$modulePrefix,entity:$entity,importId:$importId){ prop, name, type, list, disableOperators, mandatory }`,
			downloadable: true,
			downloadOnly: true,
			xlsxTranslation: args.entity,
		})
	}

	return result.filter((r) => !!r.resource);
};

const genericReportsByFile = (
	path,
	navigation,
	exact,
	title,
	navByImportId = false
) => ({
	path,
	navigation,
	component: AppByFile,
	queries,
	exact,
	navByImportId,
	title: [title, "label(param(entity))"],
	components: [
		{
			component: Filters,
			props: {
				filter: "param(filter)",
				availableFilters: "query(0).filters",
				stickyTop: parseInt(theme.layout.$headerHeight, 10),
				anchor: "results",
			},
		},
		{
			component: ExportButton,
			props: {
				data: "query(0).list",
			},
		},
	],
	routes: [
		{
			path,
			exact: true,
			queries,
			component: StackLayout,
			components: [
				{
					component: List,
					modifiers: ["--row-style-alternate"],
					props: {
						basePath: path,
						items: "query(0).list",
						sort: "param(sort)",
						cardKey: "param(entity)",
						card: rows.EntityCard,
						header: "query(0).header",
						metadata: "query(0).metadata",
						page: "param(page)",
						status: "status(0)",
						pageSize: "param(pageSize)",
						footer: "query(0).footer",
						totalizers: "query(0).totalizers",
					},
				},
			],
		},
	],
});

export default genericReportsByFile;
