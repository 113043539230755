import { date } from '../../../utils'

const mapDetails = (json) => {
    const {
        movementReference ,movementDate ,movementPostingDate ,movementType,systemId,period
    } = json;
    return [
        [
            {label: 'Movement Reference', value: movementReference, type: 'string'},
            {label: 'Movement Date', value: date(movementDate), type: 'date'},
            {label: 'Movement Posting Date', value: date(movementPostingDate), type: 'date'},
            {label: 'Movement Type', value: movementType, type: 'string'}
        ],
        [
        ],
        [
            {label: 'system Id', value: systemId, type: 'string'},
            {label: 'Period', value: period, type: 'string'}
        ]
    ]
};

const mapCard = (json) => {
    const {
        movementReference ,movementDate ,movementType,period
    } = json;

    return [
        [
            {labels: ['movementReference'], value: `${movementReference}`, type: 'string'}
        ],
        [
            {labels: ['Movement Date'], value: date(movementDate), type: 'date'}
        ],
        [
            {labels: ['Movement Type'], value: `${movementType}`, type: 'string'}
        ],
        [
            {labels: ['Period'], value: `${period}`, type: 'number', align: 'right'}
        ]
    ]
};


const mapper = (json, card, details) => {

    return {
        ...json,
        name: json.description,
        shortName: json.movementReference,
        details: details ? mapDetails(json) : [],
        card: card ? mapCard(json) : []
    }
};

const header = [
    {label: ['Movement Reference'], align: 'left', columnName: "movement_reference", sortable: true},
    {label: ['Movement Date'], align: 'center', columnName: "movement_date", sortable: true},
    {label: ['Movement Type'], align: 'left', columnName: "movement_type", sortable: true},
    {label: ['Period'], align: 'right', columnName: "period", sortable: true}
];
const movementofgoodMapper = {
    map: mapper,
    header
}

export default movementofgoodMapper;