import { date } from "../../../utils";

const header = [
	{ label: ["Movement Reference"], align: "left" },
	{ label: ["Delivery Date"], align: "center" },
	{ label: ["Delivery Period From Date"], align: "center" },
	{ label: ["Delivery Period To Date"], align: "center" }
];

const mapCard = json => {
	const {
		movementReference,
		deliveryDate,
		deliveryPeriodFromDate,
		deliveryPeriodToDate
	} = json;

	return [
		[{ labels: ["movementRef"], value: movementReference, type: "string" }],
		[{ labels: ["deliveryDate"], value: date(deliveryDate), type: "date" }],
		[
			{
				labels: ["deliveryPeriodFromDate"],
				value: date(deliveryPeriodFromDate),
				type: "date"
			}
		],
		[
			{
				labels: ["deliveryPeriodToDate"],
				value: date(deliveryPeriodToDate),
				type: "date"
			}
		]
	];
};

const mapper = (json, card) => {
	const name = json.invoice_line_id || json.purchase_invoice_line_id;

	return {
		...json,
		__typename: json.__typename,
		id: json.id,
		name,
		shortName: name,
		card: card ? mapCard(json) : []
	};
};

const deliveryMapper = {
	map: mapper,
	header
};
export default deliveryMapper;
