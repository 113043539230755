import AppByFile from "../controllers/AppByFile/AppByFile";
import {
	AuditSummaryWidget,
} from "colbi_web_ui/lib/components";
const queries = (args = {}) => {
	return args.fiscalYear
		? [
			{
				//to defer old core of the new core -> full old core navigation
				resource: "nc_audit_summary",
				args: {
					projectId: args.projectId,
					fiscalYear: args.fiscalYear,
					importId: args.importId,
					modulePrefix: args.modulePrefix,
				},
				body: "severity, count",
			},
			{
				resource: "generic_overview",
				args: {
					projectId: args.projectId,
					fiscalYear: args.fiscalYear,
					importId: args.importId,
					modulePrefix: args.modulePrefix,
				},
			},
			{
				resource: "generic_overview_export",
				args: {
					projectId: args.projectId,
					fiscalYear: args.fiscalYear,
					importId: args.importId,
					modulePrefix: args.modulePrefix,
				},
				body: 'report',
				downloadable: true,
				downloadOnly: true,
				xlsxTranslation: "overview",
			},
		]
		: [];
};

const genericOverview = (path, navigation, exact, title, navByImportId = false) => ({
	path,
	navigation,
	component: AppByFile,
	queries,
	exact,
	navByImportId,
	title: [title],
	showCsvButton: true,
	isOverview: true,
	components: [
		{
			component: AuditSummaryWidget,
			modifiers: ["--root-margin-bottom-normal"],
			props: {
				icon: "audit-icon",
				title: "label(Audit Results)",
				summary: "query(0).list",
				link: {
					to: `/:projectId/:fiscalYear/:importId/nc_audit`,
					name: "label(See All)",
					createLink: true
				},
				overviewSummaryWidget: true
			},
		},
	],
});

export default genericOverview;
