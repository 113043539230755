import { format, date } from "../../../utils";

const mapDetails = json => {
	const {
		transactionType,
		period,
		date: dateAssetTransaction,
		description,
		transactionAmount,
		bookValueOnTransaction,
		acquisitionAndProductionCostsOnTransaction,
		transactionRowKey,
		assetTransactionId,
		assetRowKey,
		assetID,
		supplierRowKey,
		supplierID
	} = json;
	return [
		[
			{ label: "Type", value: transactionType, type: "string" },
			{ label: "Period", value: period, type: "number" },
			{ label: "Date", value: date(dateAssetTransaction), type: "date" },
			{ label: "Description", value: description, type: "string" }
		],
		[
			{ label: "Amount", value: format(transactionAmount), type: "currency" },
			{
				label: "Book Value",
				value: format(bookValueOnTransaction),
				type: "currency"
			},
			{
				label: "Acquisition and Production Costs",
				value: format(acquisitionAndProductionCostsOnTransaction),
				type: "currency"
			}
		],
		[
			transactionRowKey
				? {
						label: "Transaction",
						value: {
							id: transactionRowKey,
							name: assetTransactionId,
							__typename: "Transaction"
						},
						type: "object"
				  }
				: {
						label: "Transaction",
						value: assetTransactionId || "N/A",
						type: "string"
				  },

			assetRowKey
				? {
						label: "Asset",
						value: { id: assetRowKey, name: assetID, __typename: "Asset" },
						type: "object"
				  }
				: { label: "Asset", value: assetID || "N/A", type: "string" },

			supplierRowKey
				? {
						label: "Supplier",
						value: {
							id: supplierRowKey,
							name: supplierID,
							__typename: "Supplier"
						},
						type: "object"
				  }
				: { label: "Supplier", value: supplierID || "N/A", type: "string" }
		]
	];
};

const header = [
	{ label: ["Description"], align: "left" },
	{ label: ["[Type]"], align: "left" },
	{ label: ["ID"], align: "left" },
	{ label: ["Period"], align: "center" },
	{ label: ["Date"], align: "center" },
	{ label: ["Supplier"], align: "center" },
	{ label: ["Transaction"], align: "center" },
	{ label: ["Asset Transaction Amount"], align: "right" },
	{ label: ["Book Value on Transaction"], align: "right" },
	{ label: ["Acquisition And Production Costs On Transaction"], align: "right" }
];

const mapCard = json => {
	const {
		description,
		transactionType,
		assetTransactionId,
		period,
		date: dateAssetTransaction,
		supplierRowKey,
		supplierID,
		transactionRowKey,
		transactionID,
		transactionAmount,
		bookValueOnTransaction,
		acquisitionAndProductionCostsOnTransaction
	} = json;

	return [
		[{ labels: ["description"], value: description, type: "string" }],
		[{ labels: ["type"], value: transactionType, type: "string" }],
		[{ labels: ["id"], value: assetTransactionId, type: "string" }],
		[{ labels: ["period"], value: period, type: "number" }],
		[{ labels: ["date"], value: date(dateAssetTransaction), type: "date" }],
		[
			supplierRowKey
				? {
						label: "supplier",
						value: {
							id: supplierRowKey,
							name: supplierID,
							__typename: "Supplier"
						},
						type: "object"
				  }
				: { labels: ["supplier"], value: supplierID || "N/A", type: "string" }
		],
		[
			transactionRowKey
				? {
						label: "transaction",
						value: {
							id: transactionRowKey,
							name: transactionID,
							__typename: "Transaction"
						},
						type: "object"
				  }
				: {
						labels: ["transaction"],
						value: transactionID || "N/A",
						type: "string"
				  }
		],
		[
			{ labels: ["amount"], value: format(transactionAmount), type: "currency" }
		],
		[
			{
				labels: ["book"],
				value: format(bookValueOnTransaction),
				type: "currency"
			}
		],
		[
			{
				labels: ["acquisition"],
				value: format(acquisitionAndProductionCostsOnTransaction),
				type: "currency"
			}
		]
	];
};

const mapper = (json, card, details) => {
	const name = json.asset_transaction_id;

	return {
		...json,
		__typename: json.__typename,
		id: json.id,
		name,
		shortName: name,
		details: details ? mapDetails(json) : [],
		card: card ? mapCard(json) : []
	};
};

const assettransactionMapper = {
	map: mapper,
	header
};

export default assettransactionMapper;