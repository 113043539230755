import React, { forwardRef, useRef, useImperativeHandle, useState, useEffect } from 'react'
import { TextInput, Dropdown, Checkbox, DatePicker, MultiSelectionInput, TextArea } from 'colbi_web_ui/lib/components/inputs'
import { useGlobalValue } from 'colbi_web_ui/lib/state/GlobalProvider'
import { FORM_ACTIONS } from '../FormActions'

import styles from './OrganizationForm.module.sass'
import { checkRoles } from 'colbi_web_ui/lib/utils/roleValidator'
import { UserRoles } from 'colbi_web_ui/lib/utils/userRoles'
import { adjustForTimezone } from '../../../utils/convertUtils'

const OrganizationForm = (props, ref) => {
	const { data, organizations, status, onChange, onSave, projectModules, defaultOrganizatioMetadata } = props

	const formRef = useRef()

	const {
		name,
		organizationID,
		packageID,
		active = false,
		expirationDate,
		organizationsModules,
		metadata,
		limitProjects
	} = data || {}

	const initialDates = {
		"expirationDate": expirationDate ? adjustForTimezone(expirationDate) : ""
	};

	const [inputs, setInputs] = useState(initialDates);

	const { i18n, user } = useGlobalValue()

	const handleChange = (e, prop, value) => {
		onChange && onChange({
			originalEvent: e,
			target: {
				value: {
					data: {
						...data,
						[prop]: value
					}
				}
			}
		})
	}

	const [metadataFormatted, setMetadataFormatted] = useState("{}");

	useEffect(() => {
		try {
			if (metadata) {
				setMetadataFormatted(JSON.stringify(JSON.parse(metadata), undefined, 4));
			} else if (defaultOrganizatioMetadata) {
				setMetadataFormatted(
					JSON.stringify(JSON.parse(defaultOrganizatioMetadata), undefined, 4),
				);
			}
		} catch (e) {
			alert("Invalid Json");
		}
	}, [metadata, defaultOrganizatioMetadata])

	useEffect(() => {
		setInputs(initialDates)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [expirationDate])

	const handleDateChange = (e, fieldName) => {
		setInputs(inputs => ({
			...inputs,
			[fieldName]: e
		}))
	};

	useImperativeHandle(ref, () => ({
		submit: () => {
			formRef.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))
		}
	}))

	return status !== 'loading' ? (
		<form ref={formRef} onSubmit={(e) => {
			e.preventDefault()
			e.stopPropagation()
			if (formRef.current.checkValidity()) {
				data["__typename"] = "Organization"
				data["metadata"] = metadataFormatted
				for (const props in inputs) {
					data[`${props}`] = inputs[props]
				}
				onSave && onSave()
			} else {
				formRef.current.reportValidity()
			}
		}}>
			<div className={styles['group-content']}>
				<div className={styles['flex']}>
					<TextInput
						className={styles['input']}
						name={i18n`Organization ID`}
						placeholder={i18n`Enter an id`}
						value={organizationID || ''}
						required
						onChange={(e) => {
							handleChange(e, 'organizationID', e.target.value)
						}}
					/>
					<TextInput
						className={styles['input']}
						name={i18n`Organization Name`}
						placeholder={i18n`Enter a name`}
						value={name || ''}
						required
						onChange={(e) => {
							handleChange(e, 'name', e.target.value)
						}}
					/>
				</div>
			</div>
			<div className={styles['input-field']}>
				<MultiSelectionInput
					className={styles['input']}
					name={i18n`Modules to Current Organization`}
					value={(typeof organizationsModules === 'string' ? JSON.parse(organizationsModules || '[]') : organizationsModules || []).map((type) => type).filter(p => p)}
					placeholder={i18n`Select modules to active`}
					required
					options={
						projectModules && projectModules.length
							? projectModules.map(({ id, name }) => ({ id, name: name }))
							: []
					}
					onChange={(e) => {
						handleChange(e, 'organizationsModules', JSON.stringify(e.target.value))
					}}
				/>
			</div>
			<div className={styles['input-field']}>
				<h5 className={styles['label']}>{i18n`Package`}</h5>
				<Dropdown
					value={packageID && organizations ? organizations.find(({ id }) => id === packageID) || organizations[0] : null}
					options={organizations || []}
					className={styles['dropdown']}
					placeholder={i18n`-- Select an package --`}
					required
					filter
					onChange={(e) => {
						handleChange(e, 'packageID', e.target.value.id)
					}}
				/>
				{
					(checkRoles(user, [UserRoles.ADMIN, UserRoles.PP_SUPPORT, UserRoles.BACKOFFICE])) ?
						<TextArea
							className={styles['input']}
							placeholder='{"key":"value", ...}'
							value={metadataFormatted || ''}
							name={i18n`Metadata`}
							onChange={(e) => {
								setMetadataFormatted(e.target.value);
							}}
						/>
						: null
				}
				<div className={styles['group-content']}>
					<div className={styles['flex']}>
						{(checkRoles(user, [UserRoles.ADMIN, UserRoles.PP_SUPPORT, UserRoles.BACKOFFICE])) ?
							<TextInput
								className={`${styles['input']}`}
								name={i18n`Limit Number of Projects`}
								placeholder={i18n`Enter a number`}
								value={limitProjects || ''}
								type={"number"}
								onChange={(e) => {
									if (parseInt(`${e.target.value}`) || e.target.value === '') {
										handleChange(e, 'limitProjects', parseInt(e.target.value))
									}
								}}
								onWheel={(e) => e.target.blur()}
								min={1}
							/>
							:
							null
						}
						<DatePicker
							className={`${styles['wrap-datepicker']}`}
							name={i18n`Expiration Date`}
							value={inputs["expirationDate"]}
							onChange={(e) => {
								handleDateChange(e, "expirationDate")
							}}
							dateFormat="dd-MM-yyyy"
						/>
					</div>
				</div>
			</div>
			<Checkbox
				style={{ marginTop: '10px' }}
				value={active}
				onChange={(e) => {
					handleChange(e, 'active', e.target.checked ? 1 : 0)
				}}
			>{i18n`Active`}</Checkbox>
		</form>
	) : null
}

const OrganizationFormRef = forwardRef(OrganizationForm)

OrganizationFormRef.queries = (args) => {
	return [
		{
			resource: 'packages',
			body: 'id,name'
		},
		{
			resource: 'available_project_modules',
			body: 'id, name'
		},
		{
			resource: 'default_organization_metadata',
		},
		args && args.id ? {
			resource: 'organization',
			args: { [`organizationId`]: args.id },
			body: '__typename,id,organizationID,active,expirationDate,name,packageID,organizationsModules,metadata,limitProjects'
		} : undefined,
	].filter(q => q)
}

OrganizationFormRef.dataMapper = ['organizations', 'projectModules', 'defaultOrganizatioMetadata', 'data']

OrganizationFormRef.actions = [FORM_ACTIONS.SAVE]
export default OrganizationFormRef