import AppByFile from "../controllers/AppByFile/AppByFile";
import { StackLayout } from "../layouts";
import {
	List,
	headers,
	Tabs,
	rows,
} from "colbi_web_ui/lib/components";
import { parseFilter } from "colbi_web_ui/lib/utils";
import theme from "colbi_web_ui/lib/style/_theme.json";

const queries = (args = {}) => {
	const subEntityTypeWithPrefix = args.modulePrefix && args.subEntity ? `${args.modulePrefix}_${args.subEntity}` : args.subEntity
	const entityWithPrefix = args.modulePrefix && args.entity ? `${args.modulePrefix}_${args.entity}` : args.entity
	return [
		{
			resource: "check_has_data",
			args: {
				projectId: args.projectId,
				fiscalYear: args.fiscalYear,
				modulePrefix: args.modulePrefix,
			},
		},
		{
			resource: entityWithPrefix,
			args: {
				projectId: args.projectId,
				fiscalYear: args.fiscalYear,
				modulePrefix: args.modulePrefix,
			},
			body: `...${entityWithPrefix}`,
		},
		{
			resource: subEntityTypeWithPrefix ,
			args: {
				projectId: args.projectId,
				fiscalYear: args.fiscalYear,
				modulePrefix: args.modulePrefix,
				filter: args.filter
					? { type: "[FilterInput!]", value: parseFilter(args.filter) }
					: undefined,
				sort: args.sort
					? { type: "[SortInput!]", value: parseFilter(args.sort) }
					: undefined,
				page: parseInt(args.page, 10) || 0,
			},
			downloadable: true,
			xlsxTranslation: args.subEntity,
			body: `__typename, items{...${subEntityTypeWithPrefix}}, hasMore, total, totalizers`,
		},
	].filter((q) => !!q.resource);
};

const statement = (path, navigation, exact, title) => ({
	path,
	navigation,
	component: AppByFile,
	queries,
	exact,
	title: [title, "label(query(1).__typename)"].filter(
		(l) => l
	),
	components: [
		{
			component: headers.EntityHeader,
			modifiers: ["--root-margin-bottom-normal", "--custom-background-color-widget"],
			props: {
				type: "query(1).__typename",
				data: "query(1).details",
				hasData: "query(0)"
			},
		},
		{
			component: Tabs,
			modifiers: ["--root-margin-top-small", "--root-margin-bottom-small"],
			props: {
				basePath: `${path}`,
				showCount: true,
				data: "query(1).entities",
				labelKey: "entity",
				routeKey: "model",
				redirectEnabler: true,
				stickyTop: Math.min(39, parseInt(theme.layout.$headerHeight, 10) || 39),
			},
		},
	],
	routes: [
		{
			path,
			exact: true,
			queries: queries,
			component: StackLayout,
			components: [
				{
					component: List,
					modifiers: ["--row-style-alternate"],
					props: {
						basePath: path,
						header: "query(2).header",
						sort: "param(sort)",
						items: "query(2).list",
						card: rows.EntityCard,
						handleNoResults: true,
						metadata: "query(2).metadata",
						page: "param(page)",
						status: "status(0)",
						pageSize: "param(pageSize)",
						pageDefault: 10,
						footer: "query(2).footer",
						totalizers: "query(2).totalizers",
					},
				},
			],
		},
	],
});

export default statement;
