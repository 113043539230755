import I18n from "../../I18n";
import { buildRequestBody, alias } from "../../utils";
import { pushDialog } from "./dialogActions";

const push = (id, title, view, data, modalType) => (
	{
		type: 'PUSH_MODAL',
		id,
		payload: {
			title,
			view,
			transition: 'enter',
			data,
			modalType
		}
	}
)

const pop = (id) => (
	{
		type: 'POP_MODAL',
		id
	}
)

const remove = (id) => (
	{
		type: 'REMOVE_MODAL',
		id
	}
)

const startFetchingModalData = (id) => (
	{
		type: 'FETCH_MODAL_DATA',
		id
	}
)

const startSavingModalData = (id) => (
	{
		type: 'SAVE_MODAL_DATA',
		id
	}
)

const updateStatus = (id, status, errors) => (
	{
		type: 'UPDATE_MODAL_STATUS',
		id,
		errors,
		status
	}
)

const updateData = (id, data, args, status, modalType) => (
	{
		type: 'UPDATE_MODAL_DATA',
		id,
		args,
		status,
		payload: data
	}
)

const removeModalData = (id) => (
	{
		type: 'REMOVE_MODAL_DATA',
		id
	}
)

const promises = {}

export const pushModal = ({ title, data, args, view, modalType = '' }) => (
	(dispatch) => {
		const id = `MODAL_${ Date.now() }`
		dispatch(push(id, title, view, data, modalType))
		dispatch(updateData(id, data, args, 'initial', modalType))

		return new Promise((resolve, reject) => {
			promises[id] = [resolve, reject]
		})
	}
)

export const updateModalData = ({ id, data }) => (
	(dispatch, getState) => {
		const currentData = (getState().modalsData[id]).data
		dispatch(updateData(id, { ...(currentData || {}), ...(data || {}) }))
	}
)

export const updateModalStatus = ({ id, status, errors }) => (
	(dispatch) => {
		dispatch(updateStatus(id, status, errors))
	}
)

export const updateModalErrors = ({ id, data }) => (
	(dispatch, getState) => {
		const currentData = getState().modalsData[id].data
		dispatch(updateData(id, { ...(currentData || {}), ...(data || {}) }))
	}
)

export const fetchModalData = ({ id, queries, mapper }) => (
	(dispatch, getState) => {
		const locale = getState().locale
		const currentData = (getState().modalsData[id] || {}).data || {}

		if (queries) {
			dispatch(startFetchingModalData(id))
			const queriesBody = buildRequestBody(queries, locale)
			fetch('/graphql', {
				method: 'POST',
				credentials: 'same-origin',
				headers: {
					'Content-Type': 'application/json',
					'Accept': 'application/json',
				},
				body: JSON.stringify(queriesBody)
			})
				.then((res) => res.json().catch(() => ({})))
				.then((res) => {
					const data = ((res || {}).data || {})
					const dataObject = {
						data: {
							...currentData
						}
					}
					mapper.forEach((prop, index) => {
						const query = queries[index]
						if (query) {
							const queryAlias = alias({
								resource: query.resource,
								args: query.args,
								locale
							})
							dataObject[prop] = data[queryAlias]
						}
					})
					dispatch(updateData(id, dataObject))
				})
				.catch((e) => {
					console.log(e)
				})
		}
	}
)

export const saveModalData = (id) => (
	(dispatch, getState) => {
		const modalData = (getState().modalsData[id] || { data: {} }).data.data
		if (modalData && modalData.__typename) {
			const modalName = modalData.__typename.toLowerCase()
			const queriesBody = {
				query: `mutation($${ modalName }:${ modalData.__typename }Input!,$projectId:String){createOrUpdate${ modalData.__typename }(${ modalName }:$${ modalName },projectId:$projectId){id}}`,
				variables: {
					[modalName]: {
						...modalData,
						__typename: undefined
					},
					projectId: modalData.projectId
				}
			}

			if(modalName === 'ticket'){
				delete queriesBody.variables.projectId
			}
			
			dispatch(startSavingModalData(id))
			fetch('/graphql', {
				method: 'POST',
				credentials: 'same-origin',
				headers: {
					'Content-Type': 'application/json',
					'Accept': 'application/json',
				},
				body: JSON.stringify(queriesBody)
			})
				.then((res) => res.json().catch(() => ({})))
				.then((res) => {
					const data = res.data || {}
					const errors = res.errors || [{ message: 'Unknown Error' }]
					if (data && data[`createOrUpdate${ modalData.__typename }`] && data[`createOrUpdate${ modalData.__typename }`].id) {
						dispatch(pop(id))
						dispatch(removeModalData(id))
						if (promises[id] && promises[id][0]) {
							promises[id][0]()
						}
						Reflect.deleteProperty(promises, id)
					} else {
						dispatch(updateModalStatus({ id, status: 'error', errors }))
					}
				})
		} else {
			if (modalData && !modalData.__typename) {
				console.error('Missing __typename from model data')
			}
			dispatch(pop(id))
			dispatch(removeModalData(id))
			if (promises[id] && promises[id][1]) {
				promises[id][1]()
			}
			Reflect.deleteProperty(promises, id)
		}
	}
)

export const saveTicketModal = (id) => (
	(dispatch, getState) => {
		const modalData = (getState().modalsData[id] || { data: {} }).data.data
		const path = modalData && modalData.projectId ? modalData.projectId : ''

		if (modalData && modalData.__typename) {
			const querieTicket = {
					query: `mutation($p:String!,$tID:String!,$tLabel:String!,$u:String!){create_ticket(targetProjectId:$p,ticketId:$tID,ticketLabel:$tLabel,userId:$u){id}}`,
					variables: {
						p: modalData.projectId,
						tID: modalData.ticketNumber,
						tLabel: modalData.ticketDescription,
						u: modalData.userId
					}
				}
				fetch('/graphql', {
					method: 'POST',
					credentials: 'same-origin',
					headers: {
						'Content-Type': 'application/json',
						'Accept': 'application/json',
					},
					body: JSON.stringify(querieTicket)
				})
					.then((res) => res.json().catch(() => ({})))
					.then((res) => {
						if(res && res.errors){
							const errors = res.errors
							dispatch(updateModalStatus({ id, status: 'error', errors }))
						}else{
							window.location.href = '/en/'+ path + '/repository';
						}
					})
		} else {
			if (modalData && !modalData.__typename) {
				console.error('Missing __typename from model data')
			}
			dispatch(pop(id))
			dispatch(removeModalData(id))
			if (promises[id] && promises[id][1]) {
				promises[id][1]()
			}
			Reflect.deleteProperty(promises, id)
		}
	}
)

export const popModal = (id) => (
	(dispatch) => {
		dispatch(pop(id))
		dispatch(removeModalData(id))
		if (promises[id] && promises[id][1]) {
			promises[id][1]()
		}
		Reflect.deleteProperty(promises, id)
	}
)

export const removeModal = (id) => (
	(dispatch) => {
		dispatch(remove(id))
	}
)

export const submitDataFilesAction = (queriesBody, id) => (
	(dispatch) => {
		dispatch(startSavingModalData(id))
		fetch('/graphql', {
			method: 'POST',
			credentials: 'same-origin',
			headers: {
				'Content-Type': 'application/json',
				'Accept': 'application/json',
			},
			body: JSON.stringify(queriesBody)
		})
			.then((res) => res.json().catch(() => ({})))
			.then((res) => {
				const data = res.data || {}
				const errors = res.errors || [{ message: 'Unknown Error' }]
				if (data && data[`data_file_submission_request`] && data[`data_file_submission_request`].id) {
					dispatch(pop(id))
					dispatch(removeModalData(id))
					if (promises[id] && promises[id][0]) {
						promises[id][0]()
					}
					Reflect.deleteProperty(promises, id)
				} else {
					dispatch(updateModalStatus({ id, status: 'error', errors }))
				}
			})
	}
)
export const submitFilesModal = (id) => (dispatch, getState) => {
	const modalData = (getState().modalsData[id] || { data: {} }).data.data;
	if (modalData && modalData.__typename) {
		const queriesBody = {
			query: `mutation($datafilesubmission:DataFileSubmissionInput!,$projectId:String){data_file_submission_request(datafilesubmission:$datafilesubmission,projectId:$projectId){id}}`,
			variables: {
				datafilesubmission: {
					dataFileIds: modalData.dataFileIds,
					userPasswordEncripted: modalData.userPasswordEncripted,
					submissionFilePeriod: modalData.period,
					__typename: undefined,
				},
				projectId: modalData.projectId,
			},
		};
		if (
			modalData.filesWithProblems.length &&
			modalData.filePeriods &&
			modalData.filePeriods.find(
				(elem) => elem !== parseInt(modalData.period)
			) !== undefined
		) {
			dispatch(
				pushDialog("ConfirmDialog", I18n.translate`Continue Submission?`, {
					title: I18n.translate`Some of the selected files have critical or high inconsistencies.`,
					text: I18n.translate`Please confirm that you want to proceed!`,
				})
			)
				.then(() => {
					dispatch(
						pushDialog("ConfirmDialog", I18n.translate`Continue Submission?`, {
							title: I18n.translate`Some of the selected files have a different period than the one entered.`,
							text: I18n.translate`Please confirm that you want to proceed!`,
						})
					).then(() => {
						dispatch(submitDataFilesAction(queriesBody, id));
					});
				})
				.catch(() => null);
		} else if (modalData.filesWithProblems.length) {
			dispatch(
				pushDialog("ConfirmDialog", I18n.translate`Continue Submission?`, {
					title: I18n.translate`Some of the selected files have critical or high inconsistencies.`,
					text: I18n.translate`Please confirm that you want to proceed!`,
				})
			)
				.then(() => {
					dispatch(submitDataFilesAction(queriesBody, id));
				})
				.catch(() => null);
		} else if (
			modalData.filePeriods &&
			modalData.filePeriods.find(
				(elem) => elem !== parseInt(modalData.period)
			) !== undefined
		) {
			dispatch(
				pushDialog("ConfirmDialog", I18n.translate`Period Warning?`, {
					title: I18n.translate`Some of the selected files have a different period than the one entered.`,
					text: I18n.translate`Please confirm that you want to proceed!`,
				})
			)
				.then(() => {
					dispatch(submitDataFilesAction(queriesBody, id));
				})
				.catch(() => null);
		} else {
			dispatch(submitDataFilesAction(queriesBody, id));
		}
	} else {
		if (modalData && !modalData.__typename) {
			console.error("Missing __typename from model data");
		}
		dispatch(pop(id));
		dispatch(removeModalData(id));
		if (promises[id] && promises[id][1]) {
			promises[id][1]();
		}
		Reflect.deleteProperty(promises, id);
	}
};