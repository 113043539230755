import { date, format } from '../../../utils'

const mapCard = (json) => {
	const {
        invoiceNo,
        invoiceDate,
        amount,
        vatAmount
	} = json

	return [
        [
            { labels: ['invoiceNo'], value: invoiceNo, type: 'string' }
        ],
        [
            { labels: ['date'], value: date(invoiceDate), type: 'date' }
        ],
        [
            { labels: ['amount'], value: format(amount), type: 'currency' }
        ],
        [
            { labels: ['vatAmount'], value: format(vatAmount), type: 'currency' }
        ],
	]
}

const mapper = (json, card) => {

	return {
        ...json,
        __typename: json.__typename,
        id: json.id,
		card: card ? mapCard(json) : []
	}
}

const header = [
    { label: ["Invoice No"], align: 'left',columnName: "reference_no" },
    { label: ["Invoice Date"], align: 'center', columnName: "invoice_date", sortable: true },
    { label: ["Amount"], align: 'right', columnName: "amount", sortable: true, totalizerName: 'total_amount' },
    { label: ["Vat Amount"], align: 'right', columnName: "vat_amount", sortable: true, totalizerName: 'total_vat_amount' },
]
const spreferenceinvoiceMapper = {
	map: mapper,
	header
}
export default spreferenceinvoiceMapper;