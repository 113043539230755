const header = [
    { label: ["First Name"], align: 'left', sortable: true, columnName: "first_name", },
    { label: ["Last Name"], align: 'left', sortable: true, columnName: "last_name", },
    { label: ["Telephone"], align: 'center' },
    { label: ["E-mail"], align: 'left' },
]

const mapCard = (json) => {
	const {
        telephone,
        email,
        firstName,
        lastName
	} = json

	return [
        [
            { labels: ['firstName'], value: firstName, type: 'string' }
        ],
        [
            { labels: ['lastName'], value: lastName, type: 'string' }
        ],
        [
            { labels: ['telephone'], value: telephone, type: 'number' }
        ],
        [
            { labels: ['email'], value: email, type: 'string' }
        ]
	]
}

const mapper = (json, card) => {
    const {
        contactPersonFirstName,
        contactPersonLastName
    } = json
    
    const name = json.customer_id || json.supplier_id

    return {
        ...json,
        __typename: json.__typename,
        id: (contactPersonFirstName || contactPersonLastName) && json.id,
		name,
        shortName: name,
		card: card ? mapCard(json) : []
	}
}
const contactMapper = {
    map: mapper,
    header
}

export default contactMapper;