import { format } from '../../../utils'

const header = [
    { label: ["Analysis Type"], align: 'left' },
    { label: ["Analysis ID"], align: 'left' },
    { label: ["Analysis Amount"], align: 'right', totalizerName: "total_analysis_amount", compact: "fullValue" },
    { label: ["Currency"], align: 'center' },
    { label: ["Currency Amount"], align: 'right' }
]

const mapCard = (json) => {
    const {
        analysisId,
        analysisType,
        analysisAmountCurrencyCode,
        analysisAmount,
        analysisCurrencyAmount
    } = json

    return [
        [
            { labels: ['analysisType'], value: analysisType, type: 'string'  }
        ],
        [
            { labels: ['analysisId'], value: analysisId, type: 'string' }
        ],
        [
            { labels: ['analysisAmount'], value: format(analysisAmount, "fullValue"), type: 'currency' }
        ],
        [
            { labels: ['foreignCurrency'], value: analysisAmountCurrencyCode, type: 'string' }
        ],
        [
            { labels: ['foreignCurrencyAmount'], value: format(analysisCurrencyAmount,'fullValue',null,2), type: 'currency' }
        ]
    ]
}

const mapper = (json, card) => {
    const name = json.invoice_line_id || json.payment_line_id || json.purchase_invoice_line_id || json.transaction_line_id

    return {
        ...json,
        __typename: json.__typename,
        id: json.id,
        name,
        shortName: name,
        card: card ? mapCard(json) : []
    }
}

const analysisMapper = {
    map: mapper,
    header
}

export default analysisMapper;