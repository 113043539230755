import React, { useEffect, useState } from "react";
import { Redirect, withRouter } from "react-router-dom";
import { renderRoutes, matchRoutes } from "react-router-config";
import { connect } from "react-redux";
import { parse } from "query-string";
import { changeDeepObject, mapPropertiesForMenu } from "../../utils";

import { GlobalProvider as ColbiUIProvider } from "colbi_web_ui/lib/state/GlobalProvider";
import {
	mapProperties,
	hydrateRoute,
	stringifyFilter,
} from "colbi_web_ui/lib/utils";

import AsideMenu from "colbi_web_ui/lib/components/layout/AsideMenu/AsideMenu";
import Header from "colbi_web_ui/lib/components/layout/Header/Header";
import Connect from "colbi_web_ui/lib/components/Connect";

import { GlobalProvider } from "../../state/globalProvider";
import MainTabs from "../App/MainTabs/MainTabs";

import ModalController from "../App/ModalController/ModalController";
import DialogController from "../App/DialogController/DialogController";

import { FreshDeskWidget, ImageRender, LoadingIndicator } from "colbi_web_ui/lib/components";
import {
	logout,
	pushModal,
	pushDialog,
	popModal,
	uploadAction,
	downloadAction,
	toggleMenu,
	setLocale,
	auditNoteSubmit,
	removeModal,
	setDataFileStatusAction,
	deleteFilePermanentlyAction,
	deleteInvalidFilePermanentlyAction,
	hideFileAction,
	downloadFileAction,
	reprocessRepositoryAction,
	genericSearchAction,
	getFindingDetails,
    sendSAPAction,
    downloadJSONAction,
	sendSalesInformationEmail
} from "../../store/actions";
import I18n from "../../I18n";
import theme from "../../style/_theme.json";
import proxy from "../../routes/proxy";

import { persistor } from "../../../src/index";
import styles from "./AppByFile.module.sass";
import { PersistGate } from "redux-persist/integration/react";
import { UserRoles } from "../../enums/userRoles";
import {
	filteredQueriesByModules,
	filteredQueriesByModulesForMenu,
	getFirstModuleOfArray
} from "../../routes/manageRouting";
import socketIOClient from "socket.io-client";
import { checkRoles, decryptSession } from "colbi_web_ui/lib/utils/roleValidator";
import configsByTheme from '../../enums/configsByTheme'
import {mapProjectsByOrganization} from "../../utils/mapProjectsByOrganization"

const Provider = ({ children, ...props }) => {
	return (
		<GlobalProvider {...props}>
			<ColbiUIProvider {...props}>{children}</ColbiUIProvider>
		</GlobalProvider>
	);
};

const appTheme = process && process.env && process.env.REACT_APP_COLBI_THEME ? process.env.REACT_APP_COLBI_THEME.toLocaleLowerCase() : "";
const asideMenuLogo = configsByTheme(appTheme)["icon"]
const localesByAppTheme = configsByTheme(appTheme)['locales'];
const logoHorizontalDisplay = configsByTheme(appTheme)['logoHorizontalDisplay'] || false

const logo = () => {
	return (
		process &&
		process.env && (
			<ImageRender
				icon={`${appTheme && appTheme.length ? asideMenuLogo : "logo"}`}/>
		)
	);
};

const AppByFile = (props) => {
	const {
		modules,
		user,
		fetch,
		menu,
		logout,
		pushModal,
		popModal,
		toggleMenu,
		genericSearchAction,
		setLocale,
		match,
		route,
		location,
		history,
		reload,
		uploads,
		pushDialog,
		uploadFiles,
		download,
		submitAuditNote,
		setDataFileStatus,
		deleteFilePermanently,
		deleteInvalidFilePermanently,
		hideFile,
		downloadFile,
		reprocessRepository,
		auditNote,
		getFindingDetails,
		sendSAP,
		downloadJSON,
		sendSalesInformationEmail
	} = props;

	const locale = match.params.locale || "en";

	useEffect(() => {
		const pop = history.action === "POP";
		setLocale(locale);
		if (!pop && !fetch.activity) {
			if (location.hash) {
				const scrollElement = window.document.querySelector(location.hash);
				if (scrollElement) {
					const headerOffset = 70;

					const previousRect = scrollElement.previousElementSibling.getBoundingClientRect();
					let stuckOffset = previousRect.height;
					const elementPosition = scrollElement.offsetTop;
					const offsetPosition =
						elementPosition - headerOffset - stuckOffset - 100;

					setTimeout(() => {
						window.scrollTo({
							top: offsetPosition,
							behavior: "smooth",
						});
					}, 0);
				}
			} else if (location.search.search("page") === -1) {
				setTimeout(() => {
					window.scrollTo(0, 0);
				}, 0);
			}
		}
	}, [
		fetch.activity,
		location.hash,
		location.pathname,
		location.search,
		history.action,
		locale,
		setLocale,
	]);
	const [hover, setHover] = useState(false);

	let userInformation = decryptSession(user)
	const socketOptions = {
        "reconnection" : false,
		"path": "/socket.io"
    };
	useEffect(() => {
		const socket = socketIOClient(socketOptions);
		socket.on(`${match.params.projectId}_notifications`, (data) => {
			setResponse((prevState) => [...prevState, data]);
		});
		socket.on('connect_error', err => console.log("SocketIO cannot reach the server"));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const [response, setResponse] = useState([]);

	if (!userInformation) {
		return <Redirect to={`/${locale}/login`} push />;
	}

	if (userInformation && parseInt(userInformation.mysqlPasswordReset)) {
		return <Redirect to={`/${locale}/reset_password`} />;
	}

	if (userInformation && userInformation.role === UserRoles.BACKOFFICE) {
		return <Redirect to={`/${locale}/settings/`} />;
	}

	const { projectId, modulePrefix } = match.params;

	if (!projectId) {
		return <Redirect to={`${locale ? `/${locale}` : ""}/`} />;
	}

	const i18n = I18n.use(locale);

	const projectsByOrganization = mapProperties({ organizations: 'query(0).list', filter: 'param(filter)' }, fetch.results, fetch.status, AppByFile.queries(match.params), props, match.params, i18n, locale)

	const currentOrganization = (projectsByOrganization.organizations || []).find(({ projects }) => (projects || []).some(({ id }) => id === projectId))

	const version = (fetch.results[`${locale}_application_version`] || "")
		.version;

	const ticketSubjectEnvironment = fetch.results[`${locale}_get_environment_to_subject`] || ""

	const getTotalExportLines = fetch.results[`${locale}_get_limit_export`] || ""

	const isToDisplaySupportIcon = (fetch.results[`${locale}_is_to_display_support_icon`] || "true").toLocaleLowerCase() === "true";

	const searchBarItems = (fetch.results[`${locale}_search_bar_by_module_modulePrefix_${modulePrefix}_projectId_${projectId}`] || { list: [] }).list;

	const translatedSearchBarItems = (searchBarItems || []).map((elem) => {
		const {name, type} = elem
		return {
			type: type,
			name: i18n`${name}`
		}
	})

	const project = currentOrganization ? (currentOrganization.projects || []).find(({id}) => (id === projectId)) || {} : {}
	const fiscalYears = Object.keys(project.fiscalYears || []);
	const fiscalYearsWithImportIds= project.fiscalYears || {};

	if( !match.params.fiscalYear && !match.params.importId && !fiscalYears.length ){
		return (
			<Redirect
				to={`${locale? locale : "en"}/${projectId}/repository`}
			/>
		);
	}

	let fiscalYear;

	if (match.params.fiscalYear) {
		fiscalYear = match.params.fiscalYear;
	} else if (project && project.fiscalYears) {
		fiscalYear =
			fiscalYears.length > 0 ? Math.max(...fiscalYears) : "current";
	}

	const importIdsFromUrlFiscalYear = fiscalYearsWithImportIds[fiscalYear];
	
	const importId = match.params.importId
		? match.params.importId
		: importIdsFromUrlFiscalYear && importIdsFromUrlFiscalYear.length
		? Math.max(...importIdsFromUrlFiscalYear.map(({ id }) => id))
		: "";

	const repositoryStatus = fetch.results[
		`${locale}_repository_status_fiscalYear_${fiscalYear}_projectId_${projectId}`
	] || { status: 1 };

	//############ Build Generic Menu  ############

	const queriesForMenu = filteredQueriesByModulesForMenu(modules);
	const navData = mapPropertiesForMenu(
		queriesForMenu,
		fetch.results,
		locale,
		fiscalYear,
		projectId,
		importId
	);

	const firstModuleFromProject = getFirstModuleOfArray(modules)
	const moduleConfigs = project ? (project.modules || []).find(({name}) => name === firstModuleFromProject) : undefined
	const importIdToUse = (moduleConfigs && moduleConfigs.navigation_by_import_id) ? importId : "full";
	

	if (fiscalYear && fiscalYear !== match.params.fiscalYear && match.params.importId !== importIdToUse && firstModuleFromProject) {
		const to = hydrateRoute(match.path, {
			...match.params,
			fiscalYear,
			importId: importIdToUse,
			firstModuleFromProject
		});

		if (to !== match.url && !to.match(/\*$/)) {
			return <Redirect to={to} />;
		}
	}

	const queryParams = parse(location.search) || {};
	const routeParams = match.params || {};
	const params = {
		...queryParams,
		...routeParams,
	};

	const goto = (page, filters, anchor) => {
		const pageUrl = page
			? (typeof page.join === "function" ? page.join("/") : page).replace(
					/\/+/g,
					"/"
			  )
			: null;
		const urlFilters = filters
			? Array.isArray(filters)
				? stringifyFilter(filters)
				: stringifyFilter([filters])
			: "";
		const localePrefixed = pageUrl
			? `${locale ? `/${locale}${!pageUrl.match(/^\//) ? "/" : ""}` : ""}`
			: null;
		const gotoPage = pageUrl
			? `${localePrefixed}${pageUrl}${
					urlFilters.length
						? `?filter=${urlFilters}&resetFilters=${Date.now()}`
						: ""
			  }`
			: `${location.pathname}?filter=${urlFilters}&resetFilters=${Date.now()}${
					anchor ? `#${anchor}` : ""
			  }`;
		history.push(gotoPage);
	};

	const routeQueries = (route.queries && route.queries(params)) || [];

	const genericOverview = fetch.results[
		`${locale}_generic_overview_fiscalYear_${fiscalYear}_importId_${importId}_modulePrefix_${modulePrefix}_projectId_${projectId}`
	]

	const getLayout = JSON.parse(genericOverview || "[]")

	changeDeepObject(getLayout, ['component', "cards"])

	let displayComponents = []

	displayComponents.push(...route.components)

	if(getLayout.length > 0 && route && route.isOverview){
		displayComponents.push(...getLayout)
	}

	const children = displayComponents.map((c, index) => {
		const Component = c.component;
		const componentProps = mapProperties(
			c.props,
			(fetch || {}).results,
			(fetch || {}).status,
			routeQueries,
			props,
			params,
			i18n,
			locale
		);

		return (
			<Component
				key={index}
				modifiers={c.modifiers}
				{...componentProps}
				activity={fetch.activity}
			/>
		);
	});

	const title = mapProperties(
		{ title: route.title },
		(fetch || {}).results,
		(fetch || {}).status,
		routeQueries,
		props,
		params,
		i18n,
		locale
	).title;

	const branch = matchRoutes(
		[route, ...(route.routes || [])],
		location.pathname
	);

	const branchQueries = branch.reduce((allQueries, { route }) => {
		const innerQueries = route.queries ? route.queries(params) : [];
		allQueries = [...innerQueries];
		return allQueries;
	}, []);

	const downloadableQueries = branchQueries.filter(
		({ downloadable }) => downloadable
	);

	const { navigation } = mapProperties(
		{ navigation: route.navigation },
		(fetch || {}).results,
		(fetch || {}).status,
		AppByFile.queries(params),
		{
			...props,
			...navData,
		},
		params,
		i18n,
		locale
	);
	

	const handleHover = (state) => {
		setHover(state);
	};

	const mapOrganizationsAndProjectsForMenu = (projectsByOrganization.organizations || []).reduce((acc, organization) => {
		if(organization.projects && organization.projects.length && organization.organizationExpired === 0 && organization.organizationActive === 1){
			acc.push({
				type: 'organization',
				name: organization.name
			})
			const tempProjects = mapProjectsByOrganization(organization, projectId) || []
			acc.push(...(tempProjects).filter(elem => elem.to))
		}
		
		return acc
	}, [])
	
	return (
		<Provider
			user={userInformation}
			proxy={proxy}
			theme={theme}
			uploads={uploads}
			actions={{
				pushModal,
				pushDialog,
				popModal,
				uploadFiles,
				download,
				submitAuditNote,
				removeModal,
				setDataFileStatus,
				deleteFilePermanently,
				deleteInvalidFilePermanently,
				hideFile,
				downloadFile,
				reprocessRepository,
				logout,
				getFindingDetails,
				sendSAP,
				downloadJSON,
				setLocale: (locale) => {
					history.push(`/${locale}${history.location.pathname.substring(3)}`);
				},
				search: genericSearchAction,
				goto,
				downloadableQueries,
				sendSalesInformationEmail
			}}
			i18n={i18n}
			locale={locale || localesByAppTheme[0].id}
			locales={localesByAppTheme}
			reload={reload}
			params={{
				...params,
				fiscalYear: params.fiscalYear || fiscalYear,
				importId: params.importId || importId,
			}}
			history={history}
			location={location}
			repositoryStatus={repositoryStatus}
			auditNote={auditNote}
		>
			<PersistGate persistor={persistor}>
				<div className={styles["app"]}>
					<div
						onMouseEnter={() =>
							menu === "collapsed" ? handleHover(true) : null
						}
						onMouseLeave={() =>
							menu === "collapsed" ? handleHover(false) : null
						}
						className={`${styles["aside"]} ${
							menu === "expanded" ? "" : styles["is-collapsed"]
						}`}
					>
						<AsideMenu
							logo={logo}
							navigation={navigation}
							organizations={mapOrganizationsAndProjectsForMenu}
							version={version}
							project={project}
							organization={currentOrganization}
							expanded={menu === "expanded"}
							toggleMenu={toggleMenu}
							hover={hover}
							setHover={setHover}
							settingsAction={checkRoles(userInformation, [UserRoles.ADMIN, UserRoles.OWNER, UserRoles.PP_SUPPORT, UserRoles.SUPPORT])}
							reservedRights={configsByTheme(appTheme)["reservedRights"]}
                 			showAsideMenuButtonRow={true}
                			applicationName={configsByTheme(appTheme)["loginApplicationName"]}
							logoHorizontalDisplay={logoHorizontalDisplay}
						/>
					</div>
					<div className={styles["main"]}>
						<Connect to={["search", "downloading"]}>
							{({ search, downloading }) => (
								<Header
									className={styles["header"]}
									genericSearchAction
									reportsAction
									settingsAction={checkRoles(userInformation, [UserRoles.ADMIN, UserRoles.OWNER, UserRoles.PP_SUPPORT, UserRoles.SUPPORT])}
									project={project}
									hasFullNavigation={importIdToUse === "full"}
									fiscalYears={fiscalYearsWithImportIds}
									importIds={importIdsFromUrlFiscalYear}
									items={translatedSearchBarItems}
									downloadableQueries={downloadableQueries}
									title={title}
									downloading={downloading}
									search={search}
									navigation={navigation}
									organizations={projectsByOrganization}
									menuLogo={logo}
									navByImportId={route.navByImportId}
									notifications={response.length}
									notificationList={response}
									showCsvButton={route && route.showCsvButton ? route.showCsvButton : false}
									displaySupportIcon={isToDisplaySupportIcon}
									totalLinesCsv={getTotalExportLines}
									displayHeaderSearch={searchBarItems && searchBarItems.length ? true : false}
								/>
							)}
						</Connect>
						<main
							className={`${styles["content"] || ""} ${
								styles["foreground"] || ""
							}`}
						>
							{fetch.activity && (
								<LoadingIndicator
									className={`${styles["loading-indicator"]} ${
										menu === "expanded" ? "" : styles["small-margin"]
									}`}
								/>
							)}
							{children}
							{route.routes && route.routes.length > 1 && (
								<MainTabs
									className={styles["tabs"]}
									routes={route.routes}
									params={match.params}
								/>
							)}
							{renderRoutes(route.routes)}
						</main>
					</div>
					<ModalController popModal={popModal} />
					<DialogController i18n={i18n} />
					<FreshDeskWidget environment={`${ticketSubjectEnvironment} - ${(project|| {}).taxRegistrationNumber}`} />
				</div>
			</PersistGate>
		</Provider>
	);
};

AppByFile.queries = (args, userModulesByProject) => {
	const queries = filteredQueriesByModules(userModulesByProject);
	const deepCopyQueries = JSON.parse(JSON.stringify(queries));
	if (deepCopyQueries) {
		//needed to change arguments to the respective value
		deepCopyQueries.forEach((query) =>
			Object.keys(query.args).forEach((k) =>
				query.args[k]
					? query.args[k]
					: (query.args[k] = args[k] || query.args[k])
			)
		);
	}

	return [
		{
			resource: "user_projects_organizations",
			body: 'id,name,projects{id,name,fiscalYears,status,taxRegistrationNumber,active,projectExpired,modules{navigation_by_import_id,name,default_route,default_module}},organizationExpired, organizationActive'
		},
		{
			resource: "application_version",
			body: "version",
		},
		{
			resource: "get_environment_to_subject",
		},
		{
			resource: "is_to_display_support_icon",
		},
		{
			resource: "get_limit_export",
		},
		...deepCopyQueries,
	];
};

export default connect(
	({ user, fetch, menu, uploads, auditNote }) => ({
		user,
		fetch,
		menu,
		uploads,
		auditNote,
	}),
	{
		logout,
		pushModal,
		popModal,
		removeModal,
		toggleMenu,
		setLocale,
		pushDialog,
		getFindingDetails,
		setDataFileStatus: setDataFileStatusAction,
		deleteFilePermanently: deleteFilePermanentlyAction,
		deleteInvalidFilePermanently: deleteInvalidFilePermanentlyAction,
		hideFile: hideFileAction,
		downloadFile: downloadFileAction,
		reprocessRepository: reprocessRepositoryAction,
		uploadFiles: uploadAction,
		download: downloadAction,
		submitAuditNote: auditNoteSubmit,
		genericSearchAction,
		sendSAP: sendSAPAction,
		downloadJSON: downloadJSONAction,
		sendSalesInformationEmail
	}
)(withRouter(AppByFile));
