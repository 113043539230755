import { date } from '../../../utils'

const mapDetails = (json) => {
	const {
		documentNumber,
		period,
		docYear,
		transactionDate,
		description,
		systemEntryDate,
		postingDate,
		documentType,
		currencyCode,
		exchangeRate } = json

	return [
		[
			{ label: 'Invoice No.', value: documentNumber, type: 'string' },
			{ label: 'Description', value: description, type: 'string' },
			{ label: 'Document Type', value: documentType, type: 'string' },
		],
		[
			{ label: 'Period', value: period, type: 'string' },
			{ label: 'Document Year', value: docYear, type: 'string' },
			{ label: 'Transaction Date', value: date(transactionDate), type: 'date' },
			{ label: 'System entry Date', value: date(systemEntryDate), type: 'date' },
			{ label: 'Posting Date', value: date(postingDate), type: 'date' },
		],
		[
			{ label: 'Currency Code', value: currencyCode, type: 'string' },
			{ label: 'Exchange Rate', value: exchangeRate, type: 'number' }
		]
	]

}

const mapCard = (json) => {
	const {
		documentNumber,
		period,
		transactionDate,
		currencyCode,
		documentType
	} = json
	return [
		[
			{ labels: ['document Number'], value: documentNumber, type: 'string' }
		], [
			{ labels: ['period'], value: period, type: 'string' }
		], [
			{ labels: ['document type'], value: documentType, type: 'string' }
		], [
			{ labels: ['transanction date'], value: date(transactionDate), type: 'date' }
		], [
			{ labels: ['Currency Code'], value: currencyCode, type: 'sting' }
		]
	]
}

const mapper = (json, card, details) => {
	return {
		...json,
		name: json.documentNumber,
		shortName: json.documentNumber,
		details: details ? mapDetails(json) : [],
		card: card ? mapCard(json) : []
	}
}

const header = [
	{ label: ["document Number"], align: 'left', columnName: "document_number", sortable: true },
	{ label: ["period"], align: 'left', columnName: "period", sortable: true },
	{ label: ["document type"], align: 'left', columnName: "document_type", sortable: true },
	{ label: ["transaction date"], align: 'left', columnName: "transaction_date", sortable: true },
	{ label: ["Currency Code"], align: 'left', columnName: "currencyCode", sortable: true },
]

const purchaseinvoiceMapper = {
	map: mapper,
	header
}
export default purchaseinvoiceMapper;