import { alias } from './queryUtils'
import { format } from '.'

const multiIndex = (obj, is) => {
	if (is.length && obj !== undefined && obj !== null) {
		const data = Array.isArray(obj) && (is[0] === -1 || is[0] === '-1') ? obj[obj.length - 1] : obj[is[0]]
		return multiIndex(data, is.slice(1))
	}
	return obj
}


const funcRegex = /^(query|status|param|label|currency)\((.*?)\)$/

const map = (value, data) => {
	if (value && Array.isArray(value)) {
		return value.map((value) => map(value, data))
	} else if (value && typeof value == 'object') {
		const result = {}
		Object.entries(value || {}).map(([key, value]) => {
			return [key, map(value, data)]
		}).forEach(([key, value]) => {
			if (value !== undefined) {
				result[key] = value
			}
		})
		return result
	}
	if (typeof value !== 'string') {
		return value
	}
	const [func, ...path] = !value.match(funcRegex) ? value.split('.') : [value]
	const match = func.match(funcRegex)
	if (match) {
		// eslint-disable-next-line no-unused-vars
		const [_, mapper, ...args] = match
		args.push(data)
		if (mappers[mapper]) {
			return path ? multiIndex(mappers[mapper](map(...args, data), data), path) : mappers[mapper](map(...args, data), data)
		}
	}
	return value
}

const mappers = {
	query: (queryIndex, { queries, fetchData, locale }) => {
		const query = queries[queryIndex]
		if (query) {
			const queryAlias = alias({
				resource: query.resource,
				args: query.args,
				locale
			})
			return fetchData[queryAlias]
		}
		return undefined
	},
	status: (queryIndex, { queries, fetchStatus, locale }) => {
		const query = queries[queryIndex]
		if (query) {
			const statusKey = alias({
				resource: query.resource,
				args: query.args,
				body: query.body,
				locale
			}, true)
			// const statusKey = `${ locale ? `${ locale }:` : '' }${ queryAlias }${ query.body ? `(${ query.body })` : '' }`
			return fetchStatus[statusKey]
		}
		return undefined
	},
	param: (param, { params }) => {
		return params[param]
	},
	label: (label, { i18n }) => {
		// TODO: if no label use console.warn if in development or staging
		return label ? i18n(label) || label : ''
	},
	currency: (value) => {
		// TODO: if no label use console.warn if in development or staging
		return format(value)
	},
}

export function mapProperties(mapper, fetchData = {}, fetchStatus = {}, queries = [], props = {}, params = {}, i18n, locale = 'en') {
	const result = {}
	Object.entries(mapper || {}).map(([key, value]) => {
		return [key, map(value, { fetchData, fetchStatus, queries, props, params, i18n, locale })]
	}).forEach(([key, value]) => {
		if (value !== undefined) {
			result[key] = value
		}
	})
	return result
}