const mapCard = (json) => {
	const {
        type_of_donation,
        nif,
        donation_amount
	} = json

	return [
        [
			{ labels: ['Tipo de Donativo'], value: type_of_donation, type: 'string', basis: '20%' },
		],
		[
			{ labels: ['NIF'], value: nif, type: 'string', basis: '20%' },
        ],
        [
			{ labels: ['Valor do donativo'], value: donation_amount, type: 'string', basis: '20%' },
		]
	]
}

const mapper = (json, card) => {
	return {
		...json,
		card: card ? mapCard(json) : []
	}
}

const header = [
    { label: ["Tipo de Donativo"], align: 'left', basis: '20%' },
    { label: ["NIF"], align: 'left', basis: '20%' },
    { label: ["Valor do donativo"], align: 'left', basis: '20%' }
]

const donationsmod22Mapper = {
	map: mapper,
	header
}

export default donationsmod22Mapper;