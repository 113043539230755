const mapCard = (json) => {
	const {
		country_code,
		income_type,
		balance_not_deferred,
		foreign_paid_tax,
		low_tax_obtained_abroad,
		period_imposed_credit,
		deduce_in_period,
		balance_which_transits
	} = json

	return [
		[
			{ labels: ['Código do País'], value: country_code, type: 'string', basis: '65%' },
		],
		[
			{ labels: ['Tipo de rendimentos'], value: income_type, type: 'string', basis: '20%' }
		],
		[
			{ labels: ['Saldo não deduzido'], value: balance_not_deferred, type: 'string', basis: '20%' }
		],
		[
			{ labels: ['Imposto pago no estrangeiro'], value: foreign_paid_tax, type: 'string', basis: '20%' }
		],
		[
			{ labels: ['Fração do imposto relativa a rendimentos obtidos no estrangeiro'], value: low_tax_obtained_abroad, type: 'string', basis: '20%' }
		],
		[
			{ labels: ['Crédito de imposto do período'], value: period_imposed_credit, type: 'string', basis: '20%' }
		],
		[
			{ labels: ['Dedução efetuada no período'], value: deduce_in_period, type: 'string', basis: '20%' }
		],
		[
			{ labels: ['Saldo que transita'], value: balance_which_transits, type: 'string', basis: '20%' }
		]
	]
}

const mapper = (json, card) => {
	return {
		...json,
		card: card ? mapCard(json) : []
	}
}

const header = [
	{ label: ["Código do País"], align: 'left', basis: '65%' },
	{ label: ["Tipo de rendimentos"], align: 'left', basis: '20%' },
    { label: ["Saldo não deduzido"], align: 'left', basis: '20%' },
    { label: ["Imposto pago no estrangeiro"], align: 'left', basis: '20%' },
    { label: ["Fração do imposto relativa a rendimentos obtidos no estrangeiro"], align: 'left', basis: '20%' },
    { label: ["Crédito de imposto do período"], align: 'left', basis: '20%' },
    { label: ["Dedução efetuada no período"], align: 'left', basis: '20%' },
    { label: ["Saldo que transita"], align: 'left', basis: '20%' },
]

const taxcreditmod22Mapper = {
	map: mapper,
	header
}

export default taxcreditmod22Mapper;