const header = [
	{ label: ["Supplier Name"], align: "left", columnName: "supplier_name" },
	{ label: ["Supplier ID"], align: "left", columnName: "supplier_id"},
	{ label: ["Street"], align: "left" , columnName: "street_name"},
	{ label: ["Postal Code"], align: "left",columnName: "postal_code" },
	{ label: ["City"], align: "left",columnName: "city" },
	{ label: ["Country"], align: "left",columnName: "country" },
	{ label: ["Region"], align: "left", columnName: "region" }
];

const mapCard = json => {
	const {
		supplierName,
		supplierId,
		streetName,
		postalCode,
		city,
		country,
		region
	} = json;

	return [
		[{ labels: ["type"], value: supplierName, type: "string" }],
		[
			supplierId
				? {
						label: "Supplier",
						value: {
							id: supplierId,
							name: supplierId,
							__typename: "ErpSupplier"
						},
						type: "object"
				  }
				: { labels: ["Supplier"], value: supplierId || "N/A", type: "string" }
		],
		[
			{
				labels: ["street"],
				value: `${streetName}`,
				type: "string"
			}
		],
		[{ labels: ["postalCode"], value: postalCode, type: "string" }],
		[{ labels: ["city"], value: city, type: "string" }],
		[{ labels: ["country"], value: country, type: "string" }],
		[{ labels: ["region"], value: region || "N/A", type: "string" }]
	];
};

const mapper = (json, card) => {
	const name = json.assetId;

	return {
		...json,
		__typename: json.__typename,
		id: "",
		name,
		shortName: name,
		card: card ? mapCard(json) : []
	};
};

const assetsupplierMapper = {
	map: mapper,
	header
};

export default assetsupplierMapper
