import React from 'react'

import { RegularButton, ActionButton } from 'colbi_web_ui/lib/components/inputs'

import styles from './ConfirmDialog.module.sass'


const ConfirmDialog = (props) => {
	const { data, confirm, cancel, i18n } = props
	const { title, text } = data

	return (
		<div className={ styles['root'] }>
			<div className={ styles['content'] }>
				<h5 className={ styles['title'] }>{ title }</h5>
				<small>{ text }</small>
				<div className={ styles['actions'] }>
					<RegularButton
						className={ styles['action-button'] }
						type="button"
						onClick={ () => { cancel && cancel() } }>
						<strong>{ i18n`Cancel` }</strong>
					</RegularButton>
					<ActionButton
						className={ styles['action-button'] }
						type="button"
						onClick={ () => { confirm && confirm() } }>
						<strong>{ i18n`Confirm` }</strong>
					</ActionButton>
				</div>
			</div>
		</div>
	)
}

export default ConfirmDialog